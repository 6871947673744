import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';
import { saveAs } from 'file-saver';

export const downloadFile = (file: Blob, metaData?: FetchBaseQueryMeta) => {
  const contentDisposition = metaData?.response?.headers.get(
    'Content-Disposition'
  );

  let fileName;

  // https://stackoverflow.com/a/40940790
  if (contentDisposition) {
    const matches = /filename\*?=([^']*'')?([^;]*)/.exec(contentDisposition);

    if (matches != null && matches[2]) {
      fileName = matches[2].replace(/['"]/g, '');

      try {
        fileName = decodeURIComponent(fileName);
      } catch {
        // ignore
      }
    }
  }

  // https://github.com/reduxjs/redux-toolkit/issues/1522
  saveAs(file, fileName);
};
