import { FC, ReactNode, useMemo } from 'react';
import {
  Cross,
  PasswordHidden,
  PasswordVisible,
  Pencil,
} from 'src/assets/icons';
import { Button, SvgIcon, Typography } from 'src/components/ui';
import { TCategory, TNews } from 'src/models';
import { DropdownList } from 'src/components/widgets';
import { EPublishedStatus } from '../../config/news-types';

type TMenuOptionsProps = {
  item: TCategory | TNews;
  editHandler: () => void;
  deleteHandler?: () => void;
  publishHandler: () => void;
  unpublishHandler: () => void;
  DeleteComponent?: ReactNode;
};

export const MenuOptions: FC<TMenuOptionsProps> = ({
  deleteHandler,
  editHandler,
  publishHandler,
  item,
  unpublishHandler,
  DeleteComponent,
}) =>
  useMemo(() => {
    const options = [
      {
        component: (
          <Typography display="flex" gap="8px" color="primary.dark">
            <Pencil width={24} height={24} />
            <Button variant="text">Редактировать</Button>
          </Typography>
        ) as ReactNode,
        onClick: editHandler,
      },
      {
        component: DeleteComponent ?? (
          <Typography display="flex" gap="8px" color="error">
            <SvgIcon
              icon={Cross}
              strokeColor="error.main"
              height="20px"
              width="20px"
              strokeWidth="1px"
            />
            <Button color="error" variant="text">
              Удалить
            </Button>
          </Typography>
        ),
        onClick: deleteHandler,
      },
    ];
    if (item.publishStatus === EPublishedStatus.NOT_PUBLISHED) {
      options.splice(1, 0, {
        component: (
          <Typography display="flex" gap="8px" color="primary.dark">
            <SvgIcon
              icon={PasswordVisible}
              width="24px"
              height="24px"
              strokeColor="#0041A0"
              strokeWidth="0.5px"
            />
            <Button variant="text">Опубликовать</Button>
          </Typography>
        ),
        onClick: publishHandler,
      });
    } else {
      options.splice(1, 0, {
        component: (
          <Typography display="flex" gap="8px" color="primary.dark">
            <SvgIcon
              icon={PasswordHidden}
              width="24px"
              height="24px"
              strokeColor="#0041A0"
              strokeWidth="0.5px"
            />
            <Button variant="text">Скрыть</Button>
          </Typography>
        ),
        onClick: unpublishHandler,
      });
    }
    return <DropdownList items={options} withDivider={false} />;
  }, [item]);
