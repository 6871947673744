import { FC, useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButton } from 'src/components/ui';
import { ArrowDown, ArrowUp } from 'src/assets/icons';
import classNames from 'classnames';
import styles from './financial-table.module.scss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F4F5F7',
    color: '#7A8694',
    fontSize: 14,
  },
}));

const ArrowCell: FC<{
  isExpanded: boolean;
  onClick: () => void;
  showArrow?: boolean;
  className?: string;
}> = ({ isExpanded, onClick, showArrow, className }) => (
  <TableCell
    padding="checkbox"
    className={classNames(styles.stickyRight, className)}>
    {showArrow && (
      <IconButton onClick={showArrow ? onClick : () => {}}>
        {isExpanded ? <ArrowUp /> : <ArrowDown />}
      </IconButton>
    )}
  </TableCell>
);

interface IRow {
  items: string[];
  children?: IRow[];
  isOpened?: boolean;
  id: string;
}

interface ITableTProps {
  headerColumns: {
    key: string;
    value: string;
  }[];
  rows: IRow[];
}

export const FinancialTable: FC<ITableTProps> = ({ headerColumns, rows }) => {
  const [rowsCopy, setRowsCopy] = useState(rows);

  const findDepth = (arr: IRow[], id: string, depth = 1): number | null => {
    if (arr.some((item) => item.id === id)) {
      return depth;
    }

    // eslint-disable-next-line
    for (const i of arr) {
      if (Array.isArray(i.children)) {
        const newDepth = findDepth(i.children, id, depth + 1);

        if (newDepth) {
          return newDepth;
        }
      }
    }

    return null;
  };

  // Сделать нормально - через рекурсии и для любой вложенности + deepCopy etc
  const handleRowClick = (rowId: string) => {
    const copy = JSON.parse(JSON.stringify(rowsCopy)) as IRow[];
    const depth = findDepth(copy, rowId);

    if (depth === 1) {
      const changedCopy = copy.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            isOpened: !row.isOpened,
          };
        }

        return { ...row };
      });

      setRowsCopy(changedCopy);

      return;
    }

    if (depth === 2) {
      const changedCopy = copy.map((row) => ({
        ...row,
        children: row.children!.map((childrenRow) => {
          if (childrenRow.id === rowId) {
            return {
              ...childrenRow,
              isOpened: !childrenRow.isOpened,
            };
          }

          return { ...childrenRow };
        }),
      }));

      setRowsCopy(changedCopy);
    }
  };

  const wrapperRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollLeft = wrapperRef.current?.scrollWidth;
    }
  }, []);

  return (
    <TableContainer ref={wrapperRef}>
      <Table
        sx={() => ({
          [`& .${tableCellClasses.root}`]: {
            fontSize: '14px',
            overflowX: 'auto',
          },
        })}>
        <TableHead>
          <TableRow>
            {headerColumns.map((column) => (
              <StyledTableCell
                style={
                  column.key === 'NAME'
                    ? { fontSize: '10px' }
                    : { fontSize: '14px' }
                }
                className={classNames(
                  styles.bgDark,
                  ['NAME', 'CODE'].includes(column.key) && styles.sticky,
                  column.key === 'CODE' && styles.stickySecond
                )}
                key={column.key}>
                <div
                  style={
                    column.key === 'NAME' ? { width: 168, maxWidth: 168 } : {}
                  }>
                  {column.value}
                </div>
              </StyledTableCell>
            ))}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsCopy.map((row) => (
            <>
              <TableRow key={row.id} sx={{ backgroundColor: '#F4F5F7' }}>
                {row.items.map((cell, index) => (
                  <TableCell
                    component={index === 0 ? 'th' : 'td'}
                    scope="row"
                    key={index}
                    className={classNames(
                      styles.bgDark,
                      index === 0 && styles.sticky,
                      index === 1 && styles.stickySecond
                    )}>
                    {index < 2
                      ? cell
                      : (Number(cell) &&
                          new Intl.NumberFormat('ru-RU').format(+cell)) ||
                        cell}
                  </TableCell>
                ))}

                <ArrowCell
                  isExpanded={!!row?.isOpened}
                  onClick={() => handleRowClick(row.id)}
                  showArrow={row?.children && row.children?.length > 0}
                  className={styles.bgDark}
                />
              </TableRow>

              {row?.children &&
                row.children?.length > 0 &&
                row?.isOpened &&
                row.children.map((child) => (
                  <>
                    <TableRow key={child.id}>
                      {child.items.map((cell, idx) => (
                        <TableCell
                          component={idx === 0 ? 'th' : 'td'}
                          scope="row"
                          key={idx}
                          style={idx === 0 ? { paddingLeft: '20px' } : {}}
                          className={classNames(
                            styles.bgLight,
                            idx === 0 && styles.sticky,
                            idx === 1 && styles.stickySecond
                          )}>
                          {idx < 2
                            ? cell
                            : (Number(cell) &&
                                new Intl.NumberFormat('ru-RU').format(+cell)) ||
                              cell}
                        </TableCell>
                      ))}

                      <ArrowCell
                        isExpanded={!!child?.isOpened}
                        onClick={() => handleRowClick(child.id)}
                        showArrow={
                          child?.children && child.children?.length > 0
                        }
                        className={styles.bgLight}
                      />
                    </TableRow>

                    {child?.children &&
                      child.children?.length > 0 &&
                      child?.isOpened &&
                      child.children.map((nestedChild) => (
                        <TableRow key={nestedChild.id}>
                          {nestedChild.items.map((cell, idx) => (
                            <TableCell
                              component={idx === 0 ? 'th' : 'td'}
                              scope="row"
                              key={idx}
                              style={idx === 0 ? { paddingLeft: '32px' } : {}}
                              className={classNames(
                                styles.bgLight,
                                idx === 0 && styles.sticky,
                                idx === 1 && styles.stickySecond
                              )}>
                              {idx > 1 && !Number.isNaN(+cell)
                                ? (Number(cell) &&
                                    new Intl.NumberFormat('ru-RU').format(
                                      +cell
                                    )) ||
                                  cell
                                : cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </>
                ))}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
