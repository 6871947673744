import { FC, ReactElement, useState } from 'react';
import { Button, Drawer, Tooltip } from 'src/components/ui';
import { TDrawerProps } from 'src/components/ui/drawer';

import classNames from 'classnames';
import styles from './media-drawer-button.module.scss';

type TMediaDrawerButton = {
  icon: ReactElement;
  media?: 'md' | 'lg' | 'xl';
} & TDrawerProps;

export const MediaDrawerButton: FC<TMediaDrawerButton> = ({
  children,
  title,
  icon,
  media = 'lg',
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Tooltip title={title}>
        <Button
          className={classNames(styles.button, styles[media])}
          onClick={toggleOpen}>
          {icon}
        </Button>
      </Tooltip>

      <Drawer
        title={title}
        open={open}
        close={toggleOpen}
        PaperProps={{
          sx: {
            width: '506px',
          },
        }}
        {...rest}>
        {open && children}
      </Drawer>
    </>
  );
};
