import { compareAsc } from 'date-fns';
import {
  ContractorCommercialOfferStatus,
  IContractorCommercialOffer,
  ICustomerCommercialOffer,
  IManagerContractorCommercialOffer,
  IManagerCustomerCommercialOffer,
  ManagerCustomerCommercialOfferStatus,
} from 'src/models';
import { checkRefused } from 'src/utils/commercial-offer';

export const contractorCommercialOfferSorting = (
  data: IContractorCommercialOffer[]
) =>
  data.sort((a, b) => {
    const isADraft = a.status === ContractorCommercialOfferStatus.DRAFT;
    const isBDraft = b.status === ContractorCommercialOfferStatus.DRAFT;

    const orderDraft =
      (isADraft &&
        isBDraft &&
        compareAsc(new Date(b.lastModifiedAt), new Date(a.lastModifiedAt))) ||
      0;

    const orderOther =
      (a.sentAt &&
        b.sentAt &&
        compareAsc(new Date(b.sentAt), new Date(a.sentAt))) ||
      0;

    return Number(isADraft) - Number(isBDraft) || orderOther || orderDraft;
  });

export const managerContractorCommercialOffer = (
  data: IManagerContractorCommercialOffer[]
) =>
  data?.sort((a, b) => {
    const isARefused = Boolean(checkRefused(a));
    const isBRefused = Boolean(checkRefused(b));

    const sortingByCost = a.supplySum - b.supplySum;
    const sortingByName = a.contractorDetails.organizationName.localeCompare(
      b.contractorDetails.organizationName
    );

    return (
      Number(isARefused) - Number(isBRefused) || sortingByCost || sortingByName
    );
  });

export const customerCommercialOffer = (
  offers: ICustomerCommercialOffer[]
): ICustomerCommercialOffer[] =>
  [...offers].sort((a, b) =>
    compareAsc(new Date(b.receivedAt), new Date(a.receivedAt))
  );

export const managerCustomerCommercialOfferSorting = (
  data: IManagerCustomerCommercialOffer[]
) =>
  data.sort((a, b) => {
    const isADraft =
      a.status.code === ManagerCustomerCommercialOfferStatus.DRAFT;
    const isBDraft =
      b.status.code === ManagerCustomerCommercialOfferStatus.DRAFT;

    const isARefused =
      a.status.code === ManagerCustomerCommercialOfferStatus.REFUSED;
    const isBRefused =
      b.status.code === ManagerCustomerCommercialOfferStatus.REFUSED;

    const orderDraft =
      (isADraft &&
        isBDraft &&
        compareAsc(
          new Date(b.draftLastModifiedAt),
          new Date(a.draftLastModifiedAt)
        )) ||
      0;

    const orderOther =
      (a.sentAt &&
        b.sentAt &&
        compareAsc(new Date(b.sentAt), new Date(a.sentAt)) &&
        Number(a.costForCustomer) - Number(b.costForCustomer)) ||
      0;

    const orderRefused =
      (isADraft &&
        isBDraft &&
        b.status.createdAt &&
        a.status.createdAt &&
        compareAsc(
          new Date(b.status.createdAt),
          new Date(a.status.createdAt)
        )) ||
      0;

    return (
      Number(isADraft) - Number(isBDraft) ||
      Number(isARefused) - Number(isBRefused) ||
      orderOther ||
      orderRefused ||
      orderDraft
    );
  });
