import {
  ContractorStatusCode,
  ContractorRequestStatusForKam,
  RebiddingContractorStatus,
} from 'src/models';

export const isTerminalStatus = (
  status: string,
  orderProcessingStarted: boolean | undefined
) => {
  if (orderProcessingStarted) {
    return true;
  }
  return [
    'SPECIFICATION_SIGNED_WITH_ANOTHER_CONTRACTOR',
    'REQUEST_REFUSAL',
    'REQUEST_EXCLUSION',
  ].includes(status);
};

export const isBusinessApplicationTerminalStatus = (
  status: string,
  orderProcessingStarted: boolean | undefined
) => {
  if (orderProcessingStarted) {
    return true;
  }
  return ['REQUEST_REFUSAL'].includes(status);
};

export const isBusinessRequestTerminalStatus = (
  status: string,
  orderProcessingStarted: boolean | undefined
) => {
  if (orderProcessingStarted) {
    return true;
  }
  return ['REQUEST_REFUSAL'].includes(status);
};

export const isContractorRequestTerminalStatus = (
  status: ContractorStatusCode,
  orderProcessingStarted: boolean | undefined
) => {
  if (orderProcessingStarted) {
    return true;
  }
  return [
    ContractorStatusCode.REQUEST_EXCLUSION,
    ContractorStatusCode.REQUEST_REFUSAL,
    ContractorStatusCode.SPECIFICATION_SIGNED_WITH_ANOTHER_CONTRACTOR,
  ].includes(status);
};
export const isContractorRequestTerminalStatusForKam = (
  status: ContractorRequestStatusForKam
) =>
  [
    ContractorRequestStatusForKam.REFUSED,
    ContractorRequestStatusForKam.EXCLUDED,
  ].includes(status);

export const isContractorRebiddingTerminalStatusForKam = (
  status: RebiddingContractorStatus
) =>
  [
    RebiddingContractorStatus.REFUSED,
    RebiddingContractorStatus.EXCLUDED,
  ].includes(status);

export const isOrderTerminalStatus = (status: string) => status === 'CLOSED';
