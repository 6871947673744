import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROLES } from 'src/constants';

type TInitialState = {
  currentRole: ROLES | null;
  isAuth?: boolean;
  isGuestAuth?: boolean;
};

const initialState: TInitialState = {
  currentRole: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth: (
      state,
      {
        payload,
      }: PayloadAction<{
        isAuth: boolean;
        currentRole: ROLES | null;
      }>
    ) => {
      state.isAuth = payload.isAuth;
      state.currentRole = payload.currentRole;
    },
    initGuestAuth: (
      state,
      {
        payload,
      }: PayloadAction<{ isGuestAuth: boolean; currentRole: ROLES | null }>
    ) => {
      state.isGuestAuth = payload.isGuestAuth;
      state.currentRole = payload.currentRole;
    },
    logout: (state) => {
      if (state.isGuestAuth) {
        state.isGuestAuth = false;
      } else {
        state.isAuth = false;
      }

      state.currentRole = null;
    },
    setCurrentRole: (state, { payload }: PayloadAction<ROLES | null>) => {
      state.currentRole = payload;
    },
  },
});

export const authReducer = authSlice.reducer;
export const { initAuth, initGuestAuth, logout, setCurrentRole } =
  authSlice.actions;
