import { TooltipProps } from '@mui/material';
import { FC, useEffect, useRef, useState, ReactNode } from 'react';
import { Tooltip, Typography, TypographyProps } from 'src/components/ui';

export const OverflowTooltip: FC<
  {
    text: string | ReactNode;
    tooltipProps?: Partial<Omit<TooltipProps, 'title'>>;
    isVerticalTooltip?: boolean;
  } & TypographyProps
> = ({ text, isVerticalTooltip = false, tooltipProps, ...props }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const textRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    setShowTooltip(
      !!textRef.current &&
        ((isVerticalTooltip &&
          textRef.current.scrollHeight > textRef.current.clientHeight) ||
          textRef.current.scrollWidth > textRef.current.clientWidth)
    );
  }, [text]);

  return (
    <Tooltip title={text} disableHoverListener={!showTooltip} {...tooltipProps}>
      <Typography {...props} ref={textRef} noWrap>
        {text}
      </Typography>
    </Tooltip>
  );
};
