import {
  StatusNoSign,
  StatusBlocked,
  SuccessNotifications as StatusSuccess,
} from 'src/assets/icons';
import { FunctionComponent, SVGProps } from 'react';

export enum ETagStatuses {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'ARCHIVED' = 'ARCHIVED',
}

export const TagStatus: Record<
  ETagStatuses,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  [ETagStatuses.ACTIVE]: StatusSuccess,
  [ETagStatuses.INACTIVE]: StatusNoSign,
  [ETagStatuses.ARCHIVED]: StatusBlocked,
} as const;

export const TagStatusTooltip: Record<ETagStatuses, string> = {
  [ETagStatuses.ACTIVE]: 'Активный',
  [ETagStatuses.ARCHIVED]: 'В архиве',
  [ETagStatuses.INACTIVE]: 'Неактивный',
} as const;
