import { ETypeOperation } from 'src/components/pages/admin/contract-templates/types';
import { IContractTemplate } from 'src/models';
import { apiBase } from '../api';
import {
  IGetContractTemplatesParams,
  TContractTemplatesResponse,
  TContractTemplateTypeOption,
} from './model';

const contractTemplatesApi = apiBase
  .enhanceEndpoints({ addTagTypes: ['getContractTemplates'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getContractTemplates: builder.query<
        TContractTemplatesResponse,
        IGetContractTemplatesParams
      >({
        query: (params) => ({
          url: 'contracts/api/v1/contract-template',
          method: 'GET',
          params,
        }),
        providesTags: ['getContractTemplates'],
      }),

      getActiveContractTemplates: builder.query<
        TContractTemplatesResponse,
        IGetContractTemplatesParams
      >({
        query: (params) => ({
          url: 'contracts/api/v1/contract-template/non-deleted',
          method: 'GET',
          params,
        }),
        providesTags: ['getContractTemplates'],
      }),

      createContractTemplate: builder.mutation<IContractTemplate, FormData>({
        query: (body) => ({
          url: 'contracts/api/v1/contract-template',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['getContractTemplates'],
      }),

      updateContractTemplate: builder.mutation<
        IContractTemplate,
        { body: FormData; id: number }
      >({
        query: ({ id, body }) => ({
          url: `contracts/api/v1/contract-template/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: (result, error, arg) =>
          error ? [] : ['getContractTemplates'],
      }),

      deleteContractTemplate: builder.mutation<IContractTemplate, number>({
        query: (id) => ({
          url: `contracts/api/v1/contract-template/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['getContractTemplates'],
      }),

      updateContractTemplateActiveStatuses: builder.mutation<
        void,
        {
          type: ETypeOperation;
          ids: number[];
        }
      >({
        query: ({ type, ids }) => ({
          url: 'contracts/api/v1/contract-template/is-active',
          method: 'POST',
          body: {
            ids,
            isActive: type === ETypeOperation.ACTIVATE,
          },
        }),
        invalidatesTags: ['getContractTemplates'],
      }),

      getContractTemplateTypeOptions: builder.query<
        TContractTemplateTypeOption[],
        void
      >({
        query: () => ({
          url: 'contracts/api/v1/contract-template/template-types',
          method: 'GET',
        }),
      }),
    }),
  });

export const {
  useGetContractTemplatesQuery,
  useGetActiveContractTemplatesQuery,
  useCreateContractTemplateMutation,
  useUpdateContractTemplateMutation,
  useDeleteContractTemplateMutation,
  useUpdateContractTemplateActiveStatusesMutation,
  useGetContractTemplateTypeOptionsQuery,
} = contractTemplatesApi;
