import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const SectionNewsList = lazy(
  () =>
    import(
      /* webpackChunkName: 'SectionNewsList' */
      /* webpackMode: "lazy" */
      `./section-news-list`
    )
);

export const LazySectionNewsList: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <SectionNewsList />
  </Suspense>
);
