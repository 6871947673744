import { MouseEvent, useState } from 'react';
import { Arrow, InfoHelpIcon } from 'src/assets/icons';
import { TableCell } from '@mui/material';
import { Box, Button, Tooltip } from 'src/components/ui';
import { Popover } from 'src/components/ui/index';
import { ColumnType, TSearchFilters } from 'src/components/ui/new-table/types';
import { FiltersPopup } from './components';

import styles from './th-cell.module.scss';

interface ThCellProps<RecordType> {
  column: ColumnType<RecordType>;
  onFilter?: (filters: TSearchFilters<RecordType>) => void;
}

export const ThCell = <RecordType,>({
  column,
  onFilter,
}: ThCellProps<RecordType>) => {
  let justifyCellContent;
  switch (column.setting?.align) {
    case 'center':
      justifyCellContent = 'center';
      break;
    case 'right':
      justifyCellContent = 'end';
      break;
    case 'left':
      justifyCellContent = 'start';
      break;
    default:
      justifyCellContent = 'start';
  }

  const { filters } = column;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableCell {...column.setting} className={styles.thCell}>
      {filters ? (
        <>
          <Button
            variant="text"
            className={styles.thCell}
            onClick={handleClick}
            endIcon={<Arrow />}>
            {column.title}
          </Button>
          <Popover
            id={String(column.key)}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <FiltersPopup<RecordType>
              column={column}
              filters={filters}
              onFilters={onFilter}
              onClose={setAnchorEl}
            />
          </Popover>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent={justifyCellContent}
          alignItems="center"
          gap="8px">
          <span className={styles.thCellContent}>{column.title}</span>

          {column.tooltip && (
            <Tooltip title={column.tooltip}>
              <InfoHelpIcon />
            </Tooltip>
          )}
        </Box>
      )}
    </TableCell>
  );
};
