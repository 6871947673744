import { TDocument } from 'src/models/document';
import { number } from 'src/utils/form-rules';

export interface IContractorCommercialOffer {
  contractorRequestId: number;
  commercialOfferId: number;
  number: number | null;
  cost: number | null;
  status: ContractorCommercialOfferStatus;
  statusByCost: ContractorCommercialOfferStatusByCost | null;
  sentAt: string | null;
  lastModifiedAt: string;
  createdAt: string;
  dueDateAt: string | null;
  supplyDuration: number;
  paymentConditions: string;
  comment: string;
  auctionCommercialOffer: boolean;
  rebiddingCommercialOffer: boolean;

  refusal: {
    id: number;
    createdAt: string;
    cause: string;
    comment: string;
  } | null;

  documents: {
    COMMERCIAL_OFFER: TDocument[];
  };
}

export enum ContractorCommercialOfferStatus {
  WINNER = 'WINNER',
  SENT = 'SENT',
  RESERVE = 'RESERVE',
  REFUSED = 'REFUSED',
  DRAFT = 'DRAFT',
}

export enum ContractorCommercialOfferStatusByCost {
  BEST_COST = 'BEST_COST',
  RESERVED_OFFER = 'RESERVED_OFFER',
}

export interface INoBestKp {
  businessRequestId: number;
  improvementEndDate: string;
  newCost: number;
  newDueDate: string;
  newConditions: string;
  comment?: string;
}
