import { FC, useState } from 'react';
import { Card } from 'src/components/ui';
import { TImplementedMessengerTypes } from 'src/api/communications/models';
import { Stack } from '@mui/material';
import { Header, CommunicationList } from './components';
import styles from './communication-chat.module.scss';

interface ICommunicationChatProps {
  applicationId: string;
}

export const CommunicationChat: FC<ICommunicationChatProps> = ({
  applicationId,
}) => {
  const [activeMessengerType, setActiveMessengerType] =
    useState<TImplementedMessengerTypes>('ALL');

  return (
    <Card noContentPadding noMargin className={styles.wrapper}>
      <Stack gap="4px">
        <Header
          value={activeMessengerType}
          onSelect={(value) => setActiveMessengerType(value)}
        />
        <CommunicationList
          applicationId={applicationId}
          messengerType={activeMessengerType}
        />
      </Stack>
    </Card>
  );
};
