import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Card, Checkbox, Form } from 'src/components/ui/index';
import { TVariant } from 'src/components/ui/filters/types';
import { ColumnType, TSearchFilters } from 'src/components/ui/new-table/types';
import styles from './filters-popup.module.scss';

type TProps<RecordType> = {
  column: ColumnType<RecordType>;
  filters: Array<TVariant>;
  onFilters?: (filters: TSearchFilters<RecordType>) => void;
  onClose?: (x: null | HTMLElement) => void;
};

const createListFilters = (
  filters: Array<TVariant>
): Record<string, boolean> => {
  const defaultValue = {} as Record<string, boolean>;
  filters.forEach((filter) => {
    defaultValue[filter.value] = filter.checked;
  });
  return defaultValue;
};

export const FiltersPopup = <RecordType,>(props: TProps<RecordType>) => {
  const { column, filters, onFilters, onClose } = props;

  const form = useForm<Record<string, boolean | string | number>>({
    defaultValues: createListFilters(filters),
  });

  const onSubmit: SubmitHandler<
    Record<string, boolean | string | number>
  > = async (selectFilters) => {
    try {
      const newFilters: Array<TVariant> = [];
      filters.forEach((filter) => {
        if (selectFilters[filter.value]) {
          newFilters.push({
            ...filter,
            checked: true,
          });
        }
      });
      await onFilters?.({
        [column.filterName || column.key]: newFilters,
      } as TSearchFilters<RecordType>);
      onClose?.(null);
    } catch {
      // TODO обработать ошибку
    }
  };

  useEffect(() => {
    const { filters: columnFilters } = column;
    if (columnFilters) {
      const defaultValue = createListFilters(columnFilters);
      const keyFilters = Object.keys(defaultValue);
      keyFilters.forEach((key) => {
        form.setValue(key, defaultValue[key]);
      });
    }
  }, [column, form]);

  return (
    <Card noMargin className={styles.wrapperFiltersPopup}>
      <Form
        form={form}
        onSubmit={onSubmit}
        viewMode={false}
        className={styles.form}
        footer={
          <Button onClick={onSubmit && form.handleSubmit(onSubmit)}>
            Выбрать
          </Button>
        }>
        {filters.map(({ value, label }) => (
          <Form.Item key={value} name={value} label={label} isControlLabel>
            <Checkbox />
          </Form.Item>
        ))}
      </Form>
    </Card>
  );
};
