import { format, addMinutes } from 'date-fns';

export const getServerDate = (
  date: number | string | Date,
  formatDate = "yyyy-MM-dd'T'HH:mm:ss",
  toUtc = false
) => {
  const newDate = new Date(date);
  if (toUtc) {
    return format(addMinutes(newDate, newDate.getTimezoneOffset()), formatDate);
  }

  return format(newDate, formatDate);
};
