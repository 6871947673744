import { Components } from '@mui/material/styles/components';

export const MuiDrawer: Components['MuiDrawer'] = {
  defaultProps: {
    anchor: 'right',
    PaperProps: {
      sx: {
        width: '584px',
      },
    },
    ModalProps: {
      BackdropProps: {
        sx: {
          backgroundColor: 'rgba(0, 47, 108, 0.2)',
        },
      },
    },
  },
};
