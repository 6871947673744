import { useEffect, useRef, useState } from 'react';

interface UseScrollTriggerArgs {
  triggerValue: number;
}

export const useScrollTrigger = ({ triggerValue }: UseScrollTriggerArgs) => {
  const [isActive, setIsActive] = useState(false);
  const valueRef = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < triggerValue && valueRef.current) {
        valueRef.current = false;
        setIsActive(false);
      }

      if (window.scrollY > triggerValue && !valueRef.current) {
        valueRef.current = true;
        setIsActive(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [triggerValue]);

  return {
    isActive,
  };
};
