import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const PageLayout = lazy(
  () =>
    import(
      /* webpackChunkName: 'PageLayout' */
      /* webpackPrefetch: true */
      /* webpackMode: "lazy" */
      `./page-layout`
    )
);

export const LazyPageLayout: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <PageLayout />
  </Suspense>
);
