import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const CustomerBusinessRequests = lazy(
  () =>
    import(
      /* webpackChunkName: 'CustomerBusinessRequests' */
      /* webpackMode: "lazy" */
      `./business-requests`
    )
);

export const LazyCustomerBusinessRequests: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <CustomerBusinessRequests />
  </Suspense>
);
