import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {
    size: 'medium',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: '#fff',

      '&.MuiInputBase-multiline': {
        padding: '12px 14px',
      },

      '& input': {
        padding: 0,
        height: '44px',
      },

      '& div.MuiSelect-outlined': {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        minHeight: '44px',
      },

      '&:not(.old-svg-size) .MuiInputAdornment-root svg': {
        color: theme.palette.primary.main,
        height: '24px',
        width: '24px',
      },

      ...(ownerState.size === 'small' && {
        '&.MuiInputBase-multiline': {
          padding: '8px 14px',
        },
        '& div.MuiSelect-outlined': {
          padding: 0,
          minHeight: '36px',
          display: 'flex',
          alignItems: 'center',
        },
        '& input': {
          height: '36px',
          padding: 0,
        },
        paddingTop: 0,
        paddingBottom: 0,
        '&:not(.old-svg-size) .MuiInputAdornment-root svg': {
          color: theme.palette.primary.main,
          height: '16px',
          width: '16px',
        },
      }),

      '& input::placeholder': {
        color: theme.palette.text.secondary,
        opacity: 1,
      },

      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.customTheme.borderGrayPrimary,
      },

      '&:hover:not(.Mui-error, .Mui-disabled) fieldset.MuiOutlinedInput-notchedOutline':
        {
          borderColor: theme.palette.secondary.light,
        },

      '&.Mui-focused:not(.Mui-error, .Mui-disabled) fieldset.MuiOutlinedInput-notchedOutline':
        {
          borderColor: theme.palette.primary.main,
        },

      '&.Mui-disabled': {
        backgroundColor: '#F4F4F4',
        '& .MuiInputAdornment-root svg': {
          color: theme.palette.customTheme.borderGrayPrimary,
        },
        '& input': {
          '-webkit-text-fill-color':
            theme.palette.customTheme.borderGrayPrimary,
        },
        '& input::placeholder': {
          color: theme.palette.customTheme.borderGrayPrimary,
          opacity: 1,
        },
        '& fieldset.MuiOutlinedInput-notchedOutline ': {
          borderColor: theme.palette.customTheme.borderGrayPrimary,
        },
      },
    }),
  },
};
