import { DIRECTION } from 'src/components/ui/filters/types';
import { TDocument } from 'src/models/document';

export interface IContract {
  contractTemplateId: null | number;
  isGeneratedFromTemplate: boolean;
  contractId: number;
  templateName?: string;
  status: ContractStatuses;
  contractType: 'CONTRACTOR' | 'CUSTOMER';
  signDate: string;
  validityEndDate: string;
  signatoryFio: string;
  basis: string;
  documents: {
    [key in ContractDocumentTypes]: (TDocument & {
      key: string;
      documentType: ContractDocumentTypes;
      entityType: 'CONTRACTOR' | 'CUSTOMER';
      entityId: number;
    })[];
  };
  organizationId: number;
  contractNumber?: string;
}

export enum ContractStatuses {
  DRAFT_UPLOAD = 'DRAFT_UPLOAD',
  DRAFT_APPROVAL = 'DRAFT_APPROVAL',
  FINAL_CONTRACT_CREATION = 'FINAL_CONTRACT_CREATION',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
}

export enum ContractDocumentTypes {
  DRAFT_CONTRACT = 'DRAFT_CONTRACT',
  SIGNED_CONTRACT_SCAN = 'SIGNED_CONTRACT_SCAN',
  FINAL_CONTRACT = 'FINAL_CONTRACT',
  DISAGREEMENT_PROTOCOL = 'DISAGREEMENT_PROTOCOL',
}

export enum ContractHistoryActionType {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  STAGED = 'STAGED',
  MODIFIED = 'MODIFIED',
}

export interface IContractHistory {
  id: number;
  documentName: string;
  createdAt: string;
  createdByFio: string;
  actionType: ContractHistoryActionType;
  actionAt: string;
  actionByFio: string;
  size: number;
  documentType: ContractDocumentTypes;
  actionByUserId: number;
  createdByUserId: number;
}

export interface IGetContractHistoryParams {
  id: number;
  direction: DIRECTION;
}
