import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Form,
  Autocomplete,
} from 'src/components/ui';
import { useForm } from 'react-hook-form';
import { required, notEmptyCategory } from 'src/utils/form-rules';
import { useCallback, useEffect, useState } from 'react';
import {
  useDeleteCategoryMutation,
  useGetNewsCategoriesMutation,
} from 'src/api/news';
import { TCategory } from 'src/models';
import { getErrorMessage } from 'src/store/utils';
import styles from './delete-category-modal.module.scss';
import { HeaderActions } from './components';

type TDeleteCategoryModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleUpdateCategoryList: () => void;
  item: TCategory;
};

type TDeleteFormInput = {
  category: {
    name: string;
    id: string;
  };
};

export const DeleteCategoryModal = ({
  handleUpdateCategoryList,
  open,
  setOpen,
  item,
}: TDeleteCategoryModalProps) => {
  const [categoryList, setCategoryList] = useState<
    Pick<TCategory, 'name' | 'id'>[]
  >([]);
  const [getCategoryList, { isLoading: categoryListLoading }] =
    useGetNewsCategoriesMutation();
  const [
    deleteCategory,
    { error: deleteCategoryError, isLoading: deleteCategoryLoading },
  ] = useDeleteCategoryMutation();

  useEffect(() => {
    getCategoryList({
      firstElementIndex: 0,
      lastElementIndex: 10000,
    })
      .unwrap()
      .then((res) => {
        setCategoryList(res.results.map(({ name, id }) => ({ name, id })));
      });
  }, [getCategoryList]);

  const form = useForm<TDeleteFormInput>({
    defaultValues: {
      category: {
        name: '',
        id: '',
      },
    },
  });
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async ({ category }: TDeleteFormInput) => {
    deleteCategory({
      currentId: item.id,
      newId: +category.id,
    })
      .unwrap()
      .then(() => {
        handleUpdateCategoryList();
      });
  };

  const handleDelete = () => {
    form.handleSubmit(handleSubmit)();
  };

  const fetchApiFilter = useCallback(
    (value: string) =>
      Promise.resolve(
        (value
          ? categoryList?.filter((element) =>
              element.name.toLowerCase().includes(value.toLowerCase())
            )
          : categoryList) || []
      ),
    [categoryList]
  );

  return (
    <Dialog open={open}>
      <DialogTitle className={styles.headerWrapper}>
        <Typography variant="h3">Удалить категорию</Typography>
        <HeaderActions
          onClose={handleClose}
          onDelete={handleDelete}
          disabled={categoryListLoading}
        />
      </DialogTitle>
      <DialogContent className={styles.wrapper}>
        <Form<TDeleteFormInput>
          form={form}
          footer={false}
          preloading={categoryListLoading}
          loading={deleteCategoryLoading}
          onSubmit={handleSubmit}>
          <Typography variant="body1" style={{ margin: '12px 0' }}>
            Для удаления, определите новую категорию для новостей из этой группы
          </Typography>
          <Form.Item
            name="category"
            label="Наименование категории"
            rules={{
              required,
              validate: notEmptyCategory,
            }}>
            <Autocomplete<Pick<TCategory, 'name' | 'id'>>
              getOptionLabel={(option) => option.name}
              options={categoryList}
              popupIcon
              fetchApi={fetchApiFilter}
              fetchOnEmptyInput
              placeholder="Выбрать"
            />
          </Form.Item>
        </Form>
        {deleteCategoryError && (
          <Typography color="error">
            {getErrorMessage(deleteCategoryError)}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
