import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { Box, Button, IconButton } from 'src/components/ui';
import { CloseIcon } from 'src/assets/icons';
import styles from './filter-popover.module.scss';
import { BlockFilter } from './components';
import { TConfigFilter, TVariant } from '../../types';
import { clearVariantsFilter, validateDateRange } from '../../utils';

type TProps = {
  config: Array<TConfigFilter>;
  onFilter?: (selectedFilters: Record<string, Array<TVariant>>) => void;
  onClose: () => void;
};

export const FiltersPopover: FC<TProps> = ({ config, onFilter, onClose }) => {
  const [selectedFilters, setSelectedFilters] = useState(config);
  const [isDirty, setIsDirty] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const [error, setError] = useState<Record<string, string>>({});

  const wrapperRef = useRef<HTMLDivElement>(null);
  const callbackRef = useRef<(() => void) | null>(null);

  const handleChangeFilters = useCallback(
    (filters: Record<string, Array<TVariant>>) => {
      if (clearAll) {
        setError({});
        setSelectedFilters(clearVariantsFilter);
      } else {
        setSelectedFilters((prev) =>
          prev.map((itemFilter) => {
            const selectedKey = Object.keys(filters);
            if (selectedKey.includes(itemFilter.name)) {
              return { ...itemFilter, variantList: filters[selectedKey[0]] };
            }

            return itemFilter;
          })
        );
      }
      setIsDirty(true);
      setClearAll(false);
    },
    [clearAll]
  );

  const onSubmit = () => {
    const newSelectedFilters: Record<string, Array<TVariant>> = {};
    let errors: Record<string, string> = {};

    selectedFilters.forEach((selectedFilter) => {
      if (selectedFilter.typeInput === 'date') {
        const { empty, ...errorsValidate } = validateDateRange(
          selectedFilter.variantList,
          selectedFilter.name
        );

        if (Object.keys(errorsValidate).length === 0 && !empty) {
          newSelectedFilters[selectedFilter.name] = selectedFilter.variantList;
        } else {
          errors = { ...errors, ...errorsValidate };
          setError({ ...errors, ...errorsValidate });
        }
      } else if (
        selectedFilter.variantList.find((variant) => variant.checked)
      ) {
        newSelectedFilters[selectedFilter.name] = selectedFilter.variantList;
      }
    });

    if (Object.keys(errors).length === 0) {
      onFilter?.(newSelectedFilters);
      setError({});
      onClose();
    }
  };

  useEffect(() => {
    setSelectedFilters(config);
  }, [config]);

  useEffect(() => {
    callbackRef.current = () => {
      onSubmit();
      onClose();
    };
  });

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        callbackRef.current &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !event.target.closest('div[role="dialog"]')
      ) {
        callbackRef.current();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px">
        <Typography variant="h3">Фильтр</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <div className={styles.filtersContainer}>
        {config.map((filter) => (
          <BlockFilter
            key={filter.name}
            filter={filter}
            onFilter={handleChangeFilters}
            isDirty={isDirty}
            clear={clearAll}
            error={error}
          />
        ))}
      </div>
      <Box textAlign="right">
        <Button
          variant="text"
          sx={{ mr: 3 }}
          onClick={() => {
            setClearAll(true);
          }}>
          Очистить все
        </Button>
        <Button onClick={onSubmit} disabled={!isDirty}>
          Применить
        </Button>
      </Box>
    </div>
  );
};
