import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  styleOverrides: {
    asterisk: ({ theme }) => ({
      color: theme.palette.error.main,
    }),
    root: ({ theme }) => ({
      color: theme.palette.text.primary,
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: 'normal',
      marginBottom: '4px',
    }),
  },
};
