import { useMemo } from 'react';
import { IAllCommunicationChainResult } from 'src/api/communications/models';
import { UNREAD_COUNTER_COLOR_MAP } from '../config';

export const useUnreadCounter = (
  notViewedCounter?: IAllCommunicationChainResult['notViewedCount']
) =>
  useMemo(() => {
    let color = UNREAD_COUNTER_COLOR_MAP.GRAY;

    const colorList = [];

    if (notViewedCounter?.emails?.color) {
      colorList.push(notViewedCounter.emails.color);
    }

    if (notViewedCounter?.calls?.color) {
      colorList.push(notViewedCounter.calls.color);
    }

    if (notViewedCounter?.messages?.color) {
      colorList.push(notViewedCounter.messages.color);
    }

    if (notViewedCounter?.notes?.color) {
      colorList.push(notViewedCounter.notes.color);
    }

    if (colorList.includes('RED')) {
      color = UNREAD_COUNTER_COLOR_MAP.RED;
    }

    if (colorList.includes('BLUE')) {
      color = UNREAD_COUNTER_COLOR_MAP.BLUE;
    }

    const count =
      (notViewedCounter?.emails?.count || 0) +
      (notViewedCounter?.calls?.count || 0) +
      (notViewedCounter?.notes?.count || 0) +
      (notViewedCounter?.messages?.count || 0);

    return [count, color];
  }, [notViewedCounter]);
