export const containsLetters = (value: string) => {
  if (!value) {
    return undefined;
  }
  if (/ {2,}/g.exec(value)) {
    return 'Введены недопустимые символы';
  }
  if (!/^([a-zа-яё])+/i.exec(value)) {
    return 'Значение должно начинаться с буквы!';
  }
  return undefined;
};
