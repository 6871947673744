import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
  canUpdateAuction: boolean;
}

const initialState: IInitialState = {
  canUpdateAuction: true,
};

const auctionSlice = createSlice({
  name: 'auction',
  initialState,

  reducers: {
    toggleCanUpdateAuction(state, action: PayloadAction<boolean>) {
      state.canUpdateAuction = action.payload;
    },
  },
});

export const { toggleCanUpdateAuction } = auctionSlice.actions;
export const auctionReducer = auctionSlice.reducer;
