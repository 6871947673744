import { ReactNode } from 'react';
import { ROLES } from 'src/constants';
import { IManagerBusinessRequest, KamStatusCode } from 'src/models';
import { KamStatusCodeOrderMap } from 'src/models/bid';
import { InvokeExpertStage } from 'src/models/bids-details';
import { AuthService } from 'src/services';

export const filterSpecificRoleTabs = (
  items: {
    path: string;
    title: ReactNode;
  }[],
  businessRequest: IManagerBusinessRequest
): { path: string; title: ReactNode }[] => {
  const filteredItems = [];
  const isLogistics = AuthService.currentRole! === ROLES.LOGISTIC;
  const rolesCanViewCommunicationTab = [
    ROLES.TECH_EXPERT,
    ROLES.BACK_OFFICE,
    ROLES.COMMERCIAL_DIRECTOR,
    ROLES.LOGISTIC,
    ROLES.PARTNER_RELATIONS_MANAGER,
    ROLES.GENERAL_MANAGER,
    ROLES.FINANCIAL_MANAGER,
    ROLES.OFFICE_MANAGER,
    ROLES.LAWYER,
  ];
  const isUserCanViewCommunication =
    AuthService.currentRole &&
    rolesCanViewCommunicationTab.includes(AuthService.currentRole);

  if (isUserCanViewCommunication) {
    filteredItems.push(
      items.find((item) => item.path.includes('communications'))!
    );
  }

  filteredItems.push(
    items.find(
      (item) =>
        item.path.includes('customer') &&
        !item.path.includes('customer-request')
    )!
  );

  filteredItems.push(
    items.find((item) => item.path.includes('customer-request'))!
  );

  if (
    isLogistics ||
    businessRequest.assignedSpecificRoles[
      InvokeExpertStage.QUOTATION_AGREEMENT
    ] ||
    KamStatusCodeOrderMap[businessRequest.status.code] >=
      KamStatusCodeOrderMap[KamStatusCode.COMMERCIAL_OFFER_RECEIVED]
  ) {
    filteredItems.push(
      items.find((item) => item.path.includes('commercial-offers'))!
    );
  }

  if (
    KamStatusCodeOrderMap[businessRequest.status.code] >=
      KamStatusCodeOrderMap[KamStatusCode.SPECIFICATION_SENT] ||
    isLogistics ||
    businessRequest.assignedSpecificRoles[
      InvokeExpertStage.SPECIFICATION_AGREEMENT
    ] ||
    businessRequest.assignedSpecificRoles[
      InvokeExpertStage.SPECIFICATION_NEW_REQUEST
    ] ||
    businessRequest.assignedSpecificRoles[
      InvokeExpertStage.SPECIFICATION_FORMING
    ] ||
    businessRequest.assignedSpecificRoles[
      InvokeExpertStage.SPECIFICATION_PROVIDED
    ]
  ) {
    filteredItems.push(
      items.find((item) => item.path.includes('specifications'))!
    );
  }

  if (
    [
      ROLES.BACK_OFFICE,
      ROLES.OFFICE_MANAGER,
      ROLES.FINANCIAL_MANAGER,
      ROLES.LAWYER,
    ].includes(AuthService.currentRole!)
  ) {
    filteredItems.push(items.find((item) => item.path.includes('payment'))!);
    filteredItems.push(items.find((item) => item.path.includes('shipping'))!);
  }

  if ([ROLES.LOGISTIC].includes(AuthService.currentRole!)) {
    filteredItems.push(items.find((item) => item.path.includes('logistics'))!);
  }

  return filteredItems;
};
