import { Components } from '@mui/material/styles/components';

export const MuiDialog: Components['MuiDialog'] = {
  defaultProps: {
    BackdropProps: {
      sx: {
        backgroundColor: 'rgba(0, 47, 108, 0.2)',
      },
    },
  },
  styleOverrides: {
    paper: {
      borderRadius: 16,
    },
  },
};
