import { FC } from 'react';
import { numericFormatter } from 'react-number-format';
import { Box } from 'src/components/ui';
import { DeliveryType, ICargoDescriptions } from 'src/models';

interface IViewCargoParametersProps {
  cargoParameters: ICargoDescriptions;
  emptyText?: string;
  isAuction?: boolean;
}

const formatNumber = (value?: number) => {
  if (!value) {
    return '';
  }

  return numericFormatter(value.toString(), {
    decimalScale: 2,
    fixedDecimalScale: false,
  });
};

export const ViewCargoParameters: FC<IViewCargoParametersProps> = ({
  cargoParameters,
  emptyText = 'без логистики',
  isAuction = false,
}) =>
  cargoParameters?.parameters?.deliveryType ? (
    <Box display="flex" flexDirection="column" maxWidth="258px">
      <span>
        {cargoParameters.parameters.deliveryType === DeliveryType.CARGO_SINGLE
          ? 'сборный груз, '
          : 'выделенный транспорт, '}
        {cargoParameters.parameters.deliveryType ===
          DeliveryType.CARGO_SINGLE &&
          (cargoParameters.parameters.singlePlace
            ? 'одно место'
            : 'несколько мест')}
        {cargoParameters.parameters.deliveryType === DeliveryType.TRUCK_FTL
          ? 'перевозка еврофурой'
          : null}
      </span>
      {cargoParameters.parameters.deliveryType ===
        DeliveryType.CARGO_SINGLE && (
        <>
          <span>
            {cargoParameters.parameters.singlePlace
              ? `габариты: ${cargoParameters.parameters.length} м х ${cargoParameters.parameters.width} м х ${cargoParameters.parameters.height} м`
              : `max габариты: ${cargoParameters.parameters.maxLength} м х ${cargoParameters.parameters.maxWidth} м х ${cargoParameters.parameters.maxHeight} м`}
          </span>

          {cargoParameters.parameters.singlePlace ? (
            <>
              <span>
                {`вес ${formatNumber(
                  cargoParameters.parameters.weight
                )} кг, объем ${formatNumber(
                  cargoParameters.parameters.volume
                )} м3`}
              </span>
              {isAuction && (
                <span>{`кол-во мест ${cargoParameters.parameters.places} шт.`}</span>
              )}
            </>
          ) : (
            <>
              <span>
                {`общий вес ${formatNumber(
                  cargoParameters.parameters.totalWeight
                )} кг`}
              </span>
              <span>
                {`общий объем ${formatNumber(
                  cargoParameters.parameters.totalVolume
                )} м3`}
              </span>
              <span>{`кол-во мест ${cargoParameters.parameters.places} шт.`}</span>
            </>
          )}
        </>
      )}
      {cargoParameters.parameters.deliveryType === DeliveryType.TRUCK_FTL && (
        <>
          <span>тип полуприцепа - тент:</span>
          <span>
            {cargoParameters.parameters.hardBoard ? 'жесткий борт' : null}
          </span>
          <span>
            {cargoParameters.parameters.detachableBeam
              ? 'съемная верхняя балка'
              : null}
          </span>
          <span>
            {cargoParameters.parameters.removableStand
              ? 'съемная боковая стойка'
              : null}
          </span>
          <span>{`кол-во машин ${cargoParameters.parameters.tentCount} шт.`}</span>
        </>
      )}
    </Box>
  ) : (
    <span>{emptyText}</span>
  );
