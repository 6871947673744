import { FC } from 'react';
import { FIELD_LENGTH } from 'src/constants';
import { maxLength } from 'src/utils/form-rules';
import { Form, InfoRow, OutlinedInput, Typography } from 'src/components/ui';
import { IComponentsProp } from '../../types';

export const Comment: FC<IComponentsProp> = ({ viewMode }) => (
  <Form.Item
    viewMode={viewMode}
    name="comment"
    label="Комментарий"
    rules={{
      maxLength: maxLength(FIELD_LENGTH.specifications.comment),
    }}
    renderView={(value) => (
      <InfoRow
        label="Комментарий"
        value={value && <Typography whiteSpace="pre-wrap">{value}</Typography>}
      />
    )}>
    <OutlinedInput
      multiline
      placeholder="Введите комментарий, который будут видеть исполнители"
      maxRows={7}
      maxLength={FIELD_LENGTH.specifications.comment}
    />
  </Form.Item>
);
