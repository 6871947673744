import { FC, SVGProps } from 'react';
import { Typography, Stack, SvgIcon } from 'src/components/ui';
import { Avatar } from 'src/components/widgets';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatTimeAgo } from 'src/components/widgets/notification-bubble-modal/utils';
import styles from './bubble-item.module.scss';

interface IBubbleItemProps {
  redirectUrl?: string;
  name?: string;
  date: string;
  title: any;
  subTitle?: any;
  userGroupColor?: string;
  badgeIcon?: FC<SVGProps<SVGSVGElement>>;
  avatarIcon?: any;
  text?: string;
  onClick?: () => void;
}

export const BubbleItem = ({
  redirectUrl,
  name,
  date,
  title,
  subTitle,
  userGroupColor,
  badgeIcon,
  avatarIcon,
  text,
  onClick,
}: IBubbleItemProps) => {
  const timeAgoValue = formatTimeAgo(date);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleOnClick = () => {
    onClick?.();

    if (!redirectUrl) {
      return;
    }

    navigate(redirectUrl, {
      state: {
        prevUrl: pathname,
      },
    });
  };

  return (
    <Stack
      direction="row"
      gap="12px"
      className={styles.wrapper}
      onClick={handleOnClick}>
      <div className={styles.avatarWrapper}>
        {(name || avatarIcon) && (
          <Avatar
            userFullName={name}
            borderColor={userGroupColor || '#0041A0'}
            icon={avatarIcon}
          />
        )}
        {badgeIcon && (
          <div className={styles.avatarBadge}>
            <SvgIcon
              width="16"
              height="16"
              color="#FFF"
              backgroundFill="#002F6C"
              icon={badgeIcon}
            />
          </div>
        )}
      </div>

      <Stack gap="2px" flexGrow={1}>
        <div className={styles.titleWrapper}>{title}</div>
        {subTitle}
        {text && (
          <Typography
            color="#292929"
            fontSize="12px"
            className={styles.textWrapper}>
            {text}
          </Typography>
        )}
        <Typography fontSize="12px" color="#7A8694">
          {timeAgoValue}
        </Typography>
      </Stack>
    </Stack>
  );
};
