import { FC } from 'react';
import { Skeleton } from 'src/components/ui';

interface IDialogSkeletonProps {
  width?: string;
  height?: string;
}

export const DialogSkeleton: FC<IDialogSkeletonProps> = ({
  width = '800px',
  height = '500px',
}) => <Skeleton width={width} height={height} />;
