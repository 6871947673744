import { FC, useEffect, useState } from 'react';
import { AddIcon, ListsGroup } from 'src/assets/icons';
import { Button, Box, Stack, Popover, Menu, MenuItem } from 'src/components/ui';
import {
  HintBar,
  HelpExpertStatuses,
  IHintBarProps,
} from 'src/components/ui/hint-bar';
import { HelpExpertBarStage } from 'src/components/widgets/help-expert-bar/types';
import {
  mapExpertInfoToHelpBarStatus,
  mapExpertInfoToString,
} from 'src/components/widgets/help-expert-bar/utils';
import { ROLES } from 'src/constants';
import {
  InvokeExpertStage,
  InvokeExpertStatus,
  ISpecificRole,
} from 'src/models/bids-details';

export interface IExpertVariant {
  label: string;
  role: ROLES;
  expertStatus?: InvokeExpertStatus;
  invokeExpertStage: InvokeExpertStage;
}

interface IHelpExpertBarProps extends Omit<IHintBarProps, 'after'> {
  expertVariants: IExpertVariant[];
  actionButtonLabel: string;
  onExpertChosen: (
    expertRole: ROLES,
    expertStage: InvokeExpertStage,
    expertStatus?: InvokeExpertStatus
  ) => void;
  invokedExperts?: ISpecificRole[];
  stage: HelpExpertBarStage;
}

export const HelpExpertBar: FC<IHelpExpertBarProps> = ({
  children,
  expertVariants,
  actionButtonLabel,
  onExpertChosen,
  invokedExperts,
  stage,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [statusesAnchorEl, setStatusesAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const [status, setStatus] = useState<HelpExpertStatuses | null>(null);
  const [helpBarContent, setHelpBarContent] = useState('');

  useEffect(() => {
    if (!invokedExperts || invokedExperts.length === 0) {
      setStatus(null);
      setHelpBarContent('');
      return;
    }

    if (invokedExperts?.length > 1) {
      let employee = 'сотрудника';

      if (invokedExperts.length > 4) {
        employee = 'сотрудников';
      }

      setHelpBarContent(`Подключено ${invokedExperts.length} ${employee}`);
      setStatus(HelpExpertStatuses.PENDING);
    }

    if (invokedExperts.length === 1) {
      setHelpBarContent(mapExpertInfoToString(invokedExperts[0], stage));
      setStatus(mapExpertInfoToHelpBarStatus(invokedExperts[0]));
    }
  }, [invokedExperts, invokedExperts?.length]);

  const open = Boolean(anchorEl);
  const openStatuses = Boolean(statusesAnchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenStatuses = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStatusesAnchorEl(event.currentTarget);
  };

  const handleCloseStatuses = () => {
    setStatusesAnchorEl(null);
  };

  return (
    <HintBar
      {...props}
      status={status}
      tooltip={
        !status &&
        'Вы можете подключить сотрудников для согласования или помощи по заполнению блока'
      }
      after={
        <>
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={3}>
            {invokedExperts && invokedExperts.length > 1 && (
              <>
                <Button
                  color="inherit"
                  variant="text"
                  startIcon={<ListsGroup />}
                  onClick={handleOpenStatuses}>
                  Посмотреть все статусы
                </Button>
                <Popover
                  anchorEl={statusesAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  onClose={handleCloseStatuses}
                  open={openStatuses}
                  PaperProps={{
                    sx: {
                      padding: '16px',
                      maxWidth: '768px',
                      width: '100%',
                    },
                  }}>
                  <Stack gap={1.5}>
                    {invokedExperts.map((expert, index) => (
                      <HelpExpertBar
                        onExpertChosen={() => {}}
                        expertVariants={[]}
                        invokedExperts={[expert]}
                        key={index}
                        actionButtonLabel={actionButtonLabel}
                        stage={stage}
                      />
                    ))}
                  </Stack>
                </Popover>
              </>
            )}
            {expertVariants.length !== 0 && (
              <Button
                variant="text"
                startIcon={<AddIcon />}
                onClick={handleOpenMenu}
                color="inherit">
                {actionButtonLabel}
              </Button>
            )}
            {/* {(status === 'REJECTED' || status === 'APPROVED') && ( */}
            {/*   <Button */}
            {/*     variant="text" */}
            {/*     startIcon={<Retry />} */}
            {/*     onClick={handleOpenMenu} */}
            {/*     color="inherit"> */}
            {/*     Отправить техэксперту повторно */}
            {/*   </Button> */}
            {/* )} */}
          </Box>

          <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
            {expertVariants.map((expert) => (
              <MenuItem
                key={expert.label}
                onClick={() => {
                  onExpertChosen(
                    expert.role,
                    expert.invokeExpertStage,
                    expert.expertStatus
                  );
                  handleCloseMenu();
                }}>
                {expert.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      }>
      {helpBarContent || children}
    </HintBar>
  );
};
