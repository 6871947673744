import { FC } from 'react';
import { ArrowDown } from 'src/assets/icons';
import { Box, Select, MenuItem } from 'src/components/ui';
import { TImplementedMessengerTypes } from 'src/api/communications/models';
import styles from './header.module.scss';

interface IHeaderProps {
  value: TImplementedMessengerTypes;
  onSelect: (value: TImplementedMessengerTypes) => void;
}

// TODO: Сделать правильней
const MESSENGER_TYPE_OPTIONS = [
  'ALL',
  'EMAIL',
  'TELEPHONY',
  'NOTE',
  'MESSAGE',
] as Array<TImplementedMessengerTypes>;

const MESSENGER_TYPE_TO_NAME_MAP: Record<TImplementedMessengerTypes, string> = {
  ALL: 'Все',
  EMAIL: 'Почта',
  TELEPHONY: 'Телефония',
  NOTE: 'Заметки',
  MESSAGE: 'Чат',
  STATUS_MESSAGE: 'Статус заявки',
};

export const Header: FC<IHeaderProps> = ({ value, onSelect }) => (
  <Box>
    <Select<TImplementedMessengerTypes>
      value={value}
      variant="standard"
      IconComponent={ArrowDown}
      classes={{
        icon: styles.arrow,
        select: styles.select,
      }}
      disableUnderline
      onChange={(e) =>
        onSelect?.(e.target.value as TImplementedMessengerTypes)
      }>
      {MESSENGER_TYPE_OPTIONS.map((item) => (
        <MenuItem value={item} key={item}>
          {MESSENGER_TYPE_TO_NAME_MAP[item as TImplementedMessengerTypes]}
        </MenuItem>
      ))}
    </Select>
  </Box>
);
