import { FC, lazy, Suspense } from 'react';
import { PageLoader } from 'src/components/ui';

const ManagerShipping = lazy(
  () =>
    import(
      /* webpackChunkName: 'ManagerShipping' */
      /* webpackMode: "lazy" */
      `./shipping`
    )
);

export const LazyManagerShipping: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ManagerShipping />
  </Suspense>
);
