import {
  IStorage,
  StorageService,
  TStorageItem,
} from 'src/services/storage-service';
import { ROLES } from 'src/constants';

enum AuthKeys {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  guestAuth = 'guestAuth',
  dataProcessingConfirm = 'dataProcessingConfirm',
  loginNextTryDate = 'loginNextTryDate',
  restorePasswordNextTryDate = 'restorePasswordNextTryDate',
  currentRole = 'currentRole',
}

export class AuthStorage extends StorageService implements IAuthStorage {
  keys = AuthKeys;

  get accessToken(): TStorageItem {
    return this.getItem(this.keys.accessToken);
  }

  get refreshToken(): TStorageItem {
    return this.getItem(this.keys.refreshToken);
  }

  get guestAuth() {
    return this.getItem<
      Record<string, { accessToken: string; currentRole: string }>
    >(this.keys.guestAuth);
  }

  get dataProcessingConfirm() {
    return this.getItem<boolean>(this.keys.dataProcessingConfirm);
  }

  get loginNextTryDate() {
    return this.getItem(this.keys.loginNextTryDate);
  }

  get restorePasswordNextTryDate() {
    return this.getItem(this.keys.restorePasswordNextTryDate);
  }

  get currentRole() {
    return this.getItem<ROLES>(this.keys.currentRole);
  }

  setLoginNextTryDate(date: Date) {
    this.setItem(this.keys.loginNextTryDate, date);
  }

  setRestorePasswordNextTryDate(date: Date) {
    this.setItem(this.keys.restorePasswordNextTryDate, date);
  }

  setCurrentRole(role: ROLES) {
    this.setItem(this.keys.currentRole, role);
  }

  setGuestCurrentRole(id: number, role: ROLES) {
    this.setItem(this.keys.guestAuth, {
      ...this.guestAuth,
      [id]: { ...this.guestAuth?.[id], currentRole: role },
    });
  }

  removeAuthData() {
    this.removeData([
      this.keys.accessToken,
      this.keys.refreshToken,
      this.keys.guestAuth,
    ]);
  }

  setGuestAuthData(id: number, guestToken: string) {
    this.setItem(this.keys.guestAuth, {
      ...this.guestAuth,
      [id]: { ...this.guestAuth?.[id], accessToken: guestToken },
    });
  }

  removeGuestAuthData(id: number) {
    this.setItem(
      this.keys.guestAuth,
      Object.entries(this.guestAuth || {}).reduce(
        (
          acc: Record<string, { accessToken: string; currentRole: string }>,
          [key, value]
        ) => {
          if (key !== id.toString()) {
            acc[key] = value;
          }

          return acc;
        },
        {}
      )
    );
  }

  removeFullGuestAuthData() {
    this.setItem(this.keys.guestAuth, {});
  }
}

export interface IAuthStorage extends IStorage {
  readonly accessToken: TStorageItem;
  readonly refreshToken: TStorageItem;
  readonly guestAuth: TStorageItem<
    Record<string, { accessToken: string; currentRole: string }>
  >;
  readonly dataProcessingConfirm: TStorageItem<boolean>;
  readonly loginNextTryDate: TStorageItem;
  readonly restorePasswordNextTryDate: TStorageItem;
  readonly currentRole: TStorageItem<ROLES>;
  setLoginNextTryDate(date: Date): void;
  setRestorePasswordNextTryDate(date: Date): void;
  setCurrentRole(role: ROLES): void;
  removeAuthData(): void;

  setGuestCurrentRole(id: number, role: ROLES): void;
  setGuestAuthData(id: number, guestToken: string): void;
  removeGuestAuthData(id: number): void;
  removeFullGuestAuthData(): void;
}
