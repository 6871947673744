import { ColumnsType } from 'src/components/ui/new-table/types';
import { RequestInfoColumn } from 'src/components/widgets/all-company-requests-list-panel/components/request-info-column';
import { TCompanyBusinessRequest } from 'src/models/bid';

export const columnsConfig: ColumnsType<
  TCompanyBusinessRequest & { key: number }
> = [
  {
    title: '',
    key: 'name',
    render: (item) => <RequestInfoColumn item={item} />,
    setting: {
      sx: {
        padding: '8px 12px !important',
      },
    },
  },
];
