import {
  Box,
  Drawer as MuiDrawer,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import { ReactNode } from 'react';
import { CloseIcon, FiltersBackArrow } from 'src/assets/icons';
import { IconButton } from 'src/components/ui';
import styles from './filters-drawer.module.scss';

type TFiltersDrawerProps = DrawerProps & {
  title?: ReactNode;
  close?: () => void;
  buttons: React.JSX.Element;
};

export const FiltersDrawer = ({
  title,
  close,
  children,
  buttons,
  ...props
}: TFiltersDrawerProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const handleTitleClick = () => {
    if (!isDesktop) {
      close?.();
    }
  };

  return (
    <MuiDrawer
      {...props}
      style={{ zIndex: 1300 }}
      PaperProps={{
        className: styles.paper,
      }}>
      <Stack direction="column" height="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          className={styles.header}>
          <Stack
            direction="row"
            gap="12px"
            flex="1"
            onClick={handleTitleClick}
            className={styles.titleWrapper}>
            <Box
              color="text.primary"
              display="flex"
              alignItems="center"
              className={styles.mobileCloseButton}>
              <FiltersBackArrow />
            </Box>
            <Typography fontWeight={500} fontSize="16px" lineHeight="20px">
              {title}
            </Typography>
          </Stack>
          <IconButton
            aria-label="close"
            color="white"
            size="small"
            className={styles.desktopCloseButton}
            onClick={close}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box className={styles.content}>{children}</Box>
        <Box
          sx={{
            background: '#fff',
            padding: '20px 28px 24px 28px',
            display: 'flex',
          }}>
          {buttons}
        </Box>
      </Stack>
    </MuiDrawer>
  );
};
