import { ICustomerSpecification } from 'src/models';
import { businessRequestApi } from '../business-request';

export const customerSpecificationApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerSpecifications: builder.query<
      ICustomerSpecification | undefined,
      number
    >({
      query: (businessApplicationId) => ({
        url: `business-requests/api/v1/customer/business-application/${businessApplicationId}/customer-specification`,
        method: 'GET',
        params: { statusNotIn: ['DELETED'] },
      }),

      transformResponse: (data: ICustomerSpecification[]) => data[0],
    }),
    signCustomerSpecification: builder.mutation<
      ICustomerSpecification,
      {
        specificationId: number;
        businessApplicationId: number;
      }
    >({
      query: ({ specificationId }) => ({
        url: `business-requests/api/v1/customer/customer-specification/${specificationId}/signed-scan`,
        method: 'PATCH',
      }),
      async onQueryStarted(
        { businessApplicationId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            customerSpecificationApi.util.updateQueryData(
              'getCustomerSpecifications',
              businessApplicationId,
              () => data
            )
          );
        } catch {
          // ignore
        }
      },
    }),
  }),
});

export const {
  useGetCustomerSpecificationsQuery,
  useSignCustomerSpecificationMutation,
} = customerSpecificationApi;
