import { TConfigFilter } from 'src/components/ui/filters/types';
import { ColumnsType } from 'src/components/ui/table/types';

export const initializeFilters = (
  filtersConfig: TConfigFilter[],
  initial: Record<string, any>
) =>
  filtersConfig.map((item) => {
    const initValues = initial[item.name as keyof typeof initial] as string[];

    if (!initValues || !Array.isArray(initValues)) return item;

    const newVariantList = item.variantList.map((variant) =>
      initValues.includes(variant.value)
        ? { ...variant, checked: true }
        : variant
    );

    return {
      ...item,
      variantList: newVariantList,
    };
  });

export const initializeColumnFilters = (
  filtersConfig: ColumnsType<any>,
  initial: Record<string, any>
) =>
  filtersConfig.map((item) => {
    const initValues = (initial[item.filterName as keyof typeof initial] ||
      initial[item.key as keyof typeof initial]) as string[];

    if (!initValues || !Array.isArray(initValues) || !item.filters) return item;

    const newVariantList = item.filters.map((variant) =>
      initValues.includes(variant.value)
        ? { ...variant, checked: true }
        : variant
    );

    return {
      ...item,
      filters: newVariantList,
    };
  });
