import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../store/utils';

const BUSINESS_PATH = 'business-events/api';

type BusinessEvent = {
  eventInitiatorUserId: number;
  userRole: string;
  eventSource: string;
  eventDateTime: string;
  templateType: string;
  messageParams: Record<string, string | number>;
};
export const businessEventApi = createApi({
  reducerPath: 'businessEventApi',
  baseQuery,
  endpoints: (builder) => ({
    addBusinessEvent: builder.mutation<void, BusinessEvent[]>({
      query: (data) => ({
        url: `${BUSINESS_PATH}/v1/business-events/records`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
export const { useAddBusinessEventMutation } = businessEventApi;
