import {
  ICommercialOfferRefusalCauses,
  IManagerContractorCommercialOffer,
  IManagerContractorCommercialOfferParams,
  IManagerCustomerCommercialOffer,
  ISetRefinedCommercialOfferProps,
  ManagerCustomerCommercialOfferStatus,
} from 'src/models';
import { DIRECTION } from 'src/components/ui/filters/types';
import { ICommercialOfferForImprovement } from 'src/models/bids-details';
import { managerContractorCommercialOffer } from '../../utils';
import { businessRequestApi } from '../business-request';

export interface ICustomerCommercialOfferSaveParams {
  businessApplicationId: number;
  costByContractor: number | null;
  costForCustomer: number | null;
  dueDateAt: string | null;
  processingDueDateAt: string | null;

  supplyDuration: number;
  paymentConditions: string;
  comment: string;
}

export const managerCommercialOfferApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getManagerContractorCommercialOffers: builder.query<
      IManagerContractorCommercialOffer[],
      {
        id: number;
        bestCostOnly: boolean;
      }
    >({
      query: ({ id, bestCostOnly }) => ({
        url: `business-requests/api/v1/manager/business-request/${id}/contractor-commercial-offer`,
        method: 'GET',
        params: { bestCostOnly },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getManagerContractorCommercialOffers', id: arg.id },
      ],
      transformResponse: (data: IManagerContractorCommercialOffer[]) =>
        managerContractorCommercialOffer(data),
    }),

    updateManagerContractorCommercialOffer: builder.mutation<
      IManagerContractorCommercialOffer,
      IManagerContractorCommercialOfferParams
    >({
      query: ({ commercialOfferId, ...body }) => ({
        url: `business-requests/api/v1/manager/contractor-commercial-offer/${commercialOfferId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['fieldHistoryLogs'],
    }),

    getCausesRefusedCommercialOffer: builder.query<
      ICommercialOfferRefusalCauses,
      {
        searchQueries: string[];

        sort: {
          field: string;
          order: DIRECTION;
        };
        paging: {
          page: number;
          limit: number;
        };
      }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/admin/contractor-commercial-offer-refusal-cause-by-manager/search',
        method: 'POST',
        body,
      }),
    }),

    setRefusedCommercialOffer: builder.mutation<
      {
        status: {
          code: string;
          createdAt: string;
          displayName: string;
        };
      },
      {
        commercialOfferId: number;
        businessRequestId: number;
        causeName: string;
        comment: string;
      }
    >({
      query: ({ commercialOfferId, ...body }) => ({
        url: `business-requests/api/v1/manager/refusal/contractor-commercial-offer/${commercialOfferId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              { type: 'getManagerRequest', id: arg.businessRequestId },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
              {
                type: 'getManagerContractorCommercialOffers',
                id: arg.businessRequestId,
              },
            ],
    }),

    setRefinedCommercialOffer: builder.mutation<
      unknown,
      ISetRefinedCommercialOfferProps
    >({
      query: ({ commercialOfferId, ...body }) => ({
        url: `business-requests/api/v1/manager/commercial-offer/${commercialOfferId}/refine`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerCustomerCommercialOffers',
                id: arg.businessApplicationId,
              },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    setRefinedSentCommercialOffer: builder.mutation<
      unknown,
      {
        commercialOfferId: number;
        businessRequestId: number;
        businessApplicationId: number;
      }
    >({
      query: ({ commercialOfferId }) => ({
        url: `business-requests/api/v1/manager/commercial-offer/${commercialOfferId}/commercial-offer-refine-send`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerCustomerCommercialOffers',
                id: arg.businessApplicationId,
              },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    setCommercialOfferResponseReceived: builder.mutation<
      unknown,
      {
        commercialOfferId: number;
        businessRequestId: number;
        businessApplicationId: number;
      }
    >({
      query: ({ commercialOfferId }) => ({
        url: `business-requests/api/v1/manager/commercial-offer/${commercialOfferId}/refine-response-accepted`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerCustomerCommercialOffers',
                id: arg.businessApplicationId,
              },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    setManagerContractorReturnCommercialOffer: builder.mutation<
      unknown,
      {
        commercialOfferId: number;
        businessRequestId: number;
      }
    >({
      query: ({ commercialOfferId }) => ({
        url: `business-requests/api/v1/manager/refusal/contractor-commercial-offer/${commercialOfferId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerContractorCommercialOffers',
                id: arg.businessRequestId,
              },
            ],
    }),

    getManagerCustomerCommercialOffers: builder.query<
      IManagerCustomerCommercialOffer[],
      {
        id: number;
        filter?: {
          status: ManagerCustomerCommercialOfferStatus[];
        };
      }
    >({
      query: ({ id, filter }) => ({
        url: `business-requests/api/v1/manager/business-application/${id}/commercial-offer-for-customer`,
        method: 'GET',
        params: filter,
      }),
      // transformResponse: (data: IManagerCustomerCommercialOffer[]) =>
      //   managerCustomerCommercialOfferSorting(data),
      providesTags: (result, error, arg) => [
        { type: 'getManagerCustomerCommercialOffers', id: arg.id },
        { type: 'getManagerCustomerCommercialOffers' },
      ],
    }),

    createManagerCustomerCommercialOffer: builder.mutation<
      IManagerCustomerCommercialOffer,
      ICustomerCommercialOfferSaveParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/commercial-offer-for-customer',
        method: 'POST',
        body,
      }),
    }),

    updateManagerCustomerCommercialOffer: builder.mutation<
      IManagerCustomerCommercialOffer,
      ICustomerCommercialOfferSaveParams & { commercialOfferId: number }
    >({
      query: ({ commercialOfferId, ...body }) => ({
        url: `business-requests/api/v1/manager/commercial-offer-for-customer/${commercialOfferId}`,
        method: 'PUT',
        body,
      }),
    }),

    createManagerCustomerCommercialOfferFromContractor: builder.mutation<
      IManagerCustomerCommercialOffer,
      ICustomerCommercialOfferSaveParams & {
        contractorCommercialOfferId: number;
      }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/commercial-offer-for-customer/from-contractor-commercial-offer',
        method: 'POST',
        body,
      }),
    }),

    sendManagerCustomerCommercialOffer: builder.mutation<
      IManagerCustomerCommercialOffer,
      { commercialOfferId: number; businessRequestId: number }
    >({
      query: ({ commercialOfferId }) => ({
        url: `business-requests/api/v1/manager/commercial-offer/${commercialOfferId}/for-customer`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    managerContractorCommercialOfferLogistics: builder.mutation<
      Omit<IManagerContractorCommercialOffer['shipping'], 'manualShippingCost'>,
      Omit<
        IManagerContractorCommercialOfferParams['shipping'],
        'shippingAddress' | 'manualShippingCost'
      > & { commercialOfferId: number }
    >({
      query: ({ commercialOfferId, ...body }) => ({
        url: `business-requests/api/v1/manager/contractor-commercial-offer/${commercialOfferId}/shipping`,
        method: 'PATCH',
        body,
      }),
    }),

    getManagerCommercialOffersForImprovement: builder.query<
      ICommercialOfferForImprovement[],
      {
        businessRequestId: number;
      }
    >({
      query: ({ businessRequestId }) => ({
        url: `business-requests/api/v1/manager/business-request/${businessRequestId}/commercial-offer-improvements`,
        method: 'GET',
      }),
      providesTags: () => [
        { type: 'getManagerCustomerCommercialOffersForImprovement' },
      ],
    }),
  }),
});

export const {
  useGetManagerContractorCommercialOffersQuery,
  useUpdateManagerContractorCommercialOfferMutation,
  useGetCausesRefusedCommercialOfferQuery,
  useSetRefusedCommercialOfferMutation,
  useSetManagerContractorReturnCommercialOfferMutation,
  useGetManagerCustomerCommercialOffersQuery,
  useCreateManagerCustomerCommercialOfferMutation,
  useUpdateManagerCustomerCommercialOfferMutation,
  useCreateManagerCustomerCommercialOfferFromContractorMutation,
  useSendManagerCustomerCommercialOfferMutation,
  useManagerContractorCommercialOfferLogisticsMutation,
  useSetRefinedCommercialOfferMutation,
  useSetRefinedSentCommercialOfferMutation,
  useSetCommercialOfferResponseReceivedMutation,
  useGetManagerCommercialOffersForImprovementQuery,
} = managerCommercialOfferApi;
