import { useEffect, useState } from 'react';
import { useLazyGetContactsQuery } from 'src/api/contacts';
import { TContact } from 'src/components/pages/company/contacts/types';
import { CompanyTypes, ICompany } from 'src/models';

export const useCheckIsContactPrimary = (
  contactData: TContact | undefined,
  companyData: ICompany | undefined,
  currentCompanyRole: CompanyTypes
): [boolean, boolean] => {
  const [isLoading, setIsLoading] = useState(true);
  const [isContactPrimary, setIsContactPrimary] = useState(false);

  const [getContacts] = useLazyGetContactsQuery();

  useEffect(() => {
    if (contactData && companyData) {
      if (companyData.roles.length > 1) {
        const anotherRole = companyData.roles.filter(
          (role) => role !== currentCompanyRole
        );

        getContacts({
          companyType: anotherRole[0],
          idCompany: companyData.id,
        })
          .unwrap()
          .then((contactsData) => {
            const sameContact = contactsData.find(
              (contact) => contact.email === contactData.email
            );

            if (sameContact && sameContact.contactType.includes('MAIN')) {
              setIsContactPrimary(true);
            }

            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    }
  }, [contactData, companyData]);

  return [isContactPrimary, isLoading];
};
