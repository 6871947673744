import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const CustomerRequest = lazy(
  () =>
    import(
      /* webpackChunkName: 'CustomerRequest' */
      /* webpackMode: "lazy" */
      `./request`
    )
);

export const LazyCustomerRequest: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <CustomerRequest />
  </Suspense>
);
