import { ButtonBase } from '@mui/material';
import React, { forwardRef } from 'react';
import { Arrow } from 'src/assets/icons';

export const TabScrollButton = forwardRef<any, any>((props, ref) => {
  const { direction, ...other } = props;
  return (
    <ButtonBase
      component="div"
      ref={ref}
      sx={{
        borderRadius: '8px',
        minHeight: '36px',
        minWidth: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: other.disabled ? '' : '#F3F6FB',
        marginRight: direction === 'left' ? '4px' : '',
        marginLeft: direction === 'right' ? '4px' : '',
      }}
      {...other}>
      {direction === 'left' ? (
        <Arrow color="#7A8694" />
      ) : (
        <div style={{ transform: 'rotate(180deg)' }}>
          <Arrow color="#7A8694" />
        </div>
      )}
    </ButtonBase>
  );
});
