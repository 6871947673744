import { FC } from 'react';
import { Box, Typography } from 'src/components/ui/index';
import { TConfigFilter, TVariant } from 'src/components/ui/filters/types';
import classNames from 'classnames';
import styles from './block-filter.module.scss';

type TProps = {
  filter: TConfigFilter;
  onFilter: (selectedFilters: Record<string, Array<TVariant>>) => void;
  isDirty: boolean;
  clear: boolean;
  error?: Record<string, string>;
};

export const BlockFilter: FC<TProps> = ({ filter, ...rest }) => {
  const Component = filter.render({ filter, ...rest });
  return (
    <Box
      className={classNames(
        styles.itemFilterContainer,
        filter.className && styles[filter.className]
      )}>
      <Typography variant="h4" fontWeight="500" marginBottom="15px">
        {filter.label}
      </Typography>
      {Component}
    </Box>
  );
};
