import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const AdminDigest = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminDigest' */
      /* webpackMode: "lazy" */
      `./digest`
    )
);

export const LazyDigest: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <AdminDigest />
  </Suspense>
);
