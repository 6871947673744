import { Components } from '@mui/material/styles/components';

export const MuiPaper: Components['MuiPaper'] = {
  styleOverrides: {
    root: {
      boxShadow:
        '0 4px 8px rgba(40, 48, 60, 0.04), 0 2px 20px rgba(40, 48, 60, 0.08), 0 1px 3px rgba(40, 48, 60, 0.06)',
    },
  },
};
