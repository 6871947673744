import { FC, useState } from 'react';
import { TVariantBusinessRequest } from 'src/models';
import { Button, ConfirmDialog, Typography } from 'src/components/ui';
import {
  useCloseRequestByManagerMutation,
  useGetReadinessQuery,
} from 'src/api/business-request/business-request';
import { getErrorMessage } from 'src/store/utils';

export const CloseRequest: FC<{
  businessRequest: TVariantBusinessRequest;
}> = ({ businessRequest }) => {
  const [showRefusalRejectModal, setShowRefusalRejectModal] = useState(false);
  const showCloseRequestButton =
    businessRequest.status &&
    ['IN_PRODUCTION', 'DELIVERED'].includes(businessRequest.status.code);

  const [closeRequest, { isLoading, error }] =
    useCloseRequestByManagerMutation();

  if (!showCloseRequestButton) {
    return null;
  }

  return (
    <div>
      <Button variant="text" onClick={() => setShowRefusalRejectModal(true)}>
        Заявка выполнена
      </Button>

      <ConfirmDialog
        open={showRefusalRejectModal}
        close={() => setShowRefusalRejectModal(false)}
        confirmText="Да, уверен"
        onConfirm={async () => {
          await closeRequest({
            id: businessRequest.businessApplicationId!,
            businessRequestId: businessRequest.businessRequestId!,
          })
            .unwrap()
            .then(() => setShowRefusalRejectModal(false))
            .catch(() => {});
        }}
        settingsBtn={{ loading: isLoading }}
        title="Вы уверены, что хотите закрыть заявку?">
        <Typography variant="h4">
          {`Для закрытия заявки: 
         - все отгрузки должны быть закрыты;
         - для всех счетов исполнителя и заказчика должны быть загружены ПП.`}
        </Typography>
        {error && (
          <Typography color="error">{getErrorMessage(error)}</Typography>
        )}
      </ConfirmDialog>
    </div>
  );
};
