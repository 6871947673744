import { useForm } from 'react-hook-form';
import {
  DialogTitle,
  DialogContent,
  Dialog,
  TDialogProps,
  OutlinedInput,
  FileUpload,
  Box,
  Stack,
  Button,
  FormLabel,
} from 'src/components/ui';
import { TFile } from 'src/components/ui/file-upload';
import { DocumentTypes } from 'src/models';
import { TSubcategory } from 'src/models/admin';

interface ICategoryForm {
  description: string;
  files: TFile[];
}

interface ICategoryDescriptionModalProps extends TDialogProps {
  subcategory: TSubcategory;
}

export const CategoryDescriptionModal = ({
  subcategory,
  close,
  ...props
}: ICategoryDescriptionModalProps) => {
  const form = useForm<ICategoryForm>({
    defaultValues: {
      description: '',
      files: [],
    },
  });

  const files = form.watch('files');

  const onRemoveFile = (id: number) => {
    console.log(id);
  };

  const onUploadFiles = (newFiles: TFile[]) => {
    console.log(newFiles);
    form.setValue('files', newFiles);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: '800px',
          width: '100%',
        },
      }}
      close={close}
      {...props}>
      <DialogTitle>Описание тега</DialogTitle>
      <DialogContent>
        <FormLabel
          sx={{
            mb: 0.5,
          }}>
          Описание
        </FormLabel>
        <OutlinedInput
          multiline
          placeholder="Введите описание, отражающее данный тег"
          minRows={4}
          maxRows={7}
          maxLength={500}
        />
        <Box mt={2}>
          <FileUpload
            imagesPreview
            value={files}
            onChange={onUploadFiles}
            documentType={DocumentTypes.BUSINESS_APPLICATION}
            helpText="3 файла до 10 Мб каждый"
            removeMode="soft"
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end" gap={2} mt={4}>
          <Button color="secondary" onClick={close}>
            Отмена
          </Button>
          <Button>Сохранить</Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
