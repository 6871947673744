import queryString from 'query-string';

export const openGuestPath = (userId: number) => {
  const search = window.location.search
    ? queryString.parse(window.location.search || '')
    : undefined;
  const obj = JSON.stringify({
    path: window.location.pathname,
    ...(!!search && { query: search }),
  });
  window.open(`${process.env.PUBLIC_URL}/u${userId}?from=${obj}`);
};
