import { FC, useState } from 'react';
import {
  useRestoreBusinessApplicationMutation,
  useRestoreContractorBusinessApplicationMutation,
} from 'src/api/business-request/business-request';

import { Reload } from 'src/assets/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InfoRow,
  Typography,
} from 'src/components/ui';
import {
  IContractorBusinessRequest,
  IStatusHistory,
  KamStatusCode,
  TVariantBusinessRequest,
} from 'src/models';
import { getErrorMessage } from 'src/store/utils';

import { formatServerDate } from 'src/utils';
import styles from './restore-request.module.scss';

export const RestoreRequest: FC<{
  businessRequest: TVariantBusinessRequest;
  statusHistory: IStatusHistory;
  contractorMode?: boolean;
}> = ({ businessRequest, statusHistory, contractorMode }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [restoreBusinessApplication, { isLoading, error }] =
    useRestoreBusinessApplicationMutation();

  const [
    restoreContractorBusinessApplication,
    { isLoading: isContractorRequestLoading, error: contractorRequestError },
  ] = useRestoreContractorBusinessApplicationMutation();

  const previousStatus =
    statusHistory.passedStatuses[statusHistory.passedStatuses.length - 2];
  const currentStatus =
    statusHistory.passedStatuses[statusHistory.passedStatuses.length - 1];

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleRestore = async () => {
    try {
      if (contractorMode) {
        await restoreContractorBusinessApplication({
          contractorRequestId: businessRequest.contractorRequestId!,
        }).unwrap();

        handleClose();
      } else {
        await restoreBusinessApplication({
          businessApplicationId: businessRequest.businessApplicationId!,
          businessRequestId: businessRequest.businessRequestId!,
        }).unwrap();

        handleClose();
      }
    } catch {
      // ignore
    }
  };

  if (
    contractorMode &&
    (businessRequest as IContractorBusinessRequest)?.businessRequestStatus
      ?.code === KamStatusCode.REQUEST_REFUSAL
  ) {
    return null;
  }

  if (currentStatus?.status.code !== 'REQUEST_REFUSAL') {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        color="success"
        className={styles.restoreButton}
        startIcon={<Reload />}
        onClick={() => setShowDialog(true)}>
        {contractorMode ? 'Вернуть в работу' : 'Вернуть заявку'}
      </Button>

      <Dialog
        PaperProps={{
          sx: {
            width: '600px',
          },
        }}
        open={showDialog}
        close={handleClose}>
        <DialogTitle>
          {contractorMode
            ? 'Вы уверены, что хотите вернуть заявку в работу?'
            : 'Вы уверены, что хотите вернуть заявку?'}
        </DialogTitle>

        <DialogContent>
          {!contractorMode && (
            <>
              <Typography variant="h4" mb="24px">
                Сразу после подтверждения заявка вернется в тот статус, который
                был до отмены
              </Typography>
              <InfoRow
                label="Статус заявки до отмены"
                value={previousStatus?.status.displayName}
              />
              <InfoRow
                label="Дата перехода в данный статус"
                value={
                  previousStatus &&
                  formatServerDate(
                    previousStatus.createdAt,
                    'dd.MM.yyyy HH:mm:ss'
                  )
                }
              />
            </>
          )}

          {contractorMode && (
            <>
              <InfoRow
                label="Дата отмены"
                value={formatServerDate(
                  businessRequest.refusal?.createdAt,
                  'dd.MM.yyyy HH:mm'
                )}
              />
              <InfoRow label="Причина" value={businessRequest.refusal?.cause} />
              <InfoRow
                label="Комментарий"
                value={businessRequest.refusal?.comment}
              />
            </>
          )}

          {contractorRequestError && (
            <Typography color="error">
              {getErrorMessage(
                contractorRequestError,
                'Произошла ошибка возврата заявки из отказа'
              )}
            </Typography>
          )}
          {error && (
            <Typography color="error">
              {getErrorMessage(
                error,
                'Произошла ошибка возврата заявки из отказа'
              )}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          {contractorMode && <Button onClick={handleClose}>Отмена</Button>}
          <Button
            loading={isLoading || isContractorRequestLoading}
            onClick={handleRestore}>
            {!contractorMode ? 'Вернуть заявку' : 'Подтвердить'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
