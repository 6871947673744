import { ReactElement, useState } from 'react';
import { Aim, CheckCircle20, Warning2, WarningSimple } from 'src/assets/icons';
import {
  Card,
  EmptyMessage,
  SvgIcon,
  Typography,
  Stack,
  Grid,
} from 'src/components/ui';
import { IKonturFocusData } from 'src/models';
import { FinanceAccordionItem, FinancialItem } from './components';
import { LineChart } from '../company-finance-charts';
import { TFinanceRowType } from '../company-finance-charts/types/finance-row-type';

export const CompanyFinancesInfoBlock = ({
  scoringInfo,
  infoBlockClassName,
}: {
  scoringInfo: IKonturFocusData;
  infoBlockClassName?: string;
}) => {
  const getIcon = (statusValue: string): ReactElement => {
    switch (statusValue) {
      case 'WARNING':
        return <SvgIcon icon={WarningSimple} strokeColor="#ff7706" />;

      case 'ERROR':
        return <SvgIcon icon={Warning2} />;

      case 'RECOMMENDATION':
        return <SvgIcon icon={Aim} />;

      default:
        return <SvgIcon icon={CheckCircle20} strokeColor="#13B81A" />;
    }
  };

  const [filterProps, setFilterProps] = useState<TFinanceRowType>('Выручка');
  const [activeItem, setActiveItem] = useState<number>(0);

  const isHaveFinanceInfo = !!scoringInfo?.financeInfo;

  const isWarnings = scoringInfo?.warnings && !!scoringInfo.warnings.length;

  const warnings = isWarnings
    ? scoringInfo?.warnings.filter(
        (warning) => warning.name !== 'Существенные факты'
      )
    : [];

  const criticalWarningFacts = scoringInfo?.warnings?.filter(
    (warning) => warning.name === 'Существенные факты'
  );

  const getItemDynamic = (value: string) => ({
    value: value ? Math.abs(+value).toString() : '0',
    isIncreasing: +value >= 0,
  });

  const items = [
    {
      title: 'Выручка',
      value: scoringInfo?.financeInfo?.revenue || '0',
      dynamic: getItemDynamic(scoringInfo?.financeInfo?.revenueDifference),
      filterProps: 'Выручка',
    },
    {
      title: 'Чистая прибыль',
      value: scoringInfo?.financeInfo?.netIncome || '0',
      dynamic: getItemDynamic(scoringInfo?.financeInfo?.netIncomeDifference),
      filterProps: 'Чистая прибыль (убыток)',
    },
    {
      title: 'Стоимость',
      value: scoringInfo?.financeInfo?.price || '0',
      dynamic: getItemDynamic(scoringInfo?.financeInfo?.priceDifference),
      filterProps: 'Капитал и резервы',
    },
    {
      title: 'Дебиторская задолженность',
      value: scoringInfo?.financeInfo?.accountReceivable || '0',
      dynamic: getItemDynamic(
        scoringInfo?.financeInfo?.accountReceivableDifference
      ),
    },
    {
      title: 'Кредиторская задолженность',
      value: scoringInfo?.financeInfo?.accountPayable || '0',
      dynamic: getItemDynamic(
        scoringInfo?.financeInfo?.accountPayableDifference
      ),
    },
  ];

  const handleItemClick = (item: any) => {
    if (item.filterProps) {
      setFilterProps(item.filterProps);
      setActiveItem(items.indexOf(item));
    }
  };

  const printFinanceContent = () => {
    if (!isHaveFinanceInfo) {
      return !isWarnings ? (
        <EmptyMessage title="Информация по финансам неизвестна">
          По указанному ИНН компании информация по финансам не найдена
        </EmptyMessage>
      ) : null;
    }
    return (
      <Card
        title={`Основные показатели деятельности за ${
          scoringInfo?.financeInfo?.year || 'Неизвестный'
        } год`}
        className={infoBlockClassName}>
        <Grid container spacing={1} columns={10}>
          {items.map((item, index) => (
            <Grid item xs={2} onClick={() => handleItemClick(item)}>
              <FinancialItem
                {...item}
                key={item.title}
                isRowDirection={false}
                isActive={
                  activeItem === index &&
                  !!scoringInfo?.accountingBlocks?.length
                }
              />
            </Grid>
          ))}
        </Grid>

        <LineChart filterProps={filterProps} scoringInfo={scoringInfo} />

        {criticalWarningFacts && criticalWarningFacts[0]?.items && (
          <Stack marginTop="24px">
            <Typography variant="h6" mb="16px">
              Обратите внимание
            </Typography>
            {criticalWarningFacts[0].items.map((item) => (
              <FinanceAccordionItem
                key={item.title}
                {...item}
                getIcon={getIcon}
              />
            ))}
          </Stack>
        )}
      </Card>
    );
  };

  return (
    <>
      {printFinanceContent()}
      {isWarnings &&
        warnings?.length > 0 &&
        warnings.map((warning) => (
          <Card
            key={warning.name}
            title={warning.name}
            className={infoBlockClassName}>
            {warning.items.map((item) => (
              <FinanceAccordionItem
                key={item.title}
                {...item}
                getIcon={getIcon}
              />
            ))}
          </Card>
        ))}
    </>
  );
};
