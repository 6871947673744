export class StorageService implements IStorage {
  keys: Record<string, string> = {};

  constructor(private storage = localStorage) {}

  setItem(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem<T = string>(key: string): TStorageItem<T> {
    const item = this.storage.getItem(key);

    try {
      return item && JSON.parse(item);
    } catch {
      return null;
    }
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  removeData(keys?: string[]): void {
    (keys ?? Object.keys(this.keys)).forEach((key) => this.removeItem(key));
  }

  setData(data: Record<string, any>): void {
    Object.keys(this.keys).forEach(
      (key) => data[key] !== undefined && this.setItem(key, data[key])
    );
  }
}

export type TStorageItem<T = string> = T | null;

export interface IStorage {
  clear(): void;
  getItem(key: string): TStorageItem;
  removeItem(key: string): void;
  setItem(key: string, value: any): void;
  setData(data: Record<string, any>): void;
  removeData(): void;
}
