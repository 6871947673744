import { useMemo } from 'react';
import {
  SetURLSearchParams,
  URLSearchParamsInit,
  useSearchParams,
} from 'react-router-dom';

export const useCustomSearchParams = (
  params?: URLSearchParamsInit
): [Record<string, string>, SetURLSearchParams] => {
  const [search, setSearch] = useSearchParams(params);
  const searchAsObject = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  );

  return [searchAsObject, setSearch];
};
