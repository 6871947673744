import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ManagerRebidding = lazy(
  () =>
    import(
      /* webpackChunkName: 'ManagerRebidding' */
      /* webpackMode: "lazy" */
      `./rebidding`
    )
);

export const LazyManagerRebidding: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ManagerRebidding />
  </Suspense>
);
