import { LineChart as XChart } from '@mui/x-charts';
import { IKonturFocusData } from 'src/models';
import { useEffect, useRef, useState } from 'react';
import { extractInfoFromExternalData } from './utils';

import styles from './line-chart.module.scss';
import { useLineChart } from './hooks';
import { Tooltip } from './tooltip';
import { TFinanceRowType } from '../types/finance-row-type';

export const LineChart = ({
  scoringInfo,
  filterProps = 'Выручка',
}: {
  scoringInfo: IKonturFocusData;
  filterProps?: TFinanceRowType;
}) => {
  const values = extractInfoFromExternalData(scoringInfo, filterProps);
  const { label, years, roundedValues, minYear } = useLineChart(values);
  const [chartWidth, setChartWidth] = useState(500);
  const formatter = Intl.NumberFormat('ru-RU', { notation: 'compact' });

  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef?.current) {
      setChartWidth(parentRef.current.clientWidth);
    }
  }, [parentRef?.current]);

  if (!values.length) {
    return null;
  }

  return years.length && roundedValues.length ? (
    <div ref={parentRef} className={styles.lineChart}>
      <XChart
        margin={{ left: 65 }}
        tooltip={{
          /* eslint-disable-next-line react/no-unstable-nested-components */
          axisContent: (props) => (
            <Tooltip {...props} values={roundedValues} label={label} />
          ),
        }}
        xAxis={[
          {
            dataKey: 'year',
            valueFormatter: (value) => value.toString(),
            min: minYear,
            tickInterval: years,
          },
        ]}
        yAxis={[
          {
            label,
            valueFormatter: (value) => formatter.format(value),
            slotProps: {
              axisLabel: {
                rotate: 'deg(90)',
              },
            },
          },
        ]}
        series={[
          {
            dataKey: 'value',
          },
        ]}
        dataset={roundedValues as any}
        width={chartWidth}
        height={360}
      />
    </div>
  ) : (
    <div />
  );
};
