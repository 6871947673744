import { FC } from 'react';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Chip, Typography, Tooltip, Box } from 'src/components/ui';

import styles from './tags-cell-gray.module.scss';

interface ITagsCellGrayProps {
  tags: string[];
  tooltipWidth?: number;
  tooltipHeight?: number;
  chipClassName?: string;
}

type TCustomWidthTooltipProps = Omit<ITagsCellGrayProps, 'tags'>;

const CustomWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'tooltipWidth' && prop !== 'tooltipHeight',
  }
)<TCustomWidthTooltipProps>(({ tooltipWidth, tooltipHeight }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: tooltipWidth,
    maxHeight: tooltipHeight,
    overflowY: 'auto',
  },
}));

export const TagsCellGray: FC<ITagsCellGrayProps> = ({
  tags,
  tooltipWidth = 550,
  tooltipHeight = 250,
  chipClassName,
}) => (
  <CustomWidthTooltip
    placement="bottom-start"
    tooltipWidth={tooltipWidth}
    tooltipHeight={tooltipHeight}
    title={
      <Box whiteSpace="pre-line">
        <Typography className={styles.subtagListTooltip}>
          {tags.map((tag, index) => (
            <Chip
              sx={{
                '& .MuiChip-label': {
                  paddingLeft: '5px',
                  paddingRight: '5px',
                },
              }}
              key={index}
              label={tag}
              className={styles.chipTooltip}
            />
          ))}
        </Typography>
      </Box>
    }>
    <Box className={styles.ellipsis}>
      <Box mt="8px" display="flex" flexWrap="nowrap">
        <Typography className={classNames(styles.tagsList, styles.ellipsis)}>
          {tags.map((tag, index) => (
            <Chip
              sx={{
                '& .MuiChip-label': {
                  paddingLeft: '5px',
                  paddingRight: '5px',
                },
              }}
              key={index}
              label={tag}
              className={classNames(styles.chip, chipClassName)}
            />
          ))}
        </Typography>
      </Box>
    </Box>
  </CustomWidthTooltip>
);
