import { FC, useEffect, useMemo } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { Box, Form, PasswordInput, Button } from 'src/components/ui';
import {
  maxLength,
  minLength,
  passwordExtended,
  required,
} from 'src/utils/form-rules';
import { FIELD_LENGTH } from 'src/constants';
import { PasswordHelperBlock } from 'src/components/widgets/change-password-form/components';
import { VALIDATION_KEY_TO_RULE_MAP } from './config';
import { TValidationKeys, TValidationKeyToValueMap } from './types';

export type TChangePasswordFormInput = {
  newPassword: string;
  newPasswordConfirm: string;
  oldPassword: string;
};

type FormProps = {
  isLoading?: boolean;
  checkOldPassword?: boolean;
  form: UseFormReturn<TChangePasswordFormInput>;
  onSubmit: SubmitHandler<TChangePasswordFormInput>;
};

export const ChangePasswordForm: FC<FormProps> = ({
  form,
  onSubmit,
  isLoading,
  checkOldPassword,
}) => {
  const submitHandler: SubmitHandler<TChangePasswordFormInput> = (data) => {
    if (
      checkOldPassword &&
      data.oldPassword &&
      data.oldPassword === data.newPassword
    ) {
      form.setError('oldPassword', { message: '' });
      form.setError('newPassword', {
        message: 'Новый пароль и старый пароль совпадают',
      });

      return;
    }

    onSubmit(data);
  };

  const newPasswordValue = form.watch('newPassword');
  const newPasswordConfirmValue = form.watch('newPasswordConfirm');

  useEffect(() => {
    if (!newPasswordValue || !newPasswordConfirmValue) {
      form.clearErrors('newPasswordConfirm');
    }

    if (newPasswordValue && newPasswordValue === newPasswordConfirmValue) {
      form.clearErrors('newPasswordConfirm');
    }

    if (
      newPasswordValue &&
      newPasswordConfirmValue &&
      newPasswordValue !== newPasswordConfirmValue
    ) {
      form.setError('newPasswordConfirm', {
        message: 'Пароли не совпадают',
      });
    }
  }, [form, newPasswordValue, newPasswordConfirmValue]);

  const validationKeyToValueMap = useMemo(() => {
    const map = {} as TValidationKeyToValueMap;

    Object.keys(VALIDATION_KEY_TO_RULE_MAP).forEach((key) => {
      const currentRule = VALIDATION_KEY_TO_RULE_MAP[key as TValidationKeys];

      map[key as TValidationKeys] = currentRule(newPasswordValue);
    });

    return map;
  }, [newPasswordValue]);

  return (
    <Form
      form={form}
      footer={false}
      loading={checkOldPassword && isLoading}
      autoFocusNext>
      {checkOldPassword && (
        <Form.Item
          name="oldPassword"
          label="Старый пароль"
          rules={{
            required,
            minLength: minLength(FIELD_LENGTH.password[0]),
            maxLength: maxLength(FIELD_LENGTH.password[1]),
          }}>
          <PasswordInput />
        </Form.Item>
      )}

      <Box position="relative">
        <Form.Item
          name="newPassword"
          label="Новый пароль"
          rules={{
            required,
            validate: passwordExtended,
            minLength: minLength(FIELD_LENGTH.password[0]),
            maxLength: maxLength(FIELD_LENGTH.password[1]),
          }}>
          <PasswordInput />
        </Form.Item>

        <Box mt="24px">
          <PasswordHelperBlock
            validationKeyToValueMap={validationKeyToValueMap}
          />
        </Box>
      </Box>

      <Box mt="12px">
        <Form.Item
          name="newPasswordConfirm"
          label="Подтверждение пароля"
          rules={{
            required,
            validate: (value) =>
              value === newPasswordValue || 'Пароли не совпадают',
          }}>
          <PasswordInput />
        </Form.Item>
      </Box>

      <Box mt="16px">
        <Button
          fullWidth={!checkOldPassword}
          onClick={form.handleSubmit(submitHandler)}
          loading={isLoading}
          disabled={!form.formState.isValid}>
          Сменить пароль
        </Button>
      </Box>
    </Form>
  );
};
