import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/utils/base-query';
import { authApi } from 'src/api/auth';
import { logout } from 'src/store/auth';
import { AuthService } from 'src/services';
import { notificationsSocket, socket } from 'src/utils';

export const baseQueryWithReauth: ReturnType<typeof fetchBaseQuery> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 401) {
    const prevActiveRole = AuthService.currentRole;
    let refreshResult;

    if (AuthService.isGuestAuth) {
      refreshResult = await api.dispatch(
        authApi.endpoints.loginAsGuest.initiate({
          onBehalfUserId: AuthService.userId,
        })
      );

      if ('data' in refreshResult) {
        AuthService.loginAsGuest(
          AuthService.userId,
          refreshResult.data.guestToken
        );
      }
    } else if (AuthService.refreshToken) {
      refreshResult = await api.dispatch(
        authApi.endpoints.refreshToken.initiate({
          refreshToken: AuthService.refreshToken,
        })
      );

      if ('data' in refreshResult) {
        AuthService.login(refreshResult.data);
      }
    }

    if (
      refreshResult &&
      'data' in refreshResult &&
      AuthService.currentRole === prevActiveRole
    ) {
      result = await baseQuery(args, api, extraOptions);
    } else {
      await api.dispatch(authApi.endpoints.logout.initiate());
      AuthService.logout();
      api.dispatch(logout());
      socket.disconnect();
      notificationsSocket.disconnect();
    }
  }

  return result;
};
