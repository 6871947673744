import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  InfoRow,
  OutlinedInput,
  Typography,
} from 'src/components/ui';
import { useForm } from 'react-hook-form';
import { maxLength, required } from 'src/utils/form-rules';

import { FIELD_LENGTH } from 'src/constants';
import { AttachFiles } from 'src/components/widgets/attach-files';
import { DocumentEntityTypes, DocumentTypes, TCategory } from 'src/models';
import { TFile } from 'src/components/ui/file-upload';
import {
  useCreateCategoryMutation,
  useUpdateCategoryImageMutation,
  useUpdateCategoryMutation,
} from 'src/api/news';
import { getErrorMessage } from 'src/store/utils';
import { useState } from 'react';
import {
  createRequestBodyWithFile,
  createUpdateRequestFileBody,
  isImageResolutionValid,
} from '../../utils';
import { PublishStatusSelect } from './components';
import styles from './category-modal.module.scss';
import {
  EPublishedStatus,
  EPublishedStatusName,
} from '../../config/news-types';

type TCategoryModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleUpdateCategoryList: () => void;
  item?: TCategory;
};

type TCategoryFormInput = {
  name: string;
  publishStatus: {
    name: string;
    value: EPublishedStatus;
  };
  documents: TFile[];
};

export const CategoryModal = ({
  open,
  setOpen,
  handleUpdateCategoryList,
  item,
}: TCategoryModalProps) => {
  const { categoryForm } = FIELD_LENGTH;
  const [
    createCategory,
    { error: createCategoryError, isLoading: createCategoryLoading },
  ] = useCreateCategoryMutation();
  const [
    updateCategory,
    { error: updateCategoryError, isLoading: updateCategoryLoading },
  ] = useUpdateCategoryMutation();
  const [
    updateCategoryImage,
    { error: updateCategoryImageError, isLoading: updateCategoryImageLoading },
  ] = useUpdateCategoryImageMutation();
  const [isImageValid, setImageValid] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleClearImageError = () => {
    setImageValid(true);
  };

  const form = useForm<TCategoryFormInput>({
    defaultValues: {
      name: item?.name || '',
      publishStatus: {
        name: item?.publishStatus
          ? EPublishedStatusName[item.publishStatus]
          : '',
        value: item?.publishStatus || EPublishedStatus.PUBLISHED,
      },
      documents: item?.image
        ? [
            {
              sizeBytes: item.image.size,
              name: item.image.documentName,
              id: item.imageId,
              createdAt: '',
              createdByUser: {
                firstName: '',
                lastName: '',
                middleName: '',
              },
            },
          ]
        : [],
    },
  });

  const handleSubmit = ({
    documents,
    name,
    publishStatus: { value },
  }: TCategoryFormInput) => {
    const requestBody = {
      name,
      publishStatus: value,
    };

    if (!item) {
      isImageResolutionValid(documents[0].file!).then((isImageCorrect) => {
        if (isImageCorrect) {
          createCategory(
            createRequestBodyWithFile('dto', requestBody, documents)
          )
            .unwrap()
            .then(() => {
              setTimeout(() => {
                setOpen(false);
              }, 500);
              handleUpdateCategoryList();
            });
        } else {
          setImageValid(false);
        }
      });
    } else if (documents.length) {
      if ('file' in documents[0]) {
        isImageResolutionValid(documents[0].file!).then(
          (isUpdateImageCorrect) => {
            if (isUpdateImageCorrect) {
              updateCategory({ id: item.id, ...requestBody })
                .unwrap()
                .then(() => {
                  updateCategoryImage({
                    id: item.id,
                    file: createUpdateRequestFileBody(documents),
                  })
                    .unwrap()
                    .then(() => {
                      handleUpdateCategoryList();
                    });
                });
            } else {
              setImageValid(false);
            }
          }
        );
      } else {
        updateCategory({ id: item.id, ...requestBody })
          .unwrap()
          .then(() => {
            handleUpdateCategoryList();
          });
      }
    } else {
      updateCategory({ id: item.id, ...requestBody })
        .unwrap()
        .then(() => {
          handleUpdateCategoryList();
        });
    }
  };

  return (
    <Dialog open={open} close={handleClose}>
      <DialogTitle>{`${
        item ? 'Редактировать' : 'Добавить'
      } категорию`}</DialogTitle>
      <DialogContent className={styles.wrapper}>
        <Form
          loading={
            createCategoryLoading ||
            updateCategoryLoading ||
            updateCategoryImageLoading
          }
          form={form}
          onSubmit={handleSubmit}
          onCancel={() => setOpen(false)}>
          <Form.Item
            name="name"
            label="Наименование категории"
            required
            rules={{
              required,
              maxLength: maxLength(categoryForm.name),
            }}
            renderView={(value) => (
              <InfoRow
                label="Введите наименование категории"
                value={<Typography whiteSpace="pre-wrap">{value}</Typography>}
              />
            )}>
            <OutlinedInput
              placeholder="Введите наименование категории"
              maxLength={categoryForm.name}
            />
          </Form.Item>
          <Box maxWidth="630px">
            <AttachFiles
              rules={{ required: !item && required }}
              onChange={handleClearImageError}
              documentOptions={{
                entityType: DocumentEntityTypes.NEWS_POSTS,
                documentType: DocumentTypes.NEWS_POSTS,
                entityId: 1,
              }}
              isEditMode
              formItemName="documents"
              uploadFilesTitle="Прикрепите основное изображение категории"
              showDownloadButton={false}
            />
            {!isImageValid && (
              <Typography color="error">
                Разрешение изображения не должно превышать 1920х1080
              </Typography>
            )}
          </Box>
          <PublishStatusSelect />
        </Form>
        {(createCategoryError ||
          updateCategoryError ||
          updateCategoryImageError) && (
          <Typography color="error">
            {getErrorMessage(
              createCategoryError ||
                updateCategoryError ||
                updateCategoryImageError
            )}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
