import {
  IManagerBusinessRequest,
  IManagerContractorCommercialOffer,
  ManagerContractorCommercialOfferStatus,
} from 'src/models';
import { isRequestRefusalByContractor } from 'src/utils/business-request';

export enum RefusedPoint {
  CONTRACTOR_REQUEST_EXCLUSION = 'CONTRACTOR_REQUEST_EXCLUSION',
  CONTRACTOR_REQUEST_REFUSAL = 'CONTRACTOR_REQUEST_REFUSAL',
  BUSINESS_REQUEST_REFUSAL = 'BUSINESS_REQUEST_REFUSAL',
  COMMERCIAL_OFFER_REFUSAL = 'COMMERCIAL_OFFER_REFUSAL',
}

export const checkRefused = (
  data: IManagerContractorCommercialOffer,
  businessRequest?: IManagerBusinessRequest
) => {
  const { contractorRequestData, refusal, status } = data;

  if (status === ManagerContractorCommercialOfferStatus.REFUSED) {
    return { point: RefusedPoint.COMMERCIAL_OFFER_REFUSAL, ...refusal };
  }

  if (contractorRequestData?.exclusion) {
    return {
      point: RefusedPoint.CONTRACTOR_REQUEST_EXCLUSION,
      comment: '',
      ...contractorRequestData.exclusion,
    };
  }

  if (contractorRequestData?.refusal) {
    return !businessRequest ||
      isRequestRefusalByContractor(businessRequest.status.code)
      ? {
          point: RefusedPoint.CONTRACTOR_REQUEST_REFUSAL,
          ...contractorRequestData.refusal,
        }
      : {
          point: RefusedPoint.BUSINESS_REQUEST_REFUSAL,
          ...businessRequest.refusal,
        };
  }

  return false;
};

export type TCheckRefusedResult = ReturnType<typeof checkRefused>;
