import { TFilterListMap } from 'src/components/ui/new-filters/types';
import { AllCompanyRequestsFilterKeys } from 'src/components/widgets/all-company-requests-list/types/all-company-requests-filter-keys';
import { AllCompanyRequestsFiltersData } from 'src/components/widgets/all-company-requests-list/types/all-company-requests-filters-data';
import {
  DatePickerForm,
  RangeForm,
  CheckboxForm,
} from 'src/components/ui/new-filters/components/filters-popover/components';
import { RequestStatusFilters } from 'src/components/pages/business-requests/manager/config/request-status-filters';

export const getFiltersList = (
  variantsData?: AllCompanyRequestsFiltersData
): TFilterListMap<AllCompanyRequestsFilterKeys> => ({
  [AllCompanyRequestsFilterKeys.KAM_ID]: {
    key: AllCompanyRequestsFilterKeys.KAM_ID,
    label: 'КАМ',
    filterFormType: 'array',
    variantsList: variantsData?.kamVariants || [],
    render: (props) => <CheckboxForm {...props} withSearch />,
  },
  [AllCompanyRequestsFilterKeys.BUDGET]: {
    key: AllCompanyRequestsFilterKeys.BUDGET,
    label: 'Бюджет',
    filterFormType: 'range',
    render: (props) => <RangeForm {...props} />,
  },
  [AllCompanyRequestsFilterKeys.START_DATE]: {
    key: AllCompanyRequestsFilterKeys.START_DATE,
    label: 'Дата создания',
    filterFormType: 'date',
    render: (props) => <DatePickerForm {...props} />,
  },
  [AllCompanyRequestsFilterKeys.STATUS]: {
    key: AllCompanyRequestsFilterKeys.STATUS,
    label: 'Статус',
    filterFormType: 'array',
    variantsList: RequestStatusFilters,
    render: (props) => (
      <CheckboxForm
        {...props}
        placeholder="Поиск статуса"
        withSearch
        itemHeight={30}
      />
    ),
  },
});
