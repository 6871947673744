export const word = (value: string) => {
  if (!value) {
    return undefined;
  }
  if (
    !/^$|^ *(?!.*[,])[a-zA-Zа-яёА-ЯЁ]+ *$|^ *([^,])[a-zA-Zа-яёА-ЯЁ]+(?:[- `][a-zA-Zа-яёА-ЯЁ]+)+ *$/.exec(
      value
    )
  ) {
    return 'Введены недопустимые символы';
  }

  return undefined;
};
