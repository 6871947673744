import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  EmailIncoming,
  EmailOutgoing,
  CallIncoming,
  CallOutgoing,
  MessageOut,
} from 'src/assets/icons';
import { Typography, Stack, SvgIcon } from 'src/components/ui';
import {
  IEmailMessage,
  INote,
  ITelephonyCall,
  IMessage,
  IStatus,
} from 'src/models/communications';
import { format } from 'date-fns';
import {
  isCallItem,
  isEmailItem,
  isMessageItem,
} from 'src/components/pages/communications/utils';
import styles from './communication-list-item.module.scss';

interface ICommunicationListItemProps {
  item: IEmailMessage | ITelephonyCall | INote | IMessage | IStatus;
}

interface IItemData {
  name: string;
  date: string;
  text: string;
  icon: ReactElement;
}

export const CommunicationListItem = ({
  item,
}: ICommunicationListItemProps) => {
  const [itemData, setItemData] = useState<IItemData | null>(null);

  const getEmailContactName = useCallback((message: IEmailMessage) => {
    let result = '';

    if (!message.from) {
      result = message.to[0].name || message.to[0].login;
    } else if (!message.to) {
      result = message.from.name || message.from.login;
    } else {
      const sender = message.from;
      const recipient = message.to[0];

      result =
        sender.login === message.owner.login
          ? recipient.name || recipient.login
          : sender.name || sender.login;
    }

    return result;
  }, []);

  const getTelephonyText = useCallback((call: ITelephonyCall) => {
    let callType;

    switch (call?.srcType) {
      case 'OUTGOING':
        callType = 'Исходящий звонок';
        break;
      case 'INCOMING':
        callType = 'Входящий звонок';
        break;
      default:
        callType = 'Неизвестно';
        break;
    }

    return call.status === 'ANSWER'
      ? `${callType}. Разговор состоялся.`
      : `${callType}. Разговор не состоялся.`;
  }, []);

  useEffect(() => {
    if (isEmailItem(item)) {
      setItemData({
        name: getEmailContactName(item),
        date: format(new Date(item.sortDate), 'HH:mm'),
        text: item.text,
        icon:
          item.emailType === 'INBOX' ? <EmailIncoming /> : <EmailOutgoing />,
      });

      return;
    }

    if (isCallItem(item)) {
      setItemData({
        name: item.userDto.name,
        date: format(new Date(item.sortDate), 'HH:mm'),
        text: getTelephonyText(item),
        icon: (
          <SvgIcon
            icon={item.srcType === 'INCOMING' ? CallIncoming : CallOutgoing}
            color="#13B81A"
          />
        ),
      });
    }

    if (isMessageItem(item)) {
      setItemData({
        name: item.owner.name,
        date: format(new Date(item.sortDate), 'HH:mm'),
        text: item.text,
        icon: <MessageOut />,
      });
    }
  }, [item, getEmailContactName, getTelephonyText]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap="12px"
      className={styles.wrapper}>
      {itemData?.icon}
      <Stack flexGrow="1" gap="4px" sx={{ overflow: 'hidden' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="body2" color="#6A6A6A">
            {itemData?.name || ''}
          </Typography>
          <Typography variant="body2" color="#6A6A6A">
            {itemData?.date || ''}
          </Typography>
        </Stack>
        <Typography color="#292929" className={styles.text}>
          {itemData?.text || ''}
        </Typography>
      </Stack>
    </Stack>
  );
};
