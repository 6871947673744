import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';

export enum EPredicates {
  MORE_OR_EQUAL_THAN = 'MORE_OR_EQUAL_THAN',
  LESS_THAN = 'LESS_THAN',
}

export type TShippingCostCalsulationRule = {
  ruleId: number;
  comparedValue: number;
  predicate: EPredicates;
  addedValue: number;
};

export type TShippingCostCalsulationRuleInput = {
  shippingCostCalculationRules: TShippingCostCalsulationRule[];
};

export const adminRequestsApi = createApi({
  reducerPath: 'adminRequestsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getShippingCostCalculationRule: builder.query<
      TShippingCostCalsulationRule[],
      null
    >({
      query: () => ({
        url: 'business-requests/api/admin/v1/shipping-cost-calculation-rule',
      }),
    }),
    updateShippingCostCalculation: builder.mutation<
      TShippingCostCalsulationRule[],
      TShippingCostCalsulationRuleInput
    >({
      query: (updatedValue) => ({
        url: 'business-requests/api/admin/v1/shipping-cost-calculation-rule',
        method: 'PUT',
        body: updatedValue,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedValues } = await queryFulfilled;
          dispatch(
            adminRequestsApi.util.updateQueryData(
              'getShippingCostCalculationRule',
              null,
              () => updatedValues
            )
          );
        } catch {
          // ignore
        }
      },
    }),
  }),
});

export const {
  useGetShippingCostCalculationRuleQuery,
  useUpdateShippingCostCalculationMutation,
} = adminRequestsApi;
