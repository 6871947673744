import React, { FC } from 'react';
import { InfoHelpIcon } from 'src/assets/icons';
import { interpolate } from 'src/utils';
import { TFilterTab } from 'src/components/ui/filters/types';
import { Tooltip } from '../../ui';

import styles from './tab-label.module.scss';

type TTabTooltipProps = {
  tab: { label: string; tooltip?: React.ReactNode };
  count: string | number;
};

export const TabLabel: FC<TTabTooltipProps> = ({ tab, count }) => (
  <div className={styles.wrapper}>
    {tab.tooltip ? (
      <>
        {tab.label}
        <Tooltip title={tab.tooltip}>
          <InfoHelpIcon className={styles.icon} />
        </Tooltip>
        {count}
      </>
    ) : (
      interpolate(tab.label, { count })
    )}
  </div>
);
