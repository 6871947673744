import { IContractorRequest, IManagerBusinessRequest } from 'src/models';
import { FC } from 'react';
import { formatServerDate } from 'src/utils';
import { Typography } from 'src/components/ui';
import styles from './auction-info.module.scss';

interface IAuctionInfoProps {
  businessRequest: IManagerBusinessRequest | IContractorRequest;
}

export const AuctionInfo: FC<IAuctionInfoProps> = ({ businessRequest }) => {
  if (
    businessRequest.refusal ||
    (businessRequest as IContractorRequest).exclusion ||
    !businessRequest.auction
  ) {
    return null;
  }

  const {
    auction: { auctionInfo },
  } = businessRequest;

  if (!auctionInfo || !auctionInfo.startAt || !auctionInfo.endAt) {
    return null;
  }

  if (new Date() > new Date(auctionInfo.endAt)) {
    return null;
  }

  return (
    <div className={styles.chipBlue}>
      <Typography variant="body2" fontSize="10px">
        Проводится аукцион&nbsp;
        {formatServerDate(auctionInfo.startAt, 'dd.MM.yyyy')}
        &nbsp;-&nbsp;
        {formatServerDate(auctionInfo.endAt, 'dd.MM.yyyy')}
      </Typography>
    </div>
  );
};
