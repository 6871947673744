import { FC, useCallback } from 'react';
import { Autocomplete, Form, OutlinedInput } from 'src/components/ui';
import { FIELD_LENGTH } from 'src/constants';
import { ICustomerRejectApplicationReasons } from 'src/models';
import { required, maxLength } from 'src/utils/form-rules';

interface IReasonsProps {
  items: ICustomerRejectApplicationReasons['items'] | undefined;
}

export const Reasons: FC<IReasonsProps> = ({ items }) => {
  const fetchApiFilter = useCallback(
    (value: string): Promise<string[]> =>
      new Promise((resolve) => {
        const arrayName = items?.map((element) => element.name);

        if (!value) {
          resolve(arrayName as string[]);
        }

        resolve(
          arrayName?.filter((element) =>
            element.toLowerCase().startsWith(value.toLowerCase())
          ) || []
        );
      }),
    [items]
  );

  return (
    <>
      <Form.Item
        name="selectedReason"
        label="Пожалуйста, выберите причину для отказа"
        rules={{
          required,
        }}>
        <Autocomplete
          options={items?.map((element) => element.name)}
          fetchApi={fetchApiFilter}
          fetchOnEmptyInput
          placeholder="Выбрать"
        />
      </Form.Item>

      <Form.Item
        name="comment"
        label="Пожалуйста, прокомментируйте"
        rules={{
          required,
          maxLength: maxLength(FIELD_LENGTH.rejectApplicationComment),
        }}>
        <OutlinedInput
          placeholder="Введите комментарий"
          multiline
          rows={6}
          maxLength={FIELD_LENGTH.rejectApplicationComment}
        />
      </Form.Item>
    </>
  );
};
