import { Components } from '@mui/material/styles/components';

export const MuiPagination: Components['MuiPagination'] = {
  styleOverrides: {
    root: {
      margin: '24px 0',
      '.MuiPagination-ul': {
        justifyContent: 'center',
      },
      '& li': {
        '.MuiPaginationItem-ellipsis': {
          paddingTop: '8px',
        },
        '.MuiPaginationItem-previousNext, .MuiPaginationItem-firstLast': {
          backgroundColor: '#F4F5F7',
          width: '40px',
        },
        '.MuiPaginationItem-root': {
          borderRadius: '8px',
          height: '40px',
          margin: '0px 6px',
          '&.Mui-selected': {
            backgroundColor: '#CDE3FF',
          },
          '&:hover': {
            backgroundColor: 'rgba(205, 227, 255, .5)',
          },
          '&.MuiPaginationItem-ellipsis:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
};
