import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { IImport } from 'src/models/admin';
import { baseQueryWithReauth } from 'src/store/utils';

export const adminImportsApi = createApi({
  reducerPath: 'importsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getImportsList: builder.query<IImport[], void>({
      query: () => ({
        url: `business-events/api/admin/v1/business-event/import-log-records`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetImportsListQuery } = adminImportsApi;
