import { Dialog, DialogContent } from 'src/components/ui';

interface IPreviewFileModalProps {
  open: boolean;
  toggleShow: () => void;
  url: string;
}

export const PreviewFileModal = ({
  open,
  toggleShow,
  url,
}: IPreviewFileModalProps) => (
  <Dialog
    open={open}
    onClose={toggleShow}
    PaperProps={{
      sx: {
        width: '1000px',
        margin: '0',
      },
    }}
    fullScreen
    fullWidth>
    <DialogContent
      sx={{
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <iframe
        title="pdf"
        src={url}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </DialogContent>
  </Dialog>
);
