import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { ManagerStatuses } from 'src/models/admin';
import { ROLES } from 'src/constants';

export type TManagersQueueItem = {
  userId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  managerNumberInQueue: number;
  managerStatusInQueue: ManagerStatuses;
};

export type TManagersQueue = {
  kam: TManagersQueueItem[];
  kamPlus: TManagersQueueItem[];
};

export type TChangeManagerInQueueRequest = {
  managerType: ROLES;
  managers: number[];
};

export type TAddManagerInQueueRequest = {
  userId: number;
  managerType: ROLES;
};

export type TAddManagerInQueueResponse = {
  userId: number;
  managerType: ROLES;
  managerNumberInQueue: number;
};

export const adminQueueApi = createApi({
  reducerPath: 'adminQueueApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Queue'],
  endpoints: (builder) => ({
    getManagersQueue: builder.query<TManagersQueue, void>({
      query: () => ({
        url: '/organizations/api/admin/v1/manager-queue',
        method: 'GET',
      }),
      providesTags: ['Queue'],
    }),
    addManagerInQueue: builder.mutation<
      TAddManagerInQueueResponse,
      TAddManagerInQueueRequest
    >({
      query: (body) => ({
        url: '/organizations/api/admin/v1/manager-queue',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Queue']),
    }),
    deleteManagerFromQueue: builder.mutation<
      void,
      TChangeManagerInQueueRequest
    >({
      query: (body) => ({
        url: '/organizations/api/admin/v1/manager-queue/mass/delete-managers',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Queue']),
    }),
    lockManagerInQueue: builder.mutation<void, TChangeManagerInQueueRequest>({
      query: (body) => ({
        url: '/organizations/api/admin/v1/manager-queue/mass/lock',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Queue']),
    }),
    unlockManagerInQueue: builder.mutation<void, TChangeManagerInQueueRequest>({
      query: (body) => ({
        url: '/organizations/api/admin/v1/manager-queue/mass/unlock',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Queue']),
    }),
  }),
});

export const {
  useGetManagersQueueQuery,
  useAddManagerInQueueMutation,
  useDeleteManagerFromQueueMutation,
  useLockManagerInQueueMutation,
  useUnlockManagerInQueueMutation,
} = adminQueueApi;
