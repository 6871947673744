import { FC, ReactElement } from 'react';
import { Navigate, useMatch } from 'react-router-dom';
import { CompanyTypes } from 'src/models';
import { useCompanyContext } from 'src/components/layout/admin/company-layout/hooks';
import { DEFAULT_PAGE } from 'src/configs';

export const CompanyTypeCheck: FC<{
  companyType: CompanyTypes;
  children: ReactElement;
}> = ({ companyType, children }) => {
  const { companyType: currentCompanyType } = useCompanyContext();
  const pathMatch = useMatch('/companies/:id/*');

  return currentCompanyType === companyType ? (
    children
  ) : (
    <Navigate to={pathMatch?.pathnameBase ?? DEFAULT_PAGE} replace />
  );
};
