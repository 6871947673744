import { isSameDay, isYesterday } from 'date-fns';
import { formatServerDate, getFormattedTime } from 'src/utils';

export const getNotificationDate = (
  dateString: string,
  isBriefDate: boolean
): string => {
  const today = new Date();
  if (isSameDay(today, new Date(dateString)) && isBriefDate) {
    return 'Сегодня';
  }
  if (isYesterday(new Date(dateString)) && isBriefDate) {
    return 'Вчера';
  }

  return isBriefDate
    ? formatServerDate(dateString, 'dd.MM.yyyy')
    : `${formatServerDate(dateString, 'dd.MM.yyyy')} в ${getFormattedTime(
        dateString
      )}`;
};
