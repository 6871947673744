export const COLORS_PICKER = [
  '#FFE2E2',
  '#FF9D9D',
  '#FF7676',
  '#E55454',
  '#D81C1C',
  '#B30000',
  '#F8E7CF',
  '#FDC985',
  '#FC9E46',
  '#FC8D2D',
  '#EB8021',
  '#C25C09',
  '#F8F1D7',
  '#FCECB2',
  '#FCDD7F',
  '#FCC432',
  '#EFAC21',
  '#B88400',
  '#CCF9E3',
  '#8FEABF',
  '#62DAA6',
  '#28B872',
  '#1BA05E',
  '#09753F',
  '#D0F4F9',
  '#AFEFF5',
  '#5DD1E2',
  '#17BDD9',
  '#16A2BA',
  '#077083',
  '#E6E0F9',
  '#BDB3EA',
  '#9989E1',
  '#9471CE',
  '#4C3C97',
  '#301D83',
  '#FFDCF3',
  '#F3ABDA',
  '#DB70B6',
  '#CE55A3',
  '#93226C',
  '#640A44',
] as const;

export const WHITE_TEXT_BACKGROUND: Record<string, boolean> = {
  '#FFE2E2': false,
  '#FF9D9D': false,
  '#FF7676': false,
  '#E55454': false,
  '#D81C1C': true,
  '#B30000': true,
  '#F8E7CF': false,
  '#FDC985': false,
  '#FC9E46': false,
  '#FC8D2D': false,
  '#EB8021': true,
  '#C25C09': true,
  '#F8F1D7': false,
  '#FCECB2': false,
  '#FCDD7F': false,
  '#FCC432': false,
  '#EFAC21': true,
  '#B88400': true,
  '#CCF9E3': false,
  '#8FEABF': false,
  '#62DAA6': false,
  '#28B872': false,
  '#1BA05E': true,
  '#09753F': true,
  '#D0F4F9': false,
  '#AFEFF5': false,
  '#5DD1E2': false,
  '#17BDD9': false,
  '#16A2BA': true,
  '#077083': true,
  '#E6E0F9': false,
  '#BDB3EA': false,
  '#9989E1': false,
  '#9471CE': false,
  '#4C3C97': true,
  '#301D83': true,
  '#FFDCF3': false,
  '#F3ABDA': false,
  '#DB70B6': false,
  '#CE55A3': false,
  '#93226C': true,
  '#640A44': true,
  '#0041A0': true, // TODO: !
};
