export const joinFullName = (
  lastName?: string,
  firstName?: string,
  middleName?: string
) => {
  const fullNameParts = [];

  if (lastName) {
    fullNameParts.push(lastName);
  }

  if (firstName) {
    fullNameParts.push(firstName);
  }

  if (middleName) {
    fullNameParts.push(middleName);
  }

  return fullNameParts.join(' ');
};
