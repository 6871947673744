import {
  IRolePermissions,
  RolePermissionService,
} from 'src/services/role-permission-service';
import { AuthStorage, IAuthStorage } from 'src/services/auth/auth-storage';
import { parseJwt } from 'src/utils';
import { ROLES } from 'src/constants';
import { IYandexMetrikaParams } from 'src/configs/yandex-metrika';

export class Auth implements IAuth {
  private _roles: ROLES[] = [];

  private _userId: number = 0;

  private _isGuest: boolean = false;

  private _originalUid: number = 0;

  constructor(
    private _storage: IAuthStorage,
    private _rolePermissions: IRolePermissions
  ) {
    this._init(this.parentAccessToken);
  }

  get isAuth() {
    return !!this.parentAccessToken;
  }

  get isGuestAuth() {
    return this._isGuest;
  }

  get parentAccessToken() {
    return this._storage.accessToken;
  }

  get accessToken() {
    return this.isGuestAuth
      ? this._storage.guestAuth?.[this.userId]?.accessToken ?? null
      : this._storage.accessToken;
  }

  get refreshToken() {
    return this._storage.refreshToken;
  }

  get dataProcessingConfirm() {
    return !!this._storage.dataProcessingConfirm;
  }

  get loginNextTryDate() {
    return this._storage.loginNextTryDate
      ? new Date(this._storage.loginNextTryDate)
      : null;
  }

  get restorePasswordNextTryDate() {
    return this._storage.restorePasswordNextTryDate
      ? new Date(this._storage.restorePasswordNextTryDate)
      : null;
  }

  get roles() {
    return this._roles;
  }

  get userId() {
    return this._userId;
  }

  get originalUid() {
    return this._originalUid;
  }

  get isMultiRole() {
    return this._roles.length > 1;
  }

  get parentCurrentRole() {
    return this._storage.currentRole;
  }

  get currentRole() {
    return (
      this.isGuestAuth
        ? this._storage.guestAuth?.[this.userId]?.currentRole
        : this._storage.currentRole
    ) as ROLES | null;
  }

  private _init(token: string | null) {
    const parsedToken = token && parseJwt(token);

    this._isGuest = Boolean(parsedToken?.isGuest);
    this._roles = parsedToken?.roles ?? [];
    this._userId = parsedToken?.uid ?? 0;
    this._originalUid =
      (this._isGuest ? parsedToken?.originalUid : this._userId) ?? 0;

    this.setCurrentRole(
      this.currentRole && this.roles.includes(this.currentRole)
        ? this.currentRole
        : this.roles[0] || null
    );

    // TODO: Временное решение для тестирования работы
    const ymParams: IYandexMetrikaParams = {};

    if (this.originalUid) {
      ymParams.UserId = this.originalUid;
    }

    if (this.currentRole) {
      let currentYmRole = '';

      switch (this.currentRole) {
        case ROLES.CUSTOMER:
          currentYmRole = 'customer';
          break;

        case ROLES.CONTRACTOR:
          currentYmRole = 'contractor';
          break;

        case ROLES.KAM:
          currentYmRole = 'kam';
          break;

        case ROLES.KAM_PLUS:
          currentYmRole = 'kamplus';
          break;

        case ROLES.ADMIN:
          currentYmRole = 'admin';
          break;

        default:
          break;
      }

      if (currentYmRole) {
        ymParams.user_type = currentYmRole;
      }
    }

    if (ymParams.user_type && ymParams.UserId) {
      // @ts-ignore
      ym(95707285, 'userParams', {
        user_type: ymParams.user_type,
        UserID: ymParams.UserId,
      });
    }
  }

  login(
    authData: { accessToken: string; refreshToken: string },
    dataProcessingConfirm?: boolean
  ) {
    this._storage.setData(
      dataProcessingConfirm === undefined
        ? authData
        : { ...authData, dataProcessingConfirm }
    );

    this._init(this.accessToken);
  }

  loginAsGuest(id: number, guestToken: string) {
    this._storage.setGuestAuthData(id, guestToken);
    this._init(guestToken);
  }

  logout() {
    if (this.isGuestAuth) {
      this._storage.removeGuestAuthData(this.userId);
      this._init(this.parentAccessToken);
    } else {
      this._storage.removeAuthData();
      this._rolePermissions.reset();
      this._roles = [];
      this._userId = 0;
      this._originalUid = 0;
    }
  }

  logoutGuest() {
    this._storage.removeFullGuestAuthData();
  }

  setLoginNextTryDate(date: Date) {
    this._storage.setLoginNextTryDate(date);
  }

  setRestorePasswordNextTryDate(date: Date) {
    this._storage.setRestorePasswordNextTryDate(date);
  }

  setCurrentRole(role: ROLES) {
    if (this.isGuestAuth) {
      this._storage.setGuestCurrentRole(this.userId, role);
    } else {
      this._storage.setCurrentRole(role);
    }

    this._rolePermissions.init(role);
  }

  getGuestAccessToken(id: number) {
    return this._storage.guestAuth?.[id]?.accessToken ?? null;
  }

  isSpecificRole(): boolean {
    if (this.currentRole) {
      return [
        ROLES.PARTNER_RELATIONS_MANAGER,
        ROLES.TECH_EXPERT,
        ROLES.LOGISTIC,
        ROLES.COMMERCIAL_DIRECTOR,
        ROLES.BACK_OFFICE,
        ROLES.GENERAL_MANAGER,
        ROLES.FINANCIAL_MANAGER,
        ROLES.OFFICE_MANAGER,
        ROLES.LAWYER,
      ].includes(this.currentRole);
    }
    return false;
  }
}

export const AuthService = new Auth(new AuthStorage(), RolePermissionService);

export interface IAuth {
  readonly isAuth: boolean;
  readonly isGuestAuth: boolean;
  readonly parentAccessToken: string | null;
  readonly accessToken: string | null;
  readonly refreshToken: string | null;
  readonly dataProcessingConfirm: boolean;
  readonly loginNextTryDate: Date | null;
  readonly restorePasswordNextTryDate: Date | null;
  readonly roles: ROLES[];
  readonly currentRole: ROLES | null;
  readonly userId: number;
  readonly originalUid: number;

  login(
    authData: { accessToken: string; refreshToken: string },
    dataProcessingConfirm?: boolean
  ): void;

  loginAsGuest(id: number, guestToken: string): void;

  logout(): void;

  logoutGuest(): void;

  setLoginNextTryDate(date: Date): void;

  setRestorePasswordNextTryDate(date: Date): void;

  setCurrentRole(role: ROLES): void;

  getGuestAccessToken(id: number): string | null;

  isSpecificRole(): boolean;
}
