import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ContractorPayment = lazy(
  () =>
    import(
      /* webpackChunkName: 'ContractorPayment' */
      /* webpackMode: "lazy" */
      `./payment`
    )
);

export const LazyContractorPayment: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ContractorPayment />
  </Suspense>
);
