import { useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import PaginationItem from '@mui/material/PaginationItem';
import { Arrow, DoubleArrow } from 'src/assets/icons';

import { Button, OutlinedInput } from 'src/components/ui';

import { theme } from 'src/theme';
import styles from './pagination.module.scss';

const FirstBtn = () => (
  <span className={styles.firstBtn}>
    <DoubleArrow />
  </span>
);

const PreviousArrow = () => <Arrow className={styles.arrow} />;

const NextArrow = () => <Arrow className={styles.nextArrow} />;

interface TablePaginationProps {
  itemsCount: number;
  countPerPage: number;
  handleChange?: (page: number) => void;
  page: number;
}

export const TablePagination = ({
  itemsCount,
  countPerPage,
  handleChange,
  page,
}: TablePaginationProps) => {
  const pagesCount = Math.ceil(itemsCount / countPerPage);
  const [pageInput, setPageInput] = useState('');

  const handlePageInput = (value: string) => {
    if (!value || /^[1-9]\d*$/.test(value)) {
      setPageInput(value.trim());
    }
  };

  const handleGoToPage = () => {
    const selectedPage = parseInt(pageInput, 10);

    if (
      selectedPage <= 0 ||
      selectedPage > pagesCount ||
      selectedPage === page
    ) {
      return;
    }

    handleChange?.(selectedPage);
  };

  return (
    <Stack
      direction="row"
      spacing={1.5}
      alignItems="center"
      className={styles.pagination}>
      <Pagination
        page={page}
        onChange={(_, value) => handleChange?.(value)}
        sx={{
          margin: 0,
          flexShrink: 0,
          'li .MuiPaginationItem-page': {
            width: '40px',
            border: `${theme.palette.customTheme.borderGrayPrimary} 1px solid`,
          },
          '& li .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            border: 'none',
          },
        }}
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: PreviousArrow,
              next: NextArrow,
              first: FirstBtn,
              last: DoubleArrow,
            }}
            {...item}
          />
        )}
        count={pagesCount}
        showFirstButton
        showLastButton
      />
      <Stack direction="row" gap="4px" width="320px" minWidth="0px">
        <Stack sx={{ flex: 1 }}>
          <OutlinedInput
            trimValue={false}
            sx={{ flex: 1 }}
            placeholder="Перейти на страницу №:"
            value={pageInput}
            onChange={(event) => handlePageInput(event.target.value)}
          />
        </Stack>
        <Button disabled={!pageInput} onClick={() => handleGoToPage()}>
          Перейти
        </Button>
      </Stack>
    </Stack>
  );
};
