import { createApi } from '@reduxjs/toolkit/query/react';
import { downloadFile } from 'src/api/utils';
import { baseQuery } from 'src/store/utils';
import {
  TUploadFileParams,
  TUploadFileResponse,
} from 'src/api/communications/models';

// TODO: Переделать на правильную категорию для всех коммуникаций
const NOTE_FILE_CATEGORY = 'EMAILS';
const DEFAULT_SENDER = 'zakaz@cometal.com';

export const fileStorageApi = createApi({
  reducerPath: 'fileStorageApi',
  baseQuery,
  endpoints: (builder) => ({
    downloadFileNote: builder.query<null, string>({
      query: (key) => ({
        url: 'file-storage/api/v1/file-storage',
        method: 'GET',
        params: {
          fileCategory: NOTE_FILE_CATEGORY,
          key,
        },
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/octet-stream'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob, metaData) {
        downloadFile(response, metaData);

        return null;
      },
    }),

    // TODO: make dynamic query params
    uploadFileNote: builder.mutation<TUploadFileResponse, TUploadFileParams>({
      query: (params) => ({
        url: `file-storage/api/v1/file-storage?fileCategory=${NOTE_FILE_CATEGORY}&userName=${DEFAULT_SENDER}`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const { useLazyDownloadFileNoteQuery, useUploadFileNoteMutation } =
  fileStorageApi;
