import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TNotificationsFilter,
  TNotification,
  TNotificationsHistory,
  TCurrentNotificationFilterReadValue,
} from 'src/models/notifications';
import { fillNotificationTemplate } from 'src/utils';

interface IInitialState {
  history: TNotificationsHistory;
  currentNotification: TNotification | null;
  socketConnectedStatus: boolean;
  currentNotificationFilterValue: TNotificationsFilter;
  currentNotificationFilterReadValue: TCurrentNotificationFilterReadValue;
}

const initialState: IInitialState = {
  history: {
    hasNotificationBefore: false,
    notifications: [],
    anchor: null,
    unwatchedNotificationsCount: 0,
  },
  currentNotification: null,
  socketConnectedStatus: false,
  currentNotificationFilterValue: 'ALL',
  currentNotificationFilterReadValue: 'ALL',
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setCurrentNotification(state, action: PayloadAction<TNotification | null>) {
      const { payload } = action;
      if (payload) {
        state.currentNotification = fillNotificationTemplate(payload);
      }
      state.currentNotification = payload;
    },
    addNewNotification(state, action: PayloadAction<TNotification>) {
      const { payload } = action;
      const updatedList = [
        fillNotificationTemplate(payload),
        ...state.history.notifications,
      ];
      state.history.notifications = updatedList;
      state.history.unwatchedNotificationsCount += 1;
    },
    setReadNotification(state, action: PayloadAction<string>) {
      const { payload } = action;
      const newNotifications = state.history.notifications.map(
        (notification) => ({
          ...notification,
          watched: notification.id === payload ? true : notification.watched,
        })
      );
      state.history.notifications = [...newNotifications];
      if (state.history.unwatchedNotificationsCount > 0) {
        state.history.unwatchedNotificationsCount -= 1;
      }
    },
    setCurrentNotificationsFilterValue(
      state,
      action: PayloadAction<TNotificationsFilter>
    ) {
      const { payload } = action;
      state.currentNotificationFilterValue = payload;
    },
    setCurrentNotificationsFilterReadValue(
      state,
      action: PayloadAction<TCurrentNotificationFilterReadValue>
    ) {
      const { payload } = action;
      state.currentNotificationFilterReadValue = payload;
    },
    setNotificationsHistory(
      state,
      action: PayloadAction<TNotificationsHistory>
    ) {
      const { payload } = action;
      if (
        !(
          state.history.anchor ===
          payload.notifications[payload.notifications.length - 1]?.id
        )
      ) {
        const filledNotifications = payload.notifications.map((notification) =>
          fillNotificationTemplate(notification)
        );
        const updatedList = [
          ...state.history.notifications,
          ...filledNotifications,
        ];
        state.history = { ...payload, notifications: updatedList };
        state.history.anchor =
          filledNotifications[filledNotifications.length - 1]?.id;
      }
    },
    readAllNotifications(state) {
      state.history.notifications.forEach((elem) => {
        elem.watched = true;
      });
      state.history.unwatchedNotificationsCount = 0;
    },
    setSocketConnectionStatus(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.socketConnectedStatus = payload;
    },
    clearNotificationsStore(state) {
      state.history = {
        hasNotificationBefore: false,
        notifications: [],
        anchor: null,
        unwatchedNotificationsCount: 0,
      };
      state.currentNotification = null;
      state.currentNotificationFilterValue = 'ALL';
      state.currentNotificationFilterReadValue = 'ALL';
    },
  },
});

export const {
  clearNotificationsStore,
  setCurrentNotificationsFilterValue,
  setSocketConnectionStatus,
  setNotificationsHistory,
  setCurrentNotification,
  setCurrentNotificationsFilterReadValue,
  setReadNotification,
  addNewNotification,
  readAllNotifications,
} = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
