import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiLinearProgress: Components<Theme>['MuiLinearProgress'] = {
  styleOverrides: {
    root: {
      backgroundColor: '#F0F2F5',
      borderRadius: '8px',
      height: 8,
    },

    bar: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
    }),
  },
};
