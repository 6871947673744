import { MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SvgIcon, Stack, OverflowTooltip } from 'src/components/ui';
import { Email } from 'src/assets/icons';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import { TCardManager } from 'src/components/widgets/manager-card/manager-card';
import { IBidsCustomerContact, IBusinessRequestCustomerInfo } from 'src/models';
import { Whois } from 'src/components/widgets/whois';
import styles from './email-label.module.scss';

interface IEmailLabelProps {
  user: TVariantContactItem | TCardManager['manager'];
  isContactItem: boolean;
  businessRequest?: IBusinessRequestCustomerInfo;
  onEmailClick?: (email: string | null) => void;
}

export const EmailLabel = ({
  user,
  isContactItem,
  businessRequest,
  onEmailClick,
}: IEmailLabelProps) => {
  const email = isContactItem
    ? (user as TVariantContactItem)?.email || 'email не указан'
    : (user as TCardManager['manager'])?.login || 'email не указан';

  const location = useLocation();
  const navigate = useNavigate();

  const isCommunicationPage = () =>
    location.pathname.includes('communications');

  const handleEmailClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (isCommunicationPage()) {
      onEmailClick?.(email);

      return;
    }

    if (businessRequest?.businessRequestId) {
      navigate(
        `/requests/${businessRequest.businessRequestId}/communications`,
        {
          state: {
            selectedEmail: email,
          },
        }
      );
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="8px"
      onClick={(event) => handleEmailClick(event)}
      sx={{
        cursor: onEmailClick ? 'pointer' : 'default',
      }}>
      <SvgIcon
        width="16px"
        height="16px"
        strokeHexColor="#0041A0"
        icon={Email}
      />
      <Stack
        direction="row"
        alignItems="center"
        gap="4px"
        className={styles.emailContainer}>
        <OverflowTooltip
          className={styles.contact}
          text={email}
          tooltipProps={{
            slotProps: {
              tooltip: {
                style: {
                  maxWidth: 400,
                },
              },
            },
          }}
        />
        {!!(user as IBidsCustomerContact)?.domainCheckDto && (
          <Whois
            item={user as TVariantContactItem}
            businessRequest={businessRequest}
          />
        )}
      </Stack>
    </Stack>
  );
};
