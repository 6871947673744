import { ChangeEvent, FC } from 'react';
import {
  IconButton,
  OutlinedInput,
  OutlinedInputProps,
} from 'src/components/ui';
import { CloseIcon, SearchBtn } from 'src/assets/icons';
import classNames from 'classnames';
import styles from './search-input.module.scss';

interface ISearchInputProps extends Omit<OutlinedInputProps, 'onChange'> {
  onChange: (val: string) => void;
}

export const SearchInput: FC<ISearchInputProps> = ({
  onChange,
  value,
  className,
  ...props
}) => {
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const clearSearch = () => {
    onChange('');
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <OutlinedInput
        className={styles.inputSearch}
        placeholder="Введите номер или название заявки для поиска"
        onChange={handleChangeSearch}
        value={value}
        startAdornment={
          <IconButton type="button" aria-label="search">
            <SearchBtn />
          </IconButton>
        }
        endAdornment={
          <IconButton
            onMouseDown={(e) => e.preventDefault()}
            onMouseUp={(e) => e.preventDefault()}
            onClick={clearSearch}
            tabIndex={-1}>
            {!!value && <CloseIcon />}
          </IconButton>
        }
        {...props}
      />
    </div>
  );
};
