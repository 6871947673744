import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ForgotPassword = lazy(
  () =>
    import(
      /* webpackChunkName: 'ForgotPassword' */
      /* webpackMode: "lazy" */
      `./forgot-password`
    )
);

export const LazyForgotPassword: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ForgotPassword />
  </Suspense>
);
