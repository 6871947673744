import {
  FilterTypes,
  TFilterItem,
  TFilterListMap,
  TFilterListValuesMap,
  TFilterValue,
  TSortItem,
  TVariant,
} from 'src/components/ui/new-filters/types';
import { isTags, TTechnicalOpportunitiesCategories } from 'src/models/bid';
import { TChip } from '../types';

export const getSearchParameters = (fullList: Array<TChip>) => {
  const searchList: string[] = [];
  fullList.forEach((item) => {
    if (item.type === FilterTypes.SEARCH) {
      searchList.unshift(item.label);
    }
  });
  return searchList;
};

export const validateSearchQuery = (
  searchQuery: TChip,
  listSearchQuery: Array<TChip>
) =>
  searchQuery.label.length > 0 &&
  !listSearchQuery.find(
    (item) => item.type === searchQuery.type && item.label === searchQuery.label
  );

export const createSortChip = <KeysSorting>(
  selectedSort: TSortItem<KeysSorting>
): TChip => ({
  type: FilterTypes.SORT,
  label: selectedSort.label,
  direction: selectedSort.direction,
  hidden: false,
});

const getCheckboxTreeChips = (
  data: TTechnicalOpportunitiesCategories,
  variants: TVariant[],
  filterKey: string
): TChip[] => {
  const chipsArray: TChip[] = [];

  data.forEach((category) => {
    const categoryVariant = variants.find(
      (variant) => Number(variant.value) === category.categoryId
    )!;

    const allSubcategoriesSelected =
      categoryVariant.nestedVariants!.length === category.subcategoryIds.length;

    if (allSubcategoriesSelected) {
      chipsArray.push({
        label: categoryVariant.label,
        hidden: false,
        type: FilterTypes.FILTERS,
        value: { categoryId: category.categoryId },
        filterKey,
      });
    } else {
      const subcategoryChips = categoryVariant
        .nestedVariants!.filter((nestedVariant) =>
          category.subcategoryIds.includes(Number(nestedVariant.value))
        )
        .map((nestedVariant) => ({
          label: nestedVariant.label,
          hidden: false,
          type: FilterTypes.FILTERS,
          value: {
            categoryId: category.categoryId,
            subcategoryId: Number(nestedVariant.value),
          },
          filterKey,
        }));

      chipsArray.push(...subcategoryChips);
    }
  });

  return chipsArray;
};

export const updateChipsByFiltersData = <FilterKeys extends string = string>(
  data: TFilterListValuesMap<FilterKeys>,
  chips: Array<TChip>,
  config?: TFilterListMap<FilterKeys>
): Array<TChip> => {
  const clearChips: Array<TChip> = [];
  chips.forEach((chip) => {
    if (chip.type !== FilterTypes.FILTERS) {
      clearChips.unshift({
        ...chip,
        hidden: false,
      });
    }
  });
  const newFiltersChips: Array<TChip> = [];
  Object.values(data).forEach((filterItemEntry) => {
    const filterItem = filterItemEntry as TFilterValue<FilterKeys>;
    const filterString = filterItem.stringRepresentation;
    if (filterString && !isTags(filterItem.value)) {
      if (Array.isArray(filterString)) {
        filterString.forEach((filterStringItem, index) => {
          newFiltersChips.unshift({
            type: FilterTypes.FILTERS,
            value: (filterItem.value as string[])[index],
            label: filterStringItem,
            filterKey: filterItem.key as string,
            hidden: false,
          });
        });
      } else {
        newFiltersChips.unshift({
          type: FilterTypes.FILTERS,
          value: filterItem.value,
          filterKey: filterItem.key as string,
          label: filterItem.stringRepresentation as string,
          hidden: false,
        });
      }
    }

    if (isTags(filterItem.value)) {
      getCheckboxTreeChips(
        filterItem.value,
        config![filterItem.key].variantsList!,
        filterItem.key
      ).forEach((treeChip) => newFiltersChips.unshift(treeChip));
    }
  });
  return [...clearChips, ...newFiltersChips];
};

export const mapFilterValueToFilterVariants = <FilterKeys>(
  filter: TFilterValue<FilterKeys> | undefined
): TVariant[] => {
  if (!filter) {
    return [];
  }

  const variants: TVariant[] = [];
  (filter.value as string[]).forEach((value, index) => {
    variants.push({
      value,
      label: (filter.stringRepresentation as string[])[index],
      checked: true,
    });
  });

  return variants;
};

export const getTabFiltersFromFiltersConfig = <
  FilterKeys extends string = string
>(
  config: TFilterListMap<FilterKeys>
): TFilterItem<FilterKeys>[] => {
  const tabFilters: TFilterItem<FilterKeys>[] = [];

  Object.values(config).forEach((item) => {
    if ((item as TFilterItem<FilterKeys>).filterFormType === 'tab') {
      tabFilters.push(item as TFilterItem<FilterKeys>);
    }
  });

  return tabFilters;
};

// При очищении фильтров не нужно стирать значение для фильтра типа tab
export const clearFilterValues = <FilterKeys extends string = string>(
  values: TFilterListValuesMap<FilterKeys> | undefined,
  config: TFilterListMap<FilterKeys> | undefined
): TFilterListValuesMap<FilterKeys> => {
  if (!values || !config) {
    return {};
  }

  const valuesCopy = { ...values };

  Object.entries(valuesCopy).forEach(([key]) => {
    if (config[key as FilterKeys].filterFormType === 'toggle') {
      valuesCopy[key as FilterKeys] = {
        key,
        value: ['false'],
        stringRepresentation: '',
      } as TFilterValue<FilterKeys>;
    } else if (config[key as FilterKeys].filterFormType !== 'tab') {
      delete valuesCopy[key as FilterKeys];
    }
  });

  return valuesCopy;
};
