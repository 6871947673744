import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const CompanyFinancialStatements = lazy(
  () =>
    import(
      /* webpackChunkName: 'CompanyFinancialStatements' */
      /* webpackMode: "lazy" */
      `./financial-statements`
    )
);

export const LazyCompanyFinancialStatements: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <CompanyFinancialStatements />
  </Suspense>
);
