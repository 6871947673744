import { FC, useCallback, useState } from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  InfoRow,
  Switch,
  Box,
  Grid,
  Typography,
} from 'src/components/ui';
import { DeliveryType, ICargoDescriptions } from 'src/models';
import { Package, Reload, Box as BoxIcon } from 'src/assets/icons';

import { ViewCargoParameters } from '../view-cargo-parameters';
import { TransportCalculation } from './components';
import styles from './cargo-parameters-input.module.scss';

interface ICargoParametersInputProps {
  value?: ICargoDescriptions;
  onChange?: (value: ICargoDescriptions) => void;
  variant: 'requestPerformers' | 'commercialOffer' | 'auction' | 'rebidding';
  viewMode?: boolean;
}

const mapResultCargoParameters = (params: ICargoDescriptions['parameters']) => {
  if (params.deliveryType === DeliveryType.TRUCK_FTL) {
    return {
      deliveryType: params.deliveryType,
      tentCount: params.tentCount,
      hardBoard: params.hardBoard,
      detachableBeam: params.detachableBeam,
      removableStand: params.removableStand,
    };
  }

  let placeParams;

  if (params.singlePlace) {
    placeParams = {
      places: 1,
      length: params.length,
      width: params.width,
      height: params.height,
      volume: params.volume,
      weight: params.weight,
      singlePlace: true,
    };
  } else if (Number(params.places) > 1) {
    placeParams = {
      places: params.places,
      maxLength: params.maxLength,
      maxWidth: params.maxWidth,
      maxHeight: params.maxHeight,
      totalVolume: params.totalVolume,
      totalWeight: params.totalWeight,
      isPlaceEqual: params.isPlaceEqual,
      singlePlace: false,
    };
  } else {
    placeParams = {
      places: 1,
      length: params.maxLength,
      width: params.maxWidth,
      height: params.maxHeight,
      volume: params.totalVolume,
      weight: params.totalWeight,
      singlePlace: true,
    };
  }

  return {
    deliveryType: params.deliveryType,
    ...placeParams,
  };
};

export const CargoParametersInput: FC<ICargoParametersInputProps> = ({
  value,
  onChange,
  variant,
  viewMode,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleLogisticsChange = (withLogistics: boolean) => {
    if (value && onChange) {
      onChange({ ...value, withLogistics });
    }
  };

  const handleChangeCargoParameters = useCallback(
    (params: ICargoDescriptions) => {
      if (onChange) {
        onChange({
          ...params,
          parameters: mapResultCargoParameters(params.parameters),
        });
      }
    },
    [onChange]
  );

  return (
    <>
      {variant === 'requestPerformers' && (
        <RadioGroup
          value={value?.withLogistics ? 'yes' : 'no'}
          onChange={(_, val) => handleLogisticsChange(val === 'yes')}
          className={styles.radioGroup}>
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="без логистики"
          />

          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={
              <div className={styles.logistics}>
                {value?.parameters.deliveryType ? (
                  <ViewCargoParameters cargoParameters={value} />
                ) : (
                  <span>с логистикой</span>
                )}
                {value?.withLogistics && (
                  <Button variant="text" onClick={() => setShowDialog(true)}>
                    {value?.parameters.deliveryType
                      ? 'Задать новые параметры'
                      : 'Задать параметры'}
                  </Button>
                )}
              </div>
            }
          />
        </RadioGroup>
      )}

      {variant === 'commercialOffer' && (
        <div>
          {value?.parameters.deliveryType && (
            <InfoRow
              className={styles.cargoParameters}
              variant="secondary"
              label="Параметры груза:"
              value={<ViewCargoParameters cargoParameters={value} />}
            />
          )}

          <Button
            variant="text"
            onClick={() => setShowDialog(true)}
            endIcon={<Reload />}>
            {value?.parameters.deliveryType
              ? 'Пересчитать логистику'
              : 'Рассчитать логистику'}
          </Button>
        </div>
      )}

      {variant === 'auction' && (
        <div>
          <InfoRow
            className={styles.cargoParameters}
            variant="secondary"
            label="Параметры груза:"
            value={
              value?.parameters.deliveryType ? (
                <ViewCargoParameters cargoParameters={value} isAuction />
              ) : (
                'Нет данных'
              )
            }
          />

          {!viewMode && (
            <Button
              variant="text"
              onClick={() => setShowDialog(true)}
              endIcon={<Package />}>
              Задать параметры груза
            </Button>
          )}
        </div>
      )}

      {variant === 'rebidding' && (
        <>
          <div>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={value?.withLogistics}
                  onChange={(e) => handleLogisticsChange(e.target.checked)}
                />
              }
              label="С логистикой"
            />
          </div>

          {value?.withLogistics && (
            <Grid container mt={2} columnSpacing="24px">
              <Grid item xs={4}>
                <Typography fontSize="12px" mb={0.5}>
                  Адрес доставки:
                </Typography>
                <Typography color="secondary">
                  {value.deliveryAddress || `Нет данных`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontSize="12px" mb={0.5}>
                  Параметры груза:
                </Typography>
                {value?.parameters.deliveryType ? (
                  <>
                    <Box className={styles.containerParameters} mb={1}>
                      <ViewCargoParameters cargoParameters={value} />
                    </Box>
                    <Button variant="text" onClick={() => setShowDialog(true)}>
                      <Box mr={1} color="primary">
                        Задать новые параметры груза
                      </Box>
                      <BoxIcon />
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography color="secondary">Нет данных</Typography>
                    <Button variant="text" onClick={() => setShowDialog(true)}>
                      {!value?.parameters.deliveryType && (
                        <>
                          <Box mr={1}>Задать параметры груза</Box>
                          <BoxIcon />
                        </>
                      )}
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}

      {onChange && showDialog && (
        <TransportCalculation
          open
          setShowDialog={setShowDialog}
          setCargoParameters={handleChangeCargoParameters}
          data={value}
        />
      )}
    </>
  );
};
