import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const AdminGroupDetails = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminGroupDetails' */
      /* webpackMode: "lazy" */
      `./group-details`
    )
);

export const LazyGroupDetails: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <AdminGroupDetails />
  </Suspense>
);
