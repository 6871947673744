import {
  addMinutes,
  differenceInMilliseconds,
  format,
  isValid,
} from 'date-fns';
import {
  TFilterValue,
  TRangeFilterValue,
} from 'src/components/ui/new-filters/types';

export const INVALID_DATE = 'Invalid Date';

const getServerDate = (
  date: number | string | Date,
  formatDate = "yyyy-MM-dd'T'HH:mm:ss"
) => {
  const newDate = new Date(date);
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  return format(addMinutes(newDate, -newDate.getTimezoneOffset()), formatDate);
};

export const validateDateRange = (value: TRangeFilterValue): string => {
  const startDate = value.from;
  const endDate = value.to;

  if (
    (startDate && !isValid(new Date(startDate))) ||
    (endDate && !isValid(new Date(endDate)))
  ) {
    return 'Введена некорректная дата';
  }

  const diff = differenceInMilliseconds(new Date(endDate), new Date(startDate));

  if (diff < 0) {
    return 'Дата начала должна быть раньше даты конца';
  }
  return '';
};

export const getDateString = (value: string | Date | null): string => {
  if (!value) {
    return '';
  }
  if (isValid(value) || isValid(new Date(value))) {
    return getServerDate(value, 'dd.MM.yyy');
  }
  return '';
};

export const getDate = (value: Date | null | string) => {
  if (value === null) {
    return '';
  }
  if (value && (isValid(value) || isValid(new Date(value)))) {
    return getServerDate(value);
  }
  return INVALID_DATE;
};

export const getDateRangeValueFromStrings = <
  FilterKeys extends string = string
>(
  key: FilterKeys,
  fromDateString: string | Date | null,
  toDateString: string | Date | null
): TFilterValue<FilterKeys> => {
  const fromDateStringFormatted = getDateString(fromDateString);
  const fromDateValue = getDate(fromDateString);

  const toDateStringFormatted = getDateString(toDateString);
  const toDateValue = getDate(toDateString);

  let dateString = '';

  if (fromDateStringFormatted && toDateStringFormatted) {
    dateString = `${fromDateStringFormatted} - ${toDateStringFormatted}`;
  } else if (fromDateStringFormatted) {
    dateString = fromDateStringFormatted;
  } else if (toDateStringFormatted) {
    dateString = toDateStringFormatted;
  }

  return {
    key,
    value: {
      from: fromDateValue,
      to: toDateValue,
    },
    stringRepresentation: dateString,
  };
};

export const getDateStringRepresentation = (
  value: TRangeFilterValue
): string => {
  const fromDateStringFormatted = getDateString(value.from);

  const toDateStringFormatted = getDateString(value.to);

  let dateString = '';

  if (fromDateStringFormatted && toDateStringFormatted) {
    dateString = `${fromDateStringFormatted} - ${toDateStringFormatted}`;
  } else if (fromDateStringFormatted) {
    dateString = fromDateStringFormatted;
  } else if (toDateStringFormatted) {
    dateString = toDateStringFormatted;
  }

  return dateString;
};
