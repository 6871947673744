import {
  BusinessRequestMode,
  ContractorStatusCode,
  CustomerStatusCode,
  IContractorRequestKanban,
  ICustomerRequestKanban,
  IManagerKanbanBusinessRequest,
  KamStatusCode,
} from 'src/models';
import {
  ISpecificRoleKanbanBusinessRequest,
  SpecificRoleStatusCode,
} from 'src/models/bid';

type TSortingVariant =
  | IManagerKanbanBusinessRequest
  | IContractorRequestKanban
  | ICustomerRequestKanban
  | ISpecificRoleKanbanBusinessRequest;

const getMaskSortingByMode = (mode: BusinessRequestMode) => {
  if (mode === BusinessRequestMode.MANAGER) {
    return Object.values(KamStatusCode);
  }

  if (mode === BusinessRequestMode.CONTRACTOR) {
    return Object.values(ContractorStatusCode);
  }

  if (mode === BusinessRequestMode.SPECIFIC_ROLE) {
    return Object.values(SpecificRoleStatusCode);
  }

  return Object.values(CustomerStatusCode);
};

export const bidsSortingColumn = <T extends TSortingVariant>(
  data: T,
  mode: BusinessRequestMode
): T => {
  const maskSorting = getMaskSortingByMode(mode) as string[];

  return {
    ...data,
    items: maskSorting.reduce((acc: T['items'], element) => {
      const { items }: { items: TSortingVariant['items'][number][] } = data;

      const currentColumn: any = items.find(
        (column: T['items'][number]) => column.statusCode === element
      );

      if (currentColumn) {
        return [...acc, currentColumn] as TSortingVariant['items'];
      }

      return acc;
    }, []),
  };
};
