import {
  isErrorWithMessage,
  isFetchBaseQueryError,
} from 'src/store/utils/error-check';

export const getErrorMessage = (
  error: unknown,
  replaceText: string = 'Произошла ошибка'
) =>
  isFetchBaseQueryError(error) && isErrorWithMessage(error.data)
    ? error.data.message
    : replaceText;
