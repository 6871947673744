import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { businessRequestApi } from 'src/api';
import { COUNT_PER_PAGE } from 'src/configs';
import {
  IContractorRequestKanban,
  IContractorRequestKanbanAuctionDateStatistics,
  IContractorRequestKanbanCostIndicatorStatistics,
  IContractorRequestKanbanParams,
} from 'src/models';
import { formatServerDate } from 'src/utils';
import { defaultSort } from './requests-manager-slice';

interface IInitialState {
  requests: IContractorRequestKanban;
  requestParams: IContractorRequestKanbanParams;
  costIndicatorStatistics: {
    [key: number]: IContractorRequestKanbanCostIndicatorStatistics;
  } | null;
  auctionStatistics: {
    [key: number]: IContractorRequestKanbanAuctionDateStatistics;
  } | null;
  pageCount: number;
  isInitialState: boolean;
  isInitialSortSelected: boolean;
  filterTagsPositions: Record<string, number>;
}

const initialState: IInitialState = {
  requests: {
    countByStatus: null,
    items: [],
  },
  requestParams: {
    filter: {
      statusGroup: 'ACTIVE',
      technicalOpportunitiesCategories: [],
    },

    sort: {
      field: defaultSort.field,
      order: defaultSort.direction,
    },

    searchQueries: [],

    paging: {
      vertical: {
        page: 1,
        limit: COUNT_PER_PAGE.REQUEST,
      },
    },
  },
  costIndicatorStatistics: null,
  auctionStatistics: null,
  pageCount: 0,
  isInitialState: true,
  isInitialSortSelected: false,
  filterTagsPositions: {},
};

const getContractorRequestsItems = (items: IContractorRequestKanban['items']) =>
  items.reduce(
    (acc, element) => ({
      ...acc,
      [element.statusCode]: element,
    }),
    {}
  ) as {
    [key: string]: IContractorRequestKanban['items'][number];
  };

const requestsContractorSlice = createSlice({
  name: 'requestsContractor',
  initialState,

  extraReducers: (builder) => {
    builder.addMatcher(
      businessRequestApi.endpoints.getContractorCostIndicatorStatistics
        .matchFulfilled,
      (state, { payload }) => {
        const newCostIndicatorStatistics = payload.reduce(
          (acc, element) => ({
            ...acc,
            [element.contractorRequestId]: element,
          }),
          {}
        );

        state.costIndicatorStatistics = {
          ...state.costIndicatorStatistics,
          ...newCostIndicatorStatistics,
        };
      }
    );

    builder.addMatcher(
      businessRequestApi.endpoints.getContractorAuctionDateStatistics
        .matchFulfilled,
      (state, { payload }) => {
        const newAuctionStatistics = payload.reduce(
          (acc, element) => ({
            ...acc,
            [element.contractorRequestId]: {
              ...element,
              auctionStartDate: formatServerDate(element.auctionStartDate),
              auctionEndDate: formatServerDate(element.auctionEndDate),
            },
          }),
          {}
        );

        state.auctionStatistics = {
          ...state.auctionStatistics,
          ...newAuctionStatistics,
        };
      }
    );
  },

  reducers: {
    setContractorCurrentData(
      state,
      {
        payload: { requests, requestParams, filterTagsPositions },
      }: PayloadAction<{
        requests: IContractorRequestKanban;
        requestParams: IContractorRequestKanbanParams;
        filterTagsPositions: Record<string, number>;
      }>
    ) {
      state.requestParams = requestParams;
      state.filterTagsPositions = filterTagsPositions;
      state.isInitialState = false;

      const isFirstPage = requestParams.paging.vertical.page === 1;

      if (
        isFirstPage ||
        !requests.items.length ||
        !state.requests.items.length
      ) {
        state.requests = requests;
      } else {
        const oldContractorRequests = getContractorRequestsItems(
          state.requests.items
        );

        state.requests = {
          ...requests,
          countByStatus: requests.countByStatus || state.requests.countByStatus,
          items: requests.items.map((item) => {
            const contractorRequestsItemByStatus =
              oldContractorRequests[item.statusCode];

            return contractorRequestsItemByStatus
              ? {
                  ...item,
                  contractorRequestsCountByStatus:
                    contractorRequestsItemByStatus.contractorRequestsCountByStatus,
                  contractorRequests: [
                    ...contractorRequestsItemByStatus.contractorRequests,
                    ...item.contractorRequests,
                  ],
                }
              : item;
          }),
        };
      }

      if (isFirstPage) {
        const maxTotalCountColumn = Math.max(
          ...state.requests.items.map(
            (element) => element.contractorRequestsCountByStatus || 0
          )
        );

        state.pageCount = Math.ceil(
          maxTotalCountColumn / COUNT_PER_PAGE.REQUEST
        );

        state.auctionStatistics = null;
        state.costIndicatorStatistics = null;
      }
    },

    setContractorIsInitialSortSelected(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isInitialSortSelected = payload;
    },
  },
});

export const { setContractorCurrentData, setContractorIsInitialSortSelected } =
  requestsContractorSlice.actions;

export const requestsContractorReducer = requestsContractorSlice.reducer;
