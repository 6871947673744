import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Form,
} from 'src/components/ui';
import { getErrorMessage } from 'src/store/utils';
import { DeliveryType, ICargoDescriptions } from 'src/models';
import { TransportChoose } from './transport-choose';
import { DedicatedTransport } from './dedicated-transport';
import { GroupageCargo } from './groupage-cargo';
import { TFormInput } from './types';

const steps = ['Выберите тип транспорта', 'Задайте параметры сборного груза'];

const getFormValue = (data?: ICargoDescriptions) => ({
  deliveryAddress: '',
  deliveryType: data?.parameters?.deliveryType ?? DeliveryType.CARGO_SINGLE,

  tentCount: data?.parameters?.tentCount ?? 1,
  hardBoard: !!data?.parameters?.hardBoard,
  detachableBeam: !!data?.parameters?.detachableBeam,
  removableStand: !!data?.parameters?.removableStand,

  length: data?.parameters?.length ?? 0.1,
  width: data?.parameters?.width ?? 0.1,
  height: data?.parameters?.height ?? 0.1,
  weight: data?.parameters?.weight ?? 0.1,
  volume: data?.parameters?.volume ?? 0.1,
  singlePlace: data?.parameters?.singlePlace !== false,

  maxLength: data?.parameters?.maxLength ?? 1,
  maxWidth: data?.parameters?.maxWidth ?? 1,
  maxHeight: data?.parameters?.maxHeight ?? 1,
  maxWeight: data?.parameters?.maxWeight ?? 1,
  totalWeight: data?.parameters?.totalWeight ?? 1,
  totalVolume: data?.parameters?.totalVolume ?? 1,
  places: data?.parameters?.places ?? 1,
  isPlaceEqual: data?.parameters?.isPlaceEqual === true,
});

export const TransportCalculation: FC<{
  data?: ICargoDescriptions;
  open: boolean;
  setShowDialog: (item: boolean) => void;
  setCargoParameters: (item: ICargoDescriptions) => void;
}> = ({ open, data, setShowDialog, setCargoParameters }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const form = useForm<TFormInput>({
    defaultValues: getFormValue(data),
  });

  const onSubmit: SubmitHandler<TFormInput> = async ({
    deliveryAddress,
    ...formValues
  }) => {
    setIsSaving(true);
    try {
      await setCargoParameters({
        deliveryAddress: deliveryAddress || data?.deliveryAddress || '',
        withLogistics: true,
        parameters: formValues,
      });
      setShowDialog(false);
    } catch (e: unknown) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(e),
      });
    } finally {
      setIsSaving(false);
    }
  };

  const onCloseReset = () => {
    setActiveStep(0);
    form.reset();
    setShowDialog(false);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <TransportChoose deliveryAddress={data?.deliveryAddress} />;
      case 1:
        if (form.getValues('deliveryType') === DeliveryType.CARGO_SINGLE) {
          return <GroupageCargo key={step} form={form} />;
        }
        return <DedicatedTransport key={step} />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <Dialog
      open={open}
      close={onCloseReset}
      PaperProps={{
        sx: {
          width: '1088px',
          maxWidth: '1088px',
        },
      }}>
      <DialogTitle marginBottom="12px">
        Введите данные для автоматического расчета логистики в КП от
        Исполнителей
      </DialogTitle>
      <DialogContent>
        <Form form={form} footer={false} loading={isSaving}>
          {getStepContent(activeStep)}
        </Form>
      </DialogContent>
      <DialogActions>
        {activeStep !== 0 && (
          <Box mr="16px">
            <Button color="secondary" onClick={handleBack}>
              Назад
            </Button>
          </Box>
        )}
        <Button
          loading={isSaving}
          onClick={
            activeStep === steps.length - 1
              ? onSubmit && form.handleSubmit(onSubmit)
              : handleNext
          }>
          {activeStep === steps.length - 1 ? 'Сохранить' : 'Далее'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
