import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ContractorShipping = lazy(
  () =>
    import(
      /* webpackChunkName: 'ContractorShipping' */
      /* webpackMode: "lazy" */
      `./shipping`
    )
);

export const LazyContractorShipping: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ContractorShipping />
  </Suspense>
);
