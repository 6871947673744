import { FC, ReactNode } from 'react';
import { OverflowTooltip, Typography, Tooltip } from 'src/components/ui';
import { InfoHelpIcon } from 'src/assets/icons';
import styles from './info-row.module.scss';

interface IInfoRowProps {
  label?: ReactNode;
  value: ReactNode;
  noMargin?: boolean;
  className?: string;
  overflowTooltip?: boolean;
  tooltip?: ReactNode;
  tooltipPlacement?: 'label' | 'value';
  variant?: 'default' | 'secondary';
  orientation?: 'vertical' | 'horizontal';
}

const config = {
  default: {
    labelColor: 'secondary',
    labelVariant: undefined,
    valueColor: 'text.primary',
    valueVariant: 'subtitle1',
    marginBottom: undefined,
  },
  secondary: {
    labelColor: 'text.primary',
    labelVariant: 'body2',
    valueColor: 'secondary',
    valueVariant: 'body2',
    marginBottom: '8px',
  },
} as const;

export const InfoRow: FC<IInfoRowProps> = ({
  label,
  value,
  noMargin,
  className,
  overflowTooltip,
  tooltip,
  tooltipPlacement = 'label',
  variant = 'default',
  orientation = 'vertical',
}) => (
  <Typography
    color={config[variant].valueColor}
    variant={config[variant].valueVariant}
    display="flex"
    flexDirection={orientation === 'vertical' ? 'column' : 'row'}
    className={className}
    mb={noMargin ? 0 : config[variant].marginBottom}
    whiteSpace={orientation === 'horizontal' ? 'nowrap' : undefined}>
    {label && (
      <div className={styles.container}>
        <Typography
          color={config[variant].labelColor}
          variant={config[variant].labelVariant}
          mb="4px"
          mr={orientation === 'horizontal' ? '6px' : 0}>
          {label}
          {orientation === 'horizontal' && ':'}
        </Typography>

        {tooltip && tooltipPlacement === 'label' && (
          <Tooltip title={tooltip}>
            <InfoHelpIcon />
          </Tooltip>
        )}
      </div>
    )}
    {value && overflowTooltip && typeof value === 'string' ? (
      <OverflowTooltip variant="inherit" text={value} />
    ) : (
      <div className={styles.container}>
        {value || '—'}
        {tooltip && tooltipPlacement === 'value' && (
          <Tooltip title={tooltip}>
            <InfoHelpIcon />
          </Tooltip>
        )}
      </div>
    )}
  </Typography>
);
