import { FC, ReactElement, useState } from 'react';
import { ArrowDown } from 'src/assets/icons';
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/ui';
import { Stack } from '@mui/material';

export interface IFinanceAccordionItemProps {
  status: string;
  title: string;
  description: string;
  getIcon: (statusValue: string) => ReactElement;
}

export const FinanceAccordionItem: FC<IFinanceAccordionItemProps> = ({
  title,
  status,
  description,
  getIcon,
}) => {
  const [expand, setExpand] = useState(false);
  const toggleAccordion = () => {
    if (!description) {
      return;
    }

    setExpand((prev) => !prev);
  };

  return (
    <Accordion
      expanded={expand}
      disableGutters
      sx={{
        '&': {
          boxShadow: 'none',
          borderBottom: '1px dashed #DEDEDE',
          paddingTop: '12px',
          paddingBottom: '8px',
        },
        '&:before': {
          content: 'none',
        },
        '&:last-of-type, &:first-of-type': {
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        },
        '&:first-of-type': {
          paddingTop: 0,
          marginTop: '-12px',
        },
      }}>
      <AccordionSummary
        sx={{
          padding: 0,
        }}
        expandIcon={description ? <ArrowDown /> : null}
        id="panel1-header"
        onClick={toggleAccordion}>
        <Stack direction="row" gap="12px" alignItems="center">
          {getIcon(status)}
          <div>
            <Typography variant="h4" display="inline" marginRight="4px">
              {title}
            </Typography>
          </div>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          paddingBottom: '8px',
        }}>
        <Stack gap="12px" sx={{ paddingLeft: '34px' }}>
          <Typography variant="subtitle2" marginBottom="0">
            {description}
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
