import { FC } from 'react';
import {
  ConversationEntityType,
  DocumentEntityTypes,
  DocumentTypes,
  ICustomerCommercialOffer,
} from 'src/models';
import { InfoRow, NumberInput, Typography } from 'src/components/ui';
import { AttachFiles, OpenChat } from 'src/components/widgets/index';
import { formatServerDate } from 'src/utils';

import styles from './customer-commercial-offer.module.scss';

export interface ICommercialOfferProps {
  offer: ICustomerCommercialOffer;
  businessRequestId: number;
  orderProcessingStarted: boolean;
  preview?: boolean;
}

export const CustomerCommercialOffer: FC<ICommercialOfferProps> = ({
  offer,
  businessRequestId,
  orderProcessingStarted,
  preview,
}) => (
  <div className={styles.contentWrapper}>
    <div className={styles.block}>
      <div className={styles.contentBlock}>
        <Typography variant="h3" mb="24px">
          Сумма поставки
        </Typography>
        <div className={styles.inputBlock}>
          <div className={styles.container}>
            <InfoRow
              label="Стоимость, с НДС"
              value={
                <NumberInput
                  mode="price"
                  displayType="text"
                  value={offer.cost}
                />
              }
            />
          </div>
          <div className={styles.container}>
            <InfoRow
              label="Логистика"
              value={offer.shippingIncluded ? 'Включена' : 'Не включена'}
            />
          </div>
        </div>
      </div>
      {orderProcessingStarted || preview ? null : (
        <OpenChat
          entityType={ConversationEntityType.BUSINESS_REQUEST}
          entityId={businessRequestId}
          mode="contragent"
          sx={{ marginBottom: '24px' }}
        />
      )}

      <InfoRow
        label="Условия оплаты"
        value={
          offer.paymentConditions && (
            <Typography whiteSpace="pre-wrap">
              {offer.paymentConditions}
            </Typography>
          )
        }
      />
      <InfoRow
        label="Комментарий"
        value={
          offer.comment && (
            <Typography whiteSpace="pre-wrap">{offer.comment}</Typography>
          )
        }
      />

      <AttachFiles
        documentOptions={{
          entityType: DocumentEntityTypes.COMMERCIAL_OFFER_FOR_CUSTOMER,
          documentType: DocumentTypes.COMMERCIAL_OFFER,
          entityId: offer.commercialOfferId,
        }}
        formItemName="documents.COMMERCIAL_OFFER"
        showDownloadAllButton={!preview}
      />
    </div>
    <div className={styles.block}>
      <div className={styles.contentBlock}>
        <Typography variant="h3" mb="24px">
          Сроки
        </Typography>
        <div className={styles.inputBlock}>
          <div className={styles.container}>
            <InfoRow
              noMargin
              label="Действие КП"
              value={
                offer.dueDateAt
                  ? `до ${formatServerDate(offer.dueDateAt, 'dd.MM.yyyy')}`
                  : 'Дата не указана'
              }
            />
          </div>
          <div className={styles.container}>
            <InfoRow
              noMargin
              label="Поставка, в к.д."
              value={offer.supplyDuration}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
