import {
  FC,
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
  RefObject,
} from 'react';
import { Box } from 'src/components/ui';
import styles from './scroll-panel.module.scss';

type ScrollPanelProps = {
  countColumns: number;
  containerRef: RefObject<HTMLDivElement>;
  initialScrollWidth?: number;
  initialScrollLeft?: number;
};

const COLUMN_WIDTH = 364;
const PADDING = 5;
const MARK_WIDTH = 28;
const SCALE = (PADDING + MARK_WIDTH) / COLUMN_WIDTH;

export const ScrollPanel: FC<ScrollPanelProps> = ({
  countColumns,
  containerRef,
  initialScrollWidth,
  initialScrollLeft,
}) => {
  const [shiftX, setShiftX] = useState(0);
  const [mode, setMode] = useState<'slider' | 'DnD'>('DnD');

  const slider = useRef<HTMLDivElement>(null);
  const sliderContainer = useRef<HTMLDivElement>(null);
  const containerScrollLeft = useRef(0);

  const scrollbarParams = useMemo(() => {
    const { scrollWidth = 0, clientWidth = 0 } = containerRef.current || {};
    const containerStep = (scrollWidth - clientWidth) / 100;

    const scrollbarWidth = scrollWidth * SCALE;
    const sliderWidth = clientWidth * SCALE;
    const sliderStep = (scrollbarWidth - sliderWidth) / 100;
    const rightEdge = scrollbarWidth - sliderWidth;

    const marks = [];
    for (let i = 0; i < countColumns; i++) {
      marks.push({ value: i * (MARK_WIDTH + PADDING) + PADDING });
    }

    return {
      scrollbarWidth,
      sliderWidth,
      sliderStep,
      rightEdge,
      containerStep,
      marks,
    };
  }, [countColumns, containerRef]);

  useEffect(() => {
    if (
      initialScrollLeft &&
      initialScrollLeft !== 0 &&
      initialScrollWidth &&
      initialScrollWidth !== 0 &&
      slider.current
    ) {
      const currentContainerRef = containerRef.current!;
      const scrollWidth = initialScrollWidth;
      const { clientWidth } = currentContainerRef;
      const step = (scrollWidth - clientWidth) / 100;
      const left = (initialScrollLeft / step) * scrollbarParams.sliderStep - 1;
      slider.current.style.left = `${left}px`;
      containerScrollLeft.current = initialScrollWidth;
    }
  }, []);

  useEffect(() => {
    const currentContainerRef = containerRef.current;

    const setSliderPosition = () => {
      if (
        currentContainerRef &&
        currentContainerRef.scrollLeft !== containerScrollLeft.current &&
        slider.current &&
        mode === 'DnD'
      ) {
        const { scrollWidth, clientWidth, scrollLeft } = currentContainerRef;
        const step = (scrollWidth - clientWidth) / 100;
        const left = (scrollLeft / step) * scrollbarParams.sliderStep - 1;
        slider.current.style.left = `${left}px`;
        containerScrollLeft.current = scrollLeft;
      }
    };

    currentContainerRef?.addEventListener('scroll', setSliderPosition);

    return () => {
      currentContainerRef?.removeEventListener('scroll', setSliderPosition);
    };
  }, [scrollbarParams.sliderStep, mode, containerRef]);

  const scrollContainerBy = useCallback(
    (distance: number) => {
      if (containerRef.current) {
        const left = distance - containerRef.current.scrollLeft;
        containerRef.current.scrollBy({ left });
      }
    },
    [containerRef]
  );

  const getDistanceScroll = useCallback(
    (left: number) => {
      if (left < 0) {
        left = 0;
      }

      if (left > scrollbarParams.rightEdge) {
        left = scrollbarParams.rightEdge;
      }

      const percentScroll = left / scrollbarParams.sliderStep;
      const distanceScroll = percentScroll * scrollbarParams.containerStep;
      return {
        left: left - 1,
        distanceScroll,
      };
    },
    [
      scrollbarParams.rightEdge,
      scrollbarParams.containerStep,
      scrollbarParams.sliderStep,
    ]
  );

  const onSliderMouseMove = useCallback(
    (e: any) => {
      if (sliderContainer.current && slider.current) {
        const newLeft =
          e.clientX -
          shiftX -
          sliderContainer.current.getBoundingClientRect().left;
        const { left, distanceScroll } = getDistanceScroll(newLeft);

        slider.current.style.left = `${left}px`;
        scrollContainerBy(distanceScroll);
      }
    },
    [scrollContainerBy, shiftX, getDistanceScroll]
  );

  const onSliderMouseUp = useCallback(() => {
    document.removeEventListener('mouseup', onSliderMouseUp);
    document.removeEventListener('mousemove', onSliderMouseMove);
    setMode('DnD');
  }, [onSliderMouseMove]);

  const onSliderMouseDown = useCallback(
    (e: any) => {
      if (slider.current) {
        setMode('slider');
        setShiftX(e.clientX - slider.current.getBoundingClientRect().left);
        document.addEventListener('mousemove', onSliderMouseMove);
        document.addEventListener('mouseup', onSliderMouseUp);
      }
    },
    [onSliderMouseMove, onSliderMouseUp]
  );

  const onSliderContainerMouseDown = useCallback(
    (e: any) => {
      if (sliderContainer.current) {
        setMode('slider');

        if (slider.current) {
          if (!slider.current.contains(e.target)) {
            const newLeft =
              e.clientX -
              scrollbarParams.sliderWidth / 2 -
              sliderContainer.current.getBoundingClientRect().left;
            const { left, distanceScroll } = getDistanceScroll(newLeft);

            slider.current.style.left = `${left}px`;

            scrollContainerBy(distanceScroll);
          }
        }
      }
    },
    [getDistanceScroll, scrollContainerBy, scrollbarParams.sliderWidth]
  );

  return (
    <Box
      className={styles.wrapper}
      ref={sliderContainer}
      onMouseDown={onSliderContainerMouseDown}
      onMouseUp={() => setMode('DnD')}
      sx={{ width: scrollbarParams.scrollbarWidth }}>
      <div className={styles.wrapperContent}>
        <div
          role="button"
          tabIndex={0}
          aria-label="Move"
          className={styles.slider}
          style={{ width: scrollbarParams.sliderWidth }}
          ref={slider}
          onMouseDown={onSliderMouseDown}
          onDragStart={() => false}
        />
        {scrollbarParams.marks.map((mark, index) => (
          <div
            key={index}
            className={styles.mark}
            style={{ left: mark.value, width: MARK_WIDTH }}
          />
        ))}
      </div>
    </Box>
  );
};
