import { FC } from 'react';
import {
  ContractorStatusCode,
  IContractorRequest,
  IStatusHistory,
} from 'src/models';
import { formatServerDate } from 'src/utils';
import { Alert } from 'src/components/ui';
import { isExpiredCommercialOfferDueDate } from 'src/utils/business-request';

export const ContractorRequestStatus: FC<{
  contractorRequest: IContractorRequest;
  statusHistory: IStatusHistory;
}> = ({ contractorRequest, statusHistory }) => {
  const currentStatus = contractorRequest.status?.code;

  if (contractorRequest.exclusion) {
    return (
      <Alert
        content="Вы были исключены из участия в запросе"
        tooltip={`Причина: ${contractorRequest.exclusion.cause}`}
      />
    );
  }

  if (
    currentStatus ===
    ContractorStatusCode.SPECIFICATION_SIGNED_WITH_ANOTHER_CONTRACTOR
  ) {
    return (
      <Alert
        content="По запросу подписана спецификация с другим исполнителем"
        tooltip={`${formatServerDate(
          statusHistory.passedStatuses[statusHistory.passedStatuses.length - 1]
            ?.createdAt,
          'dd.MM.yyyy'
        )}`}
      />
    );
  }

  if (
    isExpiredCommercialOfferDueDate(contractorRequest.commercialOfferDueDate) &&
    [
      ContractorStatusCode.NEW_REQUEST,
      ContractorStatusCode.IN_PROGRESS,
      ContractorStatusCode.REBIDDING_IN_PROGRESS,
    ].includes(currentStatus as ContractorStatusCode)
  ) {
    return (
      <Alert
        content="Вышел срок подачи КП"
        tooltip={formatServerDate(
          contractorRequest.commercialOfferDueDate,
          'dd.MM.yyyy'
        )}
      />
    );
  }

  return null;
};
