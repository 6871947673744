import { useCallback, useEffect } from 'react';

const ITEMS_HEIGHT = 76;
const SCROLL_OFFSET_THRESHOLD = -ITEMS_HEIGHT * 10;

export const useItemListScroll = (
  elementRef: React.RefObject<HTMLDivElement>,
  cb: () => void,
  numberOfElements?: number,
  totalElements?: number
) => {
  const handleScroll = useCallback(() => {
    const element = elementRef.current;

    if (!element || !numberOfElements || !totalElements) {
      return;
    }

    const { scrollHeight, scrollTop } = element;
    const scrollY = scrollHeight - scrollTop;
    const height = element.offsetHeight;
    const offset = height - scrollY;

    if (offset > SCROLL_OFFSET_THRESHOLD) {
      if (numberOfElements >= totalElements) {
        return;
      }

      cb();
    }
  }, [cb, elementRef, numberOfElements, totalElements]);

  useEffect(() => {
    const element = elementRef.current;

    element?.addEventListener('scroll', handleScroll);

    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, elementRef]);
};
