import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const NewsForm = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminNewsForm' */
      /* webpackMode: "lazy" */
      `./news-form`
    )
);

export const LazyNewsForm: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <NewsForm />
  </Suspense>
);
