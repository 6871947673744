import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const AdminPayments = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminPayments' */
      /* webpackMode: "lazy" */
      `./payments`
    )
);

export const LazyPayments: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <AdminPayments />
  </Suspense>
);
