import { FC } from 'react';
import { Skeleton } from 'src/components/ui';

export const TableSkeleton: FC = () => (
  <>
    <Skeleton
      height="80px"
      sx={{ marginRight: '12px', marginBottom: '4px', marginLeft: '12px' }}
    />
    <Skeleton
      height="80px"
      sx={{ marginRight: '12px', marginBottom: '4px', marginLeft: '12px' }}
    />
    <Skeleton
      height="80px"
      sx={{ marginRight: '12px', marginBottom: '4px', marginLeft: '12px' }}
    />
  </>
);
