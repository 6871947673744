import { FC } from 'react';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { Dialog as MuiDialog } from '@mui/material';
import { IconButton } from 'src/components/ui';
import { CloseIcon } from 'src/assets/icons';

// TODO: Переделать позицию кнопки нормально
export type TDialogProps = DialogProps & {
  close?: () => void;
  width?: string;
  closeBtnPosition?: {
    right: {
      xs: number;
      sm: number;
    };
    top: {
      xs: number;
      sm: number;
    };
  };
};

export const Dialog: FC<TDialogProps> = ({
  close,
  width = 'auto',
  closeBtnPosition = {
    right: {
      xs: 22,
      sm: 28,
    },
    top: {
      xs: 24,
      sm: 44,
    },
  },
  children,
  ...props
}) => (
  <MuiDialog
    PaperProps={{
      sx: {
        width,
      },
    }}
    {...props}>
    {close && (
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: {
            xs: closeBtnPosition.right.xs,
            sm: closeBtnPosition.right.sm,
          },
          top: { xs: closeBtnPosition.top.xs, sm: closeBtnPosition.top.sm },
        }}>
        <CloseIcon />
      </IconButton>
    )}
    {children}
  </MuiDialog>
);
