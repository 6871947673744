import { FC } from 'react';
import classNames from 'classnames';
import {
  Checkbox,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  SvgIcon,
} from 'src/components/ui';
import { DropdownArrow } from 'src/assets/icons';
import { TTechnicalOpportunity } from 'src/models';
import styles from './tag-select.module.scss';

interface ITagSelectProps {
  value?: TTechnicalOpportunity;
  onChange?: (v: TTechnicalOpportunity) => void;
}

export const TagSelect: FC<ITagSelectProps> = ({
  value = {} as TTechnicalOpportunity,
  onChange,
}) => {
  const list = {
    ...value,
    subcategories: value.subcategories.filter((tag) => !tag.hidden),
  } as TTechnicalOpportunity;

  const checkedSubTags = list.subcategories.filter((subtag) => subtag.checked);
  const allSubTag = {
    id: '0',
    name: 'Выбрать все',
    checked: checkedSubTags.length === list.subcategories.length,
  };

  const handleClickSubTag = (id: number, checked: boolean) => {
    onChange?.({
      ...value,
      checked: checked || checkedSubTags.length > 1,
      subcategories: value.subcategories.map((tag) =>
        tag.id === id ? { ...tag, checked } : tag
      ),
    });
  };

  const handleToggleAll = (checked: boolean) => {
    onChange?.({
      ...value,
      checked,
      subcategories: value.subcategories.map((tag) => ({ ...tag, checked })),
    });
  };

  return (
    <Box borderBottom={!list.hidden ? '1px solid #DEDEDE' : 0}>
      {list.subcategories.length === 0
        ? !list.hidden && (
            <Box mt="12px" mb="12px" className={styles.accordionBox}>
              <FormControlLabel
                label={
                  <div className={styles.labelWrapper}>
                    <div>{list.name}</div>
                    <div className={styles.labelTags}>
                      {checkedSubTags.length}/{list.subcategories.length}
                    </div>
                  </div>
                }
                onClick={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    checked={list.checked}
                    onChange={(_, checked) => handleToggleAll(checked)}
                  />
                }
              />
            </Box>
          )
        : !list.hidden && (
            <Accordion className={styles.accordion}>
              <AccordionSummary
                className={styles.accordionSummary}
                expandIcon={
                  <SvgIcon
                    icon={DropdownArrow}
                    width="14px"
                    height="8px"
                    strokeColor="text.primary"
                    strokeWidth="1.2"
                  />
                }>
                <Box className={styles.accordionBox}>
                  <FormControlLabel
                    label={
                      <div className={styles.labelWrapper}>
                        <div>{list.name}</div>
                        <div className={styles.labelTags}>
                          {checkedSubTags.length}/{list.subcategories.length}
                        </div>
                      </div>
                    }
                    onClick={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        checked={allSubTag.checked}
                        onChange={(_, checked) => handleToggleAll(checked)}
                        indeterminate={list.checked && !allSubTag.checked}
                      />
                    }
                  />
                  {checkedSubTags.length >= 2 &&
                  checkedSubTags.length < list.subcategories.length ? (
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        handleToggleAll(false);
                      }}
                      variant="text"
                      color="error">
                      Очистить
                    </Button>
                  ) : null}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Chip
                  key={allSubTag.id}
                  label={allSubTag.checked ? 'Очистить все' : allSubTag.name}
                  clickable={false}
                  className={styles.chip}
                  onClick={() => handleToggleAll(!allSubTag.checked)}
                />
                {list.subcategories.map((tag) => (
                  <Chip
                    key={tag.id}
                    label={tag.name}
                    clickable={false}
                    className={classNames(
                      styles.chip,
                      tag.checked && styles.chipActive
                    )}
                    onClick={() => handleClickSubTag(tag.id, !tag.checked)}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
    </Box>
  );
};
