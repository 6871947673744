import { ROLES } from 'src/constants';

export const ROLE_NAMES = {
  [ROLES.CONTRACTOR]: 'Исполнитель',
  [ROLES.CUSTOMER]: 'Заказчик',
  [ROLES.KAM]: 'КАМ',
  [ROLES.KAM_PLUS]: 'КАМ+',
  [ROLES.ADMIN]: 'Администратор',
  [ROLES.SYSTEM]: 'Автоматически',

  [ROLES.TECH_EXPERT]: 'Техэксперт',
  [ROLES.BACK_OFFICE]: 'Бэк Офис',
  [ROLES.COMMERCIAL_DIRECTOR]: 'Коммерческий директор',
  [ROLES.LOGISTIC]: 'Логист',
  [ROLES.PARTNER_RELATIONS_MANAGER]:
    'Руководитель отдела по работе с партнерами',

  [ROLES.GENERAL_MANAGER]: 'Генеральный директор',
  [ROLES.FINANCIAL_MANAGER]: 'Финансовый менеджер',
  [ROLES.OFFICE_MANAGER]: 'Офис менеджер',
  [ROLES.LAWYER]: 'Юрист',
};
