import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const RestorePassword = lazy(
  () =>
    import(
      /* webpackChunkName: "RestorePassword" */
      /* webpackMode: "lazy" */
      `./restore-password`
    )
);

export const LazyRestorePassword: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <RestorePassword />
  </Suspense>
);
