import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const SpecificRoleRequestPerformers = lazy(
  () =>
    import(
      /* webpackChunkName: 'ManagerRequestPerformers' */
      /* webpackMode: "lazy" */
      `./request-performers`
    )
);

export const LazySpecificRoleRequestPerformers: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <SpecificRoleRequestPerformers />
  </Suspense>
);
