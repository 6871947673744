import { ContractorStatusForKam } from 'src/models/contractor';
import {
  contractorApi,
  useGetContractorSobStatusQuery,
  useUpdateContractorEskStatusMutation,
  useUpdateContractorSobStatusMutation,
} from 'src/api/contractor';
import { useAppDispatch, useAppSelector } from 'src/store';
import { selectCurrentRole } from 'src/store/auth';
import { ROLES } from 'src/constants';

export const useEskAndSobStatus = (
  organizationId: string,
  contractorStatus?: ContractorStatusForKam
) => {
  const dispatch = useAppDispatch();
  const currentRole = useAppSelector(selectCurrentRole);

  const { data: sobStatus } = useGetContractorSobStatusQuery(
    Number(organizationId),
    {
      refetchOnMountOrArgChange: true,
      skip: currentRole === ROLES.CUSTOMER || currentRole === ROLES.CONTRACTOR,
    }
  );

  const [updateSobStatus, { isLoading }] =
    useUpdateContractorSobStatusMutation();

  if (currentRole === ROLES.CUSTOMER || currentRole === ROLES.CONTRACTOR) {
    return {
      isReadyForEsqRequest: false,
      isEsqRequested: false,
      isExpectingCheckup: false,
      handleUpdateEskStatus: () => {},

      handleUpdateSobStatus: () => {},
      isSobStatusApproved: false,
      isSobStatusRefused: false,
      sobStatusComment: '',
    };
  }

  const [updateEskStatus] = useUpdateContractorEskStatusMutation();

  const isContractorStatusPropsUpdated =
    contractorStatus === ContractorStatusForKam.PROPS_UPDATED;

  const isContractorStatusEskAcquired =
    contractorStatus === ContractorStatusForKam.ESK_ACQUIRED;

  // TODO: Добавить enum в src/models/contractor.ts по аналогии с ContractorStatusForKam
  const isSobStatusEskRequested =
    sobStatus?.sobApproveStatus === 'ESK_REQUESTED';
  const isSobStatusApproved = sobStatus?.sobApproveStatus === 'APPROVED';
  const isSobStatusRefused = sobStatus?.sobApproveStatus === 'REFUSED';

  const isReadyForEsqRequest =
    isContractorStatusPropsUpdated &&
    !isSobStatusEskRequested &&
    !isSobStatusApproved &&
    !isSobStatusRefused;

  const isEsqRequested =
    isContractorStatusPropsUpdated && isSobStatusEskRequested;

  const isSobStatusExpectingCheckup =
    sobStatus?.sobApproveStatus === 'EXPECTING_CHECKUP';

  const isExpectingCheckup =
    isSobStatusExpectingCheckup && isContractorStatusEskAcquired;

  const handleUpdateEskStatus = async () => {
    try {
      await updateEskStatus({ organizationId }).unwrap();

      dispatch(contractorApi.util?.invalidateTags(['ContractorSobStatus']));
    } catch (e) {
      // TODO: error validation
    }
  };

  const handleUpdateSobStatus = async (data: {
    decision: string;
    comment: string;
  }) => {
    try {
      const params = {
        organizationId: Number(organizationId),
        sobApproveStatus: data.decision === 'yes' ? 'APPROVED' : 'REFUSED',
        sobComment: data.comment,
      };

      await updateSobStatus(params).unwrap();

      dispatch(contractorApi.util?.invalidateTags(['ContractorSobStatus']));

      // TODO: Бек не успевает обновить
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(0);
        }, 3000);
      });

      dispatch(
        contractorApi.util.invalidateTags([
          {
            type: 'ContractorStatusHistoryForKam',
          },
        ])
      );

      dispatch(
        contractorApi.util.invalidateTags([
          { type: 'ContractorStatusHistoryForContractor' },
        ])
      );
    } catch (e) {
      console.error(e);
    }
  };

  return {
    isReadyForEsqRequest,
    isEsqRequested,
    isExpectingCheckup,
    handleUpdateEskStatus,

    handleUpdateSobStatus,
    isSobStatusApproved,
    isSobStatusRefused,
    sobStatusComment: sobStatus?.sobComment || '',
  };
};
