import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import {
  TCategory,
  TCategoryList,
  TNews,
  TNewsList,
  TNewsUpdateImage,
} from 'src/models';
import { TNewsUpdateInput } from 'src/components/pages/admin/news/config/news-types';
import { TCategoryUpdate, TCategoryUpdateImage } from 'src/models/news';
import { DIRECTION } from '../components/ui/filters/types';

export type TSortNewsParams = 'CREATED_AT';

export type TGetNewsCategoriesParams = {
  firstElementIndex: number;
  lastElementIndex: number;
};

export type TNewsListParams = {
  categoryId?: number;
  pagingDto: {
    page: number;
    limit: number;
  };
  sortDto: {
    field: TSortNewsParams;
    order: DIRECTION;
  };
};

export const defaultSortNews: TNewsListParams['sortDto'] = {
  field: 'CREATED_AT',
  order: DIRECTION.DESC,
};

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getNewsCategories: builder.mutation<
      TCategoryList,
      TGetNewsCategoriesParams
    >({
      query: (body) => ({
        url: '/news-service/api/v2/category/all',
        method: 'POST',
        body,
      }),
      transformResponse(response: TCategoryList) {
        return {
          ...response,
          results: response.results.map((item) => ({
            ...item,
            customImage: '',
          })),
        };
      },
    }),
    getAllNews: builder.mutation<TNewsList, TNewsListParams>({
      query: (body) => ({
        url: '/news-service/api/v1/news/all-news',
        method: 'POST',
        body,
      }),
      transformResponse(response: TNewsList) {
        return {
          ...response,
          results: response.results.map((item) => ({
            ...item,
            customImage: '',
          })),
        };
      },
    }),
    createNews: builder.mutation<TNews, FormData>({
      query: (body) => ({
        url: '/news-service/api/v1/news',
        method: 'POST',
        body,
      }),
    }),
    createCategory: builder.mutation<
      Pick<TCategory, 'id' | 'name' | 'imageId' | 'publishStatus'>,
      FormData
    >({
      query: (body) => ({
        url: '/news-service/api/v1/category',
        method: 'POST',
        body,
      }),
    }),
    updateCategory: builder.mutation<TCategory, TCategoryUpdate>({
      query: ({ name, id, publishStatus }) => ({
        url: `/news-service/api/v1/category/${id}`,
        method: 'PUT',
        body: {
          name,
          publishStatus,
        },
      }),
    }),
    updateCategoryImage: builder.mutation<void, TCategoryUpdateImage>({
      query: ({ id, file }) => ({
        url: `/news-service/api/v1/category/${id}`,
        method: 'PATCH',
        body: file,
      }),
    }),
    deleteCategory: builder.mutation<
      void,
      { currentId: number; newId: number }
    >({
      query: ({ currentId, newId }) => ({
        url: `/news-service/api/v1/category/${currentId}`,
        method: 'DELETE',
        params: { newId },
      }),
    }),
    downloadImage: builder.mutation<string, number>({
      query: (id) => ({
        url: `/news-service/api/v1/images/${id}`,
        cache: 'no-cache',
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'image/png' ||
          response.headers.get('Content-Type') === 'image/jpeg'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob) {
        return URL.createObjectURL(response);
      },
    }),
    updateNewsById: builder.mutation<TNews, Partial<TNewsUpdateInput>>({
      query: (body) => ({
        url: `/news-service/api/v1/news/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    updateNewsImage: builder.mutation<void, TNewsUpdateImage>({
      query: ({ id, file }) => ({
        url: `/news-service/api/v1/news/${id}`,
        method: 'PATCH',
        body: file,
      }),
    }),
    deleteNewsById: builder.mutation<void, number>({
      query: (id) => ({
        url: `/news-service/api/v1/news/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllNewsMutation,
  useCreateNewsMutation,
  useCreateCategoryMutation,
  useDownloadImageMutation,
  useUpdateNewsByIdMutation,
  useUpdateNewsImageMutation,
  useUpdateCategoryMutation,
  useUpdateCategoryImageMutation,
  useDeleteCategoryMutation,
  useDeleteNewsByIdMutation,
  useGetNewsCategoriesMutation,
} = newsApi;
