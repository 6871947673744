import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { TManagerList, TResponse } from 'src/models/chat';
import { baseQuery } from 'src/store/utils';

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery,
  endpoints: (builder) => ({
    createNewChatContragent: builder.mutation<
      TResponse,
      { entityType: string; entityId: number }
    >({
      query: (data) => ({
        url: 'chat-server/api/v1/chat/contragent/conversation',
        method: 'POST',
        body: data,
        responseHandler: (response) => response.json(),
      }),
      transformResponse: (response: any, metaData: any) => ({
        chatData: response,
        status: metaData.response.status,
      }),
    }),
    createNewChatManager: builder.mutation<
      TResponse,
      { entityType: string; entityId: number }
    >({
      query: (data) => ({
        url: 'chat-server/api/v1/chat/manager/conversation',
        method: 'POST',
        body: data,
        responseHandler: (response) => response.json(),
      }),
      transformResponse: (response: any, metaData: any) => ({
        chatData: response,
        status: metaData.response.status,
      }),
    }),
    getManagerList: builder.mutation<TManagerList, void>({
      query: () => ({
        url: 'chat-server/api/v1/user/getKamManagers',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateNewChatContragentMutation,
  useCreateNewChatManagerMutation,
  useGetManagerListMutation,
} = chatApi;

export const useCreateNewChatMutation = (mode: 'contragent' | 'manager') => {
  if (mode === 'manager') {
    return useCreateNewChatManagerMutation;
  }

  return useCreateNewChatContragentMutation;
};
