import { IContractorCommercialOffer } from 'src/models';
import { businessRequestApi } from '../business-request';
import { contractorCommercialOfferSorting } from '../../utils';

interface ICreateCommercialOfferParams {
  contractorRequestId: number;
  cost?: number | null;
  dueDateAt?: string | null;
  supplyDuration?: number;
  paymentConditions?: string;
  comment?: string;
  auctionBidId?: number;
  rebiddingId?: number;
}

interface IUpdateCommercialOfferParams extends ICreateCommercialOfferParams {
  commercialOfferId: number;
}

export const contractorCommercialOfferApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    createContractorCommercialOffer: builder.mutation<
      IContractorCommercialOffer,
      ICreateCommercialOfferParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/contractor/commercial-offer',
        method: 'POST',
        body,
      }),
    }),

    updateContractorCommercialOffer: builder.mutation<
      IContractorCommercialOffer,
      IUpdateCommercialOfferParams
    >({
      query: ({ commercialOfferId, ...body }) => ({
        url: `business-requests/api/v1/contractor/commercial-offer/${commercialOfferId}`,
        method: 'PUT',
        body,
      }),
    }),

    getContractorCommercialOffers: builder.query<
      IContractorCommercialOffer[],
      number
    >({
      query: (id) => ({
        url: `business-requests/api/v1/contractor/contractor-request/${id}/commercial-offer`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: 'getContractorCommercialOffers', id: arg },
      ],
      transformResponse: (data: IContractorCommercialOffer[]) =>
        contractorCommercialOfferSorting(data),
    }),

    sendContractorCommercialOffer: builder.mutation<
      IContractorCommercialOffer,
      {
        commercialOfferId: number;
        contractorRequestId: number;
        businessRequestId?: number;
      }
    >({
      query: ({ commercialOfferId }) => ({
        url: `business-requests/api/v1/contractor/commercial-offer/${commercialOfferId}/for-manager`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) {
          return [];
        }
        if (arg.businessRequestId) {
          return [
            {
              type: 'getContractorStatusHistory',
              id: arg.contractorRequestId,
            },
            { type: 'getContractorRebidding', id: arg.businessRequestId },
          ];
        }
        return [
          {
            type: 'getContractorStatusHistory',
            id: arg.contractorRequestId,
          },
        ];
      },
    }),
  }),
});

export const {
  useCreateContractorCommercialOfferMutation,
  useGetContractorCommercialOffersQuery,
  useUpdateContractorCommercialOfferMutation,
  useSendContractorCommercialOfferMutation,
} = contractorCommercialOfferApi;
