export const getMessageHTML = (tree: any) => {
  const stack = [tree];
  let result;

  while (stack.length) {
    const node = stack.pop();

    if (node.type?.includes('text/plain')) {
      result = node.content.trim();
    }

    if (node.type?.includes('text/html')) {
      return node.content;
    }

    if (node.parts?.length) {
      stack.push(...node.parts);
    }
  }

  return result;
};
