import { CSSProperties, FC, ReactNode } from 'react';
import { Link } from 'src/components/ui';
import { Link as RouterLink, useLocation } from 'react-router-dom';

type TReferrerLinkProps = {
  to: string;
  children: ReactNode;
  style?: CSSProperties;
};

export const ReferrerLink: FC<TReferrerLinkProps> = ({
  children,
  to,
  style,
}) => {
  const location = useLocation();
  return (
    <Link
      component={RouterLink}
      state={{ path: location.pathname }}
      to={to}
      style={style}>
      {children}
    </Link>
  );
};
