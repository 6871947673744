import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  Button,
  Card,
  OverflowTooltip,
  Result,
  Skeleton,
  Typography,
} from 'src/components/ui';
import { IStatusHistory } from 'src/models';
import { getErrorMessage } from 'src/store/utils';
import { formatServerDate, plural } from 'src/utils';
import { DotIcon } from 'src/assets/icons';
import { Stack } from '@mui/material';
import { differenceInCalendarDays, differenceInHours } from 'date-fns';
import { getBidStatusColor } from 'src/utils/bids';
import styles from './stepper.module.scss';

interface IStepperProps {
  statusHistory: IStatusHistory | undefined;
  isLoading: boolean;
  error: any;
  withCollapse?: boolean;
}

const MIN_COUNTER_ELEMENT = 3;

const getCurrentStatus = (dateLeft: Date, dateRight: Date) => {
  const diffInDays = Math.abs(differenceInCalendarDays(dateLeft, dateRight));

  if (diffInDays >= 1) {
    return {
      count: plural(diffInDays, '$d день', '$d дня', '$d дней'),
      color: getBidStatusColor(diffInDays),
    };
  }

  const diffInHours = Math.abs(differenceInHours(dateLeft, dateRight));

  if (diffInHours < 1) {
    return {
      count: 'Менее часа',
      color: getBidStatusColor(0),
    };
  }

  return {
    count: plural(diffInHours, '$d час', '$d часа', '$d часов'),
    color: getBidStatusColor(0),
  };
};

const getStatusList = (statusHistory: IStepperProps['statusHistory']) => {
  const passedLength = statusHistory?.passedStatuses.length || 0;
  const upcomingLength = statusHistory?.upcomingStatuses.length || 0;

  const currentStatusTimeInfo = (
    step: IStatusHistory['passedStatuses'][0],
    index: number
  ) => {
    if (!statusHistory) {
      return {
        count: '0 дней',
        color: getBidStatusColor(0),
      };
    }

    if (index === 0) {
      if (passedLength === 1) {
        return getCurrentStatus(new Date(step.createdAt), new Date());
      }

      const nextStatusTime = new Date(
        statusHistory.passedStatuses[index + 1].createdAt
      );

      return getCurrentStatus(new Date(step.createdAt), nextStatusTime);
    }

    if (index === passedLength - 1) {
      return getCurrentStatus(new Date(), new Date(step.createdAt));
    }

    return getCurrentStatus(
      new Date(step.createdAt),
      new Date(statusHistory.passedStatuses[index + 1].createdAt)
    );
  };

  return (
    <>
      {statusHistory?.passedStatuses.map((step, index) => (
        <li
          className={classNames(
            styles.listItem,
            styles.completed,
            index === 0 && styles.firstElement,
            passedLength - 1 === index &&
              upcomingLength === 0 &&
              styles.lastElement
          )}
          key={index}>
          <div className={styles.textWrapper}>
            <OverflowTooltip
              fontWeight="500"
              color="#0041A0"
              text={step.status.displayName}
            />
            <Stack direction="row" gap="12px" alignItems="center">
              <Typography variant="body2" color="#6A6A6A">
                {formatServerDate(step.createdAt, 'dd.MM.yyyy, HH:mm')}
              </Typography>
              <Stack direction="row" gap="4px" alignItems="center">
                <DotIcon
                  style={{
                    color: currentStatusTimeInfo(step, index).color,
                  }}
                />
                <Typography color="#7A8694" fontSize="10px">
                  {currentStatusTimeInfo(step, index).count}
                </Typography>
              </Stack>
            </Stack>
          </div>
        </li>
      ))}
      {statusHistory?.upcomingStatuses.map((element, index) => (
        <li
          key={index}
          className={classNames(
            styles.listItem,
            styles.notCompleted,
            upcomingLength - 1 === index && styles.lastElement
          )}>
          <div className={styles.textWrapper}>
            <OverflowTooltip
              fontWeight="500"
              color="#0041A0"
              text={element.displayName}
            />
            <Typography
              variant="body2"
              color="#6A6A6A"
              className={styles.description}>
              {element.description}
            </Typography>
          </div>
        </li>
      ))}
    </>
  );
};

export const Stepper: FC<IStepperProps> = ({
  statusHistory,
  isLoading,
  error,
  withCollapse = true,
}) => {
  const [isHiddenStepper, setIsHiddenStepper] = useState(true);
  const refListStatusHistory = useRef<HTMLUListElement | null>(null);

  const passedLength = statusHistory?.passedStatuses.length || 0;
  const upcomingLength = statusHistory?.upcomingStatuses.length || 0;
  const listStatusHistoryLength = passedLength + upcomingLength;
  const isMinElement = MIN_COUNTER_ELEMENT >= listStatusHistoryLength;

  const handleClickUnWrap = () => {
    setIsHiddenStepper((prevState) => !prevState);
  };

  useEffect(() => {
    if (!statusHistory || !refListStatusHistory.current || !isHiddenStepper) {
      return;
    }

    let scrollTo = 0;

    if (passedLength > 2) {
      for (let i = 0; i < passedLength; i++) {
        scrollTo +=
          refListStatusHistory.current?.children[i]?.clientHeight || 0;
      }
    }

    refListStatusHistory.current.scrollTop = scrollTo;
  }, [isHiddenStepper, passedLength, statusHistory]);

  if (isLoading || (!error && !statusHistory)) {
    return <Skeleton width="432px" height="300px" />;
  }

  if (error) {
    return (
      <Result
        noContentPadding
        className={styles.containerError}
        title={getErrorMessage(
          error,
          'Произошла ошибка при получении истории статусов'
        )}
      />
    );
  }

  return withCollapse ? (
    <Card noContentPadding className={styles.container}>
      <ul
        ref={refListStatusHistory}
        className={classNames(
          styles.list,
          isHiddenStepper && styles.isHiddenStepper
        )}>
        {getStatusList(statusHistory)}
      </ul>

      {!isMinElement && (
        <div className={styles.containerActions}>
          <Button variant="text" onClick={handleClickUnWrap}>
            {isHiddenStepper ? 'Развернуть' : 'Свернуть'}
          </Button>
        </div>
      )}
    </Card>
  ) : (
    <ul className={classNames(styles.list, styles.noOverflow)}>
      {getStatusList(statusHistory)}
    </ul>
  );
};
