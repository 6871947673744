import { FC } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateNewChatMutation } from 'src/api/chat';
import { Chat } from 'src/assets/icons';
import { Button } from 'src/components/ui';
import {
  ConversationEntityType,
  CurrentMessageWatchedStatus,
  IChat,
  MessageType,
  TResponse,
} from 'src/models';
import { AuthService, RolePermissionService } from 'src/services';
import { useAppDispatch } from 'src/store';
import { addChatToChatList, setCurrentChat } from 'src/store/chat';
import { TButton } from 'src/components/ui/button';
import { PERMISSIONS } from 'src/constants';
import styles from './open-chat.module.scss';

export type TOpenChatProps = {
  /**
   * Объект обсуждения создаваемого чата. Например, в случае
   * entityType = ConversationEntityType.CONTRACTOR, чат создается
   * для обсуждения вопросов по компании исполнителя.
   */
  entityType: ConversationEntityType;

  /**
   * Идентификатор объекта обсуждения чата. Например, в случае
   * entityType = ConversationEntityType.CONTRACTOR, необходимо передать
   * id компании исполнителя.
   */
  entityId: number;

  /**
   * В случае использования данного виджета на странице для роли менеджера,
   * необходимо передавать manager. В случае использования виджета
   * на странице для ролей заказчика или исполнителя, необходимо передать contragent.
   */
  mode: 'contragent' | 'manager';

  buttonText?: string;
} & Omit<TButton, 'onClick' | 'loading'>;

export const OpenChat: FC<TOpenChatProps> = ({
  entityType,
  entityId,
  mode,
  className,
  buttonText = 'Написать в чат',
  ...buttonProps
}) => {
  const [createNewChat, { isLoading }] = useCreateNewChatMutation(mode)();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userId } = AuthService;

  const addLastMessageData = (data: IChat) => {
    const resultData = { ...data };

    resultData.lastMessage = {
      id: data.conversationId,
      conversationId: data.conversationId,
      text: 'Создан новый чат',
      sendAt: new Date().toISOString(),
      authorUserId: userId,
      currentUserStatus: CurrentMessageWatchedStatus.NOT_WATCHED,
      otherUsersStatus: CurrentMessageWatchedStatus.NOT_WATCHED,
      messageType: MessageType.USER_WAS_JOINED_TO_CHAT,
    };

    return resultData;
  };

  const handleOpenChat = async () => {
    try {
      const response: TResponse = await createNewChat({
        entityType,
        entityId,
      }).unwrap();
      if (response.status === 201) {
        const updateChatDataInfo = addLastMessageData(response.chatData);

        dispatch(addChatToChatList(updateChatDataInfo));
        dispatch(setCurrentChat(response.chatData.conversationId));

        navigate(`/chat`, {
          state: { prevUrl: `${location.pathname}` },
        });
      } else if (response.status === 200 && response.chatData.conversationId) {
        dispatch(setCurrentChat(response.chatData.conversationId));

        navigate(`/chat`, {
          state: { prevUrl: `${location.pathname}` },
        });
      }
    } catch {
      // ignore
    }
  };

  if (!RolePermissionService.can(PERMISSIONS.VIEW_OPEN_CHAT_BUTTON)) {
    return null;
  }

  return (
    <Button
      variant={buttonText === '' ? 'text' : 'outlined'}
      className={classNames(styles.button, className)}
      endIcon={<Chat />}
      onClick={handleOpenChat}
      loading={isLoading}
      {...buttonProps}>
      {buttonText}
    </Button>
  );
};
