import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { downloadFile } from 'src/api/utils';

type TEventReport = {
  sort: {
    field: string;
    order: string;
  };
  paging: {
    page: number;
    limit: number;
  };
  searchQueries: string[];
  filter: {
    eventTypes: string[];
    initiatorRoles: string[];
    initiatorFio: string[];
    createdAt: {
      from: string;
      to: string;
    };
  };
};

type TPostReportByUsers = {
  sortDto: {
    field: string;
    order: string;
  };
  filter: {
    userIds: string[];
    fio: string[];
    organizationNames: string[];
    phones: string[];
    registrationDate: {
      from: string;
      to: string;
    };
    lastActivityDate: {
      from: string;
      to: string;
    };
    roles: string[];
    statuses: string[];
  };
  searchQueries: string[];
};

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    postEventReport: builder.query<null, TEventReport>({
      query: (data) => ({
        url: 'reports/api/v1/event-log',
        method: 'POST',
        body: data,
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/octet-stream'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob, metaData) {
        downloadFile(response, metaData);

        return null;
      },
    }),
    postReportByUsers: builder.query<null, TPostReportByUsers>({
      query: (data) => ({
        url: 'reports/api/v1/user',
        method: 'POST',
        body: data,
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/octet-stream'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob, metaData) {
        downloadFile(response, metaData);

        return null;
      },
    }),
    getReportByAllContractors: builder.query<
      null,
      {
        dateBegin?: string;
        dateEnd?: string;
      }
    >({
      query: (params) => ({
        url: `reports/api/v1/contractor/`,
        params,
      }),
    }),
    getReportByContractor: builder.query<null, number>({
      query: (id) => ({
        url: `reports/api/v1/contractor/${id}`,
      }),
    }),

    getReportByPeriod: builder.query<
      null,
      { entityType: string; documentType: string; reportPeriod: string }
    >({
      query: (params) => ({
        url: 'reports/api/v1/report',
        params,
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/octet-stream'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob, metaData) {
        downloadFile(response, metaData);

        return null;
      },
    }),
  }),
});

export const {
  useLazyPostEventReportQuery,
  useLazyPostReportByUsersQuery,
  useLazyGetReportByAllContractorsQuery,
  useLazyGetReportByContractorQuery,
  useLazyGetReportByPeriodQuery,
} = reportApi;
