import {
  DIRECTION,
  TConfigFilter,
  TSort,
} from 'src/components/ui/filters/types';
import { CheckboxForm } from 'src/components/ui/filters/components/filters-popover/components';
import {
  TCommunicationListSortKey,
  TCommunicationListParams,
} from 'src/api/communications/models';

export const filtersConfig: Array<TConfigFilter> = [
  {
    name: 'logins',
    label: 'КАМ',
    withSearch: true,
    placeholder: 'Поиск',
    render: (props) => <CheckboxForm {...props} />,
    variantList: [],
  },
];

export const defaultSort: TSort<TCommunicationListSortKey> = {
  field: 'sortDate',
  direction: DIRECTION.DESC,
};

export const defaultFilters: TCommunicationListParams['filter'] = {
  logins: [],
  relationFilterType: 'ALL',
  organizationRole: 'ALL',
};
