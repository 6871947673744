import { ILogisticsList, LogisticsListType } from 'src/models';

export const logisticsListMock: ILogisticsList[] = [
  {
    id: 0,
    type: LogisticsListType.CHAT,
    author: 'Алексей Виноградов',
    date: '18:00',
    label: 'Поподробнее?',
  },
  {
    id: 1,
    type: LogisticsListType.PHONE_CALL,
    author: 'Яковенко Андрей Игоревич',
    date: '17:38',
    label: 'Исходящий звонок. Разговор состоялся',
  },
  {
    id: 2,
    type: LogisticsListType.NOTE,
    author: 'Поздняков Евгений Викторович',
    date: '17:02',
    label: 'Отправить КП завтра до 17:00',
  },
  {
    id: 3,
    type: LogisticsListType.EMAIL_MESSAGE,
    author: 'Алексеева Валентина Валерьевна',
    date: '16:21',
    label: 'Автоматический ответ: Создание личного кабинета Metal',
  },
  {
    id: 4,
    type: LogisticsListType.EMAIL_MESSAGE,
    author: 'Алексеева Валентина Валерьевна',
    date: '14 дек',
    label: '[Северсталь] Заявка 25444027 - Новая заявка',
  },
  {
    id: 5,
    type: LogisticsListType.EMAIL_MESSAGE,
    author: 'Алексеева Валентина Валерьевна',
    date: '12 дек',
    label: 'Автоматический ответ: Создание личного кабинета Metal',
  },
  {
    id: 6,
    type: LogisticsListType.EMAIL_MESSAGE,
    author: 'Алексеева Валентина Валерьевна',
    date: '16:21',
    label: 'Автоматический ответ: Создание личного кабинета Metal',
  },
  {
    id: 7,
    type: LogisticsListType.EMAIL_MESSAGE,
    author: 'Алексеева Валентина Валерьевна',
    date: '16:21',
    label: 'Автоматический ответ: Создание личного кабинета Metal',
  },
  {
    id: 8,
    type: LogisticsListType.EMAIL_MESSAGE,
    author: 'Алексеева Валентина Валерьевна',
    date: '16:21',
    label: 'Автоматический ответ: Создание личного кабинета Metal',
  },
  {
    id: 9,
    type: LogisticsListType.EMAIL_MESSAGE,
    author: 'Алексеева Валентина Валерьевна',
    date: '16:21',
    label: 'Автоматический ответ: Создание личного кабинета Metal',
  },
];
