import { BusinessRequestSource } from 'src/models/bids-details';

export const BUSINESS_REQUEST_SOURCES_NAMES = {
  [BusinessRequestSource.WEBSITE]: 'Заявка с сайта',
  [BusinessRequestSource.TELEPHONY]: 'Заявка с телефона',
  [BusinessRequestSource.EXPOSITION]: 'Заявка с выставки',
  [BusinessRequestSource.WHATSAPP]: 'Заявка с WhatsApp',
  [BusinessRequestSource.EMAIL]: 'Заявка с email',
  [BusinessRequestSource.TENDER]: 'Заявка с тендера',
  [BusinessRequestSource.MANUAL]: 'Создана вручную',
  [BusinessRequestSource.ATTRACTION]: 'Привлечение',
  [BusinessRequestSource.HEAT]: 'Подогрев',
  [BusinessRequestSource.CUSTOMER]: 'Создана заказчиком на платформе',
};
