import { FC, MouseEvent } from 'react';
import { Typography, DialogTitle, DialogContent } from '@mui/material';

import { Box, Dialog } from 'src/components/ui';
import { TSubcategory, TVariantTag } from 'src/models/admin';
import { SubcategoryForm } from '../subcategory-form';

type TSubcategoryModal = {
  open: boolean;
  setOpen: (x: boolean) => void;
  subcategory: TSubcategory;
  updateForm?: (subTag: TVariantTag) => void;
};

export const SubcategoryModal: FC<TSubcategoryModal> = ({
  subcategory,
  open,
  setOpen,
  updateForm,
}) => {
  const onClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      close={() => setOpen(false)}
      onClose={onClose}
      PaperProps={{ onClick: (e: any) => e.stopPropagation() }}>
      <DialogTitle>Редактирование тега второго уровня</DialogTitle>
      <DialogContent>
        <Box paddingBottom="25px">
          <Typography mb={1}>
            Это действие приведет к переименованию данного тега у всех
            пользователей.
          </Typography>
          <SubcategoryForm
            updateForm={updateForm}
            subcategory={subcategory}
            onClose={() => setOpen(false)}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
