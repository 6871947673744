import { useEffect, useState } from 'react';

export const useDebounce = <T>(value: T, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (value === null || value === undefined) {
      return () => {};
    }

    const timer = setTimeout(() => setDebouncedValue(value), delay || 20);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};
