import {
  IManagerContractorSpecification,
  IManagerCustomerSpecification,
  IManagerContractorSpecificationAvailableContractor,
  IManagerContractorWinnerCriterion,
} from 'src/models';
import { businessRequestApi } from '../business-request';

export interface IContractorSpecificationSaveParams {
  businessRequestId: number;
  contractorOrganizationId: number;
  contractorCommercialOfferId?: number;
  cost?: number | string;
  supplyDuration?: number | string;
  paymentPeriodProlongation?: number | null;
  paymentConditions?: string | null;
  shippingIncluded: boolean;
  manualShippingCost: number | string | null;
  quickDeal: boolean;

  winner: {
    winnerCriteria: string[];
    comment: string;
    supplySumVisibleForOtherContractors: boolean;
  };
}

export interface ICustomerSpecificationSaveParams {
  businessApplicationId: number;
  costForCustomer?: number | null;
  costByContractor?: number | null;
  income?: number | null;
  supplyDuration?: number | null;
  paymentPeriodProlongation?: number | null;
  paymentConditions?: string | null;
  quickDeal?: boolean;
}

interface ISetContractorFinalVersionSentParams {
  specificationId: number;
  winnerCriteria: string[];
  comment: string;
  supplySumVisibleForOtherContractors: boolean;
  businessRequestId: number;
}

export const managerSpecificationApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getManagerContractorSpecifications: builder.query<
      IManagerContractorSpecification,
      number
    >({
      query: (businessRequestId) => ({
        url: `business-requests/api/v1/manager/business-request/${businessRequestId}/contractor-specification`,
        method: 'GET',
        params: { statusNotIn: ['DELETED'] },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getManagerContractorSpecifications', id: arg },
        { type: 'getManagerContractorSpecifications' },
      ],
      transformResponse: (data: IManagerContractorSpecification[]) => data[0],
    }),

    getManagerCustomerSpecifications: builder.query<
      IManagerCustomerSpecification,
      number
    >({
      query: (businessRequestId) => ({
        url: `business-requests/api/v1/manager/business-request/${businessRequestId}/customer-specification`,
        method: 'GET',
        params: { statusNotIn: ['DELETED'] },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getManagerCustomerSpecifications', id: arg },
      ],
      transformResponse: (data: IManagerCustomerSpecification[]) => data[0],
    }),

    getManagerContractorSpecificationAvailableContractors: builder.query<
      IManagerContractorSpecificationAvailableContractor[],
      number
    >({
      query: (businessRequestId) => ({
        url: `business-requests/api/v1/manager/business-request/${businessRequestId}/available-contractors`,
        method: 'GET',
      }),
    }),

    createManagerContractorSpecification: builder.mutation<
      IManagerContractorSpecification,
      IContractorSpecificationSaveParams
    >({
      invalidatesTags: ['fieldHistoryLogs'],
      query: (body) => ({
        url: 'business-requests/api/v1/manager/contractor-specification',
        method: 'POST',
        body,
      }),
    }),

    updateManagerContractorSpecification: builder.mutation<
      IManagerContractorSpecification,
      IContractorSpecificationSaveParams & { specificationId: number }
    >({
      invalidatesTags: ['fieldHistoryLogs'],
      query: ({ specificationId, ...body }) => ({
        url: `business-requests/api/v1/manager/contractor-specification/${specificationId}`,
        method: 'PUT',
        body,
      }),
    }),

    setManagerContractorSpecificationPreliminaryVersion: builder.mutation<
      IManagerContractorSpecification,
      { specificationId: number; businessRequestId: number }
    >({
      query: ({ specificationId }) => ({
        url: `business-requests/api/v1/manager/contractor-specification/${specificationId}/preliminary-version`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              { type: 'fieldHistoryLogs' },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    setManagerContractorSpecificationFinalVersionSent: builder.mutation<
      IManagerContractorSpecification,
      ISetContractorFinalVersionSentParams
    >({
      query: ({ specificationId, ...body }) => ({
        url: `business-requests/api/v1/manager/contractor-specification/${specificationId}/final-version`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              { type: 'fieldHistoryLogs' },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
              { type: 'getManagerRequest', id: arg.businessRequestId },
            ],
    }),

    getManagerContractorWinnerCriterion: builder.query<
      IManagerContractorWinnerCriterion[],
      void
    >({
      query: () => ({
        url: 'business-requests/api/v1/winner-criterion',
        method: 'GET',
      }),
    }),

    setManagerContractorSpecificationDelete: builder.mutation<
      unknown,
      { specificationId: number; businessRequestId: number }
    >({
      query: ({ specificationId }) => ({
        url: `business-requests/api/v1/manager/contractor-specification/${specificationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerContractorSpecifications',
                id: arg.businessRequestId,
              },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    createManagerCustomerSpecification: builder.mutation<
      IManagerCustomerSpecification,
      ICustomerSpecificationSaveParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/customer-specification',
        method: 'POST',
        body,
      }),
    }),

    updateManagerCustomerSpecification: builder.mutation<
      IManagerCustomerSpecification,
      ICustomerSpecificationSaveParams & { specificationId: number }
    >({
      query: ({ specificationId, ...body }) => ({
        url: `business-requests/api/v1/manager/customer-specification/${specificationId}`,
        method: 'PUT',
        body,
      }),
    }),

    setManagerCustomerSpecificationPreliminaryVersion: builder.mutation<
      IManagerCustomerSpecification,
      { specificationId: number; businessRequestId: number }
    >({
      query: ({ specificationId }) => ({
        url: `business-requests/api/v1/manager/customer-specification/${specificationId}/preliminary-version`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    setManagerCustomerSpecificationFinalVersionSent: builder.mutation<
      IManagerCustomerSpecification,
      { specificationId: number; businessRequestId: number }
    >({
      query: ({ specificationId }) => ({
        url: `business-requests/api/v1/manager/customer-specification/${specificationId}/final-version`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    setManagerCustomerSpecificationDelete: builder.mutation<
      unknown,
      { specificationId: number; businessRequestId: number }
    >({
      query: ({ specificationId }) => ({
        url: `business-requests/api/v1/manager/customer-specification/${specificationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerCustomerSpecifications',
                id: arg.businessRequestId,
              },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),
  }),
});

export const {
  useGetManagerContractorSpecificationsQuery,
  useGetManagerCustomerSpecificationsQuery,
  useGetManagerContractorSpecificationAvailableContractorsQuery,
  useCreateManagerContractorSpecificationMutation,
  useUpdateManagerContractorSpecificationMutation,
  useSetManagerContractorSpecificationPreliminaryVersionMutation,
  useSetManagerContractorSpecificationFinalVersionSentMutation,
  useGetManagerContractorWinnerCriterionQuery,
  useSetManagerContractorSpecificationDeleteMutation,

  useCreateManagerCustomerSpecificationMutation,
  useUpdateManagerCustomerSpecificationMutation,
  useSetManagerCustomerSpecificationPreliminaryVersionMutation,
  useSetManagerCustomerSpecificationFinalVersionSentMutation,
  useSetManagerCustomerSpecificationDeleteMutation,
} = managerSpecificationApi;

export const useSetManagerSpecificationDelete = (
  mode: 'contractor' | 'customer'
) => {
  if (mode === 'contractor') {
    return useSetManagerContractorSpecificationDeleteMutation;
  }

  return useSetManagerCustomerSpecificationDeleteMutation;
};
