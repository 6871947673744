import { ReactNode } from 'react';
import {
  FieldArrayPath,
  FieldError,
  FieldValues,
  useFieldArray,
  UseFieldArrayProps,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import { Box, FormHelperText } from 'src/components/ui';

export const FormList = <
  T extends FieldValues = FieldValues,
  K extends FieldArrayPath<T> = FieldArrayPath<T>
>({
  children,
  noMargin,
  ...rest
}: UseFieldArrayProps<T, K> & {
  children: (data: UseFieldArrayReturn<T, K>) => ReactNode;
  noMargin?: boolean;
}) => {
  const { control, formState } = useFormContext<T>();
  const helperText = (formState.errors[rest.name]?.root as FieldError)?.message;
  const fieldArray = useFieldArray<T, K, 'id'>({
    ...rest,
    control,
  });

  return (
    <Box mb={noMargin ? undefined : '32px'}>
      {children(fieldArray)}
      {helperText && (
        <FormHelperText error component="div">
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};
