import { FC } from 'react';
import classNames from 'classnames';
import {
  Box,
  PhoneInput,
  Link,
  Typography,
  Result,
  BoardSkeleton,
  SvgIcon,
  Tooltip,
} from 'src/components/ui';
import { joinName } from 'src/utils';
import { getErrorMessage } from 'src/store/utils';
import { useGetContactsQuery } from 'src/api/contacts';
import { CompanyTypes } from 'src/models';

import { ClosedLock } from 'src/assets/icons';
import { TContactStatuses } from 'src/components/pages/company/contacts/types';
import styles from './contacts-details.module.scss';

type TContactsDetails = {
  companyId: number;
  companyType: CompanyTypes;
};

export const ContactsDetails: FC<TContactsDetails> = ({
  companyId,
  companyType,
}) => {
  const { data, isLoading, error } = useGetContactsQuery({
    idCompany: companyId,
    companyType,
  });

  if (isLoading) {
    return <BoardSkeleton />;
  }

  if (error || (!isLoading && !data)) {
    return (
      <Result
        title={getErrorMessage(
          error,
          'Произошла ошибка при загрузке контактов'
        )}
      />
    );
  }

  return (
    <div>
      {data?.map(
        ({
          id,
          middleName,
          lastName,
          firstName,
          contactInfoDto,
          email,
          contactType,
          contactStatus,
        }) => (
          <div
            className={classNames(
              styles.wrapper,
              contactType === `MAIN_${companyType}` && styles.mainContact
            )}
            key={id}>
            <div className={styles.nameWrapper}>
              {contactStatus === TContactStatuses.LOCKED && (
                <Box mt="4px">
                  <Tooltip title="Пользователь заблокирован">
                    <SvgIcon
                      backgroundFill
                      iconPadding="2px"
                      width="15px"
                      height="15px"
                      strokeHexColor="#FF0000"
                      icon={ClosedLock}
                    />
                  </Tooltip>
                </Box>
              )}
              <Typography className={styles.nameContact}>
                {joinName(lastName, firstName, middleName).wrapName}
              </Typography>
            </div>
            <Box marginBottom="8px">
              {contactInfoDto.phones.map((phone) => (
                <div key={phone.number}>
                  <PhoneInput displayType="text" value={phone} />
                </div>
              ))}
            </Box>
            <Link href={`mailto:${email}`}>{email}</Link>
          </div>
        )
      )}
    </div>
  );
};
