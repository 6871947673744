import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: () => ({
      fontSize: '12px',
      lineHeight: '10px',
      letterSpacing: 'normal',
      margin: '8px 0 0 0',
    }),
  },
};
