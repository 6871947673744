import { Components } from '@mui/material/styles/components';
import { CheckboxCheck } from 'src/assets/icons';
import { Theme } from '@mui/material';

const DefaultCheckboxIcon = () => (
  <div
    className="medium small"
    style={{
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  />
);

const CheckedCheckboxIcon = () => (
  <div
    className="checked medium small"
    style={{
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <CheckboxCheck />
  </div>
);

const IndeterminateCheckboxIcon = () => (
  <div
    className="indeterminate medium small"
    style={{
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <div
      style={{
        height: '20px',
        width: '20px',
        border: 'none',
        backgroundColor: 'transparent',
      }}
      className="indeterminate-bullet medium">
      <svg height="20" width="20" viewBox="0 0 20 20" color="inherit">
        <rect width="10" height="10" x="5" y="5" rx="2" fill="currentColor" />
      </svg>
    </div>

    <div
      style={{
        height: '16px',
        width: '16px',
        border: 'none',
        backgroundColor: 'transparent',
      }}
      className="indeterminate-bullet small">
      <svg height="16" width="16" viewBox="0 0 16 16" color="inherit">
        <rect width="8" height="8" x="4" y="4" rx="2" fill="currentColor" />
      </svg>
    </div>
  </div>
);

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
    icon: <DefaultCheckboxIcon />,
    checkedIcon: <CheckedCheckboxIcon />,
    indeterminateIcon: <IndeterminateCheckboxIcon />,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      padding: '0 7px 0 0',
      ...(ownerState.size === 'medium' && {
        '& div.indeterminate-bullet.medium': {
          display: 'block',
        },
        '& div.medium': {
          height: '20px',
          width: '20px',
        },
      }),

      ...(ownerState.size === 'small' && {
        '& div.indeterminate-bullet.small': {
          display: 'block',
        },
        '& div:not(.indeterminate-bullet) > svg': {
          width: '12px',
        },
        '& div.small': {
          height: '16px',
          width: '16px',
        },
      }),

      '& div': {
        border: `1px solid ${theme.palette.secondary.light}`,
        color: '#ffffff',
      },

      '& div.indeterminate': {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
      },

      '& .indeterminate-bullet': {
        backgroundColor: '#ffffff',
        display: 'none',
      },

      '&:hover div': {
        border: `1px solid ${theme.palette.primary.dark}`,
      },

      '&:hover div.indeterminate': {
        backgroundColor: theme.palette.primary.dark,
      },

      '& .checked': {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
      },

      '&:hover .checked ': {
        backgroundColor: theme.palette.primary.dark,
      },

      ...(ownerState.disabled && {
        '& div': {
          borderRadius: '4px',
          backgroundColor: '#F4F4F4',
          color: theme.palette.customTheme.borderGrayPrimary,
          border: `1px solid ${theme.palette.customTheme.borderGrayPrimary}`,
        },
        '& .checked': {
          backgroundColor: '#F4F4F4',
          color: theme.palette.customTheme.borderGrayPrimary,
          border: `1px solid ${theme.palette.customTheme.borderGrayPrimary}`,
        },
        '& div.indeterminate': {
          backgroundColor: '#F4F4F4',
        },
        '& .indeterminate-bullet': {
          backgroundColor: theme.palette.customTheme.borderGrayPrimary,
        },
      }),

      ...(ownerState.color === 'error' && {
        '& div': {
          border: `1px solid #E11111`,
        },
      }),
    }),
  },
};
