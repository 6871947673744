import { IUserInfo } from 'src/models/auth';
import { IChat, IUserChat } from 'src/models/chat';

export const getUsersList = (data: IChat[]) =>
  data.reduce<IUserChat[]>((acc, { users }) => acc.concat(users), []);

export const getCurrentUser = (data: IUserChat[], id: number) => {
  const defaultUser: IUserChat = {
    id: -1,
    firstName: '',
    lastName: '',
    middleName: '',
    isActive: true,
  };
  const foundUser = data.find((item) => item.id === id);
  return foundUser || defaultUser;
};

export const getFioString = (user: IUserChat) =>
  `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`;

export const getUserLastNameWithInitials = (userInfo: {
  lastName: string;
  firstName: string;
  middleName: string;
}) => {
  if (!userInfo.firstName || !userInfo.lastName) {
    return null;
  }
  return `${userInfo.lastName} ${userInfo.firstName.substring(0, 1)}. ${
    userInfo.middleName ? `${userInfo.middleName.substring(0, 1)}.` : ''
  }`;
};

export const getUserInitials = (userInfo: {
  firstName: string;
  lastName: string;
}) => {
  if (!userInfo.firstName || !userInfo.lastName) {
    return null;
  }

  return `${userInfo?.lastName?.substring(
    0,
    1
  )} ${userInfo?.firstName?.substring(0, 1)}`.toLocaleUpperCase();
};

export const getUserInitialsFromFullName = (fullName: string) => {
  const [firstName, lastName] = fullName.split(' ');

  return getUserInitials({
    firstName: firstName || '',
    lastName: lastName || '',
  });
};
