import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { Box, BoxProps } from 'src/components/ui';
import { ROLES } from 'src/constants';
import { AuthService } from 'src/services';
import styles from './item-card.module.scss';

export const ItemCard: FC<{ children: ReactNode } & BoxProps> = ({
  children,
  className,
  ...props
}) => {
  const { inView, ref: inViewRef } = useInView({ triggerOnce: true });

  const { currentRole } = AuthService;
  const style: React.CSSProperties = useMemo(() => {
    if (currentRole === ROLES.CONTRACTOR) {
      return { minHeight: 110 };
    }
    if (currentRole === ROLES.CUSTOMER) {
      return { minHeight: 90 };
    }
    return {};
  }, [currentRole]);

  return (
    <Box
      ref={inViewRef}
      className={classNames(styles.wrapper, className)}
      style={style}
      {...props}>
      {inView && children}
    </Box>
  );
};
