import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IEmailMessage } from 'src/models/communications';

interface IInitialState {
  currentMessage: IEmailMessage | null; // TODO: nullable?
  currentChainId: string | null;
  currentFiles: any[];
  isNewDialog: boolean;
  newDialogMessageId: string | null;
  filteredAccounts: string[];
}

const initialState: IInitialState = {
  currentMessage: null,
  currentChainId: null,
  currentFiles: [],
  isNewDialog: false,
  newDialogMessageId: null,
  filteredAccounts: [],
};

const emailsSlice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    setCurrentMessage(state, action: PayloadAction<IEmailMessage | null>) {
      const { payload } = action;
      state.currentMessage = payload;
    },
    setCurrentChainId(state, action: PayloadAction<string | null>) {
      const { payload } = action;
      state.currentChainId = payload;
    },
    setIsNewDialog(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.isNewDialog = payload;

      if (payload) {
        state.currentMessage = null;
        state.currentChainId = null;
        state.currentFiles = [];
      }
    },
    setNewDialogMessageId(state, action: PayloadAction<string | null>) {
      const { payload } = action;
      state.newDialogMessageId = payload;
    },
    setFilteredAccounts(state, action: PayloadAction<string[]>) {
      const { payload } = action;
      state.filteredAccounts = payload;
    },
  },
});

export const {
  setCurrentMessage,
  setCurrentChainId,
  setIsNewDialog,
  setNewDialogMessageId,
  setFilteredAccounts,
} = emailsSlice.actions;
export const emailsReducer = emailsSlice.reducer;
