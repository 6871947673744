import { Stack } from '@mui/material';
import { useState } from 'react';
import { useUpdateInvokedExpertRequestStatusMutation } from 'src/api/business-request/business-request';
import { useGetCurrentUserQuery } from 'src/api/user';
import { CheckCircle20, Cross } from 'src/assets/icons';
import styles from 'src/components/pages/business-request-detail/components/card-request/card-request.module.scss';
import { RejectApprovalModal } from 'src/components/pages/business-request-detail/components/reject-approval-modal';
import { getRequestStage } from 'src/components/pages/business-request-detail/utils/request-stage-utils';
import { Button } from 'src/components/ui';
import { HelpExpertBarStage } from 'src/components/widgets/help-expert-bar';
import {
  InvokeExpertStage,
  InvokeExpertStatus,
  ISpecificRole,
} from 'src/models/bids-details';

export const ApproveRejectBar = ({
  assignedSpecificRoles,
  businessRequestId,
  commercialOfferId,
  customerSpecificationId,
}: {
  assignedSpecificRoles: Record<InvokeExpertStage, ISpecificRole[] | undefined>;
  businessRequestId: number;
  commercialOfferId?: number;
  customerSpecificationId?: number;
}) => {
  const [showRejectModal, setShowRejectModal] = useState(false);

  const { data: userData } = useGetCurrentUserQuery();

  const requestStage = getRequestStage(assignedSpecificRoles);

  const requestSubStages = () => {
    if (requestStage === HelpExpertBarStage.REQUEST) {
      return [
        InvokeExpertStage.REQUEST_FORMULATION,
        InvokeExpertStage.REQUEST_AGREEMENT,
      ];
    }

    if (requestStage === HelpExpertBarStage.COMMERCIAL_OFFER) {
      return [
        InvokeExpertStage.LOGISTICS_NEW_REQUEST,
        InvokeExpertStage.QUOTATION_AGREEMENT,
      ];
    }

    return [
      InvokeExpertStage.LOGISTICS_NEW_REQUEST,
      InvokeExpertStage.SPECIFICATION_AGREEMENT,
    ];
  };

  const [updateInvokedExpertRequestStatus, { isLoading }] =
    useUpdateInvokedExpertRequestStatusMutation();

  const isRequestOnFormulation = !!assignedSpecificRoles[
    requestSubStages()[0]
  ]?.find((expert) => expert.assignedManager?.id === userData!.id);

  const requestStatus = (): InvokeExpertStage => {
    if (requestStage === HelpExpertBarStage.SPECIFICATION) {
      if (isRequestOnFormulation) {
        return InvokeExpertStage.LOGISTICS_NEW_REQUEST;
      }

      return InvokeExpertStage.SPECIFICATION_AGREEMENT;
    }

    if (requestStage === HelpExpertBarStage.COMMERCIAL_OFFER) {
      if (isRequestOnFormulation) {
        return InvokeExpertStage.LOGISTICS_NEW_REQUEST;
      }

      return InvokeExpertStage.QUOTATION_AGREEMENT;
    }

    if (isRequestOnFormulation) {
      return InvokeExpertStage.REQUEST_FORMULATION;
    }

    return InvokeExpertStage.REQUEST_AGREEMENT;
  };

  const showRejectButton =
    requestStage === HelpExpertBarStage.REQUEST ||
    (requestStage === HelpExpertBarStage.COMMERCIAL_OFFER &&
      !isRequestOnFormulation) ||
    (requestStage === HelpExpertBarStage.SPECIFICATION &&
      !isRequestOnFormulation);

  const handleApproveRequest = () => {
    updateInvokedExpertRequestStatus({
      requestId: businessRequestId,
      requestStatus: requestStatus(),
      approvalStatus: isRequestOnFormulation
        ? InvokeExpertStatus.FORMED
        : InvokeExpertStatus.APPROVED,
      customerSpecificationId,
      commercialOfferId,
    });
  };

  const approveButtonLabel = () => {
    if (requestStage === HelpExpertBarStage.COMMERCIAL_OFFER) {
      if (isRequestOnFormulation) {
        return 'Логистика рассчитана';
      }
    }

    if (isRequestOnFormulation) {
      return 'Сформировать';
    }

    return 'Согласовать';
  };

  return (
    <Stack
      width="100%"
      gap="10px"
      marginTop="24px"
      justifyContent="flex-end"
      className={styles.whiteIcon}
      direction="row">
      {showRejectButton && (
        <Button
          onClick={() => setShowRejectModal(true)}
          startIcon={<Cross style={{ height: 10, width: 10 }} />}>
          Отклонить
        </Button>
      )}

      <Button
        loading={isLoading}
        onClick={() => handleApproveRequest()}
        startIcon={<CheckCircle20 />}>
        {approveButtonLabel()}
      </Button>
      <RejectApprovalModal
        requestStage={requestStatus()}
        open={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        onCancel={() => setShowRejectModal(false)}
        commercialOfferId={commercialOfferId}
        customerSpecificationId={customerSpecificationId}
      />
    </Stack>
  );
};
