import { TableHead, TableRow, TableCell } from '@mui/material';
import { Checkbox } from 'src/components/ui';
import { ThCell } from './th-cell';
import { HeaderProps } from './types';
import styles from './table.module.scss';

export const Header = <RecordType,>(props: HeaderProps<RecordType>) => {
  const {
    columns,
    onSelectAllClick,
    rowCount,
    numSelected,
    onFilter,
    expandedRowRender,
    showCounterSelected,
    isCollapse,
    withLabelSelected,
    showTotalCount,
    filtersWithRadioButton = false,
    currentPageSelectedCount,
  } = props;

  const selectedCount = currentPageSelectedCount ?? numSelected;

  return (
    <TableHead>
      <TableRow>
        {onSelectAllClick ? (
          <TableCell sx={{ width: '20px' }}>
            {showCounterSelected && (
              <Checkbox
                className={styles.checkbox}
                indeterminate={selectedCount > 0 && selectedCount < rowCount}
                checked={rowCount > 0 && selectedCount === rowCount}
                onChange={onSelectAllClick}
              />
            )}
          </TableCell>
        ) : null}

        {columns.map((column, index) => (
          <ThCell
            key={column.title}
            column={column}
            index={index}
            onFilters={onFilter}
            showCounterSelected={showCounterSelected}
            numSelected={numSelected}
            rowCount={rowCount}
            withLabelSelected={withLabelSelected}
            filtersWithRadioButton={filtersWithRadioButton}
            showTotalCount={showTotalCount}
          />
        ))}
        {expandedRowRender && (
          <TableCell width="56px">
            <div />
          </TableCell>
        )}
        {isCollapse && <TableCell sx={{ width: '50px' }}>{null}</TableCell>}
      </TableRow>
    </TableHead>
  );
};
