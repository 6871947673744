import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TContact } from 'src/components/pages/admin/company/contacts/types';
import { Autocomplete, Box, Dialog, Form } from 'src/components/ui';
import { required } from 'src/utils/form-rules';

export interface IChangeMainContactDialog {
  open: boolean;
  onClose: () => void;
  currentContact: TContact;
  contactsList: TContact[];
  onNewMainContactSubmitted: (newMainContactId: number) => void;
}

interface IFormType {
  chosenContact: TContact;
}
export const ChangeMainContactDialog: FC<IChangeMainContactDialog> = ({
  open,
  onClose,
  currentContact,
  contactsList,
  onNewMainContactSubmitted,
}) => {
  const form = useForm<IFormType>();

  const filterContactsList = contactsList.filter(
    (contact) => contact.id !== currentContact.id
  );

  const fetchApiFilter = useCallback(
    (value: string) => {
      const filteredItems =
        (value
          ? contactsList?.filter(
              (element) =>
                element.lastName?.toLowerCase().includes(value.toLowerCase()) ||
                element.firstName
                  ?.toLowerCase()
                  .includes(value.toLowerCase()) ||
                element.middleName?.toLowerCase().includes(value.toLowerCase())
            )
          : contactsList) || [];

      return Promise.resolve(
        filteredItems.filter((contact) => contact.id !== currentContact.id)
      );
    },
    [contactsList]
  );

  const onSubmit: SubmitHandler<IFormType> = async (newMainContact) => {
    onNewMainContactSubmitted(newMainContact.chosenContact.id);
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '450px',
        },
      }}
      open={open}
      close={onClose}>
      <DialogTitle>Изменить основной контакт</DialogTitle>
      <DialogContent>
        <Typography fontSize="16px" mb="24px">
          {`${currentContact.firstName} ${currentContact.lastName} является основным контактом для данной компании, какой контакт будет основным вместо него?`}
        </Typography>
        <Form<IFormType>
          form={form}
          onSubmit={onSubmit}
          onCancel={onClose}
          showCancelConfirm={false}>
          <Form.Item rules={{ required }} name="chosenContact">
            <Autocomplete<TContact>
              popupIcon
              options={filterContactsList}
              fetchApi={fetchApiFilter}
              fetchOnEmptyInput
              placeholder="Выбрать"
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  <div>
                    <Typography textAlign="left">
                      {`${option.firstName} ${option.lastName}`}
                    </Typography>
                    <Typography color="secondary">
                      {`${option.email} / +7${option.contactInfoDto.phones[0].number}`}
                    </Typography>
                  </div>
                </Box>
              )}
            />
          </Form.Item>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
