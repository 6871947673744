import { TAssignedManager } from 'src/api/admin/company';
import { ROLES } from 'src/constants';
import { CompanyTypes, MangerTypes } from 'src/models';

export const getAssignedManagerType = (companyType: CompanyTypes) =>
  companyType === 'CONTRACTOR' ? MangerTypes.KAM_PLUS : MangerTypes.KAM;

export const getFilteredManagersByCompanyType = (
  companyType: CompanyTypes,
  managers: TAssignedManager[]
) =>
  companyType === CompanyTypes.CONTRACTOR
    ? managers.filter((manager) => manager.role.includes(ROLES.KAM_PLUS))
    : managers.filter((manager) => manager.role.includes(ROLES.KAM));
