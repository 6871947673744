import { FC, useEffect, useRef } from 'react';
import { differenceInSeconds } from 'date-fns';
import { SmallClock } from 'src/assets/icons';
import { Box, Typography } from 'src/components/ui';
import { secondsToFormattedTime } from 'src/utils';
import { useTimer } from 'src/hooks';

import styles from './auction-status.module.scss';

type TGetTooltipByStatus = {
  startDate: Date | string;
  endDate: Date | string;
  isClosed?: boolean;
  onAuctionStart?: () => void;
  onAuctionEnd?: () => void;
  mode?: 'default' | 'kanban';
};

const getTooltipByStatus = (
  startDate: Date | string,
  endDate: Date | string,
  isClosed: boolean,
  onAuctionStart?: () => void,
  onAuctionEnd?: () => void
) => {
  if (isClosed) {
    return {
      preffix: 'Аукцион закрыт',
      data: null,
    };
  }

  const nowDate = new Date();
  const auctionStartDate = new Date(startDate);
  const auctionEndDate = new Date(endDate);
  let finalDiff: number;

  const isAuctionStart = useRef(nowDate >= auctionStartDate);
  const isAuctionEnd = useRef(nowDate >= auctionEndDate);

  const [counter, setCounter, stopTimer] = useTimer();

  useEffect(() => {
    setCounter(finalDiff || 0);
  }, [setCounter, auctionStartDate, auctionEndDate]);

  if (nowDate >= auctionEndDate) {
    if (!isAuctionEnd.current) {
      onAuctionEnd?.();

      isAuctionStart.current = true;
      isAuctionEnd.current = true;
    }

    stopTimer();
    return {
      preffix: 'Аукцион окончен',
      data: null,
    };
  }

  if (nowDate >= auctionStartDate) {
    if (!isAuctionStart.current) {
      onAuctionStart?.();

      isAuctionStart.current = true;
      isAuctionEnd.current = false;
    }

    finalDiff = differenceInSeconds(auctionEndDate, nowDate) + 1;
    return {
      preffix: 'До окончания',
      data: secondsToFormattedTime(counter, 'days'),
    };
  }

  finalDiff = differenceInSeconds(auctionStartDate, nowDate) + 1;
  return {
    preffix: 'До старта',
    data: secondsToFormattedTime(counter, 'days'),
  };
};

export const AuctionStatus: FC<TGetTooltipByStatus> = ({
  startDate,
  endDate,
  isClosed = false,
  onAuctionStart,
  onAuctionEnd,
  mode = 'default',
}) => {
  const { data, preffix } = getTooltipByStatus(
    startDate,
    endDate,
    isClosed,
    onAuctionStart,
    onAuctionEnd
  );
  if (mode === 'kanban') {
    const colorForKanban =
      new Date(startDate) >= new Date() ? 'success.main' : 'error.main';
    return (
      <Typography variant="body2" color={colorForKanban}>
        {preffix}{' '}
        <span
          style={{
            whiteSpace: 'nowrap',
          }}>
          {data}
        </span>
      </Typography>
    );
  }

  const status =
    new Date() >= new Date(endDate) || isClosed ? 'WARNING' : 'OPEN';

  return (
    <Box className={styles[`statusWrapper${status}`]}>
      <SmallClock />
      <Typography>
        {preffix}{' '}
        <span
          style={{
            whiteSpace: 'nowrap',
          }}>
          {data}
        </span>
      </Typography>
    </Box>
  );
};
