import { DIRECTION } from 'src/components/ui/filters/types';
import { IRejectApplicationReasonsParams } from 'src/models';

export const requestBodyRejectApplication: IRejectApplicationReasonsParams = {
  searchQuery: [],

  sort: {
    field: 'STATUS',
    order: DIRECTION.ASC,
  },
  paging: {
    page: 1,
    limit: 1000,
  },
};
