import { FC, Ref } from 'react';

export const HiddenComponent: FC<{ inputRef?: Ref<any> }> = ({ inputRef }) => (
  <button
    type="button"
    style={{
      border: 0,
      padding: 0,
      margin: 0,
    }}
    aria-label="Обязательное поле"
    ref={inputRef}
  />
);
