import { FC, PropsWithChildren } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from 'src/components/ui';

interface IPreviewProps extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  title?: string;
  subTitle?: string;
}

export const Preview: FC<IPreviewProps> = ({
  open,
  onClose,
  children,
  title = 'Предварительный просмотр',
  subTitle = 'Контрагент увидит в спецификации следующие поля',
}) => (
  <Dialog
    open={open}
    close={onClose}
    sx={{ '& .MuiPaper-root': { maxWidth: 'fit-content' } }}>
    <DialogTitle>
      {title}

      <Typography variant="h4" color="secondary" mt="8px">
        {subTitle}
      </Typography>
    </DialogTitle>

    <DialogContent sx={{ marginTop: '12px' }}>{children}</DialogContent>
  </Dialog>
);
