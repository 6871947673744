import { createTheme } from '@mui/material';
import { palette } from 'src/theme/palette';
import { typography } from 'src/theme/typography';
import vars from './scss/_vars.module.scss';

import {
  MuiButton,
  MuiCheckbox,
  MuiRadio,
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiIconButton,
  MuiLink,
  MuiOutlinedInput,
  MuiTypography,
  MuiDialogActions,
  MuiMenu,
  MuiMenuItem,
  MuiTooltip,
  MuiStepConnector,
  MuiStepLabel,
  MuiPaper,
  MuiFormLabel,
  MuiDrawer,
  MuiSkeleton,
  MuiAutocomplete,
  MuiTable,
  MuiChip,
  MuiPagination,
  MuiButtonGroup,
  MuiSwitch,
  MuiLinearProgress,
  MuiCircularProgress,
} from './components';

export const theme = createTheme({
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTypography,
    MuiButton,
    MuiIconButton,
    MuiOutlinedInput,
    MuiFormControlLabel,
    MuiFormHelperText,
    MuiCheckbox,
    MuiDialog,
    MuiDialogTitle,
    MuiDialogContent,
    MuiDialogActions,
    MuiLink,
    MuiMenu,
    MuiMenuItem,
    MuiTooltip,
    MuiStepConnector,
    MuiStepLabel,
    MuiRadio,
    MuiPaper,
    MuiFormLabel,
    MuiDrawer,
    MuiSkeleton,
    MuiAutocomplete,
    MuiTable,
    MuiChip,
    MuiPagination,
    MuiButtonGroup,
    MuiSwitch,
    MuiLinearProgress,
    MuiCircularProgress,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '::-webkit-scrollbar': {
            width: '4px',
            backgroundColor: '#ffffff',
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: vars.scrollBorderRadius,
            backgroundColor: vars.secondaryLight,
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: Number(vars.breakpointXs),
      sm: Number(vars.breakpointSm),
      md: Number(vars.breakpointMd),
      lg: Number(vars.breakpointLg),
      xl: Number(vars.breakpointXl),
      xxl: Number(vars.breakpointXxl),
    },
  },
});
