import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DocumentTypes,
  IManagerContractorCommercialOffer,
  IManagerCustomerCommercialOffer,
  ManagerCustomerCommercialOfferStatus,
} from 'src/models';

interface IInitialState {
  offer: IManagerCustomerCommercialOffer | null;
}

const initialState: IInitialState = {
  offer: null,
};

const commercialOfferSlice = createSlice({
  name: 'commercialOffer',
  initialState,

  reducers: {
    handleChangeSelectionOffer(
      state,
      action: PayloadAction<IManagerContractorCommercialOffer>
    ) {
      const { payload } = action;

      state.offer = {
        businessApplicationId: 0,
        number: 0,
        commercialOfferId: 0,
        costByContractor: payload.costByContractor,
        costForCustomer: null,
        shippingIncluded: payload.shippingIncluded,
        shippingCost: payload.shippingIncluded
          ? payload.shipping.manualShippingCost ||
            payload.shipping.calculatedShippingCost ||
            null
          : 0,
        income: null,
        dueDateAt: payload.dueDateAt,
        processingDueDateAt: null,
        supplyDuration: payload.supplyDuration,
        paymentConditions: payload.paymentConditions,
        comment: payload.comment,
        createdAt: null,

        status: {
          code: ManagerCustomerCommercialOfferStatus.DRAFT,
          createdAt: null,
          displayName: '',
        },

        draftLastModifiedAt: '',
        sentAt: null,

        contractorCommercialOffer: {
          id: payload.commercialOfferId,
          number: payload.number || 0,
        },

        contractorData: {
          organizationName: payload.contractorDetails.organizationName,
          organizationId: payload.contractorDetails.organizationId,
          contractId: payload.contractorDetails.contractId,
          contractStatus: payload.contractorDetails.contractStatusCode,
        },
        refusal: null,
        refine: null,
        documents: { [DocumentTypes.COMMERCIAL_OFFER]: [] },

        assignedSpecificRoles: {} as any,
      };
    },

    handleClearSelectedOffer(state) {
      state.offer = null;
    },
  },
});

export const { handleChangeSelectionOffer, handleClearSelectedOffer } =
  commercialOfferSlice.actions;
export const commercialOfferReducer = commercialOfferSlice.reducer;
