import { KamStatusCode } from 'src/models';
import { TVariant } from 'src/components/ui/new-filters/types';

export const RequestStatusFilters: Array<TVariant> = [
  {
    value: KamStatusCode.NEW_REQUEST,
    label: 'Новая заявка',
    checked: false,
  },
  {
    value: KamStatusCode.COMMERCIAL_QUALIFICATION,
    label: 'Коммерческая квалификация',
    checked: false,
  },
  {
    value: KamStatusCode.TECHNICAL_QUALIFICATION,
    label: 'Техническая квалификация',
    checked: false,
  },
  {
    value: KamStatusCode.QUALIFIED,
    label: 'Квалификация проведена',
    checked: false,
  },
  {
    value: KamStatusCode.SENT_TO_CONTRACTORS,
    label: 'Запрос исполнителям отправлен',
    checked: false,
  },
  {
    value: KamStatusCode.AUCTION_IN_PROGRESS,
    label: 'Проводится аукцион',
    checked: false,
  },
  {
    value: KamStatusCode.REBIDDING_IN_PROGRESS,
    label: 'Проводится переторжка',
    checked: false,
  },
  {
    value: KamStatusCode.COMMERCIAL_OFFER_RECEIVED,
    label: 'КП получено',
    checked: false,
  },
  {
    value: KamStatusCode.NO_BEST_COMMERCIAL_OFFER,
    label: 'Нет лучшего КП',
    checked: false,
  },
  {
    value: KamStatusCode.BEST_COMMERCIAL_OFFER_RECEIVED,
    label: 'Лучшее КП получено',
    checked: false,
  },
  {
    value: KamStatusCode.COMMERCIAL_OFFER_SENT_TO_CUSTOMER,
    label: 'КП отправлено заказчику',
    checked: false,
  },
  {
    value: KamStatusCode.COMMERCIAL_OFFER_REFINED,
    label: 'Уточнение по КП',
    checked: false,
  },
  {
    value: KamStatusCode.SPECIFICATION_SENT,
    label: 'Спецификация отправлена',
    checked: false,
  },
  {
    value: KamStatusCode.SPECIFICATION_SIGNED,
    label: 'Спецификация подписана',
    checked: false,
  },
  {
    value: KamStatusCode.IN_PRODUCTION,
    label: 'В производстве',
    checked: false,
  },
  {
    value: KamStatusCode.DELIVERED,
    label: 'Заказ доставлен',
    checked: false,
  },
  {
    value: KamStatusCode.CLOSED,
    label: 'Заявка выполнена',
    checked: false,
  },
  {
    value: KamStatusCode.REQUEST_REFUSAL,
    label: 'Отмена заявки',
    checked: false,
  },
];
