import { FinanceInfoEntry } from 'src/components/widgets/company-finance-charts/types/finance-info-entry';
import { IKonturFocusData } from 'src/models';
import { TFinanceRowType } from '../types/finance-row-type';

export const extractInfoFromExternalData = (
  konturFocusData: IKonturFocusData,
  rowFilter: TFinanceRowType
): FinanceInfoEntry[] => {
  const searchedTitle =
    rowFilter === 'Капитал и резервы'
      ? 'Бухгалтерский баланс'
      : 'Финансовые результаты';

  const revenueInfoBlock = konturFocusData?.accountingBlocks?.find(
    (block) => block.title === searchedTitle
  );

  const years = revenueInfoBlock?.tableInformation?.headerColumns.slice(2);

  let revenueRows = revenueInfoBlock?.tableInformation?.rows.find(
    (row: any) => row.items[0] === rowFilter
  );

  if (!revenueRows) {
    revenueInfoBlock?.tableInformation?.rows.forEach((row: any) => {
      revenueRows = row.children.find(
        (child: any) => child.items[0] === rowFilter
      );
    });
  }

  const revenuePerYear = revenueRows?.items.slice(2);

  if (!revenuePerYear) {
    return [];
  }

  const revenueEntries: FinanceInfoEntry[] = [];
  years.forEach((year: any, index: number) => {
    revenueEntries.push({
      year: parseInt(year.value, 10),
      value: parseInt(revenuePerYear[index], 10),
    });
  });

  return revenueEntries;
};

export const getFinanceValuesOrder = (range: number): [number, string] => {
  if (range < 1_000_000) {
    return [1000, 'Тыс.'];
  }

  if (range < 1_000_000_000) {
    return [1_000_000, 'Млн.'];
  }

  return [1_000_000_000, 'Млрд.'];
};
