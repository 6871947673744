import {
  Form,
  Checkbox,
  Box,
  Typography,
  NumberInput,
} from 'src/components/ui';
import { maxLength, required } from 'src/utils/form-rules';
import { FIELD_LENGTH } from 'src/constants';

export const DedicatedTransport = () => (
  <>
    <Typography variant="h3" marginBottom="22px">
      Выберите вид тента для выделенного транспорта
    </Typography>
    <Box mb="24px">
      <Form.Item
        noMargin
        name="hardBoard"
        label={<Typography variant="h4">Жесткий борт</Typography>}
        isControlLabel>
        <Checkbox />
      </Form.Item>
    </Box>
    <Box mb="24px">
      <Form.Item
        noMargin
        name="detachableBeam"
        label={<Typography variant="h4">Съемная верхняя балка</Typography>}
        isControlLabel>
        <Checkbox />
      </Form.Item>
    </Box>
    <Box mb="50px">
      <Form.Item
        noMargin
        name="removableStand"
        label={<Typography variant="h4">Съемная боковая стойка</Typography>}
        isControlLabel>
        <Checkbox />
      </Form.Item>
    </Box>
    <Typography variant="h3" marginBottom="20px">
      Введите количество машин выделенного транспорта
    </Typography>
    <Box mb="72px">
      <Form.Item
        noMargin
        name="tentCount"
        rules={{
          required,
          maxLength: maxLength(FIELD_LENGTH.cargoDescription.tentCount),
        }}>
        <NumberInput decimalScale={0} sx={{ width: '74px' }} />
      </Form.Item>
    </Box>
    <Typography variant="h3" marginBottom="24px">
      {`После сохранения данные параметры будут использоваться для расчета логистики
        в каждом КП Исполнителя`}
    </Typography>
  </>
);
