export const isOKPO = (value: string) => {
  if (
    !value ||
    ((value.length === 8 || value.length === 10) &&
      Boolean(/^\d+$/.exec(value)))
  ) {
    return true;
  }
  return 'Введены недопустимые символы';
};
