import { FC, useState } from 'react';
import { useCleanDocumentMutation } from 'src/api/document';
import {
  ConfirmDialog,
  IconButton,
  Loader,
  Typography,
  OverflowTooltip,
} from 'src/components/ui';
import { AuthService } from 'src/services';
import { fileSizeFormat, formatServerDate, joinFullName } from 'src/utils';
import {
  CleanFile,
  DownloadArrow,
  FileAdd,
  FileDeleted,
  FileError,
  FileWarning,
  TrashIcon,
} from 'src/assets/icons';
import { TFile } from 'src/components/ui/file-upload';
import classnames from 'classnames';
import { businessRequestApi } from 'src/api';
import { useAppDispatch } from 'src/store';
import { IFileItemProps } from '../../types';
import styles from './file-item.module.scss';
import { WatchedStatus } from '../watched-status';
import { ROLES } from '../../../../../constants';

const renderFileStatus = (file: TFile, onClick: () => {}) => {
  if (file.error) {
    return <FileError />;
  }

  if (file.deleted) {
    return <FileDeleted />;
  }

  if (file.clenead) {
    return <FileAdd />;
  }

  return <FileWarning onClick={onClick} style={{ cursor: 'pointer' }} />;
};

export const FileItem: FC<IFileItemProps> = ({
  file,
  onDownload,
  onRemove,
  canRemove,
  removeMode = 'default',
  removeConfirmText = '',
  showDownloadButton = true,
  disabledDeleteButton = false,
  disabledFileItem = false,
  withSignature = true,
  showCleanButton = false,
  onPreview,
}) => {
  const isSoftRemove = removeMode === 'soft';
  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const isLoading = isDownloading || isRemoving;
  const isCanBeCleaned =
    showCleanButton &&
    !file.isNew &&
    !file.clenead &&
    AuthService.currentRole! !== ROLES.CONTRACTOR;

  const [
    cleanDocument,
    { isLoading: isClearDocumentLoading, error: clearDocumentError },
  ] = useCleanDocumentMutation();
  const dispatch = useAppDispatch();

  const removeTeleportFromUrl = (host: string) => host.replace('teleport.', '');

  const handleCleanFile = async () => {
    try {
      const currentHost = window.location.host;
      const isLocalHost = currentHost.startsWith('localhost');
      const host = isLocalHost
        ? 'lk-dev.i.cometal.ru'
        : removeTeleportFromUrl(currentHost);

      const url = `${
        host.startsWith('http') ? host : `https://${host}`
      }/document-service/api/v1/document/${file.id}/download`;

      dispatch(
        businessRequestApi.util.invalidateTags([
          {
            type: 'getManagerRequest',
            id: file.entityId,
          },
        ])
      );

      cleanDocument({
        url,
        entityType: file.entityType!,
        documentType: file.documentType!,
        entityId: file.entityId!,
        token: AuthService.accessToken!,
        'Current-Role': AuthService.parentCurrentRole,
      })
        .unwrap()
        .then(() => {
          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getManagerRequest',
                id: file.entityId,
              },
            ])
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await onDownload?.(file);
    } catch {
      // ignore
    } finally {
      setIsDownloading(false);
    }
  };

  const handleRemove = async () => {
    try {
      setIsRemoving(true);
      await onRemove?.(file);
    } catch (e) {
      console.log(e);
    } finally {
      setIsRemoving(false);
    }
  };

  const handlePreview = async () => {
    try {
      await onPreview?.(file);
    } catch {
      // ignore
    }
  };

  const getFileError = () => {
    if (clearDocumentError) {
      if ('status' in clearDocumentError) {
        switch (clearDocumentError.status) {
          case 422:
            return 'Основная надпись не найдена.';
          case 415:
            return 'Не подходящий для обработки формат файла.';
          default:
            return 'Извините, что то пошло не так.';
        }
      }
      return 'Извините, что то пошло не так.';
    }
    return file.error;
  };

  if (file.deleted) {
    return null;
  }

  return (
    <div className={styles.file}>
      <div
        className={classnames(
          styles.fileData,
          disabledFileItem && styles.disabled,
          !withSignature && styles.borderNone
        )}>
        <div className={styles.fileDataIcon}>
          {disabledFileItem ? (
            <FileDeleted />
          ) : (
            renderFileStatus(file, handleDownload)
          )}
        </div>
        <div className={styles.fileDataInfo}>
          <OverflowTooltip
            text={file.name}
            color="primary"
            onClick={onPreview ? handlePreview : handleDownload}
            style={{ cursor: 'pointer' }}
          />
          <Typography color="text.secondary" variant="body2">
            {fileSizeFormat(file.sizeBytes)}
          </Typography>
        </div>
        <div className={styles.fileDataActions}>
          {!file.deleted && (
            <>
              {isCanBeCleaned && (
                <IconButton
                  aria-label="clear"
                  title="Очистить"
                  disabled={isLoading}
                  onClick={handleCleanFile}>
                  {isClearDocumentLoading ? <Loader /> : <CleanFile />}
                </IconButton>
              )}
              {showDownloadButton && (
                <IconButton
                  aria-label="download"
                  title="Скачать"
                  disabled={isLoading}
                  onClick={handleDownload}>
                  {isDownloading ? <Loader /> : <DownloadArrow />}
                </IconButton>
              )}
              {(canRemove || isSoftRemove) && !disabledFileItem && (
                <IconButton
                  aria-label="delete"
                  title="Удалить"
                  color="secondary"
                  disabled={isLoading || disabledDeleteButton}
                  onClick={() => setShowRemoveConfirm(true)}>
                  {isRemoving ? (
                    <Loader />
                  ) : (
                    <TrashIcon
                      sx={{
                        ...(disabledDeleteButton && { color: '#9FA8B3' }),
                      }}
                    />
                  )}
                </IconButton>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.fileUploadData}>
        {file.error || clearDocumentError ? (
          <Typography color="error" variant="body2">
            {getFileError()}
          </Typography>
        ) : (
          withSignature && (
            <>
              <Typography color="text.secondary" variant="body2">
                {formatServerDate(file.createdAt, 'dd.MM.yyyy в HH:mm')}
                <span className={styles.fileUploadDataAuthor}>
                  {joinFullName(
                    file?.createdByUser?.lastName,
                    file?.createdByUser?.firstName,
                    file?.createdByUser?.middleName
                  )}
                </span>
              </Typography>
              <WatchedStatus isWatched={file.isWatched} />
            </>
          )
        )}
      </div>

      {showRemoveConfirm && (
        <ConfirmDialog
          open
          close={() => setShowRemoveConfirm(false)}
          title="Удалить файл?"
          confirmText="Да, удалить"
          onConfirm={() => {
            setShowRemoveConfirm(false);
            handleRemove();
          }}>
          {removeConfirmText}
        </ConfirmDialog>
      )}
    </div>
  );
};
