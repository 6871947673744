import { useEffect, useRef, useState } from 'react';
import { Box, Button } from 'src/components/ui';
import { getCheckboxTreeFormTextRepresentation } from 'src/components/ui/new-filters/components/filters-popover/components/checkbox-tree-form/utils';
import {
  decodeFilterRangeValue,
  encodeFilterRangeValue,
} from 'src/components/ui/new-filters/components/filters-popover/utils';
import { clearFilterValues } from 'src/components/ui/new-filters/utils';
import { TTechnicalOpportunitiesCategories } from 'src/models';
import {
  TFilterItem,
  TFilterListMap,
  TFilterListValuesMap,
  TFilterValue,
  TRangeFilterValue,
} from '../../types';
import {
  BlockFilter,
  FiltersDrawer,
  getDateStringRepresentation,
  validateDateRange,
} from './components';
import {
  getRangeStringRepresentation,
  validateRange,
} from './components/range-form/utils';
import styles from './filter-popover.module.scss';

type TProps<FilterKeys extends string = string> = {
  config: TFilterListMap<FilterKeys>;
  filtersData: TFilterListValuesMap<FilterKeys>;
  onFilter?: (selectedFilters: TFilterListValuesMap<FilterKeys>) => void;
  onClose: () => void;
  open?: boolean;
  queryParamsConfig?: {
    queryParams: Record<FilterKeys, any>;
    setQueryParams: (queryParams: Record<FilterKeys, any>) => void;
  };
  updateChipsWithFilters: (filters: TFilterListValuesMap<FilterKeys>) => void;
};

export const FiltersPopover = <FilterKeys extends string = string>({
  config,
  onFilter,
  onClose,
  filtersData,
  open,
  queryParamsConfig,
  updateChipsWithFilters,
}: TProps<FilterKeys>) => {
  const queryParams = queryParamsConfig?.queryParams;
  const setQueryParams = queryParamsConfig?.setQueryParams;

  const [currentFiltersData, setCurrentFiltersData] =
    useState<TFilterListValuesMap<FilterKeys>>(filtersData);
  const [isDirty, setIsDirty] = useState(false);

  const [isInitPassed, setIsInitPassed] = useState(false);

  const [error, setError] = useState<Partial<Record<FilterKeys, string>>>({});

  const wrapperRef = useRef<HTMLDivElement>(null);
  const callbackRef = useRef<(() => void) | null>(null);

  const handleChangeFilters = (updatedFilter: TFilterValue<FilterKeys>) => {
    const errorCopy = structuredClone(error);
    errorCopy[updatedFilter.key] = '';
    setError(errorCopy);
    // Если оба поля ввода для Range пусты, значит фильтр нужно удалить
    if (!Array.isArray(updatedFilter.value)) {
      if (
        !(typeof updatedFilter.value === 'string') &&
        !updatedFilter.value.to &&
        !updatedFilter.value.from
      ) {
        const currentFiltersDataCopy = { ...currentFiltersData };
        delete currentFiltersDataCopy[updatedFilter.key];
        setCurrentFiltersData(currentFiltersDataCopy);
        setIsDirty(true);
        return;
      }
    }

    const currentFiltersDataCopy = { ...currentFiltersData };
    currentFiltersDataCopy[updatedFilter.key] = updatedFilter;
    setCurrentFiltersData(currentFiltersDataCopy);
    setIsDirty(true);
  };

  const clearFilters = () => {
    setError({});
    setCurrentFiltersData(clearFilterValues(filtersData, config));
    setIsDirty(true);
  };
  const validateFilters = (): boolean => {
    let isValid = true;
    Object.entries(config).forEach((configEntry) => {
      const configItem = configEntry[1] as TFilterItem<FilterKeys>;

      if (configItem.filterFormType === 'date') {
        const filterData = currentFiltersData[configItem.key];

        if (filterData) {
          const filterValue = filterData.value as TRangeFilterValue;
          const dateValidationError = validateDateRange(filterValue);
          if (dateValidationError) {
            const currentError = structuredClone(error);
            currentError[configItem.key] = dateValidationError;
            setError(currentError);
            isValid = false;
          }
        }
      }

      if (configItem.filterFormType === 'range') {
        const filterData = currentFiltersData[configItem.key];

        if (filterData) {
          const filterValue = filterData.value as TRangeFilterValue;
          const dateValidationError = validateRange(filterValue);
          if (dateValidationError) {
            const currentError = structuredClone(error);
            currentError[configItem.key] = dateValidationError;
            setError(currentError);
            isValid = false;
          }
        }
      }
    });
    return isValid;
  };

  const handleDecodeQueryParams = () => {
    if (queryParams) {
      const initializedFiltersData: TFilterListValuesMap<FilterKeys> = {};
      Object.entries(queryParams).forEach((queryEntry) => {
        const entryKey = queryEntry[0] as FilterKeys;

        if (config[entryKey] && queryEntry[1]) {
          const valueType = config[entryKey].filterFormType;

          if (valueType === 'toggle') {
            const entryValue = queryEntry[1] as string[];

            const isChecked = entryValue[0] === 'true';

            initializedFiltersData[entryKey] = {
              key: entryKey,
              value: isChecked ? ['true'] : ['false'],
              stringRepresentation: isChecked
                ? config[entryKey].variantsList![0].label
                : '',
            };
          }

          if (valueType === 'array') {
            const entryValue = queryEntry[1] as string[];

            const filteredVariantsList = config[entryKey].variantsList?.filter(
              (variant) => entryValue.includes(variant.value)
            );

            initializedFiltersData[entryKey] = {
              key: entryKey,
              value:
                filteredVariantsList?.map((variant) => variant.value) || [],
              stringRepresentation:
                filteredVariantsList?.map((variant) => variant.label) || [],
            };
          }

          if (valueType === 'range' || valueType === 'date') {
            const entryValue = queryEntry[1] as string;

            const rangeValue = decodeFilterRangeValue(entryValue);

            initializedFiltersData[entryKey] = {
              key: entryKey,
              value: rangeValue,
              stringRepresentation:
                valueType === 'date'
                  ? getDateStringRepresentation(rangeValue)
                  : getRangeStringRepresentation(rangeValue),
            };
          }

          if (valueType === 'tab') {
            const entryValue = queryEntry[1] as string;

            initializedFiltersData[entryKey] = {
              key: entryKey,
              value: entryValue,
              stringRepresentation: '',
            };
          }

          if (valueType === 'tree') {
            const entryValue =
              queryEntry[1] as TTechnicalOpportunitiesCategories;

            initializedFiltersData[entryKey] = {
              key: entryKey,
              value: entryValue,
              // Расчитывается на уровне updateChipsByFiltersData из-за сложной логики
              stringRepresentation: '',
            };
          }
        }
      });
      setCurrentFiltersData(initializedFiltersData);
      updateChipsWithFilters(initializedFiltersData);
    }
  };

  useEffect(() => {
    if (isInitPassed) {
      handleDecodeQueryParams();
    }
  }, [isInitPassed]);

  const handleUpdateQueryParams = (
    filtersDataMap: TFilterListValuesMap<FilterKeys>
  ) => {
    Object.keys(config).forEach((configKey) => {
      const entryKey = configKey as FilterKeys;
      const filterData = filtersDataMap[entryKey];
      const valueType = config[entryKey].filterFormType;

      if (valueType === 'tab') {
        const newParams = {
          [entryKey as FilterKeys]: filterData?.value as string,
        } as Record<FilterKeys, any>;

        setQueryParams?.(newParams);
      }

      if (valueType === 'toggle' || valueType === 'array') {
        const newParams = {
          [entryKey as FilterKeys]: filterData?.value as string[],
        } as Record<FilterKeys, any>;

        setQueryParams?.(newParams);
      }

      if (valueType === 'date' || valueType === 'range') {
        const newParams = {
          [entryKey as FilterKeys]: encodeFilterRangeValue(
            filterData?.value as TRangeFilterValue
          ),
        } as Record<FilterKeys, any>;

        setQueryParams?.(newParams);
      }

      if (valueType === 'tree') {
        const treeFilterValue =
          filterData?.value &&
          (filterData?.value as TTechnicalOpportunitiesCategories).length !== 0
            ? (filterData?.value as TTechnicalOpportunitiesCategories)
            : undefined;

        const newParams = {
          [entryKey as FilterKeys]: treeFilterValue,
        } as Record<FilterKeys, any>;

        setQueryParams?.(newParams);
      }
    });
  };

  const onSubmit = () => {
    const isValid = validateFilters();
    if (isValid) {
      handleUpdateQueryParams(currentFiltersData);
      onFilter?.(currentFiltersData);
      onClose();
    }
  };

  useEffect(() => {
    setCurrentFiltersData(filtersData);
    if (isInitPassed) {
      handleUpdateQueryParams(filtersData);
    }
    if (!isInitPassed) {
      setIsInitPassed(true);
    }
  }, [filtersData]);

  useEffect(() => {
    callbackRef.current = () => {
      if (isDirty) {
        onSubmit();
      }
      onClose();
    };
  });

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        callbackRef.current &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !event.target.closest('div[role="dialog"]') &&
        !event.target.closest('.MuiDrawer-paper')
      ) {
        callbackRef.current();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FiltersDrawer
      open={open}
      close={onClose}
      title="Фильтры"
      className={styles.wrapper}
      buttons={
        <Box textAlign="right" className={styles.stickyButtonsContainer}>
          <Button onClick={onSubmit} disabled={!isDirty} sx={{ flex: 1 }}>
            Применить
          </Button>
          <Button
            color="secondary"
            sx={{ flex: 1 }}
            onClick={() => {
              clearFilters();
            }}>
            Сбросить
          </Button>
        </Box>
      }>
      <div ref={wrapperRef} className={styles.contentWrapper}>
        <div className={styles.filtersContainer}>
          {Object.keys(config).map((key) => (
            <BlockFilter
              key={key}
              config={config[key as FilterKeys]}
              filterValue={currentFiltersData?.[key as FilterKeys]}
              onFilter={(selectedFilter) => handleChangeFilters(selectedFilter)}
              error={error[key as FilterKeys]}
              showFilter={config[key as FilterKeys].showFilter}
            />
          ))}
        </div>
      </div>
    </FiltersDrawer>
  );
};
