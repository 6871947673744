export const plural = (
  value: number,
  form1: string,
  form2: string,
  form3: string
) => {
  let resultForm = form3;
  const modulo10 = value % 10;
  const modulo100 = value % 100;

  if (modulo10 === 1 && modulo100 !== 11) {
    resultForm = form1;
  } else if (
    modulo10 >= 2 &&
    modulo10 <= 4 &&
    (modulo100 < 10 || modulo100 >= 20)
  ) {
    resultForm = form2;
  }

  return resultForm.replace(/\$d/g, value.toString());
};
