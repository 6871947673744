import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { selectIsAuth } from 'src/store/auth';
import { useAppSelector } from 'src/store';
import { DEFAULT_PAGE } from 'src/configs';

export const AuthRoute: FC = () => {
  const isAuth = useAppSelector(selectIsAuth);

  return isAuth ? <Navigate to={DEFAULT_PAGE} replace /> : <Outlet />;
};
