import { useEffect, useState } from 'react';
import { Typography, Stack, Button } from 'src/components/ui';
import { ITelephonyCall } from 'src/models/communications';
import { CallIncoming, CallMissed, CallOutgoing } from 'src/assets/icons';
import { plural } from 'src/utils';
import { useSetCommunicationViewMutation } from 'src/api/communications';
import { BubbleItem } from '../bubble-item';

interface ICallProps {
  data: ITelephonyCall[];
  onClick?: () => void;
}

const UNKNOWN_USER_NAME = 'Неизвестно';

export const Call = ({ data, onClick }: ICallProps) => {
  const callsCount = data.length;

  const [isCallListOpen, setIsCallListOpen] = useState(false);

  useEffect(() => {
    if (data.length === 1) {
      setIsCallListOpen(true);
    }
  }, [data]);

  const handleOnListClick = () => {
    setIsCallListOpen(true);
  };

  const getName = (call: ITelephonyCall) =>
    call?.srcType === 'OUTGOING'
      ? call?.owner?.name || UNKNOWN_USER_NAME
      : call?.userDto?.name || UNKNOWN_USER_NAME;

  const getPhoneNumber = (call: ITelephonyCall) =>
    call?.srcType === 'OUTGOING' ? call?.to : call?.from;

  const getUserGroupColor = (call: ITelephonyCall) =>
    call?.srcType === 'OUTGOING'
      ? call?.owner?.userGroup?.color
      : call?.userDto?.userGroup?.color;

  const getIcon = (call: ITelephonyCall) => {
    if (call.status === 'NOANSWER') {
      return CallMissed;
    }

    if (call.srcType === 'OUTGOING') {
      return CallOutgoing;
    }

    return CallIncoming;
  };

  const [setCommunicationView] = useSetCommunicationViewMutation();

  const handleReadAllButtonClick = (items: ITelephonyCall[]) => {
    setCommunicationView({
      communicationIds: items.map((item) => item.id),
    });
  };

  if (callsCount > 1) {
    return (
      <BubbleItem
        name={getName(data[0])}
        date={data[0].sortDate}
        userGroupColor={getUserGroupColor(data[0])}
        badgeIcon={getIcon(data[0])}
        title={
          <>
            <Typography display="inline" color="#7A8694">
              От{' '}
            </Typography>
            <Typography display="inline" color="#292929" fontWeight="500">
              {getName(data[0])}{' '}
            </Typography>
            <Typography display="inline" color="#292929" fontWeight="500">
              {getPhoneNumber(data[0])}{' '}
            </Typography>
            <Typography display="inline" color="#7A8694">
              {`${callsCount} пропущенных ${plural(
                callsCount,
                'звонок',
                'звонка',
                'звонков'
              )}`}
            </Typography>
          </>
        }
        subTitle={
          isCallListOpen ? (
            <Stack>
              {data.map((item) => (
                <Typography fontSize="12px" color="#292929" whiteSpace="nowrap">
                  Пропущенный звонок по заявке № {item.application.id}
                </Typography>
              ))}
              <Button
                variant="text"
                style={{ fontSize: '12px' }}
                onClick={() => handleReadAllButtonClick(data)}>
                Пометить как прочитанные
              </Button>
            </Stack>
          ) : undefined
        }
        onClick={handleOnListClick}
      />
    );
  }

  return (
    <>
      {data.map((item) => (
        <BubbleItem
          redirectUrl={`/requests/${item.application.requestId}`}
          name={getName(item)}
          date={item.sortDate}
          userGroupColor={getUserGroupColor(item)}
          badgeIcon={getIcon(item)}
          title={
            <>
              <Typography display="inline" color="#7A8694">
                От{' '}
              </Typography>
              <Typography display="inline" color="#292929" fontWeight="500">
                {getName(item)}{' '}
              </Typography>
              <Typography display="inline" color="#292929" fontWeight="500">
                {getPhoneNumber(item)}{' '}
              </Typography>
              <Typography display="inline" color="#7A8694">
                {item.status === 'NOANSWER' && 'пропущенный '} звонок по заявке{' '}
              </Typography>
              <Typography
                display="inline"
                color="#292929"
                fontWeight="500"
                whiteSpace="nowrap">
                № {item.application.id}
              </Typography>
            </>
          }
          onClick={onClick}
        />
      ))}
    </>
  );
};
