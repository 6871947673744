import { FIELD_LENGTH } from 'src/constants';
import { lettersAndDigits } from './letters-and-digits';

export const isSWIFT = (value: string) => {
  if (
    !value ||
    ((value.length === FIELD_LENGTH.swift[0] ||
      value.length === FIELD_LENGTH.swift[1]) &&
      lettersAndDigits(value) === true)
  ) {
    return true;
  }
  return 'Введены недопустимые символы';
};
