import { Stack, SvgIcon, Typography } from 'src/components/ui';
import { EmptyNotifications } from 'src/assets/icons';

export const BubbleEmptyWrapper = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    gap="8px"
    minHeight="300px"
    bgcolor="#F3F6FB"
    borderRadius="8px">
    <SvgIcon icon={EmptyNotifications} width="132px" height="132px" />
    <Typography color="#000000" fontWeight="500">
      У вас пока нет новых значимых уведомлений
    </Typography>
  </Stack>
);
