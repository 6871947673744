import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { OutlinedInput as MuiOutlinedInput } from '@mui/material';
import { OutlinedInputProps } from '../../types';

type OutlinedInputTrimProps = Omit<OutlinedInputProps, 'trimValue'>;

export const OutlinedInputTrim: FC<OutlinedInputTrimProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const displayValueRef = useRef(value);

  useEffect(() => {
    if (
      typeof displayValueRef.current !== 'string' ||
      displayValueRef.current.trim() !== value
    ) {
      setDisplayValue(value);
      displayValueRef.current = value;
    }
  }, [value]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange?.({
        ...event,
        target: {
          ...event.target,
          value: event.target.value.trim(),
        },
      });

      setDisplayValue(event.target.value);
      displayValueRef.current = event.target.value;
    },
    [onChange]
  );

  return (
    <MuiOutlinedInput value={displayValue} onChange={handleChange} {...props} />
  );
};
