import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ManagerAuction = lazy(
  () =>
    import(
      /* webpackChunkName: 'ManagerAuction' */
      /* webpackMode: "lazy" */
      `./auction`
    )
);

export const LazyManagerAuction: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ManagerAuction />
  </Suspense>
);
