import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/utils';
import {
  IBindEmailToApplicationParams,
  ISendEmailMessageParams,
  ISendEmailMessageResult,
  IUserEmailListQueryParams,
  TUserEmailListResult,
  TEmailChainResult,
  TKamAccountListResult,
  IUserCallListQueryParams,
  TUserCallListResult,
  TCallChainResult,
  IBindCallToApplicationParams,
  IUserCommunicationListQueryParams,
  TUserCommunicationListResult,
  IAllCommunicationChainResult,
  TEmailMessageResult,
  ISendNoteParams,
  ISendNoteResult,
  ISetCommunicationViewParams,
  ICustomerContactListQueryParams,
  TCustomerContactListResult,
} from 'src/api/communications/models';

export const communicationsAggregatorApi = createApi({
  reducerPath: 'aggregatorApi',
  baseQuery,
  tagTypes: ['Messages', 'Calls', 'AllCommunications', 'CustomerContactList'],
  endpoints: (builder) => ({
    getKamAccountList: builder.query<TKamAccountListResult, void>({
      query: () => 'aggregator/api/v1/email/account',
    }),

    getUserEmailList: builder.query<
      TUserEmailListResult,
      IUserEmailListQueryParams
    >({
      query: (params) => ({
        url: 'aggregator/api/v1/email',
        method: 'GET',
        params,
      }),
      providesTags: ['Messages'],
    }),
    getEmailChain: builder.query<TEmailChainResult, string>({
      query: (emailId) => `aggregator/api/v1/email/${emailId}/chain`,
    }),
    getEmailChainByApplicationId: builder.query<TEmailChainResult, string>({
      query: (applicationId) =>
        `aggregator/api/v1/email/application/${applicationId}`,
    }),
    getEmailMessage: builder.query<TEmailMessageResult, string>({
      query: (emailId) => `aggregator/api/v1/email/${emailId}`,
    }),
    sendEmail: builder.mutation<
      ISendEmailMessageResult,
      ISendEmailMessageParams
    >({
      query: ({ ...body }) => ({
        url: 'aggregator/api/v1/email/send',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Messages'], // TODO: Разобраться с тегами для всего аггрегатора
    }),
    bindEmailToApplication: builder.mutation<
      void,
      IBindEmailToApplicationParams
    >({
      query: ({ ...body }) => ({
        url: 'aggregator/api/v1/email/bind',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Messages'],
    }),

    getUserCallList: builder.query<
      TUserCallListResult,
      IUserCallListQueryParams
    >({
      query: (params) => ({
        url: 'aggregator/api/v1/call',
        method: 'GET',
        params,
      }),
    }),
    getCallChain: builder.query<TCallChainResult, string>({
      query: (applicationId) =>
        `aggregator/api/v1/call/by-request/${applicationId}`,
      providesTags: ['Calls'],
    }),
    bindCallToApplication: builder.mutation<void, IBindCallToApplicationParams>(
      {
        query: ({ ...body }) => ({
          url: 'aggregator/api/v1/call/bind',
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['Calls'],
      }
    ),

    getUserCommunicationList: builder.query<
      TUserCommunicationListResult,
      IUserCommunicationListQueryParams
    >({
      query: (params) => ({
        url: 'aggregator/api/v1/communication',
        method: 'GET',
        params,
      }),
    }),
    // TODO: Теперь и по компаниям, переделать
    // Здесь цепочки только по заявкам
    getAllCommunicationsChain: builder.query<
      IAllCommunicationChainResult,
      string
    >({
      query: (applicationId) =>
        `aggregator/api/v1/communication/application/${applicationId}`,
    }),

    sendNote: builder.mutation<ISendNoteResult, ISendNoteParams>({
      query: ({ ...body }) => ({
        url: 'aggregator/api/v1/note/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Messages'], // TODO: Разобраться с тегами для всего аггрегатора
    }),

    setCommunicationView: builder.mutation<void, ISetCommunicationViewParams>({
      query: ({ ...body }) => ({
        url: 'aggregator/api/v1/communication/view',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Messages', 'Calls', 'AllCommunications'],
    }),

    getCustomerContactList: builder.query<
      TCustomerContactListResult,
      ICustomerContactListQueryParams
    >({
      query: ({ applicationId }) => ({
        url: `aggregator/api/v1/communication/application/${applicationId}/contacts`,
        method: 'GET',
      }),
      providesTags: ['CustomerContactList'],
    }),
  }),
});

export const {
  useGetKamAccountListQuery,
  useLazyGetUserEmailListQuery,
  useGetEmailChainQuery,
  useGetEmailChainByApplicationIdQuery,
  useGetEmailMessageQuery,
  useSendEmailMutation,
  useBindEmailToApplicationMutation,
  useLazyGetUserCallListQuery,
  useGetCallChainQuery,
  useBindCallToApplicationMutation,
  useLazyGetUserCommunicationListQuery,
  useGetAllCommunicationsChainQuery,
  useSendNoteMutation,
  useSetCommunicationViewMutation,
  useGetCustomerContactListQuery,
} = communicationsAggregatorApi;
