import { Tooltip, Box } from 'src/components/ui';
import { TagStatusTooltip } from 'src/constants';
import { TSubcategory } from 'src/models/admin';
import { useState } from 'react';
import { StatusIcon } from '../status-icon';
import styles from './subcategory.module.scss';
import { CategoryDescriptionModal } from './components/category-description-modal';

type TSubcategoryProps = {
  subcategory: TSubcategory;
  setCurrentSubcategoryItem: (subcategory: TSubcategory) => void;
  setOpenSubModal: (open: boolean) => void;
};
export const Subcategory = ({
  subcategory,
  setCurrentSubcategoryItem,
  setOpenSubModal,
}: TSubcategoryProps) => {
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);

  const { name, status } = subcategory;

  const handleOpenModal = () => {
    setOpenSubModal(true);
    setCurrentSubcategoryItem(subcategory);
  };

  const handleCloseDescriptionModal = () => {
    setOpenDescriptionModal(false);
  };

  const handleOpenDescriptionModal = () => {
    setOpenDescriptionModal(true);
  };

  return (
    <div className={styles.containerName}>
      <Box className={styles.name} onClick={handleOpenDescriptionModal}>
        {name}
      </Box>
      <Tooltip title={TagStatusTooltip[status]}>
        <div>{status && <StatusIcon status={status} />}</div>
      </Tooltip>
      <CategoryDescriptionModal
        open={openDescriptionModal}
        close={handleCloseDescriptionModal}
        subcategory={subcategory}
      />
    </div>
  );
};
