import { TagStatus, TagStatusTooltip } from 'src/constants';
import { FC } from 'react';
import { Tooltip } from 'src/components/ui';
import classNames from 'classnames';
import { ETagStatuses } from 'src/configs/tag-status';
import styles from './status-icon.module.scss';

type TStatusIcon = {
  status: ETagStatuses;
};
export const StatusIcon: FC<TStatusIcon> = ({ status }) => {
  const StatusComponent = TagStatus[status];

  return (
    <Tooltip title={TagStatusTooltip[status]}>
      <div className={classNames(styles.icon, styles[status])}>
        {StatusComponent ? <StatusComponent /> : null}
      </div>
    </Tooltip>
  );
};
