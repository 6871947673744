import { FC, useRef } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from 'src/components/ui';
import styles from './personal-protection-policy-dialog.module.scss';

export const PersonalProtectionPolicyDialog: FC<{
  open: boolean;
  onClose: (checked?: boolean) => void;
  checked?: boolean;
  hideActions?: boolean;
}> = ({ open, onClose, checked, hideActions }) => {
  const checkbox = useRef<HTMLInputElement>(null);

  return (
    <Dialog
      open={open}
      close={() => onClose(checkbox.current?.checked)}
      PaperProps={{
        sx: {
          maxWidth: '590px',
          maxHeight: { xs: '100vh', sm: '670px' },
          boxShadow: 'none',
        },
      }}>
      <DialogTitle>
        ПОЛИТИКА ООО «СЕВЕРСТАЛЬ ЦИФРОВЫЕ РЕШЕНИЯ» В ОБЛАСТИ ОРГАНИЗАЦИИ
        ОБРАБОТКИ И ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </DialogTitle>
      <DialogContent className={styles.content}>
        {
          'Для поддержания деловой репутации и гарантирования выполнения норм Федерального закона РФ № 152-ФЗ и (или) применимого иностранного законодательства в области обработки и защиты персональных данных в полном объеме, в том числе Регламента Европейского Союза № 2016/679 (далее — Законодательство), ООО «Северсталь Цифровые Решения» (далее — Общество) считает обязательным соблюдение целей, принципов и условий законности обработки персональных данных. Общество также стремится следовать лучшим международным практикам в области обработки и защиты персональных данных.\n\n'
        }

        <Typography fontSize="16px" fontWeight="500" mb="8px">
          Цель Политики
        </Typography>
        {
          'Обеспечение защиты прав и свобод человека (далее — Субъекта) при обработке его персональных данных в соответствии с применимым Законодательством, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.\n\n'
        }

        <Typography fontSize="16px" fontWeight="500" mb="8px">
          Принципы обработки персональных данных:
        </Typography>
        <ul className={styles.list}>
          <li>
            Соблюдение прав Субъектов при обработке их персональных данных.
          </li>
          <li>
            Осуществление обработки персональных данных на законной и
            справедливой основе для достижения целей их обработки.
          </li>
          <li>
            Недопущение обработки персональных данных, которые не отвечают целям
            обработки, избыточны или содержатся в базах данных, цели обработки
            которых несовместимы.
          </li>
          <li>
            Обеспечение точности, достаточности и актуальности персональных
            данных при их обработке по отношению к целям их обработки.
          </li>
          <li>
            Хранение персональных данных не дольше, чем этого требуют цели
            обработки.
          </li>
          <li>
            Уничтожение или обезличивание персональных данных при достижении
            целей их обработки или в случае утраты необходимости в достижении
            этих целей.
          </li>
        </ul>

        <Typography fontSize="16px" fontWeight="500" mb="8px">
          Условия обработки персональных данных:
        </Typography>
        <ul className={styles.list}>
          <li>
            Обработка персональных данных осуществляется с соблюдением принципов
            и правил, предусмотренных применимым Законодательством.
          </li>
          <li>
            Запись, систематизация, накопление, хранение, уточнение, извлечение
            персональных данных граждан РФ при сборе персональных данных
            осуществляются с использованием баз данных, находящихся на
            территории РФ, если иное не предусмотрено применимым
            Законодательством.
          </li>
          <li>
            Трансграничная передача персональных данных допускается при условии
            их сбора на территории РФ в соответствии с применимым
            Законодательством.
          </li>
          <li>
            Любые лица (включая директоров, служащих, сотрудников, агентов,
            представителей или иных посредников), которые выполняют поручения
            или оказывают услуги для или от имени Общества и получившие доступ к
            персональным данным, обязуются сохранять конфиденциальность и не
            обрабатывать их без конкретного законного основания.
          </li>
          <li>
            При получении персональных данных из Европейского Союза или в иных
            применимых случаях обработка персональных данных осуществляется с
            предварительного получения у Субъекта согласия на обработку
            персональных данных или уведомления Субъекта об обработке его
            персональных данных или на иных законных основаниях, предусмотренных
            применимым Законодательством.
          </li>
        </ul>

        <Typography fontSize="16px" fontWeight="500" mb="8px">
          Cпособы достижения целей:
        </Typography>
        <ul className={styles.list}>
          <li>
            Отказ от обработки специальных категорий персональных данных,
            обработка которых, согласно применимому Законодательству, запрещена.
          </li>
          <li>
            Обеспечение защищенности информационных систем, в которых
            осуществляется обработка персональных данных, от воздействия
            актуальных угроз безопасности с учетом оценки вреда Субъектам.
          </li>
          <li>
            Применение комплекса организационных и технических мер по
            обеспечению безопасности персональных данных при их обработке в
            информационных системах, а также без использования средств
            автоматизации.
          </li>
          <li>
            Систематический контроль соответствия обработки персональных данных
            требованиям применимого Законодательства.
          </li>
          <li>
            Проведение мероприятий по информированию и обучению персонала
            правилам обработки и защиты персональных данных.
          </li>
          <li>
            Привлечение виновных лиц к ответственности за нарушение применимого
            Законодательства и внутренних документов Общества, регулирующих
            порядок обработки персональных данных.
          </li>
        </ul>
      </DialogContent>
      {!hideActions && (
        <DialogActions className={styles.footer}>
          <FormControlLabel
            label="Я согласен с положениями Политики в области обработки и защиты персональных данных"
            control={
              <Checkbox inputRef={checkbox} defaultChecked={Boolean(checked)} />
            }
          />
        </DialogActions>
      )}
    </Dialog>
  );
};
