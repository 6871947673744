import { Component, ReactElement } from 'react';
import { Result } from 'src/components/ui';

export class ErrorBoundary extends Component<
  { children: ReactElement },
  { isError: boolean }
> {
  constructor(props: { children: ReactElement }) {
    super(props);
    this.state = {
      isError: false,
    };
  }

  componentDidCatch() {
    this.setState({ isError: true });
  }

  render() {
    const { isError } = this.state;
    const { children } = this.props;

    return isError ? <Result title="Что-то пошло не так" /> : children;
  }
}
