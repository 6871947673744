import { Autocomplete, Form, OutlinedInput } from 'src/components/ui';
import { required, maxLength } from 'src/utils/form-rules';
import { FC, useCallback } from 'react';
import { FIELD_LENGTH } from 'src/constants';
import styles from './reasons-manager.module.scss';

interface IReasonsManagerProps {
  items:
    | {
        id: number;
        name: string;
        nameForCustomer: string;
        nameForContractor: string;
        status: string;
      }[]
    | undefined;
  onKamReasonsChanged: () => void;
}

export const ReasonsManager: FC<IReasonsManagerProps> = ({
  items,
  onKamReasonsChanged,
}) => {
  const fetchApiFilter = useCallback(
    (value: string) =>
      Promise.resolve(
        (value
          ? items?.filter((element) =>
              element.name.toLowerCase().includes(value.toLowerCase())
            )
          : items) || []
      ),
    [items]
  );

  return (
    <>
      <Form.Item
        noMargin
        name="managerSelectedReason"
        label="Выберите внутреннюю причину отказа"
        rules={{
          required,
        }}>
        <Autocomplete
          popupIcon
          options={items}
          fetchApi={fetchApiFilter}
          getOptionLabel={(opt) => opt.name}
          onChange={() => {
            onKamReasonsChanged();
          }}
          fetchOnEmptyInput
          placeholder="Выбрать"
        />
      </Form.Item>

      <div className={styles.indent} />

      <Form.Item
        noMargin
        name="managerComment"
        label="Напишите внутренний комментарий"
        rules={{
          required,
          maxLength: maxLength(FIELD_LENGTH.rejectApplicationComment),
        }}>
        <OutlinedInput
          placeholder="Введите комментарий"
          multiline
          rows={6}
          maxLength={FIELD_LENGTH.rejectApplicationComment}
        />
      </Form.Item>
    </>
  );
};
