import { useEffect, useState } from 'react';
import { IconButton, OutlinedInput, Popover } from 'src/components/ui';
import { DateIcon } from 'src/assets/icons';
import { addDays, format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import { STATIC_RANGES } from './config';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface IDateRangePicker {
  value?: {
    from: Date | null;
    to: Date | null;
  };
  onChange?: (value: { from: Date | null; to: Date | null }) => void;
  size?: 'medium' | 'small';
  onBlur?: () => void;
  disableManualInput?: boolean;
  placeholder?: string;
  staticRanges?: {
    label: string;
    range: () => {
      startDate: Date;
      endDate: Date;
    };
    isSelected(): boolean;
  }[];
}

export const DateRangePicker = ({
  value,
  onChange = () => {},
  size,
  placeholder = '_ _._ _._ _ _ _',
  onBlur: onBlurProps,
  disableManualInput = true,
  staticRanges,
  ...props
}: IDateRangePicker) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [state, setState] = useState<any>([
    {
      startDate: value?.from || new Date(),
      endDate: value?.to || addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    onChange({
      from: state[0].startDate,
      to: state[0].endDate,
    });
  }, [state]);

  return (
    <>
      <OutlinedInput
        value={
          value
            ? `${format(state[0]!.startDate!, 'dd.MM.yyyy')} - ${format(
                state[0]!.endDate!,
                'dd.MM.yyyy'
              )}`
            : value
        }
        size={size}
        trimValue={false}
        inputProps={{
          placeholder,
          readOnly: disableManualInput,
        }}
        endAdornment={
          <IconButton onClick={handleClick}>
            <DateIcon />
          </IconButton>
        }
        sx={{ width: '300px' }}
        // onBlur={() => {
        //   if (isMinDate) {
        //       onBlur(value, onChange);
        //   }
        //   onBlurProps?.();
        // }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <ReactDateRangePicker
          staticRanges={staticRanges || STATIC_RANGES}
          inputRanges={[]}
          locale={ru}
          onChange={(item) => setState([item.selection])}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </Popover>
    </>
  );
};
