import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastNotification: FC = () => (
  <ToastContainer
    style={{
      width: '430px',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    limit={5}
    newestOnTop
  />
);
