import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiCircularProgress: Components<Theme>['MuiCircularProgress'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary.main,
    }),
  },
};
