import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const Chat = lazy(
  () =>
    import(
      /* webpackChunkName: 'Chat' */
      /* webpackMode: "lazy" */
      `./chat`
    )
);

export const LazyChat: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <Chat />
  </Suspense>
);
