import { useMemo } from 'react';
import {
  IEmailMessage,
  ITelephonyCall,
  INote,
  IMessage,
  IStatus,
} from 'src/models/communications';
import { getFormatDate } from 'src/components/pages/communications/utils';
import { compareAsc } from 'date-fns';

export const useChainWithDate = (
  chain?: Array<IEmailMessage | ITelephonyCall | INote | IMessage | IStatus>
) =>
  useMemo(() => {
    if (!chain?.length) {
      return null;
    }

    const sortedChain = [...chain].sort((a, b) =>
      compareAsc(new Date(a.sortDate), new Date(b.sortDate))
    );

    const chainWithDateObj = sortedChain.reduce((acc, curr) => {
      if (curr?.sortDate) {
        const date = getFormatDate(curr.sortDate as string);

        if (acc[date]) {
          acc[date].push(curr);
        } else {
          acc[date] = [curr];
        }
      }

      return acc;
    }, {} as Record<string, Array<IEmailMessage | ITelephonyCall | INote | IMessage | IStatus>>);

    return Object.entries(chainWithDateObj).reduce((acc, [key, value]) => {
      acc[key] = value.sort((a, b) =>
        compareAsc(new Date(a.sortDate), new Date(b.sortDate))
      );

      return acc;
    }, {} as Record<string, Array<IEmailMessage | ITelephonyCall | INote | IMessage | IStatus>>);
  }, [chain]);
