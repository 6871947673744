import { FC } from 'react';
import { Box } from 'src/components/ui';
import {
  ConversationEntityType,
  DocumentEntityTypes,
  DocumentTypes,
} from 'src/models';
import { AttachFiles, OpenChat } from 'src/components/widgets';
import { arrayRequired } from 'src/utils/form-rules';
import { Finance, Deadlines, Comments } from '../components';

interface ICustomerCommercialOfferProps {
  isEditing: boolean;
  commercialOfferId?: number;
  businessRequestId: number;
  checkRequired: boolean;
  offerFromContractor?: boolean;
  orderProcessingStarted?: boolean;
  shippingIncluded?: boolean;
}

const mode = 'customer';

export const CustomerCommercialOffer: FC<ICustomerCommercialOfferProps> = ({
  isEditing,
  commercialOfferId,
  businessRequestId,
  checkRequired,
  offerFromContractor,
  orderProcessingStarted,
  shippingIncluded,
}) => (
  <>
    <Finance
      isEditing={isEditing}
      mode={mode}
      checkRequired={checkRequired}
      offerFromContractor={offerFromContractor}
      shippingIncluded={shippingIncluded}
    />

    <Deadlines
      isEditing={isEditing}
      mode={mode}
      checkRequired={checkRequired}
    />

    {!orderProcessingStarted && (
      <OpenChat
        entityType={ConversationEntityType.BUSINESS_REQUEST}
        entityId={businessRequestId}
        mode="manager"
        sx={{ marginBottom: '24px' }}
      />
    )}

    <Comments isEditing={isEditing} checkRequired={checkRequired} mode={mode} />

    <Box mb="24px">
      <AttachFiles
        noMargin
        formItemName="documents"
        isEditMode={isEditing}
        documentOptions={{
          entityType: DocumentEntityTypes.COMMERCIAL_OFFER_FOR_CUSTOMER,
          entityId: commercialOfferId || 0,
          documentType: DocumentTypes.COMMERCIAL_OFFER,
        }}
        fullScreen={false}
        rules={{ validate: checkRequired ? arrayRequired : undefined }}
      />
    </Box>
  </>
);
