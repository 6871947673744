import { TDocument } from '../document';

export enum ContractorSpecificationStatus {
  PRELIMINARY_VERSION_RECEIVED = 'PRELIMINARY_VERSION_RECEIVED',
  DELETED = 'DELETED',
  SIGNED_SCAN_SENT = 'SIGNED_SCAN_SENT',
  FINAL_VERSION_RECEIVED = 'FINAL_VERSION_RECEIVED',
}

export interface IContractorSpecificationStatus {
  code: ContractorSpecificationStatus;
  createdAt: string;
  displayName: string;
}
export interface IContractorSpecification {
  specificationId: number;
  contractorRequestId: number;
  contractorData: {
    organizationId: number;
    organizationName: string;
  };
  cost: number | string;
  supplyDuration: number | string;
  paymentPeriodProlongation: number;
  paymentConditions: string;
  quickDeal: boolean;

  status: IContractorSpecificationStatus;
  preliminaryVersionReceivedAt: string | null;
  signedScanSentAt?: string | null;
  finalVersionReceivedAt?: string | null;
  createdAt: string;
  draftLastModifiedAt?: string | null;
  documents: {
    SPECIFICATION_PRELIMINARY_VERSION: TDocument[];
    SPECIFICATION_SIGNED_SCAN?: TDocument[];
    SPECIFICATION_FINAL_VERSION?: TDocument[];
  };
}
