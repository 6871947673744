import { FC, useContext } from 'react';
import { Form, OutlinedInput } from 'src/components/ui';
import { maxLength, required } from 'src/utils/form-rules';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TSubcategory, TVariantTag } from 'src/models/admin';
import { useEditSecondLevelCategoryMutation } from 'src/api/admin/technical-opportunities';

import { getErrorMessage } from 'src/store/utils';
import { ETagStatuses } from 'src/configs/tag-status';
import { FIELD_LENGTH } from 'src/constants';
import { TechnicalOpportunitiesContext } from '../../hooks/use-technical-opportunities';

type TSubcategoryForm = {
  subcategory: TSubcategory | TVariantTag;
  onClose: () => void;
  updateForm?: (subTag: TVariantTag) => void;
};

type TFormInput = {
  id: string | number;
  name: string;
  status: ETagStatuses;
};

export const SubcategoryForm: FC<TSubcategoryForm> = ({
  subcategory,
  onClose,
  updateForm,
}) => {
  const [updateSecondLevelCategory, { isLoading }] =
    useEditSecondLevelCategoryMutation();
  const { handleUpdateList } = useContext(TechnicalOpportunitiesContext);
  const form = useForm<TFormInput>({
    defaultValues: {
      id: subcategory.id,
      name: subcategory.name,
      status: subcategory.status,
    },
  });

  const onSubmit: SubmitHandler<TFormInput> = (data) => {
    updateSecondLevelCategory(data)
      .unwrap()
      .then(() => {
        onClose();
        handleUpdateList();
        updateForm?.(data);
      })
      .catch((e) => {
        form.setError('name', {
          message: getErrorMessage(
            e,
            'Произошла ошибка при редактировании тега'
          ),
        });
      });
  };

  return (
    <Form
      form={form}
      onCancel={onClose}
      onSubmit={onSubmit}
      loading={isLoading}>
      <Form.Item
        name="name"
        rules={{
          required,
          maxLength: maxLength(FIELD_LENGTH.technicalOpportunitiesSecondLevel),
        }}>
        <OutlinedInput placeholder="Введите новый тег" />
      </Form.Item>
    </Form>
  );
};
