import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ContractorsSigningKanban = lazy(
  () =>
    import(
      /* webpackChunkName: 'ContractorsSigningKanban' */
      /* webpackMode: "lazy" */
      `./contractors-signing-kanban`
    )
);

export const LazyContractorsSigningKanban: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ContractorsSigningKanban />
  </Suspense>
);
