import { HelpExpertBarStage } from 'src/components/widgets/help-expert-bar';
import {
  InvokeExpertStage,
  InvokeExpertStatus,
  ISpecificRole,
} from 'src/models/bids-details';

export const getRequestStage = (
  assignedSpecificRoles: Record<InvokeExpertStage, ISpecificRole[] | undefined>
): HelpExpertBarStage => {
  if (
    assignedSpecificRoles[InvokeExpertStage.REQUEST_FORMULATION] ||
    assignedSpecificRoles[InvokeExpertStage.REQUEST_AGREEMENT]
  ) {
    return HelpExpertBarStage.REQUEST;
  }

  if (
    assignedSpecificRoles[InvokeExpertStage.LOGISTICS_NEW_REQUEST] ||
    assignedSpecificRoles[InvokeExpertStage.QUOTATION_AGREEMENT]
  ) {
    return HelpExpertBarStage.COMMERCIAL_OFFER;
  }

  if (
    assignedSpecificRoles[InvokeExpertStage.LOGISTICS_NEW_REQUEST] ||
    assignedSpecificRoles[InvokeExpertStage.SPECIFICATION_AGREEMENT]
  ) {
    return HelpExpertBarStage.SPECIFICATION;
  }

  return HelpExpertBarStage.REQUEST;
};

export const isRequestApproved = (
  assignedSpecificRoles: Record<InvokeExpertStage, ISpecificRole[] | undefined>,
  expertId: number,
  stage: HelpExpertBarStage
): boolean => {
  let substages = [
    InvokeExpertStage.REQUEST_FORMULATION,
    InvokeExpertStage.REQUEST_AGREEMENT,
  ];

  if (stage === HelpExpertBarStage.COMMERCIAL_OFFER) {
    substages = [
      InvokeExpertStage.SPECIFICATION_PROVIDED,
      InvokeExpertStage.QUOTATION_AGREEMENT,
    ];
  }

  if (stage === HelpExpertBarStage.SPECIFICATION) {
    substages = [
      InvokeExpertStage.SPECIFICATION_PROVIDED,
      InvokeExpertStage.SPECIFICATION_AGREEMENT,
    ];
  }

  const isRequestFormed = !!assignedSpecificRoles[substages[0]]?.find(
    (expert) =>
      expert.approvalStatus === InvokeExpertStatus.FORMED &&
      expert.assignedManager?.id === expertId
  );

  const isRequestAgreed = !!assignedSpecificRoles[substages[1]]?.find(
    (expert) =>
      expert.approvalStatus === InvokeExpertStatus.APPROVED &&
      expert.assignedManager?.id === expertId
  );

  return isRequestFormed || isRequestAgreed;
};

export const isRequestRejected = (
  assignedSpecificRoles: Record<InvokeExpertStage, ISpecificRole[] | undefined>,
  expertId: number,
  stage: HelpExpertBarStage
): boolean => {
  let substages = [
    InvokeExpertStage.REQUEST_FORMULATION,
    InvokeExpertStage.REQUEST_AGREEMENT,
  ];

  if (stage === HelpExpertBarStage.COMMERCIAL_OFFER) {
    substages = [
      InvokeExpertStage.SPECIFICATION_PROVIDED,
      InvokeExpertStage.QUOTATION_AGREEMENT,
    ];
  }

  if (stage === HelpExpertBarStage.SPECIFICATION) {
    substages = [
      InvokeExpertStage.SPECIFICATION_PROVIDED,
      InvokeExpertStage.SPECIFICATION_AGREEMENT,
    ];
  }

  const isRequestRejectedOnFormulation = !!assignedSpecificRoles[
    substages[0]
  ]?.find(
    (expert) =>
      expert.approvalStatus === InvokeExpertStatus.REJECTED &&
      expert.assignedManager?.id === expertId
  );

  const isRequestRejectedOnAgreement = !!assignedSpecificRoles[
    substages[1]
  ]?.find(
    (expert) =>
      expert.approvalStatus === InvokeExpertStatus.REJECTED &&
      expert.assignedManager?.id === expertId
  );

  return isRequestRejectedOnFormulation || isRequestRejectedOnAgreement;
};

export const isRequestInWork = (
  assignedSpecificRoles: Record<InvokeExpertStage, ISpecificRole[] | undefined>,
  expertId: number,
  stage: HelpExpertBarStage
): boolean =>
  !isRequestApproved(assignedSpecificRoles, expertId, stage) &&
  !isRequestRejected(assignedSpecificRoles, expertId, stage);
