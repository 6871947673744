import { FC, useEffect, useRef, useState } from 'react';
import { Skeleton, Box } from 'src/components/ui';

const COLUMN_WIDTH = 364;

export const KanbanSkeleton: FC = () => {
  const [columnSizeConfig, setColumnSizeConfig] = useState([4, 3, 4, 1, 5]);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const containerWidth = refContainer.current?.clientWidth;

    if (!containerWidth) return;

    const counterColumn = Math.floor(containerWidth / COLUMN_WIDTH);
    setColumnSizeConfig((prevState) => prevState.slice(0, counterColumn));
  }, []);

  return (
    <Box
      ref={refContainer}
      display="flex"
      justifyContent="center"
      gap="20px"
      maxWidth="100%"
      flex={1}>
      {columnSizeConfig.map((size, sizeIndex) => (
        <Box key={sizeIndex} display="flex" flexDirection="column" gap="10px">
          <Skeleton height="40px" width="344px" sx={{ marginTop: '10px' }} />

          {[...new Array(size)].map((_, index) => (
            <Skeleton key={index} height="126px" width="344px" />
          ))}
        </Box>
      ))}
    </Box>
  );
};
