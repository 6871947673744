import IconButton from '@mui/material/IconButton';
import { useLazyDownloadDocumentQuery } from 'src/api/document';
import { SignatureDocument } from 'src/assets/icons';
import { TContact } from 'src/components/pages/admin/company/contacts/types';
import { TContactWithSigningBasis } from 'src/components/pages/company/contacts/types';
import {
  InfoRow,
  InfoStack,
  Loader,
  Stack,
  Typography,
} from 'src/components/ui';
import { fileSizeFormat } from 'src/utils';
import styles from './signing-basis-tooltip.module.scss';

export const SigningBasisTooltip = ({
  item,
  onEditClick,
}: {
  item: TContactWithSigningBasis;
  onEditClick?: (item: TContact) => void;
}) => {
  const [downloadDocument, { isFetching }] = useLazyDownloadDocumentQuery();

  return (
    <Stack gap="12px" className={styles.stack}>
      <InfoRow
        label="Действует на основании"
        value={item.currentSigningBasis?.signingBasisName}
      />
      {item.currentSigningBasis?.document && (
        <InfoRow
          label="Документ"
          value={
            <InfoStack
              noBorder
              className={styles.stack}
              subtitle={`${fileSizeFormat(
                item.currentSigningBasis!.document.sizeBytes,
                true
              )}`}
              before={
                <IconButton
                  className={styles.fileIcon}
                  onClick={() =>
                    downloadDocument(item.currentSigningBasis!.documentId!)
                  }>
                  {isFetching ? <Loader /> : <SignatureDocument />}
                </IconButton>
              }>
              <Typography className={styles.title}>
                {item.currentSigningBasis.fileName}
              </Typography>
            </InfoStack>
          }
        />
      )}
      {!item.currentSigningBasis!.documentId && (
        <Typography>
          У контактного лица не указан документ на основании которого он
          является подписантом и не прикреплена копия документа. Для изменния
          данных параметров перейдите к
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => onEditClick?.(item)}
            color="primary">
            редактированию
          </Typography>{' '}
          контакта
        </Typography>
      )}
    </Stack>
  );
};
