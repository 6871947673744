import { differenceInCalendarDays, format } from 'date-fns';
import { TDate } from 'src/models';

export const getFormatDate = (date: TDate) => {
  const includedDay = differenceInCalendarDays(new Date(), new Date(date));

  if (includedDay < 1) return 'Сегодня';
  if (includedDay === 1) return 'Вчера';

  return format(new Date(date), 'dd.MM.yyyy');
};
