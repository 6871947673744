import {
  IContractorShipping,
  IContractorUpdateShippingParams,
} from 'src/models';
import { businessRequestApi } from '../business-request';

export const contractorShippingApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getContractorShipping: builder.query<IContractorShipping, number>({
      query: (contractorRequestId) => ({
        url: 'business-requests/api/v1/contractor/shipment-group',
        method: 'GET',
        params: { contractorRequestId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getContractorShipping', id: arg },
      ],
    }),

    updateContractorShipping: builder.mutation<
      IContractorShipping,
      IContractorUpdateShippingParams
    >({
      query: ({ shipmentGroupId, ...body }) => ({
        url: `business-requests/api/v1/contractor/shipment-group/${shipmentGroupId}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteContractorShipping: builder.mutation<
      void,
      { shipmentId: number; parentEntityId: number }
    >({
      query: ({ shipmentId }) => ({
        url: `business-requests/api/v1/shipment/${shipmentId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { shipmentId, parentEntityId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;

          dispatch(
            contractorShippingApi.util.updateQueryData(
              'getContractorShipping',
              parentEntityId,
              (data) => ({
                ...data,
                shipments: data.shipments.filter(
                  (shipment) => shipment.shipmentId !== shipmentId
                ),
              })
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),

    setContractorShipmentComment: builder.mutation<
      { shipmentGroupId: number; comment: string },
      {
        shipmentGroupId: number;
        comment: string;
        parentEntityId: number;
      }
    >({
      query: ({ shipmentGroupId, comment }) => ({
        url: `business-requests/api/v1/shipment-group/${shipmentGroupId}/comment`,
        method: 'PATCH',
        body: { comment },
      }),
      async onQueryStarted({ parentEntityId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            contractorShippingApi.util.updateQueryData(
              'getContractorShipping',
              parentEntityId,
              (cache) => ({ ...cache, comment: data.comment })
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),
  }),
});

export const {
  useGetContractorShippingQuery,
  useUpdateContractorShippingMutation,
  useDeleteContractorShippingMutation,
  useSetContractorShipmentCommentMutation,
} = contractorShippingApi;
