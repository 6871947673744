import { FC } from 'react';
import { Box, Typography } from 'src/components/ui';

interface IFormErrorProps {
  messageError: string | undefined;
}

export const FormError: FC<IFormErrorProps> = ({ messageError }) => (
  <Box mt="24px">
    <Typography
      color="error"
      whiteSpace="pre-line"
      sx={{ overflowWrap: 'break-word' }}>
      {messageError}
    </Typography>
  </Box>
);
