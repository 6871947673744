import { FC, useState } from 'react';
import { FIELD_LENGTH } from 'src/constants';
import { maxLength, required } from 'src/utils/form-rules';
import { Form, InfoRow, OutlinedInput, Typography } from 'src/components/ui';
import { IChangeLogs } from 'src/models/changelogs';
import IconButton from '@mui/material/IconButton';
import { ChangeLogsWindow } from 'src/components/widgets/change-logs-window';
import { History } from 'src/assets/icons';
import { IComponentsProp } from '../../types';

interface IPaymentConditionsProps extends IComponentsProp {
  paymentConditionsHistory?: IChangeLogs;
}
export const PaymentConditions: FC<IPaymentConditionsProps> = ({
  viewMode,
  checkRequired,
  paymentConditionsHistory,
  mode,
}) => {
  const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);
  const toggleChangeModalOpen = () => {
    setIsChangeModalOpen(!isChangeModalOpen);
  };
  return (
    <>
      <Form.Item
        required
        viewMode={viewMode}
        name="paymentConditions"
        label="Условия оплаты"
        rules={{
          required: checkRequired ? required : undefined,
          maxLength: maxLength(FIELD_LENGTH.commercialOffer.paymentConditions),
        }}
        renderView={(value) => (
          <InfoRow
            label="Условия оплаты"
            value={
              <>
                {value && (
                  <Typography whiteSpace="pre-wrap">{value}</Typography>
                )}
                {mode === 'contractor' &&
                  !!paymentConditionsHistory?.history.length && (
                    <IconButton onClick={toggleChangeModalOpen}>
                      <History />
                    </IconButton>
                  )}
              </>
            }
          />
        )}>
        <OutlinedInput
          multiline
          placeholder="Введите условия оплаты"
          maxRows={7}
          maxLength={FIELD_LENGTH.commercialOffer.paymentConditions}
        />
      </Form.Item>
      <ChangeLogsWindow
        onClose={toggleChangeModalOpen}
        changeHistory={paymentConditionsHistory?.history}
        title="Условия оплаты"
        open={isChangeModalOpen}
      />
    </>
  );
};
