import classNames from 'classnames';
import { forwardRef } from 'react';
import { OutlinedInput as MuiOutlinedInput } from '@mui/material';
import { Typography, Box } from 'src/components/ui';
import { OutlinedInputProps } from './types';
import { OutlinedInputTrim } from './components';

export const OutlinedInput = forwardRef<any, OutlinedInputProps>(
  (
    { maxLength, value, trimValue = true, applyNewIconSizing, ...props },
    ref
  ) => (
    <Box display="flex" flexDirection="column" ref={ref}>
      {trimValue ? (
        <OutlinedInputTrim
          value={value}
          {...props}
          className={classNames(
            props.className,
            !applyNewIconSizing && 'old-svg-size'
          )}
        />
      ) : (
        <MuiOutlinedInput
          value={value}
          {...props}
          className={classNames(
            props.className,
            !applyNewIconSizing && 'old-svg-size'
          )}
        />
      )}
      {maxLength && maxLength > 0 && (
        <Typography color="secondary" fontSize="12px" mt="4px">
          {`${(value as string)?.length ?? 0}/${maxLength}`}
        </Typography>
      )}
    </Box>
  )
);
