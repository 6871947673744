import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { adminUserApi } from 'src/api/admin/user';
import { CompanyTypes } from 'src/models';
import { baseQueryWithReauth } from 'src/store/utils';
import { TContact } from 'src/components/pages/company/contacts/types';

export interface EditSigningBasisBody {
  signingBasisId: number;
  body: FormData;
}

export const adminContactsApi = createApi({
  reducerPath: 'adminContactsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getContacts: builder.query<
      Array<TContact>,
      { companyType: CompanyTypes; idCompany: number }
    >({
      query: ({ companyType, idCompany }) =>
        `/organizations/api/v1/organization/${idCompany}/${companyType.toLowerCase()}/contact`,
    }),

    addSigningBasis: builder.mutation<void, FormData>({
      query: (body) => ({
        url: `organizations/api/v1/signing-basis/file`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            adminUserApi.util.invalidateTags([
              {
                type: 'SigningBasis',
              },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    editSigningBasis: builder.mutation<void, EditSigningBasisBody>({
      query: ({ signingBasisId, body }) => ({
        url: `organizations/api/v1/signing-basis/${signingBasisId}/file`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            adminUserApi.util.invalidateTags([
              {
                type: 'SigningBasis',
              },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),
  }),
});

export const {
  useGetContactsQuery,
  useAddSigningBasisMutation,
  useEditSigningBasisMutation,
} = adminContactsApi;
