import {
  Box,
  ConfirmDialog,
  IconButton,
  OverflowTooltip,
  Stack,
  Typography,
} from 'src/components/ui';
import { DownloadArrow, TrashIcon } from 'src/assets/icons';
import { formatServerDate } from 'src/utils';
import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './file-item-preview.module.scss';
import { IFileItemProps } from '../../types';

export const FileItemPreview: FC<IFileItemProps> = ({
  file,
  onDownload,
  onRemove,
  canRemove,
  removeMode = 'default',
  removeConfirmText = '',
  showDownloadButton = true,
  disabledDeleteButton = false,
  disabledFileItem = false,
}) => {
  const isSoftRemove = removeMode === 'soft';
  const [fileUrl, setFileUrl] = useState('');
  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const isLoading = isDownloading || isRemoving;

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await onDownload?.(file);
    } catch {
      // ignore
    } finally {
      setIsDownloading(false);
    }
  };

  const handleRemove = async () => {
    try {
      setIsRemoving(true);
      await onRemove?.(file);
    } catch (e) {
      console.log(e);
    } finally {
      setIsRemoving(false);
    }
  };

  useEffect(() => {
    const url = file.file ? URL.createObjectURL(file.file) : '';
    setFileUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file.file]);

  return (
    <Stack
      className={classNames(
        styles.wrapper,
        disabledFileItem && styles.disabled
      )}>
      {!!fileUrl && (
        <div className={styles.img_wrapper}>
          <Box
            component="img"
            alt={file.name}
            src={fileUrl}
            className={styles.img}
          />
        </div>
      )}
      <div className={styles.info}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0.75}>
          <OverflowTooltip text={file.name} color="primary" />
          {!file.deleted && (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              {showDownloadButton && (
                <IconButton
                  aria-label="download"
                  title="Скачать"
                  disabled={isLoading}
                  onClick={handleDownload}>
                  <DownloadArrow />
                </IconButton>
              )}
              {(canRemove || isSoftRemove) && !disabledFileItem && (
                <IconButton
                  aria-label="delete"
                  title="Удалить"
                  disabled={isLoading || disabledDeleteButton}
                  onClick={() => setShowRemoveConfirm(true)}>
                  <TrashIcon />
                </IconButton>
              )}
            </Stack>
          )}
        </Stack>
        <Box mt={0.75}>
          <Typography color="text.secondary" variant="body2">
            {formatServerDate(file.createdAt, 'dd.MM.yyyy в HH:mm')}
          </Typography>
        </Box>
      </div>

      {showRemoveConfirm && (
        <ConfirmDialog
          open
          close={() => setShowRemoveConfirm(false)}
          title="Удалить файл?"
          confirmText="Да, удалить"
          onConfirm={() => {
            setShowRemoveConfirm(false);
            handleRemove();
          }}>
          {removeConfirmText}
        </ConfirmDialog>
      )}
    </Stack>
  );
};
