import { DIRECTION } from 'src/components/ui/filters/types';
import { TDocument } from 'src/models/document';

export interface ICommercialOfferStatus {
  id: number;
  statusName: string;
  description: string;
  code: CustomerCommercialOfferStatus;
  createdAt?: string;
}

export interface ICustomerCommercialOffer {
  commercialOfferId: number;
  businessApplicationId: number;
  number: number;
  cost: number;
  supplyDuration: number;
  processingDueDateAt?: string;
  dueDateAt: string;
  paymentConditions: string;
  comment: string;
  createdAt: string;
  status: ICommercialOfferStatus;
  receivedAt: string;

  documents: {
    COMMERCIAL_OFFER: TDocument[];
  };

  shippingIncluded: boolean;
}

export interface ICustomerCommercialOfferApproved {
  status: {
    code: CustomerCommercialOfferStatus;
    createdAt: string;
    displayName: string;
  };
}

export interface ICustomerCommercialOfferParams {
  commercialOfferId: number;
  businessApplicationId: number;
}

export interface ICustomerCommercialOfferDueDateParams {
  commercialOfferId: number;
  dueAt?: string;
  businessApplicationId: number;
}

export interface ICustomerRejectCommercialOfferReasons {
  totalCount: number;
  page: number;

  items: {
    id: number;
    name: string;
    status: string;
  }[];
}

export enum CustomerCommercialOfferStatus {
  RECEIVED = 'RECEIVED',
  REFUSED = 'REFUSED',
  APPROVED = 'APPROVED',
}

export interface ICustomerRejectCommercialOfferParams {
  commercialOfferId: number;
  businessApplicationId: number;

  causeName: string;
  comment: string;
}

export interface IRejectCommercialOfferReasonsParams {
  searchQueries: [];
  sort: {
    field: 'STATUS';
    order: DIRECTION;
  };
  paging: {
    page: number;
    limit: number;
  };
}

export enum CustomerCommercialOfferReworkReason {
  COMMERCIAL_OFFER_CONDITIONS_NOT_SATISFIED = 'COMMERCIAL_OFFER_CONDITIONS_NOT_SATISFIED',
  NEED_TO_CLARIFY = 'NEED_TO_CLARIFY',
}

export enum CommercialOfferReworkReason {
  TECHNICAL_REFINE = 'TECHNICAL_REFINE',
  DELAYED_RESPONSE = 'DELAYED_RESPONSE',
}

export enum CustomerCommercialOfferRejectReason {
  PRICE = 'PRICE',
  DELIVERY_DEADLINE = 'DELIVERY_DEADLINE',
  ALREADY_CONTRACTED = 'ALREADY_CONTRACTED',
  OTHER = 'OTHER',
}
