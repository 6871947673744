import { FC, useState } from 'react';
import { Typography, Box, Form, NumberInput, InfoRow } from 'src/components/ui';
import {
  maxLength,
  notNegativeNumber,
  positiveNumber,
  required,
} from 'src/utils/form-rules';
import { FIELD_LENGTH } from 'src/constants';
import { IChangeLogs, IChangeLogsHistory } from 'src/models/changelogs';
import IconButton from '@mui/material/IconButton';
import { History } from 'src/assets/icons';
import { ChangeLogsWindow } from 'src/components/widgets/change-logs-window';
import { IComponentsProp } from '../../types';

interface IDeadlinesProps extends IComponentsProp {
  supplyDurationHistory?: IChangeLogs;
  paymentPeriodProlongationHistory?: IChangeLogs;
}

export const Deadlines: FC<IDeadlinesProps> = ({
  viewMode,
  checkRequired,
  supplyDurationHistory,
  paymentPeriodProlongationHistory,
  mode,
}) => {
  const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);
  const [changeModalTitle, setChangeModalTitle] = useState<string>('');
  const [modalData, setModalData] = useState<IChangeLogsHistory[]>([]);
  const toggleChangeModalOpen = () => {
    setIsChangeModalOpen(!isChangeModalOpen);
  };

  return (
    <Box>
      <Typography variant="h3" mb="24px">
        Сроки
      </Typography>
      <Box display="flex" gap="24px">
        <Box flexBasis="100%">
          <Form.Item
            required
            label="Поставка, в к.д."
            viewMode={viewMode}
            name="supplyDuration"
            rules={{
              required: checkRequired ? required : undefined,
              maxLength: maxLength(FIELD_LENGTH.commercialOffer.supplyDuration),
              validate: {
                positiveNumber: positiveNumber(),
              },
            }}
            renderView={(value) => (
              <InfoRow
                label="Поставка, в к.д."
                value={
                  <>
                    <Typography fontSize="16px">{value}</Typography>
                    {mode === 'contractor' &&
                      !!supplyDurationHistory?.history.length && (
                        <IconButton
                          onClick={(_) => {
                            toggleChangeModalOpen();
                            setChangeModalTitle('Поставка');
                            setModalData(supplyDurationHistory.history);
                          }}>
                          <History />
                        </IconButton>
                      )}
                  </>
                }
              />
            )}>
            <NumberInput decimalScale={0} placeholder="0" />
          </Form.Item>
        </Box>

        <Box flexBasis="100%">
          <Form.Item
            required
            label="Отсрочка платежа, в к.д."
            viewMode={viewMode}
            name="paymentPeriodProlongation"
            rules={{
              maxLength: maxLength(
                FIELD_LENGTH.commercialOffer.paymentPeriodProlongation
              ),
              validate: {
                positiveNumber: notNegativeNumber(),
              },
            }}
            renderView={(value) => (
              <InfoRow
                label="Отсрочка платежа, в к.д."
                value={
                  <>
                    {value === null ? '0' : String(value)}
                    {mode === 'contractor' &&
                      !!paymentPeriodProlongationHistory?.history.length && (
                        <IconButton
                          onClick={(_) => {
                            toggleChangeModalOpen();
                            setChangeModalTitle('Отсрочка платежа');
                            setModalData(
                              paymentPeriodProlongationHistory.history
                            );
                          }}>
                          <History />
                        </IconButton>
                      )}
                  </>
                }
                noMargin
              />
            )}>
            <NumberInput decimalScale={0} placeholder="0" />
          </Form.Item>
        </Box>
      </Box>

      <ChangeLogsWindow
        onClose={toggleChangeModalOpen}
        changeHistory={modalData}
        title={changeModalTitle}
        open={isChangeModalOpen}
      />
    </Box>
  );
};
