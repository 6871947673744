import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useUpdateInvokedExpertRequestStatusMutation } from 'src/api/business-request/business-request';
import { useBusinessRequestContext } from 'src/components/layout/bids-layout/hooks';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  HintBar,
  OutlinedInput,
  Typography,
} from 'src/components/ui';
import { HelpExpertBarStage } from 'src/components/widgets/help-expert-bar';
import { FIELD_LENGTH } from 'src/constants';
import { InvokeExpertStage, InvokeExpertStatus } from 'src/models/bids-details';
import { maxLength } from 'src/utils/form-rules';

interface IRejectApprovalModal {
  open: boolean;
  onClose: VoidFunction;
  onCancel: VoidFunction;
  requestStage: InvokeExpertStage;
  commercialOfferId?: number;
  customerSpecificationId?: number;
  explicitStage?: HelpExpertBarStage;
}

interface IFormValues {
  comment: string;
}

export const RejectApprovalModal = ({
  open,
  onClose,
  onCancel,
  requestStage,
  commercialOfferId,
  customerSpecificationId,
  explicitStage,
}: IRejectApprovalModal) => {
  const form = useForm<IFormValues>({
    defaultValues: {
      comment: '',
    },
  });

  const { businessRequest } = useBusinessRequestContext();

  const [updateInvokedExpertRequestStatus, { isLoading }] =
    useUpdateInvokedExpertRequestStatusMutation();

  const handleSubmit = async (formValues: IFormValues) => {
    await updateInvokedExpertRequestStatus({
      requestId: businessRequest.businessRequestId,
      requestStatus: requestStage,
      approvalStatus: InvokeExpertStatus.REJECTED,
      note: formValues.comment,
      commercialOfferId,
      customerSpecificationId,
    });
    onCancel();
  };

  const rejectObject = (genitive?: boolean) => {
    if (
      requestStage === InvokeExpertStage.REQUEST_AGREEMENT ||
      requestStage === InvokeExpertStage.REQUEST_FORMULATION
    ) {
      if (genitive) {
        return 'запроса';
      }

      return 'Запрос';
    }

    if (
      requestStage === InvokeExpertStage.QUOTATION_AGREEMENT ||
      explicitStage === HelpExpertBarStage.COMMERCIAL_OFFER
    ) {
      if (genitive) {
        return 'коммерческого предложения';
      }

      return 'Коммерческое предложение';
    }

    if (genitive) {
      return 'спецификации';
    }

    return 'Спецификация';
  };

  const rejectLabelEnding = () => {
    const rejectObjectLabel = rejectObject();

    if (rejectObjectLabel === 'Запрос') {
      return 'согласован';
    }

    if (rejectObjectLabel === 'Коммерческое предложение') {
      return 'согласовано';
    }

    return 'согласована';
  };

  return (
    <Dialog
      sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: '600px' } }}
      open={open}
      onClose={onClose}
      close={onClose}>
      <DialogTitle>
        <Typography variant="h3" fontWeight="600">
          Подтвердите отклонение
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingBottom: '20px',
          ' & .Mui-error': {
            marginBottom: '5px',
          },
        }}>
        <Form
          form={form}
          loading={isLoading}
          onSubmit={handleSubmit}
          showCancelConfirm={false}
          onCancel={() => onClose()}
          footerStyle={{ justifyContent: 'flex-start' }}
          submitButtonProps={{ text: 'Подтвердить' }}>
          <Stack gap={2.5}>
            <HintBar>
              <Typography variant="body2">
                {`Укажите причину отклонения ${rejectObject(true)},` +
                  ` на ее основании будет создано сообщение в чат с ответственным КАМ по заявке для` +
                  ` дальнейшего обсуждения.`}
              </Typography>
            </HintBar>
            <Form.Item
              noMargin
              name="comment"
              label={`${rejectObject(
                false
              )} не ${rejectLabelEnding()} по причине`}
              rules={{
                required: true,
                maxLength: maxLength(FIELD_LENGTH.organizationName),
              }}>
              <OutlinedInput
                multiline
                minRows={3}
                maxRows={6}
                placeholder="Введите комментарий"
                maxLength={FIELD_LENGTH.organizationName}
              />
            </Form.Item>
          </Stack>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
