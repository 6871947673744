import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { IUserInfo } from 'src/models/auth';
import { baseQueryWithReauth } from 'src/store/utils';
import { ROLES } from 'src/constants';

export interface IEmailsFilter {
  id: number;
  value: string;
  emailReceiving: boolean;
  appCreation: boolean;
}

export interface IEmailsFilterWithKey extends IEmailsFilter {
  key: string | number;
}

export interface IEmailAccount {
  id: number;
  address: string;
  password: string;
  lastCollectionDate: string;
  creationDate: string;
  lastUpdateDate: string;
  type: string;
  signature: string;
  initialCollectionCompleted: boolean;
  collectingEmails: boolean;
  userInfo: IUserInfo;
}

export interface IAddEmailAccountParams {
  address: string;
  password: string;
  accountType: 'EMPLOYEE' | 'ACCEPTING_APPLICATIONS';
  signature?: string;
}

export interface IEditEmailAccountParams extends IAddEmailAccountParams {}

export interface IDeleteEmailAccountParams {
  address: string;
}

export interface INoAccountManager {
  login: string;
  firstName: string;
  lastName: string;
  middleName: string;
  role: ROLES[];
}

export const adminEmailsApi = createApi({
  reducerPath: 'emailsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Filters', 'Accounts'],
  endpoints: (builder) => ({
    getEmailsFiltersList: builder.query<IEmailsFilter[], void>({
      providesTags: ['Filters'],
      query: () => ({
        url: `aggregator/api/v1/email/filter/sender`,
      }),
    }),
    addEmailsFilter: builder.mutation<void, Omit<IEmailsFilter, 'id'>>({
      invalidatesTags: ['Filters'],
      query: (body) => ({
        url: `aggregator/api/v1/email/filter/sender`,
        method: 'POST',
        body,
      }),
    }),
    editEmailsFilter: builder.mutation<void, IEmailsFilter>({
      invalidatesTags: ['Filters'],
      query: ({ id, ...body }) => ({
        url: `aggregator/api/v1/email/filter/sender/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteEmailsFilter: builder.mutation<void, { id: number }>({
      invalidatesTags: ['Filters'],
      query: ({ id }) => ({
        url: `aggregator/api/v1/email/filter/sender/${id}`,
        method: 'DELETE',
      }),
    }),

    getEmailAccountList: builder.query<IEmailAccount[], void>({
      providesTags: ['Accounts'],
      query: () => 'email-gateway/api/v1/accounts/all',
    }),
    addEmailAccount: builder.mutation<void, IAddEmailAccountParams>({
      invalidatesTags: ['Accounts'],
      query: (body) => ({
        url: `email-gateway/api/v1/accounts`,
        method: 'POST',
        body,
      }),
    }),
    editEmailAccount: builder.mutation<void, IEditEmailAccountParams>({
      invalidatesTags: ['Accounts'],
      query: (body) => ({
        url: `email-gateway/api/v1/accounts`,
        method: 'PUT',
        body,
      }),
    }),
    deleteEmailAccount: builder.mutation<void, IDeleteEmailAccountParams>({
      invalidatesTags: ['Accounts'],
      query: ({ address }) => ({
        url: `email-gateway/api/v1/accounts/${address}`,
        method: 'DELETE',
      }),
    }),
    getNoAccountManagers: builder.query<INoAccountManager[], void>({
      query: () => 'users-service/api/v1/user/no-account',
    }),
  }),
});

export const {
  useGetEmailsFiltersListQuery,
  useAddEmailsFilterMutation,
  useEditEmailsFilterMutation,
  useDeleteEmailsFilterMutation,

  useGetEmailAccountListQuery,
  useAddEmailAccountMutation,
  useEditEmailAccountMutation,
  useDeleteEmailAccountMutation,
  useLazyGetNoAccountManagersQuery,
} = adminEmailsApi;
