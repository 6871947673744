import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    loading: true;
    inlineText: true;
    loadingText: true;
  }

  interface ButtonPropsColorOverrides {
    attention: true;
    white: true;
    blend: true;
  }
}

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    disableRipple: true,
    size: 'large',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      fontWeight: 500,
      width: 'fit-content',
      minWidth: 'auto',
      height: '44px',
      padding: '0 16px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.customTheme.darkBlueContentSecondary,
      },
      '&:disabled': {
        svg: {
          color: theme.palette.secondary.main,
        },
      },
      '& svg': {
        color: '#FFFFFF',
      },
      whiteSpace: 'nowrap',
    }),
    startIcon: {
      marginLeft: '0px',
    },
    iconSizeLarge: {
      '& svg': {
        width: '24px',
        height: '24px',
      },
      '& .old-svg-size svg': {
        width: '16px',
        height: '16px',
      },
    },
    iconSizeMedium: {
      '& svg': {
        width: '20px',
        height: '20px',
      },
      '& .old-svg-size svg': {
        width: '16px',
        height: '16px',
      },
    },
    iconSizeSmall: {
      '& svg': {
        width: '20px',
        height: '20px',
      },
      '& .old-svg-size svg': {
        width: '16px',
        height: '16px',
      },
    },
    fullWidth: {
      width: '100%',
    },
  },
  variants: [
    {
      props: { size: 'large' },
      style: () => ({
        padding: '0 16px',
        height: '44px',
      }),
    },

    {
      props: { size: 'medium' },
      style: () => ({
        padding: '10px 12px',
        height: '36px',
        fontSize: '13px',
        lineHeight: '14px',
      }),
    },

    {
      props: { size: 'small' },
      style: () => ({
        padding: '10px 8px',
        height: '28px',
        fontSize: '12px',
        lineHeight: '14px',
      }),
    },

    {
      props: { variant: 'text' },
      style: ({ theme }) => ({
        userSelect: 'auto',
        padding: 0,
        height: 'fit-content',
        fontWeight: 400,
        backgroundColor: 'transparent',
        '& svg': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          '& svg': {
            color: theme.palette.primary.dark,
          },
        },
        '&:hover:active': {
          backgroundColor: 'transparent',
          color: `${theme.palette.customTheme.darkBlueContentSecondary}`,
          '& svg': {
            color: theme.palette.customTheme.darkBlueContentSecondary,
          },
        },
        '&:active': {
          backgroundColor: 'transparent',
          color: `${theme.palette.customTheme.darkBlueContentSecondary}`,
          '& svg': {
            color: theme.palette.customTheme.darkBlueContentSecondary,
          },
        },
        '& .MuiButton-startIcon': {
          marginLeft: 0,
        },
      }),
    },

    {
      props: { variant: 'text', color: 'primary' },
      style: ({ theme }) => ({
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      }),
    },

    {
      props: { variant: 'inlineText' },
      style: ({ theme }) => ({
        padding: 0,
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        display: 'inline',
        verticalAlign: 'initial',
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.dark,
        },
      }),
    },

    {
      props: { variant: 'loadingText' },
      style: ({ theme }) => ({
        color: theme.palette.secondary.contrastText,
        padding: 0,
        pointerEvents: 'none',
        cursor: 'default',
      }),
    },

    {
      props: { variant: 'loading' },
      style: ({ theme }) => ({
        color: theme.palette.secondary.contrastText,
        backgroundColor: 'rgba(0, 47, 108, 0.06)',
        pointerEvents: 'none',
        cursor: 'default',
      }),
    },

    {
      props: { variant: 'outlined' },
      style: {
        '&:disabled': {
          backgroundColor: 'rgba(0, 47, 108, 0.06)',
          path: {
            stroke: '#7A8694',
          },
        },
      },
    },

    {
      props: { fullWidth: true, color: 'primary' },
      style: {
        fontSize: '16px',
        padding: '0 16px',
      },
    },

    {
      props: { color: 'white', variant: 'contained' },
      style: ({ theme }) => ({
        backgroundColor: '#FFFFFF',
        color: theme.palette.text.primary,
        '& svg': {
          color: theme.palette.text.primary,
        },
        '&:hover': {
          backgroundColor: '#E3E8EF',
        },
        '&:active': {
          backgroundColor: '#D6DEE7',
        },
      }),
    },

    {
      props: { color: 'blend', variant: 'contained' },
      style: () => ({
        color: '#FFFFFF',
        backgroundColor: 'transparent',
        '& svg': {
          color: '#FFFFFF',
        },
        '&:hover': {
          backgroundColor: '#FFFFFF1A',
        },
        '&:active': {
          backgroundColor: '#FFFFFF33',
        },
        '&:disabled': {
          color: '#FFFFFF80',
          '& svg': {
            color: '#FFFFFF80',
          },
        },
      }),
    },

    {
      props: { color: 'secondary', variant: 'contained' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.primary.light,
        '&:hover': {
          backgroundColor: '#E3E8EF',
        },
        '&:active': {
          backgroundColor: '#D6DEE7',
        },
        '& .MuiButton-startIcon': {
          marginLeft: 0,
        },
        '& svg': {
          color: theme.palette.text.primary,
        },
      }),
    },

    {
      props: { color: 'error', variant: 'contained' },
      style: () => ({
        color: '#FFFFFF',
        backgroundColor: '#E11111',
        '&:hover': {
          backgroundColor: '#BF1D26',
        },
        '&:active': {
          backgroundColor: '#81080F',
        },
      }),
    },

    {
      props: { color: 'error', variant: 'text' },
      style: () => ({
        color: '#E11111',
        svg: {
          color: '#E11111',
        },
        '&:hover': {
          color: '#BF1D26',
        },
        '&:active': {
          color: '#BF1D26',
        },
      }),
    },

    {
      props: { color: 'success', variant: 'contained' },
      style: () => ({
        color: '#FFFFFF',
        backgroundColor: '#1DBF61',
        '&:hover': {
          backgroundColor: '#27AE60',
        },
        '&:active': {
          backgroundColor: '#08823B',
        },
      }),
    },

    {
      props: { color: 'success', variant: 'text' },
      style: () => ({
        color: '#1DBF61',
        svg: {
          color: '#1DBF61',
        },
        '&:hover': {
          color: '#27AE60',
        },
        '&:active': {
          color: '#08823B',
        },
      }),
    },

    {
      props: { color: 'warning', variant: 'contained' },
      style: () => ({
        color: '#FFFFFF',
        backgroundColor: '#E79600',
        '&:hover': {
          backgroundColor: '#BF861D',
        },
        '&:active': {
          backgroundColor: '#825708',
        },
      }),
    },

    {
      props: { color: 'warning', variant: 'text' },
      style: () => ({
        color: '#E79600',
        svg: {
          color: '#E79600',
        },
        '&:hover': {
          color: '#BF861D',
        },
        '&:active': {
          color: '#825708',
        },
      }),
    },

    // Todo: выпилить attention
    {
      props: { color: 'attention', variant: 'contained' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      }),
    },
  ],
};
