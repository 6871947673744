import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ManagerCommercialOffers = lazy(
  () =>
    import(
      /* webpackChunkName: 'ManagerCommercialOffers' */
      /* webpackMode: "lazy" */
      `./commercial-offers`
    )
);

export const LazyManagerCommercialOffers: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ManagerCommercialOffers />
  </Suspense>
);
