import { FinanceInfoEntry } from '../types/finance-info-entry';
import { getFinanceValuesOrder } from './utils';

export const useLineChart = (financeEntries: FinanceInfoEntry[]) => {
  const MIN_DISPLAYED_YEAR = 2013;

  if (!financeEntries.length) {
    return {
      ticks: [],
      label: '',
      years: [],
      minYear: 0,
      roundedValues: [],
    };
  }

  const maxValue = Math.abs(
    financeEntries.reduce((max, item) =>
      Math.abs(item.value) > Math.abs(max.value) ? item : max
    ).value
  );

  const [divider, label] = getFinanceValuesOrder(maxValue);

  const years = financeEntries.map((financeEntry) => financeEntry.year);
  const minYear = years[0] > MIN_DISPLAYED_YEAR ? years[0] : MIN_DISPLAYED_YEAR;

  const roundedValues = financeEntries.map((item) => ({
    ...item,
    value: item.value / divider,
  }));

  return {
    label,
    years,
    minYear,
    roundedValues,
  };
};
