export enum ROLES {
  CONTRACTOR = 'CONTRACTOR',
  CUSTOMER = 'CUSTOMER',
  KAM = 'KAM',
  KAM_PLUS = 'KAM_PLUS',
  ADMIN = 'ADMIN',
  SYSTEM = 'SYSTEM',

  // Specific roles not fully done
  TECH_EXPERT = 'TECH_EXPERT',
  BACK_OFFICE = 'BACK_OFFICE',
  COMMERCIAL_DIRECTOR = 'COMMERCIAL_DIRECTOR',
  LOGISTIC = 'LOGISTIC',
  PARTNER_RELATIONS_MANAGER = 'PARTNER_RELATIONS_MANAGER',

  GENERAL_MANAGER = 'GENERAL_MANAGER',
  FINANCIAL_MANAGER = 'FINANCIAL_MANAGER',
  OFFICE_MANAGER = 'OFFICE_MANAGER',
  LAWYER = 'LAWYER',
}
