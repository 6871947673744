import { IContractorRequest, IManagerBusinessRequest } from 'src/models';
import { FC } from 'react';
import { formatServerDate } from 'src/utils/date-format';
import { Typography } from 'src/components/ui';
import styles from './rebidding-info.module.scss';

interface IRebiddingInfoProps {
  businessRequest: IManagerBusinessRequest | IContractorRequest;
}

export const RebiddingInfo: FC<IRebiddingInfoProps> = ({ businessRequest }) => {
  const { rebidding, refusal, status } = businessRequest;
  const { exclusion } = businessRequest as IContractorRequest;

  if (
    !rebidding ||
    !rebidding.rebiddingInfo ||
    !rebidding.rebiddingInfo.endAt ||
    refusal ||
    exclusion ||
    status.code !== 'REBIDDING_IN_PROGRESS'
  ) {
    return null;
  }

  if (new Date() > new Date(rebidding.rebiddingInfo.endAt)) {
    return null;
  }

  return (
    <div className={styles.chipBlue}>
      <Typography variant="body2" fontSize="10px">
        Идет переторжка до&nbsp;
        {formatServerDate(
          new Date(rebidding.rebiddingInfo.endAt),
          'dd.MM.yyyy'
        )}
      </Typography>
    </div>
  );
};
