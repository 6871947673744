import { FC, useEffect, useState, useMemo, useRef } from 'react';
import { TRequestNotification } from 'src/models/notifications';
import { Bell, DropdownArrow } from 'src/assets/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  OverflowTooltip,
  Typography,
} from 'src/components/ui';
import {
  fillingNotificationTemplate,
  getNotificationDate,
} from 'src/components/pages/notifications/utils';
import classNames from 'classnames';
import { IntersectionOptions, useInView } from 'react-intersection-observer';
import { SvgIcon } from '../svg-icon';
import styles from './collapsible-notifications.module.scss';
import { Card } from '../card';

const WatchCircle: FC<{ watched: boolean }> = ({ watched }) => (
  <div className={watched ? styles.readCircle : styles.unreadCircle} />
);

type THeaderActionsProps = {
  notification: TRequestNotification;
};

const HeaderActions: FC<THeaderActionsProps> = ({ notification }) => (
  <div className={styles.headerWrapper}>
    <div className={styles.headerIcon}>
      <SvgIcon
        icon={Bell}
        backgroundFill="#0041A0"
        strokeHexColor="#CDE3FF"
        width="16px"
        height="16px"
        iconPadding="8px"
      />
      <Typography
        className={classNames(
          styles.cardTitle,
          !notification.watched && styles.fontWeight500
        )}>
        {notification.title ?? notification.titleTemplate}
      </Typography>
    </div>
    <div className={styles.dateWrapper}>
      <Typography variant="body2" className={styles.cardDate}>
        {getNotificationDate(notification.createdAt, false)}
      </Typography>
      <WatchCircle watched={notification.watched} />
    </div>
    <div className={classNames(styles.iconWrapper)}>
      <SvgIcon
        strokeWidth="0.1"
        fillHexColor="#7A8694"
        strokeHexColor="#7A8694"
        height="20px"
        width="20px"
        icon={DropdownArrow}
      />
    </div>
  </div>
);

type TNotificationCard = {
  notification: TRequestNotification;
  showArrow?: boolean;
  onWatchNotification: (notification: TRequestNotification) => void;
  onArrowClick?: React.MouseEventHandler<HTMLButtonElement>;
  onCardClick?: (item: TRequestNotification) => void;
};

const defaultIntersectionOptions: IntersectionOptions = {
  threshold: 0.4,
  triggerOnce: true,
};

const NotificationCard: FC<TNotificationCard> = ({
  notification,
  showArrow = false,
  onArrowClick,
  onWatchNotification,
  onCardClick,
}) => {
  const { ref, inView } = useInView({
    ...defaultIntersectionOptions,
  });

  useEffect(() => {
    if (inView) {
      onWatchNotification(notification);
    }
  }, [inView]);

  const handleArrowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onArrowClick?.(e);
  };

  const handleCardClick = () => {
    onCardClick?.(notification);
  };

  return (
    <div
      onClick={handleCardClick}
      ref={ref}
      className={classNames(
        styles.notificationWrapper,
        !notification.watched && styles.backgroundGray,
        onCardClick && styles.hover
      )}>
      <div className={styles.notificationContent}>
        <div className={styles.mainData}>
          <div className={styles.titleWrapper}>
            <Typography
              className={classNames(
                styles.title,
                !notification.watched && styles.fontWeight500
              )}>
              {notification.title ?? notification.titleTemplate}
            </Typography>
            <div className={styles.dateWrapper}>
              <Typography variant="body2" className={styles.cardDate}>
                {getNotificationDate(notification.createdAt, false)}
              </Typography>
              <WatchCircle watched={notification.watched} />
            </div>
            <IconButton
              onClick={handleArrowClick}
              className={classNames(
                styles.iconWrapper,
                styles.iconExpanded,
                !showArrow && styles.hidden
              )}>
              <SvgIcon
                strokeWidth="0.1"
                fillHexColor="#7A8694"
                strokeHexColor="#7A8694"
                height="20px"
                width="20px"
                icon={DropdownArrow}
              />
            </IconButton>
          </div>
          <OverflowTooltip
            isVerticalTooltip
            className={styles.notificationBody}
            text={
              <div className={styles.notificationBodyTooltip}>
                {fillingNotificationTemplate({
                  params: notification.params,
                  template: notification?.bodyTemplate,
                  attributes: notification.attributes,
                })}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

type TCollapsibleNotificationsProps = {
  notifications: TRequestNotification[];
  onWatchNotification: (notification: TRequestNotification) => void;
  onNotificationClick?: (item: TRequestNotification) => void;
};

const CARD_HEIGHT = 100;

export const CollapsibleNotifications: FC<TCollapsibleNotificationsProps> = ({
  notifications,
  onWatchNotification,
  onNotificationClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded((prev) => !prev);

  const hasUnreadNotifications = notifications.some((elem) => !elem.watched);

  const lastUnread = useMemo(
    () => notifications.find((elem) => !elem.watched) ?? notifications[0],
    [notifications]
  );

  const watchedNotificationsNumber = useMemo(() => {
    const watchedNotifications = notifications.filter((elem) => elem.watched);
    return watchedNotifications.length === notifications.length
      ? 0
      : watchedNotifications.length;
  }, [notifications]);

  const blockRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (expanded) {
      const scrollCount = watchedNotificationsNumber * CARD_HEIGHT;
      if (blockRef.current && scrollCount) {
        // setTimeout используется, чтобы Accordion успел развернуться в DOM дереве
        setTimeout(() => {
          blockRef.current?.scrollBy({
            top: scrollCount,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  }, [expanded]);

  return (
    <Card noContentPadding>
      <Accordion
        expanded={expanded}
        className={styles.cardWrapper}
        onChange={!expanded ? toggleExpanded : undefined}
        ref={blockRef}>
        <AccordionSummary
          sx={{
            padding: 0,
            backgroundColor:
              !expanded && hasUnreadNotifications ? '#CDE3FF' : undefined,
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: 0,
              width: '100%',
            },
            '& .MuiAccordionSummary-content': {
              margin: 0,
              width: '100%',
            },
          }}>
          <div
            className={classNames(
              styles.summaryWrapper,
              !expanded && styles.paddingWrapped
            )}>
            {expanded ? (
              <NotificationCard
                onWatchNotification={onWatchNotification}
                showArrow
                notification={notifications[0]}
                onArrowClick={toggleExpanded}
                onCardClick={onNotificationClick}
              />
            ) : (
              <HeaderActions notification={lastUnread} />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.notificationList}>
          {notifications.slice(1).map((notification, index) => (
            <NotificationCard
              onCardClick={onNotificationClick}
              onWatchNotification={onWatchNotification}
              notification={notification}
              key={index}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
