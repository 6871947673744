import styles from './communication-date-panel.module.scss';

interface ICommunicationDatePanelProps {
  date: string;
}

export const CommunicationDatePanel = ({
  date,
}: ICommunicationDatePanelProps) => (
  <span className={styles.wrapper}>{date}</span>
);
