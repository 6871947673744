import { TDocument } from '../document';

export interface IManagerShipping {
  shipmentGroupId: number;
  comment: string | null;

  businessApplicationId: number;
  contractorRequestId: number;
  customerOrganizationId: number;
  contractorOrganizationId: number;
  cost: number | null;
  shippingCost: number | null;

  shipments: {
    shipmentId: number;
    shippingDetailId: number;
    deliveryDetailId: number;
    correlationId: number | undefined;

    shippingDate: string | null;
    deliveryDate: string | null;
    createdAt: string | null;

    cargoCost: number;
    shippingCost: number;

    status: {
      code: ShipmentStatus;
      displayName: string;
    };

    documents: {
      SHIPPING_ACK: TDocument[];
      DELIVERY_ACK: TDocument[];
    };
  }[];
}

export interface IManagerUpdateShippingParams {
  shipmentGroupId: number;

  shipments: {
    shipmentId?: number;
    shippingDetailId: number;
    deliveryDetailId: number;

    shippingDate: string | null;
    deliveryDate: string | null;

    cargoCost: number;
    shippingCost: number;

    status: ShipmentStatus;
  }[];
}

export enum ShipmentStatus {
  NEW_SHIPMENT = 'NEW_SHIPMENT',
  IN_PRODUCTION = 'IN_PRODUCTION',
  READY_FOR_SENDING = 'READY_FOR_SENDING',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  CLOSED = 'CLOSED',
}

export const ShipmentStatusName = {
  [ShipmentStatus.NEW_SHIPMENT]: 'Новая отгрузка',
  [ShipmentStatus.IN_PRODUCTION]: 'В производстве',
  [ShipmentStatus.READY_FOR_SENDING]: 'Готов к отгрузке',
  [ShipmentStatus.SENT]: 'Отгружено',
  [ShipmentStatus.DELIVERED]: 'Доставлено',
  [ShipmentStatus.CLOSED]: 'Закрыто',
};

export const shipmentStatusList = [
  {
    status: ShipmentStatus.NEW_SHIPMENT,
    managerDisabled: false,
    contractorDisabled: true,
    customerDisabled: true,
  },
  {
    status: ShipmentStatus.IN_PRODUCTION,
    managerDisabled: false,
    contractorDisabled: false,
    customerDisabled: true,
  },
  {
    status: ShipmentStatus.READY_FOR_SENDING,
    managerDisabled: false,
    contractorDisabled: false,
    customerDisabled: true,
  },
  {
    status: ShipmentStatus.SENT,
    managerDisabled: false,
    contractorDisabled: false,
    customerDisabled: true,
  },
  {
    status: ShipmentStatus.DELIVERED,
    managerDisabled: false,
    contractorDisabled: true,
    customerDisabled: false,
  },
  {
    status: ShipmentStatus.CLOSED,
    managerDisabled: false,
    contractorDisabled: true,
    customerDisabled: true,
  },
];
