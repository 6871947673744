import {
  IContractorAuction,
  IContractorAuctionListBid,
  IContractorCommercialOffer,
  IContractorCreateAuctionBidParams,
  IContractorCreateAuctionBidRes,
  IContractorRejectAuction,
} from 'src/models';
import { businessRequestApi } from '../business-request';

export const contractorAuctionApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getContractorAuction: builder.query<IContractorAuction[], number>({
      query: (contractorRequestId) => ({
        url: 'business-requests/api/v1/contractor/auction-contractor-request',
        method: 'GET',
        params: { contractorRequestId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getContractorAuction', id: arg },
      ],
    }),
    createContractorAuctionBid: builder.mutation<
      IContractorCreateAuctionBidRes,
      IContractorCreateAuctionBidParams & { auctionContractorRequestId: number }
    >({
      query: ({ auctionContractorRequestId, ...body }) => ({
        url: `business-requests/api/v1/contractor/auction-contractor-request/${auctionContractorRequestId}/bid`,
        method: 'POST',
        body,
      }),
    }),

    acceptContractorAuction: builder.mutation<void, number>({
      query: (auctionContractorRequestId) => ({
        url: `business-requests/api/v1/contractor/auction-contractor-request/${auctionContractorRequestId}/accept`,
        method: 'PATCH',
      }),
    }),

    rejectContractorAuction: builder.mutation<void, IContractorRejectAuction>({
      query: ({ auctionContractorRequestId, cause }) => ({
        url: 'business-requests/api/v1/contractor/refusal/auction-contractor-request',
        method: 'POST',
        body: {
          auctionContractorRequestId,
          cause,
        },
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getContractorStatusHistory',
                id: arg.contractorRequestId,
              },
            ],
    }),

    getContractorAuctionListBid: builder.query<
      IContractorAuctionListBid,
      { auctionId: number; bestCostOnly: boolean }
    >({
      query: ({ auctionId, bestCostOnly }) => ({
        url: `business-requests/api/v1/contractor/auction/${auctionId}/bid`,
        method: 'GET',
        params: { bestCostOnly },
      }),
      providesTags: ['getContractorAuctionListBid'],
      transformResponse: (data: IContractorAuctionListBid) => {
        const indexMyOrganization =
          data?.bids.findIndex(
            (element) => element.organization.organizationName
          ) || 0;

        return {
          ...data,
          bids: data.bids.map((element, index) => {
            const bidsLength = data.bids.length - 1;
            const currentIndex =
              index >= indexMyOrganization ? index - 1 : index;

            return {
              ...element,
              organization: {
                ...element.organization,
                organizationName: element.organization.organizationName
                  ? element.organization.organizationName
                  : `Участник ${bidsLength - currentIndex}`,
              },
            };
          }),
        };
      },
    }),

    getCommercialOfferByBidId: builder.query<
      { commercialOffer: IContractorCommercialOffer | null },
      number
    >({
      query: (bidId) => ({
        url: `business-requests/api/v1/contractor/auction/bid/${bidId}/commercial-offer`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetContractorAuctionQuery,
  useCreateContractorAuctionBidMutation,
  useAcceptContractorAuctionMutation,
  useRejectContractorAuctionMutation,
  useGetContractorAuctionListBidQuery,
  useLazyGetCommercialOfferByBidIdQuery,
} = contractorAuctionApi;
