import { plural } from 'src/utils/plural';

export const maxLength = (value: number) => ({
  value,
  message: `Не больше ${plural(
    value,
    '$d символа',
    '$d символов',
    '$d символов'
  )}`,
});
