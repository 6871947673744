import { RouteObject } from 'react-router-dom';
import { ReactNode } from 'react';
import { TBusinessRequestRouteConfig } from './business-request-paths-by-type';

export const getAvailableConfigItems = (config: TBusinessRequestRouteConfig) =>
  config.reduce(
    (
      acc: {
        routeItems: RouteObject[];
        menuItems: Array<{ path: string; title: ReactNode }>;
      },
      item
    ) => {
      const { title, ...configItem } = item;

      if (configItem.children) {
        const availableChildItems = getAvailableConfigItems(
          configItem.children
        );

        acc.routeItems.push({
          ...configItem,
          children: availableChildItems.routeItems,
        });
      } else {
        acc.routeItems.push(configItem);
      }

      if (title) {
        acc.menuItems.push({
          title,
          path: configItem.path ?? '',
        });
      }

      return acc;
    },
    { routeItems: [], menuItems: [] }
  );
