import React, { FC, useState } from 'react';
import {
  IconButton,
  SvgIcon,
  Typography,
  Button,
  OutlinedInput,
  OverflowTooltip,
} from 'src/components/ui';
import { CheckedIcon, Cross, Pencil } from 'src/assets/icons';
import { Stack } from '@mui/material';
import styles from './company-comment.module.scss';

interface ICompanyCommentProps {
  label: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

const MAX_COMMENT_LENGTH = 256;

export const CompanyComment: FC<ICompanyCommentProps> = ({
  label,
  placeholder,
  value,
  onChange,
  isDisabled,
}) => {
  const [commentValue, setCommentValue] = useState(value);

  const handleChangeCommentValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCommentValue(event.target.value);
  };

  const [isEditing, setIsEditing] = useState(false);

  const handleChangeComment = () => {
    onChange(commentValue);
    setIsEditing(false);
  };

  const handleCloseCommentEdit = () => {
    setCommentValue(value || '');
    setIsEditing(false);
  };

  return (
    <div className={styles.wrapper}>
      {!isEditing ? (
        <>
          <Typography fontSize="12px" fontWeight="500" color="#292929">
            {`${label}:`}
          </Typography>
          <OverflowTooltip
            text={value || 'Не указан'}
            fontSize="12px"
            color="#7A8694"
            isVerticalTooltip
            className={styles.text}
          />
          {!isDisabled && (
            <IconButton
              className={styles.button}
              onClick={() => setIsEditing(true)}>
              <Pencil />
            </IconButton>
          )}
        </>
      ) : (
        <>
          <OutlinedInput
            placeholder={placeholder}
            multiline
            rows={4}
            maxLength={MAX_COMMENT_LENGTH}
            fullWidth
            sx={{ backgroundColor: '#FFFFFF' }}
            value={commentValue}
            onChange={handleChangeCommentValue}
          />
          <Stack direction="row" gap="20px" mt="4px">
            <Button
              startIcon={
                <SvgIcon
                  icon={CheckedIcon}
                  width="16px"
                  height="16px"
                  strokeColor="primary.main"
                  strokeWidth="2"
                />
              }
              variant="text"
              color="primary"
              onClick={handleChangeComment}
              disabled={commentValue.length > MAX_COMMENT_LENGTH}>
              Сохранить
            </Button>
            <Button
              startIcon={
                <SvgIcon
                  icon={Cross}
                  width="16px"
                  height="16px"
                  strokeColor="#7A8694"
                  strokeWidth="1"
                />
              }
              variant="text"
              sx={{ color: '#7A8694' }}
              onClick={handleCloseCommentEdit}>
              Отменить
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};
