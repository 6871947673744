import { TNotification } from 'src/models/notifications';

export type TGroupedNotifications = {
  dateString: string;
  notifications: TNotification[];
};

export const groupMessages = (
  notifications: TNotification[]
): TGroupedNotifications[] => {
  const groupedNotifications: Record<string, TGroupedNotifications> = {};

  notifications.forEach((notification) => {
    const createdAt = new Date(notification.createdAt);
    const dateString = createdAt.toISOString().split('T')[0];

    if (!groupedNotifications[dateString]) {
      groupedNotifications[dateString] = {
        dateString,
        notifications: [],
      };
    }

    groupedNotifications[dateString as string].notifications.push(notification);
  });

  return Object.values(groupedNotifications);
};
