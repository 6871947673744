import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const TechnicalOpportunities = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminTechnicalOpportunities' */
      /* webpackMode: "lazy" */
      `./technical-opportunities`
    )
);

export const LazyTechnicalOpportunities: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <TechnicalOpportunities />
  </Suspense>
);
