import { joinFullName } from 'src/utils/join-full-name';

export const joinName = (
  lastName?: string,
  firstName?: string,
  middleName?: string
) => {
  const shortNameParts = [];
  const wrapName = [];

  if (lastName) {
    shortNameParts.push(lastName);
    wrapName.push(lastName);
  }

  if (firstName) {
    shortNameParts.push(`${firstName[0]}.`);
  }

  if (middleName) {
    shortNameParts.push(`${middleName[0]}.`);
    wrapName.push(`${firstName} ${middleName}`);
  } else {
    wrapName.push(firstName);
  }

  return {
    fullName: joinFullName(lastName, firstName, middleName),
    shortName: shortNameParts.join(' '),
    wrapName: wrapName.join('\n'),
  };
};
