import { useQueryParams, StringParam, ArrayParam } from 'use-query-params';

export const useCommunicationsQueryParams = () => {
  const params = useQueryParams({
    field: StringParam,
    direction: StringParam,
    logins: ArrayParam,
    organizationRole: StringParam,
    relationFilterType: StringParam,
  });

  return params;
};
