import { TNotification, TRequestNotification } from 'src/models/notifications';
import { interpolate } from '../interpolate';

export const fillNotificationTemplate = <
  T extends TNotification | TRequestNotification
>(
  notification: T
): T => {
  const { bodyTemplate, titleTemplate, params } = notification;
  return {
    ...notification,
    body: interpolate(bodyTemplate, params, '$'),
    title: interpolate(titleTemplate, params, '$'),
  };
};
