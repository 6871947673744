import { Typography } from 'src/components/ui';
import { IStatus } from 'src/models/communications';
import { Status as StatusIcon } from 'src/assets/icons';
import { format } from 'date-fns';
import { BubbleItem } from '../bubble-item';

interface IStatusProps {
  data: IStatus;
  onClick?: () => void;
}

export const Status = ({ data, onClick }: IStatusProps) => {
  const deadlineDate = format(new Date(data.deadlineDate), 'dd.MM.yyyy HH:mm');

  return (
    <BubbleItem
      redirectUrl={`/requests/${data.application.requestId}`}
      date={data.sortDate}
      avatarIcon={StatusIcon}
      title={
        <>
          <Typography display="inline" color="#292929" fontWeight="500">
            Необходимо обновить статус по заявке{' '}
          </Typography>
          <Typography
            display="inline"
            color="#292929"
            fontWeight="500"
            whiteSpace="nowrap">
            № {data.application.id}{' '}
          </Typography>
          <Typography display="inline" color="#292929" fontWeight="500">
            до {deadlineDate}
          </Typography>
        </>
      }
      onClick={onClick}
    />
  );
};
