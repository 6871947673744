import { FC } from 'react';
import { Box } from 'src/components/ui';
import {
  useGetAllCommunicationsChainQuery,
  useGetCallChainQuery,
  useGetEmailChainByApplicationIdQuery,
} from 'src/api/communications';
import { TImplementedMessengerTypes } from 'src/api/communications/models';
import {
  IEmailMessage,
  INote,
  ITelephonyCall,
  IMessage,
  IStatus,
} from 'src/models/communications';
import { CommunicationListItem } from 'src/components/widgets/communication-chat/components/communication-list/components';

interface ICommunicationListProps {
  applicationId: string;
  messengerType: TImplementedMessengerTypes;
}

export const CommunicationList: FC<ICommunicationListProps> = ({
  applicationId,
  messengerType,
}) => {
  const { data: allCommunicationsChainData } =
    useGetAllCommunicationsChainQuery(applicationId.toString(), {
      pollingInterval: 2000,
      skip: !applicationId || messengerType !== 'ALL',
    });

  const { data: callsChainData } = useGetCallChainQuery(
    applicationId.toString(),
    {
      pollingInterval: 2000,
      skip: !applicationId || messengerType !== 'TELEPHONY',
    }
  );

  const { data: emailsChainData } = useGetEmailChainByApplicationIdQuery(
    applicationId.toString(),
    {
      pollingInterval: 2000,
      skip: !applicationId || messengerType !== 'EMAIL',
    }
  );

  let items: Array<
    IEmailMessage | ITelephonyCall | INote | IMessage | IStatus
  > = [];

  switch (messengerType) {
    case 'ALL':
      items =
        allCommunicationsChainData?.communications?.filter((item) =>
          ['EMAIL', 'TELEPHONY', 'MESSAGE'].includes(item.communicationType)
        ) || [];
      break;
    case 'EMAIL':
      items = emailsChainData || [];
      break;
    case 'TELEPHONY':
      items = callsChainData || [];
      break;
    default:
      break;
  }

  if (!items?.length) {
    return <Box>Нет сообщений</Box>;
  }

  return (
    <Box>
      {items.map((item) => (
        <CommunicationListItem item={item} key={item.id} />
      ))}
    </Box>
  );
};
