export const resetDateTime = (
  date: string | undefined | null,
  addOneDay?: boolean
): string => {
  if (!date) {
    return '';
  }
  const fixedDate = new Date(date);
  fixedDate.setHours(0, 0, 0, 0);
  if (addOneDay) {
    fixedDate.setHours(23, 59, 59);
  }
  return fixedDate.toISOString();
};
