import { Skeleton, Stack, Theme, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import { AddTableItemIcon } from 'src/assets/icons';
import { Button } from 'src/components/ui';
import { NewFilters } from 'src/components/ui/new-filters';
import { FiltersProps } from 'src/components/ui/new-filters/types';
import styles from './page-header.module.scss';

interface PageHeaderProps<
  KeysSorting,
  AutocompleteItemType = {},
  FilterKeys extends string = string
> extends FiltersProps<KeysSorting, AutocompleteItemType, FilterKeys> {
  title: string;
  totalCountLabel: string;
  totalCount: number;
  isFiltersLoading?: boolean;
  addButtonLabel?: string;
  addButtonLoading?: boolean;
  onAddButtonClick?: () => void;
  wrapperClassName?: string;
  compactVersion?: boolean;
}

export const PageHeader = <
  KeysSorting,
  AutocompleteItemType = {},
  FilterKeys extends string = string
>({
  title,
  totalCountLabel,
  totalCount,
  isFiltersLoading,
  addButtonLabel,
  addButtonLoading,
  onAddButtonClick,
  wrapperClassName,
  compactVersion,
  ...filterProps
}: PageHeaderProps<KeysSorting, AutocompleteItemType, FilterKeys>) => {
  const showCompactButton = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      flex={1}
      sx={{ padding: compactVersion ? '16px 20px !important' : undefined }}
      className={classNames(styles.wrapper, wrapperClassName)}>
      <Stack direction="row" alignItems="center" gap="24px" flex={1}>
        <Stack direction="column" gap="4px" className={styles.headerTitle}>
          <Typography fontWeight={500} fontSize="20px" lineHeight="24px">
            {title}
          </Typography>
          <Typography
            fontWeight={500}
            fontSize="12px"
            lineHeight="12px"
            color="text.secondary">
            {totalCountLabel}: {totalCount}
          </Typography>
        </Stack>
        <Stack direction="row" gap="8px" alignItems="center" flex={1}>
          {isFiltersLoading ? (
            <Skeleton height="45px" width="100%" />
          ) : (
            <NewFilters {...filterProps} />
          )}
          {addButtonLabel && onAddButtonClick && !showCompactButton && (
            <Button
              applyNewIconSizing
              startIcon={<AddTableItemIcon />}
              loading={addButtonLoading}
              onClick={onAddButtonClick}>
              {addButtonLabel}
            </Button>
          )}
          {addButtonLabel && onAddButtonClick && showCompactButton && (
            <IconButton size="large" color="primary" onClick={onAddButtonClick}>
              <AddTableItemIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
