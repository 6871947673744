import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/utils';
import { IBubbleNotificationListResult } from 'src/api/communications/models';

export const communicationNotificationsApi = createApi({
  reducerPath: 'communicationNotificationsApi',
  baseQuery,
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    getBubbleNotificationList: builder.query<
      IBubbleNotificationListResult,
      void
    >({
      query: () => `aggregator/api/v1/communication/bubble`,
      providesTags: ['Notifications'],
    }),
  }),
});

export const { useGetBubbleNotificationListQuery } =
  communicationNotificationsApi;
