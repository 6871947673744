import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      borderRadius: '8px',
      marginRight: '8px',
      ...(ownerState.color === 'error' && {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.dark,
        border: '1px solid #FF9393',
      }),
      ...(ownerState.color === 'success' && {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.customTheme.textGreen,
        border: '1px solid #79CA7D',
      }),
    }),
    avatar: {
      width: 'unset',
      height: 'unset',
    },
    label: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    filledSecondary: ({ theme }) => ({
      border: '1px solid',
      borderColor: theme.palette.customTheme.borderGrayPrimary,
      backgroundColor: theme.palette.customTheme.overlayBlueDefault,
      marginRight: 0,
    }),
  },
};
