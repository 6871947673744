import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const BankRequisites = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminBankRequisites' */
      /* webpackMode: "lazy" */
      `./bank-requisites`
    )
);

export const LazyBankRequisites: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <BankRequisites />
  </Suspense>
);
