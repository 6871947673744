import { FC } from 'react';
import { AttachFiles } from 'src/components/widgets';
import {
  CustomerSpecificationStatus,
  DocumentEntityTypes,
  DocumentTypes,
  ICustomerSpecification,
} from 'src/models';
import { Typography } from 'src/components/ui';
import { arrayRequired } from 'src/utils/form-rules';
import { formatServerDate } from 'src/utils';
import styles from './files-input.module.scss';

type TFilesInputProps = {
  specification: ICustomerSpecification;
  preview?: boolean;
};

export const FilesInput: FC<TFilesInputProps> = ({
  specification,
  preview,
}) => (
  <div className={styles.wrapper}>
    {!specification.quickDeal && (
      <>
        <AttachFiles
          isEditMode={false}
          fileListTitle="Проект спецификации"
          extraTitle={
            specification.preliminaryVersionReceivedAt && (
              <Typography color="primary">
                Получен{' '}
                {formatServerDate(
                  specification.preliminaryVersionReceivedAt,
                  'dd.MM.yy, HH:mm'
                )}
              </Typography>
            )
          }
          subtitleComponent={
            <Typography mb={2}>
              Спецификация для согласования с заказчиком без подписей и печатей.
            </Typography>
          }
          documentOptions={{
            entityType: DocumentEntityTypes.CUSTOMER_SPECIFICATION,
            documentType: DocumentTypes.SPECIFICATION_PRELIMINARY_VERSION,
            entityId: specification.specificationId,
          }}
          showDownloadAllButton={!preview}
          formItemName="documents.SPECIFICATION_PRELIMINARY_VERSION"
          canPreview
        />

        <AttachFiles
          isEditMode={
            !preview &&
            specification.status.code ===
              CustomerSpecificationStatus.PRELIMINARY_VERSION_RECEIVED
          }
          uploadFilesTitle="Прикрепите скан подписанной спецификации"
          fileListTitle="Скан подписанной спецификации"
          extraTitle={
            specification.signedScanSentAt && (
              <Typography color="primary">
                Отправлен{' '}
                {formatServerDate(
                  specification.signedScanSentAt,
                  'dd.MM.yy, HH:mm'
                )}
              </Typography>
            )
          }
          subtitleComponent={
            <Typography mb={2}>
              Спецификация, которая подписана с вашей стороны.
            </Typography>
          }
          rules={{
            validate: arrayRequired,
          }}
          showDownloadAllButton={!preview}
          documentOptions={{
            entityType: DocumentEntityTypes.CUSTOMER_SPECIFICATION,
            documentType: DocumentTypes.SPECIFICATION_SIGNED_SCAN,
            entityId: specification.specificationId,
          }}
          formItemName="documents.SPECIFICATION_SIGNED_SCAN"
          canPreview
        />
      </>
    )}

    {specification.status.code ===
      CustomerSpecificationStatus.FINAL_VERSION_RECEIVED && (
      <AttachFiles
        isEditMode={false}
        showDownloadAllButton={!preview}
        fileListTitle="Финальная спецификация"
        extraTitle={
          specification.finalVersionReceivedAt && (
            <Typography color="primary">
              Получена{' '}
              {formatServerDate(
                specification.finalVersionReceivedAt,
                'dd.MM.yy, HH:mm'
              )}
            </Typography>
          )
        }
        subtitleComponent={
          <Typography mb={2}>
            Спецификация, которая подписана с обеих сторон.
          </Typography>
        }
        documentOptions={{
          entityType: DocumentEntityTypes.CUSTOMER_SPECIFICATION,
          documentType: DocumentTypes.SPECIFICATION_FINAL_VERSION,
          entityId: specification.specificationId,
        }}
        formItemName="documents.SPECIFICATION_FINAL_VERSION"
        canPreview
      />
    )}
  </div>
);
