import { TConfigFilter } from 'src/components/ui/filters/types';

export const getActiveVariantsMap = <T = Record<string, any>>(
  filters: TConfigFilter[]
) =>
  filters.reduce(
    (acc, val) => ({
      ...acc,
      [val.name]: val.variantList
        .filter((filter) => filter.checked)
        ?.map((item) => item.value),
    }),
    {} as T
  );
