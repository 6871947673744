import { TConfigFilter } from 'src/components/ui/filters/types';
import {
  TCommunicationListParams,
  TEmailFilters,
} from 'src/api/communications/models';

export const parseEmailFilters = (
  filters: TConfigFilter[]
): TCommunicationListParams['filter'] =>
  filters.reduce(
    (acc, val) => ({
      ...acc,
      [val.name]: val.variantList
        .filter((filter) => filter.checked)
        ?.map((item) => item.value),
    }),
    {} as TCommunicationListParams['filter']
  );

// TODO: Переименовать или перенести
export const getFilters = (
  filters: TEmailFilters,
  filterConfig: TConfigFilter[]
): TConfigFilter[] =>
  filterConfig?.map((filter) => ({
    ...filter,
    variantList: filters[filter?.name]
      ? filters[filter.name].map((item: string | number) => ({
          label: String(item),
          checked: false,
          value: String(item),
        }))
      : filter.variantList,
  }));
