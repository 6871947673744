import { FC } from 'react';
import { Tooltip } from 'src/components/ui';

import styles from './activity-list.module.scss';

interface IActivityListProps {
  activityName: string[];
  maxTagLength?: number;
  containerClassName?: string;
}

export const ActivityList: FC<IActivityListProps> = ({
  activityName,
  maxTagLength = 30,
  containerClassName,
}) => {
  const trimByMaxWord = (result: string[], element: string, index: number) => {
    const oldLength = result.join('').length;
    const newLength = oldLength + element.length;

    if (index === 0 && newLength >= maxTagLength) {
      return [...result, `${element.slice(0, maxTagLength)} ...`];
    }

    if (newLength <= maxTagLength) {
      return [...result, element];
    }

    return result;
  };

  const printList = (element: string, index: number, pArray: string[]) => {
    if (!element) {
      return null;
    }

    const result = [];

    if (element.endsWith('...')) {
      result.push(
        <Tooltip
          key={index}
          className={styles.activityName}
          title={activityName[index]}>
          <span className={containerClassName || styles.activityName}>
            {element}
          </span>
        </Tooltip>
      );
    } else {
      result.push(
        <span key={index} className={containerClassName || styles.activityName}>
          {element}
        </span>
      );
    }

    if (index === pArray.length - 1 && activityName.length !== pArray.length) {
      result.push(
        <div key="ellipsis" className={styles.containerEllipsis}>
          <Tooltip
            title={
              <div className={styles.activityNameFull}>
                {activityName.map((item, key) => (
                  <div
                    key={key}
                    className={containerClassName || styles.activityName}>
                    {item}
                  </div>
                ))}
              </div>
            }>
            <span className={styles.ellipsis}>...</span>
          </Tooltip>
        </div>
      );
    }

    return result;
  };

  return (
    <div className={styles.activityNameLine}>
      {activityName?.reduce(trimByMaxWord, []).map(printList)}
    </div>
  );
};
