export const getFormattedBudget = (
  budget: string | number,
  currencySign: string = '₽'
): string => {
  const sumFormatter = Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (typeof budget === 'string') {
    return `${sumFormatter.format(parseInt(budget, 10))} ${currencySign}`;
  }
  return `${sumFormatter.format(budget as number)} ${currencySign}`;
};
