import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COUNT_PER_PAGE } from 'src/configs';
import { defaultSortNews, TNewsListParams } from 'src/api/news';
import { useCallback } from 'react';
import { useAppDispatch } from '../hooks';

interface IInitialState {
  newsCategory: {
    firstElementIndex: number;
    lastElementIndex: number;
    page: number;
  };
  news: {
    pagingDto: {
      page: number;
      limit: number;
    };
    sortDto: TNewsListParams['sortDto'];
  };
}

const initialState: IInitialState = {
  newsCategory: {
    firstElementIndex: 0,
    lastElementIndex: COUNT_PER_PAGE.NEWS_CATEGORIES_FIRST_PAGE,
    page: 1,
  },
  news: {
    pagingDto: {
      page: 1,
      limit: COUNT_PER_PAGE.NEWS,
    },
    sortDto: defaultSortNews,
  },
};

interface IPageRequestParams<
  I extends keyof IInitialState = keyof IInitialState
> {
  path: I;
  requestParams: IInitialState[I];
}

const pageRequestParams = createSlice({
  name: 'pageRequestParams',
  initialState,

  reducers: {
    setPageRequestParams(
      state: any,
      { payload }: PayloadAction<IPageRequestParams>
    ) {
      state[payload.path] = payload.requestParams;
    },
  },
});

export const pageRequestParamsReducer = pageRequestParams.reducer;

export const usePageRequestParams = () => {
  const dispatch = useAppDispatch();

  const setPageRequestParams = useCallback(
    <I extends keyof IInitialState>(arg: IPageRequestParams<I>) => {
      dispatch(pageRequestParams.actions.setPageRequestParams(arg));
    },
    [dispatch]
  );

  return { setPageRequestParams };
};
