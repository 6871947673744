import { ButtonProps } from '@mui/material/Button/Button';
import { FC, ReactNode } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'src/components/ui';
import { Dialog, TDialogProps } from './dialog';

export const ConfirmDialog: FC<
  Omit<TDialogProps, 'maxWidth'> & {
    title: ReactNode;
    confirmText?: string;
    onConfirm: () => void;
    maxWidth?: number;
    minWidth?: number;
    settingsBtn?: {
      loading?: boolean;
      color?: ButtonProps['color'];
    };
    confirmButtonDisabled?: boolean;
    cancelText?: string;
  }
> = ({
  open,
  title,
  confirmText = 'Ок',
  onConfirm,
  close,
  children,
  maxWidth = 468,
  minWidth,
  settingsBtn,
  cancelText,
  confirmButtonDisabled,
}) => (
  <Dialog
    open={open}
    close={close}
    PaperProps={{
      sx: {
        minWidth: `${minWidth}px`,
        maxWidth: `${maxWidth}px`,
        width: '100%',
      },
    }}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>{children}</DialogContent>

    <DialogActions>
      <Button
        onClick={onConfirm}
        {...settingsBtn}
        disabled={confirmButtonDisabled}>
        {confirmText}
      </Button>

      {close && cancelText && (
        <Button color="secondary" onClick={close} {...settingsBtn}>
          {cancelText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
