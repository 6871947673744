import { DocumentEntityTypes } from 'src/models';
import { TContact } from 'src/components/pages/company/contacts/types';

export interface IOrganizationData {
  organizationId: number;
  organizationName: string;

  contacts: TContact[];
}

export type TShareFilesProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  parentEntityId: number;

  entityType: DocumentEntityTypes;
  entityId: number;

  organizationInfo?: {
    organizationId: number;
    organizationName: string;
  };
};

export enum TabKeys {
  COPY_LINK = 'COPY_LINK',
  SEND_EMAIL = 'SEND_EMAIL',
}
