import { CompanyTypes } from 'src/models';
import { RouteObject } from 'react-router-dom';
import { RolePermissionService } from 'src/services';
import { TCompanyMenuItem, TCompanyRouteConfig } from './company-paths-by-type';
import { TRouteConfig } from '../routes';

export const getAvailableConfigItems = (
  config: TCompanyRouteConfig,
  companyType?: CompanyTypes
) =>
  config.reduce(
    (
      acc: { routeItems: TRouteConfig; menuItems: TCompanyMenuItem[] },
      item
    ) => {
      const { type, title, permissions, ...configItem } = item;

      const hasAccess = !permissions || RolePermissionService.can(permissions);

      if (!type || !companyType || type === companyType) {
        if (configItem.children) {
          const availableChildItems = getAvailableConfigItems(
            configItem.children,
            companyType
          );

          if (availableChildItems.routeItems.length) {
            acc.routeItems.push({
              ...configItem,
              permissions,
              children: availableChildItems.routeItems,
            });

            if (hasAccess) {
              acc.menuItems.push({
                title: title!,
                path: configItem.path ?? '',
                children: availableChildItems.menuItems,
              });
            }
          }
        } else {
          acc.routeItems.push({ ...configItem, permissions });

          if (title && hasAccess) {
            acc.menuItems.push({
              title,
              path: configItem.path ?? '',
            });
          }
        }
      }

      return acc;
    },
    { routeItems: [], menuItems: [] }
  );
