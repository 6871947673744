import { FC, useContext, useState } from 'react';
import { useDeleteNewsByIdMutation } from 'src/api/news';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  SvgIcon,
  Typography,
} from 'src/components/ui';
import { useNavigate } from 'react-router-dom';
import { Cross } from 'src/assets/icons';
import { NewsContext } from '../../hooks';

type DeleteNewsModalProps = {
  id: number;
  redirectPath: string;
};

export const DeleteNewsModal: FC<DeleteNewsModalProps> = ({
  id,
  redirectPath,
}) => {
  const redirectParam = redirectPath.split('/')[0];
  const navigate = useNavigate();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const handleUpdateListContext = useContext(NewsContext);
  const toggleDeleteModal = () => {
    setIsOpenDeleteModal((prev) => !prev);
  };
  const [deleteNews] = useDeleteNewsByIdMutation();
  const handleDelete = () => {
    deleteNews(id)
      .unwrap()
      .then(() => {
        toggleDeleteModal();
        handleUpdateListContext();
        navigate(`/news/${redirectParam}`);
      });
  };
  return (
    <>
      <Dialog open={isOpenDeleteModal}>
        <DialogTitle>
          <Typography variant="h3">Удалить новость?</Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Да</Button>
          <Button onClick={toggleDeleteModal} color="secondary">
            Нет
          </Button>
        </DialogActions>
      </Dialog>
      <Typography display="flex" gap="8px" color="error">
        <SvgIcon
          icon={Cross}
          height="20px"
          width="20px"
          strokeWidth="1px"
          strokeColor="error.main"
        />
        <Button variant="text" onClick={toggleDeleteModal}>
          <Typography color="error">Удалить</Typography>
        </Button>
      </Typography>
    </>
  );
};
