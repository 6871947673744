import { FC } from 'react';
import { AttachFiles } from 'src/components/widgets';
import {
  DocumentEntityTypes,
  DocumentTypes,
  IManagerContractorSpecification,
  IManagerCustomerSpecification,
  ManagerContractorSpecificationStatus,
} from 'src/models';
import { Typography } from 'src/components/ui';
import { arrayRequired } from 'src/utils/form-rules';
import { formatServerDate } from 'src/utils';

type TFilesInputProps = {
  viewMode: boolean;
  checkRequired: boolean;
  isQuickDeal: boolean;
} & (
  | {
      mode: 'contractor';
      specification?: IManagerContractorSpecification;
      isCustomerFinalVersionSent: boolean | undefined;
    }
  | {
      mode: 'customer';
      specification?: IManagerCustomerSpecification;
      isCustomerFinalVersionSent?: never;
    }
);

export const FilesInput: FC<TFilesInputProps> = ({
  specification,
  isCustomerFinalVersionSent,
  viewMode,
  checkRequired,
  mode,
  isQuickDeal,
}) => {
  const isContractorMode = mode === 'contractor';
  const entityType =
    DocumentEntityTypes[
      isContractorMode ? 'CONTRACTOR_SPECIFICATION' : 'CUSTOMER_SPECIFICATION'
    ];
  const isFinalVersionSent =
    specification?.status.code ===
    ManagerContractorSpecificationStatus.FINAL_VERSION_SENT;

  const isSignedScanReceived =
    isFinalVersionSent ||
    specification?.status.code ===
      ManagerContractorSpecificationStatus.SIGNED_SCAN_RECEIVED;

  return (
    <>
      {!isQuickDeal && (
        <AttachFiles
          isEditMode={!viewMode}
          rules={{
            validate: checkRequired
              ? {
                  arrayRequired,
                }
              : undefined,
          }}
          uploadFilesTitle="Прикрепите проект спецификации"
          fileListTitle="Проект спецификации"
          fullScreen={false}
          extraTitle={
            specification?.preliminaryVersionSentAt && (
              <Typography color="primary">
                Отправлен{' '}
                {formatServerDate(
                  specification.preliminaryVersionSentAt,
                  'dd.MM.yy, HH:mm'
                )}
              </Typography>
            )
          }
          subtitleComponent={
            <Typography mb={2}>
              {`Спецификация для согласования с ${
                isContractorMode ? 'исполнителем' : 'заказчиком'
              } без подписей и печатей.`}
            </Typography>
          }
          documentOptions={{
            entityType,
            documentType: DocumentTypes.SPECIFICATION_PRELIMINARY_VERSION,
            entityId: specification?.specificationId || 0,
          }}
          formItemName="documents.SPECIFICATION_PRELIMINARY_VERSION"
          canPreview
        />
      )}

      {!isQuickDeal && isSignedScanReceived && (
        <AttachFiles
          fileListTitle="Скан подписанной спецификации"
          extraTitle={
            <Typography color="primary">
              Получен{' '}
              {formatServerDate(
                specification?.signedScanReceivedAt,
                'dd.MM.yy, HH:mm'
              )}
            </Typography>
          }
          subtitleComponent={
            <Typography mb={2}>
              {`Спецификация, которую подписал ${
                isContractorMode ? 'исполнитель' : 'заказчик'
              }.`}
            </Typography>
          }
          documentOptions={{
            entityType,
            documentType: DocumentTypes.SPECIFICATION_SIGNED_SCAN,
            entityId: specification?.specificationId || 0,
          }}
          formItemName="documents.SPECIFICATION_SIGNED_SCAN"
          canPreview
        />
      )}

      {(isSignedScanReceived || isQuickDeal) &&
        (!isContractorMode || isCustomerFinalVersionSent) && (
          <AttachFiles
            isEditMode={isQuickDeal ? !viewMode : !isFinalVersionSent}
            rules={{
              validate: checkRequired
                ? {
                    arrayRequired,
                  }
                : undefined,
            }}
            uploadFilesTitle="Прикрепите финальную спецификацию"
            fileListTitle="Финальная спецификация"
            fullScreen={false}
            extraTitle={
              specification?.finalVersionSentAt && (
                <Typography color="primary">
                  Отправлена{' '}
                  {formatServerDate(
                    specification.finalVersionSentAt,
                    'dd.MM.yy, HH:mm'
                  )}
                </Typography>
              )
            }
            subtitleComponent={
              <Typography mb={2}>
                Спецификация, которая подписана с обеих сторон.
              </Typography>
            }
            documentOptions={{
              entityType,
              documentType: DocumentTypes.SPECIFICATION_FINAL_VERSION,
              entityId: specification?.specificationId || 0,
            }}
            formItemName="documents.SPECIFICATION_FINAL_VERSION"
            canPreview
          />
        )}
    </>
  );
};
