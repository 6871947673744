import { Components } from '@mui/material/styles/components';

export const MuiMenuItem: Components['MuiMenuItem'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      borderRadius: '4px',
      padding: '12px 20px',
      margin: '2px 0',
      '&.active, &.Mui-selected, &.Mui-selected.Mui-focusVisible': {
        backgroundColor: '#F5F5F5',
      },
    },
  },
};
