import { FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useGetTechnicalOpportunitiesQuery } from 'src/api/dictionary';
import {
  Box,
  Button,
  ConfirmDialog,
  Drawer,
  Form,
  Skeleton,
} from 'src/components/ui';
import { ETagStatuses } from 'src/configs/tag-status';
import { TTechnicalOpportunity } from 'src/models';
import { SearchTags } from 'src/components/widgets';
import { ListTags } from './components';
import styles from './modal-select-classifier-tags.module.scss';

interface IModalSelectClassifierTagsProps {
  form: UseFormReturn<any>;
  isShowModal: boolean;
  toggleShowModalSelectTags: () => void;
  handleSubmitForm?: () => void;
  defaultTechnicalOpportunities?: TTechnicalOpportunity[];
}

export const ModalSelectClassifierTags: FC<IModalSelectClassifierTagsProps> = ({
  isShowModal,
  toggleShowModalSelectTags,
  form,
  handleSubmitForm,
  defaultTechnicalOpportunities,
}) => {
  const [showResetFormConfirm, setShowResetFromConfirm] = useState(false);
  const { data: technicalOpportunitiesList, isLoading } =
    useGetTechnicalOpportunitiesQuery(ETagStatuses.ACTIVE);

  const onSubmit = (data: any) => {
    form.reset(data);
    toggleShowModalSelectTags();
    handleSubmitForm?.();
  };

  const handleClickCancelTags = () => {
    form.reset();
    toggleShowModalSelectTags();
  };

  const handleClickShowModal = () => {
    if (form.getFieldState('technicalOpportunitiesCategories').isDirty) {
      setShowResetFromConfirm(true);
      return;
    }

    toggleShowModalSelectTags();
  };

  useEffect(() => {
    if (!technicalOpportunitiesList) return;

    const { technicalOpportunitiesCategories, ...rest } = form.getValues();

    const resultValue = technicalOpportunitiesList.map((item) => {
      const currentCategory = technicalOpportunitiesCategories.find(
        (category: TTechnicalOpportunity) =>
          category.id === item.id && category.checked
      );

      const currentSubCategories: TTechnicalOpportunity['subcategories'] =
        currentCategory?.subcategories;

      return {
        ...item,
        checked: !!currentCategory,
        subcategories:
          item.subcategories &&
          item.subcategories.map((subcategory) => ({
            ...subcategory,
            checked: !!currentSubCategories?.find(
              (currentSubCategory) =>
                currentSubCategory.id === subcategory.id &&
                currentSubCategory.checked
            ),
          })),
      };
    });

    form.reset({
      ...rest,
      technicalOpportunitiesCategories: resultValue,
    });
  }, [form, technicalOpportunitiesList]);

  return (
    <>
      <Drawer
        // sx={{ '& .MuiPaper-root': { width: '80%' } }}
        title="Выберите теги классификатора"
        open={isShowModal}
        close={handleClickShowModal}>
        <Box width="100%">
          {isLoading ? (
            <>
              <Box marginBottom="20px">
                <Skeleton height="50px" />
              </Box>
              <Skeleton height="200px" />
            </>
          ) : (
            <>
              <Form form={form} viewMode>
                <SearchTags
                  form={form}
                  technicalOpportunitiesList={form.watch(
                    'technicalOpportunitiesCategories'
                  )}
                  defaultTechnicalOpportunities={defaultTechnicalOpportunities}
                />
                <ListTags />
              </Form>
              {form.formState.isDirty &&
                form.getFieldState('technicalOpportunitiesCategories')
                  .isDirty && (
                  <Box className={styles.containerActions}>
                    <Button onClick={form.handleSubmit(onSubmit)}>
                      Применить
                    </Button>

                    <Button color="secondary" onClick={handleClickCancelTags}>
                      Отменить
                    </Button>
                  </Box>
                )}
            </>
          )}
        </Box>
      </Drawer>

      <ConfirmDialog
        open={showResetFormConfirm}
        close={() => setShowResetFromConfirm(false)}
        title="Отменить изменения?"
        confirmText="Да, отменить"
        onConfirm={() => {
          setShowResetFromConfirm(false);
          form.reset();
          handleClickShowModal();
        }}>
        После отмены поля будут заполнены предыдущими значениями.
      </ConfirmDialog>
    </>
  );
};
