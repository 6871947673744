import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Pencil: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 16 16"
    sx={{
      width: '16px',
      height: '16px',
      fill: 'none',
      color: '#0041A0',
      ...sx,
    }}
    {...props}>
    <path
      d="M11.6929 6.74664L9.25293 4.30664"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16733 13.9996H2V11.8322C2 11.6556 2.07 11.4856 2.19533 11.3609L11.0847 2.47089C11.3453 2.21022 11.7673 2.21022 12.0273 2.47089L13.528 3.97156C13.7887 4.23222 13.7887 4.65422 13.528 4.91422L4.63867 13.8042C4.514 13.9296 4.344 13.9996 4.16733 13.9996V13.9996Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
