import { FC, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import classNames from 'classnames';
import {
  Form,
  Typography,
  Box,
  Button,
  Checkbox,
  NumberInput,
  ButtonGroup,
  Tooltip,
} from 'src/components/ui';
import { required } from 'src/utils/form-rules';
import { InfoHelpIcon } from 'src/assets/icons';
import { TFormInput } from '../types';

import styles from './groupage-cargo.module.scss';

enum TabKeys {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

const tabs = [
  {
    label: 'Одно место',
    key: TabKeys.SINGLE,
  },
  {
    label: 'Несколько мест',
    key: TabKeys.MULTIPLE,
  },
];

type TGroupageCargo = {
  form: UseFormReturn<TFormInput>;
};

export const GroupageCargo: FC<TGroupageCargo> = ({ form }) => {
  const formValues = form.getValues();
  const [activeTab, setActiveTab] = useState(
    formValues.singlePlace ? TabKeys.SINGLE : TabKeys.MULTIPLE
  );
  const [maxLength, setMaxLength] = useState(formValues.maxLength);
  const [length, setLength] = useState(formValues.length);
  const [maxWidth, setMaxWidth] = useState(formValues.maxWidth);
  const [width, setWidth] = useState(formValues.width);
  const [maxHeight, setMaxHeight] = useState(formValues.maxHeight);
  const [height, setHeight] = useState(formValues.height);
  const [maxWeight, setMaxWeight] = useState(formValues.maxWeight);
  const [totalWeight, setTotalWeight] = useState(formValues.totalWeight);
  const [places, setPlaces] = useState(formValues.places);
  const [isPlaceEqual, setIsPlaceEqual] = useState(formValues.isPlaceEqual);
  const [totalVolume, setTotalVolume] = useState(formValues.totalVolume);

  const prevTotalWeight = useRef(totalWeight);
  const prevTotalVolume = useRef(totalVolume);

  useEffect(() => {
    form.setValue('singlePlace', activeTab !== TabKeys.MULTIPLE);
  }, [activeTab, form]);

  useEffect(() => {
    const volume = length * width * height;

    form.setValue('volume', volume >= 0.01 ? volume : 0.01);
  }, [length, width, height, form]);

  useEffect(() => {
    const totalVolumeValue = maxLength * maxWidth * maxHeight;

    form.setValue(
      'totalVolume',
      totalVolumeValue >= 0.01 ? totalVolumeValue : 0.01
    );
  }, [maxLength, maxHeight, maxWidth, form]);

  useEffect(() => {
    if (isPlaceEqual) {
      if (
        totalWeight === prevTotalWeight.current &&
        totalVolume === prevTotalVolume.current
      ) {
        const calculatedTotalWeight = maxWeight * places;
        const calculatedTotalVolume = maxWidth * maxHeight * maxLength * places;

        if (calculatedTotalVolume !== totalVolume) {
          form.setValue('totalVolume', calculatedTotalVolume);
          setTotalVolume(calculatedTotalVolume);
          prevTotalVolume.current = calculatedTotalVolume;
        }

        if (calculatedTotalWeight !== totalWeight) {
          form.setValue('totalWeight', calculatedTotalWeight);
          setTotalWeight(calculatedTotalWeight);
          prevTotalWeight.current = calculatedTotalWeight;
        }
      } else {
        setIsPlaceEqual(false);
        form.setValue('isPlaceEqual', false);
        prevTotalWeight.current = totalWeight;
        prevTotalVolume.current = totalVolume;
      }
    } else {
      prevTotalWeight.current = totalWeight;
      prevTotalVolume.current = totalVolume;
    }
  }, [
    maxWeight,
    isPlaceEqual,
    places,
    form,
    totalWeight,
    totalVolume,
    maxWidth,
    maxHeight,
    maxLength,
  ]);

  return (
    <>
      <Typography
        display="flex"
        alignItems="center"
        variant="h3"
        gap="10px"
        marginBottom="22px">
        Задайте параметры сборного груза
        <Tooltip
          title={
            <Box>
              <Typography mb="20px" variant="h3">
                Максимальные размеры крупногабаритного груза
              </Typography>
              <Typography>
                {`Длина, ширина и высота — 13,5 м*2,42 м*2,45 м\n
                Вес — 20 000 кг\n
                Объем — 82 м3
                `}
              </Typography>
            </Box>
          }>
          <InfoHelpIcon />
        </Tooltip>
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ButtonGroup>
          {tabs.map((tab) => (
            <Button
              key={tab.key}
              className={classNames(tab.key === activeTab && 'active')}
              onClick={() => setActiveTab(tab.key)}>
              {tab.label}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      {activeTab === TabKeys.MULTIPLE && (
        <div>
          <Box mt="48px" className={styles.inputRow}>
            <Form.Item
              label="Макс длина (м)"
              rules={{ required }}
              name="maxLength">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  setMaxLength(Number(event.target.value));
                }}
              />
            </Form.Item>
            <Form.Item
              label="Макс ширина (м)"
              rules={{ required }}
              name="maxWidth">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  setMaxWidth(Number(event.target.value));
                }}
              />
            </Form.Item>
            <Form.Item
              label="Макс. высота (м)"
              rules={{ required }}
              name="maxHeight">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  setMaxHeight(Number(event.target.value));
                }}
              />
            </Form.Item>
          </Box>

          <Box className={styles.inputRow}>
            <Form.Item
              label="Макс. вес (кг)"
              rules={{ required }}
              name="maxWeight">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  setMaxWeight(Number(event.target.value));
                }}
              />
            </Form.Item>
            <Form.Item
              label="Кол-во мест (шт)"
              rules={{ required }}
              name="places">
              <NumberInput
                decimalScale={0}
                onBlur={(event) => {
                  setPlaces(Number(event.target.value));
                }}
              />
            </Form.Item>
          </Box>

          <Box mb="22px">
            <Form.Item
              name="isPlaceEqual"
              noMargin
              label={
                <Typography variant="h4">
                  Все места одинаковы по размеру и весу
                </Typography>
              }
              isControlLabel>
              <Checkbox
                onChange={(event) => setIsPlaceEqual(event.target.checked)}
              />
            </Form.Item>
          </Box>

          <Box mb="28px" className={styles.inputRow}>
            <Form.Item
              label="Общий вес (кг)"
              rules={{ required }}
              name="totalWeight">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  if (setTotalWeight) {
                    setTotalWeight(Number(event.target.value));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Общий объем (м3)"
              rules={{ required }}
              name="totalVolume">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  setTotalVolume(Number(event.target.value));
                }}
              />
            </Form.Item>
          </Box>
        </div>
      )}

      {activeTab === TabKeys.SINGLE && (
        <div>
          <Box mt="48px" className={styles.inputRow}>
            <Form.Item label="Длина (м)" rules={{ required }} name="length">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  setLength(Number(event.target.value));
                }}
              />
            </Form.Item>
            <Form.Item label="Ширина (м)" rules={{ required }} name="width">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  setWidth(Number(event.target.value));
                }}
              />
            </Form.Item>
            <Form.Item label="Высота (м)" rules={{ required }} name="height">
              <NumberInput
                decimalScale={2}
                onBlur={(event) => {
                  setHeight(Number(event.target.value));
                }}
              />
            </Form.Item>
          </Box>

          <Box mb="28px" className={styles.inputRow}>
            <Form.Item label="Вес (кг)" rules={{ required }} name="weight">
              <NumberInput decimalScale={2} />
            </Form.Item>
            <Form.Item label="Объем (м3)" rules={{ required }} name="volume">
              <NumberInput decimalScale={2} />
            </Form.Item>
          </Box>
        </div>
      )}

      <Typography variant="h3" marginBottom="24px">
        {`После сохранения данные параметры будут использоваться для расчета логистики
        в каждом КП Исполнителя`}
      </Typography>
    </>
  );
};
