import { BudgetChangeHistorySource } from 'src/models/bids-details';

export const BUDGET_CHANGE_HISTORY_SOURCES_NAMES = {
  [BudgetChangeHistorySource.MANUAL]: 'Вручную',
  [BudgetChangeHistorySource.BEST_COST]: 'Наименьшая цена КП исполнителя',
  [BudgetChangeHistorySource.COST_FOR_BEST_CONTRACTOR_COMMERCIAL_OFFER]:
    'Лучшее КП исполнителя',
  [BudgetChangeHistorySource.COST_FOR_LAST_CUSTOMER_COMMERCIAL_OFFER]:
    'Последнее КП заказчику',
  [BudgetChangeHistorySource.COST_FOR_APPROVED_CUSTOMER_COMMERCIAL_OFFER]:
    'Согласованное заказчиком КП',
  [BudgetChangeHistorySource.COST_FOR_CONTRACTOR_SPECIFICATION]:
    'Спецификация исполнителя',
  [BudgetChangeHistorySource.COST_FOR_CUSTOMER_SPECIFICATION]:
    'Спецификация заказчика',
};
