import { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

// TODO: Сделать одну "умную" таблицу, вместо 2х

interface ITableTProps {}

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Выручка', 159, 6.0, 24, 4.0),
  createData('Себестоимость продаж', 237, 9.0, 37, 4.3),
  createData('Валовая прибыль (убыток)', 262, 16.0, 24, 6.0),
  createData('Прибыль (убыток от продаж)', 305, 3.7, 67, 4.3),
  createData('Прибыль (убыток) до налогообложения', 356, 16.0, 49, 3.9),
  createData('Чистая прибыль (убыток)', 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F4F5F7',
    color: '#7A8694',
    fontSize: 14,
  },
  // [`&.${tableCellClasses.head}`]: {
  //   fontSize: 14,
  // },
}));

export const FinancialReportTable: FC<ITableTProps> = () => (
  <TableContainer>
    <Table
      sx={() => ({
        [`& .${tableCellClasses.root}`]: {
          fontSize: '14px',
        },
      })}
      aria-label="simple table">
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ fontSize: '10px' }}>
            Все суммы указаны в тысячах рублей
          </StyledTableCell>
          <StyledTableCell style={{ fontSize: '14px' }}>Код</StyledTableCell>
          <StyledTableCell style={{ fontSize: '14px' }}>2022</StyledTableCell>
          <StyledTableCell style={{ fontSize: '14px' }}>2021</StyledTableCell>
          <StyledTableCell style={{ fontSize: '14px' }}>2020</StyledTableCell>
          <StyledTableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell>{row.calories}</TableCell>
            <TableCell>{row.fat}</TableCell>
            <TableCell>{row.carbs}</TableCell>
            <TableCell>{row.protein}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
