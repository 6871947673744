import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      body1: 'div',
      body2: 'div',
      subtitle1: 'div',
      subtitle2: 'div',
      inherit: 'span',
    },
  },

  styleOverrides: {
    h1: ({ theme }) => ({
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
        lineHeight: 1.3,
      },
    }),
  },
};
