import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  ButtonGroup,
  Box,
  Skeleton,
  Typography,
  successNotification,
} from 'src/components/ui';
import { useGetCustomerInfoQuery } from 'src/api/business-request/business-request';
import { getErrorMessage } from 'src/store/utils';
import { CompanyTypes, DocumentEntityTypes } from 'src/models';
import {
  useLazyGetDocumentsShareLinkQuery,
  useShareDocumentsByEmailMutation,
} from 'src/api/document';
import { useGetContactsQuery } from 'src/api/contacts';
import { joinFullName } from 'src/utils';
import { TShareFilesProps, TabKeys, IOrganizationData } from './types';

const tabs = [
  {
    label: 'Копировать ссылку на файлы',
    key: TabKeys.COPY_LINK,
  },
  {
    label: 'Отправить файлы по почте',
    key: TabKeys.SEND_EMAIL,
  },
];

export const ShareFiles: FC<TShareFilesProps> = ({
  title,
  open,
  onClose,
  parentEntityId,
  organizationInfo,
  entityId,
  entityType,
}) => {
  const [activeTab, setActiveTab] = useState(TabKeys.COPY_LINK);
  const [organizationData, setOrganizationData] = useState<IOrganizationData>();

  const isCustomerCommercialOffer =
    entityType === DocumentEntityTypes.COMMERCIAL_OFFER_FOR_CUSTOMER;
  const isCustomerSpecification =
    entityType === DocumentEntityTypes.CUSTOMER_SPECIFICATION;
  const isContractorSpecification =
    entityType === DocumentEntityTypes.CONTRACTOR_SPECIFICATION;

  const {
    data: contractors,
    isLoading: isLoadingContacts,
    error: errorContacts,
  } = useGetContactsQuery(
    {
      companyType: CompanyTypes.CONTRACTOR,
      idCompany: organizationInfo?.organizationId || 0,
    },
    {
      skip: !isContractorSpecification,
    }
  );

  const {
    data: customerInfo,
    isLoading: isLoadingCustomerInfo,
    error: errorCustomerInfo,
  } = useGetCustomerInfoQuery(parentEntityId, {
    skip: !isCustomerCommercialOffer && !isCustomerSpecification,
  });

  useEffect(() => {
    if (organizationInfo && contractors && isContractorSpecification) {
      const mainContact = contractors.find((element) => element.isMainContact);

      setOrganizationData({
        organizationId: organizationInfo.organizationId,
        organizationName: organizationInfo.organizationName || '',

        contacts: mainContact ? [mainContact] : [],
      });
    }

    if (
      customerInfo &&
      (isCustomerCommercialOffer || isCustomerSpecification)
    ) {
      setOrganizationData({
        organizationId: customerInfo.organizationId,
        organizationName: customerInfo.organizationName,

        contacts: customerInfo.contacts,
      });
    }
  }, [
    contractors,
    organizationInfo,
    customerInfo,
    isCustomerCommercialOffer,
    isCustomerSpecification,
    isContractorSpecification,
  ]);

  const [
    getLinkOnDocuments,
    { isLoading: isLoadingGetLink, error: errorGetLink },
  ] = useLazyGetDocumentsShareLinkQuery();

  const [
    sendLinkOnEmail,
    { isLoading: isLoadingSendLinkOnEmail, error: errorSendLinkOnEmail },
  ] = useShareDocumentsByEmailMutation();

  const handleClickCopy = async () => {
    try {
      const result = await getLinkOnDocuments({
        entityId,
        entityType,
      }).unwrap();

      await navigator.clipboard.writeText(result.shareDocumentsLink);

      successNotification({ title: 'Ссылка была скопирована' });
    } catch {
      // ignore
    }
  };

  const handleClickSend = async () => {
    if (!organizationData) return;

    try {
      await sendLinkOnEmail({
        entityId,
        entityType,
        organizationIds: [organizationData.organizationId],
      }).unwrap();

      successNotification({
        title: 'Письмо с файлами было отправлено',
      });

      onClose();
    } catch {
      // ignore
    }
  };

  return (
    <Dialog open={open} close={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {tabs.length > 1 && (
          <ButtonGroup>
            {tabs.map((tab) => (
              <Button
                key={tab.key}
                className={classNames(tab.key === activeTab && 'active')}
                onClick={() => setActiveTab(tab.key)}>
                {tab.label}
              </Button>
            ))}
          </ButtonGroup>
        )}

        <Box mt="24px">
          {activeTab === TabKeys.COPY_LINK && (
            <>
              <Typography variant="h2">Копировать ссылку на файлы</Typography>

              <Typography variant="h4" mt="8px">
                При клике по кнопке ссылка будет скопирована в буфер обмена
              </Typography>

              {errorGetLink && (
                <Typography color="error" mt="12px">
                  {getErrorMessage(errorGetLink, 'Ошибка при получении ссылки')}
                </Typography>
              )}
            </>
          )}

          {activeTab === TabKeys.SEND_EMAIL && (
            <>
              <Typography variant="h2">Отправить файлы по почте</Typography>

              {isLoadingCustomerInfo || isLoadingContacts ? (
                <Skeleton width="100%" height="60px" />
              ) : (
                organizationData && (
                  <>
                    <Typography variant="h4" mt="8px">
                      {organizationData.organizationName}
                    </Typography>

                    <Box component="ul" m="8px 0">
                      {organizationData.contacts.map((element) => (
                        <li key={element.id}>
                          <Typography variant="h4" mt="8px">
                            {joinFullName(
                              element.lastName,
                              element.firstName,
                              element.middleName
                            )}{' '}
                            ({element.email})
                          </Typography>
                        </li>
                      ))}
                    </Box>
                  </>
                )
              )}

              {(errorCustomerInfo || errorContacts) && (
                <Typography color="error" mt="12px">
                  {getErrorMessage(
                    errorCustomerInfo || errorContacts,
                    'Ошибка при получении данных'
                  )}
                </Typography>
              )}

              {errorSendLinkOnEmail && (
                <Typography color="error" mt="12px">
                  {getErrorMessage(
                    errorSendLinkOnEmail,
                    'Ошибка при отправке файлов на почту'
                  )}
                </Typography>
              )}
            </>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        {activeTab === TabKeys.COPY_LINK && (
          <Button onClick={handleClickCopy} loading={isLoadingGetLink}>
            Скопировать
          </Button>
        )}

        {activeTab === TabKeys.SEND_EMAIL && (
          <Button onClick={handleClickSend} loading={isLoadingSendLinkOnEmail}>
            Отправить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
