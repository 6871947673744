import { TRangeFilterValue } from 'src/components/ui/new-filters/types';

export const encodeFilterRangeValue = (
  value?: TRangeFilterValue
): string | undefined => {
  if (!value) {
    return undefined;
  }
  if (value.from || value.to) {
    return `${value.from}+-+${value.to}`;
  }
  return '';
};

export const decodeFilterRangeValue = (
  value?: string | null
): TRangeFilterValue => {
  if (!value) {
    return {
      from: '',
      to: '',
    };
  }
  const values = value.split('+');
  const startValue = values[0];
  const middleValue = values[1];
  const endValue = values[2];
  if (startValue !== '-' && endValue) {
    return {
      from: startValue,
      to: endValue,
    };
  }

  if (startValue === '-') {
    return {
      from: '',
      to: middleValue,
    };
  }

  if (middleValue === '-') {
    return {
      from: startValue,
      to: '',
    };
  }

  return {
    from: '',
    to: '',
  };
};
