import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Virtuoso } from 'react-virtuoso';
import {
  OutlinedInput,
  Checkbox,
  IconButton,
  FormControlLabel,
  Box,
  Typography,
  Form,
} from 'src/components/ui/index';
import { CloseIcon, SearchBtn } from 'src/assets/icons';
import { TConfigFilter, TVariant } from '../../../../types';
import commonStyles from '../../../../filters.module.scss';

import styles from './checkbox-form.module.scss';

type TFormInput = {
  [key in string]: TVariant[];
};

export type CheckboxFormProps = {
  filter: TConfigFilter;
  onFilter: (selectedFilters: Record<string, Array<TVariant>>) => void;
  clear: boolean;
  isShowSearchErrors?: boolean;
  isShowSelectAll?: boolean;
  isSearchByType?: boolean;
  removeSort?: boolean;
  mode?: 'submit' | 'change';
  isSearchByValue?: boolean;
  isPhoneSearch?: boolean;
};

export const CheckboxForm: FC<CheckboxFormProps> = ({
  filter,
  onFilter,
  clear,
  isShowSearchErrors,
  isShowSelectAll,
  isSearchByType = false,
  removeSort = false,
  mode = 'submit',
  isSearchByValue = false,
  isPhoneSearch = false,
}) => {
  const { withSearch, className } = filter;
  const [canClear, setCanClear] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isShowList, setIsShowList] = useState(false);
  const list = useRef<TVariant[]>([]);

  const formSearch = useForm({
    defaultValues: {
      search: '',
    },
  });

  const formVariants = useForm({
    defaultValues: {
      [filter.name]: removeSort
        ? filter.variantList
        : filter.variantList.sort((a, b) => +b.checked - +a.checked),
    },
  });

  useEffect(() => {
    const dataList = removeSort
      ? filter.variantList
      : filter.variantList.sort((a, b) => +b.checked - +a.checked);

    if (isSearchByType) {
      const searchValue = formSearch.getValues('search');

      formVariants.reset({
        [filter.name]: dataList.filter((variant) =>
          variant.label.toLowerCase().includes(searchValue.toLowerCase())
        ),
      });
    } else {
      formVariants.reset({ [filter.name]: dataList });
    }

    list.current = dataList;
    setIsShowList(!!dataList.length);
  }, [filter, formSearch, formVariants, isSearchByType, removeSort]);

  const handleSearch: SubmitHandler<{
    search: string;
  }> = useCallback(
    ({ search }) => {
      let filterList: TVariant[] = [];

      if (isSearchByValue) {
        const searchValue = search.replace(/\s/g, '');

        if (isPhoneSearch) {
          const currentList = list.current.map((item) => ({
            ...item,
            value: `8${item.value}`,
          }));

          filterList = currentList.filter((variant) =>
            variant.value
              .toLowerCase()
              .includes(search.replace(/\s/g, '').toLowerCase())
          );
        } else {
          filterList = list.current.filter((variant) =>
            variant.value
              .toLowerCase()
              .includes(search.replace(/\s/g, '').toLowerCase())
          );
        }
      } else {
        filterList = list.current.filter((variant) =>
          variant.label.toLowerCase().includes(search.toLowerCase())
        );
      }

      const sortList = removeSort
        ? filterList
        : filterList.sort((a, b) => +b.checked - +a.checked);

      formVariants.setValue(filter.name, sortList);
    },
    [filter.name, formVariants, removeSort]
  );

  const handleChangeSearchQuery = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    setCanClear(!!searchValue);

    if (isSearchByType) {
      const filterList = list.current.filter((item) =>
        item.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      setIsShowList(!!filterList.length);

      formVariants.reset({
        [filter.name]: filterList,
      });
    }
  };

  const clearSearchQuery = () => {
    setCanClear(false);

    const sortList = removeSort
      ? list.current
      : list.current.sort((a, b) => +b.checked - +a.checked);

    formVariants.setValue(filter.name, sortList);
    formSearch.reset();
    setIsShowList(true);
  };

  const changeSelectedVariant = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = String(e.target.id);

      if (value === 'selectAll' && isShowSelectAll) {
        setIsSelectAll(e.target.checked);

        onFilter({
          [filter.name]: list.current.map((variant) => ({
            ...variant,
            checked: e.target.checked,
          })),
        });

        return;
      }

      const dataList = formVariants.getValues(filter.name);

      list.current = list.current.reduce((updateList: TVariant[], itemList) => {
        let currentItem = itemList;
        dataList.forEach((itemForm) => {
          // TODO: Сделать нормально, сломано из-за добавления 8 в другой задаче
          if (itemForm.value === itemList.value) {
            currentItem = itemForm;
          } else if (
            isPhoneSearch &&
            itemForm.value.substring(1) === itemList.value
          ) {
            currentItem = { ...itemForm, value: itemForm.value.substring(1) };
          }
        });
        updateList.push(currentItem);
        return updateList;
      }, []);

      onFilter({
        [filter.name]: list.current,
      });
    },
    [filter.name, formVariants, isShowSelectAll, onFilter]
  );

  useEffect(() => {
    if (clear) {
      const newList = list.current.map((variant) => ({
        ...variant,
        checked: false,
      }));

      onFilter({ [filter.name]: newList });
      setCanClear(false);
      formSearch.reset();
      formVariants.reset({
        [filter.name]: newList,
      });
    }
  }, [clear, filter.name, formSearch, formVariants, onFilter]);

  const itemComponent = useCallback(
    (index: number, item: TVariant) => (
      <Box mb={1} key={item.value}>
        <Form.Item
          noMargin
          name={`${filter.name}.${index}.checked`}
          label={item.label}
          isControlLabel>
          <Checkbox onChange={changeSelectedVariant} />
        </Form.Item>
      </Box>
    ),
    [changeSelectedVariant, filter.name]
  );
  // TODO Sasha - isShowList?
  const showButtonSelectAll = isShowSelectAll && isShowList;

  return (
    <>
      {withSearch && (
        <Form
          form={formSearch}
          onSubmit={handleSearch}
          footer={false}
          className={styles.searchForm}>
          <Form.Item name="search">
            <OutlinedInput
              className={classNames(
                commonStyles.inputSearch,
                commonStyles.inputBorder,
                styles.noMargin
              )}
              fullWidth
              placeholder="Поиск"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeSearchQuery(e)
              }
              startAdornment={
                <IconButton
                  onClick={
                    mode === 'submit'
                      ? formSearch.handleSubmit(handleSearch)
                      : undefined
                  }
                  aria-label="search"
                  className={commonStyles.searchBtn}>
                  <SearchBtn />
                </IconButton>
              }
              endAdornment={
                <IconButton
                  onClick={clearSearchQuery}
                  className={classNames(
                    commonStyles.clearBtn,
                    !canClear && commonStyles.hidden
                  )}>
                  <CloseIcon />
                </IconButton>
              }
              onKeyPress={(e) => {
                if (e.charCode === 13 && mode === 'change') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Form>
      )}
      <div className={styles.variantContainer}>
        {isShowSearchErrors && !isShowList && canClear ? (
          <Typography color="error">
            Результаты не найдены, попробуйте ввести другой запрос
          </Typography>
        ) : null}

        <Form form={formVariants} footer={false}>
          {showButtonSelectAll ? (
            <Box>
              <FormControlLabel
                sx={{ mb: 1 }}
                checked={isSelectAll}
                value="selectAll"
                control={
                  <Checkbox onChange={changeSelectedVariant} id="selectAll" />
                }
                label="Выбрать все"
              />
            </Box>
          ) : null}

          {filter.variantList.length > 0 && (
            <div className={className && styles[className]}>
              <Form.List<TFormInput> name={filter.name} noMargin>
                {({ fields }) => (
                  <Virtuoso
                    style={{
                      height: showButtonSelectAll ? '122px' : '150px',
                    }}
                    data={fields}
                    itemContent={itemComponent}
                  />
                )}
              </Form.List>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};
