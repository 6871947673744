import { FC } from 'react';
import { FileImg } from 'src/assets/icons';
import { Button, Typography, SvgIcon } from 'src/components/ui';
import { DocumentEntityTypes, DocumentTypes, ReportPeriod } from 'src/models';
import { useLazyGetReportByPeriodQuery } from 'src/api/report';
import { errorNotification } from 'src/components/ui/toast-notification';
import { getErrorMessage } from 'src/store/utils';

interface IDownloadReportProps {
  name: string;
  reportPeriod: ReportPeriod;
}

export const DownloadReport: FC<IDownloadReportProps> = ({
  name,
  reportPeriod,
}) => {
  const [getReportByPeriod, { isLoading }] = useLazyGetReportByPeriodQuery();

  const handleClickGetReport = async () => {
    try {
      await getReportByPeriod({
        entityType: DocumentEntityTypes.REPORTS,
        reportPeriod,
        documentType: DocumentTypes.REPORT_ALL_CONTRACTORS,
      }).unwrap();
    } catch (e) {
      errorNotification({
        title: getErrorMessage(e, 'Произошла ошибка при загрузке файла'),
      });
    }
  };

  return (
    <Button
      loading={isLoading}
      variant="text"
      startIcon={<SvgIcon icon={FileImg} width="16px" height="16px" />}
      onClick={handleClickGetReport}>
      <Typography ml="8px">{name}</Typography>
    </Button>
  );
};
