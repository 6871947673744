import { useEffect, useRef, useState } from 'react';

export const useTimer = () => {
  const [timerValue, setTimerValue] = useState(0);
  const timeOutID = useRef<ReturnType<typeof setTimeout>>();

  const stopTimer = () => {
    if (timeOutID.current) {
      clearTimeout(timeOutID.current);
    }
  };

  useEffect(() => {
    if (timerValue) {
      timeOutID.current = setTimeout(() => {
        setTimerValue((prev) => {
          if (prev > 0) {
            return prev - 1;
          }
          return prev;
        });
      }, 1000);
    }
    return () => stopTimer();
  }, [timerValue]);

  return [timerValue, setTimerValue, stopTimer] as const;
};
