import { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  Typography,
  successNotification,
} from 'src/components/ui';
import { CheckboxForm } from 'src/components/ui/filters/components/filters-popover/components';
import { TConfigFilter, TVariant } from 'src/components/ui/filters/types';
import { TSearchFilters } from 'src/components/ui/table/types';
import {
  useLazyGetDocumentsShareLinkQuery,
  useShareDocumentsByEmailMutation,
} from 'src/api/document';
import { DocumentEntityTypes, IOrganizationsNamesLite } from 'src/models';
import { getErrorMessage } from 'src/store/utils';
import { useGetContractorNamesLiteQuery } from 'src/api/company';

interface IModalShareRequestProps {
  open: boolean;
  toggleShow: () => void;
  entityId: number;
  entityType: DocumentEntityTypes;
  shareByEmail?: boolean;
}

const getVariantList = (data: IOrganizationsNamesLite[]) =>
  data.map((element) => ({
    value: String(element.organizationId),
    label: element.organizationName,
    checked: false,
  }));

export const ModalShareRequest: FC<IModalShareRequestProps> = ({
  open,
  toggleShow,
  entityId,
  entityType,
  shareByEmail = false,
}) => {
  const [
    getLinkOnDocuments,
    { isLoading: isLoadingGetLink, error: errorGetLink },
  ] = useLazyGetDocumentsShareLinkQuery();

  const [
    sendLinkOnEmail,
    { isLoading: isLoadingSendLinkOnEmail, error: errorSendLinkOnEmail },
  ] = useShareDocumentsByEmailMutation();

  const { data } = useGetContractorNamesLiteQuery(undefined, {
    skip: !shareByEmail,
  });

  const [filtersConfig, setFilters] = useState<TConfigFilter>({
    name: 'organizationNames',
    label: 'Компания',
    withSearch: true,
    render: () => null,
    variantList: [],
  });

  const selectedOrganizations = filtersConfig.variantList.filter(
    (element) => element.checked
  );

  const handleClickCopy = async () => {
    try {
      const result = await getLinkOnDocuments({
        entityId,
        entityType,
      }).unwrap();

      await navigator.clipboard.writeText(result.shareDocumentsLink);

      successNotification({ title: 'Ссылка была скопирована' });
    } catch {
      // ignore
    }
  };

  const handleClickSend = async () => {
    const selectedOrganizationIds = selectedOrganizations.map((element) =>
      Number(element.value)
    );

    try {
      await sendLinkOnEmail({
        entityId,
        entityType,
        organizationIds: selectedOrganizationIds,
      }).unwrap();

      successNotification({
        title: 'Письмо с документами запроса было отправлено',
      });

      toggleShow();
    } catch {
      // ignore
    }
  };

  const handleFilter = useCallback(
    (selectedFilters: TSearchFilters<TVariant[]>) => {
      const { organizationNames } = selectedFilters;

      if (!organizationNames.length) return;

      setFilters((prevState) => ({
        ...prevState,
        variantList: selectedFilters.organizationNames,
      }));
    },
    []
  );

  useEffect(() => {
    if (!data) return;

    setFilters((prevState) => ({
      ...prevState,
      variantList: getVariantList(data),
    }));
  }, [data]);

  return (
    <Drawer open={open} close={toggleShow} title="Поделиться запросом">
      <Box>
        <Typography variant="h3">Копировать ссылку на файлы</Typography>
        <Typography variant="h4" mt="12px">
          При клике по кнопке ссылка будет скопирована в буфер обмена
        </Typography>

        <Button
          sx={{ marginTop: '16px' }}
          onClick={handleClickCopy}
          loading={isLoadingGetLink}>
          Скопировать
        </Button>
      </Box>

      {shareByEmail && (
        <Box mt="32px">
          <Typography variant="h3">Отправить файлы по почте</Typography>
          <Typography variant="h4" mt="12px" mb="16px">
            Выберите исполнителей, которым будет отправлено письмо с документами
            по запросу
          </Typography>

          <CheckboxForm
            mode="change"
            filter={filtersConfig}
            onFilter={handleFilter}
            isShowSearchErrors
            isShowSelectAll
            clear
            isSearchByType
            removeSort
          />

          <Button
            sx={{ marginTop: '25px' }}
            disabled={!selectedOrganizations.length}
            loading={isLoadingSendLinkOnEmail}
            onClick={handleClickSend}>
            Отправить
          </Button>
        </Box>
      )}

      <Box mt="12px">
        {errorGetLink && (
          <Typography color="error">
            {getErrorMessage(errorGetLink, 'Ошибка при получении ссылки')}
          </Typography>
        )}

        {errorSendLinkOnEmail && (
          <Typography color="error" mt="12px">
            {getErrorMessage(
              errorSendLinkOnEmail,
              'Ошибка при отправке файлов на почту'
            )}
          </Typography>
        )}
      </Box>
    </Drawer>
  );
};
