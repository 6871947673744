import { differenceInSeconds } from 'date-fns';
import { plural } from 'src/utils';

export const formatTimeAgo = (pastDate: string) => {
  const now = new Date();
  const diffInSeconds = differenceInSeconds(now, new Date(pastDate));

  if (diffInSeconds < 60) {
    return `${diffInSeconds} секунд назад`;
  }

  if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);

    return `${minutes} ${plural(minutes, 'минуту', 'минуты', 'минут')} назад`;
  }

  if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);

    return `${hours} ${plural(hours, 'час', 'часа', 'часов')} назад`;
  }

  if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);

    return `${days} ${plural(days, 'день', 'дня', 'дней')} назад`;
  }

  if (diffInSeconds < 31536000) {
    const months = Math.floor(diffInSeconds / 2592000);

    return `${months} ${plural(months, 'месяц', 'месяца', 'месяцев')} назад`;
  }

  const years = Math.floor(diffInSeconds / 31536000);

  return `${years} ${plural(years, 'год', 'года', 'лет')} назад`;
};
