import { debounce } from '@mui/material';
import { ChangeEvent, FC, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { InfoHelpIcon, SearchBtn } from 'src/assets/icons';
import {
  Button,
  Form,
  IconButton,
  OutlinedInput,
  Tooltip,
} from 'src/components/ui';
import { TTechnicalOpportunity } from 'src/models';
import styles from './search-tags.module.scss';

interface ISearchTags {
  form: UseFormReturn<any, any>;
  technicalOpportunitiesList: TTechnicalOpportunity[] | undefined;
  defaultTechnicalOpportunities?: TTechnicalOpportunity[];
}

export const SearchTags: FC<ISearchTags> = ({
  form,
  technicalOpportunitiesList,
  defaultTechnicalOpportunities,
}) => {
  const writeForm = useMemo(
    () =>
      debounce((searchTag: string) => {
        if (!technicalOpportunitiesList) return;

        if (!searchTag) {
          const resultValue = technicalOpportunitiesList.map((item) => ({
            ...item,
            hidden: false,
            subcategories: item.subcategories.map((subcategory) => ({
              ...subcategory,
              hidden: false,
            })),
          }));
          form.setValue('technicalOpportunitiesCategories', resultValue);
          return;
        }

        const resultValue = technicalOpportunitiesList
          .map((item) => {
            const currentSubcategories = item.subcategories.map(
              (subcategory) => {
                if (
                  subcategory.name
                    .toLowerCase()
                    .includes(searchTag.toLowerCase())
                ) {
                  return { ...subcategory, hidden: false };
                }
                return { ...subcategory, hidden: true };
              }
            );
            return {
              ...item,
              subcategories: currentSubcategories,
            };
          })
          .map((item) => {
            if (item.name.toLowerCase().includes(searchTag.toLowerCase())) {
              return { ...item, hidden: false };
            }

            if (item.subcategories.find((element) => !element.hidden)) {
              return { ...item, hidden: false };
            }
            return { ...item, hidden: true };
          });

        form.setValue('technicalOpportunitiesCategories', resultValue);
      }, 400),
    [technicalOpportunitiesList]
  );

  const handleChangeSearchTag = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTag = e.target.value;

    writeForm(searchTag);
  };

  const handleClickClearTags = () => {
    const clearList = technicalOpportunitiesList?.map((item) => ({
      ...item,
      hidden: false,
      checked: false,
      subcategories: item.subcategories.map((subcategory) => ({
        ...subcategory,
        hidden: false,
        checked: false,
      })),
    }));
    form.setValue('technicalOpportunitiesCategories', clearList, {
      shouldDirty: true,
    });
  };

  const handleClickApplicationTags = () => {
    if (!technicalOpportunitiesList) return;

    const result = technicalOpportunitiesList.map((item) => {
      const currentInstallationTags = defaultTechnicalOpportunities?.find(
        (element) => element.id === item.id
      );

      if (currentInstallationTags) {
        return {
          ...item,
          checked: true,
          subcategories: item.subcategories.map((subcategory) => {
            if (
              currentInstallationTags.subcategories.find(
                (category) => category.id === subcategory.id
              )
            ) {
              return { ...subcategory, checked: true };
            }

            return subcategory;
          }),
        };
      }

      return {
        ...item,
        checked: false,
        subcategories: item.subcategories.map((subcategory) => ({
          ...subcategory,
          checked: false,
        })),
      };
    });

    form.setValue('technicalOpportunitiesCategories', result, {
      shouldDirty: true,
    });
  };

  return (
    <div className={styles.container}>
      <Form.Item noMargin name="searchTag" required>
        <OutlinedInput
          onChange={handleChangeSearchTag}
          sx={{ gap: '10px' }}
          placeholder="Поиск по тегу"
          startAdornment={
            <IconButton>
              <SearchBtn />
            </IconButton>
          }
        />
      </Form.Item>

      <div className={styles.containerBtn}>
        {defaultTechnicalOpportunities && (
          <Button
            color="secondary"
            onClick={handleClickApplicationTags}
            endIcon={
              <Tooltip title="Будут восстановлены теги, выбранные ранее в запросе">
                <InfoHelpIcon />
              </Tooltip>
            }>
            Установить теги заявки
          </Button>
        )}

        <Button color="error" onClick={handleClickClearTags}>
          Очистить все
        </Button>
      </div>
    </div>
  );
};
