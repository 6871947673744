import { Components } from '@mui/material/styles/components';
import vars from '../scss/_vars.module.scss';

export const MuiDialogContent: Components['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      fontSize: '16px',
      lineHeight: '20px',
      whiteSpace: 'pre-line',
      padding: '0 28px',
      marginBottom: '12px',

      '&::-webkit-scrollbar': {
        width: vars.scrollWidth,
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: vars.scrollBorderRadius,
        backgroundColor: vars.scrollBackgroundColor,
      },
    },
  },
};
