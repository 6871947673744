import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const Extra = lazy(
  () =>
    import(
      /* webpackChunkName: 'Extra' */
      /* webpackMode: "lazy" */
      `./extra`
    )
);

export const LazyExtra: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <Extra />
  </Suspense>
);
