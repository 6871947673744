export type TCargoParameters =
  | {
      type: DeliveryType.TRUCK_FTL;
      params: {
        tentCount: number;
        hardBoard: boolean;
        detachableBeam: boolean;
        removableStand: boolean;
      };
    }
  | {
      type: DeliveryType.CARGO_SINGLE;
      params:
        | {
            length: number;
            width: number;
            height: number;
            volume: number;
            weight: number;
            places: number;
          }
        | {
            maxLength: number;
            maxWidth: number;
            maxHeight: number;
            maxWeight: number;
            totalVolume: number;
            totalWeight: number;
            places: number;
            isPlaceEqual: boolean;
          }
        | {};
    };

export type TCargoParametersFull = {
  deliveryType?: DeliveryType;

  tentCount?: number;
  hardBoard?: boolean;
  detachableBeam?: boolean;
  removableStand?: boolean;

  length?: number;
  width?: number;
  height?: number;
  weight?: number;
  volume?: number;
  singlePlace?: boolean;

  maxLength?: number;
  maxWidth?: number;
  maxHeight?: number;
  maxWeight?: number;
  places?: number;
  totalWeight?: number;
  totalVolume?: number;
  isPlaceEqual?: boolean;
};

export interface ICargoDescriptions {
  deliveryAddress: string;
  withLogistics: boolean;
  parameters: TCargoParametersFull;
}

export enum DeliveryType {
  CARGO_SINGLE = 'CARGO_SINGLE',
  TRUCK_FTL = 'TRUCK_FTL',
}
