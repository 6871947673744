import { Components } from '@mui/material/styles/components';

export const MuiDialogTitle: Components['MuiDialogTitle'] = {
  defaultProps: {
    component: 'div',
  },
  styleOverrides: {
    root: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: 'normal',
      padding: '40px 28px 20px',
      marginRight: '25px',
    },
  },
};
