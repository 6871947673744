import { Form, Grid, InfoRow, OutlinedInput } from 'src/components/ui';
import { ViewCargoParameters } from 'src/components/widgets';

export const Logistics = () => (
  <Grid container columnSpacing="24px" mb="16px">
    <Grid item xs={6}>
      <Form.Item
        viewMode
        name="logistics.deliveryAddress"
        renderView={(value) => (
          <InfoRow variant="secondary" label="Адрес доставки:" value={value} />
        )}>
        <OutlinedInput />
      </Form.Item>

      <Form.Item
        viewMode
        name="logistics.shippingAddress"
        renderView={(value) => (
          <InfoRow variant="secondary" label="Адрес отгрузки:" value={value} />
        )}>
        <OutlinedInput />
      </Form.Item>
    </Grid>

    <Grid item xs={6}>
      <Form.Item
        viewMode
        name="logistics.cargoDescription"
        renderView={(value) => (
          <InfoRow
            variant="secondary"
            label="Параметры груза:"
            value={
              <ViewCargoParameters
                emptyText="Нет параметров"
                cargoParameters={value}
              />
            }
          />
        )}>
        <OutlinedInput />
      </Form.Item>
    </Grid>
  </Grid>
);
