import { useCallback, useEffect, useRef } from 'react';
import { AuthService } from 'src/services';
import { useAppSelector } from 'src/store';
import { selectIsAuth } from 'src/store/auth';
import { useUpdateActivityMutation } from 'src/api/user';
import { getErrorMessage } from 'src/store/utils';

const ACTIVITY_TIMEOUT = 60 * 1000;

export const useMouseActivityMonitor = () => {
  const isAuth = useAppSelector(selectIsAuth);

  const isEventListenerExist = useRef(false);
  const isThrottleInProgress = useRef(false);

  const [updateActivity] = useUpdateActivityMutation();

  const onMouseMove = useCallback(() => {
    if (AuthService.userId && !isThrottleInProgress.current) {
      isThrottleInProgress.current = true;

      setTimeout(async () => {
        try {
          await updateActivity().unwrap();

          isThrottleInProgress.current = false;
        } catch (e) {
          const error = getErrorMessage(
            e,
            'Произошла ошибка при отправки данных в сервис мониторинга активности пользователей'
          );

          console.log(error);
        }
      }, ACTIVITY_TIMEOUT);
    }
  }, [updateActivity]);

  useEffect(() => {
    if (isEventListenerExist.current) {
      document.removeEventListener('mousemove', onMouseMove);

      isEventListenerExist.current = false;

      return undefined;
    }

    if (isAuth) {
      document.addEventListener('mousemove', onMouseMove);

      isEventListenerExist.current = true;
    }

    return () => {
      if (isEventListenerExist.current) {
        document.removeEventListener('mousemove', onMouseMove);

        isEventListenerExist.current = false;
      }
    };
  }, [isAuth, onMouseMove]);
};
