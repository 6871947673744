import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const SigningBasisDetails = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminSignatory' */
      /* webpackMode: "lazy" */
      `./signing-basis-details`
    )
);

export const LazyAdminSigningBasisDetails: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <SigningBasisDetails />
  </Suspense>
);
