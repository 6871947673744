import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { ModalSelectClassifierTags as ModalSelectClassifierTagsCommon } from 'src/components/widgets/index';
import {
  TTechnicalOpportunitiesCategories,
  TTechnicalOpportunity,
} from 'src/models';
import { ETagStatuses } from 'src/configs/tag-status';

type TModalSelectClassifierTags = {
  open: boolean;
  toggleOpen: () => void;
  onChangeTechnicalOpportunities: (tags: TTechnicalOpportunity[]) => void;
  defaultTags: TTechnicalOpportunitiesCategories;
};

export const ModalSelectClassifierTags: FC<TModalSelectClassifierTags> = ({
  open,
  toggleOpen,
  onChangeTechnicalOpportunities,
  defaultTags,
}) => {
  const form = useForm({
    defaultValues: {
      technicalOpportunitiesCategories: [
        ...defaultTags.map((item) => ({
          id: item.categoryId,
          name: '',
          status: ETagStatuses.ACTIVE,
          checked: true,
          subcategories: item.subcategoryIds.map((subcategoryId) => ({
            id: subcategoryId,
            checked: true,
            name: '',
            status: ETagStatuses.ACTIVE,
          })),
        })),
      ],
      searchTag: '',
    },
  });

  const handleSubmitForm = () => {
    const formData = form.getValues('technicalOpportunitiesCategories');
    onChangeTechnicalOpportunities(formData);
  };

  return (
    <ModalSelectClassifierTagsCommon
      form={form}
      toggleShowModalSelectTags={toggleOpen}
      isShowModal={open}
      handleSubmitForm={handleSubmitForm}
    />
  );
};
