import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { businessRequestApi } from 'src/api';
import { COUNT_PER_PAGE } from 'src/configs';
import {
  ICustomerRequestKanban,
  ICustomerRequestKanbanCostIndicatorStatistics,
  ICustomerRequestKanbanParams,
} from 'src/models';
import { defaultSort } from './requests-manager-slice';

interface IInitialState {
  requests: ICustomerRequestKanban;
  requestParams: ICustomerRequestKanbanParams;
  pageCount: number;
  costIndicatorStatistics: {
    [key: number]: ICustomerRequestKanbanCostIndicatorStatistics;
  } | null;
  isInitialState: boolean;
  isInitialSortSelected: boolean;
  filterTagsPositions: Record<string, number>;
}

const initialState: IInitialState = {
  requests: {
    countByStatus: null,
    items: [],
  },
  requestParams: {
    filter: {
      statusGroup: 'ACTIVE',
      technicalOpportunitiesCategories: [],
    },

    sort: {
      field: defaultSort.field,
      order: defaultSort.direction,
    },

    searchQueries: [],

    paging: {
      vertical: {
        page: 1,
        limit: COUNT_PER_PAGE.REQUEST,
      },
    },
  },
  pageCount: 0,
  costIndicatorStatistics: null,
  isInitialState: true,
  isInitialSortSelected: false,
  filterTagsPositions: {},
};

const getCustomerRequestsItems = (items: ICustomerRequestKanban['items']) =>
  items.reduce(
    (acc, element) => ({
      ...acc,
      [element.statusCode]: element,
    }),
    {}
  ) as {
    [key: string]: ICustomerRequestKanban['items'][number];
  };

const requestsCustomerSlice = createSlice({
  name: 'requestsCustomer',
  initialState,

  extraReducers: (builder) => {
    builder.addMatcher(
      businessRequestApi.endpoints.getCustomerCostIndicatorStatistics
        .matchFulfilled,
      (state, { payload }) => {
        const newCostIndicatorStatistics = payload.reduce(
          (acc, element) => ({
            ...acc,
            [element.businessRequestId]: element,
          }),
          {}
        );

        state.costIndicatorStatistics = {
          ...state.costIndicatorStatistics,
          ...newCostIndicatorStatistics,
        };
      }
    );
  },

  reducers: {
    setCustomerCurrentData(
      state,
      {
        payload: { requests, requestParams, filterTagsPositions },
      }: PayloadAction<{
        requests: ICustomerRequestKanban;
        requestParams: ICustomerRequestKanbanParams;
        filterTagsPositions: Record<string, number>;
      }>
    ) {
      state.requestParams = requestParams;
      state.filterTagsPositions = filterTagsPositions;
      state.isInitialState = false;

      const isFirstPage = requestParams.paging.vertical.page === 1;

      if (
        isFirstPage ||
        !requests.items.length ||
        !state.requests.items.length
      ) {
        state.requests = requests;
      } else {
        const oldCustomerRequests = getCustomerRequestsItems(
          state.requests.items
        );

        state.requests = {
          ...requests,
          countByStatus: requests.countByStatus || state.requests.countByStatus,

          items: requests.items.map((item) => {
            const customerRequestsItemByStatus =
              oldCustomerRequests[item.statusCode];

            return customerRequestsItemByStatus
              ? {
                  ...item,
                  businessRequestsCountByStatus:
                    customerRequestsItemByStatus.businessRequestsCountByStatus,
                  businessRequests: [
                    ...customerRequestsItemByStatus.businessRequests,
                    ...item.businessRequests,
                  ],
                }
              : item;
          }),
        };
      }

      if (isFirstPage) {
        const maxTotalCountColumn = Math.max(
          ...state.requests.items.map(
            (element) => element.businessRequestsCountByStatus || 0
          )
        );

        state.pageCount = Math.ceil(
          maxTotalCountColumn / COUNT_PER_PAGE.REQUEST
        );

        state.costIndicatorStatistics = null;
      }
    },

    setCustomerIsInitialSortSelected(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isInitialSortSelected = payload;
    },
  },
});

export const { setCustomerCurrentData, setCustomerIsInitialSortSelected } =
  requestsCustomerSlice.actions;

export const requestsCustomerReducer = requestsCustomerSlice.reducer;
