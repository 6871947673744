import { FC, ReactNode } from 'react';
import { Box, BoxProps } from 'src/components/ui';
import classNames from 'classnames';
import styles from './circle-counter.module.scss';

interface ICircleCounterProps extends BoxProps {
  children?: ReactNode;
}

export const CircleCounter: FC<ICircleCounterProps> = ({
  children,
  className,
  ...props
}) => (
  <Box {...props} className={classNames(styles.counter, className)}>
    {children}
  </Box>
);
