import { FC, useRef } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from 'src/components/ui';
import styles from './intellectual-activity-agreement-dialog.module.scss';

export const IntellectualActivityAgreementDialog: FC<{
  open: boolean;
  onClose: (checked?: boolean) => void;
  checked?: boolean;
  hideActions?: boolean;
}> = ({ open, onClose, checked, hideActions }) => {
  const checkbox = useRef<HTMLInputElement>(null);

  return (
    <Dialog
      open={open}
      close={() => onClose(checkbox.current?.checked)}
      PaperProps={{
        sx: {
          maxWidth: '590px',
          maxHeight: { xs: '100vh', sm: '670px' },
          boxShadow: 'none',
        },
      }}>
      <DialogTitle>
        СОГЛАШЕНИЕ О ПРАВАХ НА РЕЗУЛЬТАТЫ ИНТЕЛЛЕКТУАЛЬНОЙ ДЕЯТЕЛЬНОСТИ
      </DialogTitle>
      <DialogContent className={styles.content}>
        {
          'Направляя в наш адрес информацию, документацию, в том числе, но не ограничиваясь чертежи, эскизы, конструкторскую документацию и иные результаты интеллектуальной деятельности (далее – Информация) Отправитель (от имени которого направляется Информация) подтверждает (заверяет), что направляемая Информация принадлежит Отправителю или получена Отправителем на законных основаниях и Отправитель согласен на использование направляемой Информации ООО «Северсталь Цифровые Решения», включая передачу третьим лицам, а также подтверждает отсутствие ограничений на такое использование Информации, включая передачу третьим лицам. \n\n'
        }

        {
          'Отправитель не предъявляет ООО «Северсталь Цифровые Решения» претензий в связи с использованием Информации. \n\n'
        }

        {
          'В случае нарушения указанных выше подтверждений (заверений) Отправитель компенсирует ООО «Северсталь Цифровые решения» все убытки, причиненные таким нарушением, а также за свой счет обязуется урегулировать соответствующие претензии, предъявляемые в адрес ООО «Северсталь Цифровые Решения» третьими лицами.  Указанные убытки должны быть возмещены Отправителем, а претензионные требования третьих лиц урегулированы, в течение 20 рабочих дней с момента получения Отправителем от ООО «Северсталь Цифровые Решения» соответствующего требования. \n\n'
        }
      </DialogContent>
      {!hideActions && (
        <DialogActions className={styles.footer}>
          <FormControlLabel
            label="Я согласен с положениями Соглашения о правах на результаты интеллектуальной деятельности"
            control={
              <Checkbox inputRef={checkbox} defaultChecked={Boolean(checked)} />
            }
          />
        </DialogActions>
      )}
    </Dialog>
  );
};
