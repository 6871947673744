import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const AllCustomerRequests = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminBankRequisites' */
      /* webpackMode: "lazy" */
      `./customer-all-requests`
    )
);

export const LazyCustomerAllRequests: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <AllCustomerRequests />
  </Suspense>
);
