import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const CompanyBankRequisites = lazy(
  () =>
    import(
      /* webpackChunkName: 'CompanyBankRequisites' */
      /* webpackMode: "lazy" */
      `./bank-requisites`
    )
);

export const LazyCompanyBankRequisites: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <CompanyBankRequisites />
  </Suspense>
);
