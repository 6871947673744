import { FC, lazy, Suspense } from 'react';
import { PageLoader } from 'src/components/ui';

const CustomerCommunication = lazy(
  () =>
    import(
      /* webpackChunkName: 'CustomerCommunication' */
      /* webpackMode: "lazy" */
      `./communication`
    )
);

export const LazyCustomerCommunication: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <CustomerCommunication />
  </Suspense>
);
