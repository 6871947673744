import { Stack } from '@mui/material';
import classNames from 'classnames';
import { EventHandler, FC, useEffect, useState } from 'react';
import {
  ArrowLong,
  Check,
  ChipSearch,
  CircleCross,
  SmallCross,
} from 'src/assets/icons';
import {
  Chip as ChipMui,
  IconButton,
  OutlinedInput,
  Tooltip,
  Typography,
} from 'src/components/ui/index';
import styles from 'src/components/ui/new-filters/filters.module.scss';
import { DIRECTION, FilterTypes, TChip } from '../../types';

type TProps = {
  item: TChip;
  onDeleteChip: (x: TChip) => EventHandler<any>;
  isDisabled?: boolean;
  whiteVariant?: boolean;
};

const MAX_CHAR = 25;

export const Chip: FC<TProps> = ({
  item,
  onDeleteChip,
  isDisabled,
  whiteVariant,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [searchChipValue, setSearchChipValue] = useState(item.label);

  useEffect(() => {
    if (item.editChipRefValue !== item.label) {
      setSearchChipValue(item.label);
      setIsEdit(false);
    }
  }, [item.editChipRefValue]);

  // Нужно для обнуления ссылки при закрытии tooltip
  useEffect(
    () => () => {
      item.updateEditChipRefValue?.(null);
    },
    []
  );

  if (isEdit) {
    return (
      <ChipMui
        className={classNames(
          styles.chip,
          styles.chipEdit,
          !whiteVariant && styles.chipWhiteVariant
        )}
        label={
          <Stack direction="row" gap="4px" alignItems="center">
            <OutlinedInput
              autoFocus
              className={styles.chipSearch}
              placeholder="Значение"
              value={searchChipValue}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  item.updateEditChipRefValue?.(null);
                  setIsEdit(false);
                  e.preventDefault();
                }

                if (e.key === 'Enter') {
                  item.onSearchChipEdit?.(item.label, searchChipValue);
                  setIsEdit(false);
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                setSearchChipValue(e.target.value);
              }}
            />
            <IconButton
              color="primary"
              size="extraSmall"
              onClick={() => {
                item.onSearchChipEdit?.(item.label, searchChipValue);
                setIsEdit(false);
              }}>
              <Check />
            </IconButton>
            <IconButton
              color="white"
              size="extraSmall"
              onClick={() => {
                item.updateEditChipRefValue?.(null);
                setIsEdit(false);
              }}>
              <SmallCross />
            </IconButton>
          </Stack>
        }
      />
    );
  }

  return (
    <ChipMui
      onClick={
        item.onSearchChipEdit
          ? () => {
              if (item.type === FilterTypes.SEARCH) {
                item.updateEditChipRefValue?.(item.label);
                setIsEdit(true);
              }
            }
          : undefined
      }
      avatar={item.type === FilterTypes.SEARCH ? <ChipSearch /> : undefined}
      onDelete={onDeleteChip(item)}
      className={classNames(
        styles.chip,
        !whiteVariant && styles.chipWhiteVariant
      )}
      disabled={!!isDisabled}
      label={
        <Tooltip
          title={item.label}
          disableHoverListener={item.label.length <= MAX_CHAR}>
          <Typography noWrap>
            {item.label.length > MAX_CHAR
              ? `${item.label.slice(0, MAX_CHAR)}...`
              : item.label}
            {item.direction && (
              <div
                className={classNames(
                  styles.arrow,
                  item.direction === DIRECTION.DESC && styles.desc
                )}>
                <ArrowLong />
              </div>
            )}
          </Typography>
        </Tooltip>
      }
      deleteIcon={
        <IconButton>
          <CircleCross />
        </IconButton>
      }
    />
  );
};
