import { FIELD_LENGTH } from 'src/constants';
import { maxLength } from './max-length';
import { minLength } from './min-length';
import { number } from './number';

export const isOGRN = (ogrn: string) => {
  if (!ogrn) {
    return true;
  }
  const numberRule = number(ogrn);
  if (numberRule !== true) {
    return numberRule;
  }
  if (ogrn.startsWith('1') || ogrn.startsWith('5')) {
    if (ogrn.length < FIELD_LENGTH.ogrn[0]) {
      return minLength(FIELD_LENGTH.ogrn[0]).message;
    }
    if (ogrn.length > FIELD_LENGTH.ogrn[0]) {
      return maxLength(FIELD_LENGTH.ogrn[0]).message;
    }
  } else if (ogrn.startsWith('3')) {
    if (ogrn.length < FIELD_LENGTH.ogrn[1]) {
      return minLength(FIELD_LENGTH.ogrn[1]).message;
    }
    if (ogrn.length > FIELD_LENGTH.ogrn[1]) {
      return maxLength(FIELD_LENGTH.ogrn[1]).message;
    }
  }
  if (ogrn.length < FIELD_LENGTH.ogrn[0]) {
    return minLength(FIELD_LENGTH.ogrn[0]).message;
  }
  if (ogrn.length > FIELD_LENGTH.ogrn[1]) {
    return maxLength(FIELD_LENGTH.ogrn[1]).message;
  }
  return true;
};
