import { format } from 'date-fns';
import { ActivityList } from 'src/components/pages/business-requests/components';
import { Stack, Typography } from 'src/components/ui';
import styles from 'src/components/widgets/all-company-requests-list/components/request-info-column/request-info-column.module.scss';
import { KamStatusCode, TCompanyBusinessRequest } from 'src/models/bid';
import { AuthService } from 'src/services';
import { Link } from 'react-router-dom';
import { theme } from 'src/theme';
import { getUserLastNameWithInitials } from 'src/utils';
import { getFormattedBudget } from 'src/utils/get-formatted-budget';

export const RequestInfoColumn = ({
  item,
}: {
  item: TCompanyBusinessRequest;
}) => {
  const getBidStatusColor = () => {
    if (
      [KamStatusCode.REQUEST_REFUSAL].includes(item.statusForKamValue) ||
      item.type === 'EXPECTED'
    ) {
      return '#FF9D9D';
    }

    if (
      [
        KamStatusCode.NEW_REQUEST,
        KamStatusCode.QUALIFIED,
        KamStatusCode.COMMERCIAL_QUALIFICATION,
        KamStatusCode.TECHNICAL_QUALIFICATION,
        KamStatusCode.SENT_TO_CONTRACTORS,
      ].includes(item.statusForKamValue)
    ) {
      return '#FCECB2';
    }

    return '#8FEABF';
  };

  return (
    <Link
      to={
        AuthService.isSpecificRole()
          ? `/all-requests/${item.businessRequestId}`
          : `/requests/${item.businessRequestId}`
      }>
      <Stack direction="column" gap="4px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography color="text.primary" fontSize="12px">{`№${
            item.number
          } от ${format(new Date(item.createdAt), 'dd.MM.yyyy')}`}</Typography>

          <Stack
            sx={{
              borderRadius: '999px',
              padding: '4px 8px',
              backgroundColor: getBidStatusColor(),
              height: '24px',
            }}
            flexDirection="row"
            gap="4px"
            alignItems="center">
            <Typography fontSize="11px" color="text.primary">
              {item.type === 'EXPECTED' &&
              item.statusForKamValue !== KamStatusCode.REQUEST_REFUSAL
                ? 'Перспектива'
                : item.statusForKam}
            </Typography>
          </Stack>
        </Stack>

        <Typography
          fontWeight={500}
          fontSize="12px"
          color={theme.palette.primary.dark}>
          {item.name}
        </Typography>

        <Stack direction="row" alignItems="center" gap="12px">
          <Typography
            fontWeight={500}
            fontSize="12px"
            lineHeight="12px"
            color="text.primary">
            {getFormattedBudget(item.budget)}
          </Typography>
          <Typography fontSize="10px" lineHeight="10px" color="text.secondary">
            Кам: {getUserLastNameWithInitials(item.fullName)}
          </Typography>
        </Stack>

        {item.tagClassifierFistLevel.length !== 0 && (
          <ActivityList
            activityName={item.tagClassifierFistLevel}
            containerClassName={styles.tag}
          />
        )}
      </Stack>
    </Link>
  );
};
