import { FC, useEffect, useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import {
  Typography,
  Box,
  Form,
  InfoRow,
  DatePicker,
  NumberInput,
} from 'src/components/ui';
import { getServerDate } from 'src/utils';
import {
  date,
  maxLength,
  positiveNumber,
  required,
} from 'src/utils/form-rules';
import { FIELD_LENGTH } from 'src/constants';
import { IManagerCustomerCommercialOfferForm } from 'src/components/pages/business-request-detail/manager/commercial-offers/components/customer';
import IconButton from '@mui/material/IconButton';
import { History } from 'src/assets/icons';
import { IChangeLogs, IChangeLogsHistory } from 'src/models/changelogs';
import { ChangeLogsWindow } from 'src/components/widgets/change-logs-window';
import { IComponentsProp } from '../../types';

interface IDeadlinesProps extends IComponentsProp {
  supplyDurationHistory?: IChangeLogs;
  dueDateAtHistory?: IChangeLogs;
}

export const Deadlines: FC<IDeadlinesProps> = ({
  isEditing,
  mode,
  checkRequired = true,
  supplyDurationHistory,
  dueDateAtHistory,
}) => {
  const form = useFormContext<IManagerCustomerCommercialOfferForm>();

  const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);
  const [changeModalTitle, setChangeModalTitle] = useState<string>('');
  const [modalData, setModalData] = useState<IChangeLogsHistory[]>([]);
  const toggleChangeModalOpen = () => {
    setIsChangeModalOpen(!isChangeModalOpen);
  };

  const dueDateAt = form.watch('dueDateAt');

  useEffect(() => {
    const isTouched = form.formState.dirtyFields.processingDueDateAt;

    // триггерит валидацию processingDueDateAt при изменении dueDateAt
    if (form.formState.isDirty && isTouched) {
      form.trigger('processingDueDateAt');
    }
  }, [dueDateAt]);

  return (
    <Box>
      <Typography variant="h3" mb="19px">
        Сроки
      </Typography>

      <Box display="flex" gap="24px">
        <Box flexBasis="100%">
          <Form.Item
            required
            viewMode={!isEditing}
            name="dueDateAt"
            label="Действие КП"
            rules={{
              required: checkRequired ? required : undefined,
              validate: {
                date,
                checkDate: (value) =>
                  !value ||
                  differenceInCalendarDays(value, new Date()) >= 0 ||
                  'Введите дату не ранее текущей',
              },
            }}
            renderView={(value) => (
              <InfoRow
                label="Действие КП"
                value={
                  <>
                    <Typography fontSize="16px">
                      {value
                        ? getServerDate(value, 'dd.MM.yyyy')
                        : 'Дата не указана'}
                    </Typography>
                    {mode === 'contractor' &&
                      !!dueDateAtHistory?.history.length && (
                        <IconButton
                          onClick={(_) => {
                            toggleChangeModalOpen();
                            setChangeModalTitle('Действие КП');
                            setModalData(dueDateAtHistory.history);
                          }}>
                          <History />
                        </IconButton>
                      )}
                  </>
                }
              />
            )}>
            <DatePicker minDate={new Date()} />
          </Form.Item>

          <Form.Item
            required
            viewMode={!isEditing}
            name="dueDateDuration"
            label="Действие КП, в к.д."
            rules={{
              validate: {
                positiveNumber: positiveNumber(),
              },
            }}>
            <NumberInput decimalScale={0} placeholder="0" />
          </Form.Item>

          {mode !== 'contractor' && (
            <Form.Item
              required
              viewMode={!isEditing}
              name="processingDueDateAt"
              label="Рассмотрение КП"
              rules={{
                required: checkRequired ? required : undefined,
                validate: {
                  date,
                  checkDate: (value) =>
                    !value ||
                    differenceInCalendarDays(value, new Date()) >= 0 ||
                    (dueDateAt
                      ? differenceInCalendarDays(value, new Date(dueDateAt)) <=
                        0
                      : true) ||
                    'Введите дату не ранее текущей',

                  compareDates: (value) =>
                    !value ||
                    !dueDateAt ||
                    differenceInCalendarDays(value, new Date(dueDateAt)) <= 0 ||
                    'Введите дату не позднее срока действия КП',
                },
              }}
              renderView={(value) => (
                <InfoRow
                  label="Рассмотрение КП"
                  value={
                    <Typography fontSize="16px">
                      {value
                        ? getServerDate(value, 'dd.MM.yyyy')
                        : 'Дата не указана'}
                    </Typography>
                  }
                />
              )}>
              <DatePicker minDate={new Date()} />
            </Form.Item>
          )}
        </Box>

        <Box flexBasis="100%">
          <Form.Item
            required
            label="Поставка, в к.д."
            viewMode={!isEditing}
            name="supplyDuration"
            rules={{
              required: checkRequired ? required : undefined,
              maxLength: maxLength(FIELD_LENGTH.commercialOffer.supplyDuration),
              validate: {
                positiveNumber: positiveNumber(),
              },
            }}
            renderView={(value) => (
              <InfoRow
                label="Поставка, в к.д."
                value={
                  <>
                    <Typography fontSize="16px">{value}</Typography>
                    {mode === 'contractor' &&
                      !!supplyDurationHistory?.history.length && (
                        <IconButton
                          onClick={(_) => {
                            toggleChangeModalOpen();
                            setChangeModalTitle('Поставка');
                            setModalData(supplyDurationHistory.history);
                          }}>
                          <History />
                        </IconButton>
                      )}
                  </>
                }
              />
            )}>
            <NumberInput decimalScale={0} placeholder="0" />
          </Form.Item>
        </Box>
      </Box>
      <ChangeLogsWindow
        onClose={toggleChangeModalOpen}
        changeHistory={modalData}
        title={changeModalTitle}
        open={isChangeModalOpen}
      />
    </Box>
  );
};
