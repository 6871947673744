import { Typography } from 'src/components/ui';
import { TNotification } from 'src/models/notifications';
import { BellRinging } from 'src/assets/icons';
import { interpolate, notificationsSocket } from 'src/utils';
import { AuthService } from 'src/services';
import { setReadNotification } from 'src/store/notifications';
import { useAppDispatch } from 'src/store';
import { BubbleItem } from '../bubble-item';

interface INotificationProps {
  data: TNotification;
  onClick?: () => void;
}

export const Notification = ({ data, onClick }: INotificationProps) => {
  const dispatch = useAppDispatch();
  const interpolatedTitle = interpolate(data.titleTemplate, data.params, '$');

  const businessRequestId = data.params.bidId || data.params.businessRequestId;

  let redirectUrl = businessRequestId
    ? `/requests/${businessRequestId}`
    : undefined;

  if (
    redirectUrl &&
    data.notificationType === 'COMMERCIAL_OFFER_IMPROVEMENT_REQUEST'
  ) {
    redirectUrl += '/commercial-offers';
  }

  const { userId, isGuestAuth } = AuthService;

  const handleOnClick = () => {
    if (!isGuestAuth) {
      dispatch(setReadNotification(data.id));

      notificationsSocket.emit('NOTIFICATION_WATCHED', {
        userId,
        notificationId: data.id,
      });
    }

    if (redirectUrl) {
      onClick?.();
    }
  };

  return (
    <BubbleItem
      redirectUrl={redirectUrl}
      date={data.createdAt}
      avatarIcon={BellRinging}
      title={
        <Typography
          display="inline"
          color="#292929"
          fontWeight="500"
          whiteSpace="normal">
          {interpolatedTitle}
        </Typography>
      }
      onClick={handleOnClick}
    />
  );
};
