import { PERMISSIONS, ROLES } from 'src/constants';
import { CompanyTypes, ICompany } from 'src/models';
import { AuthService, RolePermissionService } from 'src/services';
import { isAdmin, isManager } from 'src/utils';
import { isAssignedManager as checkAssignedManager } from 'src/utils/company';

export const permissions = (
  companyType: CompanyTypes,
  assignedManagers: ICompany['assignedManagers']
) => {
  const { currentRole } = AuthService;
  const isKam = currentRole === ROLES.KAM || currentRole === ROLES.KAM_PLUS;
  const isAssignedManager = checkAssignedManager(companyType, assignedManagers);

  // TODO считаем, что любой CUSTOMER/CONTRACTOR имеет доступ только к своей компании
  const isContactPerson = RolePermissionService.can(
    PERMISSIONS.VIEW_OWN_COMPANY
  );

  return {
    changeCompanyStatus: isAssignedManager || AuthService.isSpecificRole(),
    editCommonCompanyData: isKam || isContactPerson,
    editCompanyContacts: RolePermissionService.can(
      PERMISSIONS.EDIT_COMPANY_CONTACTS
    ),
    authAsGuest: RolePermissionService.can(PERMISSIONS.LOGIN_AS_GUEST),
    sendEmailWithCredentials: isAssignedManager,
    unlockContact: isManager() || isAdmin(),
    editAuditDate: isAssignedManager,
    editCertificate: isAssignedManager,
    editPresentation: isManager() || isAdmin(),
    mergeCompany: isKam,

    contract: {
      addDraftContract:
        isManager() || isAdmin() || AuthService.isSpecificRole(),
      addSignedContractScan:
        isManager() ||
        isAdmin() ||
        isContactPerson ||
        AuthService.isSpecificRole(),
      addDisagreementProtocol:
        isManager() ||
        isAdmin() ||
        isContactPerson ||
        AuthService.isSpecificRole(),
      addFinalContract:
        isManager() || isAdmin() || AuthService.isSpecificRole(),
      restartConclusion:
        isManager() || isAdmin() || AuthService.isSpecificRole(),
    },
  };
};

export type TCompanyPermissions = ReturnType<typeof permissions>;
