import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITelephonyCall } from 'src/models/communications';

interface IInitialState {
  isBubbleVisible: boolean;
}

const initialState: IInitialState = {
  isBubbleVisible: false,
};

const notificationBubbleSlice = createSlice({
  name: 'notificationBubble',
  initialState,
  reducers: {
    setIsBubbleVisible(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.isBubbleVisible = payload;
    },
  },
});

export const { setIsBubbleVisible } = notificationBubbleSlice.actions;
export const notificationBubbleReducer = notificationBubbleSlice.reducer;
