import { IAuth, AuthService } from './auth';
import { getServerDate } from '../utils';

import { StorageService } from './storage-service';

interface ILastPageVisit {
  [userId: string]: { [pageName: string]: string };
}

export class LastPageVisit extends StorageService {
  private _key = 'lastPageVisit';

  constructor(private _authService: IAuth) {
    super();
  }

  get(pageName: string) {
    const result = this.getItem<ILastPageVisit>(this._key);

    if (!result?.[this._authService.userId]?.[pageName]) {
      return getServerDate(new Date(), undefined, true);
    }

    return getServerDate(
      result[this._authService.userId][pageName],
      undefined,
      true
    );
  }

  set(pageName: string) {
    const result = this.getItem<ILastPageVisit>(this._key);

    if (!result || !result?.[this._authService.userId]) {
      this.setItem(this._key, {
        ...result,
        [this._authService.userId]: {
          [pageName]: new Date().toISOString(),
        },
      });

      return true;
    }

    this.setItem(this._key, {
      ...result,
      [this._authService.userId]: {
        ...result[this._authService.userId],
        [pageName]: new Date().toISOString(),
      },
    });

    return true;
  }
}

export const LastPageVisitService = new LastPageVisit(AuthService);
