import { ReactElement } from 'react';
import { LoadingIcon } from 'src/assets/icons';
import styles from './loader.module.scss';

interface ILoaderProps {
  icon?: ReactElement;
}

export const Loader = ({ icon }: ILoaderProps) => (
  <span className={styles.spin}>{icon || <LoadingIcon />}</span>
);
