export const positiveNumber = (message?: string) => (value: number) => {
  const cleanedValue = parseFloat((value || '').toString().replace(',', '.'));

  return (
    (!cleanedValue && cleanedValue !== 0) ||
    cleanedValue > 0 ||
    message ||
    'Значение не может равняться 0'
  );
};
