import TableRow from '@mui/material/TableRow';
import { ChangeEvent, MouseEvent, useMemo, useState, useEffect } from 'react';
import { TableCell, Collapse, Table, TableBody } from '@mui/material';
import { Checkbox, IconButton } from 'src/components/ui';
import { ArrowUp, ArrowDown, Arrow } from 'src/assets/icons';
import classNames from 'classnames';
import { SubcategoryModal } from 'src/components/pages/admin/directory/technical-opportunities/components/subcategory-modal';
import { TSubcategory } from 'src/models/admin';
import { Subcategory } from 'src/components/pages/admin/directory/technical-opportunities/components/subcategory';
import { Cell } from './cell';
import { RowProps } from './types';
import styles from './table.module.scss';

const getCountColSpan = (
  countCol: number,
  selected: boolean,
  isCollapse: boolean
) => countCol + (selected ? 1 : 0) + (isCollapse ? 1 : 0);

const isSubCategorySelected = <T extends { key: number | string }>(
  record: T,
  subCategory: any,
  selected: Array<T>,
  subCategoryKey: keyof T
): boolean =>
  selected.some(
    (selectRecord) =>
      selectRecord.key === record.key &&
      (selectRecord[subCategoryKey] as Array<any>).some(
        (item) => item.id === subCategory.id
      )
  );

export const Row = <RecordType extends { key: number | string }>(
  props: RowProps<RecordType>
) => {
  const {
    record,
    columns,
    selected,
    setSelected,
    subcategoryKey,
    renderSubCategory,
    expandedRowRender: ComponentExpandedRowRender,
    onSelectedWithSubCategory,
    onRowClick,
    rowExpanded = false,
  } = props;

  const [isExpanded, setIsExpanded] = useState(rowExpanded);
  const [openSubModal, setOpenSubModal] = useState(false);
  const [currentSubcategoryItem, setCurrentSubcategoryItem] = useState(
    {} as TSubcategory
  );

  const [open, setOpen] = useState(false);
  const countColSpan = getCountColSpan(
    columns.length,
    !!setSelected,
    !!subcategoryKey
  );

  const isItemSelected = useMemo(() => {
    if (!subcategoryKey) {
      return selected?.some((item) => item.key === record.key);
    }
    return selected?.some((item) => {
      if (item.key !== record.key) return false;
      const totalCountSubcategory = (record[subcategoryKey] as Array<unknown>)
        .length;
      const canSelected =
        onSelectedWithSubCategory?.(item) ||
        (item[subcategoryKey] as Array<unknown>).length ===
          totalCountSubcategory;
      return canSelected && item[subcategoryKey];
    });
  }, [selected, subcategoryKey, record]);

  const handleClick = (
    event: MouseEvent<unknown>,
    selectRecord: RecordType
  ) => {
    if (!selected || !setSelected) return;

    const selectedIndex = selected.findIndex(
      (item) => item.key === selectRecord.key
    );

    let newSelected: Array<RecordType> = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, selectRecord];
    } else {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const handleSelectedSubCategory = (
    event: ChangeEvent<HTMLInputElement>,
    currentRecord: RecordType,
    subCategory: any
  ) => {
    if (!selected || !subcategoryKey || !setSelected) return;

    const selectedRecord = selected.find(
      (item) => item.key === currentRecord.key
    );

    if (selectedRecord) {
      const subCategories = selectedRecord[subcategoryKey] as Array<any>;
      const newSubcategoryKey = event.target.checked
        ? [...subCategories, subCategory]
        : subCategories.filter(
            (subCategoryItem) => subCategoryItem.id !== subCategory.id
          );

      setSelected(
        selected.map((item) =>
          item.key === currentRecord.key
            ? { ...item, [subcategoryKey]: newSubcategoryKey }
            : item
        )
      );
    } else {
      const newSubCategory = (
        currentRecord[subcategoryKey] as Array<any>
      ).filter((item) => item.id === subCategory.id);

      setSelected([
        ...selected,
        { ...currentRecord, [subcategoryKey]: newSubCategory },
      ]);
    }
  };

  useEffect(() => {
    setIsExpanded(rowExpanded);
  }, [rowExpanded]);

  const handleToggleCollapse = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        className={isItemSelected ? styles.selectedRow : null}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },

          ...(isExpanded && { '&.MuiTableRow-root, & > td': { border: 0 } }),
        }}>
        {selected ? (
          <TableCell sx={{ width: '60px' }}>
            <Checkbox
              onClick={(event) => handleClick(event, record)}
              checked={isItemSelected}
            />
          </TableCell>
        ) : null}

        {columns.map((column, index) => (
          <Cell<RecordType>
            key={String(column.title)}
            column={column}
            index={index}
            record={record}
            onClick={(e) => {
              if (onRowClick) {
                onRowClick(record);
              } else if (subcategoryKey) {
                handleToggleCollapse(e);
              }
            }}
          />
        ))}

        {ComponentExpandedRowRender && (
          <TableCell sx={{ width: '56px' }}>
            <IconButton
              onClick={() => setIsExpanded((prevState) => !prevState)}>
              {isExpanded ? <ArrowUp /> : <ArrowDown />}
            </IconButton>
          </TableCell>
        )}

        {subcategoryKey && (
          <TableCell sx={{ width: '50px' }} onClick={handleToggleCollapse}>
            {record[subcategoryKey] instanceof Array &&
              !!(record[subcategoryKey] as Array<any>).length && (
                <IconButton aria-label="expand row" size="small">
                  <Arrow
                    className={classNames(styles.arrow, open && styles.open)}
                  />
                </IconButton>
              )}
          </TableCell>
        )}
      </TableRow>

      {isExpanded && (
        <TableRow>
          {selected && <TableCell />}

          <TableCell colSpan={columns.length}>
            {ComponentExpandedRowRender && (
              <ComponentExpandedRowRender item={record} />
            )}
          </TableCell>
        </TableRow>
      )}

      {subcategoryKey && (
        <TableRow>
          <TableCell
            sx={{ padding: 0, borderBottom: 0 }}
            colSpan={countColSpan}>
            <Collapse in={open}>
              <Table>
                <TableBody>
                  {record[subcategoryKey] instanceof Array &&
                    (record[subcategoryKey] as Array<any>).map(
                      (subcategory) => {
                        const isSubCategorySelectedValue =
                          selected &&
                          isSubCategorySelected(
                            record,
                            subcategory,
                            selected,
                            subcategoryKey
                          );
                        return (
                          <TableRow key={+subcategory.id}>
                            {selected ? (
                              <TableCell
                                width="60px"
                                className={classNames(
                                  isSubCategorySelectedValue &&
                                    styles.selectedRow
                                )}>
                                <Checkbox
                                  onChange={(event) =>
                                    handleSelectedSubCategory(
                                      event,
                                      record,
                                      subcategory
                                    )
                                  }
                                  checked={isSubCategorySelectedValue}
                                />
                              </TableCell>
                            ) : null}
                            <TableCell
                              className={classNames(
                                isSubCategorySelectedValue && styles.selectedRow
                              )}>
                              {
                                renderSubCategory && (
                                  <Subcategory
                                    subcategory={subcategory}
                                    setCurrentSubcategoryItem={
                                      setCurrentSubcategoryItem
                                    }
                                    setOpenSubModal={setOpenSubModal}
                                  />
                                )
                                // renderSubCategory({
                                //   subcategory,
                                //   setCurrentSubcategoryItem,
                                //   setOpenSubModal,
                                // })
                              }
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
                {renderSubCategory && (
                  <SubcategoryModal
                    open={openSubModal}
                    setOpen={setOpenSubModal}
                    subcategory={currentSubcategoryItem}
                  />
                )}
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
