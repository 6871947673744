import { createContext } from 'react';
import { TVariantTag } from 'src/models/admin';

type TechnicalOpportunitiesContextProps = {
  secondLevelCategoryList: TVariantTag[];
  handleUpdateList: () => void;
};

export const TechnicalOpportunitiesContext =
  createContext<TechnicalOpportunitiesContextProps>({
    secondLevelCategoryList: [],
    handleUpdateList: () => {},
  });
