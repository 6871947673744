import { DIRECTION, TSortItem } from 'src/components/ui/filters/types';

interface IInitializeSortListArgs {
  sortList: TSortItem<any>[];
  fieldName?: string | null;
  order?: DIRECTION | null;
}

export const initializeSortList = ({
  sortList,
  fieldName,
  order,
}: IInitializeSortListArgs) =>
  sortList.map((sortItem) => {
    if (!fieldName) return sortItem;

    const { direction } = sortItem;
    const isActive = sortItem.key === fieldName;

    return {
      ...sortItem,
      direction: isActive ? (order as DIRECTION) || direction : direction,
      active: isActive,
    };
  });
