import classNames from 'classnames';
import React, {
  ElementType,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Button, Card, HintBar, Typography } from 'src/components/ui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Pencil, Plus } from 'src/assets/icons';
import { TCompanyPermissions } from 'src/configs/company';
import { IBusinessRequestCustomerInfo } from 'src/models';
import { CustomPagination } from './pagination';
import styles from './card-slider.module.scss';

type TProps<T> = {
  title: ReactNode;
  addTitle?: string;
  itemComponent: ElementType;
  data: T[];
  onChange?: (data: T, field: keyof T, value: any) => void;
  onAdd?: () => void;
  onDelete?: (id: number) => void;
  onEdit?: (data: T) => void;
  onEditList?: () => void;
  typeElement?: string;
  loop?: boolean;
  permissions?: TCompanyPermissions;
  dataForItemComponent?: IBusinessRequestCustomerInfo;
  businessRequest?: IBusinessRequestCustomerInfo;
  // Todo: нужно, чтобы всегда показывать 2 карточки. Придумать более красивое решение
  showTwoItemsPerPage?: boolean;
  error?: string;
  hintsContent?: ReactNode;
  showSignerInfo?: boolean;
};

export const CardSlider = <T extends { id: number }>(props: TProps<T>) => {
  const {
    title,
    addTitle = 'Добавить',
    itemComponent: Component,
    data,
    onChange,
    onAdd,
    onDelete,
    onEdit,
    typeElement,
    loop,
    permissions,
    onEditList,
    dataForItemComponent,
    businessRequest,
    showTwoItemsPerPage,
    error,
    hintsContent,
    showSignerInfo,
  } = props;
  const theme = useTheme();
  const [countPerPage, setCountPerPage] = useState(3);

  const countItems = data.length;
  const totalCountItems = onAdd ? countItems + 1 : countItems;
  const lastPage = Math.ceil(totalCountItems / countPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const media = useMediaQuery(theme.breakpoints.down('xxl'));

  const showItems = useMemo(
    (): T[] =>
      data.slice((currentPage - 1) * countPerPage, currentPage * countPerPage),
    [data, currentPage, countPerPage]
  );

  useEffect(() => {
    if (showTwoItemsPerPage) {
      setCountPerPage(2);
      return;
    }
    setCountPerPage(media ? 2 : 3);
  }, [media]);

  return (
    <>
      <Card
        title={title}
        actions={
          <Box display="flex" alignItems="center">
            {onEditList && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mr="10px">
                <Pencil />
                <Button variant="text" onClick={onEditList}>
                  <Typography className={styles.editTitle}>
                    Редактировать
                  </Typography>
                </Button>
              </Box>
            )}
            <CustomPagination
              countTotal={totalCountItems}
              countPerPage={countPerPage}
              onChange={setCurrentPage}
              loop={loop}
            />
          </Box>
        }
        headerStyle={{ border: 'none' }}
      />

      {hintsContent}

      <div className={styles.mainWrapper}>
        {showItems.map((element) => (
          <React.Fragment key={element.id}>
            <section
              className={classNames(
                styles.column,
                showTwoItemsPerPage && styles.twoItemsColumn
              )}>
              <Component
                item={element}
                permissions={permissions}
                onChange={onChange}
                onDelete={onDelete}
                countElements={countItems}
                typeElement={typeElement}
                onEdit={onEdit}
                businessRequest={dataForItemComponent || businessRequest}
                error={error}
                showSignerInfo={showSignerInfo}
              />
            </section>
          </React.Fragment>
        ))}
        {lastPage === currentPage && onAdd ? (
          <section
            className={classNames(
              styles.column,
              showTwoItemsPerPage && styles.twoItemsColumn
            )}>
            <Button
              className={styles.createBlock}
              variant="text"
              onClick={() => onAdd()}
              startIcon={<Plus />}>
              {addTitle}
            </Button>
          </section>
        ) : null}
      </div>
    </>
  );
};
