import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';

type TGetEmailSignatureResponse = {
  signature: string;
};

export const adminTemplatesApi = createApi({
  reducerPath: 'adminTemplatesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getEmailSignature: builder.query<TGetEmailSignatureResponse, void>({
      query: () => 'template-service/api/v1/admin/email-signature',
    }),
  }),
});

export const { useLazyGetEmailSignatureQuery } = adminTemplatesApi;
