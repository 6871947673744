import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const SectionList = lazy(
  () =>
    import(
      /* webpackChunkName: 'SectionList' */
      /* webpackMode: "lazy" */
      `./section-list`
    )
);

export const LazySectionList: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <SectionList />
  </Suspense>
);
