import { FC } from 'react';
import { Button, SvgIcon } from 'src/components/ui';
import { Plus } from 'src/assets/icons';

type TAddButtonProps = {
  onClick: () => void;
  text: string;
};

export const AddButton: FC<TAddButtonProps> = ({ onClick, text }) => (
  <Button
    onClick={onClick}
    sx={{
      display: 'flex',
      gap: '8px',
    }}>
    <SvgIcon icon={Plus} width="16px" height="16px" strokeColor="#FFFFFF" />
    {text}
  </Button>
);
