import { Typography } from 'src/components/ui';
import { IMessage } from 'src/models/communications';
import { ChatWithText } from 'src/assets/icons';
import { BubbleItem } from '../bubble-item';

interface IMessageProps {
  data: IMessage;
  onClick?: () => void;
}

export const Message = ({ data, onClick }: IMessageProps) => (
  <BubbleItem
    redirectUrl={`/requests/${data.application.requestId}`}
    name={data.owner.name}
    date={data.sortDate}
    userGroupColor={data.owner?.userGroup?.color}
    badgeIcon={ChatWithText}
    text={data.text}
    title={
      <>
        <Typography display="inline" color="#292929" fontWeight="500">
          {data.owner.name}{' '}
        </Typography>
        <Typography display="inline" color="#7A8694">
          прислал(a) сообщение по заявке{' '}
        </Typography>
        <Typography
          display="inline"
          color="#292929"
          fontWeight="500"
          whiteSpace="nowrap">
          № {data.application.id}
        </Typography>
      </>
    }
    onClick={onClick}
  />
);
