import { TContact } from 'src/components/pages/company/contacts/types';
import { KamStatusCode } from 'src/models/bid';
import {
  ContractorRequestStatusForKam,
  ContractorRequestStatusForKamPlus,
  ISpecificRole,
} from 'src/models/bids-details';
import { ICompany } from 'src/models/company';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { ContractStatuses } from 'src/models/contract';

type TRequest = {
  totalCount: number;
  specificationSignedCount: number;
  inProgressCount: number;
  totalCost: number;
};

type TOrder = {
  fulfilled?: number;
  inWork?: number;
  sum: number;
};

export enum ContractorStatusForKam {
  CONTRACTOR_WITHOUT_CONTACT = 'CONTRACTOR_WITHOUT_CONTACT',
  NEW_CONTRACTOR = 'NEW_CONTRACTOR',
  PROPS_UPDATED = 'PROPS_UPDATED',
  ESK_ACQUIRED = 'ESK_ACQUIRED',
  SOB_CHECKUP_COMPLETED = 'SOB_CHECKUP_COMPLETED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  DISAGREEMENT_PROTOCOL_RECEIVED = 'DISAGREEMENT_PROTOCOL_RECEIVED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CONTRACTOR_SIGNED = 'CONTRACTOR_SIGNED',
  REFUSED = 'REFUSED',
}

export const ContractorStatusForKamDisplayName: Record<
  ContractorStatusForKam,
  string
> = {
  CONTRACTOR_WITHOUT_CONTACT: 'Исполнитель без контакта',
  NEW_CONTRACTOR: 'Новый исполнитель',
  PROPS_UPDATED: 'Информация об исполнителе получена',
  ESK_ACQUIRED: 'ЕСК получен',
  SOB_CHECKUP_COMPLETED: 'Проверка СОБ пройдена',
  CONTRACT_SENT: 'Проект договора отправлен',
  DISAGREEMENT_PROTOCOL_RECEIVED: 'Протокол разногласий получен',
  CONTRACT_SIGNED: 'Договор подписан',
  CONTRACTOR_SIGNED: 'Исполнитель подключен',
  REFUSED: 'Подключение отклонено',
};

export const ContractorStatusForKamDescription: Record<
  ContractorStatusForKam,
  string
> = {
  CONTRACTOR_WITHOUT_CONTACT:
    'Новый исполнитель без контактных лиц. Прикрепите контакт к исполнителю, чтобы он получил доступ к личному кабинету.',
  NEW_CONTRACTOR:
    'Ожидаем предоставление информации о компании от исполнителя. Проверьте данные по заполнении и запросите ЕСК.',
  PROPS_UPDATED:
    'Необходимо заполнить единый номер контрагента компании перед отправкой на проверку СОБ.',
  ESK_ACQUIRED:
    'Отправьте письмо в СОБ для проверки исполнителя. Внесите решение и комментарий СОБ для продолжения подключения.',
  SOB_CHECKUP_COMPLETED: 'Направьте исполнителю договор для подписания.',
  // SIGNING_APPROVED:
  //   'Подключение согласовано. Отправьте исполнителю проект договора или загрузите подписанный договор.',
  CONTRACT_SENT:
    'Получите подписанный договор со стороны исполнителя и загрузите финальную версию.',
  DISAGREEMENT_PROTOCOL_RECEIVED:
    'Ознакомтесь с протоклом и направьте новый проект договора',
  CONTRACT_SIGNED:
    'Финальная версия договора подписана. Загрузите презентацию и допустите исполнителя к работе.',
  CONTRACTOR_SIGNED: 'Исполнитель успешно подключен к платформе.',
  REFUSED: 'Принято решение не подключать исполнителя к платформе.',
};

export enum ContractorStatusForContractor {
  EXPECTING_PROPS = 'EXPECTING_PROPS',
  PROPS_UPDATED = 'PROPS_UPDATED',
  SOB_CHECKUP = 'SOB_CHECKUP',
  SOB_CHECKUP_COMPLETED = 'SOB_CHECKUP_COMPLETED',
  CONTRACT_RECIEVED = 'CONTRACT_RECIEVED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  DISAGREEMENT_PROTOCOL_SENT = 'DISAGREEMENT_PROTOCOL_SENT',
  CONTRACTOR_SIGNED = 'CONTRACTOR_SIGNED',
  REFUSED = 'REFUSED',
}

export const ContractorStatusForContractorDisplayName: Record<
  ContractorStatusForContractor,
  string
> = {
  EXPECTING_PROPS: 'Предоставление информации',
  PROPS_UPDATED: 'Информация отправлена',
  SOB_CHECKUP: 'Проверка СОБ',
  SOB_CHECKUP_COMPLETED: 'Проверка СОБ пройдена',
  CONTRACT_RECIEVED: 'Подписание договора',
  CONTRACT_SIGNED: 'Договор подписан',
  DISAGREEMENT_PROTOCOL_SENT: 'Протокол разногласий отправлен',
  CONTRACTOR_SIGNED: 'Подключено завершено',
  REFUSED: 'Подключение отклонено',
};

export const ContractorStatusForContractorDescription: Record<
  ContractorStatusForContractor,
  string
> = {
  EXPECTING_PROPS:
    'Заполните обязательную информацию о своей компании для продолжения подключения.',
  PROPS_UPDATED:
    'Ответственный менеджер проверяет предоставленную информацию. Ожидайте обратную связь.',
  SOB_CHECKUP: 'Ваша заявка передана на проверку в службу безопасности.',
  SOB_CHECKUP_COMPLETED:
    'В ближайшее время менеджер вышлет Вам договор для подписания.',
  CONTRACT_RECIEVED: 'Ознакомьтесь с договором и загрузите подписанную копию.',
  CONTRACT_SIGNED: 'Договор подписан.',
  DISAGREEMENT_PROTOCOL_SENT: 'Ожидайте новый проект договора',
  CONTRACTOR_SIGNED:
    'Вы успешно подключены к платформе COMETAL! Теперь Вам доступен раздел заявок.',
  REFUSED: 'Ваша заявка на подключение была отклонена.',
};

export interface IContractorStatusBlock<StatusType> {
  id: number;
  contractorSigningId: number;
  status: StatusType;
  createdAt: string;
  displayName: string;
  description: string;
}

export interface IContractorsSigningKanbanParams {
  onlyMine: boolean;
  status: string;
}

export interface IContractorSigning {
  id: number;
  organizationId: number;
  number: number;
  position: string | null;
  assignedManager?: {
    firstName: string;
    middleName?: string;
    lastName: string;
  };
  contactorSigningId?: number;
  mainContact: {
    contactFirstName: string;
    contactLastName: string;
    contactMiddleName?: string;
    contactPhone?: TPhoneInputValue[];
    contactPosition?: string;
    contactUserId: number;
  };
  assignmentStatus: {
    officeManagerAssignmentStatus: string;
    backOfficeAssignmentStatus: string;
    partnerRelationsManagerAssignmentStatus: string;
  };
  organization: {
    organizationName: string;
    organizationRegion?: string;
  };
}

export interface IContractorsSigningKanbanItem {
  statusCode: ContractorRequestStatusForKamPlus;
  statusName: string;
  contractorSigningCountByStatus: number;
  contractorSignings: IContractorSigning[];
}

export interface IContractorsSigningKanbanCounters {
  activeCount: number;
  lockedCount: number;
  totalCount: number;
}

export interface IContractorsSigningKanban {
  countByStatus: IContractorsSigningKanbanCounters | null;
  items: IContractorsSigningKanbanItem[];
}

export interface IContractor {
  id: number;
  organizationName?: string;
  contractStatus: ContractStatuses;
  createdAt: string;
  lastActivity: string;
  mainContact?: TContact;
  plainContactsCount: number;
  organizationStatus: ICompany['organizationStatus'];
  contactInfoDto?: Array<TPhoneInputValue>;
  region?: string;
  contractorRequestsInfo: TRequest;
  businessRequestsCount: number;
  orders?: TOrder;
  technicalAuditPassed: boolean;
  rating?: number;
  documents?: {
    documentId: number;
    documentName: string;
    entityId: number;
  }[];
}

type TMainContactLite = {
  firstName: string;
  lastName: string;
  middleName: string;
  position: string;
  mainPhone: TPhoneInputValue;
};

export type TLiteOrganization = Omit<
  IContractor,
  | 'businessRequestsCount'
  | 'contactInfoDto'
  | 'contractorRequestsInfo'
  | 'documents'
  | 'orders'
  | 'rating'
  | 'mainContact'
  | 'lastActivity'
  | 'contractStatus'
> &
  Partial<
    Pick<
      IContractor,
      | 'businessRequestsCount'
      | 'contactInfoDto'
      | 'contractorRequestsInfo'
      | 'documents'
      | 'orders'
      | 'rating'
    > & {
      mainContact?: TMainContactLite;
      lastActivityDate?: string;
      hasSignedContract: boolean;
    }
  >;

export type TContractorList = {
  countByStatus: {
    signedCount: number;
    disabledCount: number;
    lockedCount: number;
  } | null;
  totalCount: number | null;
  items: {
    segmentId: number;
    segmentName: string;
    organizations: TLiteOrganization[];
  }[];
};

interface ICustomerOrganization {
  id: number;
  organizationName?: string;
  inn: string;
  assignedManager?: {
    userId: string;
    firstName: string;
    middleName?: string;
    lastName: string;
  };
  mainContact?: TMainContactLite;
  plainContactsCount: number;
  organizationStatus: ICompany['organizationStatus'];
  region?: string;
  hasSignedContract: boolean;
  technicalAuditPassed: boolean;
  createdAt: string;
  lastActivityDate: string;
}

export type TGetContractorsKanbanResponse = Omit<
  TContractorList,
  'items' | 'countByStatus'
> & {
  items: {
    segmentId: number;
    segmentName: string;
    organizationsCountBySegment: number;
    organizations: ICustomerOrganization[];
  }[];
  countByStatus: {
    signedCount: number;
    disabledCount: number;
    lockedCount: number;
  };
};

export type TGetContractorRequestsInfoResponse = {
  organizationId: number;
  totalCount: number;
  specificationSignedCount: number;
  inProgressCount: number;
  totalCost: number;
  ordersInWorkCounts: number;
  closedOrdersCounts: number;
  specificationPriceTotalCount: number;
}[];

export interface IContractorSobStatus {
  sobApproveStatus: string; // TODO: Добавить перечисление ESK_REQUESTED и тд
  sobComment: string;
}
