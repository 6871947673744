import { IContractorSpecification } from 'src/models';
import { businessRequestApi } from '../business-request';

export const contractorSpecificationApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getContractorSpecifications: builder.query<
      IContractorSpecification | undefined,
      number
    >({
      query: (contractorRequestId) => ({
        url: `business-requests/api/v1/contractor/contractor-request/${contractorRequestId}/contractor-specification`,
        method: 'GET',
        params: { statusNotIn: ['DELETED'] },
      }),

      transformResponse: (data: IContractorSpecification[]) => data[0],
    }),
    signContractorSpecification: builder.mutation<
      IContractorSpecification,
      {
        specificationId: number;
        contractorRequestId: number;
      }
    >({
      query: ({ specificationId }) => ({
        url: `business-requests/api/v1/contractor/contractor-specification/${specificationId}/signed-scan`,
        method: 'PATCH',
      }),
      async onQueryStarted(
        { contractorRequestId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            contractorSpecificationApi.util.updateQueryData(
              'getContractorSpecifications',
              contractorRequestId,
              () => data
            )
          );
        } catch {
          // ignore
        }
      },
    }),
  }),
});

export const {
  useGetContractorSpecificationsQuery,
  useSignContractorSpecificationMutation,
} = contractorSpecificationApi;
