import { FC } from 'react';
import { PatternFormatProps, PatternFormat } from 'react-number-format';
import { OutlinedInput, OutlinedInputProps } from 'src/components/ui';

export const PatternInput: FC<PatternFormatProps & OutlinedInputProps> = ({
  displayType,
  ...props
}) => (
  <PatternFormat
    {...(displayType === 'text' ? props : { ...props, trimValue: false })}
    displayType={displayType}
    customInput={OutlinedInput}
  />
);
