import { plural } from '../plural';

export const maxLengthPrice = (fieldLength: number) => (value: string) => {
  const integer = String(value).split('.')[0];

  return (
    integer.length <= fieldLength ||
    `Не больше ${plural(
      fieldLength,
      '$d символа',
      '$d символов',
      '$d символов'
    )}`
  );
};
