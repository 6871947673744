import { Typography } from 'src/components/ui';
import { Stack } from '@mui/material';
import { ROLE_NAMES } from 'src/configs';
import { IUserState } from 'src/store/user/user-slice';
import styles from './user-block.module.scss';

interface IUserBlockProps {
  user: IUserState;
}

export const UserBlock = ({ user }: IUserBlockProps) => {
  const initials = `${user?.lastName?.substring(
    0,
    1
  )} ${user?.firstName?.substring(0, 1)}`;

  // TODO: Кружок с инициалами вынести в отдельный компонент!

  return (
    <Stack
      direction="row"
      gap="12px"
      alignItems="center"
      className={styles.userBlock}>
      <div className={styles.avatar}>{initials || ''}</div>
      <Stack>
        <Typography fontWeight="500">{user.fullName}</Typography>
        <Typography fontSize="12px" color="#7A8694">
          {ROLE_NAMES[user.roles[0]]}
        </Typography>
      </Stack>
    </Stack>
  );
};
