import { CompanyTypes } from 'src/models';
import { RouteObject } from 'react-router-dom';
import { TCompanyMenuItem, TRoute } from './company-paths-by-type';

export const getAvailableConfigItems = (
  config: Array<TRoute & { disabled?: boolean }>,
  companyType?: CompanyTypes
) =>
  config.reduce(
    (
      acc: { routeItems: RouteObject[]; menuItems: TCompanyMenuItem[] },
      item
    ) => {
      const { type, title, ...configItem } = item;

      if (!type || !companyType || type === companyType) {
        if (configItem.children) {
          const availableChildItems = getAvailableConfigItems(
            configItem.children,
            companyType
          );

          if (availableChildItems.routeItems.length) {
            acc.routeItems.push({
              ...configItem,
              children: availableChildItems.routeItems,
            });
            acc.menuItems.push({
              title: title!,
              path: configItem.path ?? '',
              disabled: configItem.disabled,
              children: availableChildItems.menuItems,
            });
          }
        } else {
          acc.routeItems.push(configItem);

          if (title) {
            acc.menuItems.push({
              title,
              path: configItem.path ?? '',
              disabled: configItem.disabled,
            });
          }
        }
      }

      return acc;
    },
    { routeItems: [], menuItems: [] }
  );
