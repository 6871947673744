import { TAssignedManager } from 'src/api/admin/company';
import { CustomersPageFiltersData } from 'src/components/pages/customers/types/customers-page-filters-data';
import { ROLES } from 'src/constants';

export const getFilterVariantsData = (
  kamsData: TAssignedManager[]
): CustomersPageFiltersData => ({
  kamVariants: kamsData
    .filter((kam) => !kam.role.includes(ROLES.KAM_PLUS))
    .map((kam) => ({
      label: `${kam.firstName} ${kam.lastName}`,
      value: kam.userId.toString(),
      checked: false,
    })),
});
