import { FC } from 'react';
import { ConversationEntityType, IContractorSpecification } from 'src/models';
import { Box, InfoRow, Typography } from 'src/components/ui';
import { OpenChat } from 'src/components/widgets';
import { FilesInput, HeadInputs } from './components';

interface IContractorSpecificationProps {
  specification: IContractorSpecification;
  contractorRequestId?: number;
  preview?: boolean;
  orderProcessingStarted?: boolean;
}

export const ContractorSpecification: FC<IContractorSpecificationProps> = ({
  specification,
  contractorRequestId,
  preview,
  orderProcessingStarted,
}) => (
  <>
    <HeadInputs
      cost={specification.cost}
      paymentPeriodProlongation={specification.paymentPeriodProlongation}
      supplyDuration={specification.supplyDuration}
    />
    <Box maxWidth="710px">
      <InfoRow
        label="Условия оплаты"
        value={
          specification.paymentConditions && (
            <Typography whiteSpace="pre-wrap">
              {specification.paymentConditions}
            </Typography>
          )
        }
      />
    </Box>
    {orderProcessingStarted ? null : (
      <OpenChat
        entityType={ConversationEntityType.CONTRACTOR_REQUEST}
        entityId={contractorRequestId || 0}
        mode="contragent"
        sx={{ marginBottom: '24px' }}
      />
    )}
    <FilesInput specification={specification} preview={preview} />
  </>
);
