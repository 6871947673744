import { FC, lazy, Suspense } from 'react';

import { BusinessRequestMode } from 'src/models';

import { PageLoader } from 'src/components/ui';

const MainBidsLayout = lazy(
  () =>
    import(
      /* webpackChunkName: 'MainBidsLayout' */
      /* webpackPrefetch: true */
      /* webpackMode: "lazy" */
      `./main-bids-layout`
    )
);

export const LazyMainBidsLayout: FC<{ mode: BusinessRequestMode }> = ({
  mode,
}) => (
  <Suspense fallback={<PageLoader />}>
    <MainBidsLayout mode={mode} />
  </Suspense>
);
