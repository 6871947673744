import { TDocument } from '../document';

export enum CustomerSpecificationStatus {
  PRELIMINARY_VERSION_RECEIVED = 'PRELIMINARY_VERSION_RECEIVED',
  DELETED = 'DELETED',
  SIGNED_SCAN_SENT = 'SIGNED_SCAN_SENT',
  FINAL_VERSION_RECEIVED = 'FINAL_VERSION_RECEIVED',
}

export interface ICustomerSpecificationStatus {
  id: number;
  statusName: string;
  description: string;
  code: CustomerSpecificationStatus;
}
export interface ICustomerSpecification {
  specificationId: number;
  businessApplicationId: number;
  cost: number | string;
  supplyDuration: number;
  paymentPeriodProlongation: number;
  paymentConditions: string;
  status: ICustomerSpecificationStatus;
  preliminaryVersionReceivedAt: string | null;
  signedScanSentAt?: string | null;
  finalVersionReceivedAt?: string | null;
  createdAt: string;
  quickDeal: boolean;
  documents: {
    SPECIFICATION_PRELIMINARY_VERSION: TDocument[];
    SPECIFICATION_SIGNED_SCAN?: TDocument[];
    SPECIFICATION_FINAL_VERSION?: TDocument[];
  };
}
