import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const NewsDetail = lazy(
  () =>
    import(
      /* webpackChunkName: 'NewsDetail' */
      /* webpackMode: "lazy" */
      `./news-detail`
    )
);

export const LazyNewsDetail: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <NewsDetail />
  </Suspense>
);
