import {
  IBusinessRequestSetContractorRequestDto,
  IManagerAuction,
  IManagerAuctionListBidsFromContractor,
  IManagerAuctionListBidsFromContractorParams,
  IManagerAuctionRequestCommercialOffer,
  IManagerCreateAuctionParams,
  IManagerUpdateAuctionParams,
} from 'src/models';
import { businessRequestApi } from '../business-request';

export const managerAuctionApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getManagerAuction: builder.query<IManagerAuction[], number>({
      query: (businessRequestId) => ({
        url: 'business-requests/api/v1/manager/auction',
        method: 'GET',
        params: { businessRequestId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getManagerAuction', id: arg },
      ],
    }),

    updateManagerAuction: builder.mutation<
      IManagerAuction[],
      IManagerUpdateAuctionParams
    >({
      invalidatesTags: ['fieldHistoryLogs'],
      query: ({ auctionId, ...body }) => ({
        url: `business-requests/api/v1/manager/auction/${auctionId}`,
        method: 'PUT',
        body,
      }),
    }),

    createManagerAuction: builder.mutation<
      IManagerAuction[],
      IManagerCreateAuctionParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/auction',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              { type: 'fieldHistoryLogs' },
              {
                type: 'getManagerStatusHistory',
                id: arg.businessRequestId,
              },
            ],
    }),

    closeManagerAuction: builder.mutation<
      void,
      { auctionId: number; businessRequestId: number }
    >({
      query: ({ auctionId }) => ({
        url: `business-requests/api/v1/manager/auction/${auctionId}/close`,
        method: 'PATCH',
      }),
      async onQueryStarted(
        { businessRequestId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.updateQueryData(
              'getManagerRequest',
              businessRequestId,
              (data) => ({
                ...data,
                auction: {
                  auctionInfo: null,
                  bestCommercialOfferCost:
                    data.auction?.bestCommercialOfferCost || null,
                },
              })
            )
          );

          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getManagerStatusHistory',
                id: businessRequestId,
              },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    getManagerAuctionContractorList: builder.query<
      IManagerAuction[],
      IBusinessRequestSetContractorRequestDto
    >({
      query: ({ id, body }) => ({
        url: `business-requests/api/v1/manager/auction/${id}/matching-contractors`,
        method: 'POST',
        body,
      }),
    }),

    setManagerAuctionContractorRequest: builder.mutation<
      void,
      IBusinessRequestSetContractorRequestDto
    >({
      query: ({ id, body }) => ({
        url: `business-requests/api/v1/manager/auction/${id}/matching-contractors/auction-contractor-request`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) =>
        error
          ? []
          : [
              {
                type: 'getManagerAuctionListBidsFromContractor',
              },
            ],
    }),

    getManagerAuctionListBidsFromContractor: builder.query<
      IManagerAuctionListBidsFromContractor,
      IManagerAuctionListBidsFromContractorParams
    >({
      query: ({ auctionId, bestCostOnly, ...body }) => ({
        url: `business-requests/api/v1/manager/auction/${auctionId}/bid`,
        method: 'POST',
        params: { bestCostOnly },
        body: {
          ...body.paging,
        },
      }),
      providesTags: (result, error, { auctionId }) => [
        { type: 'getManagerAuctionListBidsFromContractor', id: auctionId },
      ],
    }),

    getManagerAuctionRequestCommercialOffer: builder.mutation<
      IManagerAuctionRequestCommercialOffer,
      number
    >({
      query: (auctionBidId) => ({
        url: `business-requests/api/v1/manager/auction-bid/${auctionBidId}/commercial-offer-request`,
        method: 'PATCH',
      }),
    }),

    setManagerAuctionExclusionContractor: builder.mutation<
      void,
      { auctionContractorRequestId: number; cause: string }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/exclusion/auction-contractor-request',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetManagerAuctionQuery,
  useUpdateManagerAuctionMutation,
  useCreateManagerAuctionMutation,
  useCloseManagerAuctionMutation,
  useLazyGetManagerAuctionContractorListQuery,
  useSetManagerAuctionContractorRequestMutation,
  useLazyGetManagerAuctionListBidsFromContractorQuery,
  useGetManagerAuctionRequestCommercialOfferMutation,
  useSetManagerAuctionExclusionContractorMutation,
} = managerAuctionApi;
