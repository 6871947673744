import { ReactNode } from 'react';
import {
  IndexRouteObject,
  Navigate,
  NonIndexRouteObject,
} from 'react-router-dom';
import { LazyCustomerAllRequests } from 'src/components/pages/business-request-detail/customer/all-requests/lazy-all-requests';
import {
  LazyManagerCommercialOffers,
  LazyManagerRequestPerformers,
  LazyManagerSpecifications,
  LazyManagerPayment,
  LazyManagerShipping,
  LazyManagerRebidding,
  LazyManagerAuction,
  LazyManagerQualification,
  LazyManagerCustomerAbout,
  LazyManagerCustomerContacts,
  LazyManagerRequestInfo,
  LazyManagerCommunication,
} from 'src/components/pages/business-request-detail/manager';
import {
  LazyCustomerCommercialOffers,
  LazyCustomerRequest,
  LazyCustomerSpecifications,
  LazyCustomerPayment,
  LazyCustomerShipping,
  LazyCustomerCommunication,
} from 'src/components/pages/business-request-detail/customer';
import {
  LazyContractorCommercialOffers,
  LazyAllContractorCommercialOffers,
  LazyContractorRequest,
  LazyContractorSpecifications,
  LazyContractorPayment,
  LazyContractorShipping,
  LazyContractorAuction,
  LazyContractorRebidding,
  LazyContractorAllRebidding,
} from 'src/components/pages/business-request-detail/contractor';
import { LazyManagerLogistics } from 'src/components/pages/business-request-detail/manager/logistics';
import { LazySpecificRoleCommercialOffers } from 'src/components/pages/business-request-detail/specific-role/commercial-offers';
import { LazyLogisticLogistics } from 'src/components/pages/business-request-detail/specific-role/logistics';
import { LazySpecificRoleRequestPerformers } from 'src/components/pages/business-request-detail/specific-role/request-performers';
import { LazySpecificRoleSpecifications } from 'src/components/pages/business-request-detail/specific-role/specifications';
import { getAvailableConfigItems } from 'src/configs/business-request/utils';
import { BusinessRequestMode, CompanyTypes } from 'src/models';
import {
  CompanyTypeCheck,
  LazyCustomerBidsLayout,
  LazyRequestPerformersBidsLayout,
} from 'src/components/layout';
import {
  LazyCompanyCompanyInfo,
  LazyCompanyContacts,
  LazyCompanyCompanyRequisites,
  LazyCompanyBankRequisites,
  LazyCompanyDeliveryAddresses,
  LazyCompanyContract,
} from 'src/components/pages';
import {
  LazyManagerRequestPerformersAbout,
  LazyManagerRequestPerformersTechnicalOpportunities,
  LazyManagerRequestPerformersSearchPerformers,
  LazyManagerRequestPerformersRequestPerformers,
} from 'src/components/pages/business-request-detail/manager/request-performers';

type TRoute =
  | (IndexRouteObject & { title?: ReactNode })
  | (Omit<NonIndexRouteObject, 'children'> & {
      title?: ReactNode;
      children?: TRoute[];
    });

export type TBusinessRequestRouteConfig = TRoute[];

const managerRouteConfig: TBusinessRequestRouteConfig = [
  {
    index: true,
    element: <Navigate to="communications" replace />,
  },
  {
    title: 'Коммуникации',
    path: 'communications',
    element: <LazyManagerCommunication />,
  },
  // {
  //   title: 'Квалификация',
  //   path: 'qualification',
  //   element: <LazyManagerQualification />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Navigate to="about" replace />,
  //     },
  //     {
  //       title: 'Компания',
  //       path: 'about',
  //       children: [
  //         {
  //           title: 'О компании',
  //           index: true,
  //           element: <LazyCompanyCompanyInfo />,
  //         },
  //         {
  //           title: 'Контакты',
  //           path: 'contacts',
  //           element: <LazyCompanyContacts />,
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Реквизиты',
  //       path: 'requisites',
  //       children: [
  //         {
  //           title: 'Реквизиты компании',
  //           index: true,
  //           element: <LazyCompanyCompanyRequisites />,
  //         },
  //         {
  //           title: 'Банковские реквизиты',
  //           path: 'bank',
  //           element: <LazyCompanyBankRequisites />,
  //         },
  //         {
  //           title: 'Адреса доставки',
  //           path: 'delivery',
  //           element: (
  //             <CompanyTypeCheck companyType={CompanyTypes.CUSTOMER}>
  //               <LazyCompanyDeliveryAddresses />
  //             </CompanyTypeCheck>
  //           ),
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Договор',
  //       path: 'contract',
  //       element: <LazyCompanyContract />,
  //     },
  //   ],
  // },
  {
    title: 'Заявка',
    path: 'customer',
    element: <LazyManagerRequestInfo />,
  },
  {
    title: 'Запрос исполнителям',
    path: 'customer-request',
    element: <LazyManagerRequestPerformers />,
    // element: <LazyRequestPerformersBidsLayout />,
    // children: [
    //   {
    //     index: true,
    //     element: <LazyManagerRequestPerformersAbout />,
    //   },
    //   {
    //     path: 'technical-opportunities',
    //     element: <LazyManagerRequestPerformersTechnicalOpportunities />,
    //   },
    //   {
    //     path: 'search-performers',
    //     element: <LazyManagerRequestPerformersSearchPerformers />,
    //   },
    //   {
    //     path: 'request-performers',
    //     element: <LazyManagerRequestPerformersRequestPerformers />,
    //   },
    // ],
  },
  {
    title: 'Коммерческие предложения',
    path: 'commercial-offers',
    element: <LazyManagerCommercialOffers />,
  },
  {
    title: 'Аукцион',
    path: 'auction',
    element: <LazyManagerAuction />,
  },
  {
    title: 'Переторжка',
    path: 'rebidding',
    element: <LazyManagerRebidding />,
  },
  {
    title: 'Спецификации',
    path: 'specifications',
    element: <LazyManagerSpecifications />,
  },
  {
    title: 'Оплаты',
    path: 'payment',
    element: <LazyManagerPayment />,
  },
  {
    title: 'Отгрузки',
    path: 'shipping',
    element: <LazyManagerShipping />,
  },
  {
    title: 'Логистика',
    path: 'logistics',
    element: <LazyManagerLogistics />,
  },
];

const specificRoleRouteConfig: TBusinessRequestRouteConfig = [
  {
    index: true,
    element: <Navigate to="customer-request" replace />,
  },
  {
    title: 'Заявка',
    path: 'customer',
    element: <LazyManagerRequestInfo />,
  },
  {
    title: 'Коммуникации',
    path: 'communications',
    element: <LazyManagerCommunication />,
  },
  {
    title: 'Запрос исполнителям',
    path: 'customer-request',
    element: <LazySpecificRoleRequestPerformers />,
  },
  {
    title: 'Коммерческие предложения',
    path: 'commercial-offers',
    element: <LazySpecificRoleCommercialOffers />,
  },
  {
    title: 'Спецификации',
    path: 'specifications',
    element: <LazySpecificRoleSpecifications />,
  },
  {
    title: 'Оплаты',
    path: 'payment',
    element: <LazyManagerPayment />,
  },
  {
    title: 'Oтгрузки',
    path: 'shipping',
    element: <LazyManagerShipping />,
  },
  {
    title: 'Логистика',
    path: 'logistics',
    element: <LazyLogisticLogistics />,
  },
];

const customerRouteConfig: TBusinessRequestRouteConfig = [
  {
    index: true,
    element: <Navigate to="communications" replace />,
  },
  {
    title: 'Коммуникации',
    path: 'communications',
    element: <LazyCustomerCommunication />,
  },
  {
    title: 'Мой запрос',
    path: 'about',
    element: <LazyCustomerRequest />,
  },
  {
    title: 'Коммерческие предложения',
    path: 'commercial-offers',
    element: <LazyCustomerCommercialOffers />,
  },
  {
    title: 'Спецификации',
    path: 'specifications',
    element: <LazyCustomerSpecifications />,
  },
  {
    title: 'Оплаты',
    path: 'payment',
    element: <LazyCustomerPayment />,
  },
  {
    title: 'Oтгрузки',
    path: 'shipping',
    element: <LazyCustomerShipping />,
  },
];

const contractorRouteConfig: TBusinessRequestRouteConfig = [
  {
    index: true,
    element: <Navigate to="about" replace />,
  },
  {
    title: 'Запрос исполнителю',
    path: 'about',
    element: <LazyContractorRequest />,
  },
  {
    title: 'Аукцион',
    path: 'auction',
    element: <LazyContractorAuction />,
  },
  {
    title: 'Переторжка',
    path: 'rebidding',
    element: <LazyContractorRebidding />,
  },
  {
    title: 'Коммерческие предложения',
    path: 'commercial-offers',
    element: <LazyContractorCommercialOffers />,
  },
  {
    title: 'Спецификации',
    path: 'specifications',
    element: <LazyContractorSpecifications />,
  },
  {
    title: 'Оплаты',
    path: 'payment',
    element: <LazyContractorPayment />,
  },
  {
    title: 'Отгрузки',
    path: 'shipping',
    element: <LazyContractorShipping />,
  },
];

const contractorAllRouteConfig: TBusinessRequestRouteConfig = [
  {
    index: true,
    element: <Navigate to="about" replace />,
  },
  {
    title: 'Запрос исполнителю',
    path: 'about',
    element: <LazyContractorRequest />,
  },
  {
    title: 'Аукцион',
    path: 'auction',
    element: <LazyContractorAuction />,
  },
  {
    title: 'Переторжка',
    path: 'rebidding',
    element: <LazyContractorAllRebidding />,
  },
  {
    title: 'Коммерческие предложения',
    path: 'commercial-offers',
    element: <LazyAllContractorCommercialOffers />,
  },
];

export const {
  routeItems: managerBusinessRequestRouteItems,
  menuItems: managerBusinessRequestMenuItems,
} = getAvailableConfigItems(managerRouteConfig);

export const {
  routeItems: specificRoleBusinessRequestRouteItems,
  menuItems: specificRoleBusinessRequestMenuItems,
} = getAvailableConfigItems(specificRoleRouteConfig);

export const {
  routeItems: customerBusinessRequestRouteItems,
  menuItems: customerBusinessRequestMenuItems,
} = getAvailableConfigItems(customerRouteConfig);

export const {
  routeItems: contractorContractorRequestRouteItems,
  menuItems: contractorContractorRequestMenuItems,
} = getAvailableConfigItems(contractorRouteConfig);

export const {
  routeItems: contractorBusinessRequestRouteItems,
  menuItems: contractorBusinessRequestMenuItems,
} = getAvailableConfigItems(contractorAllRouteConfig);

export const businessRequestMenuItemsByMode = {
  [BusinessRequestMode.MANAGER]: managerBusinessRequestMenuItems,
  [BusinessRequestMode.CUSTOMER]: customerBusinessRequestMenuItems,
  [BusinessRequestMode.CONTRACTOR]: contractorContractorRequestMenuItems,
  [BusinessRequestMode.CONTRACTOR_ALL]: contractorBusinessRequestMenuItems,
  [BusinessRequestMode.SPECIFIC_ROLE]: specificRoleBusinessRequestMenuItems,
};
