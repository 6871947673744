import { useAddBusinessEventMutation } from 'src/api/business-event';
import { ROLES } from 'src/constants';
import { AuthService } from 'src/services';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setCurrentRole } from 'src/store/auth';
import { selectUser } from 'src/store/user';

interface IUseChangeRoleArgs {
  onChangeRole?: VoidFunction;
}

export const useChangeRole = ({ onChangeRole }: IUseChangeRoleArgs) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [addBusinessEvent] = useAddBusinessEventMutation();

  const changeRoleLogin = (role: ROLES) => {
    const now = new Date();

    if (AuthService.currentRole) {
      addBusinessEvent([
        {
          eventInitiatorUserId: AuthService.originalUid,
          userRole: AuthService.currentRole,
          eventSource: 'FRONTEND',
          eventDateTime: now.toISOString(),
          templateType: 'COMPLETED_DEAUTHORIZATION',
          messageParams: {
            fio: user.fullName,
            userId: user.id,
          },
        },
        {
          eventInitiatorUserId: AuthService.originalUid,
          userRole: role,
          eventSource: 'FRONTEND',
          eventDateTime: now.toISOString(),
          templateType: 'AUTHORIZATION_COMPLETED',
          messageParams: {
            fio: user.fullName,
            userId: user.id,
          },
        },
      ]);
    }
  };

  const handleChangeRole = (role: ROLES) => {
    if (role !== AuthService.currentRole) {
      changeRoleLogin(role);
      AuthService.setCurrentRole(role);
      dispatch(setCurrentRole(role));
      onChangeRole?.();
    }
  };

  return { handleChangeRole };
};
