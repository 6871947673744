import { IManagerShipping, IManagerUpdateShippingParams } from 'src/models';
import { businessRequestApi } from '../business-request';

export const managerShippingApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getManagerShipping: builder.query<IManagerShipping[], number>({
      query: (businessApplicationId) => ({
        url: 'business-requests/api/v1/manager/shipment-group',
        method: 'GET',
        params: { businessApplicationId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getManagerShipping', id: arg },
      ],
    }),

    updateManagerShipping: builder.mutation<
      IManagerShipping,
      IManagerUpdateShippingParams
    >({
      query: ({ shipmentGroupId, ...body }) => ({
        url: `business-requests/api/v1/manager/shipment-group/${shipmentGroupId}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteManagerShipping: builder.mutation<
      void,
      { shipmentId: number; parentEntityId: number }
    >({
      query: ({ shipmentId }) => ({
        url: `business-requests/api/v1/shipment/${shipmentId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { shipmentId, parentEntityId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;

          dispatch(
            managerShippingApi.util.updateQueryData(
              'getManagerShipping',
              parentEntityId,
              (data) =>
                data.map((element) => ({
                  ...element,
                  shipments: element.shipments.filter(
                    (shipment) => shipment.shipmentId !== shipmentId
                  ),
                }))
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),

    setManagerShipmentComment: builder.mutation<
      { shipmentGroupId: number; comment: string },
      {
        shipmentGroupId: number;
        comment: string;
        parentEntityId: number;
      }
    >({
      query: ({ shipmentGroupId, comment }) => ({
        url: `business-requests/api/v1/shipment-group/${shipmentGroupId}/comment`,
        method: 'PATCH',
        body: { comment },
      }),
      async onQueryStarted(
        { parentEntityId, shipmentGroupId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            managerShippingApi.util.updateQueryData(
              'getManagerShipping',
              parentEntityId,
              (cache) =>
                cache.map((element) => {
                  if (element.shipmentGroupId === shipmentGroupId) {
                    return { ...element, comment: data.comment };
                  }

                  return element;
                })
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),
  }),
});

export const {
  useGetManagerShippingQuery,
  useUpdateManagerShippingMutation,
  useDeleteManagerShippingMutation,
  useSetManagerShipmentCommentMutation,
} = managerShippingApi;
