import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ManagerRequestInfo = lazy(
  () =>
    import(
      /* webpackChunkName: 'ManagerRequestInfo' */
      /* webpackMode: "lazy" */
      `./request-info`
    )
);

export const LazyManagerRequestInfo: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ManagerRequestInfo />
  </Suspense>
);
