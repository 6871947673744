import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CurrentMessageWatchedStatus, IChat, IMessage } from 'src/models';
import {
  IChatHistory,
  ICurrentWatchedKam,
  IUserWasDeletedFromChat,
  IUserWasJoinedToChat,
  OrganizationRole,
  RoleFilter,
} from 'src/models/chat';
import { direction } from 'src/components/pages/chat/constants';
import { AuthService } from 'src/services';

interface IInitialState {
  chats: IChat[];
  history: IChatHistory[];
  currentChat: string;
  currentMessage: string;
  haveNewMessage: boolean;
  socketConnectedStatus: boolean;
  currentChatFilterValue: 'ALL' | 'CUSTOMER' | 'CONTRACTOR';
  currentWatchedKam: ICurrentWatchedKam | null;
}

const initialState: IInitialState = {
  chats: [],
  history: [],
  currentChat: '',
  currentMessage: '',
  haveNewMessage: false,
  socketConnectedStatus: false,
  currentChatFilterValue: 'ALL',
  currentWatchedKam: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrentChat(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.currentChat = payload;
    },
    setCurrentMessage(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.currentMessage = payload;
    },
    setCurrentChatFilterValue(state, action: PayloadAction<RoleFilter>) {
      const { payload } = action;
      state.currentChatFilterValue = payload;
    },
    setCurrentWatchedKam(
      state,
      action: PayloadAction<ICurrentWatchedKam | null>
    ) {
      const { payload } = action;
      state.currentWatchedKam = payload;
    },
    setOtherUserStatusMessage(
      state,
      action: PayloadAction<{ conversationId: string; messageId: string }>
    ) {
      const foundIndex = state.history.findIndex(
        (chat) => chat.conversationId === action.payload.conversationId
      );
      if (foundIndex >= 0) {
        const foundMessageIndex = state.history[foundIndex].messages.findIndex(
          ({ id }) => id === action.payload.messageId
        );
        if (foundMessageIndex >= 0) {
          state.history[foundIndex].messages[
            foundMessageIndex
          ].otherUsersStatus = CurrentMessageWatchedStatus.WATCHED;
        }
      }
    },
    setChatList(state, action: PayloadAction<IChat[]>) {
      const { payload } = action;
      if (payload.some((e) => e.unreadMessagesCount > 0)) {
        state.haveNewMessage = true;
      }
      const chatWithOrganizationRole = payload.map((chat) =>
        chat.conversationEntityType
          ? {
              ...chat,
              organizationRole: OrganizationRole[chat.conversationEntityType],
            }
          : { ...chat }
      );

      const chatWithUpdateInfo = chatWithOrganizationRole.map((chat) => ({
        ...chat,
        users: chat.users.map((user) => ({
          ...user,
          fio: `${user.lastName || ''} ${user.firstName || ''} ${
            user.middleName || ''
          }`,
          conversationId: chat.conversationId,
        })),
      }));

      state.chats = chatWithUpdateInfo.map((chat) => ({
        ...chat,
        userFioList: chat.users.reduce(
          (acc, user) => (user.fio ? `${acc + user.fio} ` : `${acc}`),
          ''
        ),
      }));
    },
    setHaveNewMessage(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.haveNewMessage = payload;
    },
    setSocketConnectionStatus(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.socketConnectedStatus = payload;
    },
    deleteUserFromChat(state, action: PayloadAction<IUserWasDeletedFromChat>) {
      const { payload } = action;

      state.chats = state.chats.map((chat) => {
        if (payload.conversationId === chat.conversationId) {
          return {
            ...chat,
            users: chat.users.map((user) => {
              if (user.id === payload.userId) {
                return {
                  ...user,
                  isActive: false,
                };
              }
              return user;
            }),
          };
        }
        return chat;
      });
    },
    deleteYouFromChat(
      state,
      action: PayloadAction<{ conversationId: string }>
    ) {
      const { payload } = action;
      state.chats = state.chats.filter(
        (chat) => chat.conversationId !== payload.conversationId
      );
    },
    addChatToChatList(state, action: PayloadAction<IChat>) {
      const { payload } = action;
      state.chats.push(payload);
    },
    addChatHistory(state, action: PayloadAction<IChatHistory>) {
      const { payload } = action;
      const foundIndex = state.history.findIndex(
        (chat) => chat.conversationId === payload.conversationId
      );
      if (foundIndex >= 0) {
        if (payload.direction === direction.AFTER) {
          state.history[foundIndex] = {
            ...payload,
            hasMessagesBefore: state.history[foundIndex].hasMessagesBefore,
            messages: [
              ...state.history[foundIndex].messages,
              ...payload.messages,
            ],
          };
        } else if (payload.direction === direction.BEFORE) {
          state.history[foundIndex] = {
            ...payload,
            hasMessagesAfter: state.history[foundIndex].hasMessagesAfter,
            messages: [
              ...payload.messages,
              ...state.history[foundIndex].messages,
            ],
          };
        } else if (!payload.direction) {
          state.history[foundIndex] = payload;
        }
      } else {
        state.history.push(payload);
      }
    },
    addNewMessage(state, action: PayloadAction<IMessage>) {
      const { payload } = action;
      action.payload.otherUsersStatus = CurrentMessageWatchedStatus.NOT_WATCHED;
      const { pathname } = window.location;
      if (pathname !== '/chat') state.haveNewMessage = true;
      if (payload.conversationId) {
        const foundIndex = state.history.findIndex(
          (chat) => chat.conversationId === payload.conversationId
        );
        if (foundIndex >= 0) {
          if (!state.history[foundIndex].hasMessagesAfter) {
            state.history[foundIndex].messages.push(payload);
          }
        }
        const foundChatIndex = state.chats.findIndex(
          ({ conversationId }) => conversationId === payload.conversationId
        );
        if (payload.authorUserId !== AuthService.userId) {
          if (foundChatIndex >= 0) {
            state.chats[foundChatIndex].unreadMessagesCount += 1;
          }
        }
      }
    },
    addUserToChat(state, action: PayloadAction<IUserWasJoinedToChat>) {
      const { payload } = action;
      if (payload.conversationId) {
        const foundIndex = state.chats.findIndex(
          (chat) => chat.conversationId === payload.conversationId
        );
        if (foundIndex >= 0) {
          state.chats[foundIndex].users.push(payload.user);
        }
      }
    },
    updateLastMessageInfoInChat(state, action: PayloadAction<IMessage>) {
      const { payload } = action;
      if (payload.conversationId) {
        const foundIndex = state.chats.findIndex(
          (chat) => chat.conversationId === payload.conversationId
        );
        if (foundIndex >= 0) {
          state.chats[foundIndex].lastMessage = payload;
        }
      }
    },
    updateStatusesMessages(state, action: PayloadAction<{ index: number }[]>) {
      const foundIndex = state.history.findIndex(
        (chat) => chat.conversationId === state.currentChat
      );
      if (foundIndex >= 0) {
        action.payload.forEach(({ index }) => {
          state.history[foundIndex].messages[index].currentUserStatus =
            CurrentMessageWatchedStatus.WATCHED;
        });
        const foundChatIndex = state.chats.findIndex(
          (chat) => chat.conversationId === state.currentChat
        );
        if (foundChatIndex >= 0) {
          state.chats[foundChatIndex].unreadMessagesCount -=
            action.payload.length;
        }
      }
    },
    clearChatHistory(state) {
      const foundIndex = state.history.findIndex(
        (chat) => chat.conversationId === state.currentChat
      );
      if (foundIndex >= 0) {
        state.history.splice(foundIndex, 1);
      }
    },
    clearChatHistoryWithoutCurrent(state) {
      state.history.forEach((history) => {
        if (history.conversationId !== state.currentChat) history.messages = [];
      });
    },
    clearChatStore(state) {
      state.chats = [];
      state.history = [];
      state.currentChat = '';
      state.haveNewMessage = false;
      state.currentMessage = '';
      state.currentChatFilterValue = 'ALL';
    },
  },
});

export const {
  addChatHistory,
  addChatToChatList,
  addNewMessage,
  addUserToChat,
  clearChatHistory,
  clearChatHistoryWithoutCurrent,
  clearChatStore,
  deleteUserFromChat,
  deleteYouFromChat,
  setChatList,
  setCurrentChat,
  setCurrentChatFilterValue,
  setCurrentWatchedKam,
  setCurrentMessage,
  setHaveNewMessage,
  setSocketConnectionStatus,
  setOtherUserStatusMessage,
  updateLastMessageInfoInChat,
  updateStatusesMessages,
} = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
