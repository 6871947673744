import {
  StatusBlocked,
  StatusLimited,
  StatusSleep,
  StatusSuccess,
} from 'src/assets/icons';
import { CompanyStatuses } from 'src/models';
import vars from 'src/theme/scss/_vars.module.scss';

export const CompanyStatusIcons: Record<CompanyStatuses, any> = {
  ACTIVE: StatusSuccess,
  LOCKED: StatusBlocked,
  SHOWS_NO_ACTIVITY: StatusSleep,
  LIMITED: StatusLimited,
} as const;

export const CompanyStatusColors: Record<CompanyStatuses, any> = {
  ACTIVE: vars.success,
  LOCKED: vars.danger,
  SHOWS_NO_ACTIVITY: '#6A6A6A',
  LIMITED: vars.danger,
} as const;

export const CompanyStatusTooltip: Record<CompanyStatuses, string> = {
  ACTIVE: 'Активная компания',
  LOCKED: 'Компания заблокирована',
  SHOWS_NO_ACTIVITY:
    'Неактивная (не производит действий на платформе более 2 недель)',
  LIMITED: 'Компания с ограниченным функционалом',
} as const;
