import { secondsToHours, secondsToMinutes } from 'date-fns';

const formatTime = (value: number): string =>
  Math.floor(value).toString().padStart(2, '0');

export const secondsToFormattedTime = (
  value: number,
  mode: 'minutes' | 'hours' | 'days' = 'minutes'
): string => {
  const seconds = value % 60;
  const minutes =
    mode === 'days' || mode === 'hours'
      ? (value / 60) % 60
      : secondsToMinutes(value);
  const hours =
    // eslint-disable-next-line no-nested-ternary
    mode === 'days'
      ? (value / 3600) % 24
      : mode === 'hours'
      ? secondsToHours(value)
      : 0;
  const hoursString = mode === 'hours' ? `${formatTime(hours)}:` : '';
  const daysString = mode === 'days' ? `${Math.floor(value / 86400)}` : '';

  return mode === 'days'
    ? `${daysString} д. ${formatTime(hours)} ч. ${formatTime(
        minutes
      )} мин. ${formatTime(seconds)} с.`
    : `${hoursString}${formatTime(minutes)}:${formatTime(seconds)}`;
};
