import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  IconButton,
  OverflowTooltip,
  Stack,
  SvgIcon,
  Typography,
} from 'src/components/ui';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import { TCardManager } from 'src/components/widgets/manager-card/manager-card';
import classNames from 'classnames';
import { Avatar } from 'src/components/widgets';
import {
  CompanyTypes,
  IBidsCustomerContact,
  IBusinessRequestCustomerInfo,
} from 'src/models';
import { AuthService } from 'src/services';
import { ROLES } from 'src/constants';
import { useHover } from 'src/hooks';
import { CloseIcon, Star } from 'src/assets/icons';
import { AddItem } from 'src/components/pages/company/contacts/components';
import { Popper } from '@mui/material';
import { useAppDispatch } from 'src/store';
import { businessRequestApi } from 'src/api';
import { ICustomerContact } from 'src/models/communications';
import { NameLabel, PhoneLabel, EmailLabel, ActionsBar } from './components';
import styles from './contact-card-light.module.scss';

interface IContactCardProps {
  item:
    | (TVariantContactItem & { isApplicationContact: boolean })
    | TCardManager['manager'];
  onChangeContact?: () => void;
  onSignIn?: () => void;
  onEmailClick?: (email: string | null) => void;
  className?: string;
  children?: ReactElement;
  // onEdit?: (item?: IBidsCustomerContact) => void;
  businessRequest?: IBusinessRequestCustomerInfo;
  businessRequestId: number;
  showGuestAuthAction?: boolean;
  isDetailView: boolean;
  isAllContactsView: boolean;
  onAddContactToApplicationClick: (id: number) => void;
  onSelectContact: (item: ICustomerContact) => void;
}

const isContactItem = (
  item: TVariantContactItem | TCardManager['manager']
): item is TVariantContactItem => !!item && 'email' in item;

export const ContactCardLight = ({
  item,
  onChangeContact,
  onSignIn,
  onEmailClick,
  className,
  children,
  businessRequest,
  businessRequestId,
  showGuestAuthAction,
  isDetailView,
  isAllContactsView,
  onAddContactToApplicationClick,
  onSelectContact,
}: IContactCardProps) => {
  const position = isContactItem(item)
    ? item?.position || 'Должность не указана'
    : item?.position || 'Ответственный менеджер';

  const { currentRole } = AuthService;

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    setIsOpen(isDetailView);
  }, [isDetailView]);

  const [isHovering, eventHandlers] = useHover();

  const dispatch = useAppDispatch();

  const isEditButtonVisible = useMemo(
    () =>
      currentRole &&
      [ROLES.ADMIN, ROLES.KAM, ROLES.KAM_PLUS].includes(currentRole as any),
    [currentRole]
  );

  const [openEditForm, setOpenEditForm] = useState(false);
  const [itemForEdit, setItemForEdit] = useState<IBidsCustomerContact>();

  const toggleOpenEditForm = useCallback(() => {
    setOpenEditForm((prev) => !prev);
    setItemForEdit(undefined);
  }, []);

  const onEditClick = (contact?: IBidsCustomerContact) => {
    setItemForEdit(contact);
    setOpenEditForm(true);
  };

  const handleOnSave = () => {
    setOpenEditForm(false);

    dispatch(
      businessRequestApi.util?.invalidateTags([
        {
          type: 'getCustomerInfo',
          id: businessRequestId,
        },
      ])
    );
  };

  return (
    <>
      <Stack
        direction="row"
        gap="12px"
        className={classNames(
          styles.card,
          className,
          isOpen && styles.cardOpen
        )}
        onClick={toggleIsOpen}
        {...eventHandlers}>
        <div className={styles.avatarWrapper}>
          <Avatar
            userInfo={{
              firstName: item?.firstName || '',
              lastName: item?.lastName || '',
            }}
            borderColor="#0041A0"
            className={styles.avatar}
          />
          {isAllContactsView && (
            <div className={styles.avatarBadge}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onAddContactToApplicationClick(item?.id!);
                }}>
                <SvgIcon
                  width="16"
                  height="16"
                  backgroundFill={
                    (item as any)?.isApplicationContact ? '#002F6C' : '#F3F6FB'
                  }
                  className={classNames(
                    styles.avatarIcon,
                    (item as any)?.isApplicationContact &&
                      styles.avatarIconFilled
                  )}
                  icon={Star}
                />
              </IconButton>
            </div>
          )}
        </div>

        <Stack className={styles.userInfo}>
          <NameLabel
            isStar={isContactItem(item) && !!item?.isMainContact}
            user={item}
          />

          <OverflowTooltip fontSize="10px" color="#838383" text={position} />

          {isOpen && (
            <>
              <PhoneLabel user={item} />

              <EmailLabel
                user={item}
                isContactItem={isContactItem(item)}
                businessRequest={businessRequest}
                onEmailClick={onEmailClick}
              />
            </>
          )}
        </Stack>

        {isOpen && isHovering && (
          <ActionsBar
            user={item}
            isContactItem={isContactItem(item)}
            onEdit={onEditClick}
            showGuestAuthAction={showGuestAuthAction}
            onSelectContact={onSelectContact}
            className={styles.actionsBar}
            isEditButtonVisible={isEditButtonVisible || false}
            businessRequest={businessRequest}
          />
        )}
      </Stack>
      <Popper
        open={openEditForm}
        className={styles.popper}
        style={{
          position: 'fixed',
          top: '0',
          right: '0',
          left: 'unset',
          zIndex: 1200,
          backgroundColor: '#FFFFFF',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          flex: '1 0 auto',
          outline: '0px',
          width: '584px',
        }}>
        <div className={styles.header}>
          <Typography variant="h2">Редактировать контакт</Typography>
          <IconButton aria-label="close" onClick={toggleOpenEditForm}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.content}>
          <AddItem
            defaultItem={itemForEdit}
            onSave={handleOnSave}
            onCancel={toggleOpenEditForm}
            companyTypeDefined={CompanyTypes.CUSTOMER}
          />
        </div>
      </Popper>
    </>
  );
};
