import { createApi } from '@reduxjs/toolkit/query/react';
import { IAuth, TRegistrationRequest } from 'src/models';
import { baseQuery } from 'src/store/utils';
import { AuthService } from 'src/services';

const BASE_PATH = 'mph-security-provider/api';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<
      IAuth,
      {
        login: string;
        password: string;
        dataProcessingConfirm: boolean;
      }
    >({
      query: (data) => ({
        url: `${BASE_PATH}/v1/authorization/login`,
        method: 'POST',
        body: data,
      }),
    }),
    loginAsGuest: builder.mutation<
      { guestToken: string },
      { onBehalfUserId: number }
    >({
      query: (data) => ({
        url: `${BASE_PATH}/v1/authorization/login-as-guest`,
        method: 'POST',
        body: data,
        headers: {
          authorization: `Bearer ${AuthService.parentAccessToken}`,
          'Current-Role': `${AuthService.parentCurrentRole}`,
        },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `${BASE_PATH}/v1/authorization/logout`,
        method: 'POST',
      }),
    }),
    refreshToken: builder.mutation<IAuth, { refreshToken: string }>({
      query: (data) => ({
        url: `${BASE_PATH}/v1/authorization/refresh-token`,
        method: 'POST',
        body: data,
      }),
    }),
    forgotPassword: builder.mutation<void, { login: string }>({
      query: (data) => ({
        url: `${BASE_PATH}/v1/password/forgot-password`,
        method: 'POST',
        body: data,
      }),
    }),
    restorePassword: builder.mutation<
      void,
      { newPassword: string; confirmToken: string }
    >({
      query: (data) => ({
        url: `${BASE_PATH}/v1/password/restore-password`,
        method: 'POST',
        body: data,
      }),
    }),
    registration: builder.mutation<{ userId: number }, TRegistrationRequest>({
      query: (body) => ({
        url: `${BASE_PATH}/v1/self-registration/contragent`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginAsGuestMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useRestorePasswordMutation,
  useRegistrationMutation,
  useRefreshTokenMutation,
} = authApi;
