import {
  SvgIcon,
  Stack,
  Link,
  Typography,
  PhoneInput,
  IconButton,
  Menu,
  MenuItem,
} from 'src/components/ui';
import { ArrowDown, Phone } from 'src/assets/icons';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import { TCardManager } from 'src/components/widgets/manager-card/manager-card';
import { MouseEvent, useState } from 'react';

interface IPhoneLabelProps {
  user: TVariantContactItem | TCardManager['manager'];
}

export const PhoneLabel = ({ user }: IPhoneLabelProps) => {
  const [anchorElPhones, setAnchorElPhones] = useState<null | HTMLElement>(
    null
  );
  const isPhonesMenuOpen = Boolean(anchorElPhones);

  const handleOpenPhonesMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorElPhones(event.currentTarget);
  };

  const handleClosePhonesMenu = (event: any) => {
    event.stopPropagation();
    setAnchorElPhones(null);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="8px"
      justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap="8px">
        {user?.contactInfoDto.phones[0]?.number ? (
          <Link href={`tel:${user.contactInfoDto.phones[0].number}`}>
            <SvgIcon
              width="16px"
              height="16px"
              strokeHexColor="#0041A0"
              icon={Phone}
            />
          </Link>
        ) : (
          <SvgIcon
            width="16px"
            height="16px"
            strokeHexColor="#0041A0"
            icon={Phone}
          />
        )}
        {user?.contactInfoDto.phones[0] &&
          user?.contactInfoDto.phones[0]?.type !== 'MOBILE' && (
            <Link href={`tel:${user?.contactInfoDto.phones[0]?.number}`}>
              <Typography fontSize="12px" color="#000000">
                <PhoneInput
                  displayType="text"
                  value={user?.contactInfoDto.phones[0]}
                  textSx={{
                    fontSize: 12,
                    color: '#000',
                  }}
                />
              </Typography>
            </Link>
          )}

        {user?.contactInfoDto.phones[0] &&
          user?.contactInfoDto.phones[0]?.type === 'MOBILE' && (
            <Typography fontSize="12px" color="#000000">
              <PhoneInput
                displayType="text"
                value={user?.contactInfoDto.phones[0]}
                textSx={{
                  fontSize: 12,
                  color: '#000',
                }}
              />
            </Typography>
          )}

        {!user?.contactInfoDto.phones[0] && (
          <Typography fontSize="12px" color="#000000">
            Не указано
          </Typography>
        )}
      </Stack>

      {!!user?.contactInfoDto?.phones?.length &&
        user?.contactInfoDto?.phones?.length > 1 && (
          <IconButton onClick={handleOpenPhonesMenu}>
            <ArrowDown />
          </IconButton>
        )}

      <Menu
        anchorEl={anchorElPhones}
        open={isPhonesMenuOpen}
        onClose={handleClosePhonesMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {user?.contactInfoDto?.phones?.length &&
          user?.contactInfoDto?.phones?.length > 1 &&
          user?.contactInfoDto?.phones.map((phone) => (
            <MenuItem key={phone?.number}>
              <Stack direction="row" alignItems="center" gap="8px">
                <Link href={`tel:${phone?.number}`}>
                  <SvgIcon
                    width="16px"
                    height="16px"
                    strokeHexColor="#0041A0"
                    icon={Phone}
                  />
                </Link>
                {/* TODO: Почему в PhoneInput кликабелен только Mobile? */}
                {phone.type !== 'MOBILE' ? (
                  <Link href={`tel:${phone?.number}`}>
                    <Typography fontSize="12px" color="#000000">
                      <PhoneInput
                        displayType="text"
                        value={phone}
                        textSx={{
                          fontSize: 12,
                          color: '#000',
                        }}
                      />
                    </Typography>
                  </Link>
                ) : (
                  <Typography fontSize="12px" color="#000000">
                    <PhoneInput
                      displayType="text"
                      value={phone}
                      textSx={{
                        fontSize: 12,
                        color: '#000',
                      }}
                    />
                  </Typography>
                )}
              </Stack>
            </MenuItem>
          ))}
      </Menu>
    </Stack>
  );
};
