import { compareAsc, compareDesc } from 'date-fns';
import { TSortKey } from 'src/components/pages/contractors/config';
import { DIRECTION, TSort } from 'src/components/ui/filters/types';
import { TContractorList } from 'src/models';

export const sortContractors = (
  contractors: TContractorList['items'],
  sort: TSort<TSortKey>
) =>
  contractors.map((column) => ({
    ...column,
    organizations: [...column.organizations].sort((a, b) => {
      if (
        sort.field === 'totalCount' &&
        a.contractorRequestsInfo &&
        b.contractorRequestsInfo
      ) {
        return sort.direction === DIRECTION.DESC
          ? a.contractorRequestsInfo.totalCount -
              b.contractorRequestsInfo.totalCount
          : b.contractorRequestsInfo.totalCount -
              a.contractorRequestsInfo.totalCount;
      }

      return (sort.direction === DIRECTION.ASC ? compareAsc : compareDesc)(
        new Date(a.createdAt),
        new Date(b.createdAt)
      );
    }),
  }));
