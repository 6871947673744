import { FC, useCallback, useState, useEffect } from 'react';
import { Autocomplete, Form, InfoRow, Chip, SvgIcon } from 'src/components/ui';
import { useGetManagerContractorWinnerCriterionQuery } from 'src/api/business-request/specification';
import { Cross } from 'src/assets/icons';
import { TagsCellGray } from 'src/components/ui/table/table-cells/tags-cell-gray/tags-cell-gray';
import { IComponentsProp } from '../../types';

export const CriteriaSelectingWinner: FC<IComponentsProp> = ({ viewMode }) => {
  const [winnerCriterionList, setWinnerCriterionList] = useState<string[]>();
  const { data, isLoading } = useGetManagerContractorWinnerCriterionQuery();

  const fetchApiFilter = useCallback(
    (value: string) =>
      Promise.resolve(
        (value
          ? winnerCriterionList?.filter((element) =>
              element.toLowerCase().includes(value.toLowerCase())
            )
          : winnerCriterionList) || []
      ),
    [winnerCriterionList]
  );

  useEffect(() => {
    if (!data) return;

    setWinnerCriterionList(data.map((element) => element.name));
  }, [data]);

  return (
    <Form.Item
      required
      name="winnerCriteria"
      label="Критерии выбора победителя"
      viewMode={viewMode}
      renderView={(value) => (
        <InfoRow
          label="Критерии выбора победителя"
          value={value && <TagsCellGray tags={value} tooltipWidth={350} />}
        />
      )}>
      <Autocomplete
        disabled={isLoading}
        multiple
        disableCloseOnSelect
        options={winnerCriterionList}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option || ''}
        fetchApi={fetchApiFilter}
        fetchOnEmptyInput
        placeholder="Выбрать"
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              deleteIcon={
                <SvgIcon
                  icon={Cross}
                  strokeColor="#292929"
                  width="8px"
                  height="8px"
                />
              }
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </Form.Item>
  );
};
