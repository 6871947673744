import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  OutlinedInput,
  Box,
  Typography,
} from 'src/components/ui';
import { maxLength, required } from 'src/utils/form-rules';
import { FIELD_LENGTH } from 'src/constants';
import { CompanyTypes } from 'src/models';
import { getErrorMessage } from 'src/store/utils';
import { useAddContractorMutation } from 'src/api/contractor';
import { useAddCustomerMutation } from 'src/api/customer';
import {
  useLazyGetOrganizationsListByInnQuery,
  useLazyGetOrganizationByInnQuery,
} from 'src/api/dadata';
import { useCreateContractMutation } from 'src/api/contract';

type TAddNewOrganizationProps = {
  type: CompanyTypes;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (data: { id: number }) => void;
};

type TFormData = {
  name: string;
  innInfo: {
    organizationName: string;
    inn: string;
    address: string;
    active: boolean;
  } | null;
};

export const AddNewOrganization: FC<TAddNewOrganizationProps> = ({
  type,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [addNewContractor] = useAddContractorMutation();
  const [addNewCustomer] = useAddCustomerMutation();
  const [createContract] = useCreateContractMutation();
  const [getOrganizationsListByInn] = useLazyGetOrganizationsListByInnQuery();
  const [getOrganizationByInn, { isLoading: innLoading }] =
    useLazyGetOrganizationByInnQuery();

  const form = useForm<TFormData>({
    defaultValues: {
      name: '',
      innInfo: null,
    },
  });
  const closeWithReset = () => {
    onClose();
    form.reset();
  };

  const fetchOrganizations = useCallback(
    (inn: string) => {
      if (
        inn.length < 2 ||
        inn.length > FIELD_LENGTH.inn[1] ||
        inn.includes(' ') ||
        Number.isNaN(Number(inn))
      ) {
        return Promise.resolve([]);
      }

      return getOrganizationsListByInn(inn)
        .unwrap()
        .then((res) => res.slice(0, 5));
    },
    [getOrganizationsListByInn]
  );

  const onSubmit: SubmitHandler<TFormData> = async ({ name, innInfo }) => {
    let result;

    const requestData = {
      name,
      inn: innInfo?.inn || undefined,
    };

    setIsLoading(true);

    try {
      if (type === CompanyTypes.CONTRACTOR) {
        result = await addNewContractor(requestData).unwrap();
      } else {
        result = await addNewCustomer(requestData).unwrap();
      }
      await createContract({ organizationId: result.id }).unwrap();

      closeWithReset();
      onSuccess?.(result);
    } catch (e) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(e, 'Произошла ошибка при создании компании'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onInnChange = async () => {
    const innInfo = form.getValues('innInfo');
    if (!innInfo) return;
    try {
      await getOrganizationByInn(innInfo.inn)
        .unwrap()
        .then((res) => {
          form.setValue('name', res.organizationFullName);
        });
    } catch (e) {
      form.setError('innInfo', {
        type: 'custom',
        message: getErrorMessage(
          e,
          'Произошла ошибка при получении данных из DaData'
        ),
      });
    }
  };

  return (
    <Dialog open={isOpen} close={closeWithReset}>
      <DialogTitle>Новая компания</DialogTitle>

      <DialogContent sx={{ maxWidth: '100%', width: '580px' }}>
        <Form
          form={form}
          onSubmit={onSubmit}
          onCancel={closeWithReset}
          preloading={innLoading}
          loading={isLoading}
          submitButtonProps={{
            text: 'Добавить компанию',
          }}
          cancelConfirmText="Вы уверены, что хотите закрыть форму без сохранения информации?">
          <Form.Item
            name="name"
            label="Название компании"
            rules={{
              required,
              maxLength: maxLength(FIELD_LENGTH.organizationFullName),
            }}>
            <OutlinedInput multiline />
          </Form.Item>
          <Form.Item
            name="innInfo"
            label="ИНН"
            rules={{
              required:
                type === CompanyTypes.CONTRACTOR
                  ? { value: true, message: 'Введен неверный ИНН' }
                  : undefined,
            }}>
            <Autocomplete
              getOptionLabel={(option) => option.inn || ''}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  style={{
                    textDecoration: option.active ? 'none' : 'line-through',
                  }}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h4">
                      {option.organizationName}
                    </Typography>
                    <Typography color="secondary.light">
                      ИНН {option.inn} Адрес {option.address}
                    </Typography>
                  </Box>
                </Box>
              )}
              isOptionEqualToValue={(option, value) => option.inn === value.inn}
              onChange={() => {
                onInnChange();
              }}
              freeSolo
              fetchOnEmptyInput
              fetchApi={fetchOrganizations}
            />
          </Form.Item>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
