import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { DIRECTION } from 'src/components/ui/filters/types';
import { TNotificationItem } from 'src/models';
import { TNotificationTemplateRequest } from 'src/models/admin';
import { RecipientTypes } from 'src/components/pages/admin/notifications/constants';

export type TNotificationsResponse = {
  totalCount: number;
  page: number;
  items: TNotificationItem[];
};

export type TNotificationsParams = {
  paging: {
    page: number;
    limit: number;
  };
  sort: {
    field: string;
    order: DIRECTION;
  };
  filter?: {
    userType: RecipientTypes;
    active: boolean;
  }[];
  searchQuery: string[];
};

export type TNotificationsStatus = {
  archived: boolean;
  templateIds: Array<number>;
};

export const adminNotificationsApi = createApi({
  reducerPath: 'adminNotificationsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getNotifications: builder.mutation<
      TNotificationsResponse,
      TNotificationsParams
    >({
      query: (body) => ({
        url: 'notification-service/api/v1/templates',
        method: 'POST',
        body,
      }),
    }),
    updateNotificationTemplate: builder.mutation<
      TNotificationItem,
      TNotificationTemplateRequest
    >({
      query: (body) => ({
        url: `notification-service/api/v1/templates/${body.id}`,
        method: 'PATCH',
        body,
      }),
    }),
    updateNotificationStatus: builder.mutation<void, TNotificationsStatus>({
      query: (body) => ({
        url: 'notification-service/api/v1/templates/archive-status',
        method: 'PATCH',
        body,
      }),
    }),
    getSignatureNotification: builder.query<
      {
        id: string;
        bodySignature: string;
        templateDescription: string;
      },
      string
    >({
      query: (body) => ({
        url: 'notification-service/api/v1/templates/emailSignatures',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
  }),
});

export const {
  useGetNotificationsMutation,
  useUpdateNotificationTemplateMutation,
  useUpdateNotificationStatusMutation,
  useLazyGetSignatureNotificationQuery,
} = adminNotificationsApi;
