import { isValid } from 'date-fns';
import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { DatePicker, Form } from 'src/components/ui';
import {
  TFilterItemBaseProps,
  TRangeFilterValue,
} from 'src/components/ui/new-filters/types';
import { getServerDate } from 'src/utils';
import styles from './date-picker.module.scss';
import { getDateRangeValueFromStrings } from './utils';

export type DatePickerFormProps<FilterKeys extends string = string> = {
  filterKey: string;
  maxToDate?: Date;
  maxFromDate?: Date;
} & TFilterItemBaseProps<FilterKeys>;

export const DatePickerForm = <FilterKeys extends string = string>({
  filter,
  updateFilterValue,
  filterKey,
  maxToDate,
  maxFromDate,
}: DatePickerFormProps<FilterKeys>) => {
  const form = useForm<{
    from: string | Date | null;
    to: string | Date | null;
  }>({
    defaultValues: {
      from: null,
      to: null,
    },
  });
  useEffect(() => {
    if (filter) {
      const fromDateString = (filter.value as TRangeFilterValue).from;
      const toDateString = (filter.value as TRangeFilterValue).to;
      if (isValid(new Date(fromDateString))) {
        form.setValue('from', new Date(getServerDate(fromDateString)));
      }
      if (isValid(new Date(toDateString))) {
        form.setValue('to', new Date(getServerDate(toDateString)));
      }
    } else {
      form.clearErrors();
      form.setValue('from', null);
      form.setValue('to', null);
    }
  }, [filter]);
  const handleChange = () => {
    const values = form.getValues();
    form.clearErrors();
    if (!!values.from && !isValid(values.from)) {
      form.setError('from', {});
    }

    if (!!values.to && !isValid(values.to)) {
      form.setError('to', {});
    }

    const rangeValue = getDateRangeValueFromStrings(
      filterKey,
      values.from,
      values.to
    );
    updateFilterValue(rangeValue);
  };

  const currentDate = new Date();

  return (
    <div className={styles.datePickerContainer}>
      <Form form={form} footer={false}>
        <Form.Item noMargin name="from">
          <DatePicker
            maxDate={maxFromDate ?? currentDate}
            placeholder="Выберите дату от"
            onChange={() => {
              handleChange();
            }}
          />
        </Form.Item>
        <Form.Item noMargin name="to">
          <DatePicker
            maxDate={maxToDate ?? currentDate}
            placeholder="Выберите дату до"
            onChange={() => {
              handleChange();
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
