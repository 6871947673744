import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const CompanyContacts = lazy(
  () =>
    import(
      /* webpackChunkName: 'CompanyContacts' */
      /* webpackMode: "lazy" */
      `./contacts`
    )
);

export const LazyCompanyContacts: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <CompanyContacts />
  </Suspense>
);
