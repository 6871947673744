import { Stack, Typography } from 'src/components/ui';
import { incorrectScreenBackground } from 'src/assets';
import styles from './incorrect-screen-modal.module.scss';

export const IncorrectScreenModal = () => (
  <div className={styles.wrapper}>
    <img src={incorrectScreenBackground} alt="incorrect screen" />
    <Stack gap="12px" maxWidth="800px">
      <Typography
        fontWeight="600"
        fontSize="40px"
        color="#0041A0"
        lineHeight="48px"
        align="center">
        Ой! Кажется что-то пошло не так
      </Typography>
      <Typography
        color="#7A8694"
        fontSize="16px"
        align="center"
        lineHeight="28px"
        display="inline">
        В вашем браузере установлен слишком большой масштаб экрана, для
        продолжения работы измените его с помощью комбинации клавиш{' '}
        <Stack direction="row" gap="8px" display="inline-flex">
          <Typography
            display="inline"
            color="#292929"
            className={styles.highlightedText}>
            CTRL
          </Typography>{' '}
          <Typography
            display="inline"
            color="#292929"
            fontSize="16px"
            lineHeight="30px">
            +
          </Typography>
          <Typography
            display="inline"
            color="#292929"
            className={styles.highlightedText}>
            —
          </Typography>
        </Stack>{' '}
        и попробуйте снова
      </Typography>
    </Stack>
  </div>
);
