import TableCell from '@mui/material/TableCell';

import { CellType } from './types';

export const Cell = <RecordType,>(props: CellType<RecordType>) => {
  const { column, index, record, onClick } = props;

  const data = record[column.key] as string;
  const Component = column.render && column.render(record, index);

  return (
    <TableCell onClick={onClick} {...column.setting}>
      {Component || data}
    </TableCell>
  );
};
