import {
  TTechnicalOpportunitiesCategories,
  TTechnicalOpportunity,
} from 'src/models';
import { TChip, TCustomTag } from 'src/components/ui/new-filters/types';
import { getMaxPosition } from 'src/utils';

type TRequestParamsTags = TTechnicalOpportunitiesCategories;

export const deleteTagFromRequest = (
  selectedTags: TRequestParamsTags,
  deleteItem: TCustomTag,
  tagsList: TTechnicalOpportunity[]
) => {
  const selectedTagsWithName = selectedTags.map((tag) => {
    const currentTag = tagsList.find(
      (category) => category.id === tag.categoryId
    );
    const currentSubCategories = tag.subcategoryIds.map(
      (selectedSubCategoryId) => {
        const currentSubCategory = currentTag?.subcategories.find(
          (subCategory) => selectedSubCategoryId === subCategory.id
        );
        return {
          subCategoryId: selectedSubCategoryId,
          name: currentSubCategory?.name ?? '',
        };
      }
    );
    return {
      categoryId: tag.categoryId,
      name: currentTag?.name ?? '',
      subCategories: currentSubCategories,
    };
  });
  return selectedTagsWithName.reduce((newTags: TRequestParamsTags, tag) => {
    if (
      tag.categoryId !== deleteItem.value ||
      tag.subCategories.some((item) => item.name === deleteItem.label)
    ) {
      const subCategories = tag.subCategories.filter(
        (subcategory) =>
          !(
            subcategory.subCategoryId === Number(deleteItem.value) &&
            subcategory.name === deleteItem.label
          )
      );
      if (subCategories.length) {
        newTags.push({
          categoryId: tag.categoryId,
          subcategoryIds: subCategories.map((item) => item.subCategoryId),
        });
      }
    } else if (tag.name !== deleteItem.label) {
      newTags.push({
        categoryId: tag.categoryId,
        subcategoryIds: tag.subCategories.map((item) => item.subCategoryId),
      });
    }
    return newTags;
  }, []);
};

export const getCustomTags = (
  selectedTags: TRequestParamsTags,
  tagsList: TTechnicalOpportunity[]
) =>
  selectedTags.reduce(
    (customTags: Pick<TChip, 'label' | 'value'>[], selectedTag) => {
      tagsList.forEach((tag) => {
        if (tag.id === selectedTag.categoryId) {
          if (tag.subcategories.length === selectedTag.subcategoryIds.length) {
            customTags.push({
              value: tag.id,
              label: tag.name,
            });
          } else {
            selectedTag.subcategoryIds.forEach((selectedSubcategory) => {
              const dataSelectedSubCategory = tag.subcategories.find(
                (subcategory) => subcategory.id === selectedSubcategory
              );
              if (dataSelectedSubCategory) {
                customTags.push({
                  value: dataSelectedSubCategory.id,
                  label: dataSelectedSubCategory.name,
                });
              }
            });
          }
        }
      });
      return customTags;
    },
    []
  );

export const getTagsForRequestParams = (tags: TTechnicalOpportunity[]) =>
  tags.reduce((requestParams: TRequestParamsTags, tag) => {
    if (tag.checked) {
      const subCategorySelected = tag.subcategories.length
        ? tag.subcategories
            .filter((subCategory) => subCategory.checked)
            .map((subCategory) => subCategory.id)
        : [];
      requestParams.push({
        categoryId: tag.id,
        subcategoryIds: subCategorySelected,
      });
    }
    return requestParams;
  }, []);

export const getDefaultTechnicalOpportunity = (
  tagsList: TTechnicalOpportunity[],
  requestParams: TRequestParamsTags
) =>
  tagsList.map((tag) => {
    const selectedTag = requestParams.find(
      (item) => item.categoryId === tag.id
    );
    return selectedTag
      ? {
          ...tag,
          checked: true,
          subcategories: tag.subcategories.map((subCategory) => ({
            ...subCategory,
            checked: selectedTag.subcategoryIds.includes(subCategory.id),
          })),
        }
      : tag;
  });

export const getPositionsTags = (
  currentPositions: Record<string, number>,
  tags: TTechnicalOpportunity[]
): Record<string, number> => {
  const lastPosition = getMaxPosition(currentPositions);
  return tags.reduce((positions: Record<string, number>, tag, index) => {
    if (tag.checked) {
      const selectedSubCategories = tag.subcategories.reduce(
        (subCategories: string[], subCategory) => {
          if (subCategory.checked) {
            subCategories.push(`${subCategory.id}${subCategory.name}`);
          }
          return subCategories;
        },
        []
      );
      if (selectedSubCategories.length === tag.subcategories.length) {
        const keyTag = `${tag.id}${tag.name}`;
        positions = {
          ...positions,
          [keyTag]: currentPositions[keyTag] ?? lastPosition + index + 1,
        };
      } else {
        selectedSubCategories.forEach((selectedSubCategory, indexCategory) => {
          positions = {
            ...positions,
            [selectedSubCategory]:
              currentPositions[selectedSubCategory] ??
              lastPosition + index + indexCategory + 2,
          };
        });
      }
    }

    return positions;
  }, {});
};
