import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';
import { CompanyTypes } from 'src/models';

const CompanyLayout = lazy(
  () =>
    import(
      /* webpackChunkName: 'CompanyLayout' */
      /* webpackPrefetch: true */
      /* webpackMode: "lazy" */
      `./company-layout`
    )
);

export const LazyCompanyLayout: FC<{
  companyId?: number;
  companyType?: CompanyTypes;
  hideManagerCard?: boolean;
}> = (props) => (
  <Suspense fallback={<PageLoader />}>
    <CompanyLayout {...props} />
  </Suspense>
);
