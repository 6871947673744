import { TableCell, TableHead, TableRow } from '@mui/material';
import { Checkbox } from 'src/components/ui';
import { ChangeEvent } from 'react';
import { ColumnsType, TSearchFilters } from 'src/components/ui/new-table/types';

import styles from './header.module.scss';
import { ThCell } from './components';

interface HeaderProps<RecordType> {
  columns: ColumnsType<RecordType>;
  selected?: RecordType[];
  rowCount: number;
  onSelectAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFilter?: (filters: TSearchFilters<RecordType>) => void;
}

export const Header = <RecordType,>({
  columns,
  rowCount,
  onSelectAllClick,
  selected,
  onFilter,
}: HeaderProps<RecordType>) => {
  const selectedCount = selected?.length ?? 0;

  return (
    <TableHead sx={{ borderRadius: '12px' }} className={styles.header}>
      <TableRow className={styles.headerContent}>
        {onSelectAllClick ? (
          <TableCell sx={{ width: '20px' }} className={styles.thCell}>
            <Checkbox
              className={styles.checkbox}
              indeterminate={selectedCount > 0 && selectedCount < rowCount}
              checked={rowCount > 0 && selected?.length === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        ) : null}

        {columns.map((column) => (
          <ThCell<RecordType>
            key={column.title}
            column={column}
            onFilter={onFilter}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};
