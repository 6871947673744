import { SvgIcon } from 'src/components/ui';
import {
  Support,
  MenuLogout,
  MenuProfile,
  MenuPassword,
} from 'src/assets/icons';
import { ROLES } from 'src/constants';

const SVG_ICON_PROPS = {
  color: '#0041A0',
  width: '24px',
  height: '24px',
};

type TMenuItemKeys = 'SUPPORT' | 'LOGOUT' | 'PROFILE' | 'PASSWORD';

type TItemKeyToPropsMap = Record<
  TMenuItemKeys,
  { title: string; icon: any; color: string }
>;

const MENU_ITEM_KEY_TO_PROPS_MAP: TItemKeyToPropsMap = {
  SUPPORT: {
    title: 'Техническая поддержка',
    icon: <SvgIcon icon={Support} {...SVG_ICON_PROPS} />,
    color: '#000000',
  },
  LOGOUT: {
    title: 'Выйти из системы',
    icon: <SvgIcon icon={MenuLogout} {...SVG_ICON_PROPS} color="#E11111" />,
    color: '#E11111',
  },
  PROFILE: {
    title: 'Мой профиль',
    icon: <SvgIcon icon={MenuProfile} {...SVG_ICON_PROPS} />,
    color: '#000000',
  },
  PASSWORD: {
    title: 'Сменить пароль',
    icon: <SvgIcon icon={MenuPassword} {...SVG_ICON_PROPS} />,
    color: '#000000',
  },
};

export const ROLE_TO_ITEMS_MAP: Record<
  ROLES,
  Array<TItemKeyToPropsMap[TMenuItemKeys]>
> = {
  [ROLES.ADMIN]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.SYSTEM]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.KAM]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.KAM_PLUS]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.CUSTOMER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.CONTRACTOR]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],

  [ROLES.TECH_EXPERT]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.BACK_OFFICE]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.COMMERCIAL_DIRECTOR]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.LOGISTIC]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.PARTNER_RELATIONS_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.GENERAL_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.FINANCIAL_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.OFFICE_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
  [ROLES.LAWYER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.PROFILE,
    MENU_ITEM_KEY_TO_PROPS_MAP.PASSWORD,
    MENU_ITEM_KEY_TO_PROPS_MAP.SUPPORT,
    MENU_ITEM_KEY_TO_PROPS_MAP.LOGOUT,
  ],
};
