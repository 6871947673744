import classNames from 'classnames';
import styles from './checkbox-form-item-chip.module.scss';

export interface ICheckboxFormItemChipProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  label: string;
}

const CheckboxFormItemChip = ({
  checked,
  onChange,
  label,
}: ICheckboxFormItemChipProps) => (
  <div
    className={classNames(styles.chip, checked && styles.activeChip)}
    onClick={() => onChange(!checked)}>
    {label}
  </div>
);

export default CheckboxFormItemChip;
