import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROLES } from 'src/constants';
import { AuthService } from 'src/services';

type TKanbanInitialState = {
  lastKanbanUserRole: ROLES | null;
  kanbanScrollPosition: {
    top: number;
    left: number;
    scrollWidth: number;
  };
  navigatedToKanbanFrom: string | null;
};

const initialState: TKanbanInitialState = {
  lastKanbanUserRole: AuthService.currentRole,
  kanbanScrollPosition: {
    top: 0,
    left: 0,
    scrollWidth: 0,
  },
  navigatedToKanbanFrom: null,
};

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    updateKanbanScrollPosition: (
      state,
      {
        payload,
      }: PayloadAction<{
        top: number;
        left: number;
        scrollWidth: number;
      }>
    ) => {
      state.kanbanScrollPosition = payload;
    },

    resetKanbanScrollPosition: (state) => {
      state.kanbanScrollPosition = {
        top: 0,
        left: 0,
        scrollWidth: 0,
      };
    },

    updateNavigatedToKanbanFrom: (
      state,
      { payload }: PayloadAction<{ navigatedToKanbanFrom: string | null }>
    ) => {
      state.navigatedToKanbanFrom = payload.navigatedToKanbanFrom;
    },

    updateLastKanbanUserRole: (
      state,
      { payload }: PayloadAction<{ lastKanbanUserRole: ROLES | null }>
    ) => {
      state.lastKanbanUserRole = payload.lastKanbanUserRole;
    },
  },
});

export const kanbanReducer = kanbanSlice.reducer;
export const {
  updateKanbanScrollPosition,
  resetKanbanScrollPosition,
  updateNavigatedToKanbanFrom,
  updateLastKanbanUserRole,
} = kanbanSlice.actions;
