import { TCargoParametersFull } from 'src/models/cargo-parameters';
import { IBusinessRequestListPerformersDto } from 'src/models/bids-details';
import { CompanyStatuses } from 'src/models/company';
import { ContractStatuses } from 'src/models/contract';
import { TTechnicalOpportunity } from 'src/models/technical-opportunity';
import { RebiddingContractorStatus } from 'src/models/rebidding/contractor-rebidding';

export enum RebiddingStatus {
  CREATED = 'CREATED',
  CLOSED = 'CLOSED',
}

export type TCommonRebidding = {
  initialMaximumCost: string | number;
  commercialOfferDueDate: string;
  comment: string;
  shippingIncluded: boolean;
  shipping: {
    cargoDescription: TCargoParametersFull;
    deliveryAddress: string;
  };
};

export type TRebidding = TCommonRebidding & {
  rebiddingId: number;
  businessRequestId: number;
  number: number;
  status: {
    code: RebiddingStatus;
    displayName: string;
    createdAt: string;
  };
};

export type TRebiddingListPerformersDto = Omit<
  IBusinessRequestListPerformersDto,
  'body'
> & {
  body: Omit<IBusinessRequestListPerformersDto['body'], 'filter'>;
};

export type TRebiddingListPerformers = {
  page: number;
  totalCount: number;
  items: {
    contractorRequestId: number;
    rebiddingContractorRequestId: number;
    organizationId: number;
    organizationName: string;
    organizationStatus: {
      id: number;
      statusName: string;
      description: string;
      code: CompanyStatuses;
    };
    contractId: number;
    contractStatus: ContractStatuses;
    delivery: {
      region: string;
      city: string | null;
      distanceToCustomer: number | null;
      distanceToCustomerErrorMessage: string | null;
      shippingCost: number | null;
      shippingCostErrorMessage: string | null;
      shippingAddressKladrId: string | null;
    };
    mainPresentationId: number | null;
    technicalOpportunitiesCategories: TTechnicalOpportunity[];
    currentStatus: {
      code: RebiddingContractorStatus;
      displayName: string;
      createdAt: string;
    };
    statusHistory: [
      {
        code: string;
        displayName: string;
        createdAt: string;
      }
    ];
    exclusion: {
      cause: string;
      createdAt: string;
    } | null;
    refusal: {
      id: number;
      cause: string;
      createdAt: string;
      comment: string;
    } | null;
    contractorRequestRefusal: {
      createdAt: string;
      comment: string;
      cause: string;
    } | null;
    contractorRequestExclusion: {
      createdAt: string;
      cause: string;
    } | null;
  }[];
};
