import { ButtonGroup, Typography } from '@mui/material';
import classNames from 'classnames';
import { Box, Button, Chip as ChipMui } from 'src/components/ui';

import { FilterTypes, FiltersProps } from './types';

import styles from './filters.module.scss';

import { Chip, HiddenChips, SortMenu } from './components';
import { FiltersPopover } from './components/filters-popover';
import { SearchForm } from './components/search-form';
import { useFilters } from './hooks';

export const Filters = <KeysSorting,>(props: FiltersProps<KeysSorting>) => {
  const {
    sortList,
    onSort,
    rightBlock: RightBlock,
    isDisabled,
    defaultInput,
    rightBlockWrapper = true,
    showFiltersPopover = true,
    extraActions,
    searchInputClassnames,
  } = props;

  const {
    tabs,
    alert,
    chips,
    chipsRef,
    hiddenChips,
    hiddenChipsCount,
    containerInputRef,
    noActiveSort,
    openPopoverFilter,
    filtersList,
    chipsContainerRef,
    inputPadding,
    handleDeleteChip,
    searchInputRef,
    setOpenPopoverFilter,
    handleOnSearch,
    handleFilters,
    handleSort,
    handleFiltersModal,
    handleClearFilters,
  } = useFilters(props);

  return (
    <div className={styles.filtersContainer}>
      <Typography color="error">{alert}</Typography>
      <div style={{ position: 'relative', overflowX: 'clip' }}>
        <Box
          className={classNames(
            styles.chipWrapper,
            isDisabled && styles.disable
          )}>
          <div
            ref={searchInputRef}
            className={classNames(
              styles.chipAndTabsWrapper,
              searchInputClassnames
            )}>
            <Box className={styles.chipContainer} ref={chipsContainerRef}>
              <Box ref={chipsRef} display="flex">
                {chips.map((chip, i) => {
                  if (hiddenChips[chip.label]) {
                    return null;
                  }
                  return (
                    <Box
                      key={`${chip.label + i}`}
                      marginLeft="4px"
                      sx={{ '&:first-of-type': { marginLeft: '8px' } }}>
                      <Chip
                        item={chip}
                        onDeleteChip={handleDeleteChip}
                        isDisabled={isDisabled}
                      />
                    </Box>
                  );
                })}
              </Box>
              {!!hiddenChipsCount && (
                <HiddenChips
                  arrow={false}
                  placement="bottom-start"
                  title={
                    <Box className={styles.subContainer}>
                      {chips.map((chip, i) => {
                        if (!hiddenChips[chip.label]) {
                          return null;
                        }
                        return (
                          <Box
                            key={`${chip.label + i}`}
                            marginBottom="8px"
                            marginRight="8px">
                            <Chip item={chip} onDeleteChip={handleDeleteChip} />
                          </Box>
                        );
                      })}
                    </Box>
                  }>
                  <ChipMui
                    disabled={!!isDisabled}
                    label={`+${hiddenChipsCount}`}
                    className={styles.chip}
                  />
                </HiddenChips>
              )}
            </Box>
            <Box ref={containerInputRef} flexGrow="1">
              <SearchForm
                isDisabled={isDisabled}
                defaultInput={defaultInput}
                onSearch={handleOnSearch}
                onClick={() => setOpenPopoverFilter(!isDisabled)}
                countChips={chips.length}
                handleClearFilters={handleClearFilters}
                inputSx={{
                  paddingLeft: `${inputPadding}px`,
                }}
              />
            </Box>
            {sortList && onSort && (
              <SortMenu<KeysSorting>
                isDisabled={isDisabled}
                listItems={sortList}
                onSort={handleSort}
                noActive={noActiveSort}
                extraActions={extraActions}
              />
            )}
            {filtersList && openPopoverFilter && showFiltersPopover && (
              <Box
                className={classNames(
                  styles.filterPopover,
                  openPopoverFilter && styles.open
                )}>
                <FiltersPopover
                  config={filtersList}
                  onFilter={handleFiltersModal}
                  onClose={() => setOpenPopoverFilter(false)}
                />
              </Box>
            )}
          </div>

          {(!!tabs || !!RightBlock) && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={2}>
              {!!tabs && (
                <Box>
                  {Object.keys(tabs).map((nameFilter) => (
                    <ButtonGroup key={nameFilter} sx={{ mr: 5 }}>
                      {tabs[nameFilter].map((tab) => (
                        <Button
                          key={tab.key}
                          className={classNames(tab.active && 'active')}
                          onClick={() => handleFilters(tab, nameFilter)}>
                          {tab.label}
                        </Button>
                      ))}
                    </ButtonGroup>
                  ))}
                </Box>
              )}
              {RightBlock instanceof Function ? (
                <Box
                  className={classNames(
                    rightBlockWrapper && styles.rightBlockWrapper
                  )}>
                  <RightBlock />
                </Box>
              ) : (
                RightBlock
              )}
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};
