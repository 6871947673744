import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  Form,
  FormControlLabel,
  Radio,
  RadioGroup,
  SvgIcon,
} from 'src/components/ui/index';
import { DIRECTION, TVariant } from 'src/components/ui/filters/types';
import { CheckedIcon } from 'src/assets/icons';
import { ColumnType, TSearchFilters } from '../../types';
import styles from './filters-radio.module.scss';

type TProps<RecordType> = {
  column: ColumnType<RecordType>;
  filters: Array<TVariant>;
  onFilters?: (filters: TSearchFilters<RecordType>) => void;
};

type TForm = {
  direction: DIRECTION;
};

const getValueFromFilters = (arr: Array<TVariant>) =>
  arr.find((elem) => elem.checked);

export const FiltersRadio = <RecordType,>(props: TProps<RecordType>) => {
  const { column, filters, onFilters } = props;

  const form = useForm<TForm>();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value, checked } = target;
    if (value) {
      const newFilters: Array<TVariant> = filters.map((elem) => ({
        ...elem,
        checked: elem.value === value && checked,
      }));
      onFilters?.({
        [column.key]: newFilters,
      } as TSearchFilters<RecordType>);
    }
  };

  useEffect(() => {
    if (filters) {
      const checkedItem = getValueFromFilters(filters);
      form.reset({
        direction: checkedItem
          ? (checkedItem.value as DIRECTION)
          : DIRECTION.DESC,
      });
    }
  }, [filters]);

  return (
    <Card noMargin className={styles.wrapperFiltersPopup}>
      <Form form={form} viewMode={false} className={styles.form} footer={false}>
        <RadioGroup>
          {filters.map(({ value, label }) => (
            <FormControlLabel
              onClick={(e) => {
                handleRadioChange(
                  e as unknown as React.ChangeEvent<HTMLInputElement>
                );
              }}
              key={label}
              checked={value === form.getValues().direction}
              value={value}
              label={label}
              control={
                <Radio
                  icon={<SvgIcon icon={CheckedIcon} strokeHexColor="#FFFFFF" />}
                  checkedIcon={<SvgIcon icon={CheckedIcon} />}
                />
              }
            />
          ))}
        </RadioGroup>
      </Form>
    </Card>
  );
};
