import { IEmailMessage } from 'src/models/communications';
import { isEmailItem } from 'src/components/pages/communications/utils';

export interface IFile {
  name: string;
  src: string;
  sizeBytes: number;
  raw: boolean;
}

export const getAttachmentList = (chain: IEmailMessage[]) =>
  chain.reduce((acc: IFile[], item) => {
    if (!isEmailItem(item)) {
      return acc;
    }

    if (item?.attachments?.length) {
      const attachments = item.attachments.map((attachment) => ({
        name: attachment.fileName,
        src: attachment.fileKey,
        sizeBytes: attachment.fileSize,
        raw: item.raw,
      }));

      acc = [...acc, ...attachments];
    }

    return acc;
  }, []);
