import { FC, useCallback } from 'react';
import {
  Autocomplete,
  Box,
  Form,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from 'src/components/ui';
import { InfoHelpIcon } from 'src/assets/icons';
import { useLazyGetAddressesQuery } from 'src/api/dadata';
import { required } from 'src/utils/form-rules';
import { DeliveryType } from 'src/models';

export const TransportChoose: FC<{ deliveryAddress?: string }> = ({
  deliveryAddress,
}) => {
  const [getAddresses] = useLazyGetAddressesQuery();
  const fetchAddresses = useCallback(
    (address: string) =>
      getAddresses(address)
        .unwrap()
        .then((res) => res.map((item) => item.address)),
    [getAddresses]
  );

  return (
    <div>
      <Typography variant="h3" marginBottom="22px">
        Выберите тип транспорта
      </Typography>

      <Box mb="50px">
        <Form.Item rules={{ required }} name="deliveryType">
          <RadioGroup row>
            <FormControlLabel
              value={DeliveryType.CARGO_SINGLE}
              sx={{ marginRight: '15px' }}
              control={<Radio />}
              label={
                <Typography variant="h4" marginLeft="15px">
                  Сборный груз
                </Typography>
              }
            />
            <Tooltip
              title="Экономичнее выделенного транспорта.
                 Более долгие сроки доставки. Перегрузы на терминале.">
              <InfoHelpIcon />
            </Tooltip>
            <FormControlLabel
              value={DeliveryType.TRUCK_FTL}
              sx={{ marginLeft: '24px', marginRight: '15px' }}
              control={<Radio />}
              label={
                <Typography variant="h4" marginLeft="15px">
                  Выделенный транспорт
                </Typography>
              }
            />
            <Tooltip title="Отдельная машина, свой маршрут, без перегрузов на терминалах.">
              <InfoHelpIcon />
            </Tooltip>
          </RadioGroup>
        </Form.Item>
      </Box>

      <Typography variant="h3" marginBottom="20px">
        Уточните адрес доставки
      </Typography>

      <Typography variant="h4" lineHeight="26px" marginBottom="20px">
        {`В заявке указан адрес доставки: `}
        <b>{deliveryAddress || '-'}</b>
        {`\nЕсли Вы хотите изменить адрес, заполните поле ниже, если нет, нажмите "Далее"`}
      </Typography>

      <Form.Item name="deliveryAddress" label="Адрес доставки">
        <Autocomplete fetchApi={fetchAddresses} />
      </Form.Item>
    </div>
  );
};
