import { Link, Typography } from 'src/components/ui';
import { Stack } from '@mui/material';
import { IUserState } from 'src/store/user/user-slice';
import { Link as RouterLink } from 'react-router-dom';
import { ROLES } from 'src/constants';
import styles from './menu-block.module.scss';
import { ROLE_TO_ITEMS_MAP } from './config';

interface IMenuBlockProps {
  user: IUserState;
  onClick?: () => void;
}

export const MenuBlock = ({ user, onClick }: IMenuBlockProps) => {
  const items = ROLE_TO_ITEMS_MAP[user.roles[0] as ROLES];

  return (
    <div className={styles.menuBlock}>
      {items?.length &&
        items.map((item) => (
          <Link
            key={item.title}
            component={RouterLink}
            to={item.path}
            className={styles.menuItem}>
            <Stack alignItems="center" onClick={onClick}>
              <div className={styles.iconWrapper}>{item.icon}</div>
              <Typography color="#000000" fontSize="10px" noWrap>
                {item.title}
              </Typography>
            </Stack>
          </Link>
        ))}
    </div>
  );
};
