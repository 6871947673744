import { Stack, StackProps } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Typography } from 'src/components/ui';
import styles from './info-stack.module.scss';

interface IInfoStackProps extends StackProps {
  subtitle?: React.ReactNode;
  after?: React.ReactNode;
  before?: React.ReactNode;
  noBorder?: boolean;
  color?: 'NONE' | 'BLUE';
}

export const InfoStack = React.memo(
  ({
    subtitle,
    children,
    after,
    before,
    noBorder,
    className,
    color = 'NONE',
    ...props
  }: IInfoStackProps) => (
    <Stack
      className={classNames(styles.wrapper, className, {
        [styles.blue]: color === 'BLUE',
        [styles.noBorder]: noBorder,
      })}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      {...props}>
      <Stack direction="row" gap={1} className={styles.content}>
        {before}
        <Stack gap={0.5} flexGrow={1} className={styles.body}>
          <Typography className={styles.title}>{children}</Typography>
          {!!subtitle && (
            <Typography className={styles.subtitle} variant="body2">
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
      {after}
    </Stack>
  )
);
