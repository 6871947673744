import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { IUser } from 'src/models/user';
import { IDigestUser } from 'src/models';
import { downloadFile } from './utils';

type TDigestUserListResult = IDigestUser[];

type TNoDigestUserListResult = IUser[];

interface IEditDigestUserParams {
  login: string;
  forwardEnabled: boolean;
  forwardUserId: number | null;
  digestLocked: boolean;
}

interface IDeleteDigestUserParams {
  userId: number;
}

export const digestApi = createApi({
  reducerPath: 'digestApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['DigestUser'],
  endpoints: (builder) => ({
    getDigestUsers: builder.query<TDigestUserListResult, void>({
      query: () => ({
        url: 'users-service/api/v1/user/digest',
        method: 'GET',
      }),
      providesTags: ['DigestUser'],
    }),
    getNoDigestUsers: builder.query<TNoDigestUserListResult, void>({
      query: () => ({
        url: 'users-service/api/v1/user/no-digest',
        method: 'GET',
      }),
    }),
    addDigestUser: builder.mutation<void, IEditDigestUserParams>({
      query: (body) => ({
        url: 'users-service/api/v1/user/digest',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DigestUser'],
    }),
    editDigestUser: builder.mutation<void, IEditDigestUserParams>({
      query: (body) => ({
        url: 'users-service/api/v1/user/digest',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['DigestUser'],
    }),
    deleteDigestUser: builder.mutation<void, IDeleteDigestUserParams>({
      query: ({ userId }) => ({
        url: `users-service/api/v1/user/digest/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DigestUser'],
    }),

    getDigestByUserId: builder.query<null, number>({
      query: (userId) => ({
        url: `reports/api/v1/digest/${userId}`,
        method: 'GET',
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/octet-stream'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob, metaData) {
        downloadFile(response, metaData);

        return null;
      },
    }),
  }),
});

export const {
  useGetDigestUsersQuery,
  useLazyGetDigestUsersQuery,
  useLazyGetNoDigestUsersQuery,
  useAddDigestUserMutation,
  useEditDigestUserMutation,
  useDeleteDigestUserMutation,

  useLazyGetDigestByUserIdQuery,
} = digestApi;
