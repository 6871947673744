import { FC, lazy, Suspense } from 'react';
import { PageLoader } from 'src/components/ui';

const Communications = lazy(
  () =>
    import(
      /* webpackChunkName: 'Communications' */
      /* webpackMode: "lazy" */
      `./communications`
    )
);

export const LazyCommunications: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <Communications />
  </Suspense>
);
