import vars from './scss/_vars.module.scss';

const {
  borderGrayPrimary,
  overlayBlueDefault,
  blueContentSecondary,
  darkBlueContentSecondary,
  textGreen,
} = vars;

export const palette = {
  common: {
    white: vars.commonWhite,
    dark: vars.commonDark,
  },
  primary: {
    main: vars.primary,
    dark: vars.primaryDark,
    light: vars.primaryLight,
  },
  secondary: {
    light: vars.secondaryLight,
    main: vars.secondary,
    dark: vars.secondaryDark,
    contrastText: vars.primaryText,
  },
  info: {
    main: vars.info,
  },
  success: {
    light: vars.successLight,
    main: vars.success,
  },
  warning: {
    main: vars.warning,
  },
  error: {
    light: vars.dangerLight,
    main: vars.danger,
    dark: vars.dangerDark,
  },
  text: {
    primary: vars.primaryText,
    secondary: vars.secondaryText,
    disabled: 'rgba(159, 168, 179, 1)',
  },
  divider: 'rgba(0, 47, 108, 0.06)',
  background: {
    default: '#E6E7E8',
  },
  action: {
    hover: 'rgba(0, 47, 108, 0.08)',
    disabled: '#9FA8B3',
    disabledBackground: '#F3F6FB',
  },
  attention: {},
  white: {},
  blend: {},

  // Для добавления любых значений, которые можно использовать в объекте theme.
  customTheme: {
    borderGrayPrimary,
    overlayBlueDefault,
    blueContentSecondary,
    darkBlueContentSecondary,
    textGreen,
  },
};
