import { Skeleton } from 'src/components/ui';
import styles from './header-skeleton.module.scss';

export const HeaderSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.containerHeader}>
      <Skeleton className={styles.orderId} />

      <div className={styles.containerBtn}>
        <Skeleton className={styles.btnOne} />
        <Skeleton className={styles.btnTwo} />
      </div>
    </div>

    <div className={styles.containerTabs}>
      <Skeleton className={styles.tabGroups} />
    </div>
  </div>
);
