import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';

export const adminEmailSenderApi = createApi({
  reducerPath: 'adminEmailSenderApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getMailSenderStatus: builder.query<{ currentState: boolean }, void>({
      query: () => '/email-sender/api/v1/email-send-status',
    }),
    toggleMailSenderStatus: builder.mutation<{ currentState: boolean }, void>({
      query: () => ({
        url: '/email-sender/api/v1/email-send-switch',
        method: 'PATCH',
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            adminEmailSenderApi.util.updateQueryData(
              'getMailSenderStatus',
              body,
              () => data
            )
          );
        } catch {
          // ignore
        }
      },
    }),
  }),
});

export const {
  useGetMailSenderStatusQuery,
  useToggleMailSenderStatusMutation,
} = adminEmailSenderApi;
