import { Autocomplete as MuiAutocomplete, debounce } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import classNames from 'classnames';
import {
  ComponentProps,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { OutlinedInput, OutlinedInputProps } from 'src/components/ui';
import styles from './autocomplete.module.scss';

type TMuiAutocomplete = ComponentProps<typeof MuiAutocomplete>;

type TAutocomplete<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'onChange' | 'options' | 'multiple' | 'freeSolo'
> & {
  onChange?: (value: TMuiAutocomplete['value']) => void;
  fetchApi: (value: string) => Promise<T[]>;
  options?: T[];
  fetchOnEmptyInput?: boolean;
  multiple?: any;
  freeSolo?: any;
  inputRef?: Ref<any>;
  valueKey?: keyof T;
  inputProps?: OutlinedInputProps;
  onSetOptions?: (value: T[]) => void;
};

export const Autocomplete = <T,>({
  onChange,
  fetchApi,
  options: initOptions,
  fetchOnEmptyInput,
  popupIcon = null,
  multiple,
  freeSolo,
  inputRef,
  valueKey,
  inputProps: initInputProps,
  onInputChange,
  onSetOptions,
  ...props
}: TAutocomplete<T>) => {
  const [options, setOptions] = useState<T[]>(initOptions || []);
  const { startAdornment, endAdornment, className, ...inputProps } =
    initInputProps || {};

  useEffect(() => {
    if (!initOptions) return;

    setOptions(initOptions);
  }, [initOptions]);

  const handleChange = useCallback<NonNullable<TMuiAutocomplete['onChange']>>(
    (_, newValue) => {
      onChange?.(
        valueKey && newValue
          ? {
              [valueKey]: (newValue as T)[valueKey],
            }
          : newValue
      );
    },
    [onChange, valueKey]
  );

  useEffect(() => {
    if (onSetOptions) {
      onSetOptions(options);
    }
  }, [options]);

  const fetch = useMemo(
    () =>
      debounce((input: string) => {
        if (!input && !fetchOnEmptyInput) {
          return;
        }

        // TODO cancel previous promise
        fetchApi(input)
          .then(setOptions)
          .catch(() => {});
      }, 400),
    [fetchApi, fetchOnEmptyInput]
  );

  return (
    <MuiAutocomplete<T>
      {...props}
      multiple={multiple}
      popupIcon={popupIcon && (popupIcon === true ? undefined : popupIcon)}
      options={options}
      filterOptions={(x) => x}
      onInputChange={(event, newValue) => {
        onInputChange?.(event, newValue, 'input');
        fetch(newValue);
      }}
      freeSolo={freeSolo}
      onChange={handleChange}
      renderInput={({
        InputLabelProps,
        InputProps: {
          endAdornment: inputEndAdornment,
          startAdornment: inputStartAdornment,
          className: inputClassName,
          ...InputProps
        },
        ...renderInputProps
      }) => (
        <OutlinedInput
          inputRef={inputRef}
          {...InputProps}
          {...renderInputProps}
          {...inputProps}
          className={classNames(
            inputClassName,
            className,
            startAdornment && styles.inputWithStartAdornment
          )}
          startAdornment={
            <>
              {startAdornment}
              {inputStartAdornment}
            </>
          }
          endAdornment={
            <>
              {endAdornment}
              {inputEndAdornment}
            </>
          }
          placeholder={props.placeholder}
        />
      )}
    />
  );
};
