import { AppBar, MenuList } from 'src/components/ui';
import { NavLink } from 'react-router-dom';
import { MobileMenu } from 'src/assets/icons';
import { ROLES } from 'src/constants';
import { useAppSelector } from 'src/store';
import { selectUser } from 'src/store/user';
import styles from './mobile-menu-panel.module.scss';
import { MobileMenuItem } from './components';
import { ROLE_TO_ITEMS_MAP } from './config';

interface IMobileMenuPanelProps {
  open: boolean;
  onClick: () => void;
  onClose: () => void;
}

export const MobileMenuPanel = ({
  open,
  onClick,
  onClose,
}: IMobileMenuPanelProps) => {
  const user = useAppSelector(selectUser);

  if (Object.keys(user).length === 0) {
    return null;
  }

  const items = ROLE_TO_ITEMS_MAP[user.roles[0] as ROLES];

  return (
    <AppBar
      position="sticky"
      sx={{
        top: 'auto',
        bottom: 0,
        backgroundColor: 'unset',
        zIndex: '1300',
      }}>
      <div className={styles.wrapper}>
        <MenuList component="nav">
          <div className={styles.menuListWrapper}>
            {items.map((item) => (
              <NavLink
                to={item.path}
                className={styles.menuItem}
                key={item.label}>
                {({ isActive }) => (
                  <MobileMenuItem
                    label={item.label}
                    icon={item.icon}
                    onClick={onClose}
                    className={isActive && !open ? 'active' : ''}
                  />
                )}
              </NavLink>
            ))}

            <MobileMenuItem
              label="Меню"
              icon={MobileMenu}
              onClick={onClick}
              className={open ? 'active' : ''}
            />
          </div>
        </MenuList>
      </div>
    </AppBar>
  );
};
