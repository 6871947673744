import { EBidStatusColor } from 'src/models/bids-details';

export const getBidStatusColor = (daysCount: number) => {
  if (daysCount < 3) {
    return EBidStatusColor.SUCCESS;
  }

  if (daysCount < 5) {
    return EBidStatusColor.WARNING;
  }

  return EBidStatusColor.DANGER;
};
