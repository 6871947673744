import { FC } from 'react';
import { AttachFiles } from 'src/components/widgets';
import {
  ContractorSpecificationStatus,
  DocumentEntityTypes,
  DocumentTypes,
  IContractorSpecification,
} from 'src/models';
import { Typography } from 'src/components/ui';
import { arrayRequired } from 'src/utils/form-rules';
import { formatServerDate } from 'src/utils';

import styles from './files-input.module.scss';

type TFilesInputProps = {
  specification: IContractorSpecification;
  preview?: boolean;
};

export const FilesInput: FC<TFilesInputProps> = ({
  specification,
  preview,
}) => (
  <div className={styles.wrapper}>
    {!specification.quickDeal && (
      <>
        <AttachFiles
          isEditMode={false}
          fileListTitle="Проект спецификации"
          extraTitle={
            specification.preliminaryVersionReceivedAt && (
              <Typography color="primary">
                Получен{' '}
                {formatServerDate(
                  specification.preliminaryVersionReceivedAt,
                  'dd.MM.yy, HH:mm'
                )}
              </Typography>
            )
          }
          showDownloadAllButton={!preview}
          subtitleComponent={
            <Typography mb={2}>
              Спецификация для согласования с исполнителем без подписей и
              печатей.
            </Typography>
          }
          documentOptions={{
            entityType: DocumentEntityTypes.CONTRACTOR_SPECIFICATION,
            documentType: DocumentTypes.SPECIFICATION_PRELIMINARY_VERSION,
            entityId: specification.specificationId,
          }}
          formItemName="documents.SPECIFICATION_PRELIMINARY_VERSION"
          canPreview
        />

        <AttachFiles
          isEditMode={
            !preview &&
            specification.status.code ===
              ContractorSpecificationStatus.PRELIMINARY_VERSION_RECEIVED
          }
          showDownloadAllButton={!preview}
          uploadFilesTitle="Прикрепите скан подписанной спецификации"
          fileListTitle="Скан подписанной спецификации"
          extraTitle={
            specification.signedScanSentAt && (
              <Typography color="primary">
                Отправлен{' '}
                {formatServerDate(
                  specification.signedScanSentAt,
                  'dd.MM.yy, HH:mm'
                )}
              </Typography>
            )
          }
          subtitleComponent={
            <Typography mb={2}>
              Спецификация, которая подписана с вашей стороны.
            </Typography>
          }
          rules={{
            validate: arrayRequired,
          }}
          documentOptions={{
            entityType: DocumentEntityTypes.CONTRACTOR_SPECIFICATION,
            documentType: DocumentTypes.SPECIFICATION_SIGNED_SCAN,
            entityId: specification.specificationId,
          }}
          formItemName="documents.SPECIFICATION_SIGNED_SCAN"
          canPreview
        />
      </>
    )}

    {(specification.status.code ===
      ContractorSpecificationStatus.FINAL_VERSION_RECEIVED ||
      specification.quickDeal) && (
      <AttachFiles
        isEditMode={false}
        fileListTitle="Финальная спецификация"
        showDownloadAllButton={!preview}
        extraTitle={
          specification.finalVersionReceivedAt && (
            <Typography color="primary">
              Получена{' '}
              {formatServerDate(
                specification.finalVersionReceivedAt,
                'dd.MM.yy, HH:mm'
              )}
            </Typography>
          )
        }
        subtitleComponent={
          <Typography mb={2}>
            Спецификация, которая подписана с обеих сторон.
          </Typography>
        }
        documentOptions={{
          entityType: DocumentEntityTypes.CONTRACTOR_SPECIFICATION,
          documentType: DocumentTypes.SPECIFICATION_FINAL_VERSION,
          entityId: specification.specificationId,
        }}
        formItemName="documents.SPECIFICATION_FINAL_VERSION"
        canPreview
      />
    )}
  </div>
);
