import {
  IndexRouteObject,
  Navigate,
  NonIndexRouteObject,
} from 'react-router-dom';
import {
  LazyCompanyBankRequisites,
  LazyCompanyCompanyInfo,
  LazyCompanyCompanyRequisites,
  LazyCompanyContacts,
  LazyCompanyContract,
  LazyCompanyDeliveryAddresses,
  LazyCompanyShippingAddresses,
  LazyCompanyTechnicalCapabilities,
  LazyCompanyOkved,
  LazyCompanyLawsuits,
  LazyCompanyFinances,
  LazyCompanyFinancialStatements,
  LazyCompanyDebts,
} from 'src/components/pages';
import { LazyCustomerAllRequests } from 'src/components/pages/admin/company/all-customer-requests/lazy-customer-all-requests';
import { CompanyTypes } from 'src/models';
import { getAvailableConfigItems } from 'src/configs/company/utils';
import { CompanyTypeCheck } from 'src/components/layout/company-layout';
import { PERMISSIONS } from 'src/constants';

type TRoute =
  | (IndexRouteObject & {
      type?: CompanyTypes;
      title?: string;
      permissions?: PERMISSIONS[];
    })
  | (Omit<NonIndexRouteObject, 'children'> & {
      title?: string;
      type?: CompanyTypes;
      children?: TRoute[];
      permissions?: PERMISSIONS[];
    });

export type TCompanyRouteConfig = TRoute[];

export type TCompanyMenuItem = {
  path: string;
  title: string;
  children?:
    | React.ReactNode
    | Array<{
        path: string;
        title: string;
        isWarning?: boolean;
      }>;
  isWarning?: boolean;
};

const COMPANY_ROUTES: TCompanyRouteConfig = [
  {
    index: true,
    element: <Navigate to="about" replace />,
  },
  {
    title: 'Компания',
    path: 'about',
    children: [
      {
        title: 'О компании',
        index: true,
        element: <LazyCompanyCompanyInfo />,
      },
      {
        title: 'Контакты',
        path: 'contacts',
        element: <LazyCompanyContacts />,
      },
      {
        title: 'Виды деятельности ОКВЭД',
        path: 'okved',
        element: <LazyCompanyOkved />,
        permissions: [PERMISSIONS.VIEW_FULL_COMPANY_INFO],
      },
      {
        title: 'Финансы',
        path: 'finances',
        element: <LazyCompanyFinances />,
        permissions: [PERMISSIONS.VIEW_FULL_COMPANY_INFO],
      },
      {
        title: 'Бухгалтерская отчетность',
        path: 'financial-statements',
        element: <LazyCompanyFinancialStatements />,
        permissions: [PERMISSIONS.VIEW_FULL_COMPANY_INFO],
      },
      {
        title: 'Судебные дела',
        path: 'lawsuits',
        element: <LazyCompanyLawsuits />,
        permissions: [
          PERMISSIONS.VIEW_FULL_COMPANY_INFO,
          PERMISSIONS.VIEW_LAWSUITS_PAGE,
        ],
      },
      {
        title: 'Долги',
        path: 'debts',
        element: <LazyCompanyDebts />,
        permissions: [
          PERMISSIONS.VIEW_FULL_COMPANY_INFO,
          PERMISSIONS.VIEW_DEBTS_PAGE,
        ],
      },
    ],
  },
  {
    title: 'Реквизиты',
    path: 'requisites',
    children: [
      {
        title: 'Реквизиты компании',
        index: true,
        element: <LazyCompanyCompanyRequisites />,
      },
      {
        title: 'Банковские реквизиты',
        path: 'bank',
        element: <LazyCompanyBankRequisites />,
      },
      {
        title: 'Адреса отгрузки',
        path: 'shipping',
        element: (
          <CompanyTypeCheck companyType={CompanyTypes.CONTRACTOR}>
            <LazyCompanyShippingAddresses />
          </CompanyTypeCheck>
        ),
        type: CompanyTypes.CONTRACTOR,
      },
      {
        title: 'Адреса доставки',
        path: 'delivery',
        element: (
          <CompanyTypeCheck companyType={CompanyTypes.CUSTOMER}>
            <LazyCompanyDeliveryAddresses />
          </CompanyTypeCheck>
        ),
        type: CompanyTypes.CUSTOMER,
      },
    ],
  },
  {
    title: 'Договор',
    path: 'contract',
    children: [
      {
        path: ':contractId',
        element: <LazyCompanyContract />,
      },
    ],
  },
  {
    title: 'Все заявки заказчика',
    path: 'all-requests',
    element: <LazyCustomerAllRequests />,
    type: CompanyTypes.CUSTOMER,
    permissions: [PERMISSIONS.WATCH_ALL_CUSTOMER_REQUESTS],
  },
  {
    title: 'Технические возможности',
    path: 'tech-opportunities',
    element: (
      <CompanyTypeCheck companyType={CompanyTypes.CONTRACTOR}>
        <LazyCompanyTechnicalCapabilities />
      </CompanyTypeCheck>
    ),
    type: CompanyTypes.CONTRACTOR,
  },
];

export const getAvailableCompanyMenuItems = (companyType: CompanyTypes) =>
  getAvailableConfigItems(COMPANY_ROUTES, companyType).menuItems;

export const {
  routeItems: contractorRouteItems,
  menuItems: contractorMenuItems,
} = getAvailableConfigItems(COMPANY_ROUTES, CompanyTypes.CONTRACTOR);

export const { routeItems: customerRouteItems, menuItems: customerMenuItems } =
  getAvailableConfigItems(COMPANY_ROUTES, CompanyTypes.CUSTOMER);

export const { routeItems: companyRouteItems } =
  getAvailableConfigItems(COMPANY_ROUTES);
