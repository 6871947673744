import { FC } from 'react';
import {
  Tooltip,
  InfoRow,
  NumberInput,
  SvgIcon,
  Grid,
} from 'src/components/ui';
import { InfoHelpIcon } from 'src/assets/icons';
import styles from './head-inputs.module.scss';

type THeadInputsProps = {
  cost: number | string;
  supplyDuration: number;
  paymentPeriodProlongation: number;
};

export const HeadInputs: FC<THeadInputsProps> = ({
  cost,
  supplyDuration,
  paymentPeriodProlongation,
}) => (
  <Grid container item columnSpacing="200px">
    <Grid item>
      <div className={styles.priceWrapper}>
        <NumberInput mode="price" displayType="text" value={cost} />
        <Tooltip title="Сумма спецификации с учетом НДС, в рублях">
          <SvgIcon
            icon={InfoHelpIcon}
            strokeColor="#16651A"
            width="16px"
            height="16px"
          />
        </Tooltip>
      </div>
    </Grid>
    <Grid item>
      <div className={styles.container}>
        <InfoRow label="Срок поставки, в к.д." value={supplyDuration} />
      </div>
    </Grid>
    <Grid item>
      <div className={styles.container}>
        <InfoRow
          label="Отсрочка платежа, в к.д."
          value={
            paymentPeriodProlongation === null
              ? paymentPeriodProlongation
              : String(paymentPeriodProlongation)
          }
        />
      </div>
    </Grid>
  </Grid>
);
