import { useState } from 'react';

export const useHover = () => {
  const [isHovering, setIsHovering] = useState(false);

  const onHoverProps = {
    onMouseEnter: () => setIsHovering(true),
    onMouseLeave: () => setIsHovering(false),
  };

  return [isHovering, onHoverProps] as const;
};
