import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { TTechnicalOpportunity } from 'src/models';
import { DEFAULT_TAG_DISPLAY_NAME, DEFAULT_TAG_NAME } from 'src/constants';
import { ETagStatuses } from '../configs/tag-status';

export type TGetFirstLevelCategoriesResponse = { [key: number]: string };

export const dictionaryApi = createApi({
  reducerPath: 'dictionaryApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getTechnicalOpportunities: builder.query<
      TTechnicalOpportunity[],
      ETagStatuses | void
    >({
      query: (statuses) => ({
        url: `business-opportunities/api/v1/technical-opportunities-categories`,
        params: {
          statuses,
        },
      }),
      keepUnusedDataFor: 3600,
    }),
    getFirstLevelCategories: builder.query<
      TGetFirstLevelCategoriesResponse,
      void
    >({
      query: () => ({
        url: 'business-opportunities/api/v1/technical-opportunities-categories/first-level',
        method: 'GET',
      }),
      transformResponse: (result: { id: number; name: string }[]) => {
        const resultTags = result.map((tag) => ({
          ...tag,
          name:
            tag.name === DEFAULT_TAG_NAME ? DEFAULT_TAG_DISPLAY_NAME : tag.name,
        }));

        return resultTags.reduce(
          (acc, element) => ({ ...acc, [element.id]: element.name }),
          {}
        );
      },
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useLazyGetTechnicalOpportunitiesQuery,
  useGetTechnicalOpportunitiesQuery,
  useGetFirstLevelCategoriesQuery,
} = dictionaryApi;
