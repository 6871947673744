import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiStepConnector: Components<Theme>['MuiStepConnector'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      top: '17px',
      left: 'calc(-50% + 24px)',
      right: 'calc(50% + 24px)',
      '&.Mui-active, &.Mui-completed': {
        '& .MuiStepConnector-line': {
          borderColor: theme.palette.primary.dark,
        },
      },
    }),
    line: {
      borderColor: '#BCBCBC',
    },
  },
};
