import { DIRECTION, TSortItem } from 'src/components/ui/new-filters/types';
import { TSortKey } from '../types/sort-key';

export const sortList: TSortItem<TSortKey>[] = [
  {
    label: 'Статус',
    key: 'STATUS_FOR_KAM',
    direction: DIRECTION.ASC,
    active: true,
    withoutDirection: true,
  },
  {
    label: 'Бюджет',
    key: 'BUDGET',
    direction: DIRECTION.ASC,
    active: false,
  },
  {
    label: 'Дата создания заявки',
    key: 'CREATED_AT',
    direction: DIRECTION.DESC,
    active: false,
  },
];
