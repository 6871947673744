import { IconButton, Stack } from 'src/components/ui';
import { ListDetails, List } from 'src/assets/icons';
import classNames from 'classnames';
import styles from './list-view-toggler.module.scss';

interface IListViewTogglerProps {
  isDetailView: boolean;
  onClick: () => void;
}

export const ListViewToggler = ({
  isDetailView,
  onClick,
}: IListViewTogglerProps) => (
  <Stack direction="row" className="view-toggle">
    <div
      className={classNames(
        styles.toggle,
        styles.toggleLeft,
        !isDetailView && styles.toggleActive
      )}>
      <IconButton disabled={!isDetailView} onClick={onClick}>
        <List color="#292929" width="16px" height="16px" />
      </IconButton>
    </div>
    <div
      className={classNames(
        styles.toggle,
        styles.toggleRight,
        isDetailView && styles.toggleActive
      )}>
      <IconButton disabled={isDetailView} onClick={onClick}>
        <ListDetails color="#292929" width="16px" height="16px" />
      </IconButton>
    </div>
  </Stack>
);
