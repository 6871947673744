import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IManagerCustomerCommercialOfferForm } from 'src/components/pages/business-request-detail/manager/commercial-offers/components';
import { IContractorGeneralInformationForm } from 'src/components/pages/business-request-detail/manager/specifications/components/contractor/types';
import { ICustomerGeneralInformationForm } from 'src/components/pages/business-request-detail/manager/specifications/components/customer/types';
import { IManagerCustomerCommercialOffer } from 'src/models';

export interface IManagerCustomerCommercialOfferFormWithItem
  extends IManagerCustomerCommercialOfferForm {
  item?: IManagerCustomerCommercialOffer;
}

type DraftFormsInitialState = {
  createKpFormDataArray: Record<
    number,
    Partial<IManagerCustomerCommercialOfferFormWithItem> | undefined
  >;

  createContractorSpecificationArray: Record<
    number,
    Partial<IContractorGeneralInformationForm> | undefined
  >;

  createCustomerSpecificationArray: Record<
    number,
    Partial<ICustomerGeneralInformationForm> | undefined
  >;
};

const initialState: DraftFormsInitialState = {
  createKpFormDataArray: {},
  createContractorSpecificationArray: {},
  createCustomerSpecificationArray: {},
};

const draftFormsSlice = createSlice({
  name: 'draftForms',
  initialState,
  reducers: {
    updateCreateKpFormData: (
      state,
      {
        payload,
      }: PayloadAction<{
        businessRequestId: number;
        createKpData?: Partial<IManagerCustomerCommercialOfferFormWithItem>;
      }>
    ) => {
      state.createKpFormDataArray[payload.businessRequestId] =
        payload.createKpData;
    },

    updateCreateContractorSpecificationFormData: (
      state,
      {
        payload,
      }: PayloadAction<{
        businessRequestId: number;
        createContractorSpecificationData?: Partial<IContractorGeneralInformationForm>;
      }>
    ) => {
      if (!payload.createContractorSpecificationData) {
        state.createContractorSpecificationArray[payload.businessRequestId] =
          undefined;
        return;
      }

      state.createContractorSpecificationArray[payload.businessRequestId] = {
        ...state.createContractorSpecificationArray[payload.businessRequestId],
        ...payload.createContractorSpecificationData,
        logistics: {
          deliveryAddress:
            payload.createContractorSpecificationData.logistics
              ?.deliveryAddress ?? '',
          shippingAddress:
            payload.createContractorSpecificationData.logistics
              ?.shippingAddress ?? '',
          cargoDescription:
            payload.createContractorSpecificationData.logistics
              ?.cargoDescription ?? null,
          costsLogistics:
            payload.createContractorSpecificationData.logistics
              ?.costsLogistics ?? '',
          calculatedShippingCost:
            payload.createContractorSpecificationData.logistics
              ?.calculatedShippingCost ?? 0,
        },
        documents: {
          SPECIFICATION_PRELIMINARY_VERSION:
            payload?.createContractorSpecificationData?.documents
              ?.SPECIFICATION_PRELIMINARY_VERSION ?? [],
          SPECIFICATION_SIGNED_SCAN:
            payload?.createContractorSpecificationData?.documents
              ?.SPECIFICATION_SIGNED_SCAN ?? [],
          SPECIFICATION_FINAL_VERSION:
            payload?.createContractorSpecificationData?.documents
              ?.SPECIFICATION_FINAL_VERSION ?? [],
        },
      };
    },

    updateCreateCustomerSpecificationFormData: (
      state,
      {
        payload,
      }: PayloadAction<{
        businessRequestId: number;
        createCustomerSpecificationData?: Partial<ICustomerGeneralInformationForm>;
      }>
    ) => {
      if (!payload.createCustomerSpecificationData) {
        state.createCustomerSpecificationArray[payload.businessRequestId] =
          undefined;
        return;
      }

      state.createCustomerSpecificationArray[payload.businessRequestId] = {
        ...state.createCustomerSpecificationArray[payload.businessRequestId],
        ...payload.createCustomerSpecificationData,
        documents: {
          SPECIFICATION_PRELIMINARY_VERSION:
            payload?.createCustomerSpecificationData?.documents
              ?.SPECIFICATION_PRELIMINARY_VERSION ?? [],
          SPECIFICATION_SIGNED_SCAN:
            payload?.createCustomerSpecificationData?.documents
              ?.SPECIFICATION_SIGNED_SCAN ?? [],
          SPECIFICATION_FINAL_VERSION:
            payload?.createCustomerSpecificationData?.documents
              ?.SPECIFICATION_FINAL_VERSION ?? [],
        },
      };
    },
  },
});

export const draftFormsReducer = draftFormsSlice.reducer;
export const {
  updateCreateKpFormData,
  updateCreateContractorSpecificationFormData,
  updateCreateCustomerSpecificationFormData,
} = draftFormsSlice.actions;
