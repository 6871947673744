import { fileSizeFormat, plural } from '../../../../utils';
import { TConfigData, TTypeConfig } from '../../../../models/document';

const getHelpOtherFiles = (
  countOtherFiles: number | null,
  sizeOtherFiles: number | null,
  extensions: string[],
  isShowExtList: boolean
) => {
  if (!sizeOtherFiles || !countOtherFiles) {
    return '';
  }

  let result = `${plural(countOtherFiles, '$d файл', '$d файла', '$d файлов')}`;

  result += ` до ${fileSizeFormat(sizeOtherFiles)}${
    countOtherFiles > 1 ? ' каждый' : ''
  }`;

  if (isShowExtList) {
    result += ` Допустимые форматы: ${extensions.join(', ')}`;
  }

  return result;
};

export const getHelp = (config: TConfigData, showAvailableExtList: boolean) => {
  const {
    count: countOtherFiles,
    maxFileSizeBytes: sizeOtherFiles,
    extensions: extensionsOtherFiles,
  } = config[TTypeConfig.OTHER_FILE_TYPES];
  const { count: countArchive, maxFileSizeBytes: sizeArchive } =
    config[TTypeConfig.ARCHIVE];

  const helpOtherFiles = getHelpOtherFiles(
    countOtherFiles,
    sizeOtherFiles,
    extensionsOtherFiles,
    showAvailableExtList
  );

  const helpArchive =
    countArchive && sizeArchive
      ? `и/или ${plural(
          countArchive,
          '$d архив',
          '$d архива',
          '$d архивов'
        )} до ${fileSizeFormat(sizeArchive)}`
      : '';

  return `${helpOtherFiles} ${helpArchive}`;
};

export const getDataByExtension = <T extends { name: string }>(
  config: TConfigData,
  data: T[]
): {
  error: boolean;
  dropFiles: Record<TTypeConfig, Array<T>>;
} => {
  const dropFiles = {} as Record<TTypeConfig, Array<T>>;

  const typeFilesFromConfig = Object.values(TTypeConfig);

  typeFilesFromConfig.forEach((itemType) => {
    dropFiles[itemType] = [];
  });

  let countCheckFile = 0;

  data.forEach((file) => {
    const ext = file.name.match(/\.([^.]+)$/)?.[1].toLowerCase() || '';
    typeFilesFromConfig.forEach((itemType) => {
      if (config[itemType].extensions.includes(ext)) {
        dropFiles[itemType] = dropFiles[itemType]
          ? [...dropFiles[itemType], file]
          : [file];
        countCheckFile++;
      }
    });
  });

  return {
    error: data.length !== countCheckFile,
    dropFiles,
  };
};

const validateMinSize = (data: File[]) =>
  data.some((element) => element.size === 0);

export const validateSizeByType = (
  data: File[],
  config: TConfigData,
  type: TTypeConfig
): {
  isValidate: boolean;
  messageError: string;
} | null => {
  const stringType = type === TTypeConfig.OTHER_FILE_TYPES ? 'файла' : 'архива';

  if (validateMinSize(data)) {
    return {
      isValidate: false,
      messageError: `Размер ${stringType} должен быть больше 0 б`,
    };
  }

  const maxSize = Number(config[type].maxFileSizeBytes);

  for (let i = 0; i < data.length; i++) {
    if (data[i].size > maxSize) {
      return {
        isValidate: false,
        messageError: `Размер ${stringType} превышает ${fileSizeFormat(
          maxSize
        )}`,
      };
    }
  }

  return null;
};
