import {
  DeliveryType,
  ICargoDescriptions,
  TCargoParametersFull,
} from 'src/models';

export const mapCargoParameters = (
  data?: TCargoParametersFull | null,
  deliveryAddress: string = ''
): ICargoDescriptions => {
  if (data?.deliveryType === DeliveryType.CARGO_SINGLE) {
    let placeParams;
    const places = data.places ?? 0;

    if (places === 1) {
      placeParams = { singlePlace: true };
    } else if (places > 1) {
      placeParams = {
        isPlaceEqual: data.isPlaceEqual,
        singlePlace: false,
      };
    } else {
      placeParams = {};
    }

    return {
      deliveryAddress,
      withLogistics: true,
      parameters: {
        ...data,
        ...placeParams,
      },
    };
  }

  if (data?.deliveryType === DeliveryType.TRUCK_FTL) {
    return {
      deliveryAddress,
      withLogistics: true,
      parameters: data,
    };
  }

  return { deliveryAddress, withLogistics: false, parameters: {} };
};
