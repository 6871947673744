import { createSlice } from '@reduxjs/toolkit';
import { IUser } from 'src/models';
import { userApi } from 'src/api/user';
import { joinName } from 'src/utils';

export interface IUserState extends IUser {
  fullName: string;
  shortName: string;
}

const userSlice = createSlice({
  name: 'user',
  initialState: {} as IUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getCurrentUser.matchFulfilled,
      (state, { payload }) => ({
        ...payload,
        ...joinName(payload.lastName, payload.firstName, payload.middleName),
      })
    );
    builder.addMatcher(
      userApi.endpoints.updateCurrentUser.matchFulfilled,
      (state, { payload: { organizationId, ...rest } }) => ({
        ...state,
        ...rest,
        ...joinName(rest.lastName, rest.firstName, rest.middleName),
      })
    );
  },
});

export const userReducer = userSlice.reducer;
