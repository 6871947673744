import {
  useCallback,
  useEffect,
  ChangeEvent,
  useState,
  memo,
  useMemo,
} from 'react';
import { Empty, Typography } from 'src/components/ui';
import { Table as MuiTable } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import classNames from 'classnames';
import { Header } from './header';
import { TablePagination } from './table-pagination';

import styles from './table.module.scss';
import { TableProps, TSearchFilters } from './types';
import { Row } from './row';
import { BlurRow } from './blur-row';

const TableComponent = <RecordType extends { key: number | string }>(
  props: TableProps<RecordType>
) => {
  const {
    loading,
    blur,
    className,
    columns,
    data,
    countPerPage,
    page,
    onSelected,
    onPagination,
    totalCountItems,
    onFilter,
    subcategoryKey,
    renderSubCategory,
    expandedRowRender,
    showCounterSelected,
    onSelectedWithSubCategory,
    withLabelSelected = true,
    filtersWithRadioButton = false,
    selectableLimit = false,
    emptyText,
    onLimitChange,
    limit,
    sx,
    onRowClick,
    rowsExpanded = false,
    customScroll = false,
    showTotalCount = true,
    selectedItems,
    currentPageSelected,
    attachedItems,
  } = props;

  const [selected, setSelected] = useState<Array<RecordType>>([]);

  useEffect(() => {
    if (selectedItems) return;
    setSelected([]);
  }, [loading]);

  const handleSelected = useCallback(
    (select: RecordType | Array<RecordType>) => {
      const newSelected = Array.isArray(select) ? select : [select];
      setSelected(newSelected);
      onSelected?.(newSelected);
    },
    [onSelected]
  );

  const handleChangeCurrentPage = useCallback(
    (event: ChangeEvent<unknown>, value: number) => {
      onPagination?.(value);
    },
    [onPagination]
  );

  const handleFilter = useCallback(
    (filters: TSearchFilters<RecordType>) => {
      onFilter?.(filters);
      setSelected([]);
    },
    [onFilter]
  );

  const handleSelectAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!data) {
        return;
      }

      if (event.target.checked) {
        handleSelected(attachedItems ? attachedItems.concat(data) : data);
        return;
      }
      handleSelected(attachedItems || []);
    },
    [data, handleSelected]
  );

  useEffect(() => {
    if (selectedItems) return;
    handleSelected([]);
  }, [page, handleSelected]);

  useEffect(() => {
    if (selectedItems?.length !== selected.length) {
      setSelected([]);
    }
  }, [selectedItems]);

  return data?.length ? (
    <div
      className={classNames(
        className,
        styles.wrapper,
        loading && styles.loading
      )}>
      <div
        className={classNames(
          styles.wrapperTable,
          customScroll && styles.scroll
        )}>
        <MuiTable style={{ tableLayout: 'fixed' }}>
          <Header
            columns={columns}
            onSelectAllClick={onSelected && handleSelectAllClick}
            numSelected={selected?.length || 0}
            rowCount={data.length}
            onFilter={handleFilter}
            expandedRowRender={expandedRowRender}
            showCounterSelected={showCounterSelected}
            isCollapse={!!subcategoryKey}
            withLabelSelected={withLabelSelected}
            filtersWithRadioButton={filtersWithRadioButton}
            showTotalCount={showTotalCount}
            currentPageSelectedCount={currentPageSelected?.length}
          />

          {!blur && (
            <TableBody sx={sx}>
              {data.concat(attachedItems || []).map((row) => (
                <Row<RecordType>
                  onRowClick={onRowClick}
                  key={row.key}
                  record={row}
                  columns={columns}
                  selected={onSelected && selected}
                  setSelected={onSelected && handleSelected}
                  expandedRowRender={expandedRowRender}
                  subcategoryKey={subcategoryKey}
                  renderSubCategory={renderSubCategory}
                  onSelectedWithSubCategory={onSelectedWithSubCategory}
                  rowExpanded={rowsExpanded}
                />
              ))}
            </TableBody>
          )}
        </MuiTable>
      </div>

      {blur && <BlurRow />}

      {!!totalCountItems && totalCountItems > countPerPage && !blur && (
        <TablePagination
          countItems={totalCountItems}
          countPerPage={countPerPage}
          handleChange={handleChangeCurrentPage}
          page={page}
          selectableLimit={selectableLimit}
          limit={limit}
          onLimitChange={onLimitChange}
        />
      )}
    </div>
  ) : (
    <>
      <Empty>
        {emptyText ? (
          <div>{emptyText}</div>
        ) : (
          <>
            <Typography variant="h2">Ничего не найдено</Typography>
            <Typography fontSize="16" align="center">
              Попробуйте задать другие фильтры <br /> и повторите запрос.
            </Typography>
          </>
        )}
      </Empty>
      {!!attachedItems?.length && (
        <MuiTable style={{ tableLayout: 'fixed' }} sx={{ mt: 2 }}>
          <TableBody sx={sx}>
            {attachedItems.map((row) => (
              <Row<RecordType>
                onRowClick={onRowClick}
                key={row.key}
                record={row}
                columns={columns}
                selected={onSelected && selected}
                setSelected={onSelected && handleSelected}
                expandedRowRender={expandedRowRender}
                subcategoryKey={subcategoryKey}
                renderSubCategory={renderSubCategory}
                onSelectedWithSubCategory={onSelectedWithSubCategory}
                rowExpanded={rowsExpanded}
              />
            ))}
          </TableBody>
        </MuiTable>
      )}
    </>
  );
};

export const Table = memo(TableComponent) as typeof TableComponent;
