import { FC } from 'react';
import { Form, Checkbox } from 'src/components/ui';
import { IComponentsProp } from '../../types';

export const ShowWinnerCostPerformers: FC<IComponentsProp> = ({ viewMode }) => (
  <Form.Item
    label="Показывать сумму поставки победителя другим исполнителям"
    name="supplySumVisibleForOtherContractors"
    isControlLabel>
    <Checkbox disabled={viewMode} />
  </Form.Item>
);
