import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const AdminContractTemplates = lazy(
  () =>
    import(
      /* webpackChunkName: 'ContractTemplates' */
      /* webpackMode: "lazy" */
      `./contract-templates`
    )
);

export const LazyContractTemplates: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <AdminContractTemplates />
  </Suspense>
);
