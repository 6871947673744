import { Components } from '@mui/material/styles/components';

export const MuiTable: Components['MuiTable'] = {
  styleOverrides: {
    root: {
      padding: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '.MuiTableCell-root': {
        fontSize: '16px',
      },
      '.MuiTableRow-root': {
        borderBottom: '1px solid #DEDEDE',
      },
      '& .MuiTableHead-root': {
        backgroundColor: '#F4F5F7',
        color: '#7A8694',
        borderBottom: '1px solid #DEDEDE',
        '& .MuiTableCell-root': {
          color: '#7A8694',
          borderBottom: '1px solid #DEDEDE',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
    },
  },
};
