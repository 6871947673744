import { AuthService } from 'src/services';
import { ROLES } from '../constants';

export const isContractor = () => AuthService.currentRole === ROLES.CONTRACTOR;
export const isCustomer = () => AuthService.currentRole === ROLES.CUSTOMER;
export const isContragent = () => isContractor || isCustomer;

export const isKam = () => AuthService.currentRole === ROLES.KAM;
export const isKamPlus = () => AuthService.currentRole === ROLES.KAM_PLUS;
export const isManager = () => isKam() || isKamPlus();

export const isAdmin = () => AuthService.currentRole === ROLES.ADMIN;
export const isSystem = () => AuthService.currentRole === ROLES.SYSTEM;
