import { ROLES } from 'src/constants';
import { direction } from '../components/pages/chat/constants';

export enum MessageType {
  MESSAGE = 'MESSAGE',
  USER_WAS_JOINED_TO_CHAT = 'USER_WAS_JOINED_TO_CHAT',
  USER_WAS_REMOVED_FROM_CHAT = 'USER_WAS_REMOVED_FROM_CHAT',
}
export enum CurrentMessageWatchedStatus {
  NOT_WATCHED = 'NOT_WATCHED',
  WATCHED = 'WATCHED',
}
export enum OrganizationRole {
  BUSINESS_REQUEST = 'CUSTOMER',
  CONTRACTOR = 'CONTRACTOR',
  CONTRACTOR_REQUEST = 'CONTRACTOR',
  ORGANIZATION_ABOUT_CONTRACTOR = 'CONTRACTOR',
  ORGANIZATION_CONTACTS_CONTRACTOR = 'CONTRACTOR',
  ORGANIZATION_DETAILS_CONTRACTOR = 'CONTRACTOR',
  ORGANIZATION_BANK_DETAILS_CONTRACTOR = 'CONTRACTOR',
  ORGANIZATION_BANK_DETAILS_CUSTOMER = 'CUSTOMER',
  ORGANIZATION_ABOUT_CUSTOMER = 'CUSTOMER',
  ORGANIZATION_CONTACTS_CUSTOMER = 'CUSTOMER',
  ORGANIZATION_DETAILS_CUSTOMER = 'CUSTOMER',
  ORGANIZATION_SHIPPING_DETAILS = 'CONTRACTOR',
  ORGANIZATION_DELIVERY_DETAILS = 'CUSTOMER',
  ORGANIZATION_TECHNICAL_OPPORTUNITY = 'CONTRACTOR',
}
export enum ConversationEntityType {
  BUSINESS_REQUEST = 'BUSINESS_REQUEST',
  CONTRACTOR = 'CONTRACTOR',
  CONTRACTOR_REQUEST = 'CONTRACTOR_REQUEST',
  ORGANIZATION_ABOUT_CONTRACTOR = 'ORGANIZATION_ABOUT_CONTRACTOR',
  ORGANIZATION_CONTACTS_CONTRACTOR = 'ORGANIZATION_CONTACTS_CONTRACTOR',
  ORGANIZATION_DETAILS_CONTRACTOR = 'ORGANIZATION_DETAILS_CONTRACTOR',
  ORGANIZATION_BANK_DETAILS_CONTRACTOR = 'ORGANIZATION_BANK_DETAILS_CONTRACTOR',
  ORGANIZATION_BANK_DETAILS_CUSTOMER = 'ORGANIZATION_BANK_DETAILS_CUSTOMER',
  ORGANIZATION_ABOUT_CUSTOMER = 'ORGANIZATION_ABOUT_CUSTOMER',
  ORGANIZATION_CONTACTS_CUSTOMER = 'ORGANIZATION_CONTACTS_CUSTOMER',
  ORGANIZATION_DETAILS_CUSTOMER = 'ORGANIZATION_DETAILS_CUSTOMER',
  ORGANIZATION_SHIPPING_DETAILS = 'ORGANIZATION_SHIPPING_DETAILS',
  ORGANIZATION_DELIVERY_DETAILS = 'ORGANIZATION_DELIVERY_DETAILS',
  ORGANIZATION_TECHNICAL_OPPORTUNITY = 'ORGANIZATION_TECHNICAL_OPPORTUNITY',
}

export interface IUserChat {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  fio?: string;
  conversationId?: string;
  isActive: boolean;
  avatar?: string;
}

export interface IMessage {
  id: string;
  conversationId?: string;
  text: string;
  sendAt: string;
  authorUserId: number;
  currentUserStatus: CurrentMessageWatchedStatus;
  otherUsersStatus: CurrentMessageWatchedStatus;
  messageType: MessageType;
}

export interface IChat {
  conversationId: string;
  lastMessageDate: number;
  conversationTitle: string;
  conversationEntityType?: ConversationEntityType;
  organizationRole?: OrganizationRole;
  organizationName: string;
  unreadMessagesCount: number;
  lastMessage: IMessage;
  users: IUserChat[];
  userFioList?: string;
}
export interface IChatHistory {
  hasMessagesBefore: boolean;
  hasMessagesAfter: boolean;
  conversationId: string;
  messages: IMessage[];
  direction: keyof typeof direction;
  referenceIds?: {
    requestId?: number;
    organizationId: number;
    organizationRoles?: ROLES[];
    contractorRequestId: number;
  };
}

export interface TResponse {
  status: number;
  chatData: IChat;
}

export interface ICurrentWatchedKam {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
}

export type RoleFilter = 'ALL' | 'CUSTOMER' | 'CONTRACTOR';
export interface IUserWasJoinedToChat {
  conversationId: string;
  user: IUserChat;
}
export interface IUserWasDeletedFromChat {
  conversationId: string;
  userId: number;
}

export type TManagerList = Array<{
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  role: ROLES;
}>;
