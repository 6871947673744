import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ContractorAllRequests = lazy(
  () =>
    import(
      /* webpackChunkName: 'ContractorAllRequests' */
      /* webpackMode: "lazy" */
      `./contractor-all-requests`
    )
);

export const LazyContractorAllRequests: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ContractorAllRequests />
  </Suspense>
);
