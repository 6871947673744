import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ActivityList } from 'src/components/pages/business-requests/components';
import { KamStatusCode, TCompanyBusinessRequest } from 'src/models/bid';
import { AuthService } from 'src/services';
import { theme } from 'src/theme';
import styles from './request-info-column.module.scss';

export const RequestInfoColumn = ({
  item,
}: {
  item: TCompanyBusinessRequest;
}) => {
  const getBidStatusColor = () => {
    if (
      [KamStatusCode.REQUEST_REFUSAL].includes(item.statusForKamValue) ||
      item.type === 'EXPECTED'
    ) {
      return '#FF9D9D';
    }

    if (
      [
        KamStatusCode.NEW_REQUEST,
        KamStatusCode.QUALIFIED,
        KamStatusCode.COMMERCIAL_QUALIFICATION,
        KamStatusCode.TECHNICAL_QUALIFICATION,
        KamStatusCode.SENT_TO_CONTRACTORS,
      ].includes(item.statusForKamValue)
    ) {
      return '#FCECB2';
    }

    return '#8FEABF';
  };

  return (
    <Link
      to={
        AuthService.isSpecificRole()
          ? `/all-requests/${item.businessRequestId}`
          : `/requests/${item.businessRequestId}`
      }>
      <Stack direction="column" gap="4px">
        <Stack flexDirection="row" gap="12px" alignItems="center">
          <Stack
            sx={{
              borderRadius: '999px',
              padding: '4px 8px',
              backgroundColor: getBidStatusColor(),
            }}
            flexDirection="row"
            gap="4px"
            alignItems="center">
            <Typography fontSize="12px" color="text.primary">
              {item.type === 'EXPECTED' &&
              item.statusForKamValue !== KamStatusCode.REQUEST_REFUSAL
                ? 'Перспектива'
                : item.statusForKam}
            </Typography>
          </Stack>
          <Typography color="text.primary" fontSize="12px">{`№${
            item.number
          } от ${format(new Date(item.createdAt), 'dd.MM.yyyy')}`}</Typography>
        </Stack>
        <Typography
          fontWeight={500}
          fontSize="12px"
          color={theme.palette.primary.dark}>
          {item.name}
        </Typography>
        {item.tagClassifierFistLevel.length !== 0 && (
          <ActivityList
            activityName={item.tagClassifierFistLevel}
            containerClassName={styles.tag}
          />
        )}
      </Stack>
    </Link>
  );
};
