import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

declare module '@mui/material/Radio' {
  interface RadioPropsSizeOverrides {
    large: true;
  }
}

const RadioIcon = () => (
  <div
    className="default-icon"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <svg height="20" width="20" color="inherit">
      <circle
        r="9"
        cx="10"
        cy="10"
        fill="transparent"
        strokeWidth="1px"
        stroke="currentColor"
      />
    </svg>
  </div>
);

const CheckedRadioIcon = () => (
  <div
    className="default-icon checked-default-icon"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}>
    <svg height="20" width="20" color="inherit">
      <circle
        r="9"
        cx="10"
        cy="10"
        fill="transparent"
        strokeWidth="1px"
        stroke="currentColor"
      />
    </svg>
    <div
      className="bullet"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 2,
      }}>
      <svg height="20" width="20" color="inherit">
        <circle r="6" cx="10" cy="10" fill="currentColor" />
      </svg>
    </div>
  </div>
);

export const MuiRadio: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    size: 'large',
    disableRipple: true,
    icon: <RadioIcon />,
    checkedIcon: <CheckedRadioIcon />,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      padding: '0',
      // Нужно для совместимости со старым дизайном
      paddingRight: '3px',
      ...(!ownerState.disabled && {
        '& .checked-default-icon svg': {
          color: theme.palette.primary.main,
        },
      }),
      ...(ownerState.size === 'large' && {
        '& div': {
          height: '20px',
          width: '20px',
        },
        '& div svg': {
          height: '20px',
          width: '20px',
        },
        '& .default-icon svg > circle': {
          r: 9,
          cx: 10,
          cy: 10,
        },
        '& .bullet svg > circle': {
          r: 6,
          cx: 10,
          cy: 10,
        },
      }),

      ...(ownerState.size === 'medium' && {
        '& div svg': {
          height: '16px',
          width: '16px',
        },
        '& div': {
          height: '16px',
          width: '16px',
        },
        '& .default-icon svg > circle': {
          r: 7,
          cx: 8,
          cy: 8,
        },
        '& .bullet svg > circle': {
          r: 4,
          cx: 8,
          cy: 8,
        },
      }),

      ...(ownerState.size === 'small' && {
        '& div svg': {
          height: '12px',
          width: '12px',
        },
        '& div': {
          height: '12px',
          width: '12px',
        },
        '& .default-icon svg > circle': {
          r: 5,
          cx: 6,
          cy: 6,
        },
        '& .bullet svg > circle': {
          r: 3,
          cx: 6,
          cy: 6,
        },
      }),

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '& .default-icon': {
        color: theme.palette.secondary.light,
      },

      '&:hover .default-icon': {
        color: theme.palette.primary.main,
      },

      ...(ownerState.color === 'error' && {
        '& .default-icon': {
          color: '#E11111',
        },
      }),

      ...(ownerState.disabled && {
        '& .default-icon': {
          color: theme.palette.customTheme.borderGrayPrimary,
          '& svg': {
            fill: '#F4F4F4',
          },
        },
      }),
    }),
  },
};
