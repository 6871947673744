import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  Typography,
  DialogActions,
  Button,
} from 'src/components/ui';
import {
  useGetCustomerRejectApplicationReasonsQuery,
  useSetCustomerRejectApplicationMutation,
} from 'src/api/business-request/business-request';
import { ICustomerRejectApplicationParams } from 'src/models';
import { getErrorMessage } from 'src/store/utils';
import { DialogSkeleton } from 'src/components/ui/skeleton';
import { Reasons } from './components';
import styles from './customer-reject-application.module.scss';
import { requestBodyRejectApplication } from '../constants';

interface ICustomerRejectApplicationProps {
  isShowModalWindow: boolean;
  handleShowModalWindow: () => void;
  businessApplicationId: number;
  businessRequestId: number;
}

interface ICustomerRejectApplicationForm {
  selectedReason: string | null;
  comment: string;
}

export const CustomerRejectApplication: FC<ICustomerRejectApplicationProps> = ({
  isShowModalWindow,
  handleShowModalWindow,
  businessApplicationId,
  businessRequestId,
}) => {
  const [
    setCustomerRejectApplicationQuery,
    { isLoading: isLoadingRejectApplication, error: errorRejectApplication },
  ] = useSetCustomerRejectApplicationMutation();

  const {
    data: listReasonsData,
    isLoading: isLoadingListReasons,
    error: errorListReasons,
  } = useGetCustomerRejectApplicationReasonsQuery(requestBodyRejectApplication);

  const listReasons = useMemo(
    () =>
      listReasonsData &&
      listReasonsData.items.filter((item) => item.status !== 'ARCHIVED'),
    [listReasonsData]
  );

  const form = useForm<ICustomerRejectApplicationForm>({
    defaultValues: {
      selectedReason: null,
      comment: '',
    },
  });

  const handleSubmitForm = async (data: ICustomerRejectApplicationForm) => {
    if (!data.selectedReason) return;

    const dataQuery: ICustomerRejectApplicationParams = {
      businessApplicationId,
      businessRequestId,
      causeName: data.selectedReason,
      comment: data.comment,
    };

    try {
      await setCustomerRejectApplicationQuery(dataQuery).unwrap();
      handleShowModalWindow();
    } catch {
      // ignore
    }
  };

  return (
    <Dialog
      maxWidth={false}
      open={isShowModalWindow}
      close={handleShowModalWindow}>
      {isLoadingListReasons ? (
        <DialogSkeleton width="544px" height="550px" />
      ) : (
        <>
          <DialogTitle>
            <Typography variant="h2" fontWeight="600">
              Вы уверены, что хотите отказаться от заявки?
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Form form={form} viewMode>
              <Reasons items={listReasons} />
            </Form>
          </DialogContent>

          <DialogActions className={styles.containerActions}>
            <div>
              <Typography color="error">
                {errorListReasons
                  ? getErrorMessage(
                      errorListReasons,
                      'Произошла ошибка при загрузке списка'
                    )
                  : errorRejectApplication &&
                    getErrorMessage(
                      errorRejectApplication,
                      'Произошла ошибка при отмене заявки'
                    )}
              </Typography>
            </div>

            <Button
              loading={isLoadingRejectApplication}
              color="attention"
              onClick={form.handleSubmit(handleSubmitForm)}>
              Отказаться от заявки
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
