import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { TQueryError } from 'src/models';

export const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError =>
  typeof error === 'object' && error !== null && 'status' in error;

export const isAbortRequestError = (
  error: unknown
): error is { name: 'AbortError' } =>
  typeof error === 'object' &&
  error !== null &&
  'name' in error &&
  error.name === 'AbortError';

export const isErrorWithMessage = (
  error: unknown
): error is { message: string } =>
  typeof error === 'object' &&
  error != null &&
  'message' in error &&
  typeof (error as any).message === 'string';

export const isErrorWithCode = (error: unknown): error is TQueryError =>
  typeof error === 'object' &&
  error != null &&
  'errorCode' in error &&
  typeof (error as any).errorCode === 'string';
