import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const Registration = lazy(
  () =>
    import(
      /* webpackChunkName: 'Registration' */
      /* webpackMode: "lazy" */
      `./registration`
    )
);

export const LazyRegistration: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <Registration />
  </Suspense>
);
