import {
  IEmailMessage,
  IMessage,
  INote,
  IStatus,
  ITelephonyCall,
} from 'src/models/communications';
import {
  isEmailItem,
  isMessageItem,
  isNotificationItem,
  isStatusItem,
} from 'src/components/pages/communications/utils';
import { TNotification } from 'src/models/notifications';
import { Call, Email, Message, Status, Notification } from './components';

type TNotifications =
  | IEmailMessage
  | INote
  | IMessage
  | IStatus
  | TNotification
  | ITelephonyCall[];

interface IBubbleWrapperProps {
  item: TNotifications;
  onClick?: () => void;
}

export const BubbleWrapper = ({ item, onClick }: IBubbleWrapperProps) => {
  if (isEmailItem(item)) {
    return <Email data={item} onClick={onClick} />;
  }

  if (isMessageItem(item)) {
    return <Message data={item} onClick={onClick} />;
  }

  // Звонки группируются по callRecordLink
  if (Array.isArray(item)) {
    return <Call data={item} onClick={onClick} />;
  }

  if (isStatusItem(item)) {
    return <Status data={item} onClick={onClick} />;
  }

  if (isNotificationItem(item)) {
    return <Notification data={item} onClick={onClick} />;
  }

  return null;
};
