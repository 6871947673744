import { FC } from 'react';
import { Typography } from 'src/components/ui';
import { Stack } from '@mui/material';
import { IDynamicItem } from 'src/models';

interface IFinancialItemProps {
  title: string;
  value: string;
  dynamic: IDynamicItem;
  className?: string;
  isRowDirection?: boolean;
}

const DynamicItem: FC<{
  value: IDynamicItem['value'];
  isIncreasing: IDynamicItem['isIncreasing'];
  ml0?: boolean;
}> = ({ value, isIncreasing, ml0 = false }) => (
  <Typography
    variant="h4"
    color={isIncreasing ? '#13B81A' : '#E11111'}
    marginLeft={ml0 ? '0' : '4px'}>
    {isIncreasing ? `+${value}%` : `-${value}%`}
  </Typography>
);

export const FinancialItem: FC<IFinancialItemProps> = ({
  title,
  value,
  dynamic,
  className,
  isRowDirection = true,
}) => {
  const formatter = Intl.NumberFormat('ru-RU', { notation: 'compact' });

  const [valueNumber, currency] = formatter.format(+value).split(' ');

  return (
    <Stack gap="4px" className={className}>
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
      <Stack direction="row" gap="4px" alignItems="baseline">
        <Typography
          variant="h1"
          fontSize="20px"
          lineHeight="20px"
          display="inline"
          style={{ marginBottom: '0' }}>
          {valueNumber}
        </Typography>
        <Typography variant="h4" display="inline" lineHeight="16px">
          {currency || 'руб'}
        </Typography>
        {isRowDirection && <DynamicItem {...dynamic} />}
      </Stack>
      {!isRowDirection && <DynamicItem {...dynamic} ml0 />}
    </Stack>
  );
};
