import ru from 'date-fns/locale/ru';
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

const defineds = {
  startOfWeek: startOfWeek(new Date(), { locale: ru }),
  endOfWeek: endOfWeek(new Date(), { locale: ru }),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7), { locale: ru }),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7), { locale: ru }),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

export const STATIC_RANGES = [
  {
    label: 'Сегодня',
    // hasCustomRendering: true,
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: 'Вчера',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: 'Текущая Неделя',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: 'Прошлая Неделя',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: 'Текущий Месяц',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: 'Прошлый Месяц',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
    isSelected() {
      return false;
    },
  },
];
