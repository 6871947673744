import { RebiddingStatus } from './manager-rebidding';

export enum RebiddingContractorStatus {
  INVITATION_SENT = 'INVITATION_SENT',
  EXCLUDED = 'EXCLUDED',
  REFUSED = 'REFUSED',
  COMMERCIAL_OFFER_SENT = 'COMMERCIAL_OFFER_SENT',
  COMMERCIAL_OFFER_RECEIVED = 'COMMERCIAL_OFFER_RECEIVED',
}

export enum RebiddingContractorRequestStatus {
  REQUEST_EXCLUDED = 'REQUEST_EXCLUDED',
  REQUEST_REFUSED = 'REQUEST_REFUSED',
}

export type TContractorRebidding = {
  rebiddingId: number;
  businessRequestId: number;
  initialMaximumCost: string;
  commercialOfferDueDate: string;
  comment: string;
  status: {
    code: RebiddingContractorStatus | RebiddingContractorRequestStatus;
    displayName: string;
    createdAt: string;
  };
  rebiddingStatusCode: RebiddingStatus;
};
