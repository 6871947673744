import { Autocomplete } from 'src/components/ui';
import { TAddress } from 'src/components/pages/create-bid/types';
import { useCallback, FC } from 'react';
import { useLazyGetAddressesQuery } from 'src/api/dadata';
import { IDeliveryAddress } from 'src/models';
import { ArrowDown } from 'src/assets/icons';

type TDeliveryAddressSelect = {
  options: Array<IDeliveryAddress>;
  isDisabled?: boolean;
  isEmpty?: boolean;
};

export const DeliveryAddressSelect: FC<TDeliveryAddressSelect> = ({
  options,
  isDisabled,
  isEmpty,
  ...props
}) => {
  const [getAddresses] = useLazyGetAddressesQuery();

  const fetchAddresses = useCallback(
    (address: string) =>
      address ? getAddresses(address).unwrap() : Promise.resolve([]),
    [getAddresses]
  );

  const fetchAddressFilter = useCallback(
    (value: string) =>
      Promise.resolve(
        (value
          ? options?.filter((address) =>
              address.address.toLowerCase().includes(value.toLowerCase())
            )
          : options) || []
      ),
    [options]
  );

  return options.length > 0 ? (
    <Autocomplete<IDeliveryAddress>
      getOptionLabel={(option) => option.address || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      popupIcon={isEmpty && <ArrowDown />}
      fetchOnEmptyInput
      options={options}
      fetchApi={fetchAddressFilter}
      placeholder="Введите адрес"
      disabled={isDisabled}
      {...props}
    />
  ) : (
    <Autocomplete<TAddress>
      getOptionLabel={(option) => option.address || ''}
      popupIcon={isEmpty && <ArrowDown />}
      isOptionEqualToValue={(option, value) => option.address === value.address}
      fetchApi={fetchAddresses}
      fetchOnEmptyInput
      disabled={isDisabled}
      placeholder="Введите адрес"
      {...props}
    />
  );
};
