import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  Typography,
  DialogActions,
  Button,
} from 'src/components/ui';
import {
  useGetContractorRejectRequestReasonsQuery,
  useSetContractorRejectRequestMutation,
} from 'src/api/business-request/business-request';
import { IContractorRejectApplicationParams } from 'src/models';
import { getErrorMessage } from 'src/store/utils';
import { DialogSkeleton } from 'src/components/ui/skeleton';
import { Reasons } from './components';
import styles from './contractor-reject-application.module.scss';
import { requestBodyRejectApplication } from '../constants';

interface IContractorRejectApplicationProps {
  isShowModalWindow: boolean;
  handleShowModalWindow: () => void;
  contractorRequestId: number;
}

interface IContractorRejectApplicationForm {
  selectedReason: string | null;
  comment: string;
}

export const ContractorRejectApplication: FC<
  IContractorRejectApplicationProps
> = ({ isShowModalWindow, handleShowModalWindow, contractorRequestId }) => {
  const [
    setContractorRejectApplicationQuery,
    { isLoading: isLoadingRejectApplication, error: errorRejectApplication },
  ] = useSetContractorRejectRequestMutation();

  const {
    data: listReasonsData,
    isLoading: isLoadingListReasons,
    error: errorListReasons,
  } = useGetContractorRejectRequestReasonsQuery(requestBodyRejectApplication);

  const listReasons = useMemo(
    () =>
      listReasonsData &&
      listReasonsData.items.filter((item) => item.status !== 'ARCHIVED'),
    [listReasonsData]
  );
  const form = useForm<IContractorRejectApplicationForm>({
    defaultValues: {
      selectedReason: '',
      comment: '',
    },
  });

  const handleSubmitForm = async (data: IContractorRejectApplicationForm) => {
    if (!data.selectedReason) return;

    const dataQuery: IContractorRejectApplicationParams = {
      contractorRequestId,
      contractorCauseName: data.selectedReason,
      contractorComment: data.comment,
    };

    try {
      await setContractorRejectApplicationQuery(dataQuery).unwrap();
      handleShowModalWindow();
    } catch {
      // ignore
    }
  };

  return (
    <Dialog
      maxWidth={false}
      open={isShowModalWindow}
      close={handleShowModalWindow}>
      {isLoadingListReasons ? (
        <DialogSkeleton width="544px" height="550px" />
      ) : (
        <>
          <DialogTitle>
            <Typography variant="h2" fontWeight="600">
              Вы уверены, что хотите отказаться от заявки?
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Form form={form} viewMode>
              <Reasons items={listReasons} />
            </Form>
          </DialogContent>

          <DialogActions className={styles.containerActions}>
            <div>
              <Typography color="error">
                {errorListReasons
                  ? getErrorMessage(
                      errorListReasons,
                      'Произошла ошибка при загрузке списка'
                    )
                  : errorRejectApplication &&
                    getErrorMessage(
                      errorRejectApplication,
                      'Произошла ошибка при отмене заявки'
                    )}
              </Typography>
            </div>

            <Button
              loading={isLoadingRejectApplication}
              color="attention"
              onClick={form.handleSubmit(handleSubmitForm)}>
              Отказаться от заявки
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
