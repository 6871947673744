import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import {
  TSubcategory,
  TTechnicalOpportunityList,
  TTechnicalOpportunity,
  TTechnicalOpportunityEdit,
} from 'src/models/admin';
import { DIRECTION } from 'src/components/ui/filters/types';
import { ETagStatuses } from '../../configs/tag-status';

export type TSortKey = 'NAME';

export type TTechnicalOpportunityListParams = {
  sort: {
    field: TSortKey;
    order: DIRECTION;
  };
  paging: {
    page: number;
    limit: number;
  };
  searchQueries: Array<string>;
  statusCodes: Array<ETagStatuses>;
};

export type TTechnicalOpportunitiesStatus = {
  categoryIds: Array<number>;
  subCategoryIds: Array<number>;
  action: string;
};

export const adminTechnicalOpportunities = createApi({
  reducerPath: 'adminTechnicalOpportunitiesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getTechnicalOpportunities: builder.mutation<
      TTechnicalOpportunityList,
      TTechnicalOpportunityListParams
    >({
      query: (body) => ({
        url: `business-opportunities/api/admin/v1/technical-opportunities-categories`,
        method: 'POST',
        body,
      }),
    }),
    updateTechnicalOpportunitiesStatus: builder.mutation<
      void,
      TTechnicalOpportunitiesStatus
    >({
      query: (body) => ({
        url: 'business-opportunities/api/v1/technical-opportunities-categories/status',
        method: 'PATCH',
        body,
      }),
    }),
    editFirstLevelCategory: builder.mutation<
      TTechnicalOpportunity,
      TTechnicalOpportunityEdit
    >({
      query: (body) => ({
        url: `business-opportunities/api/admin/v1/technical-opportunities-categories/first-level/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    editSecondLevelCategory: builder.mutation<
      TSubcategory,
      Omit<TTechnicalOpportunityEdit, 'subcategories'>
    >({
      query: (body) => ({
        url: `/business-opportunities/api/admin/v1/technical-opportunities-categories/second-level/${body.id}?name=${body.name}`,
        method: 'PUT',
      }),
    }),
    createFirstLevelCategory: builder.mutation<
      TTechnicalOpportunity,
      TTechnicalOpportunityEdit
    >({
      query: (body) => ({
        url: 'business-opportunities/api/admin/v1/technical-opportunities-categories/first-level',
        method: 'POST',
        body,
      }),
    }),
    getSearchSecondLevelCategories: builder.mutation<
      Array<TSubcategory>,
      string
    >({
      query: (name) => ({
        url: `business-opportunities/api/admin/v1/technical-opportunities-categories/second-level/search?query=${name}`,
      }),
    }),
  }),
});

export const {
  useGetTechnicalOpportunitiesMutation,
  useUpdateTechnicalOpportunitiesStatusMutation,
  useGetSearchSecondLevelCategoriesMutation,
  useEditFirstLevelCategoryMutation,
  useEditSecondLevelCategoryMutation,
  useCreateFirstLevelCategoryMutation,
} = adminTechnicalOpportunities;
