import { TFile } from 'src/components/ui/file-upload/file-upload';
import { getErrorMessage } from 'src/store/utils';

type TSaveFiles = {
  files: TFile[];
  account: string;
  uploadHandler: (file: FormData) => Promise<{ key: string }>;
};

export const saveFiles = async ({
  files,
  account,
  uploadHandler,
}: TSaveFiles) => {
  let saveError = false;
  const promiseAddList: Promise<{ key: string } | string>[] = [];
  const promiseDeleteList: Promise<{ key: string } | string>[] = [];

  files.forEach((file) => {
    if (file.isNew) {
      promiseDeleteList.push(Promise.resolve(''));
    }
  });

  const deleteResult = await Promise.all(promiseDeleteList);

  files.forEach((file) => {
    if (file.isNew) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('userName', account);

      promiseAddList.push(
        uploadHandler(formData).catch((e) => {
          saveError = true;
          return getErrorMessage(e);
        })
      );
    }
  });

  const addResult = await Promise.all(promiseAddList);

  // TODO: Remove any
  const saveResult = files.reduce((acc: any[], item, i) => {
    if (item.isNew || item.deleted) {
      const resultData = item.isNew ? addResult[i] : deleteResult[i];

      if (typeof resultData === 'object') {
        if (item.isNew) {
          const { isNew, file, ...resultFile } = item;
          acc.push({
            ...resultFile,
            key: resultData.key,
            // id: +resultData.key,
            deleted: false,
          });
        }
      } else {
        acc.push({
          ...item,
          error: resultData,
        });
      }
    } else {
      acc.push(item);
    }

    return acc;
  }, []);

  return { saveResult, error: saveError };
};
