import { ChangeEvent, FC } from 'react';
import {
  NumericFormatProps,
  NumericFormat,
  NumberFormatValues,
} from 'react-number-format';
import { OutlinedInput, OutlinedInputProps } from 'src/components/ui';

type NumberInputMode = 'price' | 'noDecimal';

type TNumberInputProps = {
  mode?: NumberInputMode;
  integerScale?: number;
};

export const priceProps = {
  fixedDecimalScale: true,
  decimalScale: 2,
  thousandSeparator: ' ',
  decimalSeparator: ',',
  placeholder: '0,00',
};

const getPropsByMode = (
  displayType: NumericFormatProps['displayType'],
  mode?: NumberInputMode
) => {
  if (mode === 'price') {
    return priceProps;
  }

  if (mode === 'noDecimal') {
    return {
      decimalScale: 0,

      ...(displayType !== 'text' && { placeholder: '0' }),
    };
  }

  return undefined;
};

const formattingText = (text: string) =>
  text.replace(/ /g, '').replace(/,/g, '.');

export const NumberInput: FC<
  NumericFormatProps & OutlinedInputProps & TNumberInputProps
> = ({
  onChange,
  mode,
  displayType,
  isAllowed,
  integerScale,
  allowNegative = false,
  autoComplete = 'off',
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    onChange({
      ...e,
      target: { ...e.target, value: formattingText(e.target.value) },
    });
  };

  const isValueAllowed = (inputValue: NumberFormatValues) => {
    const { value } = inputValue;
    const valueParts = value.split('.');
    if (integerScale && valueParts[0].length > integerScale) {
      return false;
    }
    return !isAllowed || isAllowed(inputValue);
  };

  return (
    <NumericFormat
      {...getPropsByMode(displayType, mode)}
      {...(displayType === 'text' ? props : { ...props, trimValue: false })}
      isAllowed={isValueAllowed}
      allowedDecimalSeparators={[',', '.']}
      allowNegative={allowNegative}
      valueIsNumericString
      displayType={displayType}
      onChange={handleChange}
      autoComplete={autoComplete}
      onPaste={(e: any) => {
        e.preventDefault();

        const newVal = formattingText(e.clipboardData.getData('text'));
        onChange?.({
          ...e,
          target: { ...e.target, value: newVal },
        });
      }}
      customInput={OutlinedInput}
    />
  );
};
