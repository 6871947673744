import { Stack } from '@mui/material';
import { InfoIcon } from 'src/assets/icons';
import { Card, EmptyMessage, SvgIcon, Typography } from 'src/components/ui';
import { IAccountingBlock, IKonturFocusData } from 'src/models';
import { FinancialItem, FinancialTable } from './components';
import styles from './company-financial-statements-info-block.module.scss';

export const CompanyFinancialStatementsInfoBlock = ({
  scoringInfo,
  infoBlockClassName,
}: {
  scoringInfo: IKonturFocusData;
  infoBlockClassName?: string;
}) => {
  const blocks = scoringInfo?.accountingBlocks;

  const isBlockHaveTableInformation = (block: IAccountingBlock) =>
    block?.tableInformation?.rows?.length > 0;

  const isBlockHaveAccountingInformation = (block: IAccountingBlock) =>
    block?.accountingInformationList?.length > 0 &&
    block?.accountingInformationList.find(
      (accountingInfoItem) => accountingInfoItem?.items?.length > 0
    );

  return (
    <div>
      {blocks?.length > 0 ? (
        blocks.map(
          (block) =>
            (isBlockHaveTableInformation(block) ||
              isBlockHaveAccountingInformation(block)) && (
              <Card
                title={block.title}
                key={block.id}
                className={infoBlockClassName}>
                {isBlockHaveAccountingInformation(block) &&
                  block.accountingInformationList.map(
                    (accountingInfoItem) =>
                      accountingInfoItem?.items?.length > 0 && (
                        <Stack
                          gap="12px"
                          marginBottom="24px"
                          key={accountingInfoItem.title}>
                          <Typography variant="h6">
                            {accountingInfoItem.title}
                          </Typography>

                          <Stack
                            direction="row"
                            className={styles.financialItemWrapper}>
                            {accountingInfoItem.items.map((item) => (
                              <FinancialItem
                                title={item.title}
                                value={item.value}
                                dynamic={item.dynamic}
                                key={item.title}
                                className={styles.financialItem}
                              />
                            ))}
                          </Stack>
                        </Stack>
                      )
                  )}

                {isBlockHaveTableInformation(block) && (
                  <FinancialTable {...block.tableInformation} />
                )}
              </Card>
            )
        )
      ) : (
        <EmptyMessage title="Информация по бухгалтерской отчетности неизвестна">
          По указанному ИНН компании информация по бухгалтерской отчетности не
          найдена
        </EmptyMessage>
      )}
    </div>
  );
};
