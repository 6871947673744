import { FC } from 'react';
import { Skeleton, ContentColumnSkeleton } from 'src/components/ui';

import styles from './customer-skeleton.module.scss';

export const CustomerSkeleton: FC<{ showColumns: boolean }> = ({
  showColumns,
}) => (
  <div className={styles.container}>
    <div className={styles.containerCustomerTabs}>
      <Skeleton className={styles.tabs} />
      <Skeleton className={styles.btn} />
    </div>
    <div className={styles.containerContent}>
      {showColumns ? (
        <ContentColumnSkeleton />
      ) : (
        <>
          <Skeleton className={styles.title} />
          <Skeleton className={styles.contentAbout} />
        </>
      )}
    </div>
  </div>
);
