import { FC, useState } from 'react';
import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  OutlinedInputProps,
} from 'src/components/ui';
import { PasswordHidden, PasswordVisible } from 'src/assets/icons';
import styles from './password-input.module.css';

export const PasswordInput: FC<OutlinedInputProps> = ({ ...props }) => {
  const [password, setPassword] = useState<boolean>(true);

  return (
    <OutlinedInput
      {...props}
      classes={{
        adornedEnd: styles.adornedEnd,
        inputAdornedEnd: styles.inputAdornedEnd,
      }}
      endAdornment={
        <InputAdornment
          position="end"
          className={styles.inputAdornedEndWrapper}>
          <IconButton
            onMouseDown={(e) => e.preventDefault()}
            onMouseUp={(e) => e.preventDefault()}
            onClick={() => setPassword(!password)}
            tabIndex={-1}>
            {password ? <PasswordVisible /> : <PasswordHidden />}
          </IconButton>
        </InputAdornment>
      }
      type={password ? 'password' : 'text'}
    />
  );
};
