import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Arrow } from 'src/assets/icons';
import {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './slider.module.scss';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export interface SliderProps {
  children?: React.ReactNode;
  wrapperClassName?: string;
  showNavigationButtons?: boolean;
  onIndexChange?: (index: number) => void;
  autoplay?: boolean;
  autoPlayDelay?: number;
  fadeEffect?: boolean;
}

export const Slider = ({
  children,
  wrapperClassName,
  showNavigationButtons = true,
  onIndexChange,
  autoplay,
  autoPlayDelay,
  fadeEffect,
}: SliderProps) => {
  const swiperRef = useRef<any>(null);

  return (
    <div className={classNames(wrapperClassName, styles.swiper)}>
      <Swiper
        onSlideChange={(swiper: any) => onIndexChange?.(swiper.realIndex)}
        ref={swiperRef}
        mousewheel
        keyboard
        loop
        cssMode={!fadeEffect}
        effect={fadeEffect ? 'fade' : 'slide'}
        pagination={{
          clickable: true,
        }}
        autoplay={
          autoplay
            ? {
                delay: autoPlayDelay || 3000,
                pauseOnMouseEnter: true,
              }
            : undefined
        }
        modules={[
          Navigation,
          Pagination,
          Mousewheel,
          Keyboard,
          Autoplay,
          EffectFade,
        ]}>
        {React.Children.map(children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </Swiper>
      {showNavigationButtons && (
        <>
          <IconButton
            className={styles.prevSliderButton}
            onClick={() => swiperRef?.current?.swiper.slidePrev()}>
            <Arrow />
          </IconButton>
          <IconButton
            className={styles.nextSliderButton}
            onClick={() => swiperRef?.current?.swiper.slideNext()}>
            <Arrow />
          </IconButton>
        </>
      )}
    </div>
  );
};
