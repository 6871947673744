import { FC } from 'react';
import { CompanyTypes, ContractStatuses } from 'src/models';
import { Link, Typography } from 'src/components/ui';
import styles from './company-contract-status.module.scss';

interface IStatusContractProps {
  contractStatus: ContractStatuses;
  organizationId: number;
  type?: CompanyTypes;
}

export const CompanyContractStatus: FC<IStatusContractProps> = ({
  contractStatus,
  organizationId,
  type = CompanyTypes.CONTRACTOR,
}) => (
  <Typography variant="body2" color="secondary" className={styles.contract}>
    {type === CompanyTypes.CONTRACTOR ? 'С исполнителем' : 'С заказчиком'}&nbsp;
    {contractStatus === ContractStatuses.CONTRACT_SIGNED ? (
      <Link
        href={`/companies/contractors/${organizationId}/contract`}
        target="_blank">
        подписан договор
      </Link>
    ) : (
      'не подписан договор'
    )}
  </Typography>
);
