import { TCargoParametersFull } from '../cargo-parameters';

export enum AuctionStepType {
  FIXED_COST = 'FIXED_COST',
  INITIAL_MAX_COST_PERCENT = 'INITIAL_MAX_COST_PERCENT',
  NONE = 'NONE',
}

export enum AuctionStatusCode {
  CREATED = 'CREATED',
  BID_PLACED = 'BID_PLACED',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  CLOSED = 'CLOSED',
}

// TODO: возможно будет нужен
// export const ManagerAuctionStatusName: Record<
//   AuctionStatusCode,
//   string
// > = {
//   [AuctionStatusCode.CREATED]: 'Аукцион создан',
//   [AuctionStatusCode.BID_PLACED]: 'Ставка сделана',
//   [AuctionStatusCode.PENDING]: 'Аукцион не начался',
//   [AuctionStatusCode.STARTED]: 'Аукцион начался',
//   [AuctionStatusCode.FINISHED]: 'Аукцион окончен',
//   [AuctionStatusCode.CLOSED]: 'Аукцион закрыт',
// };

export interface IManagerAuction {
  auctionId: number;
  businessRequestId: number;
  withInitialMaxCost: boolean;
  initialMaxCost: number;
  withCostDropLimit: boolean;
  costDropLimit: number;
  minStep: number;
  maxStep: number;
  stepType: AuctionStepType;
  shippingIncluded: boolean;

  shipping: {
    cargoDescription: TCargoParametersFull | null;
    deliveryAddress: string;
  };

  startAt: string | null;
  endAt: string | null;
  canBeProlonged: boolean;
  autoProlongationDuration: number;
  status: {
    code: AuctionStatusCode;
    displayName: string;
    createdAt: string | null;
  };
}

export interface IManagerUpdateAuctionParams {
  auctionId: number;

  initialMaxCost: number | null;
  withInitialMaxCost: boolean;

  costDropLimit: number | null;
  withCostDropLimit: boolean;

  minStep: number | null;
  maxStep: number | null;
  stepType: AuctionStepType;
  shippingIncluded: boolean;

  shipping: {
    cargoDescription: TCargoParametersFull | null;
    deliveryAddress: string;
  };

  startAt: string | null;
  endAt: string | null;

  autoProlongationDuration: number | null;
  canBeProlonged: boolean;
}

export interface IManagerCreateAuctionParams {
  businessRequestId: number;

  initialMaxCost: number | null;
  withInitialMaxCost: boolean;

  costDropLimit: number | null;
  withCostDropLimit: boolean;

  minStep: number | null;
  maxStep: number | null;
  stepType: AuctionStepType;
  shippingIncluded: boolean;

  shipping: {
    cargoDescription: TCargoParametersFull | null;
    deliveryAddress: string;
  };

  startAt: string | null;
  endAt: string | null;

  autoProlongationDuration: number | null;
  canBeProlonged: boolean;
}

export interface IManagerAuctionListBidsFromContractorParams {
  auctionId: number;
  bestCostOnly: boolean;

  paging: {
    page: number;
    limit: number;
  };
}

export enum AuctionContractorRequestStatusCode {
  INVITATION_SENT = 'INVITATION_SENT',
  EXCLUDED = 'EXCLUDED',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
  REFUSED = 'REFUSED',
  BID_RECEIVED = 'BID_RECEIVED',
  COMMERCIAL_OFFER_REQUESTED = 'COMMERCIAL_OFFER_REQUESTED',
  COMMERCIAL_OFFER_RECEIVED = 'COMMERCIAL_OFFER_RECEIVED',
}

// export const ManagerAuctionContractorRequestStatusName: Record<
//   ManagerAuctionContractorRequestStatusCode,
//   string
// > = {
//   [ManagerAuctionContractorRequestStatusCode.INVITATION_SENT]: 'Аукцион создан',
//   [ManagerAuctionContractorRequestStatusCode.EXCLUDED]: 'Ставка сделана',
//   [ManagerAuctionContractorRequestStatusCode.INVITATION_ACCEPTED]:
//     'Аукцион не начался',
//   [ManagerAuctionContractorRequestStatusCode.REFUSED]: 'Аукцион начался',
//   [ManagerAuctionContractorRequestStatusCode.BID_RECEIVED]: 'Аукцион окончен',
//   [ManagerAuctionContractorRequestStatusCode.COMMERCIAL_OFFER_REQUESTED]:
//     'Аукцион закрыт',
//   [ManagerAuctionContractorRequestStatusCode.COMMERCIAL_OFFER_RECEIVED]:
//     'Аукцион закрыт',
// };

export enum AuctionBidStatusCode {
  CREATED = 'CREATED',
  COMMERCIAL_OFFER_REQUESTED = 'COMMERCIAL_OFFER_REQUESTED',
  COMMERCIAL_OFFER_RECEIVED = 'COMMERCIAL_OFFER_RECEIVED',
}

// export const ManagerAuctionBidStatusHistoryStatusName: Record<
//   ManagerAuctionBidStatusHistoryStatusCode,
//   string
// > = {
//   [ManagerAuctionBidStatusHistoryStatusCode.CREATED]: 'Ставка получена',
//   [ManagerAuctionBidStatusHistoryStatusCode.COMMERCIAL_OFFER_REQUESTED]:
//     'КП запрошено',
//   [ManagerAuctionBidStatusHistoryStatusCode.COMMERCIAL_OFFER_RECEIVED]:
//     'КП получено',
// };

export interface IManagerAuctionListBidsFromContractor {
  totalCount: number;
  page: number;
  bestTotalCost: number | null;

  items: {
    auctionContractorRequestId: number;
    auctionBidId: number;
    offeredCost: number;
    shippingCost: number;
    totalCost: number;
    commercialOfferSentAt: string | null;
    bestCost: boolean;

    organization: {
      organizationId: number;
      organizationName: string;
      mainShippingAddress: string;
    };

    auctionContractorRequestStatusHistory: {
      code: AuctionContractorRequestStatusCode;
      displayName: string;
      createdAt: string;
    }[];

    auctionBidStatusHistory:
      | {
          code: AuctionBidStatusCode;
          displayName: string;
          createdAt: string;
        }[]
      | null;

    additionalStatusInfo: {
      auctionRefusal: {
        cause: string;
        createdAt: string | null;
      } | null;

      auctionExclusion: {
        cause: string;
        createdAt: string | null;
      } | null;

      contractorRequestRefusal: {
        cause: string;
        comment: string;
        createdAt: string | null;
      } | null;

      contractorRequestExclusion: {
        cause: string;
        comment: string;
        createdAt: string | null;
      } | null;
    };
  }[];
}

export interface IManagerAuctionRequestCommercialOffer {}
