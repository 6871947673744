import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { TShippingList } from 'src/models/admin/shipping';
import { TListRequestParams, TPaging } from 'src/api/models';

export type TSortKey =
  | 'SHIPMENT_ID'
  | 'NUMBER'
  | 'SHIPMENT_DATE'
  | 'DELIVERY_DATE'
  | 'STATUS';

export type TShippingFilterParams = {
  shipmentStatuses?: string[];
  status?: string[];
};

export type TShipmentsRequestListParams = Omit<
  TListRequestParams<TSortKey, TShippingFilterParams>,
  'pagingDto'
> & { paging: TPaging };

export const adminShippingApi = createApi({
  reducerPath: 'adminShippingApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getShippingList: builder.mutation<
      TShippingList,
      TShipmentsRequestListParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/admin/shipments',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetShippingListMutation } = adminShippingApi;
