import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { getPathEndsWith } from 'src/utils';
import { Skeleton } from 'src/components/ui';
import {
  CustomerSkeleton,
  HeaderSkeleton,
  ContentSkeleton,
} from './components';
import styles from './main-layout-skeleton.module.scss';

export const MainLayoutSkeleton: FC = () => {
  const { pathname } = useLocation();
  const page = getPathEndsWith(pathname);
  const isCustomerContactsPage = page === 'contacts';
  const isCustomerPage = isCustomerContactsPage || !page || page === 'customer';

  return (
    <>
      <HeaderSkeleton />

      <div className={styles.container}>
        {isCustomerPage ? (
          <CustomerSkeleton showColumns={isCustomerContactsPage} />
        ) : (
          <ContentSkeleton />
        )}

        <div className={styles.containerRight}>
          <Skeleton className={styles.stepper} />
          <Skeleton className={styles.communicationBlock} />
        </div>
      </div>
    </>
  );
};
