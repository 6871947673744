import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowBack: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
    {...props}>
    <path
      d="M5 12H19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 17.001L4.99902 12L10 6.99898"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
