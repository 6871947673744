import { Stack, SvgIcon } from 'src/components/ui';
import { CheckedIcon, CrossCircle20 } from 'src/assets/icons';
import { FC, ReactNode } from 'react';
import { TValidationKeyToValueMap } from '../../types';
import styles from './password-helper-block.module.scss';

const ALLOWED_SYMBOLS_STRING = '* ( ) _ - + = " @ № ; : ? ~ ` . , < > ! # $ % ^ & / \\ | { } [ ] \' § ±';

const ContentItemBlock: FC<{
  isValid: boolean;
  title: string | ReactNode;
  className?: string;
}> = ({ isValid, title, className }) => (
  <li>
    <Stack direction="row" gap="8px" className={className}>
      <SvgIcon
        icon={isValid ? CheckedIcon : CrossCircle20}
        width="16px"
        height="16px"
        strokeColor={isValid ? '#13B81A' : '#7A8694'}
        strokeWidth="2"
      />
      {title}
    </Stack>
  </li>
);

export const PasswordHelperBlock: FC<{
  validationKeyToValueMap: TValidationKeyToValueMap;
}> = ({ validationKeyToValueMap }) => {
  const validationItems = [
    {
      value: validationKeyToValueMap.NUMBER_AND_LETTER,
      title: 'Содержать цифры и буквы латинского алфавита',
    },
    {
      value: validationKeyToValueMap.CAPITAL_LETTER,
      title: 'Содержать хотя бы одну заглавную букву',
    },
    {
      value: validationKeyToValueMap.SPECIAL_SYMBOL,
      title: (
        <>
          Содержать специальные символы: <br />
          {ALLOWED_SYMBOLS_STRING}
        </>
      ),
      className: styles.flexStart,
    },
    {
      value: validationKeyToValueMap.MIN_LENGTH,
      title: 'Длина пароля не менее 8 символов',
    },
  ];

  return (
    <>
      Пароль должен соответствовать следующим условиям:
      <ul
        style={{
          listStyleType: 'none',
          paddingInlineStart: 0,
          marginBlockStart: '4px',
        }}>
        {validationItems.map((item, idx) => (
          <ContentItemBlock
            key={idx}
            isValid={item.value}
            title={item.title}
            className={item.className || ''}
          />
        ))}
      </ul>
    </>
  );
};
