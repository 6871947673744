import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const AdminEvents = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminEvents' */
      /* webpackMode: "lazy" */
      `./events`
    )
);

export const LazyEvents: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <AdminEvents />
  </Suspense>
);
