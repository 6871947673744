import { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Box, IconButton } from 'src/components/ui';
import { Arrow } from 'src/assets/icons';
import styles from './card-slider.module.scss';

type TProps = {
  countTotal: number;
  countPerPage: number;
  onChange?: (x: number) => void;
  loop?: boolean;
};

export const CustomPagination: FC<TProps> = ({
  countTotal,
  countPerPage,
  onChange,
  loop,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const countPage = useMemo(
    () => Math.ceil(countTotal / countPerPage),
    [countPerPage, countTotal]
  );

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      onChange?.(currentPage - 1);
    } else {
      setCurrentPage(countPage);
      onChange?.(countPage);
    }
  };

  const nextPage = () => {
    if (currentPage < countPage) {
      setCurrentPage((prev) => prev + 1);
      onChange?.(currentPage + 1);
    } else {
      setCurrentPage(1);
      onChange?.(1);
    }
  };

  const isDisabled = (condition: boolean): boolean => condition && !loop;

  useEffect(() => {
    setCurrentPage(1);
    onChange?.(1);
  }, [countPerPage, onChange]);

  if (countTotal <= countPerPage) return null;

  return (
    <Box display="flex" alignItems="center" className={styles.pagination}>
      <div>
        {currentPage} / {countPage}
      </div>
      <div className={styles.arrowWrapper}>
        <IconButton
          disabled={isDisabled(currentPage <= 1)}
          onClick={prevPage}
          className={styles.arrow}>
          <Arrow />
        </IconButton>
        <IconButton
          disabled={isDisabled(currentPage >= countPage)}
          onClick={nextPage}
          className={classNames(styles.arrow, styles.next)}>
          <Arrow />
        </IconButton>
      </div>
    </Box>
  );
};
