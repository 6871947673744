import { FC, useState } from 'react';
import { CheckedIcon, Forward } from 'src/assets/icons';
import { BusinessRequestMode, TVariantBusinessRequest } from 'src/models';
import {
  Alert,
  Button,
  ConfirmDialog,
  SvgIcon,
  Typography,
} from 'src/components/ui';
import { useRejectRefusalRequestMutation } from 'src/api/business-request/business-request';
import { getErrorMessage } from 'src/store/utils';
import { formatServerDate } from 'src/utils';
import { ManagerRejectApplication } from '../reject-application/components';
import styles from './refusal-request.module.scss';

export const RefusalRequest: FC<{
  businessRequest: TVariantBusinessRequest;
  mode: BusinessRequestMode;
}> = ({ businessRequest, mode }) => {
  const [showRefusalConfirmModal, setShowRefusalConfirmModal] = useState(false);
  const [showRefusalRejectModal, setShowRefusalRejectModal] = useState(false);

  const refusalRequestData =
    'customerBusinessApplicationRefusalRequest' in businessRequest
      ? businessRequest.customerBusinessApplicationRefusalRequest
      : null;

  const [rejectRefusalRequest, { isLoading, error }] =
    useRejectRefusalRequestMutation();

  if (
    !refusalRequestData ||
    !businessRequest.status ||
    businessRequest.status.code === 'REQUEST_REFUSAL'
  ) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Alert
        content={
          <>
            {mode === BusinessRequestMode.CUSTOMER
              ? 'Отмена заявки находится на согласовании у менеджера '
              : 'Заказчик хочет отменить заявку '}
            {formatServerDate(refusalRequestData.createdAt, 'dd.MM.yyyy HH:mm')}
          </>
        }
        tooltip={`Причина: ${refusalRequestData.cause}\nКомментарий: ${refusalRequestData.comment}`}
      />

      {mode === BusinessRequestMode.MANAGER && (
        <>
          <Button
            onClick={() => setShowRefusalConfirmModal(true)}
            startIcon={
              <SvgIcon
                icon={CheckedIcon}
                width="16px"
                height="16px"
                strokeColor="error.main"
                strokeWidth="2"
              />
            }
            className={styles.actionButton}
            variant="text"
            color="error">
            Подтвердить
          </Button>

          <Button
            onClick={() => setShowRefusalRejectModal(true)}
            startIcon={<Forward />}
            variant="text"
            className={styles.actionButton}
            loading={isLoading}>
            Вернуть в работу
          </Button>

          {showRefusalConfirmModal && (
            <ManagerRejectApplication
              isShowModalWindow={showRefusalConfirmModal}
              handleShowModalWindow={() => setShowRefusalConfirmModal(false)}
              businessApplicationId={businessRequest.businessApplicationId!}
              businessRequestId={businessRequest.businessRequestId!}
              isCustomerBusinessApplicationRefusal
            />
          )}

          <ConfirmDialog
            open={showRefusalRejectModal}
            close={() => setShowRefusalRejectModal(false)}
            confirmText="Да, уверен"
            onConfirm={async () => {
              await rejectRefusalRequest({
                applicationId: businessRequest.businessApplicationId!,
                businessRequestId: businessRequest.businessRequestId!,
              })
                .unwrap()
                .catch(() => {});
              setShowRefusalRejectModal(false);
            }}
            settingsBtn={{ loading: isLoading }}
            title="Вы уверены, что хотите отклонить запрос на отмену заявки?">
            {error && (
              <Typography color="error">{getErrorMessage(error)}</Typography>
            )}
          </ConfirmDialog>
        </>
      )}
    </div>
  );
};
