import { Box } from 'src/components/ui';
import { CheckedIcon, Forward } from 'src/assets/icons';
import { Button, Typography } from '@mui/material';
import styles from './header-actions.module.scss';

type THeaderActionsProps = {
  onClose: () => void;
  onDelete: () => void;
  disabled: boolean;
};

export const HeaderActions = ({
  onClose,
  onDelete,
  disabled,
}: THeaderActionsProps) => (
  <Box className={styles.wrapper}>
    <Button
      disabled={disabled}
      onClick={() => onDelete()}
      className={styles.buttonWrapper}
      variant="text">
      <CheckedIcon className={styles.iconWrapper} />
      <Typography>Удалить</Typography>
    </Button>
    <Button
      onClick={() => onClose()}
      className={styles.buttonWrapper}
      variant="text">
      <Forward className={styles.iconWrapper} />
      <Typography>Отменить</Typography>
    </Button>
  </Box>
);
