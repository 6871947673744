import { TagSelect, Form } from 'src/components/ui';

import { IRequestPerformersForm } from 'src/models';
import styles from './list-tags.module.scss';

export const ListTags = () => (
  <div className={styles.container}>
    <Form.List<
      IRequestPerformersForm,
      'technicalOpportunitiesCategories'
    > name="technicalOpportunitiesCategories">
      {({ fields }) =>
        fields.map((field, index) => (
          <Form.Item
            noMargin
            key={field.id}
            name={`technicalOpportunitiesCategories.${index}`}>
            <TagSelect />
          </Form.Item>
        ))
      }
    </Form.List>
  </div>
);
