import { FC, Ref } from 'react';
import { HiddenComponent, TagSelect } from 'src/components/ui';
import { TTechnicalOpportunity } from 'src/models';

type TTagsInput = {
  value?: TTechnicalOpportunity[];
  onChange?: (v: TTechnicalOpportunity[]) => void;
  inputRef?: Ref<any>;
};

export const TagsInput: FC<TTagsInput> = ({ value, onChange, inputRef }) => {
  const handleOnChange = (v: TTechnicalOpportunity) => {
    onChange?.(value?.map((elem) => (elem.id === v.id ? v : elem)) ?? []);
  };
  return (
    <>
      {value?.map((val) => (
        <TagSelect key={val.id} value={val} onChange={handleOnChange} />
      ))}
      <HiddenComponent inputRef={inputRef} />
    </>
  );
};
