import { Components } from '@mui/material/styles/components';
import { Theme, StepIcon as MuiStepIcon, StepIconProps } from '@mui/material';
import { FC } from 'react';

const StepIcon: FC<StepIconProps> = ({ icon, active, completed, ...props }) => (
  <MuiStepIcon
    {...props}
    icon={icon}
    active={active || completed}
    completed={false}
  />
);

export const MuiStepLabel: Components<Theme>['MuiStepLabel'] = {
  defaultProps: {
    StepIconComponent: StepIcon,

    StepIconProps: {
      completed: true,
      active: true,
    },
  },
  styleOverrides: {
    root: {
      '& .Mui-completed': {
        svg: {
          color: '#002F6C',
        },
      },
    },

    iconContainer: {
      svg: {
        width: '36px',
        height: '36px',
      },

      '&.Mui-active': {
        borderRadius: '50%',
        border: '1px solid #002F6C',

        svg: {
          color: '#FFFFFF',
        },

        text: {
          fill: '#002F6C',
        },
      },

      '&.Mui-disabled': {
        svg: {
          color: 'rgba(0, 47, 108, 0.06);',
        },

        text: {
          fill: '#9FA8B3',
        },
      },
    },

    label: ({ theme }) => ({
      '&.Mui-completed, &.Mui-active, &.Mui-disabled': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',

        color: theme.palette.text.primary,
      },
    }),
  },
};
