import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const SystemLayout = lazy(
  () =>
    import(
      /* webpackChunkName: 'SystemLayout' */
      /* webpackPrefetch: true */
      /* webpackMode: "lazy" */
      `./system-layout`
    )
);

export const LazySystemLayout: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <SystemLayout />
  </Suspense>
);
