import { ReactNode } from 'react';
import { TFile } from 'src/components/ui/file-upload';
import { ROLES } from 'src/constants';

export enum EPublishedStatus {
  PUBLISHED = 'PUBLISHED',
  NOT_PUBLISHED = 'NOT_PUBLISHED',
}

export const EPublishedStatusName = {
  [EPublishedStatus.PUBLISHED]: 'Опубликовано',
  [EPublishedStatus.NOT_PUBLISHED]: 'Не опубликовано (скрыто)',
};

export type TNewsModule = {
  name: string;
  categoryId?: string | number;
  logo: ReactNode;
  publishedStatus: EPublishedStatus;
  isAllNewsSection?: boolean;
};

export type TNewsSection = {
  title: string;
  text: string;
  isPublished: boolean;
  actualDate: string;
};

export type TNewsFormItem = TNewsFormInput & {
  id: number;
};

export type TNewsUpdateInput = Omit<TNewsFormInput, 'roles' | 'category'> & {
  roles: ROLES[];
  categoryId: number;
  id: number;
};

export enum NewsFromActions {
  SAVE = 'SAVE',
  PUBLISH = 'PUBLISH',
  UNPUBLISH = 'UNPUBLISH',
}

export type TNewsFormInput = {
  title: string;
  category: {
    name: string;
    value: number | string;
  };
  summary: string;
  actualDate: string;
  documents: TFile[];
  content: string;
  roles: {
    CONTRACTOR: boolean;
    CUSTOMER: boolean;
    KAM: boolean;
  };
  publishStatus: EPublishedStatus;
};
