import { parseISO } from 'date-fns';

export const setTimeZone = (date: Date | undefined) => {
  if (!date) return null;

  const currentTimeZone = Math.abs(new Date().getTimezoneOffset() / 60);

  return parseISO(
    new Date(date.setHours(date.getHours() + currentTimeZone)).toISOString()
  ).toISOString();
};
