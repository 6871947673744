import { FC } from 'react';
import { Typography } from 'src/components/ui';
import styles from './watched-status.module.scss';

export const WatchedStatus: FC<{
  isWatched?: boolean | null;
}> = ({ isWatched }) => {
  if (isWatched === null || isWatched === undefined) return null;
  return (
    <div className={styles.watchedStatusWrapper}>
      <div className={isWatched ? styles.greenPoint : styles.redPoint} />
      <Typography color="text.secondary" variant="body2" marginLeft={1}>
        {isWatched ? 'Просмотрено' : 'Не просмотрено'}
      </Typography>
    </div>
  );
};
