import { Tooltip } from '@mui/material';
import React from 'react';
import {
  Box,
  OverflowTooltip,
  PhoneInput,
  Stack,
  Typography,
} from 'src/components/ui';
import { ColumnsType } from 'src/components/ui/new-table/types';
import { RequestInfoColumn } from 'src/components/widgets/all-company-requests-list/components/request-info-column';
import { Avatar } from 'src/components/widgets/avatar';
import { TCompanyBusinessRequest } from 'src/models/bid';
import { getUserLastNameWithInitials } from 'src/utils';
import { getFormattedBudget } from 'src/utils/get-formatted-budget';
import styles from './all-company-requests-list.module.scss';

export const columnsConfig: ColumnsType<
  TCompanyBusinessRequest & { key: number }
> = [
  {
    title: 'Заявка',
    key: 'name',
    render: (item) => <RequestInfoColumn item={item} />,
    setting: {
      sx: {
        width: '64%',
      },
    },
  },
  {
    title: 'Стоимость',
    key: 'number',
    render: (item) => (
      <Typography fontSize="12px" fontWeight={500}>
        {getFormattedBudget(item.budget)}
      </Typography>
    ),
    setting: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    title: 'Кам',
    key: 'createdAt',
    render: (item) => (
      <Stack direction="row" gap="8px" alignItems="center">
        <Box flexShrink={0}>
          <Avatar userInfo={item.fullName} borderColor="#0041A0" />
        </Box>
        <Stack minWidth={0} direction="column" gap="4px">
          <Typography fontWeight="500" fontSize="12px" lineHeight="16px">
            {getUserLastNameWithInitials(item.fullName)}
          </Typography>
          {item.fullName.phones &&
            item.fullName.phones.length !== 0 &&
            item.fullName.phones[0] && (
              <Tooltip
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                })}
                title={
                  item.fullName.phones[0].extension && (
                    <Box className={styles.phoneNumber} minWidth={0}>
                      <PhoneInput
                        displayType="text"
                        value={item.fullName.phones[0]}
                      />
                    </Box>
                  )
                }>
                <Box className={styles.phoneNumber} minWidth={0}>
                  <PhoneInput
                    displayType="text"
                    value={item.fullName.phones[0]}
                  />
                </Box>
              </Tooltip>
            )}
        </Stack>
      </Stack>
    ),
    setting: {
      sx: {
        width: '220px',
      },
    },
  },
];
