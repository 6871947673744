import { FC, lazy, Suspense } from 'react';
import { PageLoader } from 'src/components/ui';

const SystemSettingsLayout = lazy(
  () =>
    import(
      /* webpackChunkName: 'SystemSettingsLayout' */
      /* webpackPrefetch: true */
      /* webpackMode: "lazy" */
      `./system-settings-layout`
    )
);

export const LazySystemSettingsLayout: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <SystemSettingsLayout />
  </Suspense>
);
