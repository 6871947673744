import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    root: {
      padding: 7,
      '&.Mui-checked': {
        '& $thumb': {
          backgroundColor: '#fff',
        },
        '& + $track': {
          opacity: '1',
        },
      },
    },

    thumb: {
      backgroundColor: '#fff',
      marginTop: 2,
      marginLeft: 2,
    },
    switchBase: {
      color: 'rgb(255,255,255)',
      padding: 7,

      '&.Mui-disabled+.MuiSwitch-track': {
        backgroundColor: '#DEDEDE',
      },

      '&.Mui-disabled.Mui-checked+.MuiSwitch-track': {
        backgroundColor: '#9BC6FF',
      },
    },

    track: {
      width: 44,
      height: 24,
      background: '#9FA8B3',
      borderRadius: 20,
      opacity: '1!important',
    },
  },
};
