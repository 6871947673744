import { Empty, Typography } from 'src/components/ui';

export const NotFound = () => (
  <Empty>
    <Typography variant="h2">Ничего не найдено</Typography>
    <Typography fontSize="16" align="center">
      Попробуйте задать другие фильтры <br /> и повторите запрос.
    </Typography>
  </Empty>
);
