import { NotificationType } from 'src/models/notifications';
import { CheckedIcon, InfoHelpIcon, WarningSimple } from 'src/assets/icons';
import vars from 'src/theme/scss/_vars.module.scss';
import { SvgIcon } from 'src/components/ui';

export const getIconByTypeNotification = (
  typeNotification: NotificationType
) => {
  let icon = InfoHelpIcon;
  let color = vars.info;

  const successfulType = [
    'CONTRAGENT_REGISTERED',
    'KAM_REGISTERED',
    'USER_FORGOT_PASSWORD',
    'REQUEST_BACK_TO_WORK_BY_MANAGER',
    'USER_IS_UNBLOCKED',
    'CONTACT_IS_UNBLOCKED',
    'ORGANIZATION_IS_UNBLOCKED',
    'DOCUMENT_ADDED',
    'NEW_APPLICATION_FOR_CUSTOMER',
    'CONTRACTOR_COMMERCIAL_OFFER_SENT_TO_MANAGER',
    'COMMERCIAL_OFFER_SENT_TO_CUSTOMER',
    'SIGNED_SPECIFICATION_DOWNLOAD_BY_CUSTOMER',
    'SIGNED_SPECIFICATION_ATTACHED_FOR_CONTRACTOR',
    'SIGNED_SPECIFICATION_ATTACHED_FOR_CUSTOMER',
    'COMMERCIAL_OFFER_IS_APPROVED_BY_CUSTOMER',
  ];

  const warningType = [
    'CONTRACTOR_COMMERCIAL_OFFER_REFUSAL_BY_MANAGER',
    'COMMERCIAL_OFFER_REFUSAL_BY_CUSTOMER',
  ];

  const destructiveType = [
    'USER_IS_BLOCKED',
    'CONTACT_IS_BLOCKED',
    'ORGANIZATION_IS_BLOCKED',
    'DOCUMENT_DELETED',
    'REQUEST_REFUSAL_BY_CUSTOMER',
    'REQUEST_REFUSAL_BY_CONTRACTOR',
    'REQUEST_REFUSAL_BY_MANAGER',
    'REQUEST_REFUSAL_BY_MANAGER_FOR_CUSTOMER',
  ];

  if (successfulType.includes(typeNotification)) {
    icon = CheckedIcon;
    color = vars.success;
  }

  if (warningType.includes(typeNotification)) {
    icon = WarningSimple;
    color = vars.warningDark;
  }

  if (destructiveType.includes(typeNotification)) {
    icon = WarningSimple;
    color = vars.dangerDark;
  }

  return (
    <SvgIcon
      icon={icon}
      strokeHexColor={color}
      width="25"
      height="25"
      iconPadding="8px"
      backgroundFill
    />
  );
};
