import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notificationsApi } from 'src/api';
import { TBusinessRequestsChanged, TRequestNotifications } from 'src/models';
import {
  RequestNotificationsCategory,
  TRequestNotification,
} from 'src/models/notifications';
import { fillRequestNotificationsStore } from './utils';
import { EMPTY_NOTIFICATIONS } from './config';

interface IInitialState {
  changedRequestIds: TBusinessRequestsChanged;
  notifications: TRequestNotifications;
}

const initialState: IInitialState = {
  changedRequestIds: {},
  notifications: EMPTY_NOTIFICATIONS,
};

const requestNotificationsSlice = createSlice({
  name: 'requestNotifications',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationsApi.endpoints.getRequestNotifications.matchFulfilled,
      (state, { payload }) => {
        state.notifications = fillRequestNotificationsStore(
          payload.notifications
        );
      }
    );
    builder.addMatcher(
      notificationsApi.endpoints.getRequestNotificationsContractor
        .matchFulfilled,
      (state, { payload }) => {
        state.notifications = fillRequestNotificationsStore(
          payload.notifications
        );
      }
    );
    builder.addMatcher(
      notificationsApi.endpoints.getUnreadNotificationsOnKanban.matchFulfilled,
      (state, { payload }) => {
        state.changedRequestIds = { ...state.changedRequestIds, ...payload };
      }
    );
    builder.addMatcher(
      notificationsApi.endpoints.getUnreadNotificationsOnKanbanContractor
        .matchFulfilled,
      (state, { payload }) => {
        state.changedRequestIds = { ...state.changedRequestIds, ...payload };
      }
    );
  },
  reducers: {
    removeNotifications(
      state,
      action: PayloadAction<{
        category: RequestNotificationsCategory;
        ids: string[];
      }>
    ) {
      const {
        payload: { category, ids },
      } = action;
      const updatedList = state.notifications[category].filter(
        (elem) => !ids.includes(elem.id)
      );
      state.notifications[category] = updatedList;
    },
    setNotificationWatched(
      state,
      action: PayloadAction<{
        notification: TRequestNotification;
      }>
    ) {
      const {
        payload: {
          notification: { id, category },
        },
      } = action;

      const updatedList = state.notifications[category].map((elem) => {
        if (elem.id === id) {
          return {
            ...elem,
            watched: true,
          };
        }
        return elem;
      });
      state.notifications[category] = updatedList;
    },
  },
});
export const { setNotificationWatched, removeNotifications } =
  requestNotificationsSlice.actions;
export const requestNotificationsReducer = requestNotificationsSlice.reducer;
