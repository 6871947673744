import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';

interface IDashboardTokenQueryParams {
  embedId: string;
  params?: Record<string, string>;
}

interface IDashboardTokenResult {
  token: string;
}

export const dashboardsApi = createApi({
  reducerPath: 'dashboardsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getJwtToken: builder.mutation<
      IDashboardTokenResult,
      IDashboardTokenQueryParams
    >({
      query: ({ ...body }) => ({
        url: 'mph-security-provider/api/v1/jwt',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetJwtTokenMutation } = dashboardsApi;
