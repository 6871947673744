import { Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import { Key } from 'react';
import { Button } from 'src/components/ui/button';
import { Box } from 'src/components/ui/index';
import { TabScrollButton } from 'src/components/ui/nav-bar/components';
import styles from './nav-bar.module.scss';

export type TNavBarTab<T extends Key = string> = {
  value: T;
  label: string;
};

type TNavBarProps<T extends Key = string> = {
  activeTab: T;
  onChangeTab: (selectedTab: T) => void;
  tabs: TNavBarTab<T>[];
};

export const NavBar = <T extends Key = string>({
  activeTab,
  onChangeTab,
  tabs,
}: TNavBarProps<T>) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    className={styles.tabs}
    ScrollButtonComponent={TabScrollButton}>
    {tabs.map(({ value, label }) => (
      <Tab
        sx={{
          padding: '0px',
          height: '36px',
          minHeight: '0px',
        }}
        disableRipple
        label={
          <Button
            key={value}
            onClick={() => onChangeTab(value)}
            className={classNames(
              styles.navItem,
              activeTab === value && styles.navItemActive
            )}>
            <Box mr={1}>{label}</Box>
          </Button>
        }
      />
    ))}
  </Tabs>
);
