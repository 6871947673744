import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import {
  TSortKey,
  TPaymentFilterParams,
  TPaymentList,
} from 'src/models/admin/payments';
import { TListRequestParams, TPaging } from 'src/api/models';

export type TPaymentsRequestListParams = Omit<
  TListRequestParams<TSortKey, TPaymentFilterParams>,
  'pagingDto'
> & { paging: TPaging };

export const adminPaymentsApi = createApi({
  reducerPath: 'adminPaymentsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPaymentList: builder.mutation<TPaymentList, TPaymentsRequestListParams>({
      query: (body) => ({
        url: `business-requests/api/v1/admin/payments`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetPaymentListMutation } = adminPaymentsApi;
