import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    underline: 'none',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    }),
  },
};
