export const ERROR_CODE = {
  RETRY_DELAY_NOT_EXPIRED: 'RETRY_DELAY_NOT_EXPIRED',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  WRONG_LOGIN_OR_PASSWORD: 'WRONG_LOGIN_OR_PASSWORD',
  USER_BLOCKED: 'USER_BLOCKED',
  USER_DELETED: 'USER_DELETED',
  WRONG_OLD_PASSWORD: 'WRONG_OLD_PASSWORD',
  ORGANIZATIONS_MERGE_NEEDED: 'ORGANIZATIONS_MERGE_NEEDED',
  WRONG_NEW_PASSWORD: 'WRONG_NEW_PASSWORD',
};
