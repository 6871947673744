import { MenuItem, Select, Typography } from 'src/components/ui';
import { FC } from 'react';
import PaginationItem from '@mui/material/PaginationItem';
import * as React from 'react';
import { DoubleArrow } from 'src/assets/icons';
import { PAGES_LIMIT_OPTIONS } from 'src/configs/pagination-settings';
import { Stack, Pagination, SelectChangeEvent } from '@mui/material';
import styles from './table.module.scss';

const FirstBtn = () => (
  <span className={styles.firstBtn}>
    <DoubleArrow />
  </span>
);

type TProps = {
  countItems: number;
  countPerPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  page: number;
  onLimitChange?: (limit: number) => void;
  limit?: number;
  selectableLimit?: boolean;
};

export const TablePagination: FC<TProps> = ({
  countItems,
  countPerPage,
  handleChange,
  page,
  onLimitChange,
  limit,
  selectableLimit,
}) => {
  const countPage = Math.ceil(countItems / countPerPage);

  const handleLimitChange = (e: SelectChangeEvent<number>) => {
    onLimitChange?.(Number(e.target.value));
  };

  return (
    <Stack
      direction="row"
      spacing={1.5}
      alignItems="center"
      justifyContent={selectableLimit ? 'space-between' : 'center'}>
      <Pagination
        page={page}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            slots={{ first: FirstBtn, last: DoubleArrow }}
            {...item}
          />
        )}
        count={countPage}
        showFirstButton
        showLastButton
      />
      {selectableLimit && (
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Typography variant="body2" color="secondary.main">
            Показать
          </Typography>
          <Select value={limit} onChange={handleLimitChange}>
            {PAGES_LIMIT_OPTIONS.map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
          <Typography variant="body2" color="secondary.main">
            записей на странице
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
