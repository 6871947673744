import {
  TGetRequestNotificationsResponse,
  TRequestNotifications,
} from 'src/models';
import { RequestNotificationsCategory } from 'src/models/notifications';
import { fillNotificationTemplate } from 'src/utils';
import { EMPTY_NOTIFICATIONS } from './config';

export const fillRequestNotificationsStore = (
  notifications: TGetRequestNotificationsResponse['notifications']
): TRequestNotifications =>
  Object.keys(notifications).reduce(
    (result: TRequestNotifications, category) => {
      const notificationCategory = category as RequestNotificationsCategory;
      const categoryNotifications = notifications[notificationCategory];
      if (categoryNotifications === undefined) {
        return result;
      }
      result[notificationCategory] =
        categoryNotifications.length > 0
          ? categoryNotifications.map((elem) => fillNotificationTemplate(elem))
          : [];
      return result;
    },
    { ...EMPTY_NOTIFICATIONS }
  );
