import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ContractorRequest = lazy(
  () =>
    import(
      /* webpackChunkName: 'ContractorRequest' */
      /* webpackMode: "lazy" */
      `./request`
    )
);

export const LazyContractorRequest: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ContractorRequest />
  </Suspense>
);
