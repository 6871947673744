import { CompanyTypes, ICompany } from 'src/models';
import { AuthService } from 'src/services';
import { getAssignedManagerType } from './get-assigned-manager-type';

export const isAssignedManager = (
  companyType: CompanyTypes,
  managers: ICompany['assignedManagers']
) => {
  const managerType = getAssignedManagerType(companyType);

  return AuthService.userId === managers[managerType]?.id;
};
