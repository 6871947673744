import { Typography } from 'src/components/ui';
import { IEmailMessage } from 'src/models/communications';
import { Email as EmailIcon } from 'src/assets/icons';
import { BubbleItem } from '../bubble-item';

interface IEmailProps {
  data: IEmailMessage;
  onClick?: () => void;
}

export const Email = ({ data, onClick }: IEmailProps) => (
  <BubbleItem
    redirectUrl={`/requests/${data.application.requestId}`}
    name={data.from.name}
    date={data.sortDate}
    userGroupColor={data.from?.userGroup?.color}
    badgeIcon={EmailIcon}
    text={data.text}
    title={
      <>
        <Typography display="inline" color="#292929" fontWeight="500">
          {data.from.name}{' '}
        </Typography>
        <Typography display="inline" color="#7A8694">
          прислал(a) письмо по заявке{' '}
        </Typography>
        <Typography
          display="inline"
          color="#292929"
          fontWeight="500"
          whiteSpace="nowrap">
          № {data.application.id}
        </Typography>
      </>
    }
    onClick={onClick}
  />
);
