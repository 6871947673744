import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthService } from 'src/services';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  credentials: 'same-origin', // Учетные данные будут отправлены только в том случае, если запрос выполняется на тот же домен, что и текущая страница
  prepareHeaders: (headers) => {
    const { accessToken, currentRole } = AuthService;
    if (!headers.get('Authorization') && accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    if (!headers.get('Current-Role') && currentRole) {
      headers.set('Current-Role', `${currentRole}`);
    }

    return headers;
  },
});
