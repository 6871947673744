import { FC, useCallback } from 'react';
import {
  Autocomplete,
  Checkbox,
  Form,
  OutlinedInput,
  Box,
} from 'src/components/ui';
import { FIELD_LENGTH } from 'src/constants';
import { required, maxLength } from 'src/utils/form-rules';

interface IReasonsCustomerProps {
  isWriteReason: boolean;
  items: string[] | undefined;
  onCustomerReasonsChanged: () => void;
}

export const ReasonsCustomer: FC<IReasonsCustomerProps> = ({
  isWriteReason,
  items,
  onCustomerReasonsChanged,
}) => {
  const fetchApiFilter = useCallback(
    (value: string) =>
      Promise.resolve(
        (value
          ? items?.filter((element) =>
              element.toLowerCase().includes(value.toLowerCase())
            )
          : items) || []
      ),
    [items]
  );

  return (
    <>
      <Form.Item
        noMargin
        required
        name="customerSelectedReason"
        label="Выберите причину отказа для заказчика"
        rules={{
          required: isWriteReason ? undefined : required,
        }}>
        <Autocomplete
          disabled={isWriteReason}
          popupIcon
          options={items}
          fetchApi={fetchApiFilter}
          fetchOnEmptyInput
          placeholder="Выбрать"
          onChange={() => {
            onCustomerReasonsChanged();
          }}
        />
      </Form.Item>

      <Box mt="24px">
        <Form.Item
          required
          noMargin
          name="customerIsWriteReason"
          label="Ввести причину вручную"
          isControlLabel>
          <Checkbox />
        </Form.Item>
      </Box>

      <Form.Item
        name="customerWriteReason"
        rules={{
          required: isWriteReason ? required : undefined,
          maxLength: maxLength(FIELD_LENGTH.rejectApplicationComment),
        }}
        required>
        <OutlinedInput
          disabled={!isWriteReason}
          placeholder="Ввести причину"
          multiline
          rows={6}
          maxLength={FIELD_LENGTH.rejectApplicationComment}
        />
      </Form.Item>

      <Form.Item
        noMargin
        name="customerComment"
        label="Напишите комментарий заказчику"
        rules={{
          maxLength: maxLength(FIELD_LENGTH.rejectApplicationComment),
        }}>
        <OutlinedInput
          placeholder="Введите комментарий"
          multiline
          rows={6}
          maxLength={FIELD_LENGTH.rejectApplicationComment}
        />
      </Form.Item>
    </>
  );
};
