import { FC } from 'react';
import { InfoHelpIcon } from 'src/assets/icons';
import { Box, SvgIcon, Tooltip, Typography } from 'src/components/ui';
import {
  ConversationEntityType,
  ICargoDescriptions,
  IManagerContractorSpecification,
  ManagerContractorSpecificationStatus,
} from 'src/models';
import { OpenChat } from 'src/components/widgets';
import { IChangeLogs } from 'src/models/changelogs';
import {
  Comment,
  CriteriaSelectingWinner,
  Deadlines,
  FilesInput,
  Finance,
  Logistics,
  PaymentConditions,
  ShowWinnerCostPerformers,
} from '../components';

interface IContractorSpecificationProps {
  viewMode: boolean;
  specification: IManagerContractorSpecification | undefined;
  isCustomerFinalVersionSent: boolean | undefined;
  checkRequired: boolean;
  shippingIncluded: boolean;
  isQuickDeal: boolean;

  logistics: {
    deliveryAddress: string;
    shippingAddress: string;

    cargoDescription: ICargoDescriptions | null;

    costsLogistics: number | string;
    calculatedShippingCost: number;
  };

  orderProcessingStarted: boolean;

  costHistory?: IChangeLogs;
  supplyDurationHistory?: IChangeLogs;
  paymentPeriodProlongationHistory?: IChangeLogs;
  paymentConditionsHistory?: IChangeLogs;
}

const mode = 'contractor';

export const ContractorSpecification: FC<IContractorSpecificationProps> = ({
  viewMode,
  specification,
  isCustomerFinalVersionSent,
  checkRequired,
  shippingIncluded,
  logistics,
  isQuickDeal,
  orderProcessingStarted,
  costHistory,
  supplyDurationHistory,
  paymentPeriodProlongationHistory,
  paymentConditionsHistory,
}) => {
  const isSignedScanReceived =
    specification?.status.code ===
    ManagerContractorSpecificationStatus.SIGNED_SCAN_RECEIVED;

  const isFinalVersionSent =
    specification?.status.code ===
    ManagerContractorSpecificationStatus.FINAL_VERSION_SENT;

  const hashInfoLogistics = Boolean(
    logistics?.deliveryAddress &&
      logistics?.shippingAddress &&
      logistics?.cargoDescription?.parameters
  );

  const currentLogisticsHasCalculate =
    Number(logistics?.costsLogistics) ===
    Number(logistics?.calculatedShippingCost);

  return (
    <>
      <Finance
        mode={mode}
        viewMode={viewMode}
        checkRequired={checkRequired}
        shippingIncluded={shippingIncluded}
        costsLogisticsIsManually={
          !currentLogisticsHasCalculate ||
          (Number(logistics.costsLogistics) > 0 && !hashInfoLogistics)
        }
        costHistory={costHistory}
      />

      {shippingIncluded && <Logistics />}

      <Deadlines
        viewMode={viewMode}
        checkRequired={checkRequired}
        supplyDurationHistory={supplyDurationHistory}
        paymentPeriodProlongationHistory={paymentPeriodProlongationHistory}
        mode={mode}
      />

      <PaymentConditions
        paymentConditionsHistory={paymentConditionsHistory}
        viewMode={viewMode}
        checkRequired={checkRequired}
        mode={mode}
      />

      {specification?.contractorRequestId && !orderProcessingStarted && (
        <OpenChat
          entityType={ConversationEntityType.CONTRACTOR_REQUEST}
          entityId={specification.contractorRequestId}
          mode="manager"
          sx={{ marginBottom: '24px' }}
        />
      )}

      <FilesInput
        specification={specification}
        isCustomerFinalVersionSent={isCustomerFinalVersionSent}
        viewMode={viewMode}
        checkRequired={checkRequired}
        mode={mode}
        isQuickDeal={isQuickDeal}
      />

      {(isFinalVersionSent || isSignedScanReceived || isQuickDeal) &&
        (isCustomerFinalVersionSent ? (
          <>
            {!isFinalVersionSent && (
              <Box mb="24px">
                <Typography color="secondary">
                  После отправки финальной спецификации победителю, другие
                  исполнители получат оповещение о том, что их КП перенесены в
                  резерв. Проигравшие исполнители увидят критерии выбора и
                  комментарий.
                </Typography>
              </Box>
            )}

            <CriteriaSelectingWinner
              viewMode={isQuickDeal ? viewMode : isFinalVersionSent}
            />

            <Comment viewMode={isQuickDeal ? viewMode : isFinalVersionSent} />

            <ShowWinnerCostPerformers
              viewMode={isQuickDeal ? viewMode : isFinalVersionSent}
            />
          </>
        ) : (
          <Typography
            color="secondary"
            mb="24px"
            sx={{ '& > div': { display: 'inline' } }}>
            Вы не можете отправить финальную спецификацию&nbsp;
            <Tooltip title="спецификация, которая подписана с обеих сторон">
              <SvgIcon icon={InfoHelpIcon} width="16px" height="16px" />
            </Tooltip>
            &nbsp;исполнителю до тех пор, пока не отправлена финальная
            спецификация заказчику
          </Typography>
        ))}
    </>
  );
};
