import { ThemeOptions } from '@mui/material/styles/createTheme';
import 'src/assets/fonts/fonts.scss';

export const typography: ThemeOptions['typography'] = {
  fontFamily: 'Grtsk Peta, Arial, sans-serif',
  fontSize: 14,
  fontWeightRegular: 400,
  fontWeightBold: 600,
  body1: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 'normal',
  },
  body2: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  h1: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: 'normal',
    marginBottom: '24px',
  },
  h2: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: 'normal',
  },
  h3: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: 'normal',
  },
  h4: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: 'normal',
    fontStyle: 'normal',
  },
  h5: {
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '44px',
    letterSpacing: 'normal',
    fontStyle: 'normal',
  },
  h6: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: 'normal',
    fontStyle: 'normal',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: 'normal',
    marginBottom: '24px',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 'normal',
    marginBottom: '24px',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  button: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
};
