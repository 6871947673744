import { FC } from 'react';
import { Box, Form, InfoRow, OutlinedInput } from 'src/components/ui';
import {
  CargoParametersInput,
  ViewCargoParameters,
} from 'src/components/widgets';
import { ICargoDescriptions } from 'src/models';
import { IComponentsProp } from '../../types';

interface ILogisticsProps extends IComponentsProp {
  handleSubmitTransportCalculation: (value: ICargoDescriptions) => void;
}

export const Logistics: FC<ILogisticsProps> = ({
  isEditing,
  handleSubmitTransportCalculation,
}) => (
  <Box display="flex" gap="24px" mb="16px">
    <Box flexBasis="100%">
      <Form.Item
        viewMode
        name="logistics.deliveryAddress"
        renderView={(value) => (
          <InfoRow variant="secondary" label="Адрес доставки:" value={value} />
        )}>
        <OutlinedInput />
      </Form.Item>

      <Form.Item
        viewMode
        name="logistics.shippingAddress"
        renderView={(value) => (
          <InfoRow variant="secondary" label="Адрес отгрузки:" value={value} />
        )}>
        <OutlinedInput />
      </Form.Item>
    </Box>

    <Box flexBasis="100%">
      <Box>
        <Form.Item
          viewMode={!isEditing}
          name="logistics.cargoDescription"
          noMargin
          renderView={(value) => (
            <InfoRow
              variant="secondary"
              label="Параметры груза:"
              value={
                <ViewCargoParameters
                  emptyText="Нет параметров"
                  cargoParameters={value}
                />
              }
            />
          )}>
          <CargoParametersInput
            variant="commercialOffer"
            onChange={handleSubmitTransportCalculation}
          />
        </Form.Item>
      </Box>
    </Box>
  </Box>
);
