import { MenuHome, MenuBids, MenuCommunications } from 'src/assets/icons';
import { ROLES } from 'src/constants';

type TMenuItemKeys = 'HOME' | 'BIDS' | 'COMMUNICATIONS' | 'CHAT';

type TItemKeyToPropsMap = Record<
  TMenuItemKeys,
  { label: string; icon: any; path: string }
>;

type TMenuItemKeyToPropsMap = Record<
  TMenuItemKeys,
  { label: string; icon: any; path: string }
>;

const MENU_ITEM_KEY_TO_PROPS_MAP: TItemKeyToPropsMap = {
  HOME: {
    label: 'Главная',
    icon: MenuHome,
    path: '/',
  },
  BIDS: {
    label: 'Заявки',
    icon: MenuBids,
    path: '/requests?statusGroup=ACTIVE',
  },
  COMMUNICATIONS: {
    label: 'Коммуникации',
    icon: MenuCommunications,
    path: '/communications/all',
  },
  CHAT: {
    label: 'Чат',
    icon: MenuCommunications,
    path: '/chat',
  },
};

export const ROLE_TO_ITEMS_MAP: Record<
  ROLES,
  Array<TMenuItemKeyToPropsMap[TMenuItemKeys]>
> = {
  [ROLES.ADMIN]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
  ],
  [ROLES.SYSTEM]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
  ],
  [ROLES.KAM]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
  ],
  [ROLES.KAM_PLUS]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
  ],
  [ROLES.CUSTOMER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.CHAT,
  ],
  [ROLES.CONTRACTOR]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.CHAT,
  ],

  [ROLES.TECH_EXPERT]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.BACK_OFFICE]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.COMMERCIAL_DIRECTOR]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.LOGISTIC]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.PARTNER_RELATIONS_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.GENERAL_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.FINANCIAL_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.OFFICE_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.LAWYER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
};
