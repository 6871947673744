import {
  FilterTypes,
  TConfigFilter,
  TVariant,
  TSortItem,
} from 'src/components/ui/filters/types';
import { differenceInMilliseconds } from 'date-fns';
import { isBeforeToday } from 'src/utils';
import { TChip } from '../types';
import { INVALID_DATE } from '../components/filters-popover/components/date-picker-form/date-picker-form';

export const getSearchParameters = (fullList: Array<TChip>) => {
  const searchList: string[] = [];
  fullList.forEach((item) => {
    if (item.type === FilterTypes.SEARCH) {
      searchList.unshift(item.label);
    }
  });
  return searchList;
};

export const getFilterParameters = (
  chipsList: Array<TChip>
): Record<string, Array<TVariant>> => {
  const newFiltersList: Record<string, Array<TVariant>> = {};

  chipsList.forEach((chip) => {
    if (chip.type === FilterTypes.FILTERS && chip.nameFilter) {
      const item = {
        date: chip.date,
        value: String(chip.value),
        label: chip.label,
        checked: true,
      };

      if (newFiltersList[chip.nameFilter]) {
        newFiltersList[chip.nameFilter].unshift(item);
      } else {
        newFiltersList[chip.nameFilter] = [item];
      }
    }
  });

  return newFiltersList;
};

export const validateSearchQuery = (
  searchQuery: TChip,
  listSearchQuery: Array<TChip>
) =>
  searchQuery.label.length > 0 &&
  !listSearchQuery.find(
    (item) => item.type === searchQuery.type && item.label === searchQuery.label
  );

export const createSortChip = <KeysSorting>(
  selectedSort: TSortItem<KeysSorting>
): TChip => ({
  type: FilterTypes.SORT,
  label: selectedSort.label,
  direction: selectedSort.direction,
  hidden: false,
});

export const updateChipsByFiltersConfig = (
  config: Array<TConfigFilter>,
  chips: Array<TChip>,
  isHidden?: boolean
): Array<TChip> => {
  const clearChips: Array<TChip> = [];
  chips.forEach((chip) => {
    if (chip.type !== FilterTypes.FILTERS) {
      clearChips.unshift({
        ...chip,
        hidden: false,
      });
    }
  });
  const newFiltersChips: Array<TChip> = [];
  config.forEach((filter) => {
    if (filter.typeInput === 'date') {
      const label = filter.variantList[0].label || '';
      const checked = filter.variantList[0].checked || false;
      if (checked) {
        newFiltersChips.unshift({
          type: FilterTypes.FILTERS,
          value: label,
          label,
          hidden: !!isHidden,
          nameFilter: filter.name,
        });
      }
    } else {
      filter.variantList.forEach((variant) => {
        if (variant.checked) {
          newFiltersChips.unshift({
            type: FilterTypes.FILTERS,
            value: variant.value,
            label: variant.label,
            hidden: !!isHidden,
            nameFilter: filter.name,
          });
        }
      });
    }
  });
  return [...clearChips, ...newFiltersChips];
};

export const isDateFilter = (variantList: Array<TVariant>) =>
  !!variantList[0].date;

export const validateDateRange = (
  variantList: Array<TVariant>,
  nameFilter: string
): Record<string & 'empty', string | boolean> => {
  const startDate = variantList[0].date;
  const endDate = variantList[1].date;

  if ((!startDate && !endDate) || (startDate === '' && endDate === '')) {
    return {
      empty: true,
    };
  }

  if (!startDate || startDate === '') {
    return {
      [nameFilter]: 'Введите дату начала периода',
      empty: false,
    };
  }

  if (!endDate || endDate === '') {
    return {
      [nameFilter]: 'Введите дату конца периода',
      empty: false,
    };
  }

  if (startDate === INVALID_DATE || endDate === INVALID_DATE) {
    return {
      [nameFilter]: 'Введена некорректная дата',
      empty: false,
    };
  }

  if (!isBeforeToday(startDate)) {
    return {
      [nameFilter]: 'Дата начала не должна быть позднее сегодняшней даты',
      empty: false,
    };
  }

  if (!isBeforeToday(endDate)) {
    return {
      [nameFilter]: 'Дата конца не должна быть позднее сегодняшней даты',
      empty: false,
    };
  }

  const diff = differenceInMilliseconds(new Date(endDate), new Date(startDate));

  if (diff < 0) {
    return {
      [nameFilter]: 'Дата начала должна быть раньше даты конца',
      empty: false,
    };
  }

  return {
    empty: false,
  };
};

export const setFiltersFromUrl = (
  filters: TConfigFilter[],
  query: string,
  filterName: string
): TConfigFilter[] =>
  filters.map((filter) => {
    if (filter.name === filterName) {
      return {
        ...filter,
        variantList: filter.variantList.map((variant) =>
          variant.value === query ? { ...variant, checked: true } : variant
        ),
      };
    }

    return filter;
  });

export const clearVariantsFilter = (variantsFilter: TConfigFilter[]) =>
  variantsFilter.map((itemFilter) => {
    if (itemFilter.typeInput === 'date') {
      const variantList = itemFilter.variantList.map((variant) => ({
        ...variant,
        date: null,
        label: '',
        checked: false,
      }));
      return { ...itemFilter, variantList };
    }

    const variantList = itemFilter.variantList.map((variant) => ({
      ...variant,
      checked: false,
    }));

    return { ...itemFilter, variantList };
  });
