import { TFile } from 'src/components/ui/file-upload';
import { TNews } from 'src/models';
import { ROLES } from 'src/constants';
import { ROLE_NAMES } from 'src/configs';
import { TNewsFormItem } from '../config/news-types';

export const getRoleFromArray = (rolesArray: ROLES[], role: ROLES): boolean =>
  Boolean(rolesArray.find((elem) => elem === role));

export const convertToNewsForm = (inputData: TNews): TNewsFormItem => ({
  id: inputData.id,
  title: inputData.title,
  category: {
    name: '',
    value: inputData.categoryId,
  },
  content: inputData.content,
  documents: inputData.image
    ? [
        {
          sizeBytes: inputData.image.size,
          name: inputData.image.documentName,
          id: inputData.imageId,
          createdAt: '',
          createdByUser: {
            firstName: '',
            lastName: '',
            middleName: '',
          },
        },
      ]
    : [],
  roles: {
    CONTRACTOR: getRoleFromArray(inputData.roles, ROLES.CONTRACTOR),
    CUSTOMER: getRoleFromArray(inputData.roles, ROLES.CUSTOMER),
    KAM: getRoleFromArray(inputData.roles, ROLES.KAM),
  },
  actualDate: inputData.actualDate,
  summary: inputData.summary,
  publishStatus: inputData.publishStatus,
});

export const createRequestBodyWithFile = (
  jsonFieldName: string,
  requestBody: object,
  documents?: TFile[]
): FormData => {
  const formData = new FormData();
  if (documents && documents.length) {
    const [document] = documents;
    formData.append('file', document.file!, document.file!.name);
  }
  formData.set(
    jsonFieldName,
    new Blob([JSON.stringify(requestBody)], { type: 'application/json' }),
    'data.json'
  );
  return formData;
};

export const createUpdateRequestFileBody = (
  documents: TFile[],
  fileFieldName?: string
): FormData => {
  const [document] = documents;
  const formData = new FormData();
  formData.set(fileFieldName || 'file', document.file!, document.file!.name);
  return formData;
};

export function isImageResolutionValid(image: File) {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const img = new Image();

        img.onload = () => {
          resolve(img.width <= 1920 && img.height <= 1080);
        };

        img.src = fileReader.result as string;
      };

      fileReader.readAsDataURL(image);
    } catch (e) {
      reject(e);
    }
  });
}

export const getRolesList = (roles: ROLES[]) =>
  roles
    .reduce((res, role) => `${res}${ROLE_NAMES[role]}, `, 'Доступность: ')
    .slice(0, -2);
