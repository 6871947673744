import { ComponentProps, FC, useState } from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { OutlinedInput } from 'src/components/ui';
import { DateIcon } from 'src/assets/icons';

type TMuiDatePicker = ComponentProps<typeof MuiDatePicker>;

type TDatePicker = Omit<
  TMuiDatePicker,
  'renderInput' | 'value' | 'onChange'
> & {
  value?: string;
  onChange?: TMuiDatePicker['onChange'];
  placeholder?: string;
  isMinDate?: boolean;
  onBlur?: () => void;
  disableManualInput?: boolean;
  size?: 'medium' | 'small';
};

const onBlur = (
  value: string | undefined,
  onChange: (value: unknown) => void
) => {
  if (!value) {
    return;
  }
  const inputDate = new Date(value);
  if (inputDate.getFullYear() < 100) {
    inputDate.setFullYear(2000 + inputDate.getFullYear());
    onChange(inputDate);
  }
};

export const DatePicker: FC<TDatePicker> = ({
  onChange = () => {},
  value,
  placeholder = '_ _._ _._ _ _ _',
  isMinDate = true,
  onBlur: onBlurProps,
  disableManualInput = false,
  size = 'medium',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const changeIsOpen = disableManualInput
    ? (openValue: boolean) => {
        setIsOpen(openValue);
      }
    : undefined;
  return (
    <MuiDatePicker
      {...props}
      onChange={onChange}
      onClose={() => changeIsOpen?.(false)}
      value={value}
      open={disableManualInput ? isOpen : undefined}
      components={{
        OpenPickerIcon: DateIcon,
      }}
      renderInput={({ inputRef, inputProps, InputProps }) => (
        <OutlinedInput
          size={size}
          trimValue={false}
          inputRef={inputRef}
          {...InputProps}
          onClick={() => {
            changeIsOpen?.(!isOpen);
          }}
          inputProps={{
            ...inputProps,
            placeholder,
            readOnly: disableManualInput,
          }}
          onBlur={() => {
            if (isMinDate) {
              onBlur(value, onChange);
            }
            onBlurProps?.();
          }}
        />
      )}
    />
  );
};
