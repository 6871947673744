import { Skeleton } from '@mui/material';
import { Stack } from 'src/components/ui';

export const BubbleItemWrapperSkeleton = () => (
  <Stack gap="8px">
    <Skeleton height="60px" width="100%" />
    <Skeleton height="60px" width="100%" />
    <Skeleton height="60px" width="100%" />
    <Skeleton height="60px" width="100%" />
  </Stack>
);
