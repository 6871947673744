import { TContact } from 'src/components/pages/company/contacts/types';
import { IBidsCustomerContact } from 'src/models';
import { TVariantContactItem } from '../types';

export const isContractor = (item: TVariantContactItem): item is TContact =>
  'isSendEmail' in item;

export const isBids = (
  item: TVariantContactItem
): item is IBidsCustomerContact => !('isSendEmail' in item);
