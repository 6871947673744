import { format, isBefore } from 'date-fns';
import { TDate } from 'src/models';

export const getDay = (date: TDate) => Number(format(new Date(date), 'dd'));

export const isDifferentDate = (leftDate: TDate, rightDate: TDate) =>
  getDay(leftDate) !== getDay(rightDate);

export const isBeforeToday = (date: TDate) =>
  isBefore(new Date(date), new Date());
