import { Typography } from '@mui/material';
import { FC, Fragment, useEffect } from 'react';
import { useLazyGetUserQuery } from 'src/api/user';
import {
  Box,
  Button,
  IconButton,
  InfoRow,
  OutlinedInput,
  PhoneInput,
  Form,
} from 'src/components/ui';
import { PHONES, TPhoneInputValue } from 'src/components/ui/phone-input';
import { email, maxLength, phone, required, word } from 'src/utils/form-rules';
import { AddIcon, CloseIcon } from 'src/assets/icons';
import { FIELD_LENGTH } from 'src/constants';
import { containsLetters } from 'src/utils/form-rules/contains-letters';
import { TFormInput } from './types';

import styles from './user-profile.module.scss';

type TProps = {
  isEditMode?: boolean;
  noCounterparty?: boolean;
  isLoginDisabled?: boolean;
  phoneCount?: number;
  showTitle?: boolean;
  // currentEmailValue и triggerEmailValidation нужны для проверки уникальности почты по мере ввода
  currentEmailValue?: string;
  triggerEmailValidation?: () => void;
};

export const UserProfileFields: FC<TProps> = ({
  isEditMode,
  noCounterparty,
  isLoginDisabled,
  phoneCount = 3,
  showTitle,
  currentEmailValue,
  triggerEmailValidation,
}) => {
  const [getUser, { data }] = useLazyGetUserQuery();

  const handleSearchByEmail = async () => {
    if (currentEmailValue) {
      try {
        await getUser({ login: currentEmailValue }).unwrap();
        triggerEmailValidation!();
      } catch (e) {
        console.log('user not found');
      }
    }
  };

  useEffect(() => {
    handleSearchByEmail();
  }, [currentEmailValue]);

  return (
    <>
      {showTitle && (
        <Typography
          fontWeight={500}
          fontSize="16px"
          sx={{ marginBottom: '10px' }}>
          Основные данные
        </Typography>
      )}

      <Form.Item
        name="lastName"
        label="Фамилия"
        viewMode={!isEditMode}
        rules={{
          required,
          validate: word,
          maxLength: maxLength(FIELD_LENGTH.secondName),
        }}>
        <OutlinedInput multiline maxRows={4} />
      </Form.Item>

      <Form.Item
        name="firstName"
        label="Имя"
        viewMode={!isEditMode}
        rules={{
          required,
          validate: word,
          maxLength: maxLength(FIELD_LENGTH.firstName),
        }}>
        <OutlinedInput multiline maxRows={4} />
      </Form.Item>

      <Form.Item
        name="middleName"
        label="Отчество"
        viewMode={!isEditMode}
        rules={{
          validate: word,
          maxLength: maxLength(FIELD_LENGTH.patronymic),
        }}>
        <OutlinedInput multiline maxRows={4} />
      </Form.Item>

      {!noCounterparty && (
        <Form.Item
          name="position"
          label="Должность"
          viewMode={!isEditMode}
          rules={{
            validate: containsLetters,
            maxLength: maxLength(FIELD_LENGTH.position),
          }}>
          <OutlinedInput multiline maxRows={4} />
        </Form.Item>
      )}

      <Form.List<TFormInput> name="contactInfoDto.phones" noMargin>
        {({ fields, append, remove }) => {
          if (!isEditMode && fields.length === 1 && fields[0].number === '') {
            return null;
          }
          return (
            <>
              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  <Box display="flex" alignItems="center">
                    <Box flex="1 1 auto">
                      <Form.Item
                        required
                        name={`contactInfoDto.phones.${index}`}
                        label="Номер телефона"
                        viewMode={!isEditMode}
                        renderView={(userPhone: TPhoneInputValue) => (
                          <InfoRow
                            label={PHONES[userPhone.type]}
                            value={
                              <PhoneInput
                                displayType="view"
                                value={userPhone}
                              />
                            }
                          />
                        )}
                        rules={{ validate: { phone } }}>
                        <PhoneInput />
                      </Form.Item>
                    </Box>
                    {isEditMode && fields.length > 1 && (
                      <Box flex="0 0 auto" ml="18px" mb="10px">
                        <IconButton onClick={() => remove(index)}>
                          <CloseIcon className={styles.removeIcon} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Fragment>
              ))}
              {isEditMode && fields.length < phoneCount && (
                <Box mb="32px">
                  <Button
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => append({ number: '', type: 'MOBILE' })}>
                    Добавить телефон
                  </Button>
                </Box>
              )}
            </>
          );
        }}
      </Form.List>

      {!noCounterparty && (
        <Form.Item
          name="login"
          label="Электронный адрес"
          viewMode={!isEditMode}
          rules={{
            required,
            validate: {
              email,
              checkUnique: (value: string) => {
                if (data && data.login === value) {
                  return 'Пользователь с таким email уже существует';
                }
                return true;
              },
            },
            maxLength: maxLength(FIELD_LENGTH.login),
          }}>
          <OutlinedInput multiline maxRows={2} disabled={isLoginDisabled} />
        </Form.Item>
      )}
    </>
  );
};
