import { useOutletContext } from 'react-router-dom';
import { ICompany, CompanyTypes, IKonturFocusData } from 'src/models';
import { TCompanyPermissions } from 'src/configs/company';
import {
  ContractorStatusForContractor,
  ContractorStatusForKam,
  IContractorStatusBlock,
} from 'src/models/contractor';

export const useCompanyContext = () =>
  useOutletContext<{
    companyData: ICompany;
    companyType: CompanyTypes;
    permissions: TCompanyPermissions;
    konturFocusData: IKonturFocusData;
    isContractorCompanyFulfilled: boolean;
    isContractorValid: boolean;
    statusHistory:
      | IContractorStatusBlock<ContractorStatusForKam>[]
      | IContractorStatusBlock<ContractorStatusForContractor>[]
      | undefined;
  }>();
