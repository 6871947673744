import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const CompanyCompanyRequisites = lazy(
  () =>
    import(
      /* webpackChunkName: 'CompanyCompanyRequisites' */
      /* webpackMode: "lazy" */
      `./company-requisites`
    )
);

export const LazyCompanyCompanyRequisites: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <CompanyCompanyRequisites />
  </Suspense>
);
