import {
  IEmailMessage,
  ITelephonyCall,
  INote,
  IMessage,
  IStatus,
} from 'src/models/communications';
import { TNotification } from 'src/models/notifications';
import { TEvent } from 'src/models/admin';

// TODO: Переделать на static class
export const isEmailItem = (item: any): item is IEmailMessage =>
  'communicationType' in item && item.communicationType === 'EMAIL';

export const isCallItem = (item: any): item is ITelephonyCall =>
  'communicationType' in item && item.communicationType === 'TELEPHONY';

export const isNoteItem = (item: any): item is INote =>
  'communicationType' in item && item.communicationType === 'NOTE';

export const isMessageItem = (item: any): item is IMessage =>
  'communicationType' in item && item.communicationType === 'MESSAGE';

export const isStatusItem = (item: any): item is IStatus =>
  'communicationType' in item && item.communicationType === 'STATUS_MESSAGE';

export const isEventItem = (item: any): item is TEvent => 'eventType' in item;

export const isNotificationItem = (item: any): item is TNotification =>
  'notificationType' in item;
