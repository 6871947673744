import classNames from 'classnames';
import styles from './underlined-tabs.module.scss';

export type TUnderlinedTab = {
  path: string;
  label: string;
};

interface UnderlinedTabsProps {
  tabs: TUnderlinedTab[];
  onChangeTab: (tab: TUnderlinedTab) => void;
  activeTab: TUnderlinedTab;
}

export const UnderlinedTabs = ({
  tabs,
  onChangeTab,
  activeTab,
}: UnderlinedTabsProps) => (
  <div className={styles.container}>
    <ul className={styles.containerTabs}>
      {tabs.map((tab, index) => (
        <li
          aria-hidden="true"
          key={index}
          className={classNames(
            styles.tab,
            'tab-item',
            tab.path === activeTab.path && 'active'
          )}
          onClick={() => onChangeTab(tab)}>
          <span>{tab.label}</span>
        </li>
      ))}
    </ul>
  </div>
);
