import { createApi } from '@reduxjs/toolkit/query/react';
import { DIRECTION } from 'src/components/ui/filters/types';
import { ROLES } from 'src/constants';
import { IEventsList } from 'src/models/admin/events';
import { baseQueryWithReauth } from 'src/store/utils';

export type TSortKey = 'eventDateTime';

export type TEventFilterParams = {
  eventTypes: Array<string>;
  initiatorRoles: Array<ROLES>;
  initiatorFio: Array<string>;
  createdAt: {
    from: string;
    to: string;
  };
};

export type TEventsListFilter = {
  sort: {
    field: TSortKey;
    order: DIRECTION;
  };
  paging: {
    page: number;
    limit: number;
  };
  searchQueries: Array<string>;
  filter: TEventFilterParams;
  exactSearchQuery?: string;
};

export type TEventsTypeFilter = {
  code: string;
  displayName: string;
};

export type TEventsInitiatorFioFilter = {
  userId: string;
  fullName: string;
};

export const adminEventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getList: builder.mutation<IEventsList, TEventsListFilter>({
      query: (body) => ({
        url: `business-events/api/admin/v1/business-event`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: IEventsList) => ({
        ...data,
        items: data.items.map((item) => ({
          ...item,
          event: {
            ...item.event,
            params: {
              ...item.event.params,
              targetOrganizationRoles: (item.event as any)
                .targetOrganizationRoles,
              sourceOrganizationRoles: (item.event as any)
                .sourceOrganizationRoles,
            },
          },
        })),
      }),
    }),
    getEventsInitiator: builder.mutation<TEventsInitiatorFioFilter[], void>({
      query: () => ({
        url: 'business-events/api/v1/business-events/initiator',
        method: 'GET',
      }),
    }),
    getEventsType: builder.mutation<TEventsTypeFilter[], void>({
      query: () => ({
        url: 'business-events/api/v1/business-event-types',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetListMutation,
  useGetEventsInitiatorMutation,
  useGetEventsTypeMutation,
} = adminEventsApi;
