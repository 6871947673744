import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  TBusinessRequestsChanged,
  TBusinessRequestsChangedParams,
  TBusinessRequestsChangedResponse,
  TRequestNotificationsParams,
  TBusinessRequestsChangedParamsContractor,
  TBusinessRequestsChangedResponseContractor,
  TGetRequestNotificationsResponse,
  TRequestNotificationsParamsContractor,
} from 'src/models';
import { baseQuery } from 'src/store/utils';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery,
  endpoints: (builder) => ({
    getUnreadNotificationsOnKanban: builder.query<
      TBusinessRequestsChanged,
      TBusinessRequestsChangedParams
    >({
      query: (body) => ({
        url: 'notification-service/api/v1/notifications/unread',
        method: 'POST',
        body,
      }),
      transformResponse: (data: TBusinessRequestsChangedResponse) =>
        data.businessRequestsWithUnreadNotification.reduce(
          (acc: TBusinessRequestsChanged, elem) => {
            acc[elem] = true;
            return acc;
          },
          {}
        ),
    }),
    getUnreadNotificationsOnKanbanContractor: builder.query<
      TBusinessRequestsChanged,
      TBusinessRequestsChangedParamsContractor
    >({
      query: (body) => ({
        url: 'notification-service/api/v1/notifications/contractor/unread',
        method: 'POST',
        body,
      }),
      transformResponse: (data: TBusinessRequestsChangedResponseContractor) =>
        data.contractorRequestsWithUnreadNotification.reduce(
          (acc: TBusinessRequestsChanged, elem) => {
            acc[elem] = true;
            return acc;
          },
          {}
        ),
    }),
    getRequestNotifications: builder.query<
      TGetRequestNotificationsResponse,
      TRequestNotificationsParams
    >({
      query: (params) => ({
        url: 'notification-service/api/v1/notifications',
        method: 'GET',
        params,
      }),
    }),
    getRequestNotificationsContractor: builder.query<
      TGetRequestNotificationsResponse,
      TRequestNotificationsParamsContractor
    >({
      query: (params) => ({
        url: 'notification-service/api/v1/notifications/contractor',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetUnreadNotificationsOnKanbanQuery,
  useLazyGetRequestNotificationsQuery,
  useLazyGetUnreadNotificationsOnKanbanContractorQuery,
  useLazyGetRequestNotificationsContractorQuery,
} = notificationsApi;
