import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const SigningBasis = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminSignatory' */
      /* webpackMode: "lazy" */
      `./signing-basis`
    )
);

export const LazyAdminSigningBasis: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <SigningBasis />
  </Suspense>
);
