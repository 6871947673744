import { FC } from 'react';

import { Loader } from '../loader';

import styles from './page-loader.module.scss';

export const PageLoader: FC = () => (
  <div className={styles.container}>
    <Loader />
  </div>
);
