import { Button, Drawer, SvgIcon } from 'src/components/ui';
import { ArrowLong } from 'src/assets/icons';
import { useAppSelector } from 'src/store';
import { selectUser } from 'src/store/user';
import styles from './mobile-menu.module.scss';
import { UserBlock, MenuBlock, ActionsBlock } from './components';

interface IMobileMenuProps {
  open: boolean;
  onClose: () => void;
}

export const MobileMenu = ({ open, onClose }: IMobileMenuProps) => {
  const user = useAppSelector(selectUser);

  return (
    <Drawer
      open={open}
      close={onClose}
      PaperProps={{
        sx: (theme) => ({
          width: '100%',
          backgroundColor: '#F3F6FB',
          [theme.breakpoints.down('lg')]: {
            '& div[class*="header"]': {
              display: 'none',
            },
          },
        }),
      }}>
      <div className={styles.wrapper}>
        <Button
          sx={{ color: '#000000', padding: '20px 18px' }}
          startIcon={
            <SvgIcon
              icon={ArrowLong}
              strokeColor="#000000"
              className={styles.arrow}
            />
          }
          onClick={onClose}
          variant="text">
          Меню
        </Button>

        <UserBlock user={user} />
        <MenuBlock user={user} onClick={onClose} />
        <ActionsBlock user={user} onClick={onClose} />
      </div>
    </Drawer>
  );
};
