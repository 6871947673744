import { businessRequestApi } from 'src/api/business-request/business-request';
import { TContractorRebidding } from 'src/models';

export const contractorRebiddingApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getContractorRebidding: builder.query<
      TContractorRebidding | undefined,
      number
    >({
      query: (businessRequestId) => ({
        url: `/business-requests/api/v1/contractor/rebidding?businessRequestId=${businessRequestId}`,
      }),
      providesTags: (result, error, arg) => [
        { type: 'getContractorRebidding', id: arg },
      ],
    }),
    refusalRebidding: builder.mutation<
      void,
      {
        rebiddingId: number;
        body: {
          cause: string;
          contractorRequestId: number;
        };
      }
    >({
      query: ({ rebiddingId, body }) => ({
        url: `/business-requests/api/v1/contractor/rebidding/${rebiddingId}/refusal`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getContractorStatusHistory',
                id: arg.body.contractorRequestId,
              },
            ],
    }),
  }),
});

export const { useGetContractorRebiddingQuery, useRefusalRebiddingMutation } =
  contractorRebiddingApi;
