import { SvgIcon, Typography } from 'src/components/ui';
import { Stack } from '@mui/material';
import classNames from 'classnames';
import styles from './mobile-menu-item.module.scss';

export const MobileMenuItem = ({
  label,
  icon,
  onClick,
  className,
}: {
  label: string;
  icon: any;
  onClick?: () => void;
  className?: string;
}) => (
  <Stack
    direction="row"
    gap="8px"
    alignItems="center"
    className={classNames(styles.menuItemWrapper, className)}
    onClick={onClick}>
    <SvgIcon icon={icon} width="24px" height="24px" className={styles.icon} />
    <Typography color="inherit" fontSize={12} className={styles.label}>
      {label}
    </Typography>
  </Stack>
);
