import { FC, PropsWithChildren } from 'react';
import { IBidsCustomerContact } from 'src/models';
import { Button } from 'src/components/ui';
import { SignIn } from 'src/assets/icons';
import { openGuestPath } from 'src/utils';
import { TCompanyPermissions } from 'src/configs/company';
import { useAppSelector } from 'src/store';
import { selectIsGuestAuth } from 'src/store/auth';
import styles from './bids.module.scss';

interface IBidsProps extends PropsWithChildren {
  item: IBidsCustomerContact;
  permissions?: TCompanyPermissions;
}

export const Bids: FC<IBidsProps> = ({ children, item, permissions }) => {
  const isGuestAuth = useAppSelector(selectIsGuestAuth);
  return (
    <>
      {children}
      {permissions?.authAsGuest && !isGuestAuth && (
        <Button
          color="secondary"
          fullWidth
          className={styles.mainButton}
          onClick={() => openGuestPath(item.userId)}
          startIcon={<SignIn />}>
          Авторизоваться
        </Button>
      )}
    </>
  );
};
