import { Link as RouterLink } from 'react-router-dom';
import { DEFAULT_PAGE } from 'src/configs';
import { Result, Typography, Link } from 'src/components/ui';

export const PageNotFound = () => (
  <Result
    icon={
      <Typography color="primary" fontSize="36px" fontWeight="600">
        404
      </Typography>
    }
    title="Страница не найдена"
    subtitle={
      <Link component={RouterLink} to={DEFAULT_PAGE}>
        Перейти на главную страницу
      </Link>
    }
  />
);
