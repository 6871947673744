import { ChangeEvent, FC, PropsWithChildren, useState } from 'react';
import { useAppSelector } from 'src/store';
import { useTimer } from 'src/hooks';
import { selectIsGuestAuth } from 'src/store/auth';
import {
  TContact,
  TContactStatuses,
} from 'src/components/pages/company/contacts/types';
import { isLocked } from 'src/utils/company';
import { TCompanyPermissions } from 'src/configs/company';
import { AuthService } from 'src/services';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  Tooltip,
  Typography,
} from 'src/components/ui';
import {
  Ellipsis,
  Pencil,
  QuestionCircle,
  RedTrash,
  SignIn,
} from 'src/assets/icons';
import { isManager, openGuestPath, secondsToFormattedTime } from 'src/utils';
import {
  getErrorMessage,
  isErrorWithCode,
  isFetchBaseQueryError,
} from 'src/store/utils';
import { ERROR_CODE, ROLES } from 'src/constants';
import { useLazyAddContactSendLetterQuery } from 'src/api/user';
import { useCompanyContext } from 'src/components/layout/company-layout';
import { useUnlockContactMutation } from 'src/api/contacts';
import classNames from 'classnames';
import styles from './contractor.module.scss';

interface IContractorProps extends PropsWithChildren {
  item: TContact;
  permissions?: TCompanyPermissions;
  onChange?: (x: TContact, field: keyof TContact, value: any) => void;
  onDelete?: (id: number) => void;
  onEdit?: (item?: TContact) => void;
  countElements?: number;
}

export const Contractor: FC<IContractorProps> = ({
  item,
  onChange,
  onDelete,
  onEdit,
  countElements,
  permissions,
  children,
}) => {
  const [addContactSendLetter] = useLazyAddContactSendLetterQuery();
  // TODO: не использовать контекст
  const { companyData } = useCompanyContext();
  const [unlockContact, { isLoading: isLoadingUnlock }] =
    useUnlockContactMutation();
  const isGuestAuth = useAppSelector(selectIsGuestAuth);
  const [counter, setCounter] = useTimer();
  const [isLetterSending, setLetterSending] = useState(false);
  const [error, setError] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditAddress = () => {
    onEdit?.(item);
    handleCloseMenu();
  };

  const handleUnlockContact = () => {
    unlockContact({
      userId: item.userId,
    }).unwrap();
  };
  const changeValue =
    (field: keyof TContact) => (event: ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value as boolean | string;

      if (event.target.type === 'checkbox') {
        value = event.target.value === 'true';
      }

      onChange?.(item, field, value);
    };

  const deleteContact = () => {
    onDelete?.(item.id);
  };

  const sendLetter = async () => {
    if (counter) return;

    const requestBody = {
      userId: item.userId,
    };

    setLetterSending(true);
    setError('');

    try {
      await addContactSendLetter(requestBody).unwrap();
    } catch (e) {
      if (
        isFetchBaseQueryError(e) &&
        isErrorWithCode(e.data) &&
        e.data.errorCode === ERROR_CODE.RETRY_DELAY_NOT_EXPIRED
      ) {
        setCounter(e.data.timeToNextAttempt);
      } else {
        setError(getErrorMessage(e));
      }
    } finally {
      setLetterSending(false);
    }
  };

  const isCompanyBlocked = isLocked(companyData.organizationStatus.code);
  const isContactBlocked = item.contactStatus === TContactStatuses.LOCKED;

  const canEdit =
    permissions?.editCommonCompanyData ||
    isManager() ||
    [
      ROLES.FINANCIAL_MANAGER,
      ROLES.GENERAL_MANAGER,
      ROLES.OFFICE_MANAGER,
      ROLES.COMMERCIAL_DIRECTOR,
    ].includes(AuthService.currentRole!);

  return (
    <>
      {item.userId !== AuthService.userId && canEdit && (
        <>
          <IconButton onClick={handleOpenMenu} className={styles.actionBtn}>
            <Ellipsis />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <MenuItem onClick={handleEditAddress}>
              <ListItemIcon>
                <Pencil fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="primary.dark">Редактировать</Typography>
              </ListItemText>
            </MenuItem>
            {!!countElements && countElements > 1 && (
              <div>
                <Divider className={styles.divider} />
                <MenuItem onClick={deleteContact}>
                  <ListItemIcon>
                    <RedTrash />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color="error">Удалить</Typography>
                  </ListItemText>
                </MenuItem>
              </div>
            )}
          </Menu>
        </>
      )}

      {children}

      <>
        <div className={styles.mainCheckboxEmailWrapper}>
          <FormControlLabel
            label="Оповещать по E-mail"
            disabled={isContactBlocked || !canEdit}
            control={
              <Checkbox
                checked={item.isSendEmail === true}
                onChange={changeValue('isSendEmail')}
                value={!item.isSendEmail}
              />
            }
          />

          <Tooltip
            title="Контактное лицо будет получать
          уведомления по E-mail по всем сделкам
          и вопросам компании.">
            <IconButton>
              <QuestionCircle />
            </IconButton>
          </Tooltip>
        </div>

        <div className={styles.mainCheckboxWrapper}>
          <FormControlLabel
            disabled={isContactBlocked || !canEdit}
            control={
              <Radio
                checked={item.isMainContact === true}
                onChange={changeValue('isMainContact')}
                value={item.id}
              />
            }
            label="Основной контакт"
          />

          <Tooltip title="Основной контакт будет получать уведомления по всем вопросам, которые связаны с его компанией. В организации может быть только один основной контакт.">
            <IconButton>
              <QuestionCircle />
            </IconButton>
          </Tooltip>
        </div>

        {permissions?.authAsGuest && !isGuestAuth && (
          <Button
            color="secondary"
            fullWidth
            className={styles.mainButton}
            onClick={() => openGuestPath(item.userId)}
            startIcon={<SignIn />}>
            Авторизоваться
          </Button>
        )}

        {permissions?.unlockContact &&
          isContactBlocked &&
          !isCompanyBlocked && (
            <>
              <Button
                color="secondary"
                fullWidth
                loading={isLoadingUnlock}
                className={styles.mainButton}
                onClick={handleUnlockContact}>
                Разблокировать
              </Button>
              <div className={styles.bottomMessage} />
            </>
          )}

        {permissions?.sendEmailWithCredentials && !isContactBlocked && (
          <>
            <Button
              color="secondary"
              fullWidth
              loading={isLetterSending}
              className={classNames(styles.mainButton, styles.whiteSpace)}
              onClick={sendLetter}
              disabled={Boolean(counter)}>
              {counter ? 'Письмо отправлено' : 'Отправить письмо для входа'}
            </Button>

            <div className={styles.bottomMessage}>
              {Boolean(counter) && (
                <Typography width="155px" mb="7px" variant="subtitle2">
                  {`Повторно отправить можно через ${secondsToFormattedTime(
                    counter
                  )}.`}
                </Typography>
              )}

              <Typography color="error">{error}</Typography>
            </div>
          </>
        )}
      </>
    </>
  );
};
