import {
  TFilterValue,
  TRangeFilterValue,
} from 'src/components/ui/new-filters/types';

export const getRangeValuesFromString = <FilterKeys extends string = string>(
  key: FilterKeys,
  fromString: string,
  toString: string
): TFilterValue<FilterKeys> => {
  const fromStringRepresentation = fromString || '0';
  const toStringRepresentation = toString || '∞';
  const rangeString = `${fromStringRepresentation} - ${toStringRepresentation}`;
  return {
    key,
    value: {
      from: fromString,
      to: toString,
    },
    stringRepresentation: rangeString,
  };
};

export const validateRange = (value: TRangeFilterValue): string => {
  const rangeStart = parseInt(value.from, 10) ?? 0;
  const rangeEnd = parseInt(value.to, 10) ?? Infinity;
  if (rangeStart < 0 || rangeEnd < 0) {
    return 'Бюджет не может быть отрицательным!';
  }
  if (rangeStart > rangeEnd) {
    return 'Начальный бюджет не может быть больше конечного!';
  }
  return '';
};

export const getRangeStringRepresentation = (
  value: TRangeFilterValue
): string => {
  const fromStringRepresentation = value.from || '0';
  const toStringRepresentation = value.to || '∞';
  return `${fromStringRepresentation} - ${toStringRepresentation}`;
};
