import {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useResizeObserver } from 'src/hooks';
import {
  TChip,
  FilterTypes,
  TSortItem,
  TFilterTab,
  TVariant,
  FiltersProps,
  FuncFiltersModal,
  FuncFiltersTabs,
} from '../types';
import {
  getSearchParameters,
  getFilterParameters,
  validateSearchQuery,
  createSortChip,
  isDateFilter,
  updateChipsByFiltersConfig,
} from '../utils';

const CHIPS_SPACE_PERCENT = 50; // Процент, который могут занимать chips внутри инпута

const getTabsSections = (
  tabs?: Array<TFilterTab>
): Record<string, Array<TFilterTab>> | undefined =>
  tabs?.reduce((acc: Record<string, Array<TFilterTab>>, tab) => {
    const currentNameFilter = tab.nameFilter || 'filter';
    acc[currentNameFilter] = acc[currentNameFilter]
      ? [...acc[currentNameFilter], tab]
      : [tab];
    return acc;
  }, {});

export const useFilters = <KeysSorting,>(props: FiltersProps<KeysSorting>) => {
  const {
    onSearch,
    onFilter,
    onSort,
    onCustomTagDelete,
    onClearCustomTags,
    tabList,
    filtersVariantList,
    defaultInput,
    sortList,
    searchQueries,
    resetTabs = false,
    countSearchQuery = 10,
    defaultFiltersData,
  } = props;

  const [chips, setChips] = useState<Array<TChip>>([]);
  const [chipsWidth, setChipsWidth] = useState(0);
  const [hiddenChips, setHiddenChips] = useState<Record<string, boolean>>({});
  const [noActiveSort, setNoActiveSort] = useState(false);
  const [tabs, setTabs] = useState(getTabsSections(tabList));
  const [filtersList, setFiltersList] = useState(filtersVariantList);
  const [openPopoverFilter, setOpenPopoverFilter] = useState(false);
  const [alert, setAlert] = useState('');
  // Нужно для отслеживания, находится ли сейчас один из search chip в состоянии редактирования
  const editSearchChipValueRef = useRef<string | null>(null);

  const hiddenChipsCount = Object.keys(hiddenChips).length;

  const containerInputRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const chipsRef = useRef<HTMLDivElement>(null);
  const chipsContainerRef = useRef<HTMLDivElement>(null);

  const inputPadding = useMemo(
    () => (chipsContainerRef.current?.getBoundingClientRect().width || 0) + 20,
    [chipsWidth]
  );

  useResizeObserver(chipsContainerRef, (entries) => {
    const entry = entries[0];

    if (entry.contentRect.width === chipsWidth) {
      return;
    }

    setChipsWidth(entry.contentRect.width);
    if (editSearchChipValueRef.current !== null) return;
    setHiddenChips({});
  });

  useLayoutEffect(() => {
    // Не прячем чипсы во время редактирования одной из них
    if (editSearchChipValueRef.current !== null) return;

    if (!chipsRef.current || !searchInputRef.current) return;

    const containerChildren = chipsRef.current?.children!;
    const inputWidth = searchInputRef.current?.getBoundingClientRect().width;

    const elementWidths: number[] = [];

    for (let i = 0, l = containerChildren.length; i < l; i++) {
      const child = containerChildren[i];
      const clientRect = child.getBoundingClientRect();
      const { width } = clientRect;

      elementWidths.push(width);
    }

    const hiddenChipsMap: Record<string, boolean> = {};
    let maxChipsWidth = Math.floor((inputWidth! / 100) * CHIPS_SPACE_PERCENT);

    chips.forEach((item, index) => {
      const itemWidth = elementWidths[index];
      if (itemWidth <= maxChipsWidth) {
        maxChipsWidth -= itemWidth;
      } else {
        hiddenChipsMap[item.label] = true;
      }
    });

    setHiddenChips(hiddenChipsMap);
  }, [chips, chipsWidth]);

  useEffect(() => {
    setTabs(
      resetTabs
        ? getTabsSections(tabList)
        : (prevTabs) =>
            prevTabs &&
            tabList?.reduce(
              (acc, tab) => {
                const currentNameFilter = tab.nameFilter || 'filter';
                acc[currentNameFilter] = acc[currentNameFilter]?.map(
                  (prevTab) =>
                    prevTab.key === tab.key
                      ? {
                          ...prevTab,
                          label: tab.label,
                        }
                      : prevTab
                );
                return acc;
              },
              { ...prevTabs }
            )
    );
  }, [resetTabs, tabList]);

  const handleDeleteChip = useCallback(
    (deleteChip: TChip) => () => {
      editSearchChipValueRef.current = null;

      const newChips = chips.filter(
        (chip) => JSON.stringify(chip) !== JSON.stringify(deleteChip)
      );

      setChips(newChips);
      switch (deleteChip.type) {
        case FilterTypes.SEARCH:
          onSearch?.(getSearchParameters(newChips));
          break;
        case FilterTypes.SORT:
          setNoActiveSort(true);
          onSort?.(null);
          break;
        case FilterTypes.FILTERS:
          if (onFilter) {
            (onFilter as FuncFiltersModal)?.(getFilterParameters(newChips));
          }
          break;
        case FilterTypes.CUSTOM_TAG:
          onCustomTagDelete?.({
            label: deleteChip.label,
            value: deleteChip.value ?? '',
          });
          break;
        default:
          break;
      }
      setAlert('');
    },
    [chips, onCustomTagDelete, onFilter, onSearch, onSort]
  );

  const handleClearFilters = useCallback(() => {
    setChips([]);
    onSearch?.(getSearchParameters([]));
    setNoActiveSort(true);
    onSort?.(null);
    onClearCustomTags?.();
    (onFilter as FuncFiltersModal)?.(getFilterParameters([]));
    setAlert('');
    // onCustomTagDelete?.({
    //   label: deleteChip.label,
    //   value: deleteChip.value ?? '',
    // });
  }, [onFilter, onSearch, onSort]);

  const updateEditChipRefValue = (newValue: string | null) => {
    editSearchChipValueRef.current = newValue;
  };

  const handleSearchChipEdit = (oldValue: string, newValue: string) => {
    editSearchChipValueRef.current = null;

    const parametersSearchQuery: string[] = [
      ...defaultFiltersData!.searchQueries!,
    ];

    if (!newValue) {
      onSearch?.(parametersSearchQuery);
      return;
    }

    const oldValueIndex = parametersSearchQuery.findIndex(
      (value) => value === oldValue
    );

    parametersSearchQuery[oldValueIndex] = newValue;

    onSearch?.(parametersSearchQuery);
  };

  const handleOnSearch = useCallback(
    (searchQuery: string, isClearAll = false) => {
      if (isClearAll) {
        onSearch?.([]);
        setChips((prevState) =>
          prevState.filter((chip) => chip.type !== FilterTypes.SEARCH)
        );
        return;
      }

      if (chips.length >= countSearchQuery) {
        setAlert(
          `Максимальное количество параметров в строке для поиска ${countSearchQuery}`
        );
        return;
      }

      const newChip = {
        type: FilterTypes.SEARCH,
        label: searchQuery,
        hidden: true,
      };

      if (defaultInput) {
        onSearch?.([newChip.label]);
        return;
      }

      if (validateSearchQuery(newChip, chips)) {
        const newChips = [newChip, ...chips];
        setChips(newChips);
        const parametersSearchQuery: string[] = [];
        newChips.forEach((chip) => {
          if (chip.type === FilterTypes.SEARCH) {
            parametersSearchQuery.unshift(chip.label);
          }
        });
        onSearch?.(parametersSearchQuery);
      }
      setOpenPopoverFilter(false);
    },
    [chips, countSearchQuery, defaultInput, onSearch]
  );

  const handleSort = useCallback(
    (sortParameters: TSortItem<KeysSorting>) => {
      let sortChip = createSortChip<KeysSorting>(sortParameters);
      let isChangeSortDirection = false;
      const chipsWithoutSort = chips.filter((chip) => {
        if (chip.type === FilterTypes.SORT && sortChip.label === chip.label) {
          isChangeSortDirection = true;
          sortChip = {
            ...sortChip,
            hidden: chip.hidden,
          };
        }
        return chip.type !== FilterTypes.SORT;
      });

      if (isChangeSortDirection) {
        setChips([...chipsWithoutSort, sortChip]);
      } else {
        setChips([...chipsWithoutSort, sortChip]);
      }

      setNoActiveSort(false);
      onSort?.({
        field: sortParameters.key,
        direction: sortParameters.direction,
      });
    },
    [chips, onSort]
  );

  const handleFilters = (selectedTab: TFilterTab, curNameFilter: string) => {
    setTabs(
      (prev) =>
        prev &&
        Object.keys(prev).reduce(
          (acc: Record<string, Array<TFilterTab>>, nameFilter) => {
            if (curNameFilter === nameFilter) {
              acc[nameFilter] = prev[nameFilter].map((tab) => ({
                ...tab,
                active: tab.key === selectedTab.key,
              }));
            } else {
              acc[nameFilter] = prev[nameFilter];
            }
            return acc;
          },
          {}
        )
    );
    (onFilter as FuncFiltersTabs)?.(selectedTab.key);
  };

  const handleFiltersModal = useCallback(
    (selectedFilters: Record<string, Array<TVariant>>) => {
      const keysSelectedFilters = Object.keys(selectedFilters);
      let newChips = [] as Array<TChip>;

      if (keysSelectedFilters.length === 0) {
        newChips = chips.filter((chip) => chip.type !== FilterTypes.FILTERS);

        setChips([...newChips]);
      } else {
        newChips = chips.filter((chip) => chip.type !== FilterTypes.FILTERS);
        keysSelectedFilters?.forEach((nameFilter) => {
          if (isDateFilter(selectedFilters[nameFilter])) {
            const filterDate = selectedFilters[nameFilter][0];
            newChips.unshift({
              type: FilterTypes.FILTERS,
              value: filterDate.label,
              label: filterDate.label,
              hidden: false,
              date: filterDate.date,
              nameFilter,
            });
          } else {
            selectedFilters[nameFilter].forEach((variant) => {
              if (variant.checked) {
                newChips.unshift({
                  type: FilterTypes.FILTERS,
                  value: variant.value,
                  label: variant.label,
                  hidden: false,
                  nameFilter,
                });
              }
            });
          }
        });
        setChips([...newChips]);
      }
      if (onFilter) {
        (onFilter as FuncFiltersModal)?.(getFilterParameters(newChips));
      }
    },
    [chips, onFilter]
  );

  // useEffect(() => {
  //   if (filtersVariantList) {
  //     const updatedChips = updateChipsByFiltersConfig(
  //       filtersVariantList,
  //       chips
  //     );
  //     let newChips: TChip[] = [];

  //     newChips = newChips.concat(updatedChips);
  //       console.log({chips, updatedChips, newChips});
  //     setChips(newChips);
  //     setFiltersList(filtersVariantList);
  //   }
  // }, [filtersVariantList]);

  useEffect(() => {
    editSearchChipValueRef.current = null;
  }, [filtersVariantList, sortList]);

  // TODO check
  useLayoutEffect(() => {
    if (defaultFiltersData) {
      let newChips: TChip[] = [];

      if (defaultFiltersData.searchQueries?.length) {
        newChips = defaultFiltersData.searchQueries.map((item) => ({
          hidden: false,
          label: item,
          value: item,
          type: FilterTypes.SEARCH,
          // Поля нужные для функционала редактирования значения чипа
          onSearchChipEdit: handleSearchChipEdit,
          updateEditChipRefValue,
          editChipRefValue: editSearchChipValueRef.current,
        }));
      }

      if (defaultFiltersData.sort && sortList) {
        newChips = [
          ...newChips,
          {
            direction: defaultFiltersData.sort.order,
            hidden: false,
            label:
              sortList.find(
                (sortItem) => sortItem.key === defaultFiltersData.sort?.field
              )?.label ?? '',
            type: FilterTypes.SORT,
          },
        ];
      }

      if (filtersVariantList) {
        const updatedChips = updateChipsByFiltersConfig(
          filtersVariantList,
          newChips
        );

        newChips = updatedChips;

        setChips(newChips);
        setFiltersList(filtersVariantList);
      }

      if (defaultFiltersData.customTags) {
        const customChips = defaultFiltersData.customTags.map(
          (customFilter) => ({
            hidden: false,
            label: customFilter.label,
            value: customFilter.value,
            type: FilterTypes.CUSTOM_TAG,
          })
        );
        newChips = [...newChips, ...customChips];
      }

      setChips(newChips);
    }
  }, [
    defaultFiltersData,
    sortList,
    filtersVariantList,
    // Эта зависимость нужна, чтобы в поисковой строке можно было одновременно
    // редактировать не больше одного чипа
    editSearchChipValueRef.current,
  ]);

  useEffect(() => {
    if (searchQueries && searchQueries.length) {
      const initSearchChips = searchQueries.map((query) => ({
        type: FilterTypes.SEARCH,
        label: query,
        hidden: true,
      }));

      setChips((prev) => {
        const clearQueries = initSearchChips.filter((q) =>
          validateSearchQuery(q, prev)
        );
        return [...clearQueries, ...prev];
      });
    }

    // TODO check
    // const appliedSort = chips.find((item) => item.type === 'sort');

    // if (sortList && !appliedSort) {
    //   const activeSortParam = sortList.find((item) => item.active);

    //   if (activeSortParam) {
    //     const activeSortChip = createSortChip<KeysSorting>(activeSortParam);
    //     setChips((prev) => [...prev, activeSortChip]);
    //   }
    // }
  }, []);

  return {
    chips,
    hiddenChipsCount,
    hiddenChips,
    noActiveSort,
    tabs,
    openPopoverFilter,
    containerInputRef,
    filtersList,
    searchInputRef,
    chipsRef,
    alert,
    chipsContainerRef,
    inputPadding,
    handleDeleteChip,
    handleSort,
    handleOnSearch,
    handleFiltersModal,
    setOpenPopoverFilter,
    handleFilters,
    handleClearFilters,
  };
};
