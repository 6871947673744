import { FC } from 'react';
import { ContractorStatusCode, IContractorBusinessRequest } from 'src/models';
import { formatServerDate } from 'src/utils';
import { Alert } from 'src/components/ui';
import { isExpiredCommercialOfferDueDate } from 'src/utils/business-request';

export const ContractorBusinessRequestStatus: FC<{
  contractorRequest: IContractorBusinessRequest;
}> = ({ contractorRequest }) => {
  const currentStatus = contractorRequest.statusHistory.status?.code;

  if (
    isExpiredCommercialOfferDueDate(contractorRequest.commercialOfferDueDate) &&
    ContractorStatusCode.NEW_REQUEST === (currentStatus as ContractorStatusCode)
  ) {
    return (
      <Alert
        content="Вышел срок подачи КП"
        tooltip={formatServerDate(
          contractorRequest.commercialOfferDueDate,
          'dd.MM.yyyy'
        )}
      />
    );
  }

  return null;
};
