import { Components } from '@mui/material/styles/components';

// Варианты none нужны для обратной совместимости со старой дизайн системой
declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
    none: true;
  }

  interface IconButtonPropsColorOverrides {
    white: true;
    blend: true;
    none: true;
  }
}

export const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
    size: 'none',
    color: 'none',
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      padding: 0,
      borderRadius: '8px',
      '&.MuiIconButton-edgeEnd': {
        padding: '14px',
      },
      ...(ownerState.color !== 'none' && {
        '&:disabled': {
          backgroundColor: (theme as any).palette.primary.light,
        },
      }),
      ...(ownerState.size === 'large' && {
        height: '44px',
        width: '44px',
        '& svg': {
          height: '24px',
          width: '24px',
        },
      }),
      ...(ownerState.size === 'medium' && {
        height: '36px',
        width: '36px',
        '& svg': {
          height: '20px',
          width: '20px',
        },
      }),
      ...(ownerState.size === 'small' && {
        height: '28px',
        width: '28px',
        '& svg': {
          height: '16px',
          width: '16px',
        },
      }),
      ...(ownerState.size === 'extraSmall' && {
        height: '24px',
        width: '24px',
        '& svg': {
          height: '16px',
          width: '16px',
        },
      }),
      ...(ownerState.color === 'primary' && {
        backgroundColor: (theme as any).palette.primary.main,
        color: '#fff',
        '&:hover': {
          backgroundColor: (theme as any).palette.primary.dark,
        },
        '&:active': {
          backgroundColor: (theme as any).palette.customTheme
            .darkBlueContentSecondary,
        },
      }),
      ...(ownerState.color === 'secondary' && {
        backgroundColor: (theme as any).palette.primary.light,
        color: (theme as any).palette.text.primary,
        '&:hover': {
          backgroundColor: '#E3E8EF',
        },
        '&:active': {
          backgroundColor: '#D6DEE7',
        },
      }),
      ...(ownerState.color === 'white' && {
        backgroundColor: '#FFFFFF',
        color: (theme as any).palette.text.primary,
        '&:hover': {
          backgroundColor: '#E3E8EF',
        },
        '&:active': {
          backgroundColor: '#D6DEE7',
        },
      }),
      ...(ownerState.color === 'blend' && {
        color: '#FFFFFF',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: '#FFFFFF1A',
        },
        '&:active': {
          backgroundColor: '#FFFFFF33',
        },
        '&:disabled': {
          color: '#FFFFFF80',
          '& svg': {
            color: '#FFFFFF80',
          },
        },
      }),
      ...(ownerState.color === 'error' && {
        color: '#FFFFFF',
        backgroundColor: '#E11111',
        '&:hover': {
          backgroundColor: '#BF1D26',
        },
        '&:active': {
          backgroundColor: '#81080F',
        },
      }),
      ...(ownerState.color === 'success' && {
        color: '#FFFFFF',
        backgroundColor: '#1DBF61',
        '&:hover': {
          backgroundColor: '#27AE60',
        },
        '&:active': {
          backgroundColor: '#08823B',
        },
      }),
      ...(ownerState.color === 'warning' && {
        color: '#FFFFFF',
        backgroundColor: '#E79600',
        '&:hover': {
          backgroundColor: '#BF861D',
        },
        '&:active': {
          backgroundColor: '#825708',
        },
      }),
    }),
  },
};
