import { TableCell, Typography } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { Arrow, InfoHelpIcon } from 'src/assets/icons';
import { Popover, Button, Tooltip, Box } from 'src/components/ui';
import styles from './table.module.scss';
import { ThCellType } from './types';
import { FiltersPopup } from './components/filters-header';
import { FiltersRadio } from './components/filters-radio';

export const ThCell = <RecordType,>(props: ThCellType<RecordType>) => {
  const {
    column,
    index,
    onFilters,
    showCounterSelected,
    numSelected,
    rowCount,
    withLabelSelected,
    filtersWithRadioButton = false,
    showTotalCount = true,
  } = props;
  const { filters } = column;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableCell {...column.setting}>
      {filters ? (
        <>
          <Button
            variant="text"
            className={styles.thCell}
            onClick={handleClick}
            endIcon={<Arrow />}>
            {column.title}
          </Button>
          <Popover
            id={String(column.key)}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            {!filtersWithRadioButton ? (
              <FiltersPopup<RecordType>
                column={column}
                filters={filters}
                onFilters={onFilters}
                onClose={setAnchorEl}
              />
            ) : (
              <FiltersRadio<RecordType>
                column={column}
                filters={filters}
                onFilters={onFilters}
              />
            )}
          </Popover>
        </>
      ) : (
        <Box display="flex" alignItems="center" gap="8px">
          <span className={styles.thCell}>{column.title}</span>

          {withLabelSelected && index === 0 && showCounterSelected && (
            <div className={styles.containerCounterSelected}>
              <Typography
                color="#fff"
                fontSize="12px"
                lineHeight="16px"
                whiteSpace="nowrap">
                Выбрано {numSelected} {!!showTotalCount && `/ ${rowCount}`}
              </Typography>
            </div>
          )}

          {column.tooltip && (
            <Tooltip title={column.tooltip}>
              <InfoHelpIcon />
            </Tooltip>
          )}
        </Box>
      )}
    </TableCell>
  );
};
