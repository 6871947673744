import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITelephonyCall } from 'src/models/communications';

interface IInitialState {
  currentCall: ITelephonyCall | null;
}

const initialState: IInitialState = {
  currentCall: null,
};

const telephonySlice = createSlice({
  name: 'telephony',
  initialState,
  reducers: {
    setCurrentCall(state, action: PayloadAction<ITelephonyCall | null>) {
      const { payload } = action;
      state.currentCall = payload;
    },
  },
});

export const { setCurrentCall } = telephonySlice.actions;
export const telephonyReducer = telephonySlice.reducer;
