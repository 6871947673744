const io = require('socket.io-client');

const standURL = `${process.env.REACT_APP_SOCKET_URL}`;

export const socket = io(standURL, {
  autoConnect: false,
  query: {
    token: null,
  },
});

export const notificationsSocket = io(standURL, {
  path: '/notification-socket/socket.io',
  autoConnect: false,
  query: {
    token: null,
  },
});
