import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectIsAuth } from 'src/store/auth';
import { useAppSelector } from 'src/store';

export const ProtectedRoute: FC = () => {
  const isAuth = useAppSelector(selectIsAuth);
  const location = useLocation();

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
