import { IEmailMessage } from 'src/models/communications';

// TODO: Проверить актуальность и отрефакторить
export const getActiveMessage = (
  messages: IEmailMessage[],
  id: string
): IEmailMessage | null => {
  let result = null;

  messages.forEach((item) => {
    if (item.id === id) {
      result = item;
    }
  });

  return result;
};
