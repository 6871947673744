import { ComponentProps, useState } from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import { OutlinedInput } from 'src/components/ui';
import { DateIcon } from 'src/assets/icons';
import { Input } from '@mui/material';

type TMuiDateTimePicker = ComponentProps<typeof MuiDateTimePicker>;

type TDateTimePicker = Omit<
  TMuiDateTimePicker,
  'renderInput' | 'value' | 'onChange'
> & {
  value?: string | null;
  onChange?: TMuiDateTimePicker['onChange'];
  placeholder?: string;
  isMinDate?: boolean;
  variant?: 'text' | 'outlined';
};

const onBlur = (
  value: string | undefined | null,
  onChange: (value: unknown) => void
) => {
  if (!value) {
    return;
  }
  const inputDate = new Date(value);
  if (inputDate.getFullYear() < 100) {
    inputDate.setFullYear(2000 + inputDate.getFullYear());
    onChange(inputDate);
  }
};

export const DateTimePicker = ({
  onChange = () => {},
  value,
  placeholder = 'Введите дату',
  isMinDate = true,
  variant = 'outlined',
  ...props
}: TDateTimePicker) => {
  const [open, setOpen] = useState(false);

  return (
    <MuiDateTimePicker
      {...props}
      onChange={onChange}
      value={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      components={{
        OpenPickerIcon: DateIcon,
      }}
      renderInput={({ inputRef, inputProps, InputProps }) =>
        variant === 'text' ? (
          <Input
            inputRef={inputRef}
            {...InputProps}
            endAdornment={null}
            inputProps={{ ...inputProps, placeholder }}
            onClick={() => setOpen(true)}
            onBlur={() => isMinDate && onBlur(value, onChange)}
            disableUnderline
          />
        ) : (
          <OutlinedInput
            trimValue={false}
            inputRef={inputRef}
            {...InputProps}
            inputProps={{ ...inputProps, placeholder }}
            onBlur={() => isMinDate && onBlur(value, onChange)}
          />
        )
      }
    />
  );
};
