import { toast } from 'react-toastify';
import { TNotification } from 'src/models/notifications';
import { NotificationCard } from '../../components/notification-card';
import styles from './send-toast-notification.module.scss';
import 'react-toastify/dist/ReactToastify.css';

export const sendToastNotification = (notificationData: TNotification) => {
  toast(<NotificationCard />, {
    data: notificationData,
    className: styles.toastWrapper,
    closeButton: false,
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: false,
    progress: undefined,
  });
};
