import { ICustomerShipping, IShipmentsUpdateBody } from 'src/models/shipping';
import { businessRequestApi } from '../business-request';

export const customerShippingApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerShipping: builder.query<
      ICustomerShipping[],
      { businessApplicationId: number }
    >({
      query: ({ businessApplicationId }) => ({
        url: 'business-requests/api/v1/customer/shipment-group',
        method: 'GET',
        params: { businessApplicationId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getCustomerShipping', id: arg.businessApplicationId },
      ],
    }),
    updateCustomerShipping: builder.mutation<
      ICustomerShipping[],
      IShipmentsUpdateBody
    >({
      query: (body) => ({
        url: `business-requests/api/v1/customer/shipment-group/${body.shipmentGroupId}`,
        method: 'PUT',
        body: { comment: body.comment, shipments: body.shipments },
      }),
    }),
  }),
});

export const {
  useGetCustomerShippingQuery,
  useUpdateCustomerShippingMutation,
} = customerShippingApi;
