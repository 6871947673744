import { useMemo } from 'react';
import TableRow from '@mui/material/TableRow';
import { Checkbox, TableCell } from '@mui/material';
import { ColumnsType } from 'src/components/ui/new-table/types';

import styles from './row.module.scss';
import { Cell } from './components';

interface RowProps<RecordType> {
  columns: ColumnsType<RecordType>;
  record: RecordType;
  selected?: Array<RecordType>;
  setSelected?: (select: RecordType[]) => void;
  onRowClick?: (row: RecordType) => void;
}

export const Row = <RecordType extends { key: number | string }>({
  columns,
  record,
  selected,
  setSelected,
  onRowClick,
}: RowProps<RecordType>) => {
  const isItemSelected = useMemo(
    () => selected?.some((item) => item.key === record.key),
    [selected, record]
  );

  const handleSelected = () => {
    if (!selected || !setSelected) return;

    let newSelected: Array<RecordType> = [];
    const selectedIndex = selected?.findIndex(
      (item) => item.key === record.key
    );

    if (selectedIndex === -1) {
      newSelected = [...selected, record];
    } else {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }
    setSelected(newSelected);
  };

  return (
    <TableRow
      className={styles.row}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
      {setSelected ? (
        <TableCell className={styles.cell}>
          <Checkbox onClick={() => handleSelected()} checked={isItemSelected} />
        </TableCell>
      ) : null}

      {columns.map((column, index) => (
        <Cell<RecordType>
          key={String(column.title)}
          column={column}
          index={index}
          record={record}
          onClick={() => {
            if (onRowClick) {
              onRowClick(record);
            }
          }}
        />
      ))}
    </TableRow>
  );
};
