import { FC, ReactNode } from 'react';
import { Typography } from 'src/components/ui';

import styles from './header.module.scss';

export const Header: FC<{ title: string; children?: ReactNode }> = ({
  title,
  children,
}) => (
  <div className={styles.wrapper}>
    <Typography variant="h5">{title}</Typography>
    {children}
  </div>
);
