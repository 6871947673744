import { Typography } from 'src/components/ui';
import { ITooltipProps } from './tooltip.types';
import styles from './tooltip.module.scss';

export const Tooltip = ({
  label,
  axisData,
  axisValue,
  values,
}: ITooltipProps) => {
  const formatter = Intl.NumberFormat('ru-RU', { notation: 'compact' });
  const valueIndex = axisData?.x?.index ? axisData!.x!.index : 0;
  const prevValueIndex = valueIndex ? valueIndex - 1 : 0;
  const currentValue = values[valueIndex];
  const prevValue = values[prevValueIndex];

  const getPrevValue = () => {
    const diff = currentValue.value - prevValue.value;
    const isGrow = diff > 0;
    if (diff === 0) return null;
    return (
      <Typography
        fontSize="12px"
        lineHeight="1.666"
        color={isGrow ? '#13B81A' : '#E11111'}>
        {`${isGrow ? '+' : ''}${formatter.format(
          diff
        )} ${label.toLowerCase()} руб.`}
      </Typography>
    );
  };

  if (!axisData.y) {
    return null;
  }

  return (
    <div className={styles.tooltip}>
      <Typography fontSize="20px" lineHeight="1" mb="4px">
        <>
          <strong>{formatter.format(+currentValue.value)}</strong>{' '}
          {label.toLowerCase()} руб.
        </>
      </Typography>
      {getPrevValue()}
      <Typography fontSize="12px" lineHeight="1.666" color="#7A8694">
        <>за {axisValue} год</>
      </Typography>
    </div>
  );
};
