import { useCallback, useMemo, useState } from 'react';
import {
  TTechnicalOpportunitiesCategories,
  TTechnicalOpportunity,
} from 'src/models';
import { Button } from 'src/components/ui';
import { ModalSelectClassifierTags } from './components';

type TFilterByTags = {
  onClick: () => void;
  onChangeTags: (currentTagsSelected: TTechnicalOpportunity[]) => void;
  defaultTags: TTechnicalOpportunitiesCategories;
};

export const FilterByTags = (props: TFilterByTags) => {
  const { onClick, onChangeTags, defaultTags } = props;
  const [isShowModal, setIsShowModal] = useState(false);

  const handleClick = useCallback(() => {
    setIsShowModal(true);
    onClick();
  }, [onClick, setIsShowModal]);

  return useMemo(() => {
    const title = defaultTags.length ? 'Изменить теги' : 'Выбрать теги';

    return {
      actionButton: (
        <Button variant="text" onClick={handleClick} color="inherit">
          {title}
        </Button>
      ),
      actionDialog: isShowModal && (
        <ModalSelectClassifierTags
          open={isShowModal}
          toggleOpen={() => {
            setIsShowModal((prev) => !prev);
          }}
          onChangeTechnicalOpportunities={onChangeTags}
          defaultTags={defaultTags}
        />
      ),
    };
  }, [defaultTags, handleClick, isShowModal, onChangeTags]);
};
