import { DIRECTION, TSortItem } from 'src/components/ui/filters/types';
import { TCommunicationListSortKey } from 'src/api/communications/models';

export const sortList: TSortItem<TCommunicationListSortKey>[] = [
  {
    label: 'Дата сообщения',
    key: 'sortDate',
    direction: DIRECTION.DESC,
    active: true,
  },
  // {
  //   label: 'Email пользователя',
  //   key: 'userEmail',
  //   direction: DIRECTION.DESC,
  //   active: false,
  // },
];
