import { FC } from 'react';
import styles from './empty-file-list.module.scss';

type TEmptyFileListProps = {
  text?: string;
};

export const EmptyFileList: FC<TEmptyFileListProps> = ({ text }) => (
  <div className={styles.wrapper}>{text}</div>
);
