import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Form } from 'src/components/ui';
import { useAppSelector } from 'src/store';
import { selectUser } from 'src/store/user';
import { useUpdateCurrentUserMutation } from 'src/api/user';
import { getErrorMessage } from 'src/store/utils';
import { UserProfileFields } from './user-profile-fields';
import { TFormInput } from './types';

export const UserProfile: FC = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const user = useAppSelector(selectUser);

  const form = useForm<TFormInput>({
    defaultValues: {
      login: user.login,
      lastName: user.lastName,
      firstName: user.firstName,
      middleName: user.middleName,
      position: user.position ?? '',
      contactInfoDto: user.contactInfoDto.phones?.length
        ? user.contactInfoDto
        : { phones: [{ number: '', type: 'MOBILE' }] },
    },
  });
  const [updateUserProfile, { isLoading }] = useUpdateCurrentUserMutation();

  const onSubmit: SubmitHandler<TFormInput> = async (data) => {
    try {
      await updateUserProfile(data).unwrap();
      form.reset(data);
      setIsEditMode(false);
    } catch (e) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(e, 'Произошла ошибка при сохранении данных'),
      });
    }
  };

  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      onCancel={() => setIsEditMode(false)}
      viewMode={!isEditMode}
      loading={isLoading}
      autoFocusNext
      footer={
        isEditMode || (
          <Button color="secondary" onClick={() => setIsEditMode(true)}>
            Редактировать
          </Button>
        )
      }>
      <UserProfileFields isEditMode={isEditMode} isLoginDisabled />
    </Form>
  );
};
