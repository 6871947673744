import { joinFullName } from 'src/utils/join-full-name';

export const getSortedUsers = <
  T extends { firstName: string; lastName: string; middleName?: string }
>(
  users: T[] | undefined
) => {
  if (!users) {
    return [];
  }

  return [...users].sort((a, b) => {
    const collator = new Intl.Collator('ru');

    const first = joinFullName(a?.lastName, a?.firstName, a?.middleName);
    const second = joinFullName(b?.lastName, b?.firstName, b?.middleName);

    if (Number.isNaN(first) && !Number.isNaN(second)) {
      return -1;
    }

    if (!Number.isNaN(first) && Number.isNaN(second)) {
      return 1;
    }

    return collator.compare(first, second);
  });
};

export const getSortedUsersByFio = <T extends { fullName: string }>(
  users: T[] | undefined
) => {
  if (!users) {
    return [];
  }

  return [...users].sort((a, b) => {
    const collator = new Intl.Collator('ru');

    if (Number.isNaN(a.fullName) && !Number.isNaN(b.fullName)) {
      return -1;
    }

    if (!Number.isNaN(a.fullName) && Number.isNaN(b.fullName)) {
      return 1;
    }

    return collator.compare(a.fullName, b.fullName);
  });
};
