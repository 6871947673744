import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const Customers = lazy(
  () =>
    import(
      /* webpackChunkName: 'Customers' */
      /* webpackMode: "lazy" */
      `./customers`
    )
);

export const LazyCustomers: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <Customers />
  </Suspense>
);
