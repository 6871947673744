export const compareObject = (
  obj1: Record<any, any>,
  obj2: Record<any, any>
) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    obj1 === null ||
    typeof obj1 !== 'object' ||
    obj2 == null ||
    typeof obj2 !== 'object'
  ) {
    return false;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  const keysObj1 = Object.keys(obj1);
  const keysObj2 = Object.keys(obj2);

  for (let i = 0; i < keysObj2.length; i++) {
    for (let j = 0; j < keysObj1.length; j++) {
      const key = keysObj2[i];

      if (!keysObj1.includes(key) || !compareObject(obj1[key], obj2[key])) {
        return false;
      }
    }
  }

  return true;
};
