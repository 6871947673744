import { useCallback, useMemo } from 'react';
import { Autocomplete, Box, Form } from 'src/components/ui';
import { required } from 'src/utils/form-rules';
import { EPublishedStatus } from '../../../../config/news-types';

type TPublishStatusSelectOptions = {
  name: string;
  value: EPublishedStatus;
};

export const PublishStatusSelect = () => {
  const options = useMemo(
    () => [
      {
        name: 'Опубликовано',
        value: EPublishedStatus.PUBLISHED,
      },
      {
        name: 'Не опубликовано (скрыто)',
        value: EPublishedStatus.NOT_PUBLISHED,
      },
    ],
    []
  );
  const fetchApiFilter = useCallback(
    (value: string) =>
      Promise.resolve(
        (value
          ? options?.filter((element) =>
              element.name.toLowerCase().includes(value.toLowerCase())
            )
          : options) || []
      ),
    [options]
  );
  return (
    <Box>
      <Form.Item
        name="publishStatus"
        label="Отметка о публикации"
        rules={{
          required,
          validate: (status) => status.name !== '' || 'Заполните это поле',
        }}>
        <Autocomplete<TPublishStatusSelectOptions[][number]>
          popupIcon
          getOptionLabel={(option) => option.name || ''}
          options={options}
          fetchApi={fetchApiFilter}
          fetchOnEmptyInput
          placeholder="Выбрать"
        />
      </Form.Item>
    </Box>
  );
};
