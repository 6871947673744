import {
  Dialog,
  DialogContent,
  DialogTitle,
  TDialogProps,
} from 'src/components/ui';
import { IBubbleNotificationListResult } from 'src/api/communications/models';
import {
  isCallItem,
  isEmailItem,
  isMessageItem,
  isNoteItem,
  isNotificationItem,
  isStatusItem,
} from 'src/components/pages/communications/utils';
import {
  IEmailMessage,
  IMessage,
  INote,
  IStatus,
  ITelephonyCall,
} from 'src/models/communications';
import { TNotification } from 'src/models/notifications';
import { useMemo } from 'react';
import { sortASC } from 'src/utils/sort';
import {
  BubbleEmptyWrapper,
  BubbleItemWrapperSkeleton,
  BubbleWrapper,
} from './components';

type TNotificationBubbleModalProps = TDialogProps & {
  data?: IBubbleNotificationListResult;
  isLoading: boolean;
};

export const NotificationBubbleModal = ({
  data,
  isLoading,
  close,
}: TNotificationBubbleModalProps) => {
  const getItemDate = (
    item:
      | IEmailMessage
      | TNotification
      | INote
      | IMessage
      | IStatus
      | ITelephonyCall[]
  ) => {
    if (
      isEmailItem(item) ||
      isNoteItem(item) ||
      isMessageItem(item) ||
      isStatusItem(item)
    ) {
      return item.sortDate;
    }

    if (isNotificationItem(item)) {
      return item.createdAt;
    }

    if (Array.isArray(item)) {
      return item[0].sortDate;
    }

    return undefined;
  };

  const groupedItems = useMemo(() => {
    if (!data?.items?.length) {
      return undefined;
    }

    const map = data?.items?.reduce((acc, item) => {
      if (isCallItem(item)) {
        const { callRecordLink } = item;

        // Группируем элементы с communicationType: 'TELEPHONY' по callRecordLink
        if (!acc[callRecordLink]) {
          acc[callRecordLink] = [];
        }

        (acc[callRecordLink] as ITelephonyCall[]).push(item);
      } else {
        // Элементы с другим communicationType добавляем напрямую
        acc[item.id] = item;
      }

      return acc;
    }, {} as Record<string, IEmailMessage | TNotification | INote | IMessage | IStatus | ITelephonyCall[]>);

    return Object.values(map)?.sort((a, b) => {
      const dateA = getItemDate(a);
      const dateB = getItemDate(b);

      return sortASC(dateA!, dateB!);
    });
  }, [data]);

  return (
    <Dialog
      open
      close={close}
      sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: '600px' } }}
      closeBtnPosition={{
        right: {
          xs: 22,
          sm: 28,
        },
        top: {
          xs: 7,
          sm: 27,
        },
      }}>
      <DialogTitle
        sx={{
          paddingTop: '20px',
          paddingBottom: '12px',
          fontSize: '16px',
          fontWeight: '500',
        }}>
        Уведомления
      </DialogTitle>

      <DialogContent sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
        {isLoading && <BubbleItemWrapperSkeleton />}

        {!isLoading &&
          groupedItems &&
          groupedItems?.length > 0 &&
          groupedItems.map((item) => (
            <BubbleWrapper item={item} onClick={close} />
          ))}

        {!isLoading && !groupedItems && <BubbleEmptyWrapper />}
      </DialogContent>
    </Dialog>
  );
};
