import { FC } from 'react';
import { MenuItem, Select } from 'src/components/ui';
import { SelectArrow } from 'src/assets/icons';
import styles from './phone-select.module.scss';

export enum PHONES {
  'MOBILE' = 'Моб.тел.',
  'LANDLINE' = 'Раб.прямой',
  'WORK' = 'Раб.тел.',
}

export type TPhone = keyof typeof PHONES;

export const PhoneSelect: FC<{
  value?: TPhone;
  onChange?: (v: TPhone) => void;
}> = ({ value, onChange }) => (
  <Select<TPhone>
    className={styles.select}
    classes={{
      outlined: styles.selectButton,
    }}
    value={value}
    onChange={(e) => onChange?.(e.target.value as TPhone)}
    IconComponent={SelectArrow}>
    {Object.entries(PHONES).map(([type, label]) => (
      <MenuItem key={type} value={type}>
        {label}
      </MenuItem>
    ))}
  </Select>
);
