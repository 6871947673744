import { FIELD_LENGTH } from 'src/constants';
import { TValidationKeyToRuleMap } from '../types';

const numberAndLetterValidator = (value: string) =>
  Boolean(/^(?=.*[a-zA-Z])(?=.*\d).+$/.exec(value));

const capitalLetterValidator = (value: string) => Boolean(/[A-Z]/.exec(value));

const specialSymbolValidator = (value: string) =>
  // eslint-disable-next-line
  Boolean(/[\/*()_+\-\=@"№;:?~`.,<>!#$%&^\\|{}\[\]§±']/.exec(value))
;

const minLengthValidator = (value: string) =>
  value.length >= FIELD_LENGTH.password[0];

export const VALIDATION_KEY_TO_RULE_MAP: TValidationKeyToRuleMap = {
  NUMBER_AND_LETTER: numberAndLetterValidator,
  CAPITAL_LETTER: capitalLetterValidator,
  SPECIAL_SYMBOL: specialSymbolValidator,
  MIN_LENGTH: minLengthValidator,
};
