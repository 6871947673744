import { ButtonGroup, Button, ButtonGroupProps } from '@mui/material';
import classNames from 'classnames';
import styles from './tabs.module.scss';

export type TabOptions<V = string> = Array<{
  value: V;
  label: string | React.ReactNode;
}>;

interface ITabsProps<V> extends Omit<ButtonGroupProps, 'onChange'> {
  options: TabOptions<V>;
  onChange: (value: V, index: number) => void;
  value?: V | null;
  activeClassName?: string;
}

export const Tabs = <V,>({
  onChange,
  value,
  options,
  sx,
  className,
  activeClassName,
  ...props
}: ITabsProps<V>) => (
  <ButtonGroup
    className={classNames(styles.group, className)}
    sx={{
      display: 'inline-flex',
      ...sx,
    }}
    {...props}>
    {options.map((option, i) => {
      const isActive = option.value === value;

      return (
        <Button
          key={String(option.value)}
          onClick={() => onChange(option.value, i)}
          className={classNames(
            {
              [styles.active]: isActive,
            },
            isActive && activeClassName
          )}>
          {option.label}
        </Button>
      );
    })}
  </ButtonGroup>
);
