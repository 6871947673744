import { FC } from 'react';
import { Alert, Box } from 'src/components/ui';
import { TVariantBusinessRequest } from 'src/models';
import { formatServerDate } from 'src/utils';
import { isRequestRefusalByContractor } from 'src/utils/business-request';

interface IRefusalInfoProps {
  businessRequest: TVariantBusinessRequest;
}

export const RefusalInfo: FC<IRefusalInfoProps> = ({ businessRequest }) => {
  if (!businessRequest.refusal) {
    return null;
  }

  const statusDescription =
    'businessRequestStatus' in businessRequest &&
    isRequestRefusalByContractor(businessRequest.businessRequestStatus.code)
      ? 'Вы отказались от участия в запросе'
      : 'Заявка была отменена';

  return (
    <Box display="flex">
      <Alert
        content={statusDescription}
        tooltip={
          <>
            <span>
              Дата отмены:{' '}
              {formatServerDate(
                businessRequest.refusal.createdAt,
                'dd.MM.yyyy HH:mm'
              )}
              {`\n`}
            </span>
            {businessRequest.refusal.internalCause && (
              <span>
                {`\n`}Внутренняя причина отказа:{' '}
                {`${businessRequest.refusal.internalCause}\n`}
              </span>
            )}
            {businessRequest.refusal.cause && (
              <span>
                {`\n`}Причина: {`${businessRequest.refusal.cause}\n`}
              </span>
            )}
            {businessRequest.refusal.internalComment && (
              <span>
                {`\n`}Внутренний комментарий:{' '}
                {`${businessRequest.refusal.internalComment}\n`}
              </span>
            )}
            {businessRequest.refusal.comment && (
              <span>
                {`\n`}Комментарий: {`${businessRequest.refusal.comment}\n`}
              </span>
            )}
            {businessRequest.refusal.customerCause && (
              <span>
                {`\n`}Причина отказа для заказчика:{' '}
                {`${businessRequest.refusal.customerCause}\n`}
              </span>
            )}
            {businessRequest.refusal.customerComment && (
              <span>
                {`\n`}Комментарий Заказчику:{' '}
                {`${businessRequest.refusal.customerComment}\n`}
              </span>
            )}
            {businessRequest.refusal.contractorCause && (
              <span>
                {`\n`}Причина отказа для исполнителя:{' '}
                {`${businessRequest.refusal.contractorCause}\n`}
              </span>
            )}
            {businessRequest.refusal.contractorComment && (
              <span>
                {`\n`}Комментарий Исполнителю:{' '}
                {`${businessRequest.refusal.contractorComment}\n`}
              </span>
            )}
          </>
        }
      />
    </Box>
  );
};
