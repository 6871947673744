import { debounce, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import {
  ElementType,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Box, NumberInput, Skeleton } from 'src/components/ui';
import { Typography } from 'src/components/ui/index';
import { AuthService } from 'src/services';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
  resetKanbanScrollPosition,
  updateKanbanScrollPosition,
  updateLastKanbanUserRole,
  updateNavigatedToKanbanFrom,
} from 'src/store/kanban/kanban-slice';
import { theme } from 'src/theme';
import styles from './columns-layout.module.scss';
import { ItemCard, NotFound, ScrollPanel } from './components';

type TCard =
  | {
      card: ElementType;
      renderCard?: never;
    }
  | {
      card?: never;
      renderCard: (item: any, itemStatus?: any) => ReactNode;
    };

type TProps<T> = {
  columns?: T[];
  itemListKey: keyof T;
  columnCounterKey?: keyof T;
  columnBudgetKey?: keyof T;
  header?: ElementType;
  getColorIndicator?: (column: T) => 'GREEN' | 'ORANGE';
  onScrollBottom?: () => void;
  rangeHandleScroll?: number;
  showLoadingPagination?: boolean;
  isListInitialized?: boolean;
  showAssignedSpecificRoles?: boolean;
  // Временное решение для кастомизации отступов
  className?: string;
} & (
  | {
      columnTitleKey: keyof T;
      getTitle?: never;
    }
  | {
      columnTitleKey?: never;
      getTitle: (column: T) => string;
    }
) &
  TCard;

const START_GREEN_BORDER = 3;
const SKELETON_KANBAN_HEIGHT = 40;

const sliceTitle = (
  title: string
): {
  shortTitle: string;
  lastWord: string;
} => {
  const words = title.split(' ');
  return {
    shortTitle: words.slice(0, -1).join(' '),
    lastWord: ` ${words[words.length - 1]}`,
  };
};

export const ColumnsLayout = <T,>({
  columns,
  columnTitleKey,
  itemListKey,
  columnCounterKey,
  card: Card,
  header: Header,
  getColorIndicator,
  getTitle,
  onScrollBottom,
  rangeHandleScroll = 150,
  renderCard,
  showLoadingPagination = false,
  isListInitialized = false,
  showAssignedSpecificRoles = true,
  columnBudgetKey,
  className,
}: TProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const prevScrollHeight = useRef(0);
  const prevScrollWidth = useRef(0);
  const [showScrollPanel, setShowScrollPanel] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const kanbanScrollPosition = useAppSelector(
    (state) => state.kanban.kanbanScrollPosition
  );
  const lastKanbanUserRole = useAppSelector(
    (state) => state.kanban.lastKanbanUserRole
  );
  const navigatedToKanbanFrom = useAppSelector(
    (state) => state.kanban.navigatedToKanbanFrom
  );

  const scrollPositionCoordinates = useRef(kanbanScrollPosition);

  const dispatch = useAppDispatch();

  const getColorBorder = (column: T, index: number) => {
    if (getColorIndicator) {
      return getColorIndicator(column) === 'GREEN'
        ? {
            left: styles.borderLeftGreen,
            bottom: styles.borderBottomGreen,
          }
        : undefined;
    }

    return index >= START_GREEN_BORDER
      ? {
          left: styles.borderLeftGreen,
          bottom: styles.borderBottomGreen,
        }
      : undefined;
  };

  useEffect(() => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;

      setShowScrollPanel(scrollWidth - clientWidth > 0);
    }
  }, []);

  const handleScroll = useCallback(() => {
    const scrollContainer = containerRef.current;

    if (!scrollContainer) return;

    const { scrollTop, clientHeight, scrollHeight, scrollWidth } =
      scrollContainer;

    if (prevScrollWidth.current !== scrollWidth) {
      prevScrollWidth.current = scrollWidth;
      return;
    }

    const scrollEnd = scrollTop + clientHeight; // текущая позиция скролла
    const rangeToEnd = scrollHeight - scrollEnd; // сколько px осталось до конца списка

    // диапазон rangeHandleScroll, при котором должна подгружаться новая порция
    // больше чем осталось px до конца списка isListInitialized
    if (
      rangeHandleScroll >= rangeToEnd &&
      scrollEnd > prevScrollHeight.current
    ) {
      onScrollBottom?.();
      prevScrollHeight.current = scrollHeight + SKELETON_KANBAN_HEIGHT;
    }
  }, [onScrollBottom, rangeHandleScroll]);

  useEffect(() => {
    if (navigatedToKanbanFrom?.includes('requests')) {
      containerRef.current?.scrollTo(scrollPositionCoordinates.current);
    } else {
      dispatch(resetKanbanScrollPosition());
    }
  }, []);

  useEffect(
    () => () => {
      dispatch(updateNavigatedToKanbanFrom({ navigatedToKanbanFrom: null }));
    },
    []
  );

  useEffect(() => {
    if (lastKanbanUserRole !== AuthService.currentRole) {
      dispatch(resetKanbanScrollPosition());
      dispatch(
        updateLastKanbanUserRole({
          lastKanbanUserRole: AuthService.currentRole,
        })
      );
    }
  }, [AuthService.currentRole]);

  const handleSaveScrollPosition = () => {
    const { scrollTop, scrollLeft, scrollWidth } = containerRef.current!;

    dispatch(
      updateKanbanScrollPosition({
        top: scrollTop,
        left: scrollLeft,
        scrollWidth,
      })
    );
  };

  useEffect(() => {
    if (!onScrollBottom) return;

    const scrollContainer = containerRef.current;

    if (!scrollContainer) return;

    prevScrollWidth.current = scrollContainer.scrollWidth;

    const debounceFn = debounce(handleScroll, 100);

    scrollContainer.addEventListener('scroll', debounceFn);
    // eslint-disable-next-line consistent-return
    return () => {
      scrollContainer.removeEventListener('scroll', debounceFn);
    };
  }, [handleScroll, onScrollBottom]);

  useEffect(() => {
    if (isListInitialized) {
      handleScroll();
    }
  }, [isListInitialized]);

  if (!columns?.length) {
    return <NotFound />;
  }

  // Получение надписи "заявки" в правильной форме
  const getRequestColumnLabel = (requestsNumber: string): string => {
    if (requestsNumber === '13') {
      return 'заявок';
    }
    const lastRequestCountNumber = parseInt(
      requestsNumber.substring(requestsNumber.length - 1),
      10
    );
    if (lastRequestCountNumber === 0 || lastRequestCountNumber > 4) {
      return 'заявок';
    }
    if (lastRequestCountNumber === 1) {
      return 'заявка';
    }
    if (lastRequestCountNumber > 1 && lastRequestCountNumber < 5) {
      return 'заявки';
    }
    return 'заявок';
  };

  const getRequestsCount = (element: T): number => {
    const items = element[itemListKey];

    if (columnCounterKey) {
      return element[columnCounterKey] as unknown as number;
    }

    if (Array.isArray(items)) {
      return items.length;
    }

    return 0;
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}>
      <ScrollContainer
        className={styles.container}
        innerRef={containerRef}
        hideScrollbars={false}
        vertical={false}>
        <Box display="inline-block">
          <div className={classNames(styles.header, className)}>
            {columns.map((element, index) => {
              const title = getTitle
                ? getTitle(element)
                : element[columnTitleKey];

              return (
                <div
                  key={index}
                  className={classNames(
                    styles.headerCard,

                    getColorBorder(element, index)?.bottom
                  )}>
                  <span className={styles.headerCardTitle}>
                    {sliceTitle(String(title)).shortTitle}
                  </span>

                  <span className={styles.noWrapTitle}>
                    <span className={styles.titleCard}>
                      {sliceTitle(String(title)).lastWord}
                    </span>
                  </span>

                  <div style={{ display: 'flex' }}>
                    {`${getRequestsCount(element)} ${getRequestColumnLabel(
                      String(getRequestsCount(element))
                    )}`}
                    {columnBudgetKey && getRequestsCount(element) !== 0 && (
                      <Typography
                        color="#292929"
                        fontSize="13px"
                        fontWeight={500}>
                        &nbsp;
                        {'- '}
                        <NumberInput
                          mode="price"
                          displayType="text"
                          value={
                            columnBudgetKey
                              ? String(element[columnBudgetKey])
                              : 0
                          }
                        />
                        ₽
                      </Typography>
                    )}
                  </div>
                  {Header && <Header data={element} />}
                </div>
              );
            })}
          </div>

          <div className={classNames(styles.cardList, className)}>
            {columns.map((element, index) => {
              const items = element[itemListKey];

              return (
                <div className={styles.cardListColumn} key={index}>
                  {Array.isArray(items) &&
                    items.map((item, itemIndex) => (
                      <ItemCard
                        key={itemIndex}
                        onClick={() => handleSaveScrollPosition()}
                        className={classNames(
                          styles.card,
                          showAssignedSpecificRoles &&
                            item?.assignedSpecificRoles &&
                            styles.cardAssigned
                        )}
                        sx={() => ({
                          borderLeftColor:
                            item.color ||
                            (showAssignedSpecificRoles &&
                              item?.assignedSpecificRoles &&
                              '#D4DFEE') ||
                            '#fff',
                        })}>
                        {renderCard ? (
                          renderCard({ ...item }, (element as any).statusCode)
                        ) : (
                          <Card data={item} />
                        )}
                      </ItemCard>
                    ))}
                </div>
              );
            })}
          </div>

          {showLoadingPagination && (
            <Skeleton
              width="calc(100% - 120px)"
              height={SKELETON_KANBAN_HEIGHT}
              sx={{ marginTop: '8px', marginLeft: '60px' }}
            />
          )}
        </Box>
      </ScrollContainer>

      {!isMobile && showScrollPanel && (
        <ScrollPanel
          countColumns={columns.length}
          containerRef={containerRef}
          initialScrollWidth={kanbanScrollPosition.scrollWidth}
          initialScrollLeft={kanbanScrollPosition.left}
        />
      )}
    </div>
  );
};
