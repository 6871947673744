import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/utils';
import {
  ISendStatusParams,
  TStatusesResult,
} from 'src/api/communications/models';

export const communicationStatusApi = createApi({
  reducerPath: 'communicationStatusApi',
  baseQuery,
  tagTypes: ['Status'],
  endpoints: (builder) => ({
    getStatusCommunicationsChain: builder.query<TStatusesResult, string>({
      query: (applicationId) =>
        `aggregator/api/v1/status-message/application/${applicationId}`,
      providesTags: ['Status'],
    }),
    sendStatus: builder.mutation<void, ISendStatusParams>({
      query: ({ ...body }) => ({
        url: 'aggregator/api/v1/status-message/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Status'],
    }),
  }),
});

export const { useSendStatusMutation, useGetStatusCommunicationsChainQuery } =
  communicationStatusApi;
