import { Skeleton } from 'src/components/ui/index';
import styles from './content-column-skeleton.module.scss';

export const ContentColumnSkeleton = () => (
  <>
    <Skeleton className={styles.title} />

    <div className={styles.container}>
      <Skeleton className={styles.item} />
      <Skeleton className={styles.item} />
    </div>
  </>
);
