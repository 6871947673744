import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const CompanyInfo = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminCompanyCompanyInfo' */
      /* webpackMode: "lazy" */
      `./company-info`
    )
);

export const LazyCompanyInfo: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <CompanyInfo />
  </Suspense>
);
