import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const ContractorSpecifications = lazy(
  () =>
    import(
      /* webpackChunkName: 'ContractorSpecifications' */
      /* webpackMode: "lazy" */
      `./specifications`
    )
);

export const LazyContractorSpecifications: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ContractorSpecifications />
  </Suspense>
);
