import { Stack } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'src/components/ui/form';
import commonStyles from 'src/components/ui/new-filters/filters.module.scss';
import {
  TFilterItemBaseProps,
  TRangeFilterValue,
} from 'src/components/ui/new-filters/types';
import { OutlinedInput } from 'src/components/ui/outlined-input';
import styles from '../checkbox-form/checkbox-form.module.scss';
import { getRangeValuesFromString } from './utils';

export const RangeForm = <FilterKeys extends string = string>({
  filter,
  filterKey,
  updateFilterValue,
}: TFilterItemBaseProps<FilterKeys>) => {
  const form = useForm<TRangeFilterValue>();

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!filter) {
      form.setValue('from', '');
      form.setValue('to', '');
    } else {
      form.setValue('from', (filter.value as TRangeFilterValue).from);
      form.setValue('to', (filter.value as TRangeFilterValue).to);
    }
  }, [filter]);

  const handleChange = () => {
    const values = form.getValues();
    const rangeValue = getRangeValuesFromString(
      filterKey,
      values.from,
      values.to
    );
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => updateFilterValue(rangeValue), 400);
  };

  return (
    <div>
      <Form form={form} footer={false}>
        <Stack direction="row" gap="12px">
          <Form.Item name="from">
            <OutlinedInput
              className={classNames(
                commonStyles.inputSearch,
                commonStyles.inputBorder,
                styles.noMargin
              )}
              inputProps={{ max: 20 }}
              onChange={() => handleChange()}
              type="number"
              fullWidth
              placeholder="От"
            />
          </Form.Item>
          <Form.Item name="to">
            <OutlinedInput
              className={classNames(
                commonStyles.inputSearch,
                commonStyles.inputBorder,
                styles.noMargin
              )}
              type="number"
              fullWidth
              placeholder="До"
              onChange={() => handleChange()}
            />
          </Form.Item>
        </Stack>
      </Form>
    </div>
  );
};
