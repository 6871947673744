import { Stack } from '@mui/material';
import { InfoIcon } from 'src/assets/icons';
import { Card, EmptyMessage, SvgIcon, Typography } from 'src/components/ui';
import { IKonturFocusData } from 'src/models';

export const CompanyOkvedInfoBlock = ({
  scoringInfo,
  infoBlockClassName,
}: {
  scoringInfo?: IKonturFocusData;
  infoBlockClassName?: string;
}) => {
  const primaryActivity = scoringInfo?.okveds?.find(
    (item) => item.primaryActivity
  );
  const additionalActivities = scoringInfo?.okveds?.filter(
    (item) => !item.primaryActivity
  );

  return (
    <div>
      {!scoringInfo?.okveds?.length ? (
        <EmptyMessage title="Информация по ОКВЭД неизвестна">
          По указанному ИНН компании информация по ОКВЭД не найдена
        </EmptyMessage>
      ) : (
        <Card className={infoBlockClassName}>
          <Stack direction="column" gap="24px">
            <Stack gap="12px">
              <Typography color="text.secondary">Основной</Typography>
              <Stack direction="row" gap="20px">
                <Typography variant="h6" flexBasis="60px" flexShrink="0">
                  {primaryActivity?.code || 'Неизвестно'}
                </Typography>
                <Typography variant="h4">
                  {primaryActivity?.name || 'Неизвестно'}
                </Typography>
              </Stack>
            </Stack>

            <Stack gap="12px">
              <Typography color="text.secondary">Дополнительные</Typography>
              {additionalActivities?.length &&
              additionalActivities?.length > 0 ? (
                additionalActivities?.map((item) => (
                  <Stack direction="row" gap="20px" key={item.name}>
                    <Typography variant="h6" flexBasis="60px" flexShrink="0">
                      {item?.code || 'Неизвестно'}
                    </Typography>
                    <Typography variant="h4">
                      {item?.name || 'Неизвестно'}
                    </Typography>
                  </Stack>
                ))
              ) : (
                <Typography fontSize="16px">-</Typography>
              )}
            </Stack>
          </Stack>
        </Card>
      )}
    </div>
  );
};
