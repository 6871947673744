/* eslint-disable no-nested-ternary */
import { ButtonProps, Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Button, InfoStack } from 'src/components/ui';
import styles from './items-list.module.scss';

const genericMemo: <T>(component: T) => T = React.memo;

interface IItemsList<I extends object, T> {
  items: I[];
  isLoading?: boolean;
  selectedId?: T;
  onSelect: (id: number) => void;
  multi?: boolean;
  idKey: keyof I;
  titleKey: keyof I;
  subTitleKey: keyof I;
}

export const ItemsList = genericMemo(
  <I extends object, T>({
    items,
    onSelect,
    selectedId,
    isLoading,
    idKey,
    titleKey,
    subTitleKey,
    multi,
  }: IItemsList<I, T>) => {
    const renderItem = useCallback(
      (i: number) => {
        const item = items[i];
        const color: ButtonProps['color'] =
          selectedId === item[idKey]
            ? multi
              ? 'error'
              : 'success'
            : 'primary';

        const btnText =
          selectedId === item[idKey]
            ? multi
              ? 'Исключить'
              : 'Выбрано'
            : 'Выбрать';

        return (
          <InfoStack
            noBorder
            key={item[idKey] as any}
            subtitle={item[subTitleKey] as any}
            className={styles.item}
            after={
              <Button
                color={color}
                variant="text"
                onClick={() => onSelect(item[idKey] as any)}>
                {btnText}
              </Button>
            }>
            {item[titleKey] as any}
          </InfoStack>
        );
      },
      [items, onSelect, selectedId, idKey, titleKey, subTitleKey]
    );
    return (
      <Stack gap={2} className={styles.wrapper}>
        {isLoading ? (
          <Stack mt={3} gap={2}>
            {Array.from(Array(4).keys()).map((val) => (
              <Skeleton key={val} height={44} />
            ))}
          </Stack>
        ) : items.length > 0 ? (
          <Virtuoso
            className={styles.list}
            totalCount={items.length}
            itemContent={renderItem}
          />
        ) : (
          <Typography align="center" pt={4}>
            Компании не найдены
          </Typography>
        )}
      </Stack>
    );
  }
);
