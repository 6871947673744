import { Typography } from 'src/components/ui';
import { Stack } from '@mui/material';
import { IUserState } from 'src/store/user/user-slice';
import { ROLES } from 'src/constants';
import styles from './actions-block.module.scss';
import { ROLE_TO_ITEMS_MAP } from './config';

interface IActionsBlockProps {
  user: IUserState;
  onClick?: () => void;
}

export const ActionsBlock = ({ user, onClick }: IActionsBlockProps) => {
  const items = ROLE_TO_ITEMS_MAP[user.roles[0] as ROLES];

  return (
    <div className={styles.wrapper}>
      {items?.length &&
        items.map((item) => (
          <Stack
            key={item.title}
            direction="row"
            gap="12px"
            alignItems="center"
            onClick={onClick}>
            {item.icon}
            <Typography color={item.color}>{item.title}</Typography>
          </Stack>
        ))}
    </div>
  );
};
